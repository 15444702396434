import { useState } from "react";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { CalculatorOption } from "../types/calculatorOption";
import { Optional } from "../types/optional";

type TUseChoiceCalculatorReturnValue = {
  selected: Record<string, Optional<CalculatorOption>>;
  setSelected: React.Dispatch<
    React.SetStateAction<Record<string, Optional<CalculatorOption>>>
  >;
  result: number;
  resultText: string;
  incomplete: boolean;
};

export function useChoiceCalculator(
  options: CalculatorOptionsGroup[],
  interpretor: (value: number) => string,
): TUseChoiceCalculatorReturnValue {
  const [selected, setSelected] = useState(
    options.reduce<Record<string, Optional<CalculatorOption>>>(
      (prev, curr) => ({
        ...prev,
        [curr.code]: curr.options.find((e) => e.default),
      }),
      {},
    ),
  );

  const getResultAndIncomplete = () => {
    let result = 0;
    let incomplete = false;
    for (const [k, v] of Object.entries(selected)) {
      result += v?.result ?? 0;
      const option = options.find((e) => e.code === k);
      if (option && !incomplete)
        incomplete = Boolean(option.required) && v === undefined;
    }

    return [result, incomplete] as const;
  };

  const [result, incomplete] = getResultAndIncomplete();

  return {
    selected,
    setSelected,
    result,
    resultText: interpretor(result),
    incomplete: incomplete,
  } as const;
}
