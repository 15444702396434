import { useState } from "react";

export const useTypeAheadInput = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [initialValue, setInitialValue] = useState<any>();

  const storeInitialValue = (value) => {
    setInitialValue(value);
  };

  return {
    inputValue,
    setInputValue,
    initialValue,
    storeInitialValue,
  };
};
