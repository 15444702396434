import { CacheLocation } from "@auth0/auth0-spa-js";

const cacheLocation: CacheLocation = "localstorage";

export const amplifyConfig = {
  aws_project_region: import.meta.env.VITE_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: import.meta.env
    .VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
  aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: import.meta.env
    .VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: cacheLocation,
};

export const defaultCountryConfig = {
  defaultCountryPhoneCode: import.meta.env.VITE_DEFAULT_COUNTRY_PHONE_CODE,
};

export const defaultCountryName = {
  defaultCountryAddressName: import.meta.env.VITE_DEFAULT_COUNTRY_NAME,
};

export const apiConfig = {
  patientServiceBase: import.meta.env.VITE_PATIENT_SERVICE_BASE,
  providerServiceBase: import.meta.env.VITE_PROVIDER_SERVICE_BASE,
  medicalsServiceBase: import.meta.env.VITE_MEDICALS_SERVICE_BASE,
  appointmentServiceBase: import.meta.env.VITE_APPOINTMENT_SERVICE_BASE,
  refDataServiceBase: import.meta.env.VITE_REF_DATA_SERVICE_BASE,
};

export const ldConfig = {
  clientSideId: import.meta.env.VITE_LD_CLIENT_SIDE_ID,
};

export const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: import.meta.env.VITE_GTM_CONTAINER_ID,
};

export const socketConfig = {
  maxRetryAttempts: import.meta.env.VITE_MAX_SOCKET_RETRY,
};

export const hotjarConfig = {
  id: parseInt(import.meta.env.VITE_HOTJAR_ID),
  snippetVersion: parseInt(import.meta.env.VITE_HOTJAR_SV),
};
