import { Close } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { FC } from "react";

interface FormHeaderInterface {
  title: string;
  disableActions?: boolean;
  onClose: () => void;
}

const FormHeader: FC<FormHeaderInterface> = ({
  title,
  disableActions,
  onClose,
}) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography color="primary" variant="h5">
          {title}
        </Typography>
      </Grid>
      <Grid item display="flex" justifyContent="flex-end">
        <Button
          disabled={disableActions ?? false}
          endIcon={<Close />}
          variant="text"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
