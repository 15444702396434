import { useState } from "react";
import { UnitOption } from "../types/unitOption";
import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { Quantity } from "../types/quantity";
import { Optional } from "../types/optional";

const proteinUnits: UnitOption[] = [
  { unit: "g/L", converter: (value) => value },
  { unit: "g/dL", converter: (value) => value * 10 },
];

export default function LightsExudativeEffusionsComponent() {
  const [values, setValues] = useState<{
    totalSerumProtein: Quantity;
    pleuralFluidProtein: Quantity;
    serumLDH: Optional<number>;
    pleuralFluidLDH: Optional<number>;
    upperLimitOfNormalSerumLDH: Optional<number>;
  }>({
    totalSerumProtein: {
      value: undefined,
      unit: proteinUnits[0],
    },
    pleuralFluidProtein: {
      value: undefined,
      unit: proteinUnits[0],
    },
    serumLDH: undefined,
    pleuralFluidLDH: undefined,
    upperLimitOfNormalSerumLDH: undefined,
  });

  const interpretValues = (
    totalSerumProtein: number = 0,
    pleuralFluidProtein: number = 0,
    serumLDH: number = 0,
    pleuralFluidLDH: number = 0,
    upperLimitOfNormalSerumLDH: number = 0,
  ) => {
    try {
      switch (true) {
        case pleuralFluidProtein / totalSerumProtein >= 0.5:
        case pleuralFluidLDH / serumLDH >= 0.6:
        case pleuralFluidLDH > (2 / 3) * upperLimitOfNormalSerumLDH:
          return "At least one criteria met, suggests exudative effusion";
        default:
          return "Criteria not met, transudative effusion likely";
      }
    } catch (error) {
      return "";
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Total serum protein"
            placeholder={`Norm: ${
              values.totalSerumProtein.unit.unit === "g/L" ? "60-78" : "6-7.8"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                totalSerumProtein: {
                  ...values.totalSerumProtein,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={proteinUnits}
                label={values.totalSerumProtein.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    totalSerumProtein: {
                      ...values.totalSerumProtein,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />
          <CalculatorTextFieldItem
            text="Pleural fluid protein"
            placeholder={`Norm: ${
              values.pleuralFluidProtein.unit.unit === "g/L" ? "10-20" : "1-2"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                pleuralFluidProtein: {
                  ...values.pleuralFluidProtein,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={proteinUnits}
                label={values.pleuralFluidProtein.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    pleuralFluidProtein: {
                      ...values.pleuralFluidProtein,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Serum LDH"
            placeholder="Norm: 60-160"
            endAdornment={<Typography>U/L</Typography>}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                serumLDH: float,
              });
            }}
          />

          <CalculatorTextFieldItem
            text="Pleural LDH"
            placeholder="Norm: 0-1,000"
            endAdornment={<Typography>U/L</Typography>}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                pleuralFluidLDH: float,
              });
            }}
          />

          <CalculatorTextFieldItem
            text="Upper limit of normal serum LDH"
            placeholder="Norm: 60-160"
            endAdornment={<Typography>U/L</Typography>}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                upperLimitOfNormalSerumLDH: float,
              });
            }}
          />
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const {
            totalSerumProtein,
            pleuralFluidProtein,
            serumLDH,
            pleuralFluidLDH,
            upperLimitOfNormalSerumLDH,
          } = values;

          const proteinValid = [
            totalSerumProtein.value,
            pleuralFluidProtein.value,
          ].every((e) => Number.isFinite(e));
          const ldhValid = [
            serumLDH,
            pleuralFluidLDH,
            upperLimitOfNormalSerumLDH,
          ].every((e) => Number.isFinite(e));

          const valid = proteinValid || ldhValid;

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const result = interpretValues(
            totalSerumProtein.unit.converter(totalSerumProtein.value ?? 0),
            pleuralFluidProtein.unit.converter(pleuralFluidProtein.value ?? 0),
            serumLDH,
            pleuralFluidLDH,
            upperLimitOfNormalSerumLDH,
          );

          return <Typography>{result}</Typography>;
        })()}
      </Box>
    </Box>
  );
}
