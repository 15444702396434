import { useContext } from "react";
import { DuplicatePatientModalContext } from "../contexts/DuplicatePatientModalProvider";

const useDuplicatePatientModal = () => {
  const {
    isOpen,
    setDuplicatePatients,
    openDuplicatePatientModal,
    closeDuplicatePatientModal,
    duplicatePatients,
    patientForCreation,
    setPatientForCreation,
    duplicatePatientActions,
    setDuplicatePatientActions,
  } = useContext(DuplicatePatientModalContext);
  return {
    isOpen,
    duplicatePatients,
    patientForCreation,
    setPatientForCreation,
    setDuplicatePatients,
    openDuplicatePatientModal,
    closeDuplicatePatientModal,
    duplicatePatientActions,
    setDuplicatePatientActions,
  };
};

export default useDuplicatePatientModal;
