import {
  Autocomplete,
  Button,
  Fade,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { get, set, startCase, unionBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { medicalsApi } from "../../../../api/medicalsApi";
import { patientApi } from "../../../../api/patientApi";
import { DuplicatePatientModalContext } from "../../../../contexts/DuplicatePatientModalProvider";
import useComponentLoader from "../../../../hooks/useComponentLoader";
import { usePractitionerReference } from "../../../../hooks/usePractitioner";
import { useOrganizationReference } from "../../../../pages/facility/hooks/useOrganization";
import { Patient } from "../../../../types/patient";
import { Reference } from "../../../../types/reference";
import { generateHumanDisplayName } from "../../../../utils/DataTransformationUtils";
import {
  NotificationVariant,
  NotificationsContext,
} from "../../../dashboard/NotificationsProvider";
import PatientInput from "../../../form/PatientInput";

type Props = {
  subject: Reference;
  setSubject: (subject: Reference) => void;
  setCurrentEncounterId: (encounterId: string) => void;
  setEncounterSearchComplete: (complete: boolean) => void;
};

const FindOrCreatePatientInput = ({
  subject,
  setSubject,
  setCurrentEncounterId,
  setEncounterSearchComplete,
}: Props) => {
  const defaultPatient = {
    name: [{}],
    telecom: [{ system: "phone", use: "mobile" }],
    gender: "",
    birthDate: null,
  };
  const [patientSearchString, setPatientSearchString] = useState<string>("");
  const [newPatientOption, setNewPatientOption] = useState<boolean>(false);
  const [allowNewPatient, setAllowNewPatient] = useState<boolean>(false);
  const [patientSearchResults, setPatientSearchResults] = useState<Patient[]>(
    [],
  );
  const { setNotification } = useContext(NotificationsContext);
  const [, setComponentLoading] = useComponentLoader();
  const [newPatient, setNewPatient] = useState<any>(defaultPatient);
  const organizationReference = useOrganizationReference();
  const practitionerReference = usePractitionerReference();
  const {
    openDuplicatePatientModal,
    setDuplicatePatients,
    setDuplicatePatientActions,
    closeDuplicatePatientModal,
    setPatientForCreation,
  } = useContext(DuplicatePatientModalContext);

  const searchForPatient = async (): Promise<void> => {
    try {
      const searchResults = await patientApi.findPatientsByOrg({
        q: patientSearchString,
        "managing-organization": organizationReference?.identifier,
      });
      if (searchResults) {
        setPatientSearchResults(searchResults);
      }
      if (!patientSearchString || patientSearchString.length === 0) {
        setNewPatientOption(false);
      } else {
        setNewPatientOption(true);
      }
    } catch (e) {
      console.error("Error while searching ", e);
    }
  };

  const createAndAddPatient = async (patient, createDuplicate) => {
    const payload = { ...patient };
    payload.managingOrganization = unionBy(
      payload.managingOrganization || [],
      [
        {
          reference: `Organization/${organizationReference?.identifier}`,
        },
      ],
      "reference",
    );
    try {
      const createdPatient = await patientApi.savePatient(
        payload,
        createDuplicate,
      );
      onPatientSelected(createdPatient);
      setNotification({
        message: `Successfully registered and added the new patient to the Encounter.`,
        variant: NotificationVariant.success,
      });
      setPatientSearchString("");
      resetPatientForm(false);
      closeDuplicatePatientModal();
    } catch (e) {
      if (e.body) {
        setPatientForCreation(payload);
        openDuplicatePatientModal();
        setAllowNewPatient(false);
        setDuplicatePatients(e.body);
      } else {
        setNotification({
          message: "An error occurred while creating a new patient",
          variant: NotificationVariant.error,
        });
      }
    }
  };

  useAsyncEffect(async () => {
    if (subject) {
      await findEncountersForSubject(subject);
    }
  }, [subject]);

  const updatePatient = (field, value) => {
    const update = { ...newPatient };
    set(update, field, value);
    setNewPatient(update);
    setPatientForCreation(update);
  };

  const resetPatientForm = (optionForNew) => {
    setNewPatient(defaultPatient);
    setAllowNewPatient(false);
    setNewPatientOption(optionForNew);
  };

  const getPatientOptionLabel = (option) => {
    // if no name, assume option is a reference
    if (!option.name) return option?.display ?? "";

    // otherwise assume it's a name object
    const name = generateHumanDisplayName(get(option, "name[0]"));
    const id = option.patientId;
    const gender = !option.gender
      ? undefined
      : startCase(option.gender.toLowerCase());
    const label = [name, gender, id].filter((e) => e && e !== "").join(" - ");

    return label;
  };

  const onPatientSelected = (val) => {
    if (val) {
      if (val.reference) {
        setSubject(val);
      } else {
        const patient: Reference = {
          type: "Patient",
          reference: `Patient/${val?.id}`,
          identifier: val?.id,
          display: generateHumanDisplayName(get(val, "name[0]")),
        };

        setSubject(patient);
      }
    } else {
      // cleared
      setSubject(undefined);
    }
  };

  const encounterStatuses = ["arrived", "in-progress", "triaged"];

  const findEncountersForSubject = async (patient) => {
    try {
      setComponentLoading(true);
      const result = await medicalsApi.findEncounters({
        patient: patient?.id || patient?.identifier,
        practitioner: practitionerReference?.identifier,
        "service-provider": organizationReference?.reference,
        status: encounterStatuses,
      });

      setCurrentEncounterId(result[0]?.id);
      if (result.length === 0) {
        onPatientSelected(patient);
      }
      setEncounterSearchComplete(true);
    } catch (error) {
      setNotification({
        message: "An error occured while searching patientEncounter",
        variant: NotificationVariant.error,
      });
    } finally {
      setComponentLoading(false);
    }
  };

  useEffect(() => {
    searchForPatient();
    if (
      (!patientSearchString || patientSearchString.length === 0) &&
      newPatientOption
    ) {
      setNewPatientOption(false);
    }
  }, [patientSearchString, newPatientOption]);

  return (
    <>
      {!allowNewPatient && (
        <Grid
          item
          xs={newPatientOption ? 10 : 12}
          sx={{
            transition: (theme) =>
              theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <Autocomplete
            fullWidth
            autoHighlight
            key="medical-encounter-patient-lookup"
            id="medical-encounter-patient-lookup"
            options={patientSearchResults}
            value={subject}
            getOptionLabel={getPatientOptionLabel}
            onInputChange={(e, newInput) => setPatientSearchString(newInput)}
            freeSolo
            inputValue={patientSearchString}
            onChange={async (e, v) => {
              await findEncountersForSubject(v);
            }}
            filterSelectedOptions
            filterOptions={(x) => x}
            isOptionEqualToValue={(o, v) => o?.id === v?.identifier}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Patient"
                variant="outlined"
                placeholder="Please search for a patient or create a new one if no matching patient is found."
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      )}
      {newPatientOption && !allowNewPatient && (
        <Fade in={newPatientOption} timeout={550}>
          <Grid item xs={2}>
            <Link
              color="primary"
              onClick={(e) => {
                setDuplicatePatientActions({
                  useDuplicatePatient: (patient) => {
                    onPatientSelected(patient);
                    closeDuplicatePatientModal();
                  },
                  actionButtons: [
                    {
                      button: ({ onClick }) => (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={onClick}
                        >
                          Create New Patient
                        </Button>
                      ),
                      onClick: (newPatient) => {
                        createAndAddPatient(newPatient, true);
                      },
                    },
                  ],
                });
                setSubject(undefined);
                setAllowNewPatient(true);
                setNewPatientOption(false);
                const names = patientSearchString.split(" ");
                if (names[0]) {
                  updatePatient("name[0].firstName", names[0]);
                }
                if (names[1]) {
                  updatePatient("name[0].family", names[1]);
                }
                updatePatient("managingOrganization", [organizationReference]);
              }}
            >
              <Typography sx={{ textAlign: "center", cursor: "pointer" }}>
                Create New patient
              </Typography>
            </Link>
          </Grid>
        </Fade>
      )}
      {allowNewPatient && (
        <PatientInput
          newPatient={newPatient}
          handleUpdate={updatePatient}
          handleCreate={createAndAddPatient}
          handleReset={resetPatientForm}
          btnText="Add new patient to Encounter"
          dobRequired
          genderRequired
        />
      )}
    </>
  );
};

export default FindOrCreatePatientInput;
