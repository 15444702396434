import { Box, Grid, Typography } from "@mui/material";
import convert, { Unit } from "convert";
import { set } from "lodash";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import { BmiInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import {
  bmiCheckDisplay,
  getWeightKg,
  updateMetaData,
} from "../VitalsFormHelper";

const BmiInput: FC<BmiInputProps> = ({
  encounter,
  patient,
  value,
  onChange,
  height,
  weight,
}) => {
  const practitionerReference = usePractitioner();

  const [bmi, setBmi] = useState<any>();
  const [bmiDisplay, setBmiDisplay] = useState<string>();

  const heightInCentimeters = (height: {
    valueQuantity: { value: number; unit: string };
  }) => {
    return convert(
      height?.valueQuantity?.value ?? 0,
      (height?.valueQuantity?.unit as Unit) ?? "cm",
    ).to("cm");
  };

  useEffect(() => {
    const ht = heightInCentimeters(height);
    const wt =
      weight?.valueQuantity?.unit === "kg"
        ? weight?.valueQuantity?.value ?? 0
        : getWeightKg(weight);

    const newBmi = { ...bmi };

    let bodyMassIndex;
    if (ht === null || wt === null) {
      setBmiDisplay("0");
    } else if (ht !== null || wt !== null) {
      bodyMassIndex = (wt / ((ht / 100) * (ht / 100))).toFixed(1);
      bodyMassIndex === "Infinity"
        ? setBmiDisplay(null)
        : setBmiDisplay(bodyMassIndex);
    }

    set(newBmi, "valueQuantity.value", bodyMassIndex ? bodyMassIndex : null);
    updateMetaData(newBmi, practitionerReference);
    onChange(newBmi);
  }, [height, weight, bmiDisplay]);

  useEffect(() => {
    if (value) {
      setBmi(value);
    } else {
      const bmi = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "39156-5",
        "Body Mass Index",
        "kg/m2",
      );
      setBmi(bmi);
    }
  }, [encounter, patient, value]);

  const bmiClassification = bmiCheckDisplay(bmiDisplay).bmiClassification;
  const bmiDisplayColor = bmiCheckDisplay(bmiDisplay).bmiDisplayColour;

  const bmiNum = Number(bmiDisplay);

  return (
    <Grid item xs={12} display="flex" alignItems="center" height="100%" gap={1}>
      <Box>
        <Typography>
          BMI: {bmiDisplay && Number.isFinite(bmiNum) ? bmiDisplay : "0.0"}
          <Typography component={"span"} variant="caption">
            kg/m<sup>2</sup>
          </Typography>
        </Typography>
      </Box>
      <Box flex={1}>
        <Typography
          component="span"
          variant="caption"
          textAlign="center"
          display="table-cell"
          style={{ color: bmiDisplayColor }}
        >
          <b>{bmiClassification}</b>
        </Typography>
      </Box>
    </Grid>
  );
};

export default BmiInput;
