import {
  Box,
  ClickAwayListener,
  ClickAwayListenerProps,
  Popper,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { Fragment } from "react";
import { useFieldHints } from "../hooks/useFieldHints";
import useSettings from "../hooks/useSettings";
import { THintDetails } from "../utils/inputHintHelpers";

type Props = {
  id?: string;
  value: unknown;
  fieldHintCallback?: (value: string) => THintDetails | undefined;
  textFieldProps?: Omit<TextFieldProps, "value">;
  clickAwayListenerProps?: Omit<
    ClickAwayListenerProps,
    "onClickAway" | "children"
  >;
};

export default function TextInputWithHints({
  fieldHintCallback,
  id,
  ...rest
}: Props) {
  const settings = useSettings();
  const theme = useTheme();

  const {
    enableFieldHints,
    anchorEl,
    setAnchorEl,
    open,
    setOpen,
    hasFocused,
    initialize,
    hint,
  } = useFieldHints(rest.value, fieldHintCallback);

  return (
    <Fragment>
      <ClickAwayListener
        onClickAway={() => setOpen(false)}
        {...rest.clickAwayListenerProps}
      >
        <TextField
          value={rest.value}
          ref={(r) => setAnchorEl(r)}
          onFocus={() => {
            let _hint = hint;
            if (!enableFieldHints) return;
            if (!hasFocused) _hint = initialize();
            if (_hint === undefined) return;
            setOpen(true);
          }}
          {...rest.textFieldProps}
        />
      </ClickAwayListener>
      {enableFieldHints && (
        <Popper
          disablePortal
          id={id}
          anchorEl={anchorEl}
          open={open && hint !== undefined}
          placement="bottom-start"
          style={{ zIndex: theme.zIndex.drawer - 1 }}
        >
          <Box
            color={hint?.color}
            position="relative"
            bgcolor={hint?.bgcolor}
            borderRadius={settings.settings.roundedCorners ? "10px" : "4px"}
            p={1}
            mt={1}
            sx={{
              visibility: open && hint !== undefined ? "visible" : "hidden",
              position: "relative",
              border: "1px solid ActiveBorder",
              "& ::before": {
                bottom: "100%",
                left: "50%",
                border: "solid transparent",
                content: '""',
                height: 0,
                width: 0,
                position: "absolute",
                "pointer-events": "none",
                "border-color": "rgba(194, 225, 245, 0)",
                "border-bottom-color": "#c2e1f5",
                "border-width": "8px",
                "margin-left": "-8px",
              },
              "& ::after": {
                bottom: "100%",
                left: "50%",
                border: "solid transparent",
                content: '""',
                height: 0,
                width: 0,
                position: "absolute",
                "pointer-events": "none",
                "border-color": "rgba(136, 183, 213, 0)",
                "border-bottom-color": `${hint?.bgcolor}`,
                "border-width": "9px",
                "margin-left": "-9px",
              },
            }}
          >
            <b>{hint?.message}</b>
          </Box>
        </Popper>
      )}
    </Fragment>
  );
}
