import { Box, Grid, Typography } from "@mui/material";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import Choice from "./Choice";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";

type Props = ReturnType<typeof useChoiceCalculator> & {
  options: CalculatorOptionsGroup[];
};

export default function BasicCalculatorChoice({
  options,
  selected,
  setSelected,
  result,
  resultText,
  incomplete,
}: Props) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          {options.map((e) => {
            return (
              <Grid item key={e.code} xs={12}>
                <Choice
                  value={selected[e.code]}
                  options={e.options}
                  group={e.title}
                  subtitle={e.subtitle}
                  onChange={(element) =>
                    setSelected((s) => ({ ...s, [e.code]: element }))
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        <Typography>
          {incomplete ? (
            <i>Please fill out the required fields</i>
          ) : (
            <>
              <b>Score {result}</b>: {resultText}
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
}
