import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { debounce, kebabCase } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { providerApi } from "../../../api/providerApi";
import {
  createReference,
  generateHumanDisplayName,
} from "../../../utils/DataTransformationUtils";
import useNotifications from "../../../hooks/useNotifications";
import { NotificationVariant } from "../../../components/dashboard/NotificationsProvider";

interface PractitionerLookupInterface {
  disabled?: boolean;
  value: any;
  multiple?: boolean;
  label: string;
  onChange: (val: any) => void;
  isOptionEqualToValue?: (opt: any, value: any) => boolean;
  managingOrganization?: string;
}

const PractitionerLookup: FC<PractitionerLookupInterface> = ({
  value,
  disabled,
  multiple,
  label,
  onChange,
  isOptionEqualToValue,
  managingOrganization,
}) => {
  const { setNotification } = useNotifications();
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const query: any = { q: "" };
    query.q = inputValue || "";
    if (managingOrganization) {
      query["managing-organization"] = managingOrganization;
    }
    setQuery(query);
  }, [managingOrganization, inputValue]);

  useEffect(() => {
    debouncedSearch(inputValue, query);
  }, [inputValue, query]);

  const findPractitioners = async (inputValue, query) => {
    try {
      setLoading(true);
      if (inputValue !== undefined && query) {
        const practitioners = await providerApi.findPractitioners(query);
        const opts = practitioners.map((o) => {
          return {
            display: generateHumanDisplayName(o.name[0]),
            identifier: o.id,
          };
        });
        setOptions(opts);
      }
    } catch (e) {
      setNotification({
        message: "Unable to search for practitioners. An error occured",
        variant: NotificationVariant.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(debounce(findPractitioners, 300), []);

  return (
    <Grid item xs={12} style={{ paddingTop: 10 }}>
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          loading={loading}
          fullWidth
          multiple
          autoHighlight
          disabled={disabled}
          key={`practitioner-lookup-${kebabCase(label)}`}
          renderOption={(props, option: any, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.display}
              </li>
            );
          }}
          onChange={(e, selected) => {
            if (selected && selected.length > 0) {
              if (multiple) {
                const refs = selected.map((s) => {
                  return createReference(
                    "Practitioner",
                    s.identifier,
                    s.display,
                  );
                });
                onChange(refs);
              } else {
                onChange([
                  createReference(
                    "Practitioner",
                    selected[0].identifier,
                    selected[0].display,
                  ),
                ]);
              }
            } else {
              onChange([]);
            }
          }}
          inputValue={inputValue}
          getOptionLabel={(option) => option.display}
          onInputChange={(event, newInput) => {
            setInputValue(newInput);
          }}
          isOptionEqualToValue={(option, value) =>
            isOptionEqualToValue
              ? isOptionEqualToValue(option, value)
              : option.identifier === value.identifier
          }
          options={options}
          value={value || []}
          renderInput={(params) => (
            <TextField {...params} label={label} maxRows={3} />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default PractitionerLookup;
