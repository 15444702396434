import { FC } from "react";
import { BloodPressureInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import {
  createBloodPressureComponent,
  getBloodPressureDisplay,
  updateMetaData,
} from "../VitalsFormHelper";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";
import { FormControl, Grid } from "@mui/material";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { createDefaultVital } from ".";
import useMedicalEncounter from "../../../../../../hooks/useMedicalEncounter";
import TextInputWithHints from "../../../../../../components/TextInputWithHints";
import { inputHintHelper } from "../../../../../../utils/inputHintHelpers";

const BloodPressureInput: FC<BloodPressureInputProps> = ({
  disabled,
  value,
  onChange,
  size,
  type,
  onBpInput,
}) => {
  const practitionerReference = usePractitioner();

  const { encounterReference, patientReference } = useMedicalEncounter();

  const validateInput = (text) => !text || /^[0-9/]*$/.test(text);
  const validateBp = (text) => !text || /\d+\/\d+/.test(text);

  const inputValue =
    type === "form" ? value ?? "" : value ? getBloodPressureDisplay(value) : "";

  const onChangeInput = (text) => {
    if (!validateInput) return;

    if (type === "form") {
      onBpInput(true);
      onChange(text);
      return;
    }

    const tempBase = {
      ...value,
      updated: true,
    };

    try {
      if (!text.includes("/")) {
        tempBase.component = null;
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };

        return;
      }

      const measurements = text.split("/");
      if (
        measurements.length === 2 &&
        measurements[0].length > 0 &&
        measurements[1].length > 0
      ) {
        const sys = createBloodPressureComponent(
          createCodeableConcept(
            CodingSystems.OBSERVATION_CODE,
            "8480-6",
            "Systolic blood pressure",
          ),
          Number(measurements[0]),
          "mmHg",
          "mmHg",
        );
        const dia = createBloodPressureComponent(
          createCodeableConcept(
            CodingSystems.OBSERVATION_CODE,
            "8462-4",
            "Diastolic blood pressure",
          ),
          Number(measurements[1]),
          "mmHg",
          "mmHg",
        );

        tempBase.component = [sys, dia];
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };

        return;
      } else {
        tempBase.component = null;
        tempBase.valueQuantity = {
          ...tempBase?.valueQuantity,
          value: text,
        };
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      updateMetaData(tempBase, practitionerReference);

      const defaultVital = createDefaultVital(
        encounterReference,
        patientReference,
        CodingSystems.OBSERVATION_CODE,
        "96607-7",
        "Average Blood Pressure",
        "mmHg",
      );

      const val = {
        ...defaultVital,
        ...tempBase,
        valueQuantity: {
          ...defaultVital.valueQuantity,
          ...tempBase?.valueQuantity,
        },
      };

      onChange(val);
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextInputWithHints
          id="vitals-blood-pressure"
          value={inputValue}
          fieldHintCallback={inputHintHelper.bp}
          textFieldProps={{
            disabled,
            autoComplete: "off",
            error: !validateBp(inputValue),
            inputProps: { maxLength: 7 },
            variant: "outlined",
            size: size ?? "medium",
            type: type ?? "modal",
            label: "Sys/Dia (mmHg)",
            onChange: (e) => onChangeInput(e.target.value),
          }}
        />
      </FormControl>
    </Grid>
  );
};

export default BloodPressureInput;
