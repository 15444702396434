import React, { FC, ReactElement } from "react";
import BaseForm, { BaseFormInterface } from "./BaseForm";

/**
 * This component is a wrapper for BaseForm that adds validation to the form.
 * It will only submit the form if requirements are met.
 * @param props  BaseFormInterface - see BaseForm.tsx
 * @param props.actions Needs to be wrapped in a `<></>` tag
 * @returns ReactElement
 * @constructor
 */

const BaseFormWithValidation: FC<BaseFormInterface> = (props) => {
  const { children, actions, ...rest } = props;

  const actionsButtons =
    actions &&
    React.Children.map(
      (actions as any).props.children,
      (child: ReactElement) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as ReactElement<any>, {
            onClick: () => handleFormSubmit((child.props as any).onClick),
          });
        }
        return child;
      },
    );

  const handleFormSubmit = (actionBtn) => {
    const baseForm = document.getElementById("baseForm") as HTMLFormElement;
    if (baseForm && baseForm.reportValidity()) {
      actionBtn();
    }
  };

  return (
    <form id="baseForm">
      <BaseForm {...rest} actions={actionsButtons}>
        {children}
      </BaseForm>
    </form>
  );
};

export default BaseFormWithValidation;
