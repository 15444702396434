import { Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import { FormProps } from "..";
import { medicalApiPaths, medicalsApi } from "../../../../../api/medicalsApi";
import ComponentLoader from "../../../../../components/ComponentLoader";
import {
  NotificationVariant,
  NotificationsContext,
} from "../../../../../components/dashboard/NotificationsProvider";
import CodingSystems from "../../../../../constants/CodingSystems";
import useComponentLoader from "../../../../../hooks/useComponentLoader";
import useMedicalEncounter from "../../../../../hooks/useMedicalEncounter";
import { useSaved } from "../../../../../hooks/useSaved";
import { useSelector } from "../../../../../store";
import { createEncounterParam } from "../../../../../utils/DataTransformationUtils";
import { createCodeableConcept } from "../../../../../utils/DataUtils";
import BaseFormWithValidation from "../../BaseFormWithValidation";
import ChiefComplaintFormBody from "./ChiefComplaintFormBody";

export const defaultChiefComplaint = {
  status: "preliminary",
  category: createCodeableConcept(
    CodingSystems.OBSERVATION_CATEGORY,
    "chief-complaint",
    "Chief Complaint",
  ),
  code: {
    coding: [
      {
        system: "http://loinc.org",
        code: "72166-2",
        display: "Chief complaint",
      },
    ],
  },
  note: [],
  component: {
    // code: createCodeableConcept(),
  },
};

export default function ChiefComplaintForm({
  closeForm,
  disabled,
  data,
}: FormProps) {
  const [loading, setLoading] = useComponentLoader();
  const [isDisabled] = useState(disabled);

  const { setNotification } = useContext(NotificationsContext);
  const { setSaved, requestClose } = useSaved(true, closeForm);

  const { patientReference, encounterReference } = useMedicalEncounter();

  const practitionerReference = useSelector(
    (state) => state.user.practitionerReference,
  );

  const [chiefComplaint, setChiefComplaint] = useState<Record<string, unknown>>(
    cloneDeep(data || defaultChiefComplaint),
  );

  useEffect(() => {
    const note = chiefComplaint?.note as Record<string, unknown>[];
    if (practitionerReference?.identifier && !note?.length) {
      setChiefComplaint({
        ...chiefComplaint,
        note: [{ author: practitionerReference }],
      });
    }
  }, [practitionerReference, chiefComplaint]);

  const resetForm = () => {
    requestClose();
    setChiefComplaint({});
  };

  const saveChiefComplaint = async () => {
    try {
      setLoading(true);

      const complaint = {
        ...chiefComplaint,
      };

      if (!chiefComplaint || !chiefComplaint.id) {
        const toSave = {
          ...complaint,
          subject: patientReference,
          encounter: encounterReference,
          performer: practitionerReference,
          id: undefined,
          _id: undefined,
        };
        const savedCondition = await medicalsApi.createEntity(
          toSave,
          medicalApiPaths.observations,
          createEncounterParam(encounterReference),
        );
        setChiefComplaint(savedCondition);
      } else {
        const updatedCondition = await medicalsApi.updateEntity(
          chiefComplaint.id as string,
          {
            ...complaint,
          },
          medicalApiPaths.observations,
          createEncounterParam(encounterReference),
        );
        setChiefComplaint(updatedCondition);
      }
      setNotification({
        variant: NotificationVariant.success,
        message: `The chief complaint was successfully saved.`,
      });

      setSaved(true);
    } catch (e) {
      console.error(
        `An error occured while saving the patient's chief complaint. Please try again.${e}`,
      );
      setNotification({
        variant: NotificationVariant.error,
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseFormWithValidation
      title="Chief Complaint · HPI · HPC"
      onClose={resetForm}
      isLoading={loading}
      disabled={isDisabled}
      actions={
        <>
          <Button
            variant="outlined"
            startIcon={<Save />}
            onClick={() => saveChiefComplaint()}
            style={{ marginRight: "10px" }}
            disabled={isDisabled || loading}
          >
            {chiefComplaint?.id ? "Update" : "Save"}
          </Button>
          <Button
            variant="contained"
            startIcon={<Save />}
            onClick={async () => {
              await saveChiefComplaint();
              closeForm();
            }}
            style={{ marginRight: "10px" }}
            disabled={isDisabled || loading}
          >
            {`${chiefComplaint?.id ? "Update" : "Save"} and Close `}
          </Button>
        </>
      }
    >
      <ChiefComplaintFormBody
        data={chiefComplaint}
        disabled={isDisabled}
        onChange={(value) => {
          setChiefComplaint(value);
          setSaved(false);
        }}
      />
      <ComponentLoader loading={loading} />
    </BaseFormWithValidation>
  );
}
