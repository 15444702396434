import { Calculate } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { FC } from "react";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import useMedicalEncounterModal from "../hooks/useMedicalEncounterModal";
import Plus from "../icons/Plus";
import { Roles } from "../pages/appointment/helpers/constants";
import { useSelector } from "../store";
import { isPremiumOrganization } from "../utils/DataUtils";
import useShortcuts from "./shortcuts/hooks/useShortcuts";

const ActionsMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { openMedicalEncounterModal } = useMedicalEncounterModal();
  const { setOpen } = useShortcuts();
  const { allowShortcutsMenu } = useFlags();
  const {
    newMedicalEncounterActionMenu,
    newAppointmentActionMenu,
    newPatientActionMenu,
    newBroadcastActionMenu,
  } = useFlags();
  const userRoles = useSelector((state) => state.user.roles);
  const currentOrg = useSelector((state) => state.user.currentOrganization);

  const open = Boolean(anchorEl);

  const handleOpen = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const openEncounterModal = () => {
    handleClose();
    openMedicalEncounterModal();
  };
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <>
      <Box display="flex" flexDirection="row">
        {allowShortcutsMenu && (
          <Tooltip title="Open calculators">
            <IconButton color="inherit" onClick={() => setOpen(true)}>
              <Calculate />
            </IconButton>
          </Tooltip>
        )}
        {(newMedicalEncounterActionMenu ||
          newAppointmentActionMenu ||
          newPatientActionMenu) && (
          <Tooltip title="Add New Item">
            <IconButton color="inherit" onClick={handleOpen}>
              <Plus fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {newPatientActionMenu && (
          <MenuItem
            onClick={() => {
              handleClose();
              window.open("/patients/new");
            }}
          >
            New Patient
          </MenuItem>
        )}
        {newAppointmentActionMenu && (
          <MenuItem
            onClick={() => {
              handleClose();
              window.open("/appointments/new");
            }}
          >
            New Appointment
          </MenuItem>
        )}
        {newBroadcastActionMenu &&
          userRoles?.includes(Roles.ADMINISTRATOR) &&
          isPremiumOrganization(currentOrg?.adminSettings) && (
            <MenuItem
              onClick={() => {
                handleClose();
                window.open("/communications/broadcast/new");
              }}
            >
              New Communication
            </MenuItem>
          )}
        {newMedicalEncounterActionMenu && (
          <MenuItem onClick={openEncounterModal}>
            New Medical Encounter
          </MenuItem>
        )}
      </Menu>
    </>
  ) : null;
};

export default ActionsMenu;

// Refactor those menu buttons, one on click func called GotoNewPage
//fix repetive menu code
