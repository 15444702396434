import { Code } from "../../types/code";

export const immunizationRoute: Code[] = [
  {
    code: "IDINJ",
    display: "Injection, intradermal",
    definition: "Injection, intradermal",
  },
  {
    code: "IM",
    display: "Injection, intramuscular",
    definition: "Injection, intramuscular",
  },
  {
    code: "NASINHLC",
    display: "Inhalation, nasal",
    definition: "Inhalation, nasal, prongs",
  },
  {
    code: "IVINJ",
    display: "Injection, intravenous",
    definition: "Injection, intravenous",
  },
  { code: "PO", display: "Swallow, oral", definition: "Swallow, oral" },
  {
    code: "SQ",
    display: "Injection, subcutaneous",
    definition: "Injection, subcutaneous",
  },
  { code: "TRNSDERM", display: "Transdermal", definition: "Transdermal" },
];

export const immunizationStatusReason: Code[] = [
  {
    code: "IMMUNE",
    display: "Immunity",
    definition:
      "Testing has shown that the patient already has immunity to the agent targeted by the immunization.",
  },
  {
    code: "MEDPREC",
    display: "Medical precaution",
    definition:
      "The patient currently has a medical condition for which the vaccine is contraindicated or for which precaution is warranted.",
  },
  {
    code: "OSTOCK",
    display: "Product out of stock",
    definition:
      "There was no supply of the product on hand to perform the service.",
  },
  {
    code: "PATOBJ",
    display: "Patient objection",
    definition:
      "The patient or their guardian objects to receiving the vaccine.",
  },
  {
    code: "310376006",
    display: "Immunization consent not given",
    definition: "",
  },
  {
    code: "171257003",
    display: "No consent - Tetanus/low dose diphtheria vaccine (finding)",
    definition: "",
  },
  {
    code: "171265000",
    display: "Pertussis vaccine refused",
    definition: "",
  },
  {
    code: "171266004",
    display: "No consent - diphtheria immunization",
    definition: "",
  },
  {
    code: "171267008",
    display: "No consent - tetanus immunization",
    definition: "",
  },
  {
    code: "171268003",
    display: "Polio immunization refused",
    definition: "",
  },
  {
    code: "171269006",
    display: "No consent - measles immunization",
    definition: "",
  },
  {
    code: "171270007",
    display: "No consent - rubella immunization",
    definition: "",
  },
  {
    code: "171271006",
    display: "No consent - BCG",
    definition: "",
  },
  {
    code: "171272004",
    display: "No consent - influenza immunization",
    definition: "",
  },
  {
    code: "171280006",
    display: "No consent for MMR",
    definition: "",
  },
  {
    code: "171283008",
    display: "No consent for any primary immunization (finding)",
    definition: "",
  },
  {
    code: "171285001",
    display: "No consent - pre-school vaccinations",
    definition: "",
  },
  {
    code: "171286000",
    display: "No consent - school exit vaccinations",
    definition: "",
  },
  {
    code: "171291004",
    display:
      "No consent - Haemophilus influenzae type B immunization (finding)",
    definition: "",
  },
  {
    code: "171292006",
    display: "No consent pneumococcal immunization",
    definition: "",
  },
  {
    code: "171293001",
    display: "No consent for MR - Measles/rubella vaccine",
    definition: "",
  },
  {
    code: "268559007",
    display: "No consent for any immunization",
    definition: "",
  },
  {
    code: "310839003",
    display: "No consent for MMR1",
    definition: "",
  },
  {
    code: "310840001",
    display: "No consent for measles/mumps/rubella two",
    definition: "",
  },
  {
    code: "314768003",
    display: "No consent DTP immunization",
    definition: "",
  },
  {
    code: "314769006",
    display: "No consent DT immunization",
    definition: "",
  },
  {
    code: "314936001",
    display: "No consent for meningitis C immunization",
    definition: "",
  },
  {
    code: "407598009",
    display: "No consent for 3rd HIB booster",
    definition: "",
  },
];

export const immunizationStatuses: Code[] = [
  { code: "completed", display: "Completed" },
  { code: "entered-in-error", display: "Entered in Error" },
  { code: "not-done", display: "Not Done" },
];

export const immunizationSubpotentReason: Code[] = [
  {
    code: "partial",
    display: "Partial Dose",
    definition:
      "The full volume of the dose was not administered to the patient.",
  },
  {
    code: "coldchainbreak",
    display: "Cold Chain Break",
    definition: "The vaccine experienced a cold chain break.",
  },
  {
    code: "recall",
    display: "Manufacturer Recall",
    definition: "The vaccine was recalled by the manufacturer.",
  },
];
