import { useFlags, withLDProvider } from "launchdarkly-react-client-sdk";
import { findIndex } from "lodash";
import axios from "axios";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { hotjar } from "react-hotjar";
import { useRoutes } from "react-router-dom";
import DuplicatePatientModal from "./components/DuplicatePatientModal";
import MedicalEncounterModal from "./components/MedicalEncounterModal";
import OrgSelectionModal from "./components/OrgSelectionModal";
import RTL from "./components/RTL";
import SettingsDrawer from "./components/SettingsDrawer";
import SplashScreen from "./components/SplashScreen";
import UserProfileModal from "./components/UserProfileModal";
import { hotjarConfig, ldConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useOrgSelectionModal from "./hooks/useOrgSelectionModal";
import useProfileModal from "./hooks/useProfileModal";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import "./i18n";
import routes from "./routes";
import {
  getUserProfile,
  getUserRoles,
  setUserOrganization,
  getCurrentOrganization,
} from "./slices/user";
import { useDispatch, useSelector } from "./store";
import { createCustomTheme } from "./theme";
import getUserId from "./utils/getUserId";
import ErrorBoundary from "./ErrorLoadingComponent";
import { ConfirmProvider } from "material-ui-confirm";
import useRoles from "./hooks/useRoles";
import useMedicalEncounterModal from "./hooks/useMedicalEncounterModal";
import { CssBaseline, ThemeProvider } from "@mui/material";
import useHealthChecks from "./hooks/useHealthChecks";
import useSocketIO from "./hooks/useSocketIO";
import { getMedicalConfig } from "./slices/configuration";

const App = withLDProvider({
  clientSideID: `${ldConfig.clientSideId}`,
  options: {
    bootstrap: "localStorage",
  },
})(() => {
  useHealthChecks();
  useSocketIO();
  const { settings } = useSettings();
  const { selectedOrganization } = settings;

  const auth = useAuth();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const launchDarklyFlags = useFlags();
  const currentOrg = useSelector((state) => state.user.currentOrganization);
  const userRoles = useRoles();
  const [matchedOrg, setMatchedOrg] = useState(false);
  const { isOpen: medicalEncounterModalIsOpen } = useMedicalEncounterModal();
  const { openUserProfileModal } = useProfileModal();
  const { openOrgSelectionModal, closeOrgSelectionModal } =
    useOrgSelectionModal();

  const [filteredRoutes, setFilteredRoutes] = useState(routes);
  const content = useRoutes(filteredRoutes);

  useScrollReset();

  useEffect(() => {
    if (launchDarklyFlags && currentOrg && userRoles) {
      //filter routes here based on flags, and org details and roles
      const filteredRoutes = routes;
      setFilteredRoutes(filteredRoutes);
    }
  }, [launchDarklyFlags, currentOrg, userRoles]);

  useEffect(() => {
    if (hotjarConfig.id && hotjarConfig.snippetVersion) {
      hotjar.initialize(hotjarConfig.id, hotjarConfig.snippetVersion);
    }
  }, []);

  useEffect(() => {
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      const { user } = auth;
      const userId = getUserId(user);
      dispatch(getUserProfile(userId));
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (profile) {
      if (!profile.name[0]?.firstName) {
        openUserProfileModal();
      }
    }
  }, [openUserProfileModal, profile]);

  useEffect(() => {
    const { organizations } = auth;
    if (organizations && selectedOrganization) {
      const index = findIndex(
        organizations,
        (o) => o.id === selectedOrganization.id,
      );
      const found = index > -1;
      if (!found) {
        setMatchedOrg(false);
        openOrgSelectionModal();
      } else {
        setMatchedOrg(true);
        axios.defaults.headers.common["organization"] =
          `Organization/${organizations[index].id}`;
        axios.defaults.headers.common["X-Organization-AuthId"] =
          organizations[index].authId;
        dispatch(setUserOrganization(organizations[index]));
        dispatch(getUserRoles(organizations[index].authId));
        dispatch(getCurrentOrganization(organizations[index].id));
        dispatch(getMedicalConfig(organizations[index].id));

        closeOrgSelectionModal();
      }
    } else {
      setMatchedOrg(false);
      openOrgSelectionModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, auth]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  //TODO: Replace null screen with a backsplash for medial health when no org is selected
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <RTL direction={settings.direction}>
            <CssBaseline />
            <Toaster position="top-center" />
            <SettingsDrawer />
            {medicalEncounterModalIsOpen && <MedicalEncounterModal />}
            <DuplicatePatientModal />
            <OrgSelectionModal />
            <UserProfileModal />

            {/* TODO: replace content with page asking for org */}
            {auth.isInitialized ? (
              matchedOrg ? (
                content
              ) : (
                content
              )
            ) : (
              <SplashScreen />
            )}
          </RTL>
        </ConfirmProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
});

export default App;
