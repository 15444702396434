import { createContext, useState, useCallback } from "react";

const defaultApi = {
  isOpen: false,
  // encounterId: string,
  openUserProfileModal: (): void => null,
  closeUserProfileModal: (): void => null,
};

export type UserProfileModalValue = typeof defaultApi;

export const UserProfileModalContext =
  createContext<UserProfileModalValue>(defaultApi);

export function UserProfileModalProvider({ children }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openUserProfileModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeUserProfileModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // Return Provider with full API
  const api = { isOpen, openUserProfileModal, closeUserProfileModal };
  return (
    <UserProfileModalContext.Provider value={api}>
      {children}
    </UserProfileModalContext.Provider>
  );
}
