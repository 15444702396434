import { useContext } from "react";
import { UserProfileModalContext } from "../contexts/UserProfileModalProvider";

const useProfileModal = () => {
  const { isOpen, openUserProfileModal, closeUserProfileModal } = useContext(
    UserProfileModalContext,
  );
  return { isOpen, openUserProfileModal, closeUserProfileModal };
};

export default useProfileModal;
