import { Code } from "../../types/code";

export const medicationStatusCodes: Code[] = [
  { display: "Active", code: "active" },
  { display: "Completed", code: "completed" },
  { display: "Entered in Error", code: "entered-in-error" },
  { display: "Stopped", code: "stopped" },
  { display: "On Hold", code: "on-hold" },
  { display: "Unknown", code: "unknown" },
  { display: "Not Taken", code: "not-taken" },
];

export const medicationReasonStatusCode: Code[] = [
  { code: "266710000", display: "Drugs not taken/completed" },
  { code: "182862001", display: "Drug not taken - dislike taste" },
  { code: "182863006", display: "Drug not taken - dislike form" },
  { code: "182864000", display: "Drug not taken - side-effects" },
  { code: "182865004", display: "Drug not taken - inconvenient" },
  {
    code: "182868002",
    display: "Treatment stopped - alternative therapy undertaken",
  },
  { code: "182869005", display: "Drug not taken - patient lost tablets" },
  { code: "182870006", display: "Drug discontinued - reason unknown" },
  { code: "182871005", display: "Drug discontinued - patient fear/risk" },
  { code: "182872003", display: "Drug discontinued - too expensive" },
  {
    code: "182873008",
    display: "Drug treatment stopped - patient ran out of tablets",
  },
  {
    code: "182874002",
    display: "Treatment stopped - patient unable to concentrate",
  },
  { code: "266711001", display: "Drug not taken - problem swallowing" },
  { code: "275929009", display: "Tablets too large to swallow" },
  { code: "182834008", display: "Drug course completed" },
  { code: "182840001", display: "Drug treatment stopped - medical advice" },
  { code: "182841002", display: "Doctor stopped drugs - ineffective" },
  { code: "182842009", display: "Doctor stopped drugs - side effect" },
  { code: "182843004", display: "Doctor stopped drugs -inconvenient" },
  { code: "182844005", display: "Doctor stopped drugs - patient dislikes" },
  { code: "182845006", display: "Doctor stopped drugs - avoid interaction" },
  { code: "182846007", display: "Doctor stopped drugs - medical aim achieved" },
  { code: "274512008", display: "Drug therapy discontinued" },
  { code: "395006008", display: "Medication stopped - interaction" },
  { code: "395007004", display: "Medication stopped - ineffective" },
  { code: "395008009", display: "Medication stopped - contraindication" },
  { code: "395009001", display: "Medication stopped - side effect" },
  { code: "408354007", display: "Drug treatment still needed" },
  { code: "408355008", display: "Drug treatment no longer needed" },
  { code: "710814002", display: "Long-term current use of drug therapy" },
  { code: "712985002", display: "Chemotherapy not done" },
  { code: "722480002", display: "Chemotherapy started (situation)" },
  { code: "70732231000132107", display: "Duplicate drug therapy stopped" },
];

export const medicationPriorityCodes: Code[] = [
  { code: "routine", display: "Routine" },
  { code: "urgent", display: "Urgent" },
  { code: "stat", display: "STAT" },
];

export const medicationRequestIntentCodes: Code[] = [
  { code: "proposal", display: "Proposal" },
  { code: "plan", display: "Plan" },
  { code: "order", display: "Order" },
  { code: "original-order", display: "Original Order" },
  { code: "reflex-order", display: "Reflex Order" },
  { code: "filler-order", display: "Filler Order" },
  { code: "instance-order", display: "Instance Order" },
  { code: "option", display: "Option" },
];

export const medicationRequestSubstitutionNotAllowedReasonCodes: Code[] = [
  { code: "ALGINT", display: "Allery intolerance" },
  { code: "COMPCON", display: "Compliance concern" },
  { code: "THERCHAR", display: "Therapeutic characteristics" },
  { code: "TRIAL", display: "Clinical trial drug" },
];

export const medicationRequestSubstitutionReasonCodes: Code[] = [
  { code: "CT", display: "Continuing therapy" },
  { code: "FP", display: "Formulary policy" },
  { code: "OS", display: "Out of stock" },
  { code: "RR", display: "Regulatory requirement" },
];
