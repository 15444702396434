import { Code } from "../../types/code";

export const sexualAttractionType: Code[] = [
  { code: "LA14686-2", display: "Only to females, never to males" },
  {
    code: "LA14687-0",
    display: "Mostly to females, and at least once to a male",
  },
  { code: "LA14688-8", display: "About equally often to females and to males" },
  {
    code: "LA14689-6",
    display: "Mostly to males, and at least once to a female",
  },
  { code: "LA14690-4", display: "Only to males, never to females" },
  {
    code: "LA14691-2",
    display: "I have never felt sexually attracted to anyone at all",
  },
];

export const sexualEncounterTime: Code[] = [
  { display: "In the last 7 days", code: "LA14692-0" },
  { display: "Between 7 days and 4 weeks ago", code: "LA14693-8" },
  { display: "Between 4 weeks and 6 months ago", code: "LA14694-6" },
  { display: "Between 6 months and 1 year ago", code: "LA14695-3" },
  { display: "Between 1 year and 5 years ago", code: "LA14696-1" },
  { display: "Longer than 5 years ago", code: "LA14697-9" },
];
export const stdStatus: Code[] = [
  { display: "Positive", code: "9ae46d75-e0db-4743-b1ce-e31a119ee94a" },
  { display: "Negative", code: "5dccfab0-7140-44d6-89b0-434d1b8fff13" },
  { display: "Unknown", code: "3c51c25c-cf16-4f0d-962d-1bd9d709e29d" },
];
export const condomUse: Code[] = [
  { display: "Always", code: "c62bbcc7-e550-4e35-a23e-78c294e7dcbf" },
  { display: "Sometimes", code: "834c3736-c874-4e98-b765-88eb93bd6c35" },
  { display: "Never", code: "494d8235-0e34-4473-96f8-9d5ff6e9f0d1" },
];
export const sexualPartners: Code[] = [
  { display: "Male", code: "80f8a99c-39fc-404c-92d9-d94092122278" },
  { display: "Female", code: "a29f603b-0801-4a92-86f4-dacb98d77f2b" },
  { display: "Both", code: "6da686f6-9453-4584-8a35-82a9452547b2" },
];
export const contraceptiveSatisfactionLevel: Code[] = [
  { display: "Satisfied", code: "151d540d-68cb-4a5f-a6b3-cb9094d501bd" },
  {
    display: "Somewhat satisfied",
    code: "b82a859b-0ffd-470e-977d-b53adc0cb8ac",
  },
  { display: "Not satisfied", code: "65952932-2f5b-4733-86d0-d5b7e1b44af2" },
];
export const sexualActivityType = [
  { display: "Vaginal", code: "befe7856-7c8c-455b-89e8-750ae0bee8cf" },
  { display: "Anal", code: "282bc722-6f32-4d18-ae7e-ad9ca821c35f" },
  { display: "Oral", code: "c60e3def-a0a6-46b3-9fd3-e3c17545d8f1" },
];
export const contraceptionMethods: Code[] = [
  { display: "Combined oral contraceptive pills", code: "LA27908-5" },
  { display: "Condom - Female", code: "LA27914-3" },
  { display: "Condom - Male", code: "LA27912-7" },
  { display: "Contraceptive gel", code: "LA33038-3" },
  { display: "Contraceptive patch", code: "LA16581-3" },
  { display: "Decline to answer", code: "LA27922-6" },
  { display: "Diaphragm or cervical cap", code: "LA27913-5" },
  { display: "Emergency contraception", code: "LA27921-8" },
  { display: "Female sterilization", code: "LA27905-1" },
  { display: "Fertility awareness-based methods", code: "LA27918-4" },
  { display: "Implantable rod", code: "LA27901-0" },
  { display: "Injectable", code: "LA27907-7" },
  { display: "IUD copper", code: "LA27903-6" },
  { display: "IUD unspecified", code: "LA27904-4" },
  { display: "IUD with Progestin", code: "LA27902-8" },
  { display: "Lactational amenorrhea method", code: "LA27919-2" },
  { display: "Male relying on female method", code: "LA27920-0" },
  { display: "Progestin only contraceptive pills", code: "LA27909-3" },
  { display: "Spermicide", code: "LA27916-8" },
  { display: "Sponge", code: "LA27917-6" },
  { display: "Vaginal ring", code: "LA27911-9" },
  { display: "Vasectomy", code: "LA14534-4" },
  { display: "Withdrawal", code: "LA14549-2" },
];
export const noUseOfContraceptionReasons: Code[] = [
  { display: "Abstinence", code: "LA14550-0" },
  { display: "Same sex partner", code: "LA27925-9" },
  { display: "Other", code: "LA46-8" },
  { display: "Sterile for non-contraceptive reasons", code: "LA27927-5" },
  { display: "Seeking pregnancy", code: "LA27928-3" },
];
export const pregnancyStatus: Code[] = [
  { display: "Pregnant", code: "LA15173-0" },
  { display: "Not Pregnant", code: "LA26683-5" },
  { display: "Unknown", code: "LA4489-6" },
];

export const mergedSexualOptions: Code[] = [
  ...pregnancyStatus,
  ...sexualAttractionType,
  ...sexualEncounterTime,
  ...stdStatus,
  ...condomUse,
  ...sexualPartners,
  ...contraceptiveSatisfactionLevel,
  ...sexualActivityType,
  ...contraceptionMethods,
  ...noUseOfContraceptionReasons,
];
