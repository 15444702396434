import { useState } from "react";
import { CalculatorOption } from "../types/calculatorOption";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

const majorOptions: CalculatorOption[] = [
  {
    display: "Acute pulmonary edema",
    result: 0,
    code: "acute-pulmonary-edema",
  },
  { display: "Cardiomegaly", result: 0, code: "cardiomegaly" },
  {
    display: "Neck vein distension",
    result: 0,
    code: "neck-vein-distension",
  },
  {
    display: "Paroxysmal nocturnal dyspnea or orthopnea",
    result: 0,
    code: "paroxysmal-nocturnal-dyspnea-or-orthopnea",
  },
  { display: "Pulmonary rales", result: 0, code: "pulmonary-rales" },
  {
    display: "Third heart sound (S3 gallop rhythm)",
    result: 0,
    code: "third-heart-sound",
  },
  {
    display: "Weight loss >4.5kg in 5 days in response to treatment",
    result: 0,
    code: "weight-loss",
  },
];
const minorOptions: CalculatorOption[] = [
  { display: "Ankle edema", result: 0, code: "ankle-edema" },
  { display: "Dyspnea on exertion", result: 0, code: "dyspnea-on-exertion" },
  { display: "Hepatomegaly", result: 0, code: "hepatomegaly" },
  { display: "Nocturnal cough", result: 0, code: "nocturnal-cough" },
  { display: "Pleural effusion", result: 0, code: "pleural-effusion" },
  { display: "Tachycardia (HR >120)", result: 0, code: "tachycardia" },
];

export default function FraminghamCHDRiskComponent() {
  const [values, setValues] = useState({
    major: 0,
    minor: 0,
  });

  const interpretResult = (major: number, minor: number) => {
    if (major >= 2) return true;
    if (major === 1 && minor === 2) return true;
    return false;
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  <b>Major Criteria</b>: 2+ required for positive diagnosis (or
                  1, plus 2 minor)
                </Typography>
              </Grid>
              {majorOptions.map((e) => {
                return (
                  <Grid item xs={12} key={e.code}>
                    <FormControlLabel
                      label={e.display}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            const checked = e.target.checked;
                            const value = values.major;
                            setValues({
                              ...values,
                              major: checked ? value + 1 : value - 1,
                            });
                          }}
                        />
                      }
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12} mt={4}>
                <Typography>
                  <b>Minor Criteria</b>: 2+ required for positive diagnosis (or
                  2 major)
                </Typography>
              </Grid>
            </Grid>
            {minorOptions.map((e) => {
              return (
                <Grid item xs={12} key={e.code}>
                  <FormControlLabel
                    label={e.display}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const value = values.minor;
                          setValues({
                            ...values,
                            minor: checked ? value + 1 : value - 1,
                          });
                        }}
                      />
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {interpretResult(values.major, values.minor) ? "Positive" : "Negative"}
      </Box>
    </Box>
  );
}
