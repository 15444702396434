import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { UnitOption } from "../types/unitOption";
import Choice from "../components/Choice";
import { CalculatorOption } from "../types/calculatorOption";
import { Optional } from "../types/optional";

const bsaBaseDoseUnits: UnitOption[] = [
  {
    unit: "mg/m²",
    converter(value) {
      return value;
    },
  },
  {
    unit: "g/m²",
    converter(value) {
      return value;
    },
  },
];
const weightUnits: UnitOption[] = [
  {
    unit: "kg",
    converter(value) {
      return value;
    },
  },
  {
    unit: "lb",
    converter(value) {
      return value / 2.205;
    },
  },
];
const heightUnits: UnitOption[] = [
  {
    unit: "cm",
    converter(value) {
      return value;
    },
  },
  {
    unit: "m",
    converter(value) {
      return value * 100;
    },
  },
  {
    unit: "in",
    converter(value) {
      return value * 2.54;
    },
  },
];
const methodOptions: CalculatorOption[] = [
  { display: "DuBois", code: "dubois", result: 0 },
  { display: "Monsteller", code: "monsteller", result: 0 },
];

export default function BodySurfaceAreaBasedDosingComponent() {
  const [values, setValues] = useState<{
    bsaBasedDose: { value: Optional<number>; unit: UnitOption };
    height: { value: Optional<number>; unit: UnitOption };
    weight: { value: Optional<number>; unit: UnitOption };
    method: Optional<CalculatorOption>;
  }>({
    bsaBasedDose: { value: undefined, unit: bsaBaseDoseUnits[0] },
    height: { value: undefined, unit: heightUnits[0] },
    weight: { value: undefined, unit: weightUnits[0] },
    method: undefined,
  });

  /**
   * @param {number} height patient height in centimeters (cm)
   * @param {number} weight weight in kilograms (kg)
   */
  const calculateMonstellerBSA = (height: number = 0, weight: number = 0) => {
    return Math.sqrt((height * weight) / 3600);
  };

  /**
   * @param {number} height patient height in centimeters (cm)
   * @param {number} weight weight in kilograms (kg)
   */
  const calculateDuBoisBSA = (height: number, weight: number) => {
    return 0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425);
  };

  const calculateDosing = (
    method?: string,
    bsaBasedDosing: number = 0,
    height: number = 0,
    weight: number = 0,
  ) => {
    try {
      if (!methodOptions.map((e) => e.code).includes(method)) {
        throw new Error("No method");
      }

      const bsa =
        method === "dubois"
          ? calculateDuBoisBSA(height, weight)
          : calculateMonstellerBSA(height, weight);

      return bsaBasedDosing * bsa;
    } catch (error) {
      return 0;
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="BSA based dose"
            placeholder="Enter BSA dose"
            endAdornment={
              <SelectButton
                label={values.bsaBasedDose.unit.unit}
                options={bsaBaseDoseUnits}
                variant="text"
                onSelect={(value): void => {
                  setValues({
                    ...values,
                    bsaBasedDose: {
                      ...values.bsaBasedDose,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                bsaBasedDose: {
                  ...values.bsaBasedDose,
                  value: float,
                },
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Height"
            placeholder="Enter height"
            endAdornment={
              <SelectButton
                variant="text"
                label={values.height.unit.unit}
                options={heightUnits}
                onSelect={(value) => {
                  setValues({
                    ...values,
                    bsaBasedDose: {
                      ...values.bsaBasedDose,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                height: {
                  ...values.height,
                  value: float,
                },
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Weight"
            placeholder="Enter weight"
            endAdornment={
              <SelectButton
                variant="text"
                options={weightUnits}
                label={values.weight.unit.unit}
                onSelect={(value) => {
                  setValues({
                    ...values,
                    weight: {
                      ...values.weight,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value: UnitOption) => value.unit}
              />
            }
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                weight: {
                  ...values.weight,
                  value: float,
                },
              });
            }}
          />

          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            width="100%"
          >
            <Choice
              group={"BSA Method"}
              value={values.method}
              options={methodOptions}
              onChange={(option: CalculatorOption) => {
                setValues({
                  ...values,
                  method: option,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { height, weight, bsaBasedDose, method } = values;
          const valid =
            method !== undefined &&
            [bsaBasedDose.value, height.value, weight.value].every((e) =>
              Number.isFinite(e),
            );

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const result = calculateDosing(
            method?.code,
            bsaBasedDose?.unit?.converter(bsaBasedDose.value ?? 0),
            height?.unit?.converter(height.value ?? 0),
            weight?.unit?.converter(weight.value ?? 0),
          );

          return (
            <Typography>
              Dosage: {result.toFixed(2)}{" "}
              {bsaBasedDose.unit.unit.split("/").at(0)}
            </Typography>
          );
        })()}
      </Box>
    </Box>
  );
}
