const regexp = {
  "non-negative decimal number or empty string": /^(?:\d+(\.\d*)?|\.\d+|)$/,
  "non-negative integer or empty string": /^(?:\d+|)$/,
} as const;

type RegExpKeys = keyof typeof regexp;

/** Creates a simple, human readable abstraction over common Regular Expressions */
export function testRegExp(key: RegExpKeys, value: string): boolean {
  return regexp[key].test(value);
}
