import { useContext } from "react";
import { OrgContactModalContext } from "../context/OrgContactModalContext";

export const useOrgContactModal = () => {
  const {
    isOpen,
    contact,
    contactsList,
    setContactsList,
    openOrgContactModal,
    closeOrgContactModal,
    setOrgContact,
    updateContact,
  } = useContext(OrgContactModalContext);
  return {
    isOpen,
    contact,
    contactsList,
    setContactsList,
    openOrgContactModal,
    closeOrgContactModal,
    setOrgContact,
    updateContact,
  };
};
