export const CONDITION_CATEGORY = {
  ENCOUNTER_DIAGNOSIS: "encounter-diagnosis",
  PROBLEM_LIST_ITEM: "problem-list-item",
};

export const ENCOUNTER_LABELS = {
  simple: { label: "Simple", color: "#70E3FD" },
  full: { label: "Detailed", color: "#E3FD70" },
  paper: { label: "Upload", color: "#FD70E3" }, // Triadic third color
};
