import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useKeyCombination } from "../../hooks/useKeyCombination";
import useScreenWidth from "../../hooks/useScreenWidth";
import useSettings from "../../hooks/useSettings";
import { createCustomTheme } from "../../theme";
import ShortcutDisplay from "./ShortcutDisplay";
import MoreInformationButton from "./components/MoreInformationButton";
import useShortcuts from "./hooks/useShortcuts";
import { shortcutOptions as _shortcutOptions } from "./options/shortcutOptions";

const shortcutOptions = Object.fromEntries(
  Object.entries(_shortcutOptions)
    .sort(([aK, aV], [bK, bV]) => aV.title.localeCompare(bV.title))
    .filter(([k, v]) => v.enabled),
);

function ShortcutsComponent({
  header,
  body,
}: {
  header: JSX.Element;
  body: JSX.Element;
}) {
  return (
    <>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        sx={{
          borderBottom: 1,
          borderColor: "#D3D3D3",
        }}
      >
        {header}
      </Box>
      <Box flex={1} overflow="hidden">
        {body}
      </Box>
    </>
  );
}

export default function ShortcutsModal() {
  const { settings } = useSettings();
  const { open, setOpen } = useShortcuts();
  const screenWidth = useScreenWidth();

  const [text, setText] = useState("");
  const [selectedKey, setSelectedKey] = useState<string>(undefined);

  useKeyCombination(
    () => setOpen(!open),
    ["Meta", "KeyM"],
    ["Meta", "KeyM"],
    ["Ctrl", "KeyM"],
    ["Ctrl", "KeyM"],
  );

  const filteredList = Object.keys(shortcutOptions).filter((e) => {
    const { title, whenToUse, function: fn } = shortcutOptions[e];
    return [
      title.toLowerCase(),
      whenToUse?.toLowerCase() ?? "",
      fn?.toLowerCase() ?? "",
    ].some((e) => e.includes(text.toLowerCase()));
  });

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={(e) => {
          setText("");
          setSelectedKey(undefined);
          setOpen(false);
        }}
      >
        <Box
          position="fixed"
          height="80%"
          top="50%"
          left="50%"
          width={screenWidth < 700 ? 400 : 700}
          bgcolor="background.default"
          boxShadow={24}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <ShortcutsComponent
            header={
              selectedKey ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <IconButton onClick={() => setSelectedKey(undefined)}>
                      <ArrowBackIos />
                    </IconButton>
                    <Typography variant="h5">
                      {shortcutOptions[selectedKey].title}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    {shortcutOptions[selectedKey].function && (
                      <MoreInformationButton label="Function" variant="text">
                        <div style={{ padding: "8px" }}>
                          {shortcutOptions[selectedKey].function}
                        </div>
                      </MoreInformationButton>
                    )}
                    {shortcutOptions[selectedKey].whenToUse && (
                      <MoreInformationButton label="When to use" variant="text">
                        <div style={{ padding: "8px" }}>
                          {shortcutOptions[selectedKey].whenToUse}
                        </div>
                      </MoreInformationButton>
                    )}
                  </Box>
                </Box>
              ) : (
                <TextField
                  id="shortcuts-modal-input"
                  fullWidth
                  value={text}
                  placeholder="Search for option"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ShortcutDisplay>Ctrl/⌘ + M</ShortcutDisplay>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                  onChange={(e) => setText(e.target.value)}
                />
              )
            }
            body={
              selectedKey ? (
                shortcutOptions[selectedKey].component
              ) : (
                <Box height="100%" overflow="auto">
                  <List>
                    {filteredList.map((e, i) => {
                      const { title, icon } = shortcutOptions[e];
                      return (
                        <ListItem key={e} disablePadding>
                          <ListItemButton onClick={() => setSelectedKey(e)}>
                            <ListItemText>{title}</ListItemText>
                            <ListItemIcon>
                              {icon ? icon : <ArrowForwardIos />}
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              )
            }
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
