import { useContext } from "react";
import { MedicalEncounterModalContext } from "../contexts/MedicalEncounterModalProvider";

const useMedicalEncounterModal = () => {
  const {
    isOpen,
    appointment,
    patient,
    openMedicalEncounterModal,
    closeMedicalEncounterModal,
    setEncounterAppointment,
    setEncounterPatient,
  } = useContext(MedicalEncounterModalContext);
  return {
    isOpen,
    patient,
    appointment,
    openMedicalEncounterModal,
    closeMedicalEncounterModal,
    setEncounterAppointment,
    setEncounterPatient,
  };
};

export default useMedicalEncounterModal;
