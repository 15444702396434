import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

export type THint = {
  message: string;
  color: string;
  bgcolor: string;
};

export function useFieldHints<T>(
  dep: T,
  callback: (value: T) => THint | undefined,
  launchDarklyFlag?: string,
) {
  const flags = useFlags();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [hasFocused, setHasFocused] = useState(false);
  const [hint, setHint] = useState<THint>();

  const enabled = launchDarklyFlag === undefined || flags[launchDarklyFlag];

  useEffect(() => {
    if (!enabled) return;

    try {
      const hint = callback(dep);

      if (hint && hasFocused) {
        setHint(hint);
        setOpen(true);
      } else {
        setOpen(false);
        setHint(undefined);
      }
    } catch (error) {
      console.error(error);
      setOpen(false);
    }
  }, [dep, flags]);

  return {
    enableFieldHints: enabled,
    anchorEl,
    setAnchorEl,
    open,
    setOpen,
    hasFocused,
    initialize: () => {
      const initial = callback(dep);
      if (initial) setHint(initial);

      setHasFocused(true);

      return initial;
    },
    hint,
    setHint,
  };
}
