import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import { FC, useEffect, useState } from "react";
import { EncounterNotesInputProps } from ".";
import TypeAheadInput from "../../../form/TypeAheadInput/TypeAheadInput";
import NoKnownInput from "./NoKnownInput";
import useAsyncEffect from "use-async-effect";
import { medicalsApi } from "../../../../api/medicalsApi";
import { createCodeableConcept } from "../../../../utils/DataUtils";
import CodingSystems from "../../../../constants/CodingSystems";

interface PatientMedicalHistoryInputProps extends EncounterNotesInputProps {
  setLoading?: (value: boolean) => void;
}

export function PatientMedicalHistoryInput(
  props: PatientMedicalHistoryInputProps,
) {
  useAsyncEffect(async () => {
    if (!props?.patient?.identifier) return;

    try {
      if (props.setLoading) props.setLoading(true);
      const conditions = await medicalsApi.findEntities(
        {
          patient: props.patient.identifier,
          clinicalStatus: ["active"],
        },
        "conditions",
      );

      const conditionCodes = conditions
        .filter((e) => e.code)
        .map((e) => e.code);

      props.onChange(conditionCodes, true);
    } catch (error) {
      console.error(error);
      props.onChange([], true);
    } finally {
      if (props.setLoading) props.setLoading(false);
    }
  }, [props.patient]);

  return <MedicalHistoryInput {...props} />;
}

const MedicalHistoryInput: FC<EncounterNotesInputProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const [noKnownMedicalHistory, setNoKnownMedicalHistory] = useState(false);
  useEffect(() => {
    setNoKnownMedicalHistory(
      value &&
        value.length === 1 &&
        value[0]?.coding?.some(
          (e) => e?.code === 160243008 || e?.code === "160243008",
        ),
    );
  }, [value]);

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={9}>
        {noKnownMedicalHistory ? (
          <Autocomplete
            disabled={true}
            multiple
            options={[
              createCodeableConcept(
                "http://snomed.info/sct",
                "160243008",
                "No medical history(Situation)",
              ),
            ]}
            value={[
              createCodeableConcept(
                "http://snomed.info/sct",
                "160243008",
                "No medical history(Situation)",
              ),
            ]}
            getOptionLabel={(option) => option.text}
            size={"small"}
            onChange={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Typography>
                    Personal Medical History (PMH){" "}
                    <span style={{ color: "red", fontSize: "1.5em" }}>*</span>
                  </Typography>
                }
                // required
                InputLabelProps={{ shrink: true, required: false }}
              />
            )}
          />
        ) : (
          <TypeAheadInput
            optionType="snomed"
            subset={["disorder", "finding", "event", "situation"]}
            isMultiple={true}
            size="small"
            value={value}
            disabled={disabled}
            onChange={(val) => {
              if (val) {
                const newValue = val.map((v) => {
                  if (!v.snomedCID || !v.snomedFSN) return v;

                  return createCodeableConcept(
                    CodingSystems.ENCOUNTER_REASON,
                    v.snomedCID,
                    v.snomedFSN,
                  );
                });

                onChange(newValue);
              } else {
                onChange(null);
              }
            }}
            fieldLabel={"Personal Medical History (PMH)"}
            getValueLabel={(v) => get(v, "text")}
            getOptionLabel={(o) => o?.snomedFSN}
            getOptionValue={(o) => o}
            freeOptions
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <NoKnownInput
          inputLabel="No Known PMHx"
          noKnownValue={noKnownMedicalHistory}
          setNoKnownValue={() => {}}
          disabled={disabled}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
          ) => {
            if (checked) {
              onChange([
                createCodeableConcept(
                  "http://snomed.info/sct",
                  "160243008",
                  "No medical history(Situation)",
                ),
              ]);
            } else {
              onChange([]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MedicalHistoryInput;
