import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import {
  pregnancyStatus,
  pregnancyHistory,
  gestationalAge,
  estimatedDueDate,
} from "./gynaecological";

import {
  sexualAttractionType,
  sexualEncounterTime,
  contraceptionMethods,
  sexualActivityType,
  noUseOfContraceptionReasons,
  contraceptiveSatisfactionLevel,
  sexualPartners,
  condomUse,
  stdStatus,
} from "./sexual";
import { alertReminderStatus, alertReminderCategory } from "./alert";
import { bodySite } from "./anatomy";
import { Specialty, AppointmentType, Priority, Status } from "./appointments";
import { attachmentsModality } from "./attachments";
import { ServiceCategory, ServiceType } from "./service";
import {
  allergyCategories,
  allergyIntoleranceTypes,
  allergyVerificationStatuses,
  allergyIntoleranceClinicalStatuses,
  allergyCriticalities,
  severityCodes,
} from "./allergy";
import {
  carePlanCategory,
  carePlanStatus,
  carePlanIntent,
  carePlanActivityType,
} from "./carePlan";
import {
  compositionStatus,
  medicalClinicCompositionTypes,
  compositionTypes,
} from "./composition";
import {
  conditionVerificationStatuses,
  conditionCategory,
  conditionClinicalStatuses,
} from "./condition";
import {
  encounterTriageTypes,
  encounterParticipantRole,
  EncounterStatus,
  EncounterClassification,
} from "./encounter";
import {
  familyMemberRelationships,
  familyHistoryRelationships,
  patientRelationships,
} from "./family";
import {
  genders,
  smokingSubstance,
  accommodationType,
  smokingStatus,
  countries,
  timeSpans,
  daysOfWeek,
  usStates,
  simpleStatus,
  languages,
  name,
} from "./general";
import {
  immunizationRoute,
  immunizationStatuses,
  immunizationStatusReason,
  immunizationSubpotentReason,
} from "./immunization";
import {
  medicationStatusCodes,
  medicationReasonStatusCode,
} from "./medication";
import {
  observationStatus,
  chiefComplaintStatus,
  alcoholFrequency,
  physicalActivityFrequency,
  alcoholicBeverageType,
  identifierUses,
  identifierTypes,
  ReasonCode,
  practiceCodes,
  contactPurpose,
} from "./misc";
import {
  obstetricInspectionAssessment,
  prenatalKetonesPhysicalAssessment,
  eyeOpeningScorePhysicalAssessment,
  motorScorePhysicalAssessment,
  verbalScorePhysicalAssessment,
  heartMurmurGradePhysicalAssessment,
  heartMurmurQualityPhysicalAssessment,
  heartMurmurPitchPhysicalAssessment,
  heartMurmurTimingPhysicalAssessment,
  heartMurmurLocationPhysicalAssessment,
  heartMurmurRadiatingPhysicalAssessment,
  generalPhysicalAssessment,
  skinPhysicalAssessment,
  facePhysicalAssessment,
  lymphaticPhysicalAssessment,
  headPhysicalAssessment,
  eyesPhysicalAssessment,
  earsPhysicalAssessment,
  nosePhysicalAssessment,
  oralCavityPhysicalAssessment,
  neckPhysicalAssessment,
  backPhysicalAssessment,
  chestPhysicalAssessment,
  heartMurmurPhysicalAssessment,
  abdomenPhysicalAssessment,
  umbilicalCordPhysicalAssessment,
  pelvisPhysicalAssessment,
  digitalRectalPhysicalAssessment,
  neurologicalPhysicalAssessment,
  respiratoryPhysicalAssessment,
  cardiovascularPhysicalAssessment,
  prostateExamPhysicalAssessment,
  maleGenitaliaPhysicalAssessment,
  femaleGenitaliaPhysicalAssessment,
  obstetricPhysicalAssessment,
  palpableFetalMovementsAssessment,
  fundalHeightGestationalAgeAssessment,
  palpationLieAssessment,
  palpationPresentationAssessment,
  palpationEngagementAssessment,
  musculoskeletalPhysicalAssessment,
  psychiatricPhysicalAssessment,
} from "./physicalAssessment";
import {
  temperatureUnit,
  bpMeasurementLocation,
  vitalsPulseMeasurement,
} from "./vitals";
import { organizationTypes } from "./organization";
import { bloodGroups } from "./blood";

export const initialState = {
  bloodGroups,
  gestationalAge,
  estimatedDueDate,
  stdStatus,
  condomUse,
  sexualPartners,
  contraceptionMethods,
  noUseOfContraceptionReasons,
  sexualEncounterTime,
  sexualActivityType,
  contraceptiveSatisfactionLevel,
  sexualAttractionType,
  observationStatus,
  chiefComplaintStatus,
  attachmentsModality,
  medicationStatusCodes,
  medicationReasonStatusCode,
  immunizationRoute,
  immunizationStatuses,
  immunizationStatusReason,
  immunizationSubpotentReason,
  pregnancyHistory,
  pregnancyStatus,
  bodySite,
  obstetricInspectionAssessment,
  prenatalKetonesPhysicalAssessment,
  eyeOpeningScorePhysicalAssessment,
  motorScorePhysicalAssessment,
  verbalScorePhysicalAssessment,
  heartMurmurGradePhysicalAssessment,
  heartMurmurQualityPhysicalAssessment,
  heartMurmurPitchPhysicalAssessment,
  heartMurmurTimingPhysicalAssessment,
  heartMurmurLocationPhysicalAssessment,
  heartMurmurRadiatingPhysicalAssessment,
  generalPhysicalAssessment,
  skinPhysicalAssessment,
  facePhysicalAssessment,
  lymphaticPhysicalAssessment,
  headPhysicalAssessment,
  eyesPhysicalAssessment,
  earsPhysicalAssessment,
  nosePhysicalAssessment,
  oralCavityPhysicalAssessment,
  neckPhysicalAssessment,
  backPhysicalAssessment,
  chestPhysicalAssessment,
  heartMurmurPhysicalAssessment,
  abdomenPhysicalAssessment,
  umbilicalCordPhysicalAssessment,
  pelvisPhysicalAssessment,
  digitalRectalPhysicalAssessment,
  neurologicalPhysicalAssessment,
  respiratoryPhysicalAssessment,
  cardiovascularPhysicalAssessment,
  prostateExamPhysicalAssessment,
  maleGenitaliaPhysicalAssessment,
  femaleGenitaliaPhysicalAssessment,
  obstetricPhysicalAssessment,
  palpableFetalMovementsAssessment,
  fundalHeightGestationalAgeAssessment,
  palpationLieAssessment,
  palpationPresentationAssessment,
  palpationEngagementAssessment,
  musculoskeletalPhysicalAssessment,
  psychiatricPhysicalAssessment,
  conditionVerificationStatuses,
  familyMemberRelationships,
  familyHistoryRelationships,
  gender: genders,
  severityCodes: severityCodes,
  conditionCategory,
  smokingSubstance,
  accommodationType,
  alcoholFrequency,
  temperatureUnit,
  smokingStatus,
  carePlanCategory,
  physicalActivityFrequency,
  carePlanStatus,
  bpMeasurementLocation,
  alcoholicBeverageType,
  carePlanIntent,
  carePlanActivityType,
  encounterTriageTypes,
  encounterParticipantRole,
  countries,
  vitalsPulseMeasurement,
  patientRelationships,
  EncounterStatus,
  conditionClinicalStatuses,
  timeSpans,
  daysOfWeek,
  identifierUses,
  identifierTypes,
  name,
  usStates,
  ServiceCategory,
  ServiceType,
  Specialty,
  AppointmentType,
  Priority,
  ReasonCode,
  EncounterClassification,
  Status,
  simpleStatus,
  practiceCodes,
  languages,
  alertReminderStatus,
  alertReminderCategory,
  organizationTypes,
  contactPurpose,
  compositionStatus,
  medicalClinicCompositionTypes,
  compositionTypes,
  allergyCategory: allergyCategories,
  allergyIntoleranceType: allergyIntoleranceTypes,
  allergyVerificationStatus: allergyVerificationStatuses,
  allergyIntoleranceClinicalStatus: allergyIntoleranceClinicalStatuses,
  allergyCriticality: allergyCriticalities,
};

const slice = createSlice({
  name: "options",
  initialState,
  reducers: {
    getIdentifierUses(
      state: typeof initialState,
      action: PayloadAction<any>,
    ): void {
      state.identifierUses = action.payload;
    },
    setIdentifierUses(
      state: typeof initialState,
      action: PayloadAction<any>,
    ): void {
      state.identifierUses = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getEvents = (): AppThunk => async (): Promise<void> => {};
export const createEvent = (): AppThunk => async (): Promise<void> => {};
export const sortOptionByDisplay = (a, b) => (a.display > b.display ? 1 : -1);

export default slice;
