/* eslint-disable no-restricted-properties */

import { daysBetween } from "rrule/dist/esm/dateutil";
import { Organization } from "../types/organization";

const calculateTrialDaysRemaining = (currentOrganization: Organization) => {
  if (!currentOrganization?.adminSettings?.trialEndDate) {
    return null;
  }

  const trialEndDate = new Date(currentOrganization.adminSettings.trialEndDate);
  if (Number.isNaN(trialEndDate?.getTime())) {
    return null;
  }

  const today = new Date();
  const daysRemaining = daysBetween(trialEndDate, today);

  return daysRemaining >= 0 ? daysRemaining : -1;
};

export default calculateTrialDaysRemaining;
