import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import { Optional } from "../types/optional";

export default function BaseExcessComponent() {
  const [values, setValues] = useState<{
    pco2: Optional<number>;
    ph: Optional<number>;
  }>({
    pco2: undefined,
    ph: undefined,
  });

  const calculateBaseExcess = (pco2: number = 0, ph: number = 0) => {
    return 0.02786 * pco2 * Math.pow(10, ph - 6.1) + 13.77 * ph - 124.58;
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="PCO2"
            placeholder="Enter PCO2"
            endAdornment={<Typography>mmHg</Typography>}
            onChange={(e) => {
              const value = e.target.value;
              const float = parseFloat(value);
              setValues({
                ...values,
                pco2: float,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="pH"
            placeholder="Enter pH"
            endAdornment={<Typography>pH</Typography>}
            onChange={(e) => {
              const value = e.target.value;
              const float = parseInt(value);
              setValues({
                ...values,
                ph: float,
              });
            }}
          />
        </Grid>
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { pco2, ph } = values;
          const valid = [pco2, ph].every((e) => Number.isFinite(e));

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const baseExcess = calculateBaseExcess(pco2, ph);

          return (
            <Typography>
              <b>Base Excess</b>: {baseExcess.toFixed(2)} meq/L
            </Typography>
          );
        })()}
      </Box>
    </Box>
  );
}
