import { Code } from "../../types/code";

export const attachmentsModality: Code[] = [
  {
    code: "BMD",
    display: "Bone Mineral Densitometry",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "EOG",
    display: "Electrooculography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "SM",
    display: "Slide Microscopy",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "LAB",
    display: "Lab Result",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OP",
    display: "Ophthalmic Photography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "ECG",
    display: "Electrocardiography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "GM",
    display: "General Microscopy",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "XA",
    display: "X-Ray Angiography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "XC",
    display: "External-camera Photography	",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "DMS",
    display: "Dermoscopy",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "IVUS",
    display: "Intravascular Ultrasound",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "CR",
    display: "Computed Radiography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "CT",
    display: "Computed Tomography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OSS",
    display: "Optical Surface Scanner",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "TG",
    display: "Thermography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "LEN",
    display: "Lensometry",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OPTENF",
    display: "Ophthalmic Tomography En Face",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "HD",
    display: "Hemodynamic Waveform",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OCT",
    display: "Optical Coherence Tomography	",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "BDUS",
    display: "Ultrasound Bone Densitometry",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "DG",
    display: "Diaphanography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "PT",
    display: "Positron emission tomography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "EPS",
    display: "Cardiac Electrophysiology",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "LS",
    display: "Laser Scan",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "PX",
    display: "Panoramic X-Ray",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OPM",
    display: "Ophthalmic Mapping",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OPTBSV",
    display: "Ophthalmic Tomography B-scan Volume Analysis",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OPV",
    display: "Ophthalmic Visual Field",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "DX",
    display: "Digital Radiography/X-Ray",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OPT",
    display: "Ophthalmic Tomography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "MG",
    display: "Mammography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "US",
    display: "Ultrasound",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "EMG",
    display: "Electromyography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "IVOCT",
    display: "Intravascular Optical Coherence Tomography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "MR",
    display: "Magnetic Resonance",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "IO",
    display: "Intra-oral Radiography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "EEG",
    display: "Electroencephalography",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "RTIMAGE",
    display: "RT Image",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "VA",
    display: "Visual Acuity",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "RESP",
    display: "Respiratory Waveform",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "ES",
    display: "Endoscopy",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "AR",
    display: "Autorefraction",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "POS",
    display: "Position Sensor",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "RG",
    display: "Radiographic imaging",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "RF",
    display: "Radiofluoroscopy",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "KER",
    display: "Keratometry",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "OAM",
    display: "Ophthalmic Axial Measurements",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "NM",
    display: "Nuclear Medicine",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "BI",
    display: "Biomagnetic Imaging",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "SRF",
    display: "Subjective Refraction",
    system: "http://dicom.nema.org/resources/ontology/DCM",
  },
  {
    code: "diagram",
    display: "Diagram",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "fax",
    display: "Fax",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "scan",
    display: "Scanned Document",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "retina",
    display: "Retina Scan",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "fingerprint",
    display: "Fingerprint",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "iris",
    display: "Iris Scan",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "palm",
    display: "Palm Scan",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
  {
    code: "face",
    display: "Face Scan",
    system: "http://terminology.hl7.org/CodeSystem/media-modality",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});
