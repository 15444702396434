import { FormControl, Grid, TextField } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import { get } from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers";
import DictationControl from "../dictation/DictationControl";
import NotesInput from "../form/NotesInput";

const PaperEncounterInput = ({
  actualPeriod,
  setActualPeriod,
  chiefComplaint,
  setChiefComplaint,
  setSaved,
  subject,
  setUploadedFiles,
  uploadedFiles,
}) => {
  return (
    <Grid container spacing={3} xs={12} md={12}>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          renderInput={(props) => (
            <TextField
              required
              fullWidth
              {...props}
              InputLabelProps={{ shrink: true }}
              helperText={null}
            />
          )}
          maxDateTime={new Date()}
          label="Encounter Date"
          value={actualPeriod.start}
          onChange={(date) => {
            setActualPeriod({
              ...actualPeriod,
              start: date,
            });
          }}
          inputFormat="dd/MM/yyyy"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <DictationControl
            value={get(chiefComplaint, "summary") ?? ""}
            onTextChange={(value) => {
              setChiefComplaint({
                ...chiefComplaint,
                summary: value,
              });
              setSaved(false);
            }}
            id="encounter-chief-complaint-summary"
            label="Chief Complaint"
            placeholder="Summary of the complaint"
            required
            disabled={!subject?.identifier}
            minRows={1}
            maxRows={2}
            style={{ width: "100%" }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <NotesInput
            value={chiefComplaint?.note}
            label={"Encounter Notes"}
            onChange={(notes) => {
              setChiefComplaint({
                ...chiefComplaint,
                note: notes,
              });
              setSaved(false);
            }}
            placeholderText="Please add as much information as possible about the paper encounter being uploaded."
            disabled={!subject?.identifier}
            minRows={3}
            maxNotes={1}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <DropzoneArea
          onChange={(loadedFiles) => {
            console.log(loadedFiles);
            setUploadedFiles(loadedFiles);

            // Component calls onChange when mounting so this
            // simply protects saved against calls on mount
            if (loadedFiles.length !== uploadedFiles.length) {
              setSaved(false);
            }
          }}
          // 10mb
          maxFileSize={10485760}
        />
      </Grid>
    </Grid>
  );
};

export default PaperEncounterInput;
