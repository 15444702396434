import { createContext, useState } from "react";

const defaultApi = {
  practitioners: [],
  services: [],
  setPractitioners: (e: any[]) => null,
  setServices: (e: any) => null,
  reset: () => null,
};

export type AppointmentDashboardType = typeof defaultApi;

export const AppointmentDashboardContext =
  createContext<AppointmentDashboardType>(defaultApi);

export const AppointmentDashboardProvider = ({ children }: any) => {
  const [practitioners, setPractitioners] = useState<any[]>([]);
  const [services, setServices] = useState<any>([]);

  const reset = () => {
    setPractitioners([]);
    setServices([]);
  };

  // Return Provider with full API
  const api: AppointmentDashboardType = {
    practitioners,
    services,
    setPractitioners,
    setServices,
    reset,
  };

  return (
    <AppointmentDashboardContext.Provider value={api}>
      {children}
    </AppointmentDashboardContext.Provider>
  );
};
