import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import { get, set } from "lodash";
import { createDefaultVital } from ".";
import CodingSystems from "../../../../../../constants/CodingSystems";

import { getSimpleVitalValue, updateMetaData } from "../VitalsFormHelper";
import usePractitioner from "../../../../../../hooks/usePractitioner";

const HeartRateInout: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = usePractitioner();
  const [heartRate, setHeartRate] = useState<any>();

  useEffect(() => {
    if (value) {
      setHeartRate(value);
    } else {
      const hr = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "8867-4",
        "Heart Rate",
        "/min",
      );
      setHeartRate(hr);
    }
  }, [encounter, patient, value]);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          id="vitals-bpm"
          key={"vital-weight-input"}
          value={getSimpleVitalValue(heartRate) || ""}
          disabled={disabled}
          autoComplete="off"
          onChange={(e) => {
            let val;
            if (e.target.value) {
              val = Number(e.target.value);
            } else {
              val = null;
            }
            const newHeartRate = { ...heartRate };
            set(newHeartRate, "valueQuantity.value", val);
            updateMetaData(newHeartRate, practitionerReference);
            onChange(newHeartRate);
          }}
          label={size === "small" ? `HR` : `Heart Rate `}
          inputProps={{ inputProps: { type: "number" } }}
          InputProps={{
            endAdornment: (
              <Typography pl={1} variant="body2">
                {get(heartRate, "valueQuantity.unit")}
              </Typography>
            ),
          }}
          variant="outlined"
          size={size || "medium"}
        />
      </FormControl>
    </Grid>
  );
};

export default HeartRateInout;
