import { CarePlanActivityDetailKind } from "../../pages/health/encounters/types/carePlanActivity";

export const carePlanCategory = [
  { code: "718347000", display: "Mental health care plan" },
  { code: "735321000", display: "Surgical inpatient care plan" },
  {
    code: "735322007",
    display: "Termination of pregnancy inpatient care plan",
  },
  { code: "735323002", display: "Thoracic surgery inpatient care plan" },
  { code: "735324008", display: "Treatment escalation plan" },
  { code: "735325009", display: "Unplanned inpatient care plan" },
  {
    code: "735326005",
    display: "Upper gastrointestinal surgery inpatient care plan",
  },
  {
    code: "735327001",
    display: "Upper gastrointestinal tract endoscopy care plan",
  },
  { code: "735328006", display: "Urological surgery inpatient care plan" },
  { code: "735329003", display: "Vascular surgery inpatient care plan" },
  { code: "735330008", display: "Vulnerable adult care plan" },
  { code: "735984001", display: "Heart failure self management plan" },
  { code: "735985000", display: "Diabetes self management plan" },
  { code: "735986004", display: "Patient written birth plan" },
  { code: "736054002", display: "Pressure ulcer care plan" },
  { code: "736055001", display: "Rehabilitation care plan" },
  { code: "736056000", display: "Asthma clinical management plan" },
  { code: "736057009", display: "Respiratory disorder inpatient care plan" },
  { code: "736058004", display: "Mental health personal health plan" },
  { code: "736059007", display: "Angina self management plan" },
  { code: "736234007", display: "Pediatric community care plan" },
  { code: "736235008", display: "Pediatric inpatient care plan" },
  { code: "736241001", display: "Pediatric outpatient care plan" },
  { code: "736245005", display: "Pediatric community complex care plan" },
  { code: "736246006", display: "Pediatric surgery inpatient care plan" },
  { code: "736248007", display: "Pain control care plan" },
  { code: "736249004", display: "Radiotherapy care plan" },
  { code: "736250004", display: "Respite care plan" },
  { code: "736251000", display: "Stroke care plan" },
  { code: "736252007", display: "Cancer care plan" },
  { code: "736253002", display: "Mental health crisis plan" },
  { code: "736254008", display: "Psychiatry care plan" },
  { code: "736271009", display: "Outpatient care plan" },
  { code: "736282001", display: "Neonatal critical care plan" },
  {
    code: "736283006",
    display: "Chronic obstructive pulmonary disease clinical management plan",
  },
  { code: "736284000", display: "Diabetes clinical management plan" },
  { code: "736285004", display: "Hyperlipidemia clinical management plan" },
  { code: "736286003", display: "Hypertension clinical management plan" },
  { code: "736287007", display: "Hypothyroidism clinical management plan" },
  {
    code: "736288002",
    display: "Transient ischemic attack clinical management plan",
  },
  { code: "736336006", display: "Miscarriage inpatient care plan" },
  { code: "736337002", display: "Neurosurgery inpatient care plan" },
  { code: "736338007", display: "Ophthalmic surgery inpatient care plan" },
  { code: "736339004", display: "Orthopedic surgery inpatient care plan" },
  {
    code: "736340002",
    display: "Ovarian hyperstimulation syndrome inpatient care plan",
  },
  { code: "736351002", display: "Breast surgery inpatient care plan" },
  { code: "736352009", display: "Cardiac surgery inpatient care plan" },
  { code: "736353004", display: "Inpatient care plan" },
  {
    code: "736355006",
    display: "Gynecology bladder training inpatient care plan",
  },
  { code: "736356007", display: "Gynecology inpatient care plan" },
  {
    code: "736357003",
    display: "Minor gynecology surgery inpatient care plan",
  },
  {
    code: "736358008",
    display: "Minor ophthalmic surgery inpatient care plan",
  },
  { code: "736359000", display: "Minor surgery inpatient care plan" },
  { code: "736361009", display: "Minor thoracic surgery inpatient care plan" },
  { code: "736362002", display: "Minor urologic surgery inpatient care plan" },
  { code: "736363007", display: "Minor plastic surgery inpatient care plan" },
  {
    code: "736364001",
    display: "Minor orthopedic surgery inpatient care plan",
  },
  {
    code: "736365000",
    display:
      "Acute exacerbation of chronic obstructive pulmonary disease care plan",
  },
  { code: "736366004", display: "Advance care plan" },
  { code: "736367008", display: "Anticipatory care plan" },
  { code: "736368003", display: "Coronary heart disease care plan" },
  { code: "736369006", display: "Day case care plan" },
  { code: "736370007", display: "Day case surgery care plan" },
  { code: "736371006", display: "Dementia care plan" },
  { code: "736372004", display: "Discharge care plan" },
  { code: "736373009", display: "End of life care plan" },
  { code: "736374003", display: "Gynecology unplanned inpatient care plan" },
  { code: "736375002", display: "Hemodialysis care plan" },
  { code: "736376001", display: "Infectious disease care plan" },
  { code: "736377005", display: "Maternity care plan" },
  { code: "736378000", display: "Medication management plan" },
  { code: "736379008", display: "Miscarriage care plan" },
  { code: "736381005", display: "Major surgery inpatient care plan" },
  {
    code: "736382003",
    display: "Major head and neck surgery inpatient care plan",
  },
  { code: "736383008", display: "Major neurosurgery inpatient care plan" },
  {
    code: "736384002",
    display: "Major orthopedic surgery inpatient care plan",
  },
  { code: "736385001", display: "Major plastic surgery inpatient care plan" },
  { code: "736386000", display: "Major vascular surgery inpatient care plan" },
  { code: "736387009", display: "Major urologic surgery inpatient care plan" },
  { code: "736389007", display: "Colorectal surgery inpatient care plan" },
  {
    code: "736390003",
    display: "Gynecology major surgery inpatient care plan",
  },
  {
    code: "736391004",
    display: "Hepatobiliary and pancreatic surgery inpatient care plan",
  },
  { code: "736392006", display: "Bronchoscopy care plan" },
  { code: "736393001", display: "Colposcopy care plan" },
  { code: "736394007", display: "Cystoscopy care plan" },
  { code: "736395008", display: "Hysteroscopy care plan" },
  {
    code: "736396009",
    display: "Interventional cardiology inpatient care plan",
  },
  {
    code: "736397000",
    display: "Lower gastrointestinal tract endoscopy care plan",
  },
  { code: "736399002", display: "Burn inpatient care plan" },
  { code: "736400009", display: "Cardiology inpatient care plan" },
  { code: "736401008", display: "Chemotherapy care plan" },
  { code: "736402001", display: "Hip fracture inpatient care plan" },
  {
    code: "736403006",
    display: "Hyperemesis in pregnancy inpatient care plan",
  },
  { code: "736453000", display: "Laryngectomy surgery inpatient care plan" },
  { code: "736482004", display: "Major burn inpatient care plan" },
  { code: "736690008", display: "Dialysis care plan" },
  { code: "736990006", display: "Plastic surgery inpatient care plan" },
  {
    code: "737426005",
    display: "Ankle brachial pressure index management plan",
  },
  { code: "737427001", display: "Clinical management plan" },
  { code: "737428006", display: "Arthritis clinical management plan" },
  {
    code: "737429003",
    display: "Chronic kidney disease clinical management plan",
  },
  {
    code: "737430008",
    display: "Congestive heart failure clinical management plan",
  },
  {
    code: "737431007",
    display: "Coronary heart disease risk clinical management plan",
  },
  { code: "737432000", display: "Hyperglycemia clinical management plan" },
  { code: "737433005", display: "Hypoglycemia clinical management plan" },
  {
    code: "737434004",
    display: "Major depressive disorder clinical management plan",
  },
  { code: "737435003", display: "Malnutrition clinical management plan" },
  { code: "737436002", display: "Hypoglycemia self management plan" },
  { code: "737437006", display: "Hyperglycemia self management plan" },
  {
    code: "737438001",
    display: "Myocardial infarction clinical management plan",
  },
  { code: "737439009", display: "Seizure disorder clinical management plan" },
  { code: "744980002", display: "Medical critical care plan" },
  {
    code: "763249000",
    display: "Mental health care program approach contingency plan",
  },
  {
    code: "763321009",
    display: "Mental health care program approach crisis plan",
  },
  { code: "770576004", display: "Prevention of delirium care plan" },
  { code: "771082000", display: "Acute medicine care plan" },
  { code: "773130005", display: "Nursing care plan" },
  { code: "773430001", display: "Obstetric care plan" },
  { code: "773431002", display: "Obstetric perinatal care plan" },
  { code: "773432009", display: "Obstetric postnatal care plan" },
  { code: "773433004", display: "Obstetric antenatal care plan" },
  { code: "773435006", display: "Surgical care plan" },
  { code: "773436007", display: "Plastic surgery care plan" },
  { code: "773437003", display: "Trauma surgery care plan" },
  { code: "773438008", display: "Oral surgery care plan" },
  { code: "773439000", display: "Hepatobiliary surgical care plan" },
  { code: "773440003", display: "Dermatological surgery care plan" },
  { code: "773441004", display: "Hand surgery care plan" },
  { code: "773442006", display: "Podiatric surgery care plan" },
  { code: "773443001", display: "Ophthalmic surgery care plan" },
  { code: "773444007", display: "Pancreatic surgery care plan" },
  { code: "773445008", display: "Gastrointestinal surgery care plan" },
  { code: "773446009", display: "Rehabilitation psychiatry care plan" },
  { code: "773447000", display: "Forensic psychiatry care plan" },
  { code: "773448005", display: "Psychiatric intensive care plan" },
  { code: "773449002", display: "Old age psychiatry care plan" },
  { code: "773450002", display: "Liaison psychiatry care plan" },
  { code: "773451003", display: "Psychotherapy care plan" },
  { code: "773452005", display: "Psychology care plan" },
  { code: "773506001", display: "Gynecology care plan" },
  { code: "773507005", display: "Gynecology oncology care plan" },
  { code: "773508000", display: "Interventional radiology care plan" },
  { code: "773509008", display: "Ophthalmology care plan" },
  { code: "773511004", display: "Occupational therapy care plan" },
  { code: "773512006", display: "Genitourinary medicine care plan" },
  { code: "773513001", display: "Physiotherapy care plan" },
  { code: "773514007", display: "Speech and language therapy care plan" },
  { code: "773516009", display: "Head injury rehabilitation care plan" },
  { code: "773517000", display: "Osteopathic manipulative medicine care plan" },
  { code: "773590002", display: "Dietetic care plan" },
  { code: "773591003", display: "Podiatry care plan" },
  { code: "773592005", display: "Occupational health care plan" },
  { code: "773593000", display: "Urology care plan" },
  { code: "773594006", display: "Orthodontic care plan" },
  { code: "773595007", display: "Respiratory medicine care plan" },
  { code: "773596008", display: "Care of elderly care plan" },
  { code: "773597004", display: "Orthopedic care plan" },
  { code: "773598009", display: "Gastroenterology care plan" },
  { code: "773599001", display: "Hemophilia care plan" },
  { code: "773976008", display: "Dermatology care plan" },
  { code: "773977004", display: "Hepatology care plan" },
  { code: "773978009", display: "Endocrinology care plan" },
  { code: "773979001", display: "Prosthetic care plan" },
  { code: "773980003", display: "Orthotic care plan" },
  { code: "773981004", display: "Palliative care plan" },
  { code: "773982006", display: "Rheumatology care plan" },
  { code: "773983001", display: "ENT (ear, nose, throat) care plan" },
  { code: "774202005", display: "Special care baby care plan" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const defaultCarePlanStatuses = [
  { code: "draft", display: "Draft" },
  { code: "active", display: "Active" },
  { code: "on-hold", display: "On Hold" },
];

export const carePlanIntent = [
  { code: "proposal", display: "Proposal" },
  { code: "plan", display: "Plan" },
  { code: "order", display: "Order" },
  { code: "option", display: "Option" },
];

export const carePlanStatus = [
  { code: "draft", display: "Draft" },
  { code: "active", display: "Active" },
  { code: "on-hold", display: "On Hold" },
  { code: "revoked", display: "Revoked" },
  { code: "completed", display: "Completed" },
  { code: "entered-in-error", display: "Entered in Error" },
  { code: "unknown", display: "Unknown" },
];

export const carePlanActivityType: {
  code: CarePlanActivityDetailKind;
  display: string;
  definition: string;
}[] = [
  // {
  //   code: "CommunicationRequest",
  //   display: "Communication Request",
  //   definition:
  //     "A request to convey information; e.g. the CDS system proposes that an alert be sent to a responsible provider, the CDS system proposes that the public health agency be notified about a reportable condition.",
  // },
  // {
  //   code: "DeviceRequest",
  //   display: "Device Request",
  //   definition:
  //     "Represents a request for a patient to employ a medical device. The device may be an implantable device, or an external assistive device, such as a walker.",
  // },
  {
    code: "MedicationRequest",
    display: "Prescription",
    definition:
      'An order or request for both supply of the medication and the instructions for administration of the medication to a patient. The resource is called "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to generalize the use across inpatient and outpatient settings, including care plans, etc., and to harmonize with workflow patterns.',
  },
  {
    code: "Appointment",
    display: "Follow Up",
    definition:
      "A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s).",
  },
  // {
  //   code: "NutritionOrder",
  //   display: "Nutrition Order",
  //   definition:
  //     "A request to supply a diet, formula feeding (enteral) or oral nutritional supplement to a patient/resident.",
  // },
  // {
  //   code: "Task",
  //   display: "Task",
  //   definition: "A task to be performed.",
  // },
  // {
  //   code: "ServiceRequest",
  //   display: "Referral",
  //   definition:
  //     "A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.",
  // },
  // {
  //   code: "VisionPrescription",
  //   display: "Vision Prescription",
  //   definition:
  //     "An authorization for the provision of glasses and/or contact lenses to a patient.",
  // },
];

export const carePlanActivityStatus = [
  {
    code: "not-started",
    display: "Not Started",
  },
  {
    code: "scheduled",
    display: "Scheduled",
  },
  {
    code: "in-progress",
    display: "In Progress",
  },
  {
    code: "on-hold",
    display: "On Hold",
  },
  {
    code: "completed",
    display: "Completed",
  },
  {
    code: "cancelled",
    display: "Cancelled",
  },
  {
    code: "stopped",
    display: "Stopped",
  },
  {
    code: "unknown",
    display: "Unknown",
  },
  {
    code: "entered-in-error",
    display: "Entered in Error",
  },
];
