import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Right lower quadrant tenderness",
    code: "right-lower-quadrant-tenderness",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Elevated temperature",
    subtitle: "(37.3°C or 99.1°F)",
    code: "temp",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Rebound tenderness",
    code: "rebound-tenderness",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },

  {
    title: "Migration of pain to the right lower quadrant",
    code: "migration-to-lower-quadrant",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Anorexia",
    code: "anorexia",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Nausea or vomiting",
    code: "nausea-or-vomiting",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Leukocytosis >10,000",
    code: "leukocytosis",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Leukocyte left shift (>75% neutrophils)",
    code: "leukocyte-left-shift",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function AlvaradoAcuteAppedicitisComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (true) {
      case value >= 7:
        return "Possible appendicitis. Consider surgical intervention.";
      case value >= 4:
        return "Possible Appendicitis. Consider imaging.";
      default:
        return "Appendicitis unlikely.";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
