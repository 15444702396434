import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Temp >38°C (100.4°F) or <36°C (96.8°F)",
    code: "temp",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Heart rate >90",
    code: "hr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Respiratory rate >20 or PaCO₂ <32 mm Hg",
    code: "rr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "WBC >12,000/mm³, <4,000/mm³, or >10% bands",
    code: "wbc",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function SepticShockCriteriaComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (value) {
      case 0:
      case 1:
        return "Patient does not meet criteria for SIRS";
      case 2:
      case 3:
      case 4:
        return "Patient meets SIRS criteria";
      default:
        return "";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
