import { useConfirm } from "material-ui-confirm";
import { useEffect, useRef } from "react";

/**
 * A wrapper around `useState` which enables the `onbeforeunload`
 * functionality
 */
export const useSaved = (
  initialValue: boolean,
  closeForm?: (...args: any) => void,
  message?: string,
) => {
  const confirm = useConfirm();
  const savedRef = useRef(initialValue);

  useEffect(() => {
    return () => {
      setSaved(true);
      window.onbeforeunload = null;
    };
  }, []);

  const requestClose = () => {
    if (savedRef.current) return closeForm();

    confirm({
      description: message ?? "Any unsaved changes would be lost!",
    }).then(() => {
      setSaved(true);
      if (closeForm) closeForm();
    }).catch(() => {});
  };

  const requestCloseByProp = (onCloseForm: (...args: any) => void) => {
    if (savedRef.current) return onCloseForm();

    confirm({
      description: "Any unsaved changes would be lost!",
    }).then(() => {
      setSaved(true);
      onCloseForm();
    }).catch(() => {});
  }

  const setSaved = (value: boolean) => {
    savedRef.current = value;
    window.onbeforeunload = !savedRef.current ? () => true : null;
  };

  return { saved: savedRef.current, setSaved, requestClose,requestCloseByProp };
};
