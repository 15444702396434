function HealthProfessionalTeam() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="368"
      height="368"
      viewBox="0 0 368 368"
      fill="none"
    >
      <path
        d="M184 337.92C281.251 337.92 360.088 293.919 360.088 239.642C360.088 185.364 281.251 141.364 184 141.364C86.7494 141.364 7.91211 185.364 7.91211 239.642C7.91211 293.919 86.7494 337.92 184 337.92Z"
        fill="#F0F0F0"
        fillOpacity="0.1"
      />
      <path
        d="M183.993 218.879C205.065 218.879 222.147 209.016 222.147 196.85C222.147 184.685 205.065 174.822 183.993 174.822C162.921 174.822 145.839 184.685 145.839 196.85C145.839 209.016 162.921 218.879 183.993 218.879Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M230.95 256.157C252.022 256.157 269.104 246.295 269.104 234.129C269.104 221.963 252.022 212.1 230.95 212.1C209.878 212.1 192.795 221.963 192.795 234.129C192.795 246.295 209.878 256.157 230.95 256.157Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M272.04 306.993C293.113 306.993 310.195 297.131 310.195 284.965C310.195 272.799 293.113 262.936 272.04 262.936C250.968 262.936 233.886 272.799 233.886 284.965C233.886 297.131 250.968 306.993 272.04 306.993Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M307.258 242.6C328.33 242.6 345.412 232.738 345.412 220.572C345.412 208.406 328.33 198.543 307.258 198.543C286.186 198.543 269.104 208.406 269.104 220.572C269.104 232.738 286.186 242.6 307.258 242.6Z"
        fill="#E0E0E0"
      />
      <path
        d="M60.7275 242.6C81.7995 242.6 98.8817 232.738 98.8817 220.572C98.8817 208.406 81.7995 198.543 60.7275 198.543C39.6555 198.543 22.5732 208.406 22.5732 220.572C22.5732 232.738 39.6555 242.6 60.7275 242.6Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M134.099 254.465C155.171 254.465 172.254 244.602 172.254 232.436C172.254 220.27 155.171 210.408 134.099 210.408C113.027 210.408 95.9451 220.27 95.9451 232.436C95.9451 244.602 113.027 254.465 134.099 254.465Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M93.634 311.46C114.706 311.46 131.788 301.598 131.788 289.432C131.788 277.266 114.706 267.404 93.634 267.404C72.562 267.404 55.4797 277.266 55.4797 289.432C55.4797 301.598 72.562 311.46 93.634 311.46Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M166.395 310.776L166.748 299.324C166.757 299.09 166.719 298.856 166.635 298.637C166.551 298.418 166.424 298.218 166.261 298.05C166.098 297.881 165.903 297.748 165.687 297.657C165.47 297.566 165.238 297.519 165.004 297.521H143.52C143.285 297.523 143.052 297.477 142.835 297.387C142.618 297.296 142.422 297.163 142.258 296.994C142.094 296.825 141.967 296.625 141.882 296.406C141.798 296.186 141.759 295.952 141.768 295.717L142.387 276.007C142.404 275.524 142.607 275.067 142.954 274.731C143.302 274.394 143.765 274.206 144.249 274.204H165.755C166.237 274.199 166.699 274.009 167.045 273.674C167.391 273.338 167.596 272.883 167.617 272.401L167.97 260.941C167.991 260.458 168.196 260.002 168.544 259.666C168.892 259.331 169.356 259.142 169.839 259.138H198.205C198.688 259.142 199.152 259.331 199.5 259.666C199.848 260.002 200.054 260.458 200.074 260.941L200.428 272.394C200.446 272.877 200.65 273.334 200.997 273.671C201.343 274.008 201.806 274.199 202.29 274.204H223.847C224.33 274.206 224.794 274.394 225.141 274.731C225.489 275.067 225.692 275.524 225.709 276.007L226.269 295.717C226.276 295.952 226.237 296.186 226.152 296.405C226.068 296.624 225.94 296.823 225.776 296.992C225.613 297.16 225.417 297.294 225.201 297.385C224.984 297.475 224.752 297.522 224.517 297.521H202.996C202.762 297.519 202.53 297.566 202.314 297.657C202.098 297.748 201.902 297.881 201.739 298.05C201.576 298.218 201.449 298.418 201.365 298.637C201.282 298.856 201.243 299.09 201.252 299.324L201.605 310.776C201.614 311.011 201.575 311.246 201.491 311.466C201.407 311.685 201.279 311.886 201.116 312.055C200.952 312.224 200.756 312.359 200.539 312.45C200.322 312.541 200.089 312.588 199.853 312.586H168.139C167.904 312.588 167.671 312.541 167.455 312.45C167.238 312.358 167.043 312.224 166.879 312.055C166.716 311.885 166.589 311.685 166.506 311.465C166.423 311.245 166.385 311.011 166.395 310.776V310.776Z"
        fill="#E0E0E0"
        fillOpacity="0.1"
      />
      <path
        d="M331.119 193.296C330.383 188.35 332.473 177.575 336.668 170.399C340.864 163.223 348.246 157.35 354.266 158.468C359.874 159.513 360.323 166.609 354.524 172.195C348.724 177.781 336.271 188.335 334.593 196.777L333.629 204.211L331.119 193.296Z"
        fill="#726EFF"
      />
      <g opacity="0.15">
        <path
          opacity="0.15"
          d="M331.119 193.296C330.383 188.35 332.473 177.575 336.668 170.399C340.864 163.223 348.246 157.35 354.266 158.468C359.874 159.513 360.323 166.609 354.524 172.195C348.724 177.781 336.271 188.335 334.593 196.777L333.629 204.211L331.119 193.296Z"
          fill="black"
        />
      </g>
      <path
        d="M331.745 200.119C331.823 200.102 331.894 200.06 331.946 199.999C331.998 199.937 332.028 199.86 332.032 199.78C332.871 182.41 344.544 166.66 352.257 162.45C352.305 162.43 352.348 162.401 352.384 162.363C352.419 162.325 352.447 162.281 352.464 162.232C352.481 162.183 352.488 162.131 352.483 162.079C352.479 162.028 352.464 161.978 352.439 161.932C352.414 161.887 352.379 161.847 352.338 161.816C352.297 161.785 352.249 161.763 352.199 161.751C352.148 161.74 352.096 161.739 352.045 161.749C351.994 161.759 351.946 161.78 351.904 161.81C344.028 166.123 332.157 182.109 331.296 199.743C331.293 199.791 331.299 199.839 331.315 199.885C331.331 199.93 331.356 199.972 331.388 200.007C331.42 200.043 331.459 200.072 331.503 200.092C331.546 200.113 331.594 200.124 331.642 200.126L331.745 200.119Z"
        fill="white"
      />
      <path
        d="M331.634 214.721C332.017 211.828 334.748 206.743 339.686 202.143C345.147 197.064 352.559 194.047 353.854 190.484C355.407 186.252 351.558 183.029 345.427 184.721C339.296 186.414 329.574 196.512 329.728 209.613L331.634 214.721Z"
        fill="#726EFF"
      />
      <path
        d="M330 211.968C330.069 211.952 330.131 211.916 330.179 211.865C330.228 211.814 330.26 211.75 330.273 211.681C333.334 196.461 343.631 189.763 348.673 188.35C348.721 188.337 348.766 188.315 348.806 188.284C348.846 188.253 348.879 188.215 348.904 188.172C348.929 188.129 348.945 188.081 348.951 188.031C348.958 187.981 348.954 187.931 348.941 187.883C348.928 187.834 348.906 187.789 348.875 187.749C348.845 187.71 348.807 187.676 348.763 187.652C348.72 187.627 348.672 187.61 348.622 187.604C348.573 187.598 348.522 187.601 348.474 187.614C342.932 189.174 332.709 195.96 329.588 211.505C329.577 211.552 329.576 211.6 329.585 211.648C329.594 211.695 329.613 211.74 329.639 211.78C329.666 211.82 329.701 211.854 329.742 211.88C329.782 211.906 329.828 211.924 329.875 211.931C329.913 211.953 329.956 211.966 330 211.968V211.968Z"
        fill="white"
      />
      <path
        d="M37.573 191.632C37.573 191.632 39.6265 181.115 37.0652 171.643C34.5039 162.17 29.1237 155.061 22.8089 152.507C16.494 149.953 9.39158 154.251 15.022 160.529C20.6524 166.807 31.6482 174.866 32.4725 191.956L37.573 191.632Z"
        fill="#726EFF"
      />
      <g opacity="0.15">
        <path
          opacity="0.15"
          d="M37.573 191.632C37.573 191.632 39.6265 181.115 37.0652 171.643C34.5039 162.17 29.1237 155.061 22.8089 152.507C16.494 149.953 9.39158 154.251 15.022 160.529C20.6524 166.807 31.6482 174.866 32.4725 191.956L37.573 191.632Z"
          fill="black"
        />
      </g>
      <path
        d="M36.5056 188.416H36.5792C36.627 188.413 36.6737 188.401 36.7166 188.38C36.7594 188.358 36.7975 188.329 36.8284 188.292C36.8594 188.256 36.8827 188.213 36.8967 188.168C36.9108 188.122 36.9155 188.074 36.9104 188.026C35.4384 169.045 24.6119 157.916 19.1213 154.678C19.0393 154.629 18.9413 154.615 18.8489 154.639C18.7565 154.662 18.6772 154.721 18.6282 154.803C18.5793 154.886 18.565 154.985 18.5884 155.079C18.6118 155.173 18.671 155.253 18.7533 155.304C24.1335 158.468 34.7024 169.405 36.1892 188.085C36.1954 188.168 36.2303 188.247 36.2881 188.307C36.3458 188.368 36.4227 188.406 36.5056 188.416V188.416Z"
        fill="white"
      />
      <path
        d="M8.93515 177.582C9.08235 179.01 10.2305 180.099 11.3272 181.012C12.4238 181.924 13.6161 182.925 13.9179 184.324C14.5214 187.143 11.18 189.748 11.9012 192.538C12.0964 193.113 12.4065 193.642 12.8127 194.094C13.2188 194.546 13.7125 194.91 14.2638 195.165C15.1649 195.608 16.0886 196.004 17.0312 196.35C17.4878 196.488 17.9523 196.599 18.4222 196.681C19.8381 196.816 21.1701 197.414 22.2126 198.381C23.7582 200.052 23.5595 202.621 24.1115 204.836C24.4684 206.15 25.1059 207.372 25.9801 208.416C26.8542 209.461 27.9442 210.303 29.1752 210.886C31.2212 211.865 34.5848 212.424 35.4459 211.659C36.1009 211.077 35.5489 208.501 35.7918 207.655C36.4215 205.492 36.843 203.274 37.0504 201.031C37.1047 200.007 37.2827 198.994 37.5803 198.013C37.9409 196.924 38.3163 195.805 38.6475 194.738C38.9787 193.671 39.6043 192.707 39.5675 191.382C39.4712 190.12 39.1859 188.879 38.7211 187.702C38.3187 186.515 37.9041 185.337 37.4772 184.169C37.0244 182.763 36.399 181.418 35.6152 180.165C34.8887 179.108 33.9538 178.21 32.868 177.526C31.7821 176.843 30.568 176.389 29.3003 176.191C27.2027 175.882 24.8843 176.368 22.875 175.602C20.8657 174.837 19.1361 173.696 17.0459 173.151C15.9579 172.831 14.8112 172.763 13.6928 172.951C12.5745 173.14 11.5138 173.581 10.5912 174.241C10.061 174.618 9.63231 175.12 9.34338 175.703C9.05445 176.286 8.91421 176.932 8.93515 177.582Z"
        fill="#726EFF"
      />
      <path
        d="M35.4091 205.778C35.4517 205.78 35.4942 205.774 35.5342 205.759C35.5743 205.745 35.6111 205.723 35.6425 205.694C35.674 205.665 35.6995 205.631 35.7175 205.592C35.7355 205.554 35.7458 205.512 35.7477 205.469C36.8296 185.266 18.0837 176.206 13.4175 175.05C13.3346 175.032 13.248 175.047 13.1755 175.09C13.1029 175.134 13.0499 175.204 13.0274 175.286C13.0073 175.369 13.0208 175.456 13.0649 175.529C13.109 175.602 13.1802 175.655 13.2629 175.676C17.8482 176.809 36.1672 185.664 35.1074 205.44C35.1033 205.482 35.1081 205.525 35.1213 205.565C35.1345 205.606 35.156 205.643 35.1843 205.675C35.2127 205.707 35.2472 205.732 35.2859 205.75C35.3246 205.768 35.3666 205.778 35.4091 205.778V205.778Z"
        fill="white"
      />
      <path
        d="M16.7001 189.285C16.7661 189.293 16.8333 189.28 16.8914 189.248C19.2106 188.27 21.7167 187.815 24.2316 187.916C26.7465 188.016 29.2083 188.67 31.4421 189.829C31.5195 189.865 31.6079 189.87 31.6888 189.843C31.7698 189.815 31.837 189.758 31.8764 189.682C31.8953 189.644 31.9064 189.602 31.9093 189.56C31.9121 189.517 31.9065 189.475 31.8928 189.434C31.8792 189.394 31.8577 189.357 31.8296 189.325C31.8015 189.293 31.7674 189.267 31.7292 189.248C29.4047 188.043 26.8427 187.368 24.2265 187.27C21.6104 187.172 19.005 187.654 16.597 188.681C16.5587 188.7 16.5246 188.726 16.4967 188.758C16.4688 188.79 16.4477 188.828 16.4347 188.868C16.4216 188.909 16.4169 188.952 16.4207 188.994C16.4246 189.037 16.437 189.078 16.4572 189.115C16.4813 189.161 16.5159 189.2 16.5581 189.229C16.6004 189.259 16.649 189.278 16.7001 189.285V189.285Z"
        fill="white"
      />
      <path
        d="M297.742 87.7606C295.85 91.9631 289.042 108.052 289.042 108.052L297.742 114.742C298.478 113.66 303.019 100.383 303.019 100.383L306.441 82.1743C302.062 82.2921 299.486 83.9039 297.742 87.7606Z"
        fill="#E6E6E6"
      />
      <path
        d="M303.828 204.461C303.666 204.571 303.284 205.013 302.606 205.638C301.903 206.242 301.166 206.804 300.398 207.324C298.856 208.452 297.235 209.467 295.548 210.363C293.546 211.467 291.743 212.137 289.351 213.219C289.099 213.317 288.871 213.468 288.681 213.661C288.536 213.832 288.435 214.037 288.387 214.257C288.317 214.709 288.373 215.172 288.55 215.594C288.727 216.016 289.017 216.381 289.388 216.649C290.139 217.174 290.991 217.537 291.89 217.716C293.924 218.288 296.067 218.351 298.132 217.9C299.913 217.394 301.604 216.612 303.144 215.582C304.43 214.675 305.891 214.048 307.435 213.742C308.774 213.55 310.43 213.609 311.593 212.807C312.888 211.916 312.373 210.01 312.005 208.391C311.608 206.603 311.512 203.879 310.702 203.938L310.636 204.674C310.57 205.447 308.701 205.8 307.251 205.8C306.051 205.8 303.784 205.491 303.791 204.924L303.828 204.461Z"
        fill="#263238"
      />
      <path
        d="M324.731 214.353L324.62 213.403C324.274 213.403 324.12 213.793 323.98 214.632C323.682 216.424 323.116 218.161 322.302 219.784C321.301 221.877 320.018 223.824 318.489 225.569C317.766 226.306 317.09 227.087 316.465 227.91C316.068 228.646 315.928 230.567 316.598 231.008C319.063 232.657 322.81 232.701 325.43 231.928C326.435 231.682 327.341 231.137 328.028 230.364C328.716 229.591 329.152 228.627 329.279 227.601C329.706 225.186 329.934 223.7 331.163 221.874C331.612 221.205 332.341 220.667 332.635 219.917C333.18 218.577 332.738 217.068 332.392 215.729C332.091 214.573 331.759 213.307 331.362 213.41V214.146C330.891 214.676 329.228 215.192 327.748 215.25C326.836 215.258 324.723 215.081 324.731 214.353Z"
        fill="#263238"
      />
      <path
        d="M331.34 127.674C331.465 133.812 332.76 174.38 332.76 174.38C333.217 177.694 333.487 181.031 333.57 184.375C333.739 192.471 333.254 213.484 333.254 213.484C332.731 216.907 323.781 216.303 322.95 214.721C322.95 214.721 320.742 184.699 319.711 176.669C318.828 169.67 315.913 146.78 315.913 146.78L313.624 169.28C313.716 171.906 313.657 174.536 313.448 177.155C313.19 180.835 311.976 205.638 311.976 205.638C311.976 207 303.07 208.546 301.834 205.373C301.834 205.373 301.186 171.282 301.098 167.874C301.024 163.995 301.561 127.262 301.561 127.262L331.34 127.674Z"
        fill="#37474F"
      />
      <path
        d="M315.192 138.191C312.619 137.199 310.273 135.697 308.296 133.775C308.296 133.775 309.392 136.984 313.904 139.663L313.61 167.631L315.884 146.795L315.192 138.191Z"
        fill="#263238"
      />
      <path
        d="M321.161 81.3722C322.309 81.6445 325.945 82.6234 328.896 83.6685C330.388 84.236 331.682 85.2257 332.62 86.5168C334.762 89.6448 332.834 96.6 331.252 109.701C331.377 118.157 331.62 128.675 331.62 128.675C327.726 131.509 309.584 133.429 301.399 127.453C301.399 127.453 301.127 95.7095 301.672 91.5805C302.702 83.6832 305.801 81.5709 312.182 81.1956L321.161 81.3722Z"
        fill="#455A64"
      />
      <path
        d="M310.011 90.6457C309.436 92.6182 306.043 106.507 306.522 111.394C306.887 113.902 307.564 116.355 308.539 118.695C310.011 122.176 310.989 119.063 311.814 117.009C312.503 115.09 312.965 113.097 313.19 111.07C313.462 108.45 312.41 100.471 312.815 91.1241C312.662 90.9084 312.469 90.7248 312.245 90.5839C312.021 90.4431 311.772 90.3477 311.512 90.3033C311.251 90.2588 310.985 90.2662 310.727 90.325C310.469 90.3837 310.226 90.4927 310.011 90.6457V90.6457Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M310.011 90.6457C309.436 92.6182 306.043 106.507 306.522 111.394C306.887 113.902 307.564 116.355 308.539 118.695C310.011 122.176 310.989 119.063 311.814 117.009C312.503 115.09 312.965 113.097 313.19 111.07C313.462 108.45 312.41 100.471 312.815 91.1241C312.662 90.9084 312.469 90.7248 312.245 90.5839C312.021 90.4431 311.772 90.3477 311.512 90.3033C311.251 90.2588 310.985 90.2662 310.727 90.325C310.469 90.3837 310.226 90.4927 310.011 90.6457V90.6457Z"
        fill="black"
      />
      <path
        d="M309.856 86.1856C308.509 87.3337 309.981 90.6457 309.981 90.6457C310.943 90.4367 311.948 90.6081 312.785 91.1241C312.785 91.1241 315.464 89.976 315.472 87.9961C315.472 86.1856 313.551 86.767 312.189 86.5241C310.828 86.2813 310.048 85.2214 309.856 86.1856Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M309.856 86.1856C308.509 87.3337 309.981 90.6457 309.981 90.6457C310.943 90.4367 311.948 90.6081 312.785 91.1241C312.785 91.1241 315.464 89.976 315.472 87.9961C315.472 86.1856 313.551 86.767 312.189 86.5241C310.828 86.2813 310.048 85.2214 309.856 86.1856Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M310.011 90.6458C310.232 89.8656 311.284 89.9098 312.13 89.6742C312.607 89.4872 313.006 89.1449 313.264 88.7027C313.698 87.9667 313.264 86.7082 312.653 86.5757C313.963 86.6714 315.501 86.377 315.501 87.9962C315.501 89.976 312.815 91.1242 312.815 91.1242C311.977 90.6082 310.972 90.4367 310.011 90.6458Z"
        fill="black"
      />
      <path
        d="M319.196 62.1037C319.156 63.792 318.617 65.4305 317.645 66.812C316.674 68.1936 315.315 69.2561 313.74 69.8653C312.166 70.4745 310.445 70.6029 308.797 70.2344C307.149 69.8658 305.648 69.0168 304.482 67.7948C303.317 66.5727 302.54 65.0325 302.25 63.3689C301.96 61.7053 302.17 59.993 302.853 58.4487C303.536 56.9044 304.662 55.5973 306.088 54.6929C307.514 53.7884 309.176 53.3271 310.864 53.3674C313.128 53.4214 315.277 54.3721 316.839 56.0104C318.401 57.6487 319.249 59.8405 319.196 62.1037V62.1037Z"
        fill="#263238"
      />
      <path
        d="M325.268 68.3228C325.268 68.3228 323.73 74.7848 323.156 75.8373C322.769 76.4881 322.147 76.9652 321.419 77.1695L321.625 72.5989L325.268 68.3228Z"
        fill="#263238"
      />
      <path
        d="M323.759 58.9683C324.244 58.9233 324.732 58.9894 325.188 59.1616C325.643 59.3337 326.053 59.6074 326.387 59.9619C327.498 61.1542 326.777 64.7827 325.268 68.3229L322.655 68.5584L323.759 58.9683Z"
        fill="#263238"
      />
      <path
        d="M321.875 68.5437C322.39 68.8676 323.163 67.8813 323.825 67.2189C324.488 66.5565 326.607 65.688 327.601 67.9549C328.595 70.2218 326.556 73.1658 324.885 73.6442C322.007 74.4685 321.625 72.6285 321.625 72.6285L321.139 84.2794C319.1 86.7671 314.309 86.9069 311.939 86.4212C310.04 86.0384 310.011 84.6916 311.88 82.6308L311.968 79.2893C310.693 79.4622 309.402 79.4992 308.119 79.3997C306.029 79.0244 304.741 77.3389 304.167 75.05C303.284 71.554 303.07 67.6532 303.946 61.2647C305.028 53.4189 314.25 53.5588 319.1 56.7898C323.95 60.0208 321.875 68.5437 321.875 68.5437Z"
        fill="#B16668"
      />
      <path
        d="M321.853 69.4195C322.118 69.4195 323.156 67.7929 323.825 67.2115C324.745 66.4166 323.759 58.9609 323.759 58.9609C324.086 58.3161 324.21 57.5879 324.117 56.8711C324.024 56.1544 323.717 55.4823 323.237 54.9424C321.411 52.8374 319.012 52.2192 316.274 52.2854C315.273 52.2854 314.287 52.3958 313.286 52.4105C312.093 52.4015 310.91 52.1977 309.782 51.807C308.252 51.3139 306.772 50.6515 305.205 50.2835C304.963 50.1865 304.701 50.1532 304.444 50.1867C304.186 50.2202 303.941 50.3195 303.733 50.4749C303.613 50.6336 303.529 50.8166 303.487 51.0111C303.445 51.2056 303.446 51.4069 303.49 51.6009C303.608 52.5118 303.989 53.3685 304.586 54.0665C304.052 53.9734 303.508 53.9462 302.967 53.9856C302.407 54.0186 301.881 54.2633 301.495 54.6701C301.255 54.9725 301.104 55.3362 301.06 55.7199C301.016 56.1036 301.08 56.4921 301.245 56.8413C301.586 57.5355 302.137 58.1046 302.82 58.4678C304.118 59.2156 305.646 59.4568 307.111 59.1449C307.279 59.534 307.527 59.8833 307.839 60.1699C308.151 60.4565 308.519 60.6741 308.921 60.8083C310.147 61.3373 311.469 61.604 312.804 61.5914C314.139 61.5787 315.456 61.2869 316.671 60.7347C316.94 61.1368 317.235 61.5204 317.555 61.8829C317.916 62.2377 318.322 62.5445 318.762 62.7955C318.97 62.9181 319.186 63.0263 319.409 63.1193C319.662 63.1805 319.908 63.2642 320.145 63.3696C320.418 63.5757 320.388 64.1056 320.44 64.4662C320.58 65.4009 320.697 66.343 320.874 67.2777C320.93 67.9603 321.144 68.6205 321.5 69.2061C321.54 69.2647 321.592 69.3141 321.653 69.3509C321.714 69.3877 321.782 69.4111 321.853 69.4195V69.4195Z"
        fill="#263238"
      />
      <path
        d="M323.737 59.5498L326.939 57.9306C326.843 57.7165 326.705 57.5238 326.533 57.3642C326.362 57.2046 326.159 57.0812 325.939 57.0015C325.718 56.9218 325.484 56.8874 325.25 56.9004C325.016 56.9133 324.787 56.9733 324.576 57.0768C324.145 57.3021 323.818 57.6848 323.661 58.1453C323.505 58.6058 323.532 59.1087 323.737 59.5498V59.5498Z"
        fill="#263238"
      />
      <path
        d="M311.968 79.2598C311.968 79.2598 316.671 78.4281 318.335 77.5964C319.313 77.1112 320.121 76.3415 320.653 75.3884C320.38 76.3405 319.919 77.2287 319.299 78.0012C318.07 79.4732 311.939 80.5772 311.939 80.5772L311.968 79.2598Z"
        fill="#9A4A4D"
      />
      <path
        d="M313.536 67.3956C313.552 67.5798 313.622 67.7551 313.737 67.8998C313.852 68.0445 314.007 68.1521 314.183 68.2093C314.359 68.2665 314.548 68.2707 314.726 68.2214C314.904 68.172 315.064 68.0714 315.185 67.932C315.307 67.7925 315.384 67.6205 315.409 67.4372C315.433 67.254 315.403 67.0676 315.322 66.9014C315.241 66.7351 315.113 66.5963 314.954 66.5023C314.795 66.4083 314.611 66.3632 314.427 66.3726C314.301 66.381 314.178 66.4141 314.065 66.4702C313.952 66.5262 313.852 66.6039 313.769 66.699C313.686 66.7941 313.623 66.9045 313.583 67.0241C313.543 67.1436 313.527 67.2699 313.536 67.3956V67.3956Z"
        fill="black"
      />
      <path
        d="M314.154 63.8627L316.215 64.8637C316.344 64.5806 316.358 64.2588 316.255 63.9654C316.152 63.6719 315.94 63.4295 315.663 63.2886C315.527 63.2241 315.379 63.1877 315.228 63.1818C315.078 63.1758 314.927 63.2004 314.786 63.254C314.645 63.3077 314.517 63.3892 314.408 63.4938C314.3 63.5984 314.213 63.7239 314.154 63.8627V63.8627Z"
        fill="black"
      />
      <path
        d="M304.439 64.7681L306.647 64.0321C306.605 63.8872 306.533 63.7526 306.437 63.6363C306.341 63.52 306.222 63.4245 306.088 63.3556C305.953 63.2867 305.806 63.2459 305.656 63.2357C305.505 63.2255 305.354 63.246 305.212 63.2961C304.918 63.3941 304.673 63.6021 304.529 63.8765C304.385 64.1509 304.353 64.4704 304.439 64.7681V64.7681Z"
        fill="black"
      />
      <path
        d="M305.506 67.1306C305.497 67.3149 305.542 67.4978 305.636 67.6567C305.73 67.8156 305.868 67.9435 306.034 68.0244C306.2 68.1054 306.386 68.1358 306.569 68.1121C306.752 68.0883 306.924 68.0113 307.063 67.8906C307.203 67.7699 307.304 67.6109 307.354 67.4333C307.404 67.2557 307.401 67.0673 307.345 66.8915C307.289 66.7157 307.182 66.5603 307.039 66.4445C306.895 66.3286 306.72 66.2575 306.537 66.24C306.282 66.2218 306.031 66.3053 305.837 66.4722C305.644 66.6392 305.525 66.876 305.506 67.1306V67.1306Z"
        fill="black"
      />
      <path
        d="M310.585 66.5933L310.246 72.2384L307.302 71.2227L310.585 66.5933Z"
        fill="#9A4A4D"
      />
      <path
        d="M313.772 73.2026L310.092 74.211C310.147 74.4584 310.251 74.6922 310.399 74.8984C310.546 75.1045 310.734 75.2788 310.95 75.4107C311.167 75.5427 311.407 75.6296 311.658 75.6662C311.909 75.7029 312.165 75.6885 312.41 75.6241C312.906 75.4751 313.325 75.1395 313.579 74.688C313.833 74.2364 313.902 73.704 313.772 73.2026V73.2026Z"
        fill="#9A4A4D"
      />
      <path
        d="M313.779 74.1372C313.615 74.0816 313.444 74.0518 313.271 74.0488C312.837 74.0504 312.419 74.2132 312.098 74.5056C311.777 74.798 311.576 75.1991 311.534 75.6312H311.6C311.862 75.6896 312.134 75.6896 312.395 75.6312C312.739 75.5302 313.049 75.3379 313.293 75.0749C313.537 74.8119 313.705 74.4878 313.779 74.1372V74.1372Z"
        fill="#F28F8F"
      />
      <path
        d="M311.939 86.3916C314.544 85.8469 320.697 83.4476 321.294 80.4226L321.374 78.7666C322.383 78.7666 322.846 79.9 323.347 81.5119C323.553 85.7733 319.063 89.6594 314.552 90.7045C314.402 89.7175 314.064 88.7683 313.558 87.9077C313.181 87.2525 312.618 86.7245 311.939 86.3916V86.3916Z"
        fill="#37474F"
      />
      <path
        d="M321.272 80.96C321.272 80.96 319.351 90.6089 318.512 97.152C317.673 103.695 317.842 122.102 318.283 133.805C318.607 142.328 319.881 158.093 319.881 158.093C319.881 158.093 321.985 158.52 327.064 157.681C329.687 157.335 332.214 156.469 334.497 155.134C334.497 155.134 333.695 131.185 333.092 122.824C332.532 115.118 332.554 110.871 332.554 110.871L334.372 93.2732C334.372 93.2732 334.233 82.2332 330.795 81.7475C327.233 81.1955 321.272 80.96 321.272 80.96Z"
        fill="#FAFAFA"
      />
      <path
        d="M324.576 96.7546C324.576 96.3498 324.576 95.474 324.686 94.3773C325.025 88.9824 324.532 81.1809 322.714 80.7908L322.552 81.5268C323.884 81.8138 324.09 91.7057 323.928 94.3258C323.855 95.4519 323.796 96.3424 323.818 96.7914L324.576 96.7546Z"
        fill="#726EFF"
      />
      <path
        d="M330.898 139.104C331.84 137.5 331.892 136.307 333.312 134.872C333.923 134.285 334.365 133.546 334.593 132.73C334.939 131.416 335.112 130.063 335.108 128.704L343.351 129.087C343.284 132.15 342.947 135.202 342.343 138.206C340.768 145.073 339.296 147.936 336.301 148.672C331.583 149.739 330.413 147.708 332.076 144.3C332.79 142.88 333.3 141.366 333.592 139.803C333.082 140.406 332.49 140.934 331.833 141.371C331.498 141.622 331.079 141.736 330.663 141.691C330.246 141.646 329.862 141.445 329.588 141.128C329.382 140.797 330.155 140.341 330.898 139.104Z"
        fill="#B16668"
      />
      <path
        d="M329.404 81.696C329.404 81.696 333.482 81.6371 335.771 83.6979C338.06 85.7587 339.296 91.264 340.768 96.7913C342.24 102.319 343.565 107.684 343.955 111.511C344.286 114.72 343.756 131.383 343.756 131.383C343.756 131.383 338.096 132.583 334.505 130.647L334.799 111.909L328.867 93.0893C327.784 89.3362 327.973 85.3304 329.404 81.696V81.696Z"
        fill="#F0F0F0"
      />
      <path
        d="M321.404 77.7731C321.941 77.67 322.434 77.9497 322.876 78.6931C323.318 79.4364 324.274 80.9967 324.274 80.9967C324.274 80.9967 324.767 86.1487 324.274 87.4662C323.847 88.5849 320.984 93.862 320.984 93.862C320.984 93.862 321.176 99.014 321.22 100.486C321.264 101.958 318.276 108.398 317.974 111.364C317.974 111.364 317.827 99.3084 318.261 95.9596C318.997 90.1379 319.998 82.8441 321.294 80.4521L321.404 77.7731Z"
        fill="#EBEBEB"
      />
      <path
        d="M323.45 113.763C322.761 114.22 321.95 114.456 321.124 114.441L321.19 113.911C321.822 113.923 322.443 113.751 322.979 113.418C322.465 113.009 322.016 112.525 321.647 111.982C320.911 110.805 319.689 102.76 320.425 100.942C321.029 99.4703 322.81 96.2319 324.157 96.2319H324.193C325.548 96.2908 327.064 99.6985 327.55 101.251C328.131 103.091 326.254 111.011 325.393 112.122C324.974 112.633 324.477 113.075 323.921 113.432C324.431 113.806 325.035 114.031 325.665 114.08V114.617H325.599C324.818 114.537 324.074 114.241 323.45 113.763V113.763ZM320.896 101.17C320.219 102.812 321.367 110.599 322.074 111.695C322.453 112.242 322.918 112.724 323.45 113.123C324.011 112.772 324.508 112.329 324.922 111.813C325.717 110.79 327.52 103.121 326.99 101.428C326.342 99.3526 324.915 96.8207 324.12 96.7913C323.354 96.7913 321.735 99.1686 320.896 101.17Z"
        fill="#455A64"
      />
      <path
        d="M320.374 114.146C320.365 114.3 320.402 114.454 320.481 114.587C320.56 114.72 320.676 114.826 320.816 114.893C320.955 114.96 321.111 114.984 321.264 114.961C321.417 114.939 321.56 114.872 321.675 114.769C321.789 114.666 321.871 114.53 321.909 114.381C321.947 114.231 321.939 114.073 321.887 113.927C321.836 113.782 321.742 113.655 321.618 113.563C321.493 113.471 321.345 113.418 321.19 113.41C320.985 113.4 320.783 113.472 320.63 113.61C320.477 113.748 320.385 113.94 320.374 114.146V114.146Z"
        fill="#263238"
      />
      <path
        d="M324.915 114.338C324.905 114.494 324.941 114.65 325.02 114.785C325.099 114.92 325.216 115.029 325.357 115.097C325.498 115.166 325.656 115.19 325.811 115.168C325.966 115.146 326.111 115.079 326.228 114.974C326.344 114.869 326.426 114.732 326.465 114.58C326.503 114.428 326.494 114.268 326.441 114.121C326.388 113.974 326.292 113.846 326.166 113.753C326.039 113.661 325.888 113.608 325.732 113.602C325.526 113.594 325.326 113.666 325.173 113.803C325.021 113.941 324.928 114.133 324.915 114.338V114.338Z"
        fill="#263238"
      />
      <path
        d="M320.293 104.453H320.742C320.787 104.449 320.829 104.428 320.86 104.394C320.89 104.359 320.905 104.315 320.903 104.269C320.777 103.263 320.822 102.243 321.036 101.252C321.823 99.3306 323.457 96.9238 324.164 96.9533C324.87 96.9827 326.291 99.5219 326.917 101.502C327.039 102.506 326.995 103.523 326.784 104.512C326.778 104.557 326.788 104.602 326.814 104.639C326.84 104.676 326.88 104.702 326.924 104.711L327.373 104.777C327.419 104.783 327.465 104.771 327.502 104.744C327.539 104.716 327.564 104.675 327.572 104.63C327.812 103.519 327.842 102.373 327.66 101.252C327.329 100.192 325.761 96.2099 324.186 96.1437C322.611 96.0774 320.705 99.912 320.285 100.942C320.006 102.043 319.939 103.187 320.086 104.313C320.095 104.359 320.122 104.399 320.161 104.426C320.199 104.452 320.247 104.462 320.293 104.453V104.453Z"
        fill="#726EFF"
      />
      <path
        d="M311.954 79.6868C311.218 79.7456 310.938 80.7172 309.319 81.1588C306.301 82.0567 303.939 82.41 302.695 84.0439C300.671 86.745 301.068 88.953 300.56 102.628C300.053 116.303 299.891 155.245 299.891 155.245C301.338 155.902 302.916 156.221 304.505 156.179C304.505 156.179 304.388 113.204 304.675 103.533C304.962 93.8621 306.426 89.1591 308.517 85.9722C309.066 83.8023 310.223 81.8338 311.851 80.2976L311.954 79.6868Z"
        fill="#FAFAFA"
      />
      <path
        d="M308.539 86.009C306.448 89.1959 304.984 93.9063 304.697 103.57C304.66 104.725 304.631 106.337 304.601 108.28C304.601 108.28 302.879 99.522 303.188 97.6599C303.497 95.7978 305.506 91.9412 305.506 91.9412C305.253 88.8369 305.843 85.7217 307.214 82.9252C307.501 82.4103 307.86 81.9393 308.281 81.5268C308.693 81.2006 309.128 80.9054 309.584 80.6436C310.271 80.1127 311.091 79.7816 311.954 79.6868V80.3565C310.297 81.8743 309.112 83.8364 308.539 86.009V86.009Z"
        fill="#EBEBEB"
      />
      <path
        d="M311.939 80.3564C311.078 80.8698 310.311 81.527 309.672 82.2995C308.878 83.3608 308.382 84.6142 308.233 85.931C308.084 87.2478 308.289 88.5804 308.826 89.792C309.107 88.9503 309.564 88.1778 310.165 87.5251C310.644 86.9956 311.257 86.6042 311.939 86.3916C311.107 85.5084 311.431 83.9628 311.88 82.6012L311.939 80.3564Z"
        fill="#37474F"
      />
      <path
        d="M279.989 99.1465L303.828 107.559L310.386 135.924L309.856 136.491L287.187 128.027L279.452 99.7132L279.989 99.1465Z"
        fill="#726EFF"
      />
      <path
        opacity="0.35"
        d="M303.291 108.126L309.856 136.491L310.386 135.925L303.828 107.559L303.291 108.126Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M279.452 99.7134L303.291 108.126L309.856 136.491L287.187 128.027L279.452 99.7134Z"
        fill="black"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M290.72 122.264C290.499 122.816 288.674 124.045 286.032 123.825L285.568 122.176L290.72 122.264Z"
          fill="black"
        />
      </g>
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M286.635 105.528L286.436 104.571C286.333 104.048 287.732 104.321 289.564 105.189L293.87 106.492C295.703 107.36 297.432 108.001 297.55 108.531L297.749 109.48L286.635 105.528Z"
          fill="black"
        />
      </g>
      <path
        d="M285.222 101.222C285.149 100.891 285.693 100.964 286.459 101.163L287.828 101.634C287.828 101.634 287.268 100.251 290.521 101.134C294.091 102.098 294.201 103.886 294.201 103.886C294.746 104.126 295.304 104.335 295.872 104.512C297.057 104.895 297.19 105.13 297.285 105.461L297.462 106.065L285.311 101.789C285.311 101.789 285.288 101.568 285.222 101.222Z"
        fill="#37474F"
      />
      <path
        d="M286.495 103.04C287.621 103.548 288.092 103.776 288.254 104.608C288.446 105.535 288.49 105.918 288.49 105.918L294.82 108.413C294.82 108.413 294.474 107.051 294.393 106.602C294.312 106.153 295.129 106.139 296.24 106.646L295.975 105.513L286.142 101.833L286.495 103.04Z"
        fill="#37474F"
      />
      <path
        d="M286.304 105.542L286.105 104.593C285.995 104.063 287.393 104.343 289.233 105.204L293.531 106.507C295.364 107.375 297.101 107.979 297.211 108.545L297.403 109.495L286.304 105.542Z"
        fill="#37474F"
      />
      <path
        d="M282.109 109.421C282.109 109.421 282.948 112.527 283.169 113.314C283.39 114.102 287.298 115.905 289.255 116.148C292.994 116.612 293.414 117.429 292.516 120.078C291.684 122.529 289.255 123.449 285.892 123.022C284.832 122.818 283.845 122.336 283.032 121.626C282.219 120.917 281.608 120.004 281.263 118.982C280.173 115.854 280.762 112.512 280.328 110.687C279.894 108.862 281.152 108.332 282.109 109.421Z"
        fill="#B16668"
      />
      <path
        d="M263.054 271.378C263.135 269.17 261.523 247.347 261.523 247.347L272.136 255.046C269.928 268.051 269.494 271.113 269.457 273.24C269.501 273.418 269.521 273.601 269.516 273.785C269.516 276.309 266.653 277.671 264.43 279.356C262.542 280.836 260.53 282.151 258.417 283.286C256.805 284.096 253.522 284.169 253.118 283.132C255.819 281.064 259.742 277.98 261.906 275.61C262.936 274.484 262.98 273.593 263.054 271.378Z"
        fill="#FFA8A7"
      />
      <path
        d="M248.503 285.848C248.362 286.218 248.325 286.619 248.397 287.008C248.468 287.398 248.645 287.76 248.908 288.056C250.888 289.433 253.327 289.987 255.708 289.601C259.079 289.167 260.904 288.129 262.259 286.989C263.613 285.848 265.497 284.265 267.021 283.927C268.242 283.7 269.404 283.227 270.436 282.536C270.885 282.102 270.826 280.151 270.826 280.151L248.503 285.848Z"
        fill="#455A64"
      />
      <path
        d="M269.479 272.622C269.603 272.627 269.722 272.676 269.815 272.759C269.907 272.842 269.968 272.955 269.987 273.078C270.097 273.615 270.208 275.176 270.428 276.059C270.776 277.611 270.888 279.206 270.76 280.791C270.576 281.792 268.264 282.484 266.785 283.044C265.046 283.803 263.433 284.823 262.001 286.069C259.932 287.617 257.461 288.536 254.884 288.718C252.286 288.718 249.357 287.857 248.68 286.65C247.855 285.178 248.503 284.295 252.36 282.506C253.096 282.168 255.657 280.652 256.533 280.1C258.895 278.628 261.236 276.537 262.325 273.902C262.413 273.58 262.607 273.297 262.877 273.1C263.109 273.001 263.367 272.978 263.613 273.034C264.259 273.123 264.887 273.312 265.475 273.593C265.99 273.836 266.726 274.123 266.712 274.786C266.703 275.018 266.653 275.248 266.564 275.463C266.52 275.595 266.483 275.779 266.601 275.853C266.719 275.926 266.866 275.853 266.962 275.757C267.343 275.369 267.693 274.95 268.007 274.506C268.182 274.273 268.387 274.065 268.618 273.888C268.844 273.758 269.063 273.616 269.273 273.461C269.523 273.225 269.472 272.931 269.479 272.622Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M269.479 272.622C269.603 272.627 269.722 272.676 269.815 272.759C269.907 272.842 269.968 272.955 269.987 273.078C270.097 273.615 270.208 275.176 270.428 276.059C270.776 277.611 270.888 279.206 270.76 280.791C270.576 281.792 268.264 282.484 266.785 283.044C265.046 283.803 263.433 284.823 262.001 286.069C259.932 287.617 257.461 288.536 254.884 288.718C252.286 288.718 249.357 287.857 248.68 286.65C247.855 285.178 248.503 284.295 252.36 282.506C253.096 282.168 255.657 280.652 256.533 280.1C258.895 278.628 261.236 276.537 262.325 273.902C262.413 273.58 262.607 273.297 262.877 273.1C263.109 273.001 263.367 272.978 263.613 273.034C264.259 273.123 264.887 273.312 265.475 273.593C265.99 273.836 266.726 274.123 266.712 274.786C266.703 275.018 266.653 275.248 266.564 275.463C266.52 275.595 266.483 275.779 266.601 275.853C266.719 275.926 266.866 275.853 266.962 275.757C267.343 275.369 267.693 274.95 268.007 274.506C268.182 274.273 268.387 274.065 268.618 273.888C268.844 273.758 269.063 273.616 269.273 273.461C269.523 273.225 269.472 272.931 269.479 272.622Z"
        fill="white"
      />
      <path
        d="M262.016 286.068C262.693 285.516 263.407 284.979 264.121 284.501C264.121 284.501 259.587 287.261 257.754 285.973C257.415 285.755 257.167 285.421 257.059 285.033C256.95 284.644 256.988 284.23 257.166 283.868C257.166 283.559 254.28 282.219 253.132 282.079C252.808 282.256 252.543 282.403 252.396 282.477C248.54 284.265 247.885 285.134 248.716 286.62C249.393 287.827 251.513 288.77 254.877 288.77C257.04 288.799 260.419 287.379 262.016 286.068Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M262.016 286.068C262.693 285.516 263.407 284.979 264.121 284.501C264.121 284.501 259.587 287.261 257.754 285.973C257.415 285.755 257.167 285.421 257.059 285.033C256.95 284.644 256.988 284.23 257.166 283.868C257.166 283.559 254.28 282.219 253.132 282.079C252.808 282.256 252.543 282.403 252.396 282.477C248.54 284.265 247.885 285.134 248.716 286.62C249.393 287.827 251.513 288.77 254.877 288.77C257.04 288.799 260.419 287.379 262.016 286.068Z"
        fill="white"
      />
      <path
        d="M257.467 282.985C255.068 283.949 256.091 284.398 256.731 284.494C257.372 284.589 261.442 282.227 263.9 280.284C266.358 278.341 268.088 276.029 269.052 275.279C269.405 274.999 269.788 274.661 270.134 274.344C270.068 273.814 270.016 273.328 269.965 273.078C269.946 272.955 269.885 272.842 269.793 272.759C269.7 272.676 269.581 272.627 269.457 272.622C269.457 272.931 269.501 273.225 269.265 273.461C269.056 273.616 268.837 273.758 268.61 273.888C268.38 274.065 268.174 274.273 268 274.506C267.685 274.95 267.336 275.369 266.954 275.757C264.287 278.755 261.065 281.209 257.467 282.985V282.985Z"
        fill="#726EFF"
      />
      <path
        d="M256.93 284.14C256.987 284.139 257.041 284.118 257.085 284.081C257.127 284.036 257.15 283.977 257.15 283.916C257.15 283.854 257.127 283.795 257.085 283.75C256.517 283.16 255.828 282.7 255.066 282.402C254.304 282.103 253.486 281.973 252.669 282.02C252.638 282.024 252.608 282.033 252.581 282.048C252.553 282.063 252.529 282.083 252.51 282.108C252.49 282.132 252.476 282.16 252.467 282.19C252.458 282.22 252.456 282.251 252.459 282.282C252.462 282.313 252.472 282.343 252.487 282.37C252.502 282.397 252.522 282.421 252.546 282.441C252.57 282.46 252.598 282.475 252.628 282.483C252.658 282.492 252.689 282.495 252.72 282.492C253.455 282.461 254.187 282.584 254.872 282.853C255.556 283.122 256.177 283.53 256.695 284.052C256.722 284.087 256.758 284.114 256.8 284.13C256.841 284.146 256.887 284.149 256.93 284.14V284.14Z"
        fill="#FAFAFA"
      />
      <path
        d="M259.021 282.852C259.076 282.851 259.13 282.833 259.175 282.801C259.198 282.779 259.216 282.752 259.228 282.723C259.24 282.694 259.247 282.663 259.247 282.631C259.247 282.6 259.24 282.568 259.228 282.539C259.216 282.51 259.198 282.484 259.175 282.462C258.605 281.875 257.916 281.417 257.155 281.118C256.393 280.82 255.576 280.688 254.759 280.732C254.698 280.74 254.642 280.771 254.603 280.819C254.565 280.867 254.547 280.929 254.553 280.99C254.556 281.021 254.565 281.051 254.579 281.079C254.594 281.106 254.613 281.131 254.637 281.15C254.661 281.17 254.689 281.185 254.719 281.194C254.748 281.203 254.78 281.206 254.811 281.203C255.545 281.172 256.278 281.295 256.963 281.564C257.647 281.832 258.268 282.241 258.785 282.764C258.812 282.799 258.849 282.825 258.89 282.841C258.932 282.856 258.977 282.86 259.021 282.852Z"
        fill="#FAFAFA"
      />
      <path
        d="M261.118 281.631C261.172 281.631 261.224 281.613 261.265 281.579C261.309 281.534 261.334 281.473 261.334 281.41C261.334 281.347 261.309 281.286 261.265 281.24C260.696 280.653 260.007 280.195 259.245 279.896C258.483 279.598 257.666 279.466 256.849 279.511C256.786 279.518 256.728 279.55 256.688 279.6C256.648 279.649 256.629 279.713 256.636 279.776C256.639 279.807 256.649 279.837 256.664 279.864C256.68 279.891 256.7 279.915 256.725 279.934C256.75 279.954 256.778 279.968 256.808 279.976C256.838 279.984 256.87 279.986 256.901 279.982C257.635 279.952 258.368 280.075 259.052 280.343C259.736 280.612 260.357 281.02 260.875 281.542C260.901 281.581 260.939 281.61 260.982 281.626C261.026 281.641 261.073 281.643 261.118 281.631V281.631Z"
        fill="#FAFAFA"
      />
      <path
        d="M263.054 280.239C263.11 280.238 263.165 280.217 263.208 280.181C263.25 280.136 263.273 280.076 263.273 280.015C263.273 279.953 263.25 279.894 263.208 279.849C262.64 279.26 261.951 278.801 261.189 278.502C260.427 278.204 259.609 278.073 258.792 278.12C258.762 278.122 258.732 278.131 258.705 278.146C258.677 278.16 258.653 278.18 258.634 278.204C258.615 278.229 258.601 278.256 258.593 278.286C258.584 278.316 258.582 278.347 258.586 278.377C258.588 278.409 258.596 278.439 258.61 278.467C258.625 278.495 258.644 278.52 258.668 278.54C258.693 278.56 258.721 278.575 258.751 278.584C258.781 278.592 258.813 278.595 258.844 278.591C259.578 278.561 260.311 278.684 260.995 278.952C261.68 279.221 262.3 279.629 262.818 280.151C262.846 280.186 262.882 280.213 262.924 280.228C262.965 280.244 263.01 280.248 263.054 280.239V280.239Z"
        fill="#FAFAFA"
      />
      <path
        d="M264.96 278.848C265.004 278.846 265.046 278.831 265.083 278.807C265.12 278.782 265.149 278.748 265.169 278.709C265.188 278.669 265.197 278.625 265.194 278.581C265.191 278.537 265.176 278.495 265.151 278.458C264.537 277.821 263.786 277.332 262.954 277.029C262.123 276.725 261.233 276.615 260.353 276.707C260.321 276.708 260.291 276.717 260.263 276.731C260.235 276.745 260.21 276.765 260.19 276.789C260.17 276.813 260.155 276.841 260.146 276.871C260.137 276.901 260.135 276.933 260.139 276.964C260.147 277.027 260.178 277.085 260.228 277.125C260.278 277.165 260.341 277.184 260.404 277.178C261.204 277.098 262.012 277.198 262.768 277.47C263.525 277.743 264.211 278.181 264.776 278.753C264.798 278.781 264.825 278.805 264.857 278.821C264.889 278.838 264.924 278.847 264.96 278.848V278.848Z"
        fill="#FAFAFA"
      />
      <path
        d="M283.544 255.12L294.253 254.928C294.098 262.075 292.957 270.009 292.781 276.721C292.744 277.98 292.781 286.532 292.339 289.13C291.603 293.031 286.988 292.508 286.738 290.168C286.488 287.827 287.04 277.855 287.025 276.824C287.025 272.519 285.045 265.262 283.993 257.534C283.934 257.158 283.595 255.488 283.544 255.12Z"
        fill="#FFA8A7"
      />
      <path
        d="M284.986 291.794C284.891 294.091 285.089 295.636 285.767 297.057C286.108 297.724 286.661 298.259 287.339 298.577C288.017 298.896 288.782 298.98 289.513 298.816C291.095 298.573 293.193 297.727 293.826 295.806C294.403 294.057 294.589 292.203 294.37 290.374L284.986 291.794Z"
        fill="#455A64"
      />
      <path
        d="M292.803 275.897C293.428 276.015 293.311 279.091 293.369 282.197C293.436 285.524 294.282 286.981 294.356 289.712C294.451 292.832 294.15 294.187 292.884 295.946C291.618 297.705 287.614 299.14 286.002 296.284C284.685 293.958 284.869 291.537 285.318 288.475C285.759 285.89 286.068 283.283 286.245 280.666C286.355 278.348 286.414 276.103 286.981 275.897C286.981 276.243 286.981 276.677 286.981 277.016C286.981 277.354 286.929 278.525 287.283 278.576C287.337 278.289 287.369 277.999 287.378 277.708C287.362 277.439 287.452 277.174 287.629 276.972C287.839 276.809 288.099 276.723 288.365 276.729C289.003 276.661 289.644 276.627 290.286 276.626C290.608 276.627 290.93 276.647 291.25 276.684C291.523 276.707 291.791 276.772 292.045 276.876C292.117 276.909 292.182 276.957 292.236 277.016C292.289 277.076 292.329 277.146 292.354 277.222C292.376 277.341 292.376 277.463 292.354 277.582C292.328 277.795 292.328 278.01 292.354 278.223C292.354 278.311 292.42 278.429 292.516 278.414C292.611 278.399 292.626 278.318 292.633 278.245C292.678 277.869 292.633 277.597 292.685 277.163C292.685 276.964 292.685 276.685 292.685 276.486C292.685 276.287 292.795 276.096 292.803 275.897Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M292.803 275.897C293.428 276.015 293.311 279.091 293.369 282.197C293.436 285.524 294.282 286.981 294.356 289.712C294.451 292.832 294.15 294.187 292.884 295.946C291.618 297.705 287.614 299.14 286.002 296.284C284.685 293.958 284.869 291.537 285.318 288.475C285.759 285.89 286.068 283.283 286.245 280.666C286.355 278.348 286.414 276.103 286.981 275.897C286.981 276.243 286.981 276.677 286.981 277.016C286.981 277.354 286.929 278.525 287.283 278.576C287.337 278.289 287.369 277.999 287.378 277.708C287.362 277.439 287.452 277.174 287.629 276.972C287.839 276.809 288.099 276.723 288.365 276.729C289.003 276.661 289.644 276.627 290.286 276.626C290.608 276.627 290.93 276.647 291.25 276.684C291.523 276.707 291.791 276.772 292.045 276.876C292.117 276.909 292.182 276.957 292.236 277.016C292.289 277.076 292.329 277.146 292.354 277.222C292.376 277.341 292.376 277.463 292.354 277.582C292.328 277.795 292.328 278.01 292.354 278.223C292.354 278.311 292.42 278.429 292.516 278.414C292.611 278.399 292.626 278.318 292.633 278.245C292.678 277.869 292.633 277.597 292.685 277.163C292.685 276.964 292.685 276.685 292.685 276.486C292.685 276.287 292.795 276.096 292.803 275.897Z"
        fill="white"
      />
      <path
        d="M294.304 288.821C294.304 289.27 294.4 292.251 293.568 292.7C292.736 293.149 292.398 290.823 292.398 290.823C292.398 290.823 290.433 289.999 287.342 291C287.445 291.486 286.834 293.016 286.054 292.604C285.273 292.192 285.318 288.784 285.318 288.74C284.898 291.684 284.766 294.025 286.054 296.284C287.665 299.14 291.669 297.697 292.935 295.946C294.201 294.194 294.503 292.832 294.407 289.712C294.348 289.403 294.326 289.108 294.304 288.821Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M294.304 288.821C294.304 289.27 294.4 292.251 293.568 292.7C292.736 293.149 292.398 290.823 292.398 290.823C292.398 290.823 290.433 289.999 287.342 291C287.445 291.486 286.834 293.016 286.054 292.604C285.273 292.192 285.318 288.784 285.318 288.74C284.898 291.684 284.766 294.025 286.054 296.284C287.665 299.14 291.669 297.697 292.935 295.946C294.201 294.194 294.503 292.832 294.407 289.712C294.348 289.403 294.326 289.108 294.304 288.821Z"
        fill="white"
      />
      <path
        d="M292.126 291C292.03 292.111 293.163 291.846 293.369 290.264C293.575 288.681 293.09 284.876 293.369 282.168C293.311 279.062 293.428 275.985 292.803 275.867C292.799 275.907 292.799 275.946 292.803 275.985L292.729 277.546C292.729 277.774 292.729 277.98 292.692 278.223C292.486 280.489 292.361 284.913 292.346 287.003C292.351 288.338 292.277 289.673 292.126 291V291Z"
        fill="#726EFF"
      />
      <path
        d="M287.666 291C287.525 289.682 287.454 288.358 287.452 287.033C287.452 284.957 287.32 280.585 287.106 278.304C287.032 277.879 287.002 277.447 287.018 277.016C287.018 276.699 287.018 276.28 287.018 275.971V275.904C286.598 276.051 286.451 277.281 286.355 278.848C286.355 279.746 286.355 280.858 286.444 282.212C286.723 284.913 286.23 288.755 286.444 290.308C286.657 291.861 287.776 292.111 287.666 291Z"
        fill="#726EFF"
      />
      <path
        d="M287.246 291.081H287.334C288.94 290.518 290.689 290.518 292.295 291.081C292.352 291.092 292.411 291.083 292.461 291.055C292.512 291.028 292.551 290.982 292.571 290.928C292.592 290.874 292.592 290.815 292.573 290.76C292.553 290.706 292.514 290.66 292.464 290.632C290.748 290.022 288.874 290.022 287.158 290.632C287.1 290.656 287.054 290.702 287.029 290.759C287.004 290.817 287.003 290.882 287.025 290.941C287.045 290.982 287.077 291.017 287.116 291.042C287.155 291.066 287.2 291.08 287.246 291.081Z"
        fill="#FAFAFA"
      />
      <path
        d="M286.959 288.608H287.04C288.846 288.041 290.783 288.041 292.589 288.608C292.65 288.626 292.715 288.621 292.772 288.592C292.828 288.563 292.871 288.513 292.891 288.453C292.902 288.424 292.906 288.392 292.904 288.361C292.902 288.33 292.894 288.3 292.881 288.271C292.867 288.243 292.848 288.218 292.824 288.198C292.801 288.177 292.774 288.161 292.744 288.151C290.829 287.54 288.771 287.54 286.856 288.151C286.804 288.171 286.761 288.207 286.734 288.255C286.706 288.303 286.696 288.359 286.706 288.413C286.716 288.468 286.744 288.517 286.786 288.552C286.828 288.588 286.882 288.607 286.937 288.608H286.959Z"
        fill="#FAFAFA"
      />
      <path
        d="M292.67 286.304C292.716 286.305 292.76 286.292 292.798 286.267C292.837 286.242 292.866 286.206 292.884 286.164C292.909 286.107 292.911 286.041 292.889 285.982C292.867 285.923 292.823 285.875 292.766 285.848C290.883 285.124 288.798 285.124 286.915 285.848C286.886 285.859 286.859 285.876 286.837 285.898C286.814 285.92 286.796 285.946 286.784 285.975C286.771 286.003 286.765 286.034 286.765 286.066C286.764 286.097 286.77 286.128 286.782 286.157C286.807 286.215 286.853 286.261 286.91 286.285C286.968 286.31 287.033 286.311 287.091 286.289C287.091 286.289 290.036 285.149 292.567 286.289C292.6 286.301 292.635 286.306 292.67 286.304Z"
        fill="#FAFAFA"
      />
      <path
        d="M292.722 284.096C292.767 284.097 292.812 284.084 292.85 284.059C292.888 284.034 292.918 283.998 292.935 283.956C292.949 283.928 292.957 283.898 292.959 283.867C292.961 283.836 292.956 283.804 292.946 283.775C292.936 283.745 292.92 283.718 292.899 283.695C292.878 283.672 292.853 283.653 292.825 283.64C291.89 283.212 290.875 282.986 289.847 282.974C288.819 282.963 287.8 283.167 286.856 283.574C286.828 283.587 286.803 283.606 286.782 283.629C286.761 283.652 286.745 283.679 286.735 283.709C286.724 283.738 286.72 283.769 286.722 283.8C286.724 283.832 286.732 283.862 286.746 283.89C286.773 283.947 286.821 283.991 286.88 284.013C286.939 284.035 287.004 284.033 287.062 284.008C287.943 283.637 288.891 283.451 289.846 283.461C290.802 283.471 291.746 283.677 292.619 284.067C292.649 284.086 292.685 284.097 292.722 284.096Z"
        fill="#FAFAFA"
      />
      <path
        d="M292.773 281.645C292.826 281.642 292.877 281.622 292.917 281.588C292.958 281.554 292.986 281.508 292.998 281.456C293.01 281.404 293.004 281.35 292.982 281.302C292.96 281.254 292.923 281.214 292.876 281.189C290.043 279.79 286.988 281.13 286.863 281.189C286.806 281.214 286.761 281.261 286.738 281.32C286.715 281.379 286.716 281.444 286.742 281.502C286.767 281.559 286.814 281.604 286.873 281.627C286.932 281.65 286.997 281.648 287.055 281.623C287.055 281.623 290.035 280.32 292.67 281.623C292.701 281.641 292.738 281.649 292.773 281.645V281.645Z"
        fill="#EBEBEB"
      />
      <path
        d="M288.887 181.144C290.631 185.158 291.765 189.41 292.251 193.759C293.473 209.591 292.81 232.311 292.81 232.311C292.95 233.967 294.002 237.721 294.702 245C295.541 253.604 294.496 282.749 294.496 282.749C294.496 282.749 289.344 285.811 285.347 282.322C285.347 282.322 280.688 242.254 279.614 233.746C278.672 226.283 276.037 210.135 276.037 210.135L272.96 234.011C272.96 234.011 273.247 240.731 273.049 245.051C272.813 250.1 271.4 275.823 271.4 275.823C271.4 275.823 264.334 278.215 261.177 274.815C261.177 274.815 260.441 239.141 260.316 233.062C260.11 221.146 260.316 199.434 262.251 186.937L288.887 181.144Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M288.887 181.144C290.631 185.158 291.765 189.41 292.251 193.759C293.473 209.591 292.81 232.311 292.81 232.311C292.95 233.967 294.002 237.721 294.702 245C295.541 253.604 294.496 282.749 294.496 282.749C294.496 282.749 289.344 285.811 285.347 282.322C285.347 282.322 280.688 242.254 279.614 233.746C278.672 226.283 276.037 210.135 276.037 210.135L272.96 234.011C272.96 234.011 273.247 240.731 273.049 245.051C272.813 250.1 271.4 275.823 271.4 275.823C271.4 275.823 264.334 278.215 261.177 274.815C261.177 274.815 260.441 239.141 260.316 233.062C260.11 221.146 260.316 199.434 262.251 186.937L288.887 181.144Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M276.037 210.099L274.933 202.496C274.933 202.496 268.515 201.642 265.608 198.911C266.442 200.213 267.552 201.316 268.859 202.141C270.167 202.967 271.64 203.495 273.174 203.688L274.307 210.555L273.328 231.104L276.037 210.099Z"
        fill="black"
      />
      <path
        d="M280.556 169.53C281.833 169.627 283.117 169.575 284.383 169.376C285.298 169.242 286.172 168.909 286.944 168.4C287.716 167.892 288.367 167.22 288.85 166.432C289.052 165.937 289.314 165.47 289.631 165.041C290.19 164.43 291.103 164.253 291.839 163.856C292.466 163.486 292.999 162.976 293.396 162.366C293.794 161.755 294.044 161.061 294.127 160.338C294.272 158.899 294.036 157.447 293.443 156.128C292.906 154.788 291.824 153.566 291.176 152.271C289.838 149.969 289.036 147.396 288.828 144.742C288.622 142.158 288.711 139.487 288.622 136.881C288.527 133.937 288.46 129.801 284.905 126.577C284.025 125.782 282.908 125.298 281.726 125.201C280.254 122.507 277.31 121.322 274.852 120.903C272.782 120.596 270.676 120.631 268.618 121.006C266.544 121.325 264.611 122.249 263.061 123.663C261.628 125.053 260.78 126.938 260.691 128.932C260.634 130.548 261.189 132.124 262.244 133.348C261.726 133.82 261.36 134.435 261.191 135.115C261.007 136.587 261.39 138.331 263.878 139.281L267.499 142.644C266.876 145.014 266.494 147.44 266.358 149.886C266.358 151.454 266.358 153.029 266.505 154.589C266.895 159.256 268.117 164.783 272.342 167.639C273.572 168.452 274.958 169.001 276.412 169.251C277.001 169.371 277.599 169.44 278.2 169.457C278.944 169.449 279.761 169.493 280.556 169.53Z"
        fill="#726EFF"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M280.556 169.53C281.833 169.627 283.117 169.575 284.383 169.376C285.298 169.242 286.172 168.909 286.944 168.4C287.716 167.892 288.367 167.22 288.85 166.432C289.052 165.937 289.314 165.47 289.631 165.041C290.19 164.43 291.103 164.253 291.839 163.856C292.466 163.486 292.999 162.976 293.396 162.366C293.794 161.755 294.044 161.061 294.127 160.338C294.272 158.899 294.036 157.447 293.443 156.128C292.906 154.788 291.824 153.566 291.176 152.271C289.838 149.969 289.036 147.396 288.828 144.742C288.622 142.158 288.711 139.487 288.622 136.881C288.527 133.937 288.46 129.801 284.905 126.577C284.025 125.782 282.908 125.298 281.726 125.201C280.254 122.507 277.31 121.322 274.852 120.903C272.782 120.596 270.676 120.631 268.618 121.006C266.544 121.325 264.611 122.249 263.061 123.663C261.628 125.053 260.78 126.938 260.691 128.932C260.634 130.548 261.189 132.124 262.244 133.348C261.726 133.82 261.36 134.435 261.191 135.115C261.007 136.587 261.39 138.331 263.878 139.281L267.499 142.644C266.876 145.014 266.494 147.44 266.358 149.886C266.358 151.454 266.358 153.029 266.505 154.589C266.895 159.256 268.117 164.783 272.342 167.639C273.572 168.452 274.958 169.001 276.412 169.251C277.001 169.371 277.599 169.44 278.2 169.457C278.944 169.449 279.761 169.493 280.556 169.53Z"
          fill="black"
        />
      </g>
      <path
        d="M257.651 189.778L262.664 195.989C262.664 195.989 266.152 190.205 266.012 190.205C265.872 190.205 261.596 183.19 261.596 183.19L257.651 189.778Z"
        fill="#FFC4C3"
      />
      <path
        d="M267.786 149.923C263.849 149.864 261.736 151.631 258.844 156.165C256.386 160.029 250.682 169.28 249.077 172.07C247.266 175.22 247.009 176.486 248.429 178.855C249.776 181.063 258.373 190.985 258.373 190.985C260.336 189.29 261.816 187.106 262.664 184.655L257.651 176.272L267.286 162.03L267.786 149.923Z"
        fill="#455A64"
      />
      <path
        d="M268.036 149.747C265.188 149.599 263.422 150.188 261.707 151.852C259.992 153.515 251.16 168.323 251.16 168.323C251.16 168.323 251.579 170.965 259.035 174.947L267.168 165.416L268.036 149.747Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M268.036 149.747C265.188 149.599 263.422 150.188 261.707 151.852C259.992 153.515 251.16 168.323 251.16 168.323C251.16 168.323 251.579 170.965 259.035 174.947L267.168 165.416L268.036 149.747Z"
        fill="white"
      />
      <path
        d="M262.752 169.707C263.032 172.827 263.105 178.936 263.105 178.936C261.972 186.914 261.339 194.76 261.339 194.76C273.255 201.127 290.977 195.923 293.237 193.288C292.118 185.472 289.653 180.607 288.041 176.206L291.029 162.906C291.029 162.906 285.877 158.63 286.348 149.953L279.304 149.217L272.585 149.408L267.808 149.746C265.414 152.072 263.318 154.685 261.567 157.526C259.226 161.294 259.138 165.946 262.752 169.707Z"
        fill="#726EFF"
      />
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M262.752 169.707C263.032 172.827 263.105 178.936 263.105 178.936C261.972 186.914 261.339 194.76 261.339 194.76C273.255 201.127 290.977 195.923 293.237 193.288C292.118 185.472 289.653 180.607 288.041 176.206L291.029 162.906C291.029 162.906 285.877 158.63 286.348 149.953L279.304 149.217L272.585 149.408L267.808 149.746C265.414 152.072 263.318 154.685 261.567 157.526C259.226 161.294 259.138 165.946 262.752 169.707Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.1"
        d="M271.886 169.398C271.886 169.398 274.999 172.15 278.819 171.061C279.888 170.797 280.87 170.261 281.671 169.505C282.472 168.75 283.064 167.8 283.389 166.748C283.389 166.748 283.515 171.046 278.708 172.194C277.422 172.523 276.063 172.428 274.835 171.925C273.606 171.421 272.572 170.535 271.886 169.398V169.398Z"
        fill="black"
      />
      <path
        d="M266.969 127.21C265.497 127.99 263.341 130.831 263.65 138.684C263.893 144.837 265.681 146.412 266.712 146.876C267.742 147.34 269.766 147.119 271.731 146.876L271.635 150.556C271.635 150.556 268.036 156.628 270.06 159.123C273.08 158.381 275.829 156.799 277.987 154.56C280.195 152.352 280.195 150.519 280.195 150.519L280.357 142.482C280.357 142.482 281.476 143.586 283.47 141.989C285.119 140.672 285.715 138.405 284.493 137.146C284.172 136.846 283.787 136.623 283.366 136.495C282.945 136.368 282.501 136.338 282.067 136.409C281.634 136.48 281.222 136.65 280.864 136.905C280.506 137.161 280.212 137.495 280.004 137.882C280.004 137.882 277.796 137.978 277.428 133.893C277.428 133.893 274.152 133.599 272.357 132.679C268.257 130.633 267.44 128.226 266.969 127.21Z"
        fill="#FFC4C3"
      />
      <path
        d="M274.822 153.228C271.488 153.228 271.142 152.293 271.51 150.74C270.907 151.83 268.257 156.871 270.038 159.094C273.062 158.36 275.817 156.789 277.987 154.56C279.112 153.592 279.888 152.281 280.195 150.829C279.356 152.234 277.472 153.257 274.822 153.228Z"
        fill="#455A64"
      />
      <path
        d="M271.731 146.825C274.55 146.148 278.061 144.757 278.767 143.145C278.379 144.183 277.736 145.106 276.898 145.831C275.602 146.847 271.694 148.039 271.694 148.039L271.731 146.825Z"
        fill="#F28F8F"
      />
      <path
        d="M272.783 135.851C272.769 136.088 272.847 136.321 273 136.502C273.154 136.684 273.372 136.798 273.608 136.822C273.837 136.856 274.07 136.798 274.257 136.66C274.443 136.522 274.567 136.316 274.601 136.086C274.616 135.85 274.538 135.618 274.384 135.438C274.23 135.258 274.013 135.145 273.777 135.122C273.549 135.088 273.317 135.146 273.13 135.282C272.944 135.419 272.82 135.623 272.783 135.851Z"
        fill="black"
      />
      <path
        d="M272.519 141.665L269.376 142.438C269.424 142.646 269.514 142.842 269.639 143.015C269.764 143.188 269.922 143.335 270.104 143.446C270.287 143.557 270.489 143.63 270.7 143.662C270.912 143.694 271.127 143.683 271.334 143.63C271.749 143.523 272.105 143.257 272.327 142.889C272.548 142.522 272.617 142.083 272.519 141.665V141.665Z"
        fill="#F28F8F"
      />
      <path
        d="M272.467 142.571C272.261 142.398 272 142.304 271.731 142.306C271.423 142.306 271.127 142.428 270.909 142.646C270.691 142.864 270.568 143.16 270.568 143.469C270.561 143.527 270.561 143.587 270.568 143.645C270.796 143.696 271.032 143.696 271.26 143.645C271.537 143.585 271.794 143.455 272.005 143.267C272.217 143.078 272.376 142.839 272.467 142.571V142.571Z"
        fill="#FFA8A7"
      />
      <path
        d="M265.078 133.738L266.756 132.59C266.681 132.48 266.586 132.386 266.474 132.313C266.363 132.24 266.239 132.19 266.108 132.165C265.977 132.141 265.843 132.142 265.713 132.17C265.583 132.197 265.46 132.25 265.35 132.325C265.239 132.4 265.144 132.496 265.07 132.608C264.997 132.719 264.946 132.844 264.92 132.975C264.895 133.107 264.896 133.242 264.923 133.373C264.95 133.504 265.003 133.628 265.078 133.738V133.738Z"
        fill="black"
      />
      <path
        d="M273.247 133.098L275.117 133.952C275.18 133.837 275.218 133.711 275.228 133.58C275.239 133.45 275.223 133.319 275.18 133.196C275.137 133.072 275.068 132.959 274.979 132.864C274.889 132.768 274.781 132.693 274.66 132.642C274.41 132.53 274.127 132.515 273.866 132.599C273.606 132.684 273.385 132.861 273.247 133.098V133.098Z"
        fill="black"
      />
      <path
        d="M265.424 135.424C265.407 135.66 265.483 135.892 265.636 136.072C265.789 136.252 266.006 136.366 266.241 136.388C266.354 136.406 266.47 136.401 266.582 136.373C266.694 136.346 266.799 136.296 266.891 136.228C266.984 136.159 267.062 136.073 267.12 135.974C267.179 135.876 267.218 135.766 267.234 135.652C267.247 135.42 267.172 135.191 267.023 135.012C266.874 134.833 266.663 134.717 266.432 134.688C266.317 134.667 266.199 134.67 266.085 134.696C265.971 134.722 265.864 134.771 265.77 134.84C265.675 134.908 265.596 134.996 265.537 135.096C265.477 135.197 265.439 135.308 265.424 135.424V135.424Z"
        fill="black"
      />
      <path
        d="M270.06 135.255L269.611 140.237L266.925 139.258L270.06 135.255Z"
        fill="#FFA8A7"
      />
      <path
        d="M296.88 185.177C296.368 186.027 295.803 186.843 295.187 187.621C294.704 188.317 293.981 188.81 293.156 189.005C291.46 189.228 289.791 189.62 288.173 190.175C286.753 190.624 285.855 190.506 285.803 190.867C285.745 191.338 285.995 191.882 287.275 192.155C288.219 192.262 289.172 192.262 290.116 192.155C288.99 193.49 287.679 194.657 286.223 195.621C283.345 197.52 282.366 198.955 285.362 200.773C287.57 202.105 291.36 201.745 296.623 196.836C299.154 194.357 301.449 191.646 303.475 188.74L296.88 185.177Z"
        fill="#FFC4C3"
      />
      <path
        d="M303.232 189.527C311.703 178.966 311.843 177.177 309.348 173.483C294.525 151.535 294.577 151.719 287.165 150.151C283.941 155.701 287.165 161.11 289.851 164.135L300.641 177.486C299.221 180.497 297.697 183.374 296.608 185.31C296.608 185.31 299.309 188.652 303.232 189.527Z"
        fill="#455A64"
      />
      <path
        d="M286.304 149.967C290.367 150.151 293.355 150.703 296.211 154.214C299.265 157.938 306.345 168.198 306.345 168.198C306.345 168.198 304.446 174.042 296.821 176.007L287.217 163.672C287.217 163.672 283.728 156.768 286.304 149.967Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M286.304 149.967C290.367 150.151 293.355 150.703 296.211 154.214C299.265 157.938 306.345 168.198 306.345 168.198C306.345 168.198 304.446 174.042 296.821 176.007L287.217 163.672C287.217 163.672 283.728 156.768 286.304 149.967Z"
        fill="white"
      />
      <path
        d="M280.924 149.408C282.182 149.68 282.521 154.972 282.219 161.39C282.168 162.45 282.094 163.289 282.116 163.709H282.852C282.852 163.326 282.889 162.502 282.955 161.501C283.271 156.43 283.308 149.062 281.1 148.724C280.807 148.65 280.504 148.622 280.202 148.643V149.379L280.924 149.408Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M280.924 149.408C282.182 149.68 282.521 154.972 282.219 161.39C282.168 162.45 282.094 163.289 282.116 163.709H282.852C282.852 163.326 282.889 162.502 282.955 161.501C283.271 156.43 283.308 149.062 281.1 148.724C280.807 148.65 280.504 148.622 280.202 148.643V149.379L280.924 149.408Z"
        fill="black"
      />
      <path
        d="M281.792 179.68C281.137 180.109 280.367 180.329 279.584 180.313L279.643 179.819C280.24 179.822 280.826 179.659 281.336 179.348C280.849 178.968 280.424 178.513 280.077 178.002C279.341 176.898 278.237 169.324 278.929 167.639C279.503 166.24 281.174 163.223 282.44 163.223H282.477C283.75 163.274 285.178 166.483 285.634 167.94C286.179 169.677 284.412 177.126 283.603 178.163C283.215 178.643 282.755 179.06 282.241 179.4C282.72 179.754 283.289 179.966 283.882 180.011V180.511H283.824C283.081 180.432 282.377 180.144 281.792 179.68V179.68ZM279.408 167.808C278.775 169.354 279.849 176.677 280.512 177.707C280.871 178.223 281.311 178.677 281.814 179.054C282.345 178.722 282.82 178.307 283.22 177.825C283.956 176.853 285.671 169.648 285.17 168.051C284.56 166.1 283.213 163.716 282.469 163.686C281.696 163.708 280.173 165.953 279.408 167.808Z"
        fill="#455A64"
      />
      <path
        d="M278.892 180.033C278.892 180.228 278.97 180.415 279.108 180.553C279.246 180.691 279.433 180.769 279.628 180.769C279.774 180.769 279.916 180.726 280.037 180.645C280.158 180.564 280.253 180.449 280.308 180.315C280.364 180.18 280.379 180.032 280.35 179.889C280.322 179.747 280.252 179.615 280.149 179.512C280.046 179.41 279.915 179.339 279.772 179.311C279.629 179.283 279.481 179.297 279.347 179.353C279.212 179.409 279.097 179.503 279.016 179.624C278.936 179.745 278.892 179.887 278.892 180.033Z"
        fill="#263238"
      />
      <path
        d="M283.168 180.217C283.168 180.363 283.212 180.505 283.293 180.626C283.373 180.747 283.488 180.841 283.623 180.897C283.757 180.953 283.905 180.967 284.048 180.939C284.191 180.91 284.322 180.84 284.425 180.737C284.528 180.634 284.598 180.503 284.626 180.361C284.655 180.218 284.64 180.07 284.584 179.935C284.529 179.801 284.434 179.686 284.313 179.605C284.192 179.524 284.05 179.481 283.904 179.481C283.709 179.481 283.522 179.558 283.384 179.697C283.246 179.835 283.168 180.022 283.168 180.217Z"
        fill="#263238"
      />
      <path
        d="M278.819 170.921H279.238C279.282 170.917 279.322 170.897 279.351 170.864C279.38 170.831 279.395 170.788 279.393 170.745C279.277 169.797 279.32 168.837 279.518 167.904C280.254 166.1 281.792 163.834 282.462 163.863C283.132 163.892 284.464 166.277 285.053 168.139C285.173 169.086 285.133 170.046 284.935 170.98C284.93 171.024 284.942 171.067 284.968 171.103C284.994 171.138 285.032 171.163 285.075 171.171L285.494 171.238C285.538 171.242 285.582 171.229 285.616 171.201C285.65 171.174 285.673 171.134 285.678 171.09C285.907 170.048 285.937 168.972 285.767 167.918C285.45 166.917 283.978 163.178 282.491 163.112C281.005 163.046 279.223 166.652 278.811 167.624C278.551 168.66 278.489 169.737 278.627 170.796C278.636 170.838 278.661 170.875 278.697 170.898C278.733 170.922 278.777 170.93 278.819 170.921V170.921Z"
        fill="#726EFF"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M278.819 170.921H279.238C279.282 170.917 279.322 170.897 279.351 170.864C279.38 170.831 279.395 170.788 279.393 170.745C279.277 169.797 279.32 168.837 279.518 167.904C280.254 166.1 281.792 163.834 282.462 163.863C283.132 163.892 284.464 166.277 285.053 168.139C285.173 169.086 285.133 170.046 284.935 170.98C284.93 171.024 284.942 171.067 284.968 171.103C284.994 171.138 285.032 171.163 285.075 171.171L285.494 171.238C285.538 171.242 285.582 171.229 285.616 171.201C285.65 171.174 285.673 171.134 285.678 171.09C285.907 170.048 285.937 168.972 285.767 167.918C285.45 166.917 283.978 163.178 282.491 163.112C281.005 163.046 279.223 166.652 278.811 167.624C278.551 168.66 278.489 169.737 278.627 170.796C278.636 170.838 278.661 170.875 278.697 170.898C278.733 170.922 278.777 170.93 278.819 170.921V170.921Z"
          fill="black"
        />
      </g>
      <path
        d="M271.672 148.451C270.546 148.606 267.065 150.321 265.416 155.487C263.768 160.654 263.871 168.735 263.944 171.017C264.018 173.299 264.15 174.984 264.15 174.984H265.21C265.21 174.984 265.026 172.467 264.975 171.017C264.923 169.567 265.107 159.175 266.366 155.892C267.875 151.984 269.582 150.475 271.672 149.482V148.451Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M271.672 148.451C270.546 148.606 267.065 150.321 265.416 155.487C263.768 160.654 263.871 168.735 263.944 171.017C264.018 173.299 264.15 174.984 264.15 174.984H265.21C265.21 174.984 265.026 172.467 264.975 171.017C264.923 169.567 265.107 159.175 266.366 155.892C267.875 151.984 269.582 150.475 271.672 149.482V148.451Z"
        fill="black"
      />
      <path
        d="M267.595 175.367C267.595 175.949 267.422 176.518 267.099 177.002C266.775 177.487 266.315 177.864 265.777 178.087C265.239 178.31 264.648 178.368 264.076 178.254C263.505 178.141 262.981 177.86 262.569 177.449C262.157 177.037 261.877 176.512 261.763 175.941C261.65 175.37 261.708 174.778 261.931 174.24C262.154 173.702 262.531 173.242 263.015 172.919C263.499 172.596 264.069 172.423 264.651 172.423C265.432 172.423 266.18 172.733 266.733 173.285C267.285 173.837 267.595 174.586 267.595 175.367Z"
        fill="#455A64"
      />
      <path
        d="M264.673 177.03C265.592 177.03 266.336 176.285 266.336 175.367C266.336 174.448 265.592 173.703 264.673 173.703C263.754 173.703 263.01 174.448 263.01 175.367C263.01 176.285 263.754 177.03 264.673 177.03Z"
        fill="#37474F"
      />
      <path
        d="M201.715 237.198C201.579 237.569 201.545 237.969 201.616 238.357C201.687 238.746 201.861 239.108 202.12 239.406C204.093 240.781 206.524 241.336 208.899 240.952C212.262 240.517 214.088 239.48 215.434 238.346C216.781 237.213 218.666 235.63 220.189 235.292C221.404 235.062 222.56 234.592 223.589 233.908C224.038 233.466 223.979 231.523 223.979 231.523L201.715 237.198Z"
        fill="#455A64"
      />
      <path
        d="M222.633 224.016C222.757 224.02 222.877 224.066 222.971 224.148C223.065 224.23 223.127 224.342 223.148 224.465C223.251 225.003 223.361 226.563 223.582 227.409C223.93 228.956 224.041 230.547 223.913 232.127C223.729 233.121 221.425 233.813 219.953 234.379C218.218 235.132 216.61 236.147 215.184 237.389C213.118 238.931 210.653 239.848 208.082 240.032C205.491 240.032 202.569 239.178 201.899 237.971C201.068 236.499 201.715 235.623 205.579 233.842C206.315 233.503 208.869 231.987 209.738 231.443C212.093 229.971 214.433 227.888 215.515 225.26C215.603 224.938 215.798 224.655 216.067 224.458C216.3 224.362 216.557 224.339 216.803 224.392C217.444 224.481 218.068 224.67 218.651 224.951C219.173 225.194 219.902 225.481 219.895 226.143C219.878 226.373 219.829 226.598 219.747 226.813C219.703 226.946 219.666 227.13 219.784 227.211C219.902 227.292 220.042 227.211 220.145 227.108C220.52 226.718 220.867 226.303 221.183 225.864C221.357 225.633 221.56 225.425 221.786 225.245C222.016 225.118 222.237 224.975 222.449 224.819C222.684 224.613 222.625 224.325 222.633 224.016Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M222.633 224.016C222.757 224.02 222.877 224.066 222.971 224.148C223.065 224.23 223.127 224.342 223.148 224.465C223.251 225.003 223.361 226.563 223.582 227.409C223.93 228.956 224.041 230.547 223.913 232.127C223.729 233.121 221.425 233.813 219.953 234.379C218.218 235.132 216.61 236.147 215.184 237.389C213.118 238.931 210.653 239.848 208.082 240.032C205.491 240.032 202.569 239.178 201.899 237.971C201.068 236.499 201.715 235.623 205.579 233.842C206.315 233.503 208.869 231.987 209.738 231.443C212.093 229.971 214.433 227.888 215.515 225.26C215.603 224.938 215.798 224.655 216.067 224.458C216.3 224.362 216.557 224.339 216.803 224.392C217.444 224.481 218.068 224.67 218.651 224.951C219.173 225.194 219.902 225.481 219.895 226.143C219.878 226.373 219.829 226.598 219.747 226.813C219.703 226.946 219.666 227.13 219.784 227.211C219.902 227.292 220.042 227.211 220.145 227.108C220.52 226.718 220.867 226.303 221.183 225.864C221.357 225.633 221.56 225.425 221.786 225.245C222.016 225.118 222.237 224.975 222.449 224.819C222.684 224.613 222.625 224.325 222.633 224.016Z"
        fill="white"
      />
      <path
        d="M215.184 237.419C215.854 236.867 216.568 236.329 217.282 235.851C217.282 235.851 212.755 238.596 210.93 237.323C210.592 237.106 210.346 236.773 210.237 236.386C210.129 236 210.166 235.587 210.341 235.225C210.341 234.916 207.463 233.577 206.315 233.444C205.999 233.621 205.734 233.761 205.579 233.842C201.73 235.623 201.082 236.484 201.899 237.971C202.569 239.178 204.681 240.112 208.038 240.112C210.231 240.142 213.602 238.721 215.184 237.419Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M215.184 237.419C215.854 236.867 216.568 236.329 217.282 235.851C217.282 235.851 212.755 238.596 210.93 237.323C210.592 237.106 210.346 236.773 210.237 236.386C210.129 236 210.166 235.587 210.341 235.225C210.341 234.916 207.463 233.577 206.315 233.444C205.999 233.621 205.734 233.761 205.579 233.842C201.73 235.623 201.082 236.484 201.899 237.971C202.569 239.178 204.681 240.112 208.038 240.112C210.231 240.142 213.602 238.721 215.184 237.419Z"
        fill="white"
      />
      <path
        d="M210.658 234.35C208.266 235.306 209.282 235.748 209.922 235.822C210.562 235.895 214.618 233.569 217.076 231.626C219.534 229.683 221.249 227.387 222.228 226.636C222.604 226.343 222.965 226.031 223.31 225.702C223.243 225.179 223.192 224.701 223.148 224.443C223.127 224.32 223.065 224.208 222.971 224.126C222.877 224.044 222.757 223.998 222.633 223.994C222.633 224.303 222.684 224.59 222.449 224.826C222.237 224.982 222.016 225.125 221.786 225.253C221.56 225.433 221.357 225.64 221.183 225.871C220.867 226.31 220.52 226.725 220.145 227.115C218.055 229.786 213.05 233.393 210.658 234.35Z"
        fill="#726EFF"
      />
      <path
        d="M210.12 235.52C210.177 235.52 210.232 235.499 210.275 235.461C210.319 235.417 210.343 235.357 210.343 235.295C210.343 235.233 210.319 235.174 210.275 235.13C209.71 234.546 209.027 234.09 208.272 233.792C207.517 233.494 206.707 233.36 205.896 233.4C205.833 233.408 205.775 233.44 205.735 233.489C205.695 233.539 205.676 233.602 205.682 233.665C205.685 233.697 205.694 233.727 205.709 233.755C205.724 233.783 205.745 233.807 205.77 233.826C205.795 233.846 205.823 233.86 205.854 233.867C205.884 233.875 205.916 233.876 205.947 233.871C206.68 233.841 207.41 233.964 208.092 234.233C208.774 234.501 209.392 234.91 209.907 235.432C209.932 235.463 209.965 235.487 210.003 235.503C210.04 235.518 210.08 235.524 210.12 235.52V235.52Z"
        fill="#FAFAFA"
      />
      <path
        d="M212.211 234.21C212.264 234.21 212.316 234.192 212.358 234.158C212.382 234.139 212.402 234.115 212.417 234.088C212.432 234.061 212.442 234.031 212.445 234C212.448 233.97 212.446 233.938 212.437 233.909C212.429 233.879 212.414 233.851 212.395 233.827C211.826 233.238 211.138 232.779 210.376 232.48C209.613 232.182 208.796 232.051 207.979 232.097C207.918 232.105 207.862 232.136 207.823 232.184C207.785 232.232 207.766 232.294 207.773 232.355C207.775 232.386 207.784 232.416 207.799 232.444C207.813 232.471 207.833 232.496 207.857 232.515C207.881 232.535 207.909 232.55 207.938 232.559C207.968 232.568 207.999 232.572 208.03 232.569C208.763 232.537 209.495 232.659 210.178 232.926C210.861 233.194 211.481 233.601 211.997 234.121C212.021 234.154 212.054 234.18 212.092 234.196C212.129 234.211 212.171 234.216 212.211 234.21V234.21Z"
        fill="#FAFAFA"
      />
      <path
        d="M214.294 232.995C214.35 232.998 214.405 232.98 214.448 232.944C214.492 232.899 214.517 232.838 214.517 232.775C214.517 232.711 214.492 232.651 214.448 232.605C213.88 232.017 213.191 231.558 212.429 231.26C211.667 230.963 210.849 230.834 210.032 230.883C209.97 230.89 209.913 230.921 209.873 230.97C209.834 231.019 209.816 231.082 209.822 231.144C209.829 231.207 209.861 231.264 209.91 231.303C209.959 231.343 210.021 231.361 210.084 231.354C210.816 231.323 211.546 231.445 212.228 231.712C212.91 231.98 213.528 232.387 214.043 232.907C214.072 232.945 214.111 232.973 214.155 232.988C214.2 233.004 214.248 233.007 214.294 232.995V232.995Z"
        fill="#FAFAFA"
      />
      <path
        d="M216.229 231.604C216.283 231.605 216.335 231.587 216.376 231.553C216.401 231.533 216.421 231.51 216.436 231.483C216.451 231.455 216.46 231.426 216.464 231.395C216.467 231.364 216.464 231.333 216.456 231.303C216.447 231.274 216.433 231.246 216.413 231.222C215.845 230.633 215.156 230.173 214.394 229.875C213.632 229.576 212.814 229.446 211.997 229.492C211.935 229.499 211.878 229.53 211.838 229.579C211.799 229.628 211.781 229.691 211.788 229.753C211.794 229.816 211.826 229.873 211.875 229.912C211.924 229.952 211.986 229.97 212.049 229.963C212.781 229.932 213.511 230.054 214.193 230.321C214.875 230.588 215.493 230.996 216.008 231.516C216.035 231.548 216.069 231.574 216.107 231.589C216.146 231.605 216.188 231.61 216.229 231.604V231.604Z"
        fill="#FAFAFA"
      />
      <path
        d="M218.121 230.213C218.175 230.215 218.227 230.197 218.268 230.162C218.293 230.143 218.313 230.12 218.329 230.093C218.345 230.066 218.355 230.036 218.359 230.006C218.363 229.975 218.361 229.943 218.353 229.913C218.345 229.883 218.331 229.855 218.312 229.831C217.7 229.195 216.951 228.707 216.122 228.403C215.293 228.1 214.406 227.989 213.528 228.079C213.465 228.087 213.407 228.118 213.367 228.168C213.327 228.218 213.308 228.281 213.315 228.344C213.322 228.405 213.353 228.461 213.401 228.5C213.45 228.538 213.511 228.556 213.572 228.55C214.371 228.471 215.177 228.572 215.932 228.844C216.688 229.117 217.372 229.554 217.937 230.125C217.959 230.152 217.987 230.174 218.019 230.19C218.051 230.205 218.086 230.213 218.121 230.213V230.213Z"
        fill="#FAFAFA"
      />
      <path
        d="M238.096 243.13C237.909 244.917 238.178 246.722 238.876 248.378C239.217 249.042 239.768 249.574 240.443 249.891C241.118 250.208 241.879 250.292 242.608 250.13C244.19 249.894 246.288 249.048 246.913 247.127C247.487 245.385 247.673 243.538 247.458 241.717L238.096 243.13Z"
        fill="#455A64"
      />
      <path
        d="M245.89 227.277C246.516 227.395 246.398 230.464 246.457 233.562C246.523 236.874 247.362 238.332 247.443 241.055C247.539 244.161 247.237 245.515 245.971 247.267C244.705 249.018 240.723 250.454 239.112 247.605C237.801 245.287 237.978 242.873 238.427 239.826C238.891 236.668 239.237 234.453 239.354 232.031C239.457 229.72 239.524 227.483 240.09 227.277C240.09 227.623 240.09 228.057 240.09 228.388C240.09 228.719 240.039 229.897 240.392 229.949C240.454 229.663 240.486 229.372 240.488 229.08C240.479 228.947 240.496 228.813 240.539 228.687C240.582 228.561 240.65 228.444 240.738 228.344C240.948 228.181 241.208 228.096 241.474 228.101C242.112 228.03 242.753 227.995 243.395 227.998C243.712 227.997 244.029 228.014 244.345 228.05C244.619 228.071 244.887 228.138 245.139 228.248C245.214 228.28 245.281 228.327 245.335 228.387C245.39 228.447 245.431 228.517 245.456 228.594C245.478 228.711 245.478 228.831 245.456 228.948C245.43 229.16 245.43 229.375 245.456 229.588C245.456 229.676 245.522 229.794 245.618 229.787C245.654 229.773 245.685 229.749 245.708 229.718C245.73 229.686 245.742 229.649 245.743 229.61C245.78 229.235 245.743 228.962 245.787 228.528C245.787 228.329 245.787 228.057 245.787 227.858C245.787 227.66 245.883 227.476 245.89 227.277Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M245.89 227.277C246.516 227.395 246.398 230.464 246.457 233.562C246.523 236.874 247.362 238.332 247.443 241.055C247.539 244.161 247.237 245.515 245.971 247.267C244.705 249.018 240.723 250.454 239.112 247.605C237.801 245.287 237.978 242.873 238.427 239.826C238.891 236.668 239.237 234.453 239.354 232.031C239.457 229.72 239.524 227.483 240.09 227.277C240.09 227.623 240.09 228.057 240.09 228.388C240.09 228.719 240.039 229.897 240.392 229.949C240.454 229.663 240.486 229.372 240.488 229.08C240.479 228.947 240.496 228.813 240.539 228.687C240.582 228.561 240.65 228.444 240.738 228.344C240.948 228.181 241.208 228.096 241.474 228.101C242.112 228.03 242.753 227.995 243.395 227.998C243.712 227.997 244.029 228.014 244.345 228.05C244.619 228.071 244.887 228.138 245.139 228.248C245.214 228.28 245.281 228.327 245.335 228.387C245.39 228.447 245.431 228.517 245.456 228.594C245.478 228.711 245.478 228.831 245.456 228.948C245.43 229.16 245.43 229.375 245.456 229.588C245.456 229.676 245.522 229.794 245.618 229.787C245.654 229.773 245.685 229.749 245.708 229.718C245.73 229.686 245.742 229.649 245.743 229.61C245.78 229.235 245.743 228.962 245.787 228.528C245.787 228.329 245.787 228.057 245.787 227.858C245.787 227.66 245.883 227.476 245.89 227.277Z"
        fill="white"
      />
      <path
        d="M247.384 240.164C247.384 240.613 247.487 243.579 246.648 244.028C245.809 244.477 245.485 242.166 245.485 242.166C245.485 242.166 243.52 241.342 240.436 242.335C240.539 242.821 239.928 244.345 239.156 243.933C238.383 243.52 238.42 240.127 238.42 240.083C238 243.027 237.868 245.353 239.156 247.605C240.767 250.453 244.757 249.011 246.015 247.267C247.274 245.522 247.583 244.161 247.487 241.055C247.436 240.738 247.414 240.444 247.384 240.164Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M247.384 240.164C247.384 240.613 247.487 243.579 246.648 244.028C245.809 244.477 245.485 242.166 245.485 242.166C245.485 242.166 243.52 241.342 240.436 242.335C240.539 242.821 239.928 244.345 239.156 243.933C238.383 243.52 238.42 240.127 238.42 240.083C238 243.027 237.868 245.353 239.156 247.605C240.767 250.453 244.757 249.011 246.015 247.267C247.274 245.522 247.583 244.161 247.487 241.055C247.436 240.738 247.414 240.444 247.384 240.164Z"
        fill="white"
      />
      <path
        d="M245.22 242.335C245.117 243.447 246.251 243.182 246.457 241.599C246.663 240.017 246.177 236.234 246.457 233.503C246.398 230.405 246.516 227.336 245.89 227.218C245.89 227.255 245.89 227.299 245.89 227.336L245.817 228.896C245.817 229.124 245.817 229.323 245.817 229.566C245.603 231.825 245.478 236.234 245.471 238.324C245.459 239.665 245.375 241.004 245.22 242.335V242.335Z"
        fill="#726EFF"
      />
      <path
        d="M240.768 242.335C240.627 241.023 240.556 239.703 240.554 238.383C240.554 236.308 240.422 231.958 240.216 229.676C240.136 229.252 240.104 228.82 240.12 228.388C240.12 228.079 240.12 227.652 240.12 227.35C240.12 227.35 240.12 227.306 240.12 227.284C239.7 227.431 239.553 228.661 239.465 230.228C239.465 231.126 239.465 232.237 239.553 233.592C239.833 236.285 239.34 240.113 239.553 241.688C239.767 243.263 240.871 243.447 240.768 242.335Z"
        fill="#726EFF"
      />
      <path
        d="M240.355 242.416H240.444C242.043 241.858 243.784 241.858 245.382 242.416C245.442 242.438 245.508 242.436 245.565 242.409C245.623 242.383 245.668 242.336 245.691 242.277C245.703 242.248 245.709 242.217 245.708 242.186C245.707 242.154 245.7 242.124 245.687 242.095C245.674 242.067 245.655 242.042 245.632 242.021C245.608 242 245.581 241.985 245.552 241.975C243.843 241.366 241.976 241.366 240.267 241.975C240.238 241.986 240.211 242.003 240.189 242.025C240.166 242.047 240.148 242.073 240.136 242.102C240.124 242.13 240.117 242.161 240.117 242.193C240.117 242.224 240.123 242.255 240.135 242.284C240.155 242.324 240.187 242.358 240.226 242.382C240.265 242.405 240.31 242.417 240.355 242.416Z"
        fill="#FAFAFA"
      />
      <path
        d="M240.068 239.936H240.142C241.946 239.373 243.879 239.373 245.684 239.936C245.743 239.953 245.805 239.947 245.86 239.92C245.914 239.893 245.957 239.846 245.978 239.789C245.989 239.759 245.993 239.728 245.991 239.697C245.99 239.666 245.982 239.635 245.968 239.607C245.954 239.579 245.935 239.554 245.912 239.533C245.888 239.513 245.861 239.497 245.831 239.487C243.929 238.887 241.889 238.887 239.987 239.487C239.958 239.497 239.93 239.513 239.907 239.533C239.883 239.554 239.864 239.579 239.851 239.607C239.837 239.635 239.829 239.666 239.827 239.697C239.825 239.728 239.83 239.759 239.84 239.789C239.859 239.833 239.891 239.871 239.932 239.897C239.972 239.923 240.02 239.937 240.068 239.936V239.936Z"
        fill="#FAFAFA"
      />
      <path
        d="M245.758 237.625C245.803 237.626 245.848 237.613 245.886 237.588C245.924 237.563 245.954 237.527 245.971 237.485C245.996 237.427 245.998 237.362 245.976 237.303C245.954 237.244 245.91 237.196 245.853 237.169C243.979 236.437 241.9 236.424 240.017 237.132C239.988 237.144 239.963 237.161 239.941 237.184C239.919 237.206 239.902 237.232 239.89 237.26C239.879 237.289 239.873 237.32 239.873 237.351C239.873 237.382 239.88 237.412 239.892 237.441C239.902 237.47 239.919 237.496 239.94 237.519C239.961 237.541 239.986 237.559 240.014 237.572C240.043 237.584 240.073 237.591 240.104 237.591C240.135 237.591 240.165 237.585 240.194 237.573C240.194 237.573 243.101 236.433 245.655 237.573C245.685 237.598 245.72 237.616 245.758 237.625V237.625Z"
        fill="#FAFAFA"
      />
      <path
        d="M245.824 235.446C245.868 235.448 245.912 235.436 245.95 235.412C245.988 235.389 246.019 235.354 246.037 235.314C246.051 235.286 246.059 235.255 246.061 235.224C246.063 235.193 246.058 235.162 246.048 235.133C246.038 235.103 246.022 235.076 246.001 235.053C245.98 235.03 245.955 235.011 245.927 234.997C244.995 234.573 243.984 234.348 242.96 234.337C241.936 234.325 240.921 234.528 239.98 234.931C239.923 234.958 239.879 235.007 239.857 235.066C239.835 235.125 239.837 235.19 239.862 235.248C239.876 235.276 239.894 235.301 239.918 235.322C239.941 235.343 239.968 235.359 239.997 235.369C240.027 235.379 240.058 235.384 240.089 235.382C240.12 235.38 240.151 235.372 240.179 235.358C241.059 234.993 242.004 234.811 242.957 234.823C243.909 234.834 244.85 235.039 245.721 235.424L245.824 235.446Z"
        fill="#FAFAFA"
      />
      <path
        d="M245.861 233.003C245.904 233.004 245.947 232.993 245.985 232.971C246.023 232.948 246.054 232.916 246.074 232.878C246.102 232.82 246.106 232.754 246.086 232.693C246.065 232.632 246.021 232.582 245.964 232.554C243.145 231.163 240.076 232.495 239.965 232.554C239.909 232.58 239.865 232.626 239.843 232.684C239.821 232.742 239.823 232.806 239.848 232.863C239.86 232.892 239.877 232.919 239.9 232.942C239.923 232.964 239.95 232.981 239.979 232.993C240.009 233.005 240.04 233.01 240.072 233.01C240.104 233.009 240.135 233.001 240.164 232.988C240.164 232.988 243.108 231.685 245.758 232.988C245.791 233.001 245.826 233.006 245.861 233.003V233.003Z"
        fill="#EBEBEB"
      />
      <path
        d="M241.989 132.796C243.728 136.801 244.857 141.043 245.338 145.382C246.56 161.162 245.897 183.816 245.897 183.816C246.037 185.465 247.082 189.211 247.789 196.468C248.621 205.042 247.575 234.107 247.575 234.107C247.575 234.107 242.423 237.169 238.456 233.687C238.456 233.687 233.812 193.737 232.738 185.207C231.803 177.766 229.175 161.655 229.175 161.655L226.106 185.472C226.106 185.472 226.386 192.162 226.187 196.512C225.959 201.546 224.553 227.196 224.553 227.196C224.553 227.196 217.503 229.58 214.352 226.195C214.352 226.195 213.616 190.617 213.499 184.559C213.293 172.68 213.499 151.027 215.427 138.567L241.989 132.796Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M241.989 132.796C243.728 136.801 244.857 141.043 245.338 145.382C246.56 161.162 245.897 183.816 245.897 183.816C246.037 185.465 247.082 189.211 247.789 196.468C248.621 205.042 247.575 234.107 247.575 234.107C247.575 234.107 242.423 237.169 238.456 233.687C238.456 233.687 233.812 193.737 232.738 185.207C231.803 177.766 229.175 161.655 229.175 161.655L226.106 185.472C226.106 185.472 226.386 192.162 226.187 196.512C225.959 201.546 224.553 227.196 224.553 227.196C224.553 227.196 217.503 229.58 214.352 226.195C214.352 226.195 213.616 190.617 213.499 184.559C213.293 172.68 213.499 151.027 215.427 138.567L241.989 132.796Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M229.176 161.67L228.329 155.84C228.329 155.84 222.809 155.921 219.917 153.198C219.917 153.198 221.043 156.834 226.651 157.283L227.446 162.126L226.475 182.624L229.176 161.67Z"
        fill="black"
      />
      <path
        d="M222.338 102.304C219.88 102.194 216.56 102.532 214.75 104.681C212.593 107.243 212.005 108.899 210.032 116.457C208.729 121.462 206.58 132.576 208.45 134.372C210.319 136.167 215 135.505 215 135.505L222.338 102.304Z"
        fill="#F28F8F"
      />
      <path
        d="M223.582 102.142C220.888 101.708 216.053 101.671 213.639 105.506C211.225 109.34 206.772 122.809 206.772 122.809C209.373 124.257 212.381 124.801 215.324 124.355C220.277 123.648 223.582 102.142 223.582 102.142Z"
        fill="#726EFF"
      />
      <path
        opacity="0.1"
        d="M223.582 102.142C220.888 101.708 216.053 101.671 213.639 105.506C211.225 109.34 206.772 122.809 206.772 122.809C209.373 124.257 212.381 124.801 215.324 124.355C220.277 123.648 223.582 102.142 223.582 102.142Z"
        fill="black"
      />
      <path
        d="M234.202 101.87C237.029 102.142 243.447 102.856 245.677 103.297C247.62 104.092 247.149 105.432 247.149 107.772C247.34 115.817 244.146 132.612 244.146 132.612L246.398 148.348C242.519 152.219 221.97 154.383 212.608 148.186C212.608 148.186 214.198 125.216 215.986 113.763C217.26 105.564 220.211 102.267 223.207 102.142L234.202 101.87Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M231.619 117.65L239.539 117.966V119.49L231.509 119.151L231.619 117.65Z"
        fill="black"
      />
      <path
        d="M234.173 117.753L234.357 115.515H239.539L239.318 117.959L234.173 117.753Z"
        fill="#FAFAFA"
      />
      <path
        d="M231.972 117.664C232.098 115.081 232.377 113.8 232.583 113.638C232.669 113.567 232.777 113.528 232.889 113.528C233 113.528 233.108 113.567 233.194 113.638C233.386 113.889 233.533 115.221 233.518 117.723L231.972 117.664Z"
        fill="#455A64"
      />
      <path
        d="M232.576 117.436C232.576 118.422 232.502 120.306 232.848 120.336C233.194 120.365 233.165 118.511 233.172 117.436H232.576Z"
        fill="#455A64"
      />
      <path
        opacity="0.1"
        d="M238.618 102.341C236.911 102.142 235.292 101.973 234.202 101.87L226.577 102.061C225.547 104.321 224.443 107.588 226.43 111.681C229.479 110.687 232.296 109.088 234.71 106.977C237.235 104.681 238.272 103.29 238.618 102.341Z"
        fill="black"
      />
      <path
        d="M237.934 77.9055C237.934 77.9055 240.142 78.1484 240.966 79.775C241.702 81.1807 241.54 84.6399 240.716 88.423C240.257 90.8704 239.537 93.2614 238.567 95.5548C238.217 96.2048 237.633 96.697 236.933 96.9311V92.471L237.007 89.3577C237.007 89.3577 234.938 86.686 234.659 84.4559C234.298 81.5929 234.872 80.4006 234.872 80.4006L237.934 77.9055Z"
        fill="#455A64"
      />
      <path
        d="M235.137 83.0354C235.183 84.6839 234.739 86.309 233.861 87.7049C232.983 89.1008 231.71 90.2047 230.204 90.8768C228.698 91.5488 227.027 91.7588 225.401 91.4802C223.776 91.2016 222.27 90.4468 221.073 89.3116C219.877 88.1763 219.045 86.7115 218.682 85.1029C218.319 83.4942 218.441 81.8139 219.034 80.2749C219.626 78.7359 220.662 77.4074 222.01 76.4576C223.358 75.5077 224.958 74.9794 226.607 74.9394C228.811 74.884 230.947 75.7054 232.546 77.2232C234.146 78.741 235.077 80.8313 235.137 83.0354V83.0354Z"
        fill="#455A64"
      />
      <path
        d="M237.08 88.7909C237.581 89.0853 238.287 88.1211 238.898 87.4735C239.509 86.8258 241.511 85.9205 242.534 88.0255C243.557 90.1304 241.688 93.0376 240.113 93.5528C237.389 94.4287 236.963 92.6843 236.963 92.6843L236.852 102.834C234.975 105.277 231.067 108.494 227.284 109.693C226.844 108.494 226.674 107.213 226.786 105.941C226.897 104.669 227.289 103.437 227.932 102.333V99.3378C226.717 99.543 225.483 99.617 224.252 99.5586C222.243 99.2642 220.962 97.6891 220.336 95.5253C219.328 92.044 218.931 89.2251 219.703 82.3583C220.542 74.8216 229.271 74.6671 234.048 77.6037C235.049 78.2219 234.357 79.4216 234.858 80.3858C234.504 81.8613 234.522 83.4016 234.91 84.8685C235.298 86.3354 236.043 87.6831 237.08 88.7909V88.7909Z"
        fill="#F28F8F"
      />
      <path
        d="M227.939 99.36C227.939 99.36 232.414 98.418 233.974 97.5716C234.89 97.0825 235.637 96.3283 236.116 95.4077C235.889 96.3267 235.478 97.1903 234.909 97.9469C233.776 99.4189 227.947 100.552 227.947 100.552L227.939 99.36Z"
        fill="#B16668"
      />
      <path
        d="M228.896 87.7458C228.906 87.9212 228.967 88.0898 229.072 88.2306C229.177 88.3715 229.321 88.4785 229.486 88.5384C229.651 88.5983 229.83 88.6085 230.001 88.5676C230.172 88.5268 230.327 88.4367 230.447 88.3086C230.567 88.1805 230.647 88.02 230.677 87.8469C230.707 87.6738 230.685 87.4957 230.615 87.3348C230.545 87.1738 230.429 87.037 230.282 86.9413C230.134 86.8456 229.962 86.7953 229.786 86.7964C229.544 86.8079 229.315 86.9134 229.149 87.0906C228.982 87.2679 228.892 87.5029 228.896 87.7458V87.7458Z"
        fill="black"
      />
      <path
        d="M229.838 84.3457L231.766 85.3761C231.901 85.1104 231.929 84.8028 231.842 84.5175C231.756 84.2322 231.562 83.9913 231.303 83.8452C231.174 83.7781 231.034 83.7378 230.889 83.7267C230.745 83.7157 230.6 83.7341 230.463 83.781C230.326 83.8278 230.2 83.902 230.092 83.9991C229.985 84.0962 229.898 84.2141 229.838 84.3457V84.3457Z"
        fill="black"
      />
      <path
        d="M228.984 93.2144L226.04 94.3625C226.107 94.5625 226.213 94.747 226.353 94.905C226.493 95.0629 226.663 95.1911 226.853 95.2818C227.043 95.3725 227.25 95.4239 227.461 95.4328C227.671 95.4418 227.882 95.4082 228.079 95.334C228.472 95.1631 228.785 94.8484 228.953 94.4542C229.121 94.0601 229.133 93.6165 228.984 93.2144V93.2144Z"
        fill="#B16668"
      />
      <path
        d="M220.881 85.1699L222.618 83.8083C222.535 83.6906 222.429 83.591 222.306 83.5156C222.183 83.4403 222.046 83.3908 221.904 83.3701C221.761 83.3494 221.616 83.358 221.477 83.3954C221.337 83.4328 221.207 83.4982 221.094 83.5875C220.863 83.7742 220.713 84.0421 220.673 84.3363C220.633 84.6305 220.708 84.9288 220.881 85.1699V85.1699Z"
        fill="black"
      />
      <path
        d="M221.44 87.5839C221.449 87.7597 221.509 87.9291 221.613 88.0709C221.717 88.2128 221.861 88.321 222.026 88.382C222.191 88.443 222.37 88.4542 222.541 88.4141C222.713 88.374 222.869 88.2845 222.99 88.1566C223.11 88.0287 223.191 87.8681 223.221 87.6948C223.252 87.5215 223.231 87.343 223.16 87.1816C223.09 87.0203 222.974 86.8831 222.827 86.7871C222.679 86.6911 222.507 86.6406 222.331 86.6419C222.21 86.6447 222.092 86.6714 221.981 86.7204C221.871 86.7694 221.772 86.8397 221.689 86.9273C221.606 87.0149 221.542 87.118 221.499 87.2307C221.456 87.3434 221.436 87.4635 221.44 87.5839Z"
        fill="black"
      />
      <path
        d="M226.011 86.6641L225.761 92.0663L222.934 91.1316L226.011 86.6641Z"
        fill="#B16668"
      />
      <path
        d="M218.489 74.336C218.511 74.2729 218.55 74.2173 218.602 74.1757C218.654 74.134 218.717 74.1079 218.783 74.1004C218.886 74.104 218.985 74.143 219.063 74.2108C219.648 74.6574 220.271 75.0514 220.925 75.3884C220.729 74.5738 220.694 73.7288 220.822 72.9008C220.903 72.3929 221.013 71.2227 221.632 71.0828C222 70.9945 222.368 71.2963 222.618 71.5465C225.186 73.8281 228.425 72.6505 231.45 72.6652C233.974 72.6652 237.043 73.622 238.25 76.0435C238.802 77.1548 238.928 78.3324 237.919 79.1347C237.014 79.7793 235.971 80.2028 234.872 80.3712C229.875 81.3795 223.155 83.3152 219.313 78.7004C218.74 78.0585 218.366 77.2629 218.239 76.4115C218.123 75.7202 218.207 75.0104 218.481 74.3654C218.481 74.3654 218.481 74.3728 218.489 74.336Z"
        fill="#37474F"
      />
      <path
        d="M219.328 78.752C218.754 78.1101 218.381 77.3145 218.253 76.4631C218.138 75.7718 218.222 75.062 218.496 74.417C217.966 75.8301 218.261 78.097 221.499 79.3997C224.738 80.7024 228.786 79.8928 230.986 79.65C232.331 79.505 233.688 79.7611 234.887 80.386C229.868 81.409 223.148 83.3152 219.328 78.752Z"
        fill="#263238"
      />
      <path
        d="M243.049 127.946L241.931 117.436C240.731 109.031 243.483 103.916 244.639 103.114C247.922 103.688 249.909 103.805 251.786 110.996C252.477 113.66 252.522 116.884 252.912 121.911C253.221 125.878 253.228 129.955 253.154 134.6C253.11 137.941 251.683 138.581 247.502 138.795C244.043 138.971 233.165 137.683 227.085 136.528C224.627 136.064 221.021 135.107 219.115 134.592C217.723 134.217 212.417 133.076 212.645 130.986C212.695 130.694 212.814 130.418 212.99 130.18C213.166 129.943 213.396 129.75 213.661 129.617C214.189 129.358 214.76 129.196 215.346 129.138C217.916 128.77 220.526 128.77 223.096 129.138C225.025 129.403 226.872 129.749 228.815 129.874C230.758 130 234.703 127.586 236.543 127.666C240.054 127.799 243.049 127.946 243.049 127.946Z"
        fill="#F28F8F"
      />
      <path
        d="M243.793 102.988C246.685 102.9 248.68 103.585 249.916 105.564C251.153 107.544 252.124 109.244 252.912 114.89C254.008 122.824 254.148 126.577 254.148 126.577C254.148 126.577 247.863 129.146 242.586 126.577L241.408 116.001C241.408 116.001 240.547 107.662 243.793 102.988Z"
        fill="#726EFF"
      />
      <path
        opacity="0.1"
        d="M243.793 102.988C246.685 102.9 248.68 103.585 249.916 105.564C251.153 107.544 252.124 109.244 252.912 114.89C254.008 122.824 254.148 126.577 254.148 126.577C254.148 126.577 247.863 129.146 242.586 126.577L241.408 116.001C241.408 116.001 240.547 107.662 243.793 102.988Z"
        fill="black"
      />
      <path
        d="M248.282 132.2C248.282 132.2 248.025 129.256 243.049 127.946C243.049 127.946 240.054 127.799 236.543 127.659C234.674 127.586 230.655 130.022 228.815 129.867C226.975 129.713 225.025 129.367 223.096 129.131C220.526 128.763 217.916 128.763 215.346 129.131C214.76 129.189 214.189 129.351 213.661 129.61C213.396 129.742 213.166 129.935 212.99 130.173C212.814 130.411 212.695 130.687 212.645 130.978C212.417 133.069 217.723 134.21 219.115 134.585C220.115 134.857 221.602 135.247 223.133 135.63C228.719 133.694 240.9 128.542 243.049 128.94C245.485 129.403 247.561 130.669 248.282 132.2Z"
        fill="#B16668"
      />
      <path
        d="M240.819 128.204C236.234 129.035 220.763 135.336 213.631 135.608C211.423 135.034 211.129 133.93 210.356 130.978C209.583 128.027 212.866 124.656 214.978 124.354C236.116 124.354 242.946 127.24 242.946 127.24L243.042 127.976C243.042 127.976 242.144 128.012 240.819 128.204Z"
        fill="#F28F8F"
      />
      <path
        d="M242.953 127.24L243.049 127.946L242.122 128.034L242.195 126.982L242.953 127.24Z"
        fill="#B16668"
      />
      <path
        d="M214.61 124.443C212.174 125.017 210.577 127.806 210.577 127.806C210.894 126.513 211.61 125.352 212.623 124.487C213.286 124.523 213.95 124.508 214.61 124.443V124.443Z"
        fill="#B16668"
      />
      <path
        d="M171.54 48.804C170.535 58.1612 168.58 67.3918 165.703 76.3524H191.286C192.942 67.7044 193.833 58.8062 191.286 48.804H171.54Z"
        fill="#37474F"
      />
      <path
        d="M189.638 59.0713C193.487 59.1743 194.584 60.7199 196.902 64.0319C199.22 67.3439 205.344 80.717 205.344 80.717C205.344 80.717 206.426 87.4441 201.59 86.4579C200.001 86.1267 198.911 84.8534 198.168 83.7126C197.542 82.7705 192.648 75.0498 192.648 75.0498L189.638 59.0713Z"
        fill="#E6E6E6"
      />
      <path
        d="M186.694 59.1744C190.33 58.2617 191.934 60.3004 193.148 62.5232C195.091 66.078 196.254 73.3424 196.416 86.6272C196.527 95.1648 199.493 105.491 200.023 113.587C200.818 125.76 201.208 135.24 201.208 135.24C194.007 134.304 186.749 133.873 179.488 133.952L186.694 59.1744Z"
        fill="#E0E0E0"
      />
      <path
        d="M165.71 183.021C165.71 183.021 163.502 168.073 164.437 159.314C165.225 151.8 166.329 148.65 166.483 146.942C166.483 146.942 167.749 129.278 167.403 114.352C167.241 107.265 167.602 99.4556 172.644 91.0946L172.828 90.7634C173.277 93.5087 177.494 95.2825 181.262 95.702C184.893 96.0994 189.41 95.3806 194.812 93.5455C196.012 100.633 197.381 114.154 198.772 128.874C199.456 136.072 199.338 142.122 199.456 146.751C199.618 153.029 199.051 177.619 199.051 177.619V178.657C199.088 179.805 199.368 180.6 200.17 182.418C201.172 184.762 202.571 186.916 204.306 188.784C204.851 189.329 208.568 192.368 209.068 193.031C210.68 194.79 207.493 195.916 205.094 195.673C202.562 195.423 198.617 194.598 197.462 193.097C196.681 192.103 195.99 188.534 195.217 187.525C192.538 184.118 191.434 182.646 191.375 181.534C191.279 179.518 191.942 178.693 192.155 177.545C191.868 175.168 187.047 162.752 186.731 155.818C186.728 152.755 186.919 149.694 187.305 146.655L183.073 122.566C182.131 129.337 180.342 144.602 179.694 149.459C178.804 156.15 175.499 167.469 173.416 176.36C172.785 178.658 172.329 181 172.055 183.367C172.055 183.367 172.003 184.103 172.003 184.147C172.003 185.133 171.908 195.665 171.591 197.896C170.929 202.562 166.792 202.113 166.005 198.389C165.335 195.217 165.71 185.317 165.688 184.11L165.71 183.021Z"
        fill="#FFA8A7"
      />
      <path
        d="M165.6 182.955C164.437 183.014 165.35 187.489 165.144 190.396C164.93 193.502 163.988 195.805 163.782 198.359C163.454 200.59 163.87 202.866 164.967 204.836C165.836 206.669 169.994 209.075 171.451 206.367C172.777 203.693 173.269 200.683 172.864 197.726C172.585 194.782 172.334 192.574 172.195 190.293C172.077 188.291 173.461 183.529 171.952 183.286L171.9 184.074C171.392 185.17 166.262 186.09 165.585 184.029L165.6 182.955Z"
        fill="#37474F"
      />
      <path
        d="M191.088 183.904C190.779 185.376 191.279 189.329 191.279 189.329C191.964 190.394 193.009 191.177 194.223 191.537L195.048 185.98L191.088 183.904Z"
        fill="#263238"
      />
      <path
        d="M194.216 191.515L199.095 189.733L200.141 185.317L194.694 188.306L194.216 191.515Z"
        fill="black"
      />
      <path
        d="M198.919 178.583V177.545C199.603 177.847 201.252 183.19 203.372 186.826C205.3 190.145 208.965 191.905 210.187 193.34C211.821 195.253 208.862 197.888 203.938 197.02C201.495 196.578 197.771 195.15 196.726 193.546C195.68 191.941 195.253 188.946 194.385 187.776C193.517 186.605 191.551 185.273 191.095 183.919C190.733 182.689 190.693 181.386 190.977 180.136C191.316 178.877 191.603 177.361 192.03 177.486L192.096 178.428C192.442 179.083 193.649 179.827 196.122 179.9C197.285 179.886 198.418 179.665 198.919 178.583Z"
        fill="#37474F"
      />
      <path
        d="M186.09 158.461C185.808 154.669 185.946 150.858 186.502 147.097L182.948 123.405C182.948 123.405 180.519 145.964 180.114 149.46C179.709 152.956 173.21 190.676 173.21 190.676C169.884 192.641 165.615 192.015 162.84 190.543C162.84 175.293 163.318 160.014 163.87 155.789C164.354 152.888 165.015 150.02 165.85 147.2C165.85 147.2 166.204 124.951 166.182 116.509C166.159 108.067 166.8 102.407 168.39 98.6093C169.639 95.8512 171.116 93.2022 172.806 90.6899C172.806 90.6899 174.042 94.8557 183.846 94.8704C193.649 94.8851 194.709 92.3975 194.709 92.3975C194.709 92.3975 197.572 102.4 198.529 117.51C199.265 129.683 199.471 135.225 199.699 140.65C199.898 145.272 200.023 150.615 200.126 159.962C200.192 167.072 201.208 182.528 201.208 182.528C201.208 182.528 196.431 185.038 188.549 182.182C188.549 182.197 186.576 164.548 186.09 158.461Z"
        fill="#726EFF"
      />
      <path
        opacity="0.4"
        d="M186.09 158.461C185.808 154.669 185.946 150.858 186.502 147.097L182.948 123.405C182.948 123.405 180.519 145.964 180.114 149.46C179.709 152.956 173.21 190.676 173.21 190.676C169.884 192.641 165.615 192.015 162.84 190.543C162.84 175.293 163.318 160.014 163.87 155.789C164.354 152.888 165.015 150.02 165.85 147.2C165.85 147.2 166.204 124.951 166.182 116.509C166.159 108.067 166.8 102.407 168.39 98.6093C169.639 95.8512 171.116 93.2022 172.806 90.6899C172.806 90.6899 174.042 94.8557 183.846 94.8704C193.649 94.8851 194.709 92.3975 194.709 92.3975C194.709 92.3975 197.572 102.4 198.529 117.51C199.265 129.683 199.471 135.225 199.699 140.65C199.898 145.272 200.023 150.615 200.126 159.962C200.192 167.072 201.208 182.528 201.208 182.528C201.208 182.528 196.431 185.038 188.549 182.182C188.549 182.197 186.576 164.548 186.09 158.461Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M182.948 123.405L184.883 109.075C187.721 108.775 190.366 107.494 192.361 105.454C192.361 105.454 191.272 108.928 186.679 110.878L184.088 123.449L185.98 143.623L182.948 123.405Z"
        fill="black"
      />
      <path
        d="M178.37 60.1753L173.762 60.6611C170.708 61.397 169.346 62.7586 169.015 65.4671C168.446 68.3896 168.501 71.3995 169.177 74.2991C170.149 78.1116 172.725 87.4441 172.725 87.4441C172.725 87.4441 166.778 95.1868 165.703 108.486C176.574 115.574 193.237 111.754 197.741 107.471C197.358 103.415 194.797 92.2796 194.444 88.6585C194.444 88.6585 194.679 83.0134 195.128 80.5625C198.566 76.8089 198.124 73.2025 196.6 70.3247C193.752 64.9814 189.446 60.2857 188.063 60.2931L178.37 60.1753Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M178.37 60.1753L173.762 60.6611C170.708 61.397 169.346 62.7586 169.015 65.4671C168.446 68.3896 168.501 71.3995 169.177 74.2991C170.149 78.1116 172.725 87.4441 172.725 87.4441C172.725 87.4441 166.778 95.1868 165.703 108.486C176.574 115.574 193.237 111.754 197.741 107.471C197.358 103.415 194.797 92.2796 194.444 88.6585C194.444 88.6585 194.679 83.0134 195.128 80.5625C198.566 76.8089 198.124 73.2025 196.6 70.3247C193.752 64.9814 189.446 60.2857 188.063 60.2931L178.37 60.1753Z"
        fill="white"
      />
      <path
        d="M186.745 56.9368C188.107 56.9368 188.262 57.1649 189.439 58.7326C190.138 59.6747 194.554 66.9537 194.554 66.9537C194.554 66.9537 191.036 62.3316 188.387 60.9332C187.878 60.6359 187.327 60.4175 186.753 60.2856L186.745 56.9368Z"
        fill="#EBEBEB"
      />
      <path
        d="M178.112 60.1754C178.112 60.1754 181.91 70.1482 182.771 76.9194C183.632 83.6906 180.666 102.584 179.4 116.192C178.576 124.988 177.339 137.926 177.339 137.926C174.707 137.934 172.079 137.71 169.486 137.257C165.019 136.418 163.333 135.24 162.568 134.313C162.568 134.313 164.776 112.144 165.858 103.526C166.881 95.4298 171.245 87.4147 171.245 87.4147L165.998 65.7248C165.998 65.7248 166.263 62.0448 169.678 61.0439C172.96 60.065 178.112 60.1754 178.112 60.1754Z"
        fill="#FAFAFA"
      />
      <path
        d="M181.35 98.7123L167.734 95.6799C167.263 97.0931 166.814 98.6239 166.454 100.199C171.112 101.799 175.896 103.01 180.754 103.82C180.956 102.108 181.154 100.405 181.35 98.7123Z"
        fill="#F0F0F0"
      />
      <path
        d="M171.4 80.452C171.4 80.452 171.812 78.3765 172.437 73.9016C173.173 68.7496 172.894 66.1957 172.114 64.5618L167.742 72.9227L171.238 87.4146C168.952 91.7888 167.264 96.45 166.218 101.273V101.273C169.648 91.5656 173.409 88.651 173.409 88.651L171.4 80.452Z"
        fill="#F0F0F0"
      />
      <path
        d="M176.684 72.4297C176.684 72.0102 176.64 71.1049 176.566 69.9567C176.213 64.3705 176.051 59.3215 177.935 58.9167L178.105 59.6527C176.721 59.9471 177.185 67.1673 177.369 69.8831C177.442 71.046 177.501 71.966 177.486 72.4297H176.684Z"
        fill="#726EFF"
      />
      <path
        d="M170.369 35.7401L173.313 38.1468C173.473 37.9635 173.593 37.7497 173.667 37.5183C173.741 37.287 173.767 37.0431 173.743 36.8014C173.72 36.5596 173.648 36.3252 173.531 36.1124C173.414 35.8996 173.255 35.7128 173.063 35.5635C172.677 35.2433 172.182 35.0832 171.681 35.116C171.18 35.1489 170.711 35.3722 170.369 35.7401V35.7401Z"
        fill="#37474F"
      />
      <path
        d="M178.06 57.2975C178.06 55.4501 173.755 52.6901 171.54 48.8041C169.324 44.918 170.421 38.5442 173.468 37.5359C173.468 37.5359 173.571 36.7189 173.586 36.6601C173.76 35.9313 174.045 35.2337 174.432 34.5919C175.134 33.497 176.059 32.5631 177.147 31.8515C178.236 31.1398 179.463 30.6666 180.747 30.4629C190.381 29.0572 194.804 32.7961 196.578 36.9177C197.157 38.3144 197.409 39.8248 197.314 41.3337C197.265 42.432 196.858 43.4842 196.155 44.3294C195.452 45.1747 194.492 45.7666 193.421 46.0146L178.06 57.2975Z"
        fill="#37474F"
      />
      <path
        d="M191.411 35.5339C193.303 36.3583 195.091 39.2139 195.091 47.3099C195.091 54.1547 192.964 55.8917 191.905 56.3995C190.845 56.9074 188.777 56.6498 186.753 56.3407V60.2783C186.753 60.2783 188.225 61.7503 187.731 62.6923C187.238 63.6344 185.244 64.4072 182.211 63.4283C178.767 62.3464 177.979 60.0501 177.979 60.0501L177.913 51.4389C177.913 51.4389 176.758 52.6607 174.719 50.8722C173.041 49.4002 172.43 46.8757 173.696 45.492C174.962 44.1083 176.581 44.02 178.046 45.5435L179.098 44.9842C180.968 44.7707 183.735 44.4543 185.722 43.4312C190.918 40.8331 191.448 37.9627 191.411 35.5339Z"
        fill="#FFA8A7"
      />
      <path
        d="M178.061 44.4617V48.2668C178.303 48.2729 178.545 48.2294 178.77 48.139C178.995 48.0485 179.199 47.9129 179.37 47.7406C179.541 47.5683 179.675 47.3629 179.764 47.137C179.852 46.911 179.894 46.6694 179.886 46.4268C179.887 45.929 179.699 45.4492 179.361 45.0844C179.022 44.7197 178.557 44.4971 178.061 44.4617V44.4617Z"
        fill="#37474F"
      />
      <path
        d="M191.588 44.9915C191.573 45.1168 191.584 45.2438 191.621 45.3645C191.658 45.4853 191.719 45.5971 191.801 45.6929C191.883 45.7888 191.984 45.8666 192.098 45.9214C192.211 45.9762 192.335 46.0069 192.461 46.0115C192.587 46.0161 192.713 45.9945 192.83 45.9481C192.948 45.9017 193.054 45.8314 193.143 45.7418C193.232 45.6522 193.301 45.5451 193.346 45.4274C193.391 45.3097 193.412 45.1838 193.406 45.0578C193.413 44.9359 193.396 44.8139 193.356 44.6988C193.315 44.5836 193.253 44.4775 193.171 44.3865C193.09 44.2956 192.991 44.2216 192.881 44.1688C192.771 44.116 192.652 44.0855 192.53 44.0789C192.285 44.0769 192.049 44.1715 191.872 44.3422C191.696 44.513 191.594 44.7462 191.588 44.9915V44.9915Z"
        fill="black"
      />
      <path
        d="M184.346 45.3964C184.346 45.5887 184.403 45.7768 184.509 45.937C184.615 46.0973 184.767 46.2227 184.944 46.2976C185.121 46.3725 185.316 46.3935 185.505 46.3581C185.694 46.3226 185.869 46.2323 186.007 46.0983C186.145 45.9643 186.24 45.7927 186.281 45.6048C186.322 45.4169 186.307 45.221 186.237 45.0417C186.168 44.8624 186.047 44.7076 185.89 44.5966C185.733 44.4856 185.547 44.4233 185.354 44.4175C185.091 44.4155 184.838 44.5171 184.649 44.7003C184.461 44.8834 184.352 45.1335 184.346 45.3964V45.3964Z"
        fill="black"
      />
      <path
        d="M193.995 43.3061L191.655 42.3714C191.706 42.2124 191.791 42.066 191.902 41.9414C192.014 41.8168 192.15 41.7167 192.302 41.6475C192.455 41.5783 192.62 41.5415 192.787 41.5394C192.954 41.5373 193.12 41.57 193.274 41.6354C193.588 41.7646 193.84 42.0117 193.974 42.3239C194.109 42.6361 194.117 42.9886 193.995 43.3061Z"
        fill="black"
      />
      <path
        d="M189.373 44.2556L189.255 49.8639L192.273 49.1868L189.373 44.2556Z"
        fill="#F28F8F"
      />
      <path
        d="M185.825 51.0046L188.769 51.9173C188.73 52.1199 188.648 52.3119 188.53 52.4809C188.411 52.65 188.259 52.7922 188.082 52.8984C187.905 53.0046 187.707 53.0723 187.503 53.0973C187.298 53.1223 187.09 53.1039 186.893 53.0434C186.489 52.902 186.155 52.6129 185.957 52.2345C185.759 51.856 185.712 51.4164 185.825 51.0046Z"
        fill="#B16668"
      />
      <path
        d="M187.452 53.0949C187.448 52.6856 187.283 52.2943 186.992 52.0063C186.701 51.7182 186.308 51.5566 185.899 51.5566H185.781C185.797 51.8842 185.911 52.1995 186.108 52.4619C186.304 52.7243 186.575 52.9217 186.885 53.0286C187.067 53.0905 187.261 53.1131 187.452 53.0949V53.0949Z"
        fill="#F28F8F"
      />
      <path
        d="M186.745 56.3481C184.626 56.0978 180.246 54.8761 179.54 53.1023C179.821 53.9971 180.368 54.7851 181.107 55.3618C182.425 56.4953 186.745 57.6729 186.745 57.6729V56.3481Z"
        fill="#F28F8F"
      />
      <path
        d="M177.957 56.9589C177.398 56.8485 176.905 57.0913 176.485 57.8568C176.066 58.6222 175.013 60.2488 175.013 60.2488C175.013 60.2488 174.498 65.6216 175.013 66.9537C175.455 68.1166 180.371 72.459 180.371 72.459C180.371 72.459 179.091 77.2872 179.047 78.8696C179.003 80.452 181.991 86.4651 182.351 89.5416C182.351 89.5416 183.411 79.8705 182.955 76.3966C182.068 70.7548 180.396 65.265 177.987 60.0869L177.957 56.9589Z"
        fill="#EBEBEB"
      />
      <path
        d="M175.551 91.1094H175.484V90.5574C176.138 90.5043 176.764 90.2727 177.295 89.8877C176.737 89.513 176.24 89.0536 175.823 88.5261C174.932 87.3779 172.989 79.1715 173.615 77.2579C174.115 75.6534 175.691 72.1059 177.096 72.0618H177.133C178.531 72.0618 180.371 75.4032 181.004 76.9414C181.74 78.7962 180.519 87.1424 179.731 88.3568C179.348 88.9134 178.884 89.4095 178.355 89.8288C178.91 90.176 179.555 90.3549 180.21 90.344L180.276 90.896C179.418 90.8954 178.58 90.6392 177.869 90.16C177.204 90.6937 176.399 91.0234 175.551 91.1094V91.1094ZM177.096 72.6138C176.272 72.6138 174.793 75.2707 174.152 77.4198C173.6 79.1789 175.47 87.1203 176.294 88.1875C176.734 88.7208 177.259 89.1783 177.847 89.5418C178.399 89.1312 178.881 88.6341 179.275 88.0698C180.011 86.9363 181.196 78.8624 180.497 77.1622C179.584 75.072 177.928 72.6138 177.096 72.6138V72.6138Z"
        fill="#455A64"
      />
      <path
        d="M181.056 90.6016C181.061 90.7079 181.044 90.8141 181.007 90.914C180.971 91.0139 180.915 91.1055 180.842 91.1838C180.77 91.2621 180.684 91.3253 180.587 91.37C180.49 91.4147 180.386 91.44 180.28 91.4443C180.173 91.4487 180.067 91.4321 179.967 91.3954C179.867 91.3587 179.776 91.3027 179.698 91.2306C179.619 91.1585 179.556 91.0717 179.511 90.9752C179.467 90.8786 179.441 90.7742 179.437 90.6679C179.428 90.4531 179.505 90.2437 179.651 90.0857C179.796 89.9276 179.999 89.8339 180.213 89.8251C180.428 89.8164 180.638 89.8932 180.796 90.0389C180.954 90.1845 181.047 90.3869 181.056 90.6016V90.6016Z"
        fill="#263238"
      />
      <path
        d="M176.309 90.8003C176.319 91.015 176.243 91.2248 176.098 91.3835C175.953 91.5422 175.751 91.6369 175.536 91.6467C175.321 91.6564 175.111 91.5805 174.953 91.4355C174.794 91.2906 174.699 91.0886 174.69 90.8739C174.68 90.6591 174.756 90.4493 174.901 90.2906C175.046 90.1319 175.248 90.0372 175.462 90.0274C175.677 90.0177 175.887 90.0936 176.046 90.2386C176.204 90.3835 176.299 90.5855 176.309 90.8003V90.8003Z"
        fill="#263238"
      />
      <path
        d="M181.1 80.5624H180.636C180.613 80.5605 180.591 80.5541 180.57 80.5434C180.549 80.5328 180.531 80.5182 180.516 80.5004C180.501 80.4826 180.489 80.462 180.482 80.4398C180.475 80.4176 180.473 80.3942 180.475 80.371C180.598 79.3273 180.548 78.2705 180.327 77.243C179.51 75.2484 177.825 72.7534 177.089 72.7902C176.353 72.827 174.881 75.4472 174.233 77.5006C174.105 78.5431 174.147 79.5997 174.358 80.6286C174.365 80.6762 174.354 80.7245 174.326 80.7641C174.299 80.8036 174.258 80.8314 174.211 80.842L173.748 80.9083C173.7 80.9146 173.651 80.902 173.613 80.8731C173.574 80.8442 173.549 80.8013 173.541 80.7537C173.292 79.6021 173.259 78.4139 173.446 77.2504C173.792 76.1537 175.418 72.0321 177.052 71.9585C178.686 71.8849 180.659 75.8593 181.093 76.9265C181.379 78.0688 181.449 79.2545 181.299 80.4225C181.287 80.4652 181.261 80.5025 181.225 80.528C181.188 80.5535 181.144 80.5657 181.1 80.5624V80.5624Z"
        fill="#726EFF"
      />
      <path
        d="M206.956 74.645L186.024 82.0271L182.948 110.106L183.411 110.599L203.305 103.172L207.42 75.1455L206.956 74.645Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M186.495 82.5276L183.411 110.599L182.948 110.106L186.024 82.0271L186.495 82.5276Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M207.419 75.1455L186.495 82.5276L183.411 110.599L203.305 103.172L207.419 75.1455Z"
        fill="black"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M201.119 80.2239C201.119 80.2239 201.193 79.8485 201.289 79.3848C201.384 78.9212 200.155 79.164 198.543 79.9295L194.768 81.0703C193.156 81.8063 191.64 82.3951 191.544 82.8588L191.367 83.6904L201.119 80.2239Z"
          fill="black"
        />
      </g>
      <path
        d="M202.356 76.4702C202.422 76.1758 201.944 76.2347 201.274 76.4113L200.067 76.8235C200.067 76.8235 200.56 75.6164 197.712 76.3892C194.569 77.2356 194.503 78.8033 194.503 78.8033C194.023 79.0148 193.531 79.1991 193.031 79.3553C191.993 79.6939 191.875 79.8926 191.787 80.187L191.64 80.7169L202.304 76.9633C202.304 76.9633 202.297 76.7572 202.356 76.4702Z"
        fill="#37474F"
      />
      <path
        d="M201.237 78.0675C200.251 78.5164 199.839 78.7372 199.692 79.4438C199.522 80.2608 199.485 80.592 199.485 80.592L193.936 82.7999C193.936 82.7999 194.238 81.6076 194.311 81.2102C194.385 80.8127 193.649 80.8054 192.685 81.2544L192.913 80.2534L201.554 77.0444L201.237 78.0675Z"
        fill="#37474F"
      />
      <path
        d="M201.406 80.2238L201.576 79.3922C201.671 78.9285 200.442 79.1714 198.83 79.9368L195.04 81.107C193.428 81.843 191.912 82.4318 191.816 82.8955L191.64 83.7272L201.406 80.2238Z"
        fill="#37474F"
      />
      <path
        d="M203.607 81.7546L188.313 87.5543L185.862 111.379L201.362 105.395L203.607 81.7546Z"
        fill="#455A64"
      />
      <path
        d="M188.46 88.1654L203.541 82.4246C204.195 83.2714 205.103 83.8876 206.132 84.1836L191.161 89.9097C190.186 89.4536 189.277 88.8668 188.46 88.1654V88.1654Z"
        fill="#F5F5F5"
      />
      <path
        d="M192.31 89.9759C191.937 90.1241 191.535 90.1821 191.136 90.145C190.737 90.1079 190.352 89.9768 190.013 89.7625L188.114 88.4083C187.994 88.3371 187.897 88.2333 187.834 88.109C187.771 87.9847 187.744 87.845 187.757 87.7062C187.771 87.5674 187.823 87.4352 187.908 87.325C187.993 87.2147 188.108 87.1309 188.239 87.0835L203.136 81.4163L203.607 81.7548L188.887 87.3631C188.801 87.3825 188.722 87.4289 188.664 87.4955C188.605 87.5621 188.569 87.6455 188.561 87.7338C188.553 87.8221 188.573 87.9107 188.618 87.9869C188.664 88.0632 188.732 88.123 188.814 88.158L190.58 89.4019C190.784 89.5316 191.015 89.6112 191.256 89.6342C191.496 89.6572 191.739 89.623 191.964 89.5343L206.684 83.9334L207.214 84.3161L192.31 89.9759Z"
        fill="#263238"
      />
      <path
        d="M207.206 84.3159L204.998 107.956L190.101 113.616C189.729 113.764 189.327 113.822 188.928 113.785C188.529 113.748 188.144 113.617 187.805 113.403L185.899 112.048C185.77 111.975 185.663 111.867 185.59 111.738C185.518 111.608 185.482 111.461 185.487 111.312L187.68 87.7015C187.673 87.8505 187.708 87.9985 187.78 88.1287C187.853 88.2589 187.961 88.3659 188.092 88.4375L189.991 89.7918C190.33 90.0061 190.714 90.1371 191.114 90.1742C191.513 90.2113 191.915 90.1534 192.287 90.0052L207.206 84.3159Z"
        fill="#455A64"
      />
      <path
        d="M191.139 90.1451C190.737 90.1169 190.349 89.9851 190.013 89.7624L188.114 88.4081C187.983 88.3365 187.875 88.2295 187.803 88.0993C187.73 87.9691 187.695 87.8211 187.702 87.6721L185.472 111.342C185.467 111.49 185.503 111.637 185.576 111.767C185.648 111.897 185.755 112.004 185.884 112.078L187.79 113.432C188.125 113.652 188.51 113.783 188.909 113.815L191.139 90.1451Z"
        fill="#37474F"
      />
      <path
        d="M206.89 87.7017C208.141 88.5481 208.237 90.0495 208.237 91.6466C208.237 93.2437 208.237 95.7388 207.206 97.093C206.176 98.4472 204.181 100.383 202.893 99.7426C201.605 99.1023 201.694 94.5244 203.21 92.8684C204.275 91.7543 205.438 90.7371 206.684 89.8287L206.89 87.7017Z"
        fill="#FFA8A7"
      />
      <path
        d="M191.316 94.8261C189.831 95.0688 188.32 95.1035 186.826 94.9291C187.517 94.6386 188.161 94.2493 188.74 93.7736C189.061 93.541 189.282 93.1952 189.359 92.806C189.435 92.4167 189.361 92.0129 189.152 91.676C188.939 91.4037 188.313 91.9851 186.944 92.2943C185.222 92.6991 184.125 92.4047 182.432 93.2879C181.726 93.5943 180.954 93.7183 180.188 93.6485C178.9 93.6485 177.163 93.1627 173.821 92.1323C171.045 91.1891 168.327 90.0832 165.681 88.8203L170.752 74.0635C170.752 74.0635 173.696 64.2306 169.692 61.0437C165.011 61.0437 163.642 63.2517 162.207 67.4543C160.897 71.2447 156.488 88.9602 156.245 91.6466C156.003 94.333 157.217 95.4002 159.889 96.5631C162.56 97.7259 168.721 99.2127 174.358 100.744C177.158 101.557 180.025 102.114 182.925 102.407C189.601 102.922 192.648 102.407 194.083 99.8824C196.387 95.908 194.871 94.2815 191.316 94.8261Z"
        fill="#FFA8A7"
      />
      <path
        d="M170.369 60.8599C167.58 60.7348 165.129 60.5949 163.304 63.8848C161.478 67.1748 158.748 77.3389 157.276 84.6327C155.804 91.9264 154.759 94.8263 158.122 96.313C161.486 97.7997 172.747 100.604 172.747 100.604C172.747 100.604 172.334 96.0186 175.44 92.6256L166.226 88.6954L171.157 74.2551C171.157 74.2551 174.278 64.9888 170.369 60.8599Z"
        fill="#FAFAFA"
      />
      <path
        d="M163.775 229.132C163.916 229.501 163.952 229.903 163.881 230.292C163.81 230.682 163.633 231.044 163.37 231.34C161.394 232.709 158.965 233.26 156.591 232.878C153.228 232.444 151.439 231.406 150.063 230.272C148.687 229.139 146.825 227.557 145.308 227.225C144.094 226.992 142.938 226.519 141.908 225.834C141.452 225.393 141.518 223.45 141.518 223.45L163.775 229.132Z"
        fill="#455A64"
      />
      <path
        d="M142.858 215.95C142.734 215.953 142.615 216 142.523 216.082C142.43 216.164 142.369 216.276 142.35 216.399C142.247 216.936 142.136 218.496 141.908 219.343C141.561 220.89 141.449 222.48 141.577 224.06C141.768 225.061 144.072 225.753 145.544 226.313C147.275 227.072 148.883 228.086 150.313 229.323C152.375 230.867 154.839 231.784 157.408 231.965C160.006 231.965 162.928 231.111 163.598 229.904C164.422 228.432 163.775 227.556 159.918 225.775C159.182 225.437 156.636 223.921 155.767 223.376C153.405 221.904 151.071 219.821 149.982 217.194C149.894 216.871 149.7 216.588 149.43 216.391C149.198 216.293 148.94 216.27 148.694 216.325C148.05 216.414 147.425 216.602 146.839 216.884C146.324 217.127 145.588 217.414 145.603 218.077C145.611 218.307 145.661 218.534 145.75 218.746C145.794 218.879 145.831 219.063 145.713 219.144C145.596 219.225 145.448 219.144 145.353 219.041C144.973 218.655 144.626 218.239 144.315 217.797C144.136 217.568 143.931 217.36 143.704 217.179C143.478 217.049 143.259 216.907 143.049 216.752C142.784 216.546 142.872 216.259 142.858 215.95Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M142.858 215.95C142.734 215.953 142.615 216 142.523 216.082C142.43 216.164 142.369 216.276 142.35 216.399C142.247 216.936 142.136 218.496 141.908 219.343C141.561 220.89 141.449 222.48 141.577 224.06C141.768 225.061 144.072 225.753 145.544 226.313C147.275 227.072 148.883 228.086 150.313 229.323C152.375 230.867 154.839 231.784 157.408 231.965C160.006 231.965 162.928 231.111 163.598 229.904C164.422 228.432 163.775 227.556 159.918 225.775C159.182 225.437 156.636 223.921 155.767 223.376C153.405 221.904 151.071 219.821 149.982 217.194C149.894 216.871 149.7 216.588 149.43 216.391C149.198 216.293 148.94 216.27 148.694 216.325C148.05 216.414 147.425 216.602 146.839 216.884C146.324 217.127 145.588 217.414 145.603 218.077C145.611 218.307 145.661 218.534 145.75 218.746C145.794 218.879 145.831 219.063 145.713 219.144C145.596 219.225 145.448 219.144 145.353 219.041C144.973 218.655 144.626 218.239 144.315 217.797C144.136 217.568 143.931 217.36 143.704 217.179C143.478 217.049 143.259 216.907 143.049 216.752C142.784 216.546 142.872 216.259 142.858 215.95Z"
        fill="white"
      />
      <path
        d="M150.313 229.352C149.643 228.8 148.929 228.263 148.216 227.785C148.216 227.785 152.735 230.537 154.567 229.257C154.904 229.041 155.151 228.709 155.259 228.324C155.368 227.938 155.331 227.526 155.156 227.166C155.156 226.85 158.034 225.51 159.175 225.378C159.491 225.555 159.763 225.694 159.911 225.775C163.76 227.557 164.408 228.418 163.591 229.904C162.921 231.111 160.801 232.046 157.452 232.046C155.296 232.076 151.896 230.655 150.313 229.352Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M150.313 229.352C149.643 228.8 148.929 228.263 148.216 227.785C148.216 227.785 152.735 230.537 154.567 229.257C154.904 229.041 155.151 228.709 155.259 228.324C155.368 227.938 155.331 227.526 155.156 227.166C155.156 226.85 158.034 225.51 159.175 225.378C159.491 225.555 159.763 225.694 159.911 225.775C163.76 227.557 164.408 228.418 163.591 229.904C162.921 231.111 160.801 232.046 157.452 232.046C155.296 232.076 151.896 230.655 150.313 229.352Z"
        fill="white"
      />
      <path
        d="M154.84 226.283C157.232 227.24 156.209 227.689 155.576 227.755C154.943 227.822 150.88 225.496 148.429 223.553C145.868 221.529 144.249 219.313 143.277 218.57C142.924 218.29 142.541 217.952 142.203 217.628C142.262 217.105 142.313 216.627 142.365 216.369C142.383 216.247 142.445 216.135 142.537 216.053C142.63 215.971 142.749 215.924 142.872 215.92C142.872 216.23 142.828 216.517 143.064 216.752C143.274 216.907 143.492 217.05 143.719 217.179C143.946 217.361 144.151 217.568 144.33 217.797C144.641 218.24 144.988 218.656 145.367 219.041C147.443 221.72 152.448 225.327 154.84 226.283Z"
        fill="#726EFF"
      />
      <path
        d="M155.37 227.424C155.325 227.422 155.282 227.408 155.245 227.384C155.208 227.359 155.178 227.325 155.159 227.285C155.14 227.245 155.132 227.2 155.137 227.156C155.142 227.111 155.159 227.069 155.186 227.034C155.755 226.446 156.444 225.987 157.206 225.689C157.967 225.391 158.785 225.259 159.602 225.304C159.663 225.313 159.719 225.346 159.758 225.395C159.796 225.445 159.814 225.507 159.808 225.569C159.805 225.6 159.796 225.63 159.781 225.657C159.767 225.684 159.747 225.708 159.723 225.727C159.699 225.747 159.671 225.761 159.641 225.769C159.612 225.777 159.581 225.779 159.55 225.775C158.817 225.745 158.085 225.868 157.402 226.137C156.719 226.406 156.099 226.814 155.583 227.336C155.558 227.367 155.525 227.392 155.488 227.408C155.45 227.423 155.41 227.429 155.37 227.424Z"
        fill="#FAFAFA"
      />
      <path
        d="M153.287 226.143C153.231 226.146 153.176 226.127 153.132 226.092C153.089 226.048 153.064 225.988 153.064 225.926C153.064 225.864 153.089 225.805 153.132 225.761C153.697 225.177 154.38 224.72 155.135 224.422C155.89 224.124 156.7 223.991 157.511 224.031C157.542 224.034 157.572 224.044 157.6 224.059C157.627 224.074 157.651 224.094 157.67 224.118C157.69 224.142 157.704 224.17 157.713 224.2C157.722 224.23 157.725 224.261 157.721 224.292C157.718 224.323 157.708 224.353 157.693 224.38C157.678 224.408 157.658 224.432 157.634 224.451C157.61 224.471 157.582 224.485 157.552 224.494C157.522 224.503 157.491 224.505 157.46 224.502C156.728 224.471 155.998 224.593 155.316 224.86C154.634 225.127 154.016 225.534 153.5 226.055C153.475 226.086 153.442 226.111 153.405 226.126C153.367 226.142 153.327 226.147 153.287 226.143V226.143Z"
        fill="#FAFAFA"
      />
      <path
        d="M151.196 224.929C151.143 224.93 151.091 224.911 151.049 224.878C151.006 224.833 150.981 224.774 150.981 224.712C150.981 224.65 151.006 224.59 151.049 224.546C151.616 223.956 152.305 223.495 153.067 223.197C153.83 222.898 154.648 222.768 155.465 222.817C155.526 222.824 155.582 222.855 155.621 222.903C155.659 222.952 155.678 223.013 155.671 223.074C155.669 223.105 155.66 223.135 155.645 223.163C155.631 223.191 155.611 223.215 155.587 223.235C155.563 223.255 155.535 223.27 155.506 223.279C155.476 223.288 155.445 223.291 155.414 223.288C154.681 223.256 153.949 223.378 153.266 223.646C152.583 223.913 151.963 224.32 151.447 224.841C151.419 224.879 151.38 224.908 151.335 224.924C151.291 224.939 151.242 224.941 151.196 224.929Z"
        fill="#FAFAFA"
      />
      <path
        d="M149.261 223.538C149.207 223.539 149.155 223.52 149.114 223.486C149.07 223.442 149.046 223.383 149.046 223.321C149.046 223.259 149.07 223.199 149.114 223.155C149.683 222.567 150.372 222.109 151.134 221.81C151.896 221.512 152.713 221.381 153.53 221.426C153.592 221.432 153.649 221.464 153.689 221.513C153.728 221.562 153.746 221.624 153.739 221.687C153.733 221.749 153.701 221.807 153.652 221.846C153.603 221.885 153.541 221.903 153.478 221.897C152.745 221.866 152.013 221.988 151.33 222.257C150.646 222.526 150.027 222.935 149.511 223.457C149.482 223.493 149.442 223.52 149.398 223.534C149.354 223.549 149.306 223.55 149.261 223.538V223.538Z"
        fill="#FAFAFA"
      />
      <path
        d="M147.369 222.154C147.316 222.152 147.265 222.134 147.222 222.103C147.173 222.062 147.141 222.004 147.134 221.941C147.128 221.878 147.146 221.814 147.185 221.764C147.797 221.128 148.546 220.64 149.375 220.337C150.204 220.035 151.092 219.926 151.969 220.02C152.024 220.034 152.073 220.067 152.105 220.114C152.137 220.161 152.152 220.218 152.145 220.274C152.139 220.331 152.113 220.383 152.071 220.421C152.029 220.46 151.975 220.482 151.918 220.484C151.119 220.405 150.313 220.506 149.558 220.779C148.803 221.051 148.118 221.488 147.553 222.059C147.532 222.087 147.504 222.11 147.472 222.127C147.44 222.144 147.405 222.153 147.369 222.154V222.154Z"
        fill="#FAFAFA"
      />
      <path
        d="M127.394 235.064C127.49 237.353 127.291 238.898 126.614 240.311C126.275 240.976 125.724 241.509 125.048 241.826C124.373 242.143 123.611 242.227 122.883 242.063C121.3 241.828 119.203 240.981 118.584 239.06C118.008 237.319 117.823 235.472 118.04 233.651L127.394 235.064Z"
        fill="#455A64"
      />
      <path
        d="M119.607 219.218C118.974 219.328 119.092 222.397 119.033 225.496C118.974 228.808 118.128 230.265 118.047 232.988C117.959 236.094 118.26 237.456 119.519 239.2C120.778 240.944 124.774 242.387 126.379 239.538C127.696 237.22 127.512 234.806 127.07 231.759C126.63 229.181 126.321 226.582 126.143 223.972C126.033 221.661 125.966 219.416 125.407 219.218C125.407 219.556 125.407 219.954 125.407 220.322C125.407 220.69 125.458 221.794 125.105 221.882C125.051 221.595 125.019 221.305 125.01 221.013C125.026 220.746 124.939 220.483 124.767 220.277C124.556 220.116 124.296 220.03 124.031 220.034C123.393 219.963 122.751 219.929 122.11 219.931C121.793 219.931 121.469 219.931 121.153 219.983C120.879 220.005 120.61 220.072 120.358 220.182C120.285 220.215 120.22 220.262 120.167 220.322C120.114 220.382 120.074 220.452 120.049 220.528C120.031 220.645 120.031 220.764 120.049 220.881C120.075 221.094 120.075 221.309 120.049 221.521C120.049 221.617 119.983 221.727 119.887 221.72C119.791 221.713 119.777 221.617 119.769 221.543C119.725 221.175 119.769 220.896 119.725 220.469C119.725 220.263 119.725 219.99 119.725 219.792C119.725 219.593 119.615 219.409 119.607 219.218Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M119.607 219.218C118.974 219.328 119.092 222.397 119.033 225.496C118.974 228.808 118.128 230.265 118.047 232.988C117.959 236.094 118.26 237.456 119.519 239.2C120.778 240.944 124.774 242.387 126.379 239.538C127.696 237.22 127.512 234.806 127.07 231.759C126.63 229.181 126.321 226.582 126.143 223.972C126.033 221.661 125.966 219.416 125.407 219.218C125.407 219.556 125.407 219.954 125.407 220.322C125.407 220.69 125.458 221.794 125.105 221.882C125.051 221.595 125.019 221.305 125.01 221.013C125.026 220.746 124.939 220.483 124.767 220.277C124.556 220.116 124.296 220.03 124.031 220.034C123.393 219.963 122.751 219.929 122.11 219.931C121.793 219.931 121.469 219.931 121.153 219.983C120.879 220.005 120.61 220.072 120.358 220.182C120.285 220.215 120.22 220.262 120.167 220.322C120.114 220.382 120.074 220.452 120.049 220.528C120.031 220.645 120.031 220.764 120.049 220.881C120.075 221.094 120.075 221.309 120.049 221.521C120.049 221.617 119.983 221.727 119.887 221.72C119.791 221.713 119.777 221.617 119.769 221.543C119.725 221.175 119.769 220.896 119.725 220.469C119.725 220.263 119.725 219.99 119.725 219.792C119.725 219.593 119.615 219.409 119.607 219.218Z"
        fill="white"
      />
      <path
        d="M118.106 232.098C118.106 232.547 118.01 235.52 118.842 235.969C119.674 236.418 120.005 234.099 120.005 234.099C120.005 234.099 121.97 233.275 125.046 234.269C124.943 234.762 125.554 236.278 126.334 235.866C127.114 235.454 127.07 232.061 127.07 232.017C127.482 234.961 127.615 237.286 126.334 239.539C124.73 242.387 120.733 240.952 119.475 239.2C118.216 237.448 117.914 236.094 118.003 232.988C118.062 232.672 118.076 232.385 118.106 232.098Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M118.106 232.098C118.106 232.547 118.01 235.52 118.842 235.969C119.674 236.418 120.005 234.099 120.005 234.099C120.005 234.099 121.97 233.275 125.046 234.269C124.943 234.762 125.554 236.278 126.334 235.866C127.114 235.454 127.07 232.061 127.07 232.017C127.482 234.961 127.615 237.286 126.334 239.539C124.73 242.387 120.733 240.952 119.475 239.2C118.216 237.448 117.914 236.094 118.003 232.988C118.062 232.672 118.076 232.385 118.106 232.098Z"
        fill="white"
      />
      <path
        d="M120.277 234.269C120.373 235.38 119.239 235.115 119.033 233.533C118.827 231.95 119.313 228.167 119.033 225.474C119.092 222.375 118.974 219.306 119.607 219.188C119.603 219.227 119.603 219.267 119.607 219.306L119.681 220.866C119.667 221.089 119.667 221.313 119.681 221.536C119.894 223.803 120.012 228.204 120.027 230.294C120.037 231.623 120.12 232.95 120.277 234.269V234.269Z"
        fill="#726EFF"
      />
      <path
        d="M124.723 234.269C124.867 232.956 124.938 231.637 124.936 230.316C124.936 228.241 125.069 223.891 125.282 221.61C125.356 221.184 125.386 220.753 125.37 220.322C125.37 220.012 125.37 219.586 125.37 219.284V219.218C125.79 219.365 125.937 220.594 126.033 222.162C126.033 223.052 126.033 224.163 125.944 225.518C125.665 228.211 126.15 232.039 125.944 233.614C125.738 235.189 124.627 235.38 124.723 234.269Z"
        fill="#726EFF"
      />
      <path
        d="M125.12 234.35H125.032C123.43 233.796 121.688 233.796 120.086 234.35C120.057 234.361 120.026 234.367 119.995 234.366C119.964 234.365 119.933 234.358 119.905 234.345C119.876 234.332 119.851 234.313 119.83 234.29C119.81 234.267 119.794 234.24 119.784 234.21C119.772 234.182 119.766 234.151 119.767 234.12C119.767 234.089 119.773 234.059 119.786 234.031C119.798 234.003 119.816 233.977 119.839 233.956C119.861 233.935 119.888 233.919 119.916 233.908C121.628 233.301 123.497 233.301 125.208 233.908C125.265 233.934 125.31 233.98 125.334 234.037C125.359 234.094 125.361 234.159 125.341 234.217C125.321 234.258 125.289 234.293 125.25 234.316C125.211 234.34 125.166 234.352 125.12 234.35V234.35Z"
        fill="#FAFAFA"
      />
      <path
        d="M125.429 231.891H125.348C123.546 231.332 121.616 231.332 119.813 231.891C119.754 231.91 119.689 231.906 119.633 231.878C119.577 231.851 119.533 231.803 119.512 231.744C119.501 231.715 119.497 231.684 119.499 231.652C119.5 231.621 119.508 231.591 119.522 231.563C119.536 231.534 119.555 231.509 119.578 231.489C119.602 231.468 119.629 231.452 119.659 231.442C121.563 230.842 123.606 230.842 125.51 231.442C125.569 231.464 125.616 231.508 125.642 231.564C125.669 231.621 125.671 231.686 125.65 231.744C125.633 231.789 125.603 231.827 125.563 231.853C125.524 231.88 125.477 231.893 125.429 231.891V231.891Z"
        fill="#FAFAFA"
      />
      <path
        d="M119.74 229.558C119.694 229.559 119.648 229.546 119.609 229.521C119.57 229.496 119.539 229.461 119.519 229.419C119.494 229.361 119.492 229.296 119.514 229.237C119.536 229.177 119.58 229.129 119.637 229.102C121.515 228.376 123.596 228.376 125.473 229.102C125.502 229.113 125.529 229.129 125.551 229.15C125.574 229.171 125.592 229.197 125.604 229.225C125.616 229.253 125.623 229.283 125.623 229.314C125.624 229.345 125.618 229.375 125.606 229.404C125.595 229.433 125.577 229.46 125.556 229.482C125.534 229.505 125.508 229.523 125.479 229.535C125.45 229.547 125.419 229.554 125.388 229.554C125.357 229.554 125.326 229.548 125.297 229.536C125.297 229.536 122.397 228.396 119.836 229.536C119.806 229.551 119.773 229.558 119.74 229.558V229.558Z"
        fill="#FAFAFA"
      />
      <path
        d="M119.681 227.424C119.636 227.423 119.592 227.409 119.554 227.384C119.517 227.36 119.487 227.325 119.467 227.284C119.442 227.227 119.439 227.163 119.459 227.104C119.48 227.045 119.522 226.996 119.578 226.968C120.512 226.544 121.523 226.319 122.549 226.309C123.574 226.299 124.59 226.503 125.532 226.909C125.56 226.921 125.585 226.94 125.606 226.962C125.627 226.985 125.643 227.011 125.653 227.04C125.663 227.069 125.668 227.099 125.666 227.13C125.664 227.161 125.656 227.19 125.643 227.218C125.615 227.275 125.567 227.319 125.508 227.341C125.449 227.363 125.384 227.361 125.326 227.336C124.447 226.967 123.502 226.782 122.549 226.792C121.596 226.802 120.655 227.007 119.784 227.395C119.754 227.415 119.718 227.426 119.681 227.424Z"
        fill="#FAFAFA"
      />
      <path
        d="M119.629 224.944C119.586 224.943 119.544 224.93 119.508 224.906C119.471 224.883 119.442 224.85 119.423 224.811C119.408 224.784 119.399 224.754 119.396 224.723C119.393 224.692 119.397 224.66 119.406 224.631C119.416 224.601 119.432 224.574 119.452 224.55C119.473 224.527 119.498 224.508 119.526 224.495C122.353 223.096 125.414 224.428 125.525 224.495C125.582 224.52 125.627 224.567 125.65 224.626C125.673 224.685 125.672 224.75 125.646 224.807C125.621 224.865 125.574 224.91 125.515 224.933C125.456 224.956 125.391 224.954 125.333 224.929C125.333 224.929 122.389 223.626 119.74 224.929C119.705 224.944 119.667 224.949 119.629 224.944V224.944Z"
        fill="#EBEBEB"
      />
      <path
        d="M123.508 124.737C121.766 128.739 120.635 132.979 120.152 137.316C118.937 153.095 119.593 175.749 119.593 175.749C119.453 177.398 118.408 181.144 117.708 188.401C116.877 196.983 117.914 226.04 117.914 226.04C117.914 226.04 123.066 229.102 127.041 225.621C127.041 225.621 131.678 185.671 132.752 177.141C133.694 169.7 136.314 153.589 136.314 153.589L139.391 177.398C139.391 177.398 139.104 184.096 139.303 188.438C139.531 193.472 140.944 219.115 140.944 219.115C140.944 219.115 147.987 221.499 151.137 218.114C151.137 218.114 151.873 182.543 151.991 176.478C152.205 164.599 151.991 142.946 150.07 130.485L123.508 124.737Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M123.508 124.737C121.766 128.739 120.635 132.979 120.152 137.316C118.937 153.095 119.593 175.749 119.593 175.749C119.453 177.398 118.408 181.144 117.708 188.401C116.877 196.983 117.914 226.04 117.914 226.04C117.914 226.04 123.066 229.102 127.041 225.621C127.041 225.621 131.678 185.671 132.752 177.141C133.694 169.7 136.314 153.589 136.314 153.589L139.391 177.398C139.391 177.398 139.104 184.096 139.303 188.438C139.531 193.472 140.944 219.115 140.944 219.115C140.944 219.115 147.987 221.499 151.137 218.114C151.137 218.114 151.873 182.543 151.991 176.478C152.205 164.599 151.991 142.946 150.07 130.485L123.508 124.737Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M136.314 153.603L137.418 146.023C137.418 146.023 143.814 145.169 146.714 142.446C145.883 143.742 144.777 144.84 143.475 145.662C142.173 146.483 140.705 147.009 139.177 147.2L138.044 154.052L139.023 174.55L136.314 153.603Z"
        fill="black"
      />
      <path
        d="M144.543 93.4719C148.378 93.8399 149.695 94.8262 151.167 97.8438C152.639 100.861 156.54 114.404 156.54 114.404L146.618 118.157L144.543 93.4719Z"
        fill="#726EFF"
      />
      <path
        opacity="0.1"
        d="M144.543 93.4719C148.378 93.8399 149.695 94.8262 151.167 97.8438C152.639 100.861 156.54 114.404 156.54 114.404L146.618 118.157L144.543 93.4719Z"
        fill="black"
      />
      <path
        d="M149.585 113.344C149.305 116.457 149.239 122.544 149.239 122.544C150.365 130.5 151.27 138.272 151.27 138.272C139.384 144.617 121.447 139.479 119.188 136.867C120.299 129.072 122.765 124.222 124.34 119.836L121.396 106.588C121.396 106.588 126.548 102.326 126.055 93.6781L133.083 92.9421L139.781 93.1261L144.543 93.4647C146.932 95.7849 149.024 98.3932 150.77 101.229C153.088 104.939 153.169 109.576 149.585 113.344Z"
        fill="#726EFF"
      />
      <path
        opacity="0.1"
        d="M144.411 98.4694C143.785 96.6136 142.897 94.8565 141.776 93.2511L139.781 93.1039L133.084 92.9199L130.463 93.1922C130.279 94.1122 132.671 96.9458 134.784 98.646C136.896 100.346 141.474 102.746 143.954 103.246C144.73 101.776 144.895 100.059 144.411 98.4694V98.4694Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M137.919 108.788L130 109.105V110.628L138.03 110.29L137.919 108.788Z"
        fill="black"
      />
      <path
        d="M135.365 108.884L135.189 106.654H130L130.221 109.097L135.365 108.884Z"
        fill="#FAFAFA"
      />
      <path
        d="M137.566 108.796C137.441 106.212 137.161 104.939 136.955 104.777C136.87 104.706 136.763 104.668 136.653 104.668C136.543 104.668 136.436 104.706 136.351 104.777C136.153 105.027 136.005 106.359 136.02 108.862L137.566 108.796Z"
        fill="#455A64"
      />
      <path
        d="M136.962 108.575C136.962 109.561 137.036 111.445 136.69 111.475C136.344 111.504 136.381 109.649 136.366 108.575H136.962Z"
        fill="#455A64"
      />
      <path
        d="M131.619 88.7102C131.619 87.5179 129.948 85.0302 128.425 82.2628C126.901 79.4955 125.253 75.0132 128.425 70.8254C131.597 66.6375 138.773 64.1425 142.998 66.1223C147.222 68.1022 148.414 72.9377 148.414 72.9377L131.619 88.7102Z"
        fill="black"
      />
      <path
        d="M130.677 84.3383C132.399 86.1856 133.429 88.0183 131.582 89.7553C130.687 90.53 129.527 90.928 128.344 90.8656C127.162 90.8032 126.05 90.2853 125.242 89.4207C124.433 88.556 123.991 87.4117 124.008 86.2281C124.025 85.0444 124.5 83.9134 125.333 83.0724C127.173 81.3501 128.947 82.4983 130.677 84.3383Z"
        fill="black"
      />
      <path
        d="M145.544 69.2429C147.134 70.0525 149.349 71.7821 149.003 79.8634C148.738 86.1709 146.435 89.0781 145.323 89.6375C144.212 90.1968 142.917 90.3146 140.812 90.0938V93.6855C140.812 93.6855 144.896 98.6682 143.417 101.943C143.417 101.943 139.111 100.39 136.793 98.7123C132.245 95.4371 131.685 93.6487 131.685 93.6487L131.575 85.0154C131.575 85.0154 130.169 86.0458 127.976 84.353C126.165 82.9546 125.429 80.4595 126.688 79.017C127.017 78.6509 127.436 78.3765 127.903 78.2204C128.369 78.0643 128.869 78.0319 129.352 78.1264C129.878 78.244 130.37 78.4802 130.791 78.8169C131.212 79.1537 131.551 79.5821 131.781 80.0695C131.819 80.1715 131.881 80.2629 131.961 80.336C132.042 80.409 132.139 80.4615 132.245 80.489C132.325 80.491 132.405 80.4741 132.478 80.4395C132.551 80.405 132.615 80.3539 132.664 80.2903C132.762 80.1633 132.835 80.0183 132.878 79.8634C133.326 78.5239 133.12 77.1181 133.437 75.7565C133.681 74.6897 134.147 73.6865 134.806 72.8125C136.349 70.8219 138.6 69.5036 141.091 69.1325C142.571 68.915 144.077 68.9524 145.544 69.2429Z"
        fill="#FFBDA7"
      />
      <path
        d="M150.004 75.8006L150.438 75.8448C150.583 75.8903 150.706 75.9855 150.787 76.1135C150.868 76.2415 150.901 76.3941 150.88 76.544C150.786 76.7812 150.634 76.9912 150.438 77.1549C150.358 77.2356 150.298 77.3339 150.262 77.4419C150.121 78.1955 149.912 78.9346 149.636 79.6499C149.387 80.1516 148.993 80.5666 148.504 80.8409C148.016 81.1153 147.456 81.2363 146.898 81.1881C146.59 81.1938 146.281 81.1666 145.978 81.1072H145.912C145.656 81.0525 145.408 80.9634 145.176 80.8422H145.132H145.073L144.992 80.7907L144.918 80.7465L144.838 80.6877L144.764 80.6214C144.711 80.5812 144.662 80.5369 144.617 80.4889C144.372 80.2436 144.173 79.9571 144.028 79.6425C143.653 78.8624 143.77 77.7805 143.13 77.648H142.968C142.092 77.648 142.166 78.8256 141.665 79.6646C141.165 80.5037 140.341 81.3133 138.442 81.3133H138.199H138.007L137.551 81.2544H137.404H137.271H137.183C137.004 81.2001 136.831 81.1261 136.668 81.0336L136.499 80.9305C136.396 80.8584 136.297 80.7797 136.204 80.695C135.97 80.4696 135.781 80.2025 135.645 79.9075C135.415 79.1885 135.255 78.4491 135.166 77.6995C135.14 77.5839 135.093 77.4741 135.027 77.3757L134.843 77.1475L130.427 78.5533C130.291 78.4649 130.148 78.3885 130 78.3251C130.18 78.0178 130.376 77.7205 130.589 77.4346L135.159 75.8963L135.623 75.8448C137.158 75.6597 138.713 75.6969 140.238 75.9552C141.702 76.3232 142.262 76.7501 143.02 76.7427C143.778 76.7354 144.286 76.3158 145.677 75.9405C147.103 75.6723 148.563 75.6251 150.004 75.8006Z"
        fill="#37474F"
      />
      <path
        d="M139.715 78.0529C139.736 78.294 139.662 78.5339 139.51 78.7219C139.358 78.91 139.138 79.0316 138.898 79.0612C138.781 79.0777 138.662 79.0703 138.548 79.0397C138.434 79.009 138.328 78.9557 138.235 78.8828C138.142 78.8099 138.065 78.7191 138.008 78.6157C137.951 78.5123 137.916 78.3984 137.904 78.2811C137.885 78.0403 137.959 77.8014 138.111 77.6138C138.264 77.4262 138.482 77.3041 138.721 77.2727C138.838 77.2573 138.957 77.2654 139.07 77.2965C139.184 77.3276 139.29 77.381 139.382 77.4537C139.475 77.5264 139.552 77.6168 139.609 77.7197C139.666 77.8226 139.702 77.9359 139.715 78.0529V78.0529Z"
        fill="black"
      />
      <path
        d="M139.59 84.0881L142.762 84.8904C142.719 85.1023 142.633 85.3033 142.51 85.4813C142.387 85.6592 142.23 85.8105 142.047 85.926C141.864 86.0415 141.659 86.1188 141.446 86.1534C141.232 86.1879 141.014 86.1789 140.804 86.1269C140.377 86.0108 140.012 85.7333 139.785 85.3529C139.558 84.9726 139.488 84.5191 139.59 84.0881V84.0881Z"
        fill="#F0997A"
      />
      <path
        d="M147.686 75.234L145.691 74.7409C145.72 74.6119 145.774 74.49 145.85 74.3824C145.927 74.2749 146.025 74.184 146.138 74.1153C146.25 74.0465 146.376 74.0013 146.507 73.9823C146.637 73.9633 146.771 73.971 146.898 74.0049C147.161 74.0714 147.389 74.2355 147.535 74.4637C147.681 74.692 147.735 74.9674 147.686 75.234V75.234Z"
        fill="black"
      />
      <path
        d="M138.537 74.645L136.675 75.5209C136.613 75.4027 136.575 75.273 136.564 75.1398C136.554 75.0066 136.57 74.8725 136.613 74.7459C136.656 74.6192 136.724 74.5026 136.813 74.403C136.902 74.3034 137.01 74.223 137.131 74.1666C137.382 74.0501 137.668 74.0348 137.93 74.1239C138.192 74.2131 138.41 74.3997 138.537 74.645V74.645Z"
        fill="black"
      />
      <path
        d="M147.141 78.016C147.162 78.256 147.09 78.4949 146.939 78.6827C146.788 78.8706 146.57 78.993 146.332 79.0243C146.214 79.0397 146.095 79.0314 145.981 78.9998C145.867 78.9683 145.761 78.9142 145.668 78.8408C145.575 78.7673 145.498 78.676 145.442 78.5723C145.385 78.4685 145.35 78.3544 145.338 78.2368C145.317 77.9965 145.391 77.7575 145.543 77.5706C145.696 77.3837 145.915 77.2636 146.155 77.2358C146.271 77.2194 146.39 77.2268 146.503 77.2575C146.617 77.2882 146.723 77.3417 146.815 77.4146C146.907 77.4876 146.984 77.5785 147.04 77.6819C147.096 77.7852 147.13 77.8989 147.141 78.016V78.016Z"
        fill="black"
      />
      <path
        d="M142.681 77.8174L143.019 82.8222L145.691 81.8139L142.681 77.8174Z"
        fill="#F0997A"
      />
      <path
        d="M140.834 90.0937C138.626 89.7625 133.893 88.5776 133.083 86.8333C133.418 87.8302 134.05 88.7009 134.894 89.3283C136.315 90.4029 140.834 91.367 140.834 91.367V90.0937Z"
        fill="#F0997A"
      />
      <path
        d="M148.466 73.129C148.466 73.129 147.31 71.2669 142.033 71.7674C139.132 72.0761 136.334 73.0156 133.834 74.52C132.278 75.4741 130.966 76.7768 130 78.3251C129.792 78.2358 129.574 78.1692 129.352 78.1264C128.868 78.0328 128.367 78.0655 127.899 78.2215C127.432 78.3775 127.012 78.6515 126.68 79.017V79.017C125.52 77.1896 125.057 75.0049 125.378 72.864C125.944 69.184 129.588 66.6522 133.636 64.9299C138.42 62.8986 143.66 63.4211 145.765 65.401C147.87 67.3808 148.414 72.9376 148.466 73.129Z"
        fill="#726EFF"
      />
      <path
        d="M160.242 107.493L138.986 114.993L135.851 143.513L136.322 144.021L156.532 136.477L160.713 108.001L160.242 107.493Z"
        fill="#37474F"
      />
      <path
        d="M139.457 115.501L136.322 144.021L135.851 143.513L138.986 114.993L139.457 115.501Z"
        fill="#263238"
      />
      <path
        d="M160.713 108.001L139.457 115.501L136.322 144.021L156.533 136.477L160.713 108.001Z"
        fill="#455A64"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M154.31 113.182L154.486 112.336C154.582 111.865 153.338 112.107 151.697 112.88L147.862 114.08C146.229 114.853 144.683 115.427 144.587 115.898L144.411 116.744L154.31 113.182Z"
          fill="black"
        />
      </g>
      <path
        d="M155.576 109.348C155.634 109.053 155.149 109.112 154.472 109.296L153.25 109.708C153.25 109.708 153.743 108.479 150.85 109.267C147.664 110.128 147.59 111.717 147.59 111.717C147.093 111.938 146.581 112.124 146.059 112.277C144.999 112.623 144.889 112.829 144.801 113.123L144.646 113.661L155.48 109.848C155.48 109.848 155.509 109.664 155.576 109.348Z"
        fill="#E0E0E0"
      />
      <path
        d="M154.435 110.974C153.434 111.43 153.014 111.651 152.867 112.372C152.777 112.758 152.708 113.149 152.661 113.543L147.016 115.751C147.016 115.751 147.318 114.536 147.399 114.131C147.48 113.727 146.722 113.727 145.75 114.176L145.978 113.16L154.751 109.899L154.435 110.974Z"
        fill="#EBEBEB"
      />
      <path
        d="M154.604 113.197L154.773 112.351C154.876 111.879 153.625 112.122 151.991 112.895L148.157 114.08C146.515 114.853 144.97 115.427 144.874 115.898L144.698 116.744L154.604 113.197Z"
        fill="#EBEBEB"
      />
      <path
        d="M144.359 127.99C142.848 128.242 141.31 128.279 139.788 128.101C140.489 127.803 141.146 127.409 141.739 126.931C142.064 126.693 142.288 126.341 142.366 125.945C142.443 125.55 142.369 125.14 142.158 124.796C141.938 124.524 141.305 125.105 139.95 125.429C138.199 125.834 137.087 125.54 135.365 126.437C134.647 126.748 133.862 126.872 133.084 126.798C131.781 126.835 130.014 126.305 126.614 125.267C124.235 124.495 121.91 123.568 119.652 122.493L124.804 110.106C123.987 111.762 130.081 97.1079 126.099 93.6487C121.344 93.6487 118.739 94.8116 116.641 100.332C115.169 104.129 109.024 122.044 108.781 124.767C108.538 127.49 109.767 128.579 112.461 129.764C115.155 130.949 121.433 132.451 127.181 134.004C130.023 134.833 132.935 135.4 135.88 135.696C142.666 136.226 145.757 135.66 147.222 133.128C149.504 129.094 147.936 127.438 144.359 127.99Z"
        fill="#FFBDA7"
      />
      <path
        d="M127.276 93.5162C124.097 93.3395 119.453 93.2365 116.774 99.4042C114.095 105.572 111.342 114.492 111.342 114.492C114.385 116.784 118.066 118.071 121.874 118.172L126.592 105.785C126.592 105.785 128.925 97.152 127.276 93.5162Z"
        fill="#726EFF"
      />
      <path
        opacity="0.1"
        d="M127.276 93.5162C124.097 93.3395 119.453 93.2365 116.774 99.4042C114.095 105.572 111.342 114.492 111.342 114.492C114.385 116.784 118.066 118.071 121.874 118.172L126.592 105.785C126.592 105.785 128.925 97.152 127.276 93.5162Z"
        fill="black"
      />
      <path
        d="M60.0723 76.5881C64.0099 76.6985 65.1212 78.2736 67.4985 81.6592C69.8758 85.0448 77.935 97.8512 77.935 97.8512L89.3577 102.267L87.8857 108.935C87.8857 108.935 78.2147 107.007 73.2687 105.991C72.6232 105.903 72.0069 105.666 71.4681 105.299C70.9293 104.933 70.4826 104.447 70.1628 103.879C69.5299 102.915 63.1782 92.92 63.1782 92.92L60.0723 76.5881Z"
        fill="#E6E6E6"
      />
      <path
        d="M57.0621 76.691C60.7421 75.7637 62.4202 77.8392 63.6861 80.1134C65.666 83.7493 66.8583 91.1534 67.0276 104.747C67.1306 113.476 70.1703 124.023 70.7076 132.303C71.5245 144.742 71.9146 154.427 71.9146 154.427C64.5511 153.46 57.1286 153.012 49.7021 153.088L57.0621 76.691Z"
        fill="#E0E0E0"
      />
      <path
        d="M35.6224 203.261C35.6224 203.261 33.4144 187.989 34.3197 179.032C35.122 171.348 36.248 168.132 36.41 166.387C36.41 166.387 37.7053 148.355 37.352 133.083C37.1828 125.856 37.536 117.856 42.688 109.318L42.872 108.979C43.3284 111.776 47.6413 113.594 51.4906 114.021C55.1706 114.433 59.8221 113.69 65.3348 111.813C66.5639 119.055 67.9623 132.855 69.3901 147.877C70.1261 155.237 69.9642 161.419 70.1261 166.152C70.288 172.563 69.7066 197.697 69.7066 197.697V198.757C69.7066 199.927 70.0304 200.744 70.8474 202.599C71.878 204.992 73.3092 207.192 75.0794 209.105C75.6314 209.664 79.4292 212.785 79.9444 213.447C81.593 215.243 78.3399 216.391 75.889 216.148C73.2983 215.891 69.265 215.044 68.08 213.514C67.2925 212.498 66.608 208.855 65.7911 207.817C63.0532 204.336 61.9197 202.834 61.8682 201.693C61.7652 199.633 62.4423 198.794 62.6631 197.623C62.3687 195.195 57.4375 182.499 57.1136 175.418C57.1139 172.286 57.313 169.156 57.7098 166.049L53.3674 141.459C52.4106 148.385 50.5853 163.981 49.9229 168.949C49.0103 175.786 45.632 187.349 43.505 196.438C42.8547 198.785 42.3896 201.18 42.114 203.6L42.0624 204.395C42.0624 205.403 41.9594 216.171 41.6356 218.452C40.9584 223.214 36.7338 222.758 35.9316 218.945C35.2471 215.707 35.6298 205.594 35.6077 204.358L35.6224 203.261Z"
        fill="#F28F8F"
      />
      <path
        d="M35.4825 203.195C34.2975 203.254 35.2323 207.824 35.0188 210.79C34.798 213.977 33.8339 216.325 33.6204 218.931C33.2932 221.212 33.7196 223.538 34.8348 225.555C35.7254 227.424 39.9868 229.89 41.4588 227.122C42.8178 224.39 43.3304 221.315 42.9308 218.29C42.6364 215.243 42.3788 213.013 42.239 210.687C42.1212 208.641 43.5343 203.776 41.9887 203.533L41.9519 204.336C41.4367 205.454 36.1891 206.396 35.4972 204.291L35.4825 203.195Z"
        fill="#37474F"
      />
      <path
        d="M61.5515 204.166C61.2351 205.638 61.7503 209.708 61.7503 209.708C61.7503 209.708 62.4347 211.085 64.7531 211.916L65.5922 206.227L61.5515 204.166Z"
        fill="#263238"
      />
      <path
        d="M64.7532 211.946L69.7359 210.121L70.8031 205.609L65.2389 208.663L64.7532 211.946Z"
        fill="black"
      />
      <path
        d="M69.5594 198.72V197.66C70.2513 197.977 71.9441 203.438 74.1079 207.147C76.0804 210.54 79.8266 212.343 81.0778 213.771C82.7412 215.729 79.7162 218.423 74.6893 217.517C72.1943 217.076 68.3892 215.611 67.3293 213.97C66.2695 212.329 65.8132 209.274 64.9373 208.082C64.0615 206.89 62.0375 205.528 61.5738 204.144C61.2049 202.888 61.1644 201.557 61.4561 200.28C61.8093 198.992 62.0964 197.439 62.538 197.564L62.5969 198.529C62.9575 199.206 64.1866 199.956 66.7111 200.001C67.8887 200.052 69.0516 199.831 69.5594 198.72Z"
        fill="#37474F"
      />
      <path
        d="M56.4512 178.163C56.1598 174.289 56.3005 170.393 56.8707 166.549L53.2349 142.335C53.2349 142.335 50.784 165.387 50.3424 168.956C49.9008 172.526 43.2842 211.07 43.2842 211.07C39.8912 213.087 35.5268 212.446 32.6858 210.938C32.6858 195.349 33.1716 179.739 33.7383 175.418C34.2363 172.468 34.9122 169.551 35.7623 166.682C35.7623 166.682 36.1229 143.947 36.1008 135.314C36.0788 126.68 36.7338 120.91 38.3751 117.031C39.6525 114.21 41.1667 111.501 42.9015 108.935C42.9015 108.935 44.16 113.189 54.1549 113.197C64.1498 113.204 65.2538 110.68 65.2538 110.68C65.2538 110.68 68.1978 120.895 69.1546 136.337C69.9421 148.782 70.1261 154.442 70.3616 159.992C70.5603 164.717 70.6855 170.178 70.7959 179.724C70.8768 186.929 71.8925 202.775 71.8925 202.775C71.8925 202.775 67.0202 205.344 58.961 202.4C58.961 202.4 56.9517 184.383 56.4512 178.163Z"
        fill="#455A64"
      />
      <path
        d="M53.2349 142.335L55.2 127.689C58.0999 127.39 60.8045 126.089 62.847 124.009C62.847 124.009 61.7283 127.556 57.0326 129.543L54.383 142.394L56.3187 163.002L53.2349 142.335Z"
        fill="#37474F"
      />
      <path
        d="M48.576 77.7141L43.8656 78.2146C40.745 78.9506 39.3319 80.3564 39.0154 83.1237C38.4303 86.1091 38.4855 89.1846 39.1773 92.1471C40.1709 96.0405 42.8058 105.586 42.8058 105.586C42.8058 105.586 39.376 114.168 37.7568 118.496C48.8631 125.738 62.5674 122.912 67.1968 118.496C66.8141 114.352 65.3421 110.496 64.9888 106.793C64.9888 106.793 65.2317 101.023 65.688 98.5208C69.2061 94.6863 68.7572 91.021 67.16 88.0623C64.216 82.6012 59.8589 77.8024 58.4384 77.8098L48.576 77.7141Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M48.576 77.7141L43.8656 78.2146C40.745 78.9506 39.3319 80.3564 39.0154 83.1237C38.4303 86.1091 38.4855 89.1846 39.1773 92.1471C40.1709 96.0405 42.8058 105.586 42.8058 105.586C42.8058 105.586 39.376 114.168 37.7568 118.496C48.8631 125.738 62.5674 122.912 67.1968 118.496C66.8141 114.352 65.3421 110.496 64.9888 106.793C64.9888 106.793 65.2317 101.023 65.688 98.5208C69.2061 94.6863 68.7572 91.021 67.16 88.0623C64.216 82.6012 59.8589 77.8024 58.4384 77.8098L48.576 77.7141Z"
        fill="white"
      />
      <path
        d="M48.2962 77.7141C48.2962 77.7141 52.1749 87.9077 53.0581 94.8261C53.9413 101.745 50.909 122.654 50.438 135.041C50.092 144.065 49.2383 157.055 49.2383 157.055C46.4872 157.315 43.714 157.219 40.9877 156.768C36.4245 155.907 35.0114 155.163 32.4133 153.463C32.4133 153.463 34.6655 130.802 35.7695 121.992C36.8146 113.705 41.2748 105.535 41.2748 105.535L35.9167 83.3887C35.9167 83.3887 36.1816 79.6424 39.6997 78.5973C43.0485 77.6037 48.2962 77.7141 48.2962 77.7141Z"
        fill="#FAFAFA"
      />
      <path
        d="M41.4367 98.4324C41.4367 98.4324 42.1727 95.7975 43.1663 91.2932C44.1599 86.7889 44.0127 84.1687 43.6594 82.2036L37.845 90.7265L41.2748 105.557C38.9331 110.026 37.2004 114.789 36.1228 119.718V119.718C39.6335 109.796 43.4828 106.815 43.4828 106.815L41.4367 98.4324Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.1344 74.4317C47.5677 74.3139 47.0672 74.5642 46.6035 75.3443C46.1398 76.1245 45.1315 77.7952 45.1315 77.7952C45.1315 77.7952 44.6016 83.2858 45.1315 84.6474C45.5805 85.8323 50.6074 90.2704 50.6074 90.2704C50.6074 90.2704 49.2899 95.209 49.2458 96.8208C49.2016 98.4326 52.359 104.586 52.7123 107.728C52.7123 107.728 53.7059 97.8438 53.2422 94.2963C52.3357 88.5306 50.6289 82.9197 48.1712 77.6259L48.1344 74.4317Z"
        fill="#EBEBEB"
      />
      <path
        d="M36.248 136.565C35.512 135.144 35.512 134.114 34.3491 132.826C33.8476 132.297 33.4969 131.644 33.3334 130.934C33.0907 129.791 32.9967 128.621 33.0537 127.453L26.3856 127.556C26.3341 129.764 26.0323 132.958 26.4518 135.321C27.519 141.312 28.704 143.851 31.2064 144.55C35.2249 145.654 36.3069 143.962 35.0189 140.959C34.4648 139.71 34.0906 138.389 33.9075 137.036C34.3321 137.575 34.8272 138.056 35.3795 138.464C35.6586 138.69 36.013 138.803 36.3718 138.78C36.7307 138.757 37.0676 138.599 37.3152 138.339C37.4771 138.096 36.8 137.676 36.248 136.565Z"
        fill="#F28F8F"
      />
      <path
        d="M42.7542 78.016C42.7542 78.016 38.4413 77.751 35.9168 79.8118C33.3923 81.8726 31.8246 87.6502 29.9699 93.4278C28.1152 99.2054 26.4665 104.814 25.8557 108.884C25.3478 112.262 26.0102 130.169 26.0102 130.169C28.6578 130.418 31.3233 129.945 33.7235 128.8L35.5267 109.664L42.7542 90.0569C44.0789 86.1526 44.0789 81.9203 42.7542 78.016Z"
        fill="#F0F0F0"
      />
      <path
        d="M46.8316 90.241C46.8316 89.8068 46.7948 88.8868 46.7212 87.7165C46.3532 82.0052 46.1839 76.8384 48.1196 76.4263L48.2888 77.2138C46.8757 77.5156 47.3468 84.8903 47.5528 87.665C47.6264 88.8573 47.6853 89.7994 47.6632 90.2704L46.8316 90.241Z"
        fill="#726EFF"
      />
      <path
        d="M45.6835 109.333H45.632V108.766C46.2993 108.713 46.9388 108.476 47.4794 108.082C46.9042 107.706 46.3883 107.247 45.9485 106.72C45.0358 105.542 43.0486 97.152 43.6595 95.2016C44.1747 93.5677 45.7866 89.9539 47.2218 89.895C48.6496 89.895 50.5264 93.3101 51.1741 94.8851C51.9542 96.7766 50.681 105.307 49.8787 106.551C49.4866 107.124 49.0102 107.635 48.4656 108.067C49.0367 108.417 49.6945 108.601 50.3645 108.597L50.4307 109.156C49.5565 109.16 48.701 108.903 47.9725 108.42C47.3092 108.931 46.5165 109.247 45.6835 109.333V109.333ZM47.2586 90.4249C46.4122 90.4249 44.9034 93.1481 44.2115 95.3414C43.6522 97.1373 45.5658 105.255 46.4195 106.381C46.8703 106.926 47.4074 107.394 48.0093 107.765C48.5786 107.343 49.0757 106.83 49.4813 106.249C50.2173 105.093 51.439 96.8429 50.7251 95.1059C49.8198 92.9494 48.105 90.4249 47.2586 90.4249V90.4249Z"
        fill="#455A64"
      />
      <path
        d="M51.2623 108.81C51.2542 109.019 51.1681 109.216 51.021 109.364C50.8739 109.512 50.6766 109.6 50.4681 109.609C50.2597 109.618 50.0554 109.549 49.8958 109.414C49.7361 109.28 49.6328 109.091 49.6063 108.884C49.5982 108.664 49.6771 108.449 49.8258 108.287C49.9745 108.124 50.1812 108.027 50.4012 108.015C50.6205 108.007 50.8342 108.086 50.9955 108.235C51.1568 108.384 51.2527 108.591 51.2623 108.81Z"
        fill="#263238"
      />
      <path
        d="M46.4488 109.016C46.4632 109.13 46.4537 109.246 46.4209 109.357C46.3881 109.467 46.3327 109.57 46.2583 109.658C46.1838 109.745 46.0919 109.817 45.9884 109.867C45.8849 109.918 45.772 109.946 45.6569 109.951C45.5418 109.956 45.427 109.936 45.3198 109.894C45.2126 109.852 45.1153 109.788 45.034 109.707C44.9528 109.625 44.8894 109.527 44.8479 109.42C44.8064 109.312 44.7876 109.197 44.7928 109.082C44.8023 108.874 44.8894 108.677 45.0371 108.53C45.1848 108.383 45.3823 108.297 45.5904 108.289C45.7986 108.281 46.0023 108.351 46.1612 108.485C46.3202 108.62 46.4228 108.809 46.4488 109.016V109.016Z"
        fill="#263238"
      />
      <path
        d="M51.3507 98.5503L50.8796 98.5135C50.8302 98.5097 50.7841 98.4869 50.7512 98.4498C50.7182 98.4127 50.7009 98.3643 50.703 98.3148C50.8318 97.2496 50.7845 96.1704 50.5632 95.1205C49.7241 93.0892 48.0019 90.5353 47.2512 90.5647C46.5004 90.5941 44.9916 93.2879 44.3072 95.3855C44.1668 96.4521 44.2091 97.5347 44.4323 98.5871C44.4386 98.6348 44.426 98.6832 44.3971 98.7217C44.3682 98.7602 44.3253 98.7859 44.2777 98.7932L43.8067 98.8668C43.757 98.8731 43.7068 98.8597 43.6669 98.8294C43.627 98.7991 43.6005 98.7544 43.5932 98.7049C43.3397 97.5313 43.3073 96.3207 43.4976 95.1352C43.8508 94.0092 45.5068 89.7919 47.1776 89.7257C48.8483 89.6594 50.8576 93.7074 51.3065 94.7967C51.5971 95.9658 51.6669 97.1789 51.5126 98.3737C51.5097 98.4171 51.4922 98.4583 51.4627 98.4905C51.4333 98.5226 51.3937 98.5437 51.3507 98.5503V98.5503Z"
        fill="#726EFF"
      />
      <path
        d="M96.2908 91.8159L74.91 99.3599L69.022 124.803L69.5004 125.311L89.8287 117.716L96.7692 92.3238L96.2908 91.8159Z"
        fill="#726EFF"
      />
      <path
        opacity="0.35"
        d="M75.381 99.8677L69.5004 125.311L69.022 124.803L74.91 99.3599L75.381 99.8677Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M96.7693 92.3237L75.3811 99.8677L69.5005 125.311L89.8288 117.716L96.7693 92.3237Z"
        fill="black"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M86.6492 112.549C86.8479 113.049 88.4818 114.146 90.8591 113.947L91.2565 112.475L86.6492 112.549Z"
          fill="black"
        />
      </g>
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M90.3292 97.5345C90.3292 97.5345 90.4028 97.1518 90.5059 96.6808C90.6089 96.2097 89.343 96.46 87.7017 97.2328L83.8377 98.403C82.1964 99.1758 80.6361 99.7572 80.5404 100.228L80.3638 101.082L90.3292 97.5345Z"
          fill="black"
        />
      </g>
      <path
        d="M91.5952 93.678C91.6614 93.3836 91.1683 93.4425 90.4912 93.6192L89.2547 94.0387C89.2547 94.0387 89.7552 92.8022 86.8406 93.5971C83.639 94.4656 83.5654 96.0627 83.5654 96.0627C83.0658 96.2833 82.5519 96.4701 82.0272 96.622C80.96 96.968 80.8422 97.1814 80.7613 97.4758L80.5994 98.0204L91.4995 94.208C91.4995 94.208 91.5363 93.9724 91.5952 93.678Z"
        fill="#37474F"
      />
      <path
        d="M90.4544 95.312C89.4387 95.7683 88.9824 95.9891 88.872 96.7177C88.7616 97.4464 88.6659 97.8953 88.6659 97.8953L82.984 100.103C82.984 100.103 83.2931 98.8816 83.3667 98.4768C83.4403 98.072 82.6969 98.0646 81.7107 98.5209L81.9462 97.4979L90.7782 94.2153L90.4544 95.312Z"
        fill="#37474F"
      />
      <path
        d="M90.6163 97.5494C90.6163 97.5494 90.6973 97.1667 90.7929 96.6957C90.8886 96.2246 89.6374 96.4675 87.9961 97.2477L84.1321 98.4179C82.4908 99.1907 80.9305 99.7721 80.8348 100.243L80.6582 101.097L90.6163 97.5494Z"
        fill="#37474F"
      />
      <path
        d="M94.3919 101.023C94.3919 101.023 93.6559 103.82 93.4351 104.527C93.2143 105.233 89.7551 106.845 87.9814 107.066C84.6252 107.485 84.2425 108.207 85.0374 110.591C85.7734 112.799 87.9814 113.616 90.9916 113.256C91.9417 113.073 92.8264 112.641 93.5556 112.005C94.2848 111.369 94.8324 110.551 95.1426 109.635C96.1215 106.823 95.5916 103.827 95.9817 102.194C96.3718 100.56 95.2457 100.052 94.3919 101.023Z"
        fill="#F28F8F"
      />
      <path
        d="M52.6314 46.9714H53.8605C57.1926 46.9714 60.3882 48.2951 62.7443 50.6512C65.1004 53.0073 66.424 56.2029 66.424 59.535V72.4518C66.424 74.6484 65.5519 76.7552 63.9993 78.3092C62.4467 79.8632 60.3407 80.7372 58.144 80.7391H40.0679V59.535C40.0679 56.2029 41.3915 53.0073 43.7476 50.6512C46.1038 48.2951 49.2993 46.9714 52.6314 46.9714Z"
        fill="#726EFF"
      />
      <path
        opacity="0.15"
        d="M52.6314 46.9714H53.8605C57.1926 46.9714 60.3882 48.2951 62.7443 50.6512C65.1004 53.0073 66.424 56.2029 66.424 59.535V72.4518C66.424 74.6484 65.5519 76.7552 63.9993 78.3092C62.4467 79.8632 60.3407 80.7372 58.144 80.7391H40.0679V59.535C40.0679 56.2029 41.3915 53.0073 43.7476 50.6512C46.1038 48.2951 49.2993 46.9714 52.6314 46.9714Z"
        fill="black"
      />
      <path
        d="M44.8003 76.3379H52.6534V85.9059C52.6534 87.8911 51.8648 89.7949 50.4611 91.1987C49.0573 92.6024 47.1535 93.391 45.1683 93.391H44.8003V76.3379V76.3379Z"
        fill="#726EFF"
      />
      <path
        opacity="0.3"
        d="M44.8003 76.3379H52.6534V85.9059C52.6534 87.8911 51.8648 89.7949 50.4611 91.1987C49.0573 92.6024 47.1535 93.391 45.1683 93.391H44.8003V76.3379V76.3379Z"
        fill="black"
      />
      <path
        d="M62.1111 53.7279C63.8849 55.0012 64.4884 56.4511 64.3191 64.5618C64.1425 71.3919 61.9198 73.0847 60.801 73.5999C59.6823 74.1151 57.5332 73.7913 55.443 73.438V78.3029C56.1704 78.9237 56.6617 79.7763 56.834 80.717C56.9738 81.2248 55.2884 82.0197 51.13 81.453C50.2394 81.3743 49.3819 81.0772 48.6335 80.5881C47.8851 80.099 47.2688 79.4329 46.8391 78.6489V63.2959C46.8391 63.2959 46.8391 57.0693 51.3066 54.6258C52.9249 53.6754 54.7363 53.1013 56.6066 52.9458C58.4768 52.7904 60.3581 53.0577 62.1111 53.7279Z"
        fill="#F28F8F"
      />
      <path
        d="M46.8391 63.2959C46.8391 63.2959 50.9092 56.0242 56.834 55.141C62.7588 54.2578 64.0689 57.2313 64.0689 57.2313C64.0689 57.2313 63.3329 50.5116 56.834 50.6809C47.899 50.8943 46.8391 57.7391 46.8391 63.2959Z"
        fill="#726EFF"
      />
      <path
        opacity="0.4"
        d="M46.8391 63.2959C46.8391 63.2959 50.9092 56.0242 56.834 55.141C62.7588 54.2578 64.0689 57.2313 64.0689 57.2313C64.0689 57.2313 63.3329 50.5116 56.834 50.6809C47.899 50.8943 46.8391 57.7391 46.8391 63.2959Z"
        fill="black"
      />
      <path
        d="M55.5018 62.7955C55.5071 62.9085 55.4901 63.0216 55.4517 63.1281C55.4133 63.2346 55.3544 63.3325 55.2782 63.4162C55.202 63.4999 55.11 63.5678 55.0076 63.616C54.9051 63.6642 54.7942 63.6917 54.6811 63.6971C54.568 63.7024 54.455 63.6854 54.3485 63.647C54.242 63.6086 54.1441 63.5497 54.0604 63.4735C53.9766 63.3973 53.9088 63.3053 53.8606 63.2029C53.8124 63.1004 53.7848 62.9895 53.7795 62.8764C53.7705 62.7617 53.7844 62.6464 53.8203 62.5371C53.8562 62.4278 53.9135 62.3267 53.9889 62.2398C54.0642 62.1528 54.156 62.0817 54.2591 62.0305C54.3621 61.9793 54.4743 61.9491 54.5891 61.9417C54.8229 61.9357 55.0496 62.0219 55.2203 62.1816C55.3911 62.3414 55.4922 62.5618 55.5018 62.7955V62.7955Z"
        fill="black"
      />
      <path
        d="M54.8026 68.0432L57.9748 69.0589C57.918 69.269 57.8198 69.4656 57.6859 69.6372C57.5521 69.8088 57.3853 69.9519 57.1954 70.0582C57.0055 70.1644 56.7963 70.2317 56.58 70.256C56.3638 70.2803 56.1448 70.2612 55.9361 70.1997C55.5042 70.0576 55.1446 69.7532 54.9331 69.3508C54.7216 68.9484 54.6748 68.4795 54.8026 68.0432V68.0432Z"
        fill="#B16668"
      />
      <path
        d="M62.9574 60.249L61.2205 59.0419C61.2927 58.9203 61.3895 58.8151 61.5048 58.7331C61.62 58.6511 61.7512 58.594 61.8897 58.5656C62.0283 58.5372 62.1713 58.5381 62.3095 58.5681C62.4478 58.5982 62.5782 58.6568 62.6925 58.7402C62.9217 58.9097 63.0771 59.1608 63.1264 59.4416C63.1757 59.7225 63.1152 60.0115 62.9574 60.249V60.249Z"
        fill="black"
      />
      <path
        d="M52.992 60.1457L54.7363 58.9313C54.6635 58.8103 54.5662 58.7058 54.4506 58.6245C54.3351 58.5432 54.2039 58.4869 54.0653 58.4592C53.9268 58.4315 53.784 58.433 53.6461 58.4636C53.5082 58.4942 53.3781 58.5532 53.2643 58.6369C53.0354 58.8065 52.8797 59.057 52.8291 59.3374C52.7785 59.6177 52.8367 59.9069 52.992 60.1457V60.1457Z"
        fill="black"
      />
      <path
        d="M62.5599 62.6187C62.5597 62.7871 62.5101 62.9517 62.4173 63.0922C62.3245 63.2327 62.1925 63.3429 62.0377 63.4092C61.8829 63.4755 61.7121 63.4949 61.5464 63.4651C61.3806 63.4353 61.2273 63.3576 61.1053 63.2415C60.9833 63.1255 60.898 62.9762 60.8599 62.8122C60.8219 62.6481 60.8327 62.4765 60.8912 62.3186C60.9497 62.1607 61.0531 62.0234 61.1888 61.9237C61.3245 61.824 61.4864 61.7662 61.6546 61.7576C61.7706 61.7547 61.886 61.7746 61.9943 61.8163C62.1026 61.858 62.2016 61.9206 62.2857 62.0006C62.3697 62.0806 62.4372 62.1763 62.4843 62.2824C62.5313 62.3884 62.557 62.5027 62.5599 62.6187V62.6187Z"
        fill="black"
      />
      <path
        d="M58.3501 62.2214L58.4899 67.425L61.2131 66.5786L58.3501 62.2214Z"
        fill="#B16668"
      />
      <path
        d="M55.4429 73.4159C53.2349 73.1289 48.7085 71.8261 48.002 70.0229C48.2841 70.9332 48.8391 71.7347 49.5917 72.3193C50.9459 73.4821 55.4797 74.7922 55.4797 74.7922L55.4429 73.4159Z"
        fill="#B16668"
      />
      <path
        d="M58.2397 74.3359L55.4503 73.4233C55.4503 73.664 55.3551 73.8951 55.1856 74.066C55.016 74.2369 54.7858 74.334 54.545 74.3359H50.1805C49.7408 74.3349 49.3055 74.2473 48.8996 74.0782C48.4936 73.909 48.125 73.6615 47.8147 73.3499C47.5044 73.0382 47.2586 72.6685 47.0912 72.2618C46.9238 71.8552 46.8381 71.4195 46.8391 70.9797L44.057 74.3359H40.0679V79.613C40.0669 80.0421 40.1505 80.4673 40.3138 80.8641C40.4771 81.261 40.717 81.6217 41.0198 81.9259C41.3226 82.23 41.6823 82.4715 42.0784 82.6366C42.4745 82.8018 42.8992 82.8872 43.3284 82.8882H55.362C56.4068 82.8853 57.4408 82.6766 58.405 82.2741C59.3692 81.8716 60.2446 81.2831 60.9814 80.5423C61.7181 79.8015 62.3017 78.9227 62.6989 77.9563C63.096 76.9899 63.2989 75.9548 63.296 74.91V74.3359H58.2397Z"
        fill="#726EFF"
      />
      <path
        opacity="0.15"
        d="M58.2397 74.3359L55.4503 73.4233C55.4503 73.664 55.3551 73.8951 55.1856 74.066C55.016 74.2369 54.7858 74.334 54.545 74.3359H50.1805C49.7408 74.3349 49.3055 74.2473 48.8996 74.0782C48.4936 73.909 48.125 73.6615 47.8147 73.3499C47.5044 73.0382 47.2586 72.6685 47.0912 72.2618C46.9238 71.8552 46.8381 71.4195 46.8391 70.9797L44.057 74.3359H40.0679V79.613C40.0669 80.0421 40.1505 80.4673 40.3138 80.8641C40.4771 81.261 40.717 81.6217 41.0198 81.9259C41.3226 82.23 41.6823 82.4715 42.0784 82.6366C42.4745 82.8018 42.8992 82.8872 43.3284 82.8882H55.362C56.4068 82.8853 57.4408 82.6766 58.405 82.2741C59.3692 81.8716 60.2446 81.2831 60.9814 80.5423C61.7181 79.8015 62.3017 78.9227 62.6989 77.9563C63.096 76.9899 63.2989 75.9548 63.296 74.91V74.3359H58.2397Z"
        fill="black"
      />
      <path
        d="M106.933 278.208C106.845 276.081 108.553 255.083 108.553 255.083L98.3665 261.817C99.441 274.109 100.133 277.951 100.17 280.004C100.125 280.174 100.105 280.35 100.111 280.527C100.111 282.955 103.128 284.265 105.476 285.885C107.485 287.319 109.61 288.584 111.828 289.668C113.535 290.448 116.98 290.521 117.429 289.52C114.573 287.533 110.415 284.552 108.141 282.286C107.059 281.189 107.014 280.335 106.933 278.208Z"
        fill="#9E6767"
      />
      <path
        d="M122.301 292.126C122.441 292.48 122.474 292.867 122.397 293.239C122.319 293.612 122.135 293.954 121.867 294.223C119.728 295.557 117.182 296.079 114.691 295.695C111.129 295.276 109.201 294.275 107.78 293.186C106.36 292.096 104.358 290.565 102.746 290.242C101.469 290.026 100.245 289.569 99.1394 288.895C98.661 288.475 98.7272 286.598 98.7272 286.598L122.301 292.126Z"
        fill="#455A64"
      />
      <path
        d="M100.148 279.4C100.023 279.403 99.9023 279.448 99.8051 279.526C99.708 279.605 99.6395 279.713 99.6103 279.835C99.4999 280.357 99.3821 281.859 99.1466 282.705C98.7814 284.194 98.6621 285.733 98.7933 287.261C98.9921 288.218 101.436 288.887 102.989 289.432C104.806 290.151 106.509 291.131 108.045 292.339C110.275 293.844 112.867 294.725 115.552 294.893C118.297 294.893 121.389 294.062 122.102 292.899C122.978 291.463 122.286 290.632 118.216 288.91C117.48 288.586 114.735 287.128 113.8 286.598C111.305 285.163 108.832 283.169 107.684 280.63C107.581 280.313 107.373 280.04 107.095 279.857C106.843 279.757 106.566 279.734 106.301 279.79C105.626 279.879 104.968 280.06 104.343 280.328C103.798 280.571 103.018 280.843 103.033 281.483C103.048 281.706 103.1 281.925 103.187 282.131C103.239 282.263 103.276 282.433 103.187 282.514C103.099 282.595 102.908 282.514 102.805 282.411C102.405 282.043 102.038 281.642 101.708 281.211C101.516 280.988 101.298 280.788 101.06 280.615C100.82 280.491 100.586 280.353 100.361 280.203C100.096 279.974 100.162 279.68 100.148 279.4Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M100.148 279.4C100.023 279.403 99.9023 279.448 99.8051 279.526C99.708 279.605 99.6395 279.713 99.6103 279.835C99.4999 280.357 99.3821 281.859 99.1466 282.705C98.7814 284.194 98.6621 285.733 98.7933 287.261C98.9921 288.218 101.436 288.887 102.989 289.432C104.806 290.151 106.509 291.131 108.045 292.339C110.275 293.844 112.867 294.725 115.552 294.893C118.297 294.893 121.389 294.062 122.102 292.899C122.978 291.463 122.286 290.632 118.216 288.91C117.48 288.586 114.735 287.128 113.8 286.598C111.305 285.163 108.832 283.169 107.684 280.63C107.581 280.313 107.373 280.04 107.095 279.857C106.843 279.757 106.566 279.734 106.301 279.79C105.626 279.879 104.968 280.06 104.343 280.328C103.798 280.571 103.018 280.843 103.033 281.483C103.048 281.706 103.1 281.925 103.187 282.131C103.239 282.263 103.276 282.433 103.187 282.514C103.099 282.595 102.908 282.514 102.805 282.411C102.405 282.043 102.038 281.642 101.708 281.211C101.516 280.988 101.298 280.788 101.06 280.615C100.82 280.491 100.586 280.353 100.361 280.203C100.096 279.974 100.162 279.68 100.148 279.4Z"
        fill="white"
      />
      <path
        d="M108.045 292.339C107.335 291.799 106.598 291.295 105.837 290.83V290.83C105.837 290.83 110.628 293.487 112.564 292.302C113.808 291.53 113.212 290.588 113.182 290.286C113.153 289.984 116.229 288.689 117.444 288.556C117.782 288.733 118.062 288.865 118.239 288.939C122.309 290.661 123 291.493 122.125 292.928C121.389 294.091 119.181 294.996 115.626 294.996C112.909 294.794 110.294 293.878 108.045 292.339V292.339Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M108.045 292.339C107.335 291.799 106.598 291.295 105.837 290.83V290.83C105.837 290.83 110.628 293.487 112.564 292.302C113.808 291.53 113.212 290.588 113.182 290.286C113.153 289.984 116.229 288.689 117.444 288.556C117.782 288.733 118.062 288.865 118.239 288.939C122.309 290.661 123 291.493 122.125 292.928C121.389 294.091 119.181 294.996 115.626 294.996C112.909 294.794 110.294 293.878 108.045 292.339V292.339Z"
        fill="white"
      />
      <path
        d="M112.836 289.373C115.368 290.3 114.308 290.727 113.572 290.845C112.836 290.963 108.597 288.637 105.999 286.79C103.29 284.832 101.583 282.698 100.567 281.976C100.192 281.711 99.7942 281.38 99.4336 281.071C99.4998 280.563 99.5514 280.099 99.6029 279.857C99.6309 279.735 99.699 279.625 99.7965 279.547C99.8939 279.468 100.015 279.424 100.14 279.422C100.14 279.724 100.096 279.996 100.339 280.225C100.564 280.375 100.798 280.512 101.038 280.637C101.276 280.81 101.493 281.01 101.686 281.233C102.016 281.664 102.383 282.065 102.782 282.433C104.998 284.972 110.304 288.453 112.836 289.373Z"
        fill="#726EFF"
      />
      <path
        d="M113.396 290.485C113.34 290.482 113.287 290.464 113.241 290.433C113.215 290.416 113.194 290.393 113.177 290.367C113.161 290.34 113.151 290.31 113.148 290.28C113.144 290.249 113.147 290.218 113.157 290.188C113.167 290.159 113.183 290.132 113.204 290.109C113.827 289.536 114.558 289.093 115.354 288.806C116.15 288.519 116.996 288.394 117.841 288.438C117.871 288.442 117.9 288.451 117.926 288.466C117.953 288.48 117.976 288.5 117.995 288.524C118.014 288.547 118.028 288.574 118.036 288.603C118.044 288.632 118.047 288.662 118.044 288.692C118.04 288.722 118.031 288.751 118.016 288.778C118.002 288.804 117.982 288.827 117.958 288.846C117.935 288.865 117.908 288.879 117.879 288.887C117.85 288.896 117.82 288.898 117.79 288.895C116.253 288.818 114.747 289.347 113.594 290.367C113.572 290.4 113.543 290.428 113.509 290.448C113.474 290.469 113.436 290.481 113.396 290.485V290.485Z"
        fill="#FAFAFA"
      />
      <path
        d="M111.188 289.248C111.132 289.249 111.077 289.231 111.033 289.197C111.007 289.18 110.986 289.158 110.97 289.132C110.954 289.106 110.943 289.077 110.94 289.047C110.936 289.017 110.94 288.986 110.949 288.957C110.959 288.928 110.975 288.902 110.996 288.88C111.618 288.305 112.349 287.861 113.145 287.574C113.942 287.287 114.788 287.163 115.633 287.209C115.664 287.21 115.695 287.217 115.724 287.231C115.752 287.244 115.777 287.263 115.798 287.286C115.819 287.31 115.834 287.337 115.844 287.367C115.854 287.397 115.857 287.428 115.854 287.46C115.85 287.491 115.84 287.522 115.825 287.55C115.809 287.578 115.787 287.602 115.762 287.621C115.737 287.64 115.707 287.654 115.676 287.662C115.645 287.67 115.613 287.671 115.582 287.666C114.045 287.589 112.539 288.118 111.386 289.138C111.363 289.169 111.333 289.194 111.298 289.213C111.264 289.232 111.227 289.244 111.188 289.248V289.248Z"
        fill="#FAFAFA"
      />
      <path
        d="M108.98 288.07C108.922 288.068 108.866 288.05 108.818 288.019C108.795 287.998 108.778 287.973 108.766 287.945C108.753 287.917 108.747 287.887 108.747 287.857C108.747 287.827 108.753 287.797 108.766 287.769C108.778 287.741 108.795 287.716 108.818 287.695C109.44 287.122 110.171 286.679 110.967 286.393C111.764 286.108 112.61 285.985 113.455 286.032C113.486 286.033 113.517 286.04 113.545 286.053C113.573 286.066 113.599 286.085 113.619 286.109C113.64 286.132 113.656 286.16 113.665 286.19C113.675 286.219 113.678 286.251 113.675 286.282C113.671 286.313 113.66 286.343 113.644 286.37C113.628 286.397 113.606 286.42 113.581 286.439C113.556 286.457 113.527 286.471 113.496 286.478C113.466 286.485 113.434 286.486 113.403 286.481C111.864 286.402 110.355 286.931 109.2 287.953C109.178 287.991 109.146 288.023 109.107 288.043C109.068 288.064 109.024 288.074 108.98 288.07V288.07Z"
        fill="#FAFAFA"
      />
      <path
        d="M106.933 286.731C106.876 286.728 106.82 286.711 106.772 286.679C106.75 286.658 106.733 286.633 106.722 286.605C106.71 286.577 106.704 286.548 106.704 286.518C106.704 286.487 106.71 286.458 106.722 286.43C106.733 286.402 106.75 286.377 106.772 286.356C107.395 285.782 108.128 285.339 108.925 285.052C109.723 284.765 110.569 284.64 111.416 284.685C111.446 284.688 111.475 284.698 111.501 284.712C111.527 284.727 111.551 284.746 111.569 284.77C111.588 284.794 111.602 284.821 111.611 284.85C111.619 284.879 111.621 284.909 111.618 284.939C111.615 284.969 111.605 284.998 111.591 285.024C111.576 285.051 111.557 285.074 111.533 285.093C111.509 285.111 111.482 285.125 111.453 285.134C111.424 285.142 111.394 285.145 111.364 285.141C109.825 285.063 108.316 285.591 107.162 286.613C107.139 286.653 107.105 286.685 107.065 286.706C107.024 286.727 106.979 286.735 106.933 286.731V286.731Z"
        fill="#FAFAFA"
      />
      <path
        d="M104.924 285.384C104.87 285.384 104.816 285.369 104.77 285.34C104.746 285.32 104.728 285.295 104.715 285.267C104.702 285.239 104.695 285.209 104.695 285.178C104.695 285.147 104.702 285.117 104.715 285.089C104.728 285.061 104.746 285.036 104.77 285.016C105.749 283.883 107.971 283.139 109.833 283.323C109.865 283.325 109.896 283.333 109.924 283.347C109.953 283.361 109.978 283.381 109.999 283.405C110.019 283.429 110.035 283.457 110.044 283.487C110.054 283.518 110.057 283.549 110.054 283.581C110.043 283.643 110.009 283.698 109.958 283.735C109.907 283.772 109.844 283.788 109.782 283.78C108.104 283.618 106.028 284.295 105.16 285.303C105.129 285.333 105.092 285.356 105.051 285.371C105.01 285.385 104.967 285.389 104.924 285.384V285.384Z"
        fill="#FAFAFA"
      />
      <path
        d="M87.253 262.553L76.949 262.369C77.1035 269.244 78.1928 276.883 78.3768 283.338C78.3768 284.56 78.3768 292.788 78.8037 295.283C79.4882 299.044 83.9557 298.536 84.1839 296.284C84.412 294.032 83.8968 284.435 83.9115 283.441C83.9115 279.305 85.8178 272.313 86.8555 264.879C86.8482 264.511 87.2015 262.907 87.253 262.553Z"
        fill="#9E6767"
      />
      <path
        d="M86.3108 297.852C86.4138 300.06 86.193 301.532 85.4791 302.916C84.7652 304.299 83.1902 304.829 81.5121 304.601C79.834 304.373 77.6186 303.563 76.9415 301.709C76.3311 300.039 76.1317 298.246 76.3601 296.483L86.3108 297.852Z"
        fill="#455A64"
      />
      <path
        d="M78.016 282.551C77.3462 282.661 77.4787 285.627 77.4125 288.615C77.3463 291.817 76.4483 293.215 76.3674 295.843C76.2717 298.846 76.5882 300.156 77.9277 301.841C79.2672 303.527 83.5139 304.918 85.2215 302.172C86.6199 299.928 86.4285 297.602 85.9575 294.658C85.4918 292.173 85.165 289.664 84.9786 287.143C84.8608 284.935 84.7946 282.727 84.1763 282.551C84.1763 282.882 84.1763 283.287 84.1763 283.625C84.1763 283.964 84.2352 285.097 83.8599 285.097C83.7993 284.821 83.7648 284.54 83.7568 284.258C83.7649 284.13 83.7454 284.003 83.6997 283.883C83.654 283.764 83.5831 283.656 83.4919 283.566C83.263 283.408 82.99 283.325 82.7117 283.331C82.0346 283.272 81.3501 283.22 80.673 283.228C80.3344 283.228 79.9959 283.228 79.6573 283.279C79.3674 283.302 79.0823 283.366 78.8109 283.471C78.7343 283.499 78.6649 283.545 78.6077 283.603C78.5505 283.662 78.5068 283.732 78.4797 283.809C78.4613 283.921 78.4613 284.036 78.4797 284.148C78.5128 284.352 78.5128 284.561 78.4797 284.766C78.4797 284.854 78.4135 284.965 78.3104 284.95C78.2074 284.935 78.1927 284.854 78.1853 284.788C78.1411 284.427 78.1853 284.162 78.1338 283.743C78.1338 283.551 78.1338 283.287 78.1338 283.095C78.1338 282.904 78.016 282.742 78.016 282.551Z"
        fill="#726EFF"
      />
      <path
        opacity="0.9"
        d="M78.016 282.551C77.3462 282.661 77.4787 285.627 77.4125 288.615C77.3463 291.817 76.4483 293.215 76.3674 295.843C76.2717 298.846 76.5882 300.156 77.9277 301.841C79.2672 303.527 83.5139 304.918 85.2215 302.172C86.6199 299.928 86.4285 297.602 85.9575 294.658C85.4918 292.173 85.165 289.664 84.9786 287.143C84.8608 284.935 84.7946 282.727 84.1763 282.551C84.1763 282.882 84.1763 283.287 84.1763 283.625C84.1763 283.964 84.2352 285.097 83.8599 285.097C83.7993 284.821 83.7648 284.54 83.7568 284.258C83.7649 284.13 83.7454 284.003 83.6997 283.883C83.654 283.764 83.5831 283.656 83.4919 283.566C83.263 283.408 82.99 283.325 82.7117 283.331C82.0346 283.272 81.3501 283.22 80.673 283.228C80.3344 283.228 79.9959 283.228 79.6573 283.279C79.3674 283.302 79.0823 283.366 78.8109 283.471C78.7343 283.499 78.6649 283.545 78.6077 283.603C78.5505 283.662 78.5068 283.732 78.4797 283.809C78.4613 283.921 78.4613 284.036 78.4797 284.148C78.5128 284.352 78.5128 284.561 78.4797 284.766C78.4797 284.854 78.4135 284.965 78.3104 284.95C78.2074 284.935 78.1927 284.854 78.1853 284.788C78.1411 284.427 78.1853 284.162 78.1338 283.743C78.1338 283.551 78.1338 283.287 78.1338 283.095C78.1338 282.904 78.016 282.742 78.016 282.551Z"
        fill="white"
      />
      <path
        d="M76.4336 294.989C76.4336 295.423 76.3306 298.286 77.2359 298.721C78.1412 299.155 78.4724 296.917 78.4724 296.917C80.2361 296.428 82.1067 296.485 83.8378 297.079C83.7274 297.558 84.3751 299.022 85.1994 298.625C86.0237 298.227 86.0016 294.945 86.0016 294.945C86.4432 297.764 86.5831 300.023 85.2656 302.202C83.5581 304.947 79.3114 303.556 77.9719 301.871C76.6324 300.185 76.3159 298.875 76.4116 295.872C76.3821 295.548 76.4042 295.261 76.4336 294.989Z"
        fill="#726EFF"
      />
      <path
        opacity="0.8"
        d="M76.4336 294.989C76.4336 295.423 76.3306 298.286 77.2359 298.721C78.1412 299.155 78.4724 296.917 78.4724 296.917C80.2361 296.428 82.1067 296.485 83.8378 297.079C83.7274 297.558 84.3751 299.022 85.1994 298.625C86.0237 298.227 86.0016 294.945 86.0016 294.945C86.4432 297.764 86.5831 300.023 85.2656 302.202C83.5581 304.947 79.3114 303.556 77.9719 301.871C76.6324 300.185 76.3159 298.875 76.4116 295.872C76.3821 295.548 76.4042 295.261 76.4336 294.989Z"
        fill="white"
      />
      <path
        d="M78.752 297.087C78.8624 298.161 77.6554 297.904 77.4346 296.395C77.2138 294.886 77.7363 291.243 77.4346 288.615C77.5008 285.627 77.3683 282.661 78.0381 282.551C78.0381 282.587 78.0381 282.624 78.0381 282.661L78.1117 284.17C78.1117 284.391 78.1117 284.582 78.1485 284.817C78.3693 287.025 78.5018 291.25 78.5091 293.267C78.5155 294.544 78.5966 295.819 78.752 297.087V297.087Z"
        fill="#726EFF"
      />
      <path
        d="M83.4698 297.086C83.6204 295.819 83.6966 294.543 83.698 293.267C83.698 291.265 83.8378 287.062 84.0586 284.862C84.1459 284.456 84.178 284.04 84.1543 283.625C84.1543 283.323 84.1543 282.941 84.1543 282.624C84.1543 282.624 84.1543 282.58 84.1543 282.551C84.5959 282.698 84.7505 283.883 84.8903 285.369C84.8903 286.238 84.8903 287.312 84.7946 288.615C84.5002 291.213 85.0154 294.908 84.7946 296.417C84.5738 297.926 83.3668 298.161 83.4698 297.086Z"
        fill="#726EFF"
      />
      <path
        d="M83.9042 297.16C83.8727 297.167 83.84 297.167 83.8085 297.16C82.0974 296.627 80.2646 296.627 78.5535 297.16C78.4927 297.183 78.4257 297.182 78.3656 297.157C78.3056 297.133 78.257 297.086 78.2297 297.028C78.2182 296.999 78.213 296.968 78.2143 296.937C78.2157 296.906 78.2235 296.875 78.2374 296.847C78.2513 296.82 78.2709 296.795 78.2949 296.775C78.319 296.756 78.3469 296.741 78.3768 296.733C80.205 296.144 82.1718 296.144 83.9999 296.733C84.0292 296.742 84.0563 296.757 84.0795 296.777C84.1027 296.797 84.1215 296.822 84.1347 296.849C84.1478 296.877 84.1551 296.907 84.1559 296.938C84.1568 296.969 84.1513 296.999 84.1397 297.028C84.116 297.069 84.0816 297.103 84.0401 297.126C83.9987 297.149 83.9517 297.161 83.9042 297.16V297.16Z"
        fill="#FAFAFA"
      />
      <path
        d="M84.2206 294.783H84.1323C82.2077 294.236 80.1689 294.236 78.2443 294.783C78.1827 294.802 78.116 294.798 78.0574 294.771C77.9987 294.744 77.9525 294.695 77.9278 294.636C77.9178 294.606 77.9141 294.575 77.917 294.544C77.9198 294.513 77.9292 294.483 77.9445 294.456C77.9598 294.429 77.9806 294.405 78.0057 294.387C78.0307 294.368 78.0594 294.355 78.0898 294.349C80.1193 293.765 82.2721 293.765 84.3016 294.349C84.3316 294.357 84.3596 294.371 84.3839 294.391C84.4081 294.41 84.4281 294.435 84.4426 294.462C84.457 294.49 84.4657 294.52 84.4681 294.551C84.4704 294.582 84.4664 294.613 84.4562 294.643C84.4355 294.687 84.402 294.724 84.36 294.749C84.318 294.774 84.2694 294.786 84.2206 294.783V294.783Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.1632 292.538C78.1171 292.537 78.0719 292.524 78.032 292.501C77.9921 292.478 77.9587 292.445 77.935 292.406C77.9229 292.377 77.9165 292.346 77.9163 292.315C77.916 292.284 77.9218 292.254 77.9335 292.225C77.9451 292.196 77.9623 292.17 77.984 292.148C78.0057 292.126 78.0316 292.108 78.0602 292.096C80.0689 291.395 82.2559 291.395 84.2646 292.096C84.2946 292.104 84.3225 292.119 84.3464 292.139C84.3703 292.158 84.3895 292.183 84.4028 292.211C84.4161 292.239 84.4232 292.27 84.4235 292.301C84.4238 292.332 84.4173 292.363 84.4045 292.391C84.3758 292.448 84.3267 292.493 84.2668 292.516C84.2069 292.539 84.1405 292.539 84.0806 292.516C84.0806 292.516 80.9894 291.419 78.2736 292.516L78.1632 292.538Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.1118 290.44C78.0654 290.441 78.0196 290.429 77.9795 290.406C77.9393 290.382 77.9062 290.349 77.8836 290.308C77.865 290.279 77.8537 290.246 77.8505 290.212C77.8474 290.178 77.8525 290.143 77.8655 290.112C77.8785 290.08 77.899 290.052 77.9252 290.029C77.9514 290.007 77.9826 289.992 78.0161 289.984C80.0369 289.152 82.3005 289.128 84.3384 289.918C84.3674 289.928 84.3939 289.944 84.416 289.966C84.4381 289.987 84.4554 290.013 84.4666 290.042C84.4778 290.07 84.4826 290.101 84.4808 290.132C84.479 290.163 84.4706 290.193 84.4561 290.22C84.4262 290.278 84.3748 290.322 84.313 290.342C84.2511 290.363 84.1836 290.358 84.1249 290.33C82.2235 289.612 80.1217 289.636 78.2369 290.396C78.1991 290.42 78.1563 290.435 78.1118 290.44V290.44Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.053 288.078C78.0084 288.079 77.9644 288.067 77.9255 288.045C77.8867 288.023 77.8545 287.991 77.8322 287.953C77.8175 287.925 77.8086 287.895 77.8063 287.865C77.804 287.834 77.8081 287.803 77.8185 287.774C77.8289 287.744 77.8454 287.718 77.8667 287.695C77.8881 287.673 77.9139 287.655 77.9426 287.644C80.9454 286.304 84.1838 287.585 84.3237 287.644C84.3526 287.654 84.3791 287.67 84.4013 287.691C84.4236 287.713 84.4412 287.738 84.453 287.767C84.4647 287.795 84.4705 287.826 84.4697 287.856C84.469 287.887 84.4619 287.917 84.4488 287.945C84.4189 288.003 84.3675 288.047 84.3056 288.068C84.2438 288.088 84.1763 288.084 84.1176 288.056C84.0808 288.056 80.9528 286.805 78.1707 288.056C78.1333 288.071 78.0933 288.078 78.053 288.078V288.078Z"
        fill="#EBEBEB"
      />
      <path
        d="M82.1082 191.36C80.43 195.224 79.3385 199.317 78.8698 203.504C77.6995 218.732 77.1107 240.849 77.1107 240.849C76.9709 242.446 75.9626 246.06 75.2855 253.066C74.4832 261.339 76.7133 289.13 76.7133 289.13C76.7133 289.13 81.6666 292.074 85.5453 288.726C85.5453 288.726 90.0202 250.159 91.0653 241.931C91.9706 234.747 94.5024 219.21 94.5024 219.21L96.416 242.991C96.416 242.991 96.1437 249.453 96.3351 253.633C96.5559 258.491 98.9331 282.455 98.9331 282.455C98.9331 282.455 105.741 284.759 108.781 281.491C108.781 281.491 109.517 247.156 109.605 241.305C109.804 229.838 109.208 208.457 108.803 196.807L82.1082 191.36Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M82.1082 191.36C80.43 195.224 79.3385 199.317 78.8698 203.504C77.6995 218.732 77.1107 240.849 77.1107 240.849C76.9709 242.446 75.9626 246.06 75.2855 253.066C74.4832 261.339 76.7133 289.13 76.7133 289.13C76.7133 289.13 81.6666 292.074 85.5453 288.726C85.5453 288.726 90.0202 250.159 91.0653 241.931C91.9706 234.747 94.5024 219.21 94.5024 219.21L96.416 242.991C96.416 242.991 96.1437 249.453 96.3351 253.633C96.5559 258.491 98.9331 282.455 98.9331 282.455C98.9331 282.455 105.741 284.759 108.781 281.491C108.781 281.491 109.517 247.156 109.605 241.305C109.804 229.838 109.208 208.457 108.803 196.807L82.1082 191.36Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M94.4731 219.225L95.533 211.909C95.533 211.909 101.708 211.085 104.512 208.458C103.71 209.71 102.642 210.771 101.384 211.565C100.126 212.359 98.7091 212.866 97.2331 213.05L96.1365 219.674L96.0629 240.282L94.4731 219.225Z"
        fill="black"
      />
      <path
        d="M114.286 193.48L109.458 199.456C109.458 199.456 106.102 193.892 106.234 193.892C106.367 193.892 110.488 187.143 110.488 187.143L114.286 193.48Z"
        fill="#9E6767"
      />
      <path
        d="M98.8301 155.723C102.62 155.664 106.816 155.561 110.481 159.911C113.315 163.289 120.991 173.762 122.537 176.442C124.281 179.474 124.524 180.703 123.155 182.97C121.786 185.237 113.587 194.643 113.587 194.643C111.696 193.016 110.271 190.915 109.458 188.556L114.286 180.46L105.012 166.763L98.8301 155.723Z"
        fill="#37474F"
      />
      <path
        d="M98.624 155.554C104.512 155.053 107.088 155.885 109.34 158.269C110.915 159.94 120.564 172.835 120.564 172.835C120.564 172.835 120.159 175.381 112.991 179.201L105.123 170.016L98.624 155.554Z"
        fill="#726EFF"
      />
      <path
        opacity="0.6"
        d="M98.624 155.554C104.512 155.053 107.088 155.885 109.34 158.269C110.915 159.94 120.564 172.835 120.564 172.835C120.564 172.835 120.159 175.381 112.991 179.201L105.123 170.016L98.624 155.554Z"
        fill="white"
      />
      <path
        d="M89.402 155.826C88.0698 156.15 83.3226 156.15 80.1946 157.261C78.4944 157.865 78.1117 159.984 77.9056 162.413C77.4567 167.617 78.2663 177.376 78.2663 177.376L78.2 202.275C82.513 205.41 100.229 207.007 109.303 200.383C109.303 200.383 108.192 170.737 108.192 166.123C108.192 161.508 105.631 155.966 98.5504 155.554L89.402 155.826Z"
        fill="#726EFF"
      />
      <path
        opacity="0.75"
        d="M89.402 155.826C88.0698 156.15 83.3226 156.15 80.1946 157.261C78.4944 157.865 78.1117 159.984 77.9056 162.413C77.4567 167.617 78.2663 177.376 78.2663 177.376L78.2 202.275C82.513 205.41 100.229 207.007 109.303 200.383C109.303 200.383 108.192 170.737 108.192 166.123C108.192 161.508 105.631 155.966 98.5504 155.554L89.402 155.826Z"
        fill="white"
      />
      <path
        d="M97.9764 157.158C96.4014 158.431 92.1915 158.799 89.2695 157.622C92.0171 160.671 95.0077 163.492 98.2119 166.056C99.1377 163.148 99.0547 160.013 97.9764 157.158V157.158Z"
        fill="#455A64"
      />
      <path
        d="M90.8521 136.16C90.8506 137.804 91.3367 139.411 92.2489 140.779C93.161 142.146 94.4583 143.212 95.9765 143.842C97.4947 144.472 99.1657 144.638 100.778 144.318C102.39 143.998 103.872 143.208 105.035 142.046C106.197 140.884 106.99 139.403 107.311 137.791C107.632 136.179 107.468 134.508 106.839 132.989C106.21 131.471 105.145 130.172 103.779 129.259C102.412 128.346 100.805 127.858 99.1615 127.858C96.959 127.858 94.8465 128.733 93.2884 130.289C91.7303 131.846 90.854 133.958 90.8521 136.16V136.16Z"
        fill="black"
      />
      <path
        d="M84.802 142.048C84.802 142.048 86.1489 148.37 86.6862 149.408C87.048 150.047 87.6404 150.524 88.3422 150.74L88.2465 146.287L84.802 142.048Z"
        fill="black"
      />
      <path
        d="M86.4874 133.003C86.0175 132.947 85.5411 133 85.0946 133.157C84.6482 133.314 84.2435 133.571 83.9115 133.908C82.8001 135.041 83.4183 138.589 84.802 142.063L87.3338 142.357L86.4874 133.003Z"
        fill="black"
      />
      <path
        d="M88.0994 142.357C87.5916 142.659 86.8629 141.68 86.2373 141.025C85.6117 140.37 83.5656 139.472 82.5573 141.629C81.549 143.785 83.4552 146.714 85.0671 147.222C87.8492 148.091 88.2613 146.309 88.2613 146.309L88.4674 156.613C91.407 160.063 94.6755 163.218 98.2268 166.034C99.1762 163.635 99.1762 159.278 97.4908 156.047V153.014C98.729 153.213 99.985 153.277 101.237 153.206C103.276 152.897 104.564 151.285 105.182 149.069C106.176 145.522 106.551 142.652 105.712 135.667C104.785 128.013 95.8789 127.932 91.0728 130.957C86.2668 133.981 88.0994 142.357 88.0994 142.357Z"
        fill="#9E6767"
      />
      <path
        d="M105.565 132.252C103.887 133.614 102.79 134.342 99.5442 134.46C97.3308 134.542 95.1279 134.116 93.1042 133.216C92.9891 133.994 92.6576 134.723 92.1474 135.321C91.501 135.966 90.7261 136.467 89.8732 136.793C89.6965 138.169 89.7039 139.936 89.6156 141.319C89.6156 141.791 89.6156 144.263 88.4821 143.527C88.1128 143.21 87.7863 142.847 87.5106 142.446C87.1157 141.946 86.6905 141.472 86.2373 141.025C85.3615 140.23 86.4876 133.003 86.4876 133.003C86.1855 132.366 86.0822 131.654 86.1911 130.958C86.3001 130.262 86.6163 129.615 87.0984 129.102C88.2981 127.777 89.9762 126.158 95.4226 126.806C99.3087 127.269 103.519 128.476 106.956 126.806C107.456 128.756 106.764 131.273 105.565 132.252Z"
        fill="black"
      />
      <path
        d="M86.4949 133.562L83.4185 131.914C83.5203 131.709 83.6622 131.527 83.8358 131.379C84.0094 131.231 84.211 131.119 84.4288 131.05C84.6465 130.982 84.8759 130.957 85.1031 130.979C85.3304 131.001 85.5509 131.069 85.7516 131.178C86.1528 131.409 86.4521 131.782 86.5899 132.224C86.7277 132.666 86.6938 133.144 86.4949 133.562V133.562Z"
        fill="black"
      />
      <path
        d="M97.4906 153.014C97.4906 153.014 92.9274 152.102 91.3376 151.248C90.3944 150.754 89.6242 149.983 89.1296 149.04C89.372 149.973 89.7973 150.849 90.3808 151.616C91.5511 153.088 97.4906 154.199 97.4906 154.199V153.014Z"
        fill="#874C4C"
      />
      <path
        d="M96.6369 141.187C96.6283 141.379 96.5636 141.563 96.4509 141.718C96.3382 141.873 96.1825 141.992 96.0031 142.059C95.8236 142.127 95.6283 142.14 95.4414 142.097C95.2546 142.055 95.0843 141.958 94.9518 141.82C94.8193 141.681 94.7303 141.507 94.696 141.318C94.6617 141.13 94.6835 140.935 94.7587 140.759C94.8339 140.583 94.9592 140.432 95.1191 140.326C95.279 140.221 95.4664 140.164 95.6581 140.164C95.9229 140.172 96.1741 140.283 96.3573 140.475C96.5405 140.666 96.6409 140.922 96.6369 141.187Z"
        fill="black"
      />
      <path
        d="M95.68 137.875L93.656 138.986C93.5128 138.709 93.4826 138.387 93.5718 138.088C93.661 137.789 93.8627 137.536 94.1344 137.382C94.2674 137.309 94.4139 137.264 94.565 137.249C94.716 137.234 94.8685 137.25 95.0131 137.297C95.1577 137.343 95.2914 137.418 95.406 137.517C95.5207 137.617 95.6139 137.738 95.68 137.875V137.875Z"
        fill="black"
      />
      <path
        d="M95.8861 146.891L99.1981 148.127C99.1262 148.35 99.0101 148.556 98.8567 148.732C98.7034 148.909 98.5159 149.053 98.3056 149.155C98.0952 149.258 97.8664 149.317 97.6327 149.328C97.3991 149.34 97.1655 149.305 96.9459 149.224C96.5006 149.049 96.1409 148.708 95.9431 148.272C95.7452 147.837 95.7248 147.341 95.8861 146.891Z"
        fill="#874C4C"
      />
      <path
        d="M104.902 138.618L103.04 137.198C103.126 137.073 103.237 136.967 103.366 136.886C103.495 136.805 103.638 136.751 103.788 136.728C103.939 136.704 104.092 136.712 104.239 136.75C104.386 136.787 104.524 136.855 104.645 136.948C104.893 137.14 105.058 137.421 105.106 137.732C105.154 138.042 105.081 138.36 104.902 138.618V138.618Z"
        fill="black"
      />
      <path
        d="M104.291 141.084C104.284 141.27 104.222 141.45 104.114 141.601C104.005 141.752 103.854 141.868 103.68 141.934C103.506 142.001 103.317 142.014 103.135 141.974C102.953 141.933 102.788 141.84 102.659 141.706C102.529 141.571 102.443 141.402 102.409 141.219C102.375 141.036 102.396 140.847 102.469 140.676C102.542 140.504 102.663 140.358 102.818 140.255C102.974 140.153 103.156 140.098 103.342 140.098C103.469 140.1 103.595 140.127 103.711 140.177C103.828 140.228 103.934 140.301 104.022 140.393C104.11 140.485 104.179 140.593 104.226 140.712C104.272 140.83 104.294 140.957 104.291 141.084V141.084Z"
        fill="black"
      />
      <path
        d="M99.6177 139.774L99.9415 145.736L103.055 144.676L99.6177 139.774Z"
        fill="#874C4C"
      />
      <path
        d="M87.7238 156.481C86.5168 156.739 86.1856 161.839 86.48 168.014C86.5315 169.03 86.5977 169.839 86.5757 170.222H85.8912C85.8912 169.854 85.8912 169.059 85.7955 168.066C85.4864 163.179 85.4496 156.091 87.5766 155.767C87.8583 155.699 88.1483 155.672 88.4378 155.686V156.422L87.7238 156.481Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M87.7238 156.481C86.5168 156.739 86.1856 161.839 86.48 168.014C86.5315 169.03 86.5977 169.839 86.5757 170.222H85.8912C85.8912 169.854 85.8912 169.059 85.7955 168.066C85.4864 163.179 85.4496 156.091 87.5766 155.767C87.8583 155.699 88.1483 155.672 88.4378 155.686V156.422L87.7238 156.481Z"
        fill="black"
      />
      <path
        d="M84.9051 186.4H84.8462V185.921C85.4179 185.875 85.9656 185.671 86.4286 185.332C85.9331 185.005 85.4912 184.603 85.1185 184.14C84.3384 183.139 82.6382 175.97 83.1608 174.3C83.6024 172.901 84.9787 169.81 86.2004 169.759C87.4222 169.759 89.0267 172.703 89.5787 174.027C90.2411 175.647 89.1592 182.933 88.4747 184C88.1363 184.49 87.727 184.926 87.2603 185.296C87.7481 185.598 88.3128 185.754 88.8868 185.744L88.9457 186.223C88.1988 186.234 87.4656 186.021 86.8408 185.612C86.2807 186.048 85.6107 186.321 84.9051 186.4ZM86.252 170.244C85.516 170.244 84.2427 172.563 83.6539 174.447C83.1681 175.978 84.802 182.918 85.5233 183.846C85.8972 184.31 86.3448 184.711 86.8481 185.031C87.3309 184.667 87.7532 184.23 88.0993 183.735C88.7323 182.749 89.7774 175.698 89.1665 174.211C88.4452 172.401 86.9806 170.244 86.252 170.244Z"
        fill="#455A64"
      />
      <path
        d="M89.6742 185.958C89.6861 186.105 89.6532 186.253 89.5798 186.382C89.5065 186.51 89.3961 186.613 89.2631 186.678C89.1301 186.743 88.9806 186.766 88.8342 186.745C88.6878 186.724 88.5512 186.659 88.4423 186.558C88.3334 186.458 88.2572 186.328 88.2237 186.183C88.1902 186.039 88.2009 185.888 88.2545 185.75C88.308 185.612 88.4019 185.494 88.5239 185.41C88.6459 185.326 88.7903 185.281 88.9382 185.281C89.1236 185.28 89.3025 185.35 89.4389 185.475C89.5754 185.601 89.6594 185.773 89.6742 185.958Z"
        fill="#263238"
      />
      <path
        d="M85.5602 186.127C85.572 186.275 85.5391 186.422 85.4658 186.551C85.3925 186.679 85.2821 186.783 85.1491 186.848C85.0161 186.912 84.8666 186.936 84.7202 186.914C84.5737 186.893 84.4372 186.828 84.3283 186.728C84.2194 186.627 84.1432 186.497 84.1097 186.353C84.0762 186.208 84.0869 186.058 84.1405 185.92C84.194 185.782 84.2879 185.663 84.4099 185.579C84.5318 185.495 84.6762 185.45 84.8242 185.45C85.0096 185.449 85.1884 185.519 85.3249 185.644C85.4613 185.77 85.5454 185.942 85.5602 186.127Z"
        fill="#263238"
      />
      <path
        d="M89.7479 177.185H89.3431C89.3021 177.179 89.2646 177.159 89.2374 177.128C89.2103 177.097 89.1956 177.057 89.1959 177.015C89.3154 176.105 89.273 175.181 89.0708 174.285C88.3348 172.548 86.8628 170.362 86.2446 170.391C85.6263 170.421 84.3162 172.717 83.7495 174.513C83.633 175.423 83.6703 176.346 83.8599 177.244C83.8663 177.286 83.856 177.328 83.8313 177.363C83.8065 177.397 83.7692 177.42 83.7274 177.428L83.33 177.486C83.2881 177.493 83.2453 177.483 83.2109 177.458C83.1765 177.433 83.1532 177.396 83.146 177.354C82.9261 176.348 82.8986 175.309 83.065 174.292C83.3668 173.335 84.7873 169.729 86.2151 169.67C87.643 169.611 89.3578 173.078 89.7406 174.013C89.9921 175.008 90.052 176.042 89.9172 177.06C89.9085 177.097 89.8867 177.13 89.8557 177.153C89.8246 177.176 89.7864 177.187 89.7479 177.185V177.185Z"
        fill="#726EFF"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M89.7479 177.185H89.3431C89.3021 177.179 89.2646 177.159 89.2374 177.128C89.2103 177.097 89.1956 177.057 89.1959 177.015C89.3154 176.105 89.273 175.181 89.0708 174.285C88.3348 172.548 86.8628 170.362 86.2446 170.391C85.6263 170.421 84.3162 172.717 83.7495 174.513C83.633 175.423 83.6703 176.346 83.8599 177.244C83.8663 177.286 83.856 177.328 83.8313 177.363C83.8065 177.397 83.7692 177.42 83.7274 177.428L83.33 177.486C83.2881 177.493 83.2453 177.483 83.2109 177.458C83.1765 177.433 83.1532 177.396 83.146 177.354C82.9261 176.348 82.8986 175.309 83.065 174.292C83.3668 173.335 84.7873 169.729 86.2151 169.67C87.643 169.611 89.3578 173.078 89.7406 174.013C89.9921 175.008 90.052 176.042 89.9172 177.06C89.9085 177.097 89.8867 177.13 89.8557 177.153C89.8246 177.176 89.7864 177.187 89.7479 177.185V177.185Z"
          fill="black"
        />
      </g>
      <path
        d="M97.4907 155.002C98.58 155.149 101.907 156.798 103.511 161.773C105.116 166.748 104.983 174.543 104.983 176.721C104.983 178.9 104.784 180.534 104.784 180.534H104.004C104.004 180.534 104.115 177.759 104.144 176.368C104.255 170.244 103.872 165.019 102.672 161.869C101.2 158.1 99.5442 156.893 97.5202 155.936L97.4907 155.002Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M97.4907 155.002C98.58 155.149 101.907 156.798 103.511 161.773C105.116 166.748 104.983 174.543 104.983 176.721C104.983 178.9 104.784 180.534 104.784 180.534H104.004C104.004 180.534 104.115 177.759 104.144 176.368C104.255 170.244 103.872 165.019 102.672 161.869C101.2 158.1 99.5442 156.893 97.5202 155.936L97.4907 155.002Z"
        fill="black"
      />
      <path
        d="M104.225 183.713C105.778 183.713 107.037 182.454 107.037 180.902C107.037 179.349 105.778 178.09 104.225 178.09C102.672 178.09 101.414 179.349 101.414 180.902C101.414 182.454 102.672 183.713 104.225 183.713Z"
        fill="#455A64"
      />
      <path
        d="M104.225 182.499C105.107 182.499 105.822 181.784 105.822 180.902C105.822 180.019 105.107 179.304 104.225 179.304C103.343 179.304 102.628 180.019 102.628 180.902C102.628 181.784 103.343 182.499 104.225 182.499Z"
        fill="#37474F"
      />
      <path
        d="M88.4011 202.989V226.541C88.4121 226.624 88.4 226.708 88.3663 226.785C88.3325 226.861 88.2784 226.927 88.2097 226.975L87.1499 227.601C87.094 227.612 87.0365 227.612 86.9806 227.601C86.8742 227.597 86.7704 227.567 86.6788 227.512L59.9841 212.13C59.785 211.998 59.6193 211.822 59.5005 211.615C59.3816 211.408 59.3129 211.176 59.2996 210.938V187.386C59.2911 187.302 59.3043 187.217 59.3378 187.14C59.3713 187.063 59.424 186.995 59.491 186.944H59.5351L60.5361 186.363C60.5901 186.333 60.651 186.317 60.7127 186.318C60.8213 186.323 60.9273 186.353 61.0219 186.407L75.0059 194.503L76.6619 195.46L79.1348 196.88H79.2011L87.6945 201.782C87.7433 201.81 87.7901 201.842 87.8343 201.877C87.9725 201.997 88.0894 202.139 88.1803 202.297C88.3132 202.504 88.3893 202.743 88.4011 202.989V202.989Z"
        fill="#726EFF"
      />
      <path
        opacity="0.5"
        d="M88.2023 202.312L87.1719 202.915C87.141 202.862 87.1066 202.81 87.0688 202.761C86.9975 202.664 86.9161 202.575 86.826 202.496L86.6861 202.393L78.1927 197.498L75.6756 196.026L74.6599 195.445L59.9988 187.018C59.9339 186.973 59.8598 186.944 59.782 186.933C59.7041 186.921 59.6247 186.928 59.5498 186.952L60.5508 186.37C60.6048 186.34 60.6657 186.325 60.7274 186.326C60.8366 186.326 60.9437 186.357 61.0365 186.414L75.0205 194.51L76.6765 195.467L79.1495 196.888H79.2157L87.7092 201.789C87.758 201.818 87.8047 201.85 87.849 201.885C87.9904 202.006 88.1099 202.15 88.2023 202.312Z"
        fill="white"
      />
      <path
        d="M59.3069 187.415V210.967C59.3198 211.207 59.3892 211.441 59.5094 211.65C59.6296 211.858 59.7972 212.035 59.9987 212.167L86.767 227.623C86.8289 227.659 86.8993 227.679 86.9712 227.679C87.043 227.68 87.1136 227.661 87.1758 227.625C87.238 227.589 87.2895 227.537 87.325 227.475C87.3605 227.412 87.3788 227.341 87.3779 227.27V203.592C87.3688 203.353 87.2978 203.119 87.1718 202.915C87.0543 202.707 86.8875 202.53 86.6861 202.4L78.1926 197.498L75.6755 196.026L59.9987 187.018C59.9339 186.973 59.8597 186.944 59.7819 186.933C59.7041 186.921 59.6247 186.928 59.5498 186.951C59.4698 186.998 59.4047 187.066 59.3617 187.148C59.3188 187.23 59.2998 187.323 59.3069 187.415V187.415Z"
        fill="#726EFF"
      />
      <path
        d="M87.1719 202.915C87.141 202.862 87.1066 202.81 87.0688 202.761C87.1089 202.808 87.1434 202.86 87.1719 202.915Z"
        fill="#726EFF"
      />
      <path
        opacity="0.2"
        d="M87.113 227.608L88.1802 226.99C88.2439 226.936 88.2937 226.868 88.3257 226.791C88.3577 226.714 88.3709 226.631 88.3642 226.548V202.996C88.3551 202.756 88.2841 202.523 88.1582 202.319L87.1278 202.923C87.2514 203.127 87.3199 203.361 87.3265 203.6V227.24C87.3294 227.315 87.3109 227.39 87.2731 227.455C87.2354 227.52 87.1798 227.573 87.113 227.608V227.608Z"
        fill="black"
      />
      <path
        d="M66.0413 189.152C71.76 165.777 74.6967 158.608 81.6445 158.696C83.6759 161.118 81.5341 168.662 80.1725 172.327C80.1725 172.327 74.0269 187.341 72.8861 192.7C71.8851 197.41 70.7517 203.327 70.8253 207.375C70.8461 208.094 71.1288 208.78 71.6202 209.304C72.9229 210.717 72.9303 211.858 73.7693 213.418C74.4317 214.647 75.1603 215.103 74.9543 215.376C74.6828 215.669 74.3102 215.849 73.9115 215.879C73.5128 215.909 73.1176 215.787 72.8051 215.538C72.1947 215.094 71.6474 214.569 71.1786 213.977C71.328 215.822 71.7573 217.634 72.4519 219.35L73.9239 220.189C73.9239 220.189 73.4823 221.698 69.9715 220.69C66.0045 219.556 64.584 219.063 64.1645 212.336C63.964 209.861 63.9394 207.374 64.0909 204.895C64.6871 198.507 65.1213 192.898 66.0413 189.152Z"
        fill="#9E6767"
      />
      <path
        d="M81.8139 156.805C81.8139 156.805 76.4779 156.996 74.7999 159.565C72.8568 162.509 71.164 167.815 67.9035 179.437C64.6431 191.058 63.6495 199.493 63.4875 206.021C63.4875 206.021 67.5723 207.927 71.3407 205.756C71.3407 205.756 73.0114 195.666 74.4907 190.3C75.9701 184.935 80.7173 173.299 80.7173 173.299C80.7173 173.299 84.4562 164.864 81.8139 156.805Z"
        fill="#37474F"
      />
      <path
        d="M83.3373 156.518C79.385 156.93 75.8301 156.805 73.8723 160.47C71.6643 164.606 66.7405 179.606 66.7405 179.606C68.0654 181.067 69.6712 182.245 71.4618 183.071C73.2525 183.896 75.1912 184.353 77.1622 184.412L81.9168 171.098C81.9168 171.098 85.0522 162.251 83.3373 156.518Z"
        fill="#726EFF"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M83.3373 156.518C79.385 156.93 75.8301 156.805 73.8723 160.47C71.6643 164.606 66.7405 179.606 66.7405 179.606C68.0654 181.067 69.6712 182.245 71.4618 183.071C73.2525 183.896 75.1912 184.353 77.1622 184.412L81.9168 171.098C81.9168 171.098 85.0522 162.251 83.3373 156.518Z"
          fill="white"
        />
      </g>
      <path
        d="M88.8941 222.39C89.2106 222.588 89.3504 221.985 89.424 221.102V219.468C89.424 219.468 90.6752 220.587 90.977 216.804C91.3155 212.645 89.4535 211.887 89.4535 211.887C89.4535 211.887 89.4535 211.195 89.4535 209.826C89.4535 208.457 89.1664 208.119 88.8499 207.891L88.3789 207.655V222.103C88.3789 222.103 88.5703 222.191 88.8941 222.39Z"
        fill="#37474F"
      />
      <path
        d="M87.3632 220.336C87.3632 219.1 87.2454 218.364 86.4506 217.878C85.6557 217.392 85.1552 217.142 85.1552 217.142L84.9197 209.48C84.9197 209.48 86.2298 210.364 86.6787 210.629C87.1277 210.894 87.4147 209.326 87.3632 208.87L88.4378 209.355L88.5629 221.19L87.3632 220.336Z"
        fill="#37474F"
      />
      <path
        d="M84.7798 219.593L85.7145 220.16C86.2371 220.469 86.4505 218.879 86.2297 216.605L86.4505 211.541C86.215 209.267 86.1782 207.184 85.6557 206.875L84.7209 206.308L84.7798 219.593Z"
        fill="#37474F"
      />
      <path
        d="M222.125 277.391H196.961L196.49 262.325H171.51L171.039 277.391H145.919L145.419 283.124V293.48H170.531L170.06 298.183V308.546H197.94V298.183L197.469 293.48H222.581V283.117L222.125 277.391Z"
        fill="#726EFF"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M222.125 277.391H196.961L196.49 262.325H171.51L171.039 277.391H145.919L145.419 283.124V293.48H170.531L170.06 298.183V308.546H197.94V298.183L197.469 293.48H222.581V283.117L222.125 277.391Z"
          fill="black"
        />
      </g>
      <path
        d="M222.125 267.028H196.961L196.49 251.962H171.51L171.039 267.028H145.919L145.419 283.124H170.531L170.06 298.183H197.94L197.469 283.117H222.581L222.125 267.028Z"
        fill="#726EFF"
      />
      <path
        opacity="0.13"
        d="M197.469 283.117H222.581V293.48H197.793L197.469 283.117Z"
        fill="black"
      />
      <path
        opacity="0.13"
        d="M170.531 283.124L170.207 293.48H145.419V283.124H170.531Z"
        fill="black"
      />
    </svg>
  );
}

export default HealthProfessionalTeam;
