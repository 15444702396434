import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import { Optional } from "../types/optional";

export default function TIMIRiskComponent() {
  const [values, setValues] = useState<{
    heartRate: Optional<number>;
    age: Optional<number>;
    sysBp: Optional<number>;
  }>({
    heartRate: undefined,
    age: undefined,
    sysBp: undefined,
  });

  const calculateTIMIRiskIndex = (
    heartRate: number = 0,
    age: number = 0,
    sysBp: number = 0,
  ) => {
    try {
      const result = (heartRate * Math.pow(age / 10, 2)) / sysBp;
      if (Number.isNaN(result) || result === Infinity) {
        throw new Error("Invalid number");
      }

      return result;
    } catch (error) {
      return "";
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Heart rate/pulse"
            placeholder="Norm 60-100"
            onChange={(e) => {
              const value = e.target.value;
              const int = parseInt(value);
              setValues({
                ...values,
                heartRate: int,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Age"
            placeholder="Age in years"
            onChange={(e) => {
              const value = e.target.value;
              const int = parseInt(value);
              setValues({
                ...values,
                age: int,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Systolic BP"
            placeholder="Norm: 100-120"
            onChange={(e) => {
              const value = e.target.value;
              const int = parseInt(value);
              setValues({
                ...values,
                sysBp: int,
              });
            }}
          />
        </Grid>
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { heartRate, age, sysBp } = values;
          const valid = [heartRate, age, sysBp].every((e) =>
            Number.isFinite(e),
          );

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          return (
            <Typography>
              <b>Risk score</b>: {calculateTIMIRiskIndex(heartRate, age, sysBp)}
            </Typography>
          );
        })()}
      </Box>
    </Box>
  );
}
