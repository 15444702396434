import { FormControl, Grid } from "@mui/material";
import DictationControl from "../../../../../components/dictation/DictationControl";
import NotesInput from "../../../../../components/form/NotesInput";
import { FormBodyProps } from "../../../../../types/formBodyProps";

export default function ChiefComplaintFormBody({
  data,
  disabled,
  px,
  py,
  onChange,
  noteLabel,
  notePrompt,
}: FormBodyProps & {
  onChange: (...args: any[]) => void;
  noteLabel?: string;
  notePrompt?: string;
}) {
  return (
    <Grid
      container
      spacing={3}
      py={py ?? 2}
      px={px ?? 4}
      sx={{
        transition: (theme) =>
          theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <DictationControl
            value={data?.summary ?? ""}
            onTextChange={(e) => {
              onChange({ ...data, summary: e });
            }}
            disabled={disabled}
            required
            id="clinicalImpression-description"
            label={`Chief Complaint`}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <NotesInput
            value={data?.note}
            label={noteLabel || "HPI - HPC"}
            onChange={(notes) => {
              onChange({
                ...data,
                note: notes,
              });
            }}
            placeholderText={
              notePrompt ||
              "Please add as much information as possible, including exact words of the patient."
            }
            disabled={disabled}
            minRows={3}
            maxNotes={1}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
