import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "History",
    code: "history",
    options: [
      {
        display: "Slightly suspicious",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Moderately suspicous", code: nanoid(), result: 1 },
      { display: "Highly suspicious", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "EKG",
    code: "ekg",
    options: [
      { display: "Normal", code: nanoid(), result: 0, default: true },
      {
        display: "Non-specific repolarization disturbance",
        code: nanoid(),
        result: 1,
      },
      { display: "Significant ST deviation", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Age",
    code: "age",
    options: [
      { display: "< 45 years", code: nanoid(), result: 0, default: true },
      { display: "45-64 years", code: nanoid(), result: 1 },
      { display: "≥ 65 years", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Risk factors",
    code: "risk-factors",
    options: [
      { display: "No known factors", code: nanoid(), result: 0, default: true },
      { display: "1-2 risk factors", code: nanoid(), result: 1 },
      {
        display: "≥ 3 risk factors or history of atherosclerotic disease",
        code: nanoid(),
        result: 2,
      },
    ],
  },
  {
    title: "Initial troponin",
    code: "initial-troponin",
    options: [
      { display: "Normal limit", code: nanoid(), result: 0, default: true },
      { display: "1-3x normal limit", code: nanoid(), result: 1 },
      { display: "> 3x normal limit", code: nanoid(), result: 2 },
    ],
  },
];

export default function HEARTComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (true) {
      case value >= 7:
        return "High";
      case value >= 4:
        return "Moderate";
      default:
        return "Low";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
