import { createContext, useState, useCallback, useContext } from "react";

export const NotificationVariant = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
} as const;

interface Notification {
  id?: number;
  message: string | React.ReactNode;
  variant: (typeof NotificationVariant)[keyof typeof NotificationVariant];
}

const defaultApi = {
  notifications: [] as Notification[],
  setNotification: (notification: Notification) => null,
  clearNotification: (id: number) => null,
};

export type NotificationsContextValue = typeof defaultApi;

/**
 * Create Context
 */
export const NotificationsContext =
  createContext<NotificationsContextValue>(defaultApi);

/**
 * Custom Notifications Provider
 */
export function NotificationsProvider({ children }: any) {
  const [notifications, setNotifications] = useState<Notification[]>(
    defaultApi.notifications,
  );

  // const location = useLocation();

  // useEffect(() => {
  //   setNotifications([]);
  // }, [location]);

  // Method to push a new notification
  const setNotification = useCallback(
    (notification: Notification) => {
      const nextNotifications = [
        {
          id: Date.now(), // we use UIDs in the final ver
          ...notification,
        } as Notification,
      ];
      setNotifications(nextNotifications);
    },
    [setNotifications],
  );

  // Method to clear a notification
  const clearNotification = useCallback(
    (id: number) => {
      const nextNotifications = notifications.filter((n) => n.id !== id);
      setNotifications(nextNotifications);
    },
    [notifications, setNotifications],
  );

  // Return Provider with full API
  const api = { notifications, setNotification, clearNotification };
  return (
    <NotificationsContext.Provider value={api}>
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
