import { FormControl, Grid, TextField } from "@mui/material";
import { set } from "lodash";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { testRegExp } from "../../../../../../utils/regex";
import { updateMetaData } from "../VitalsFormHelper";
import { useMedicalConfig } from "../../../../../../slices/configuration";

const WeightInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = usePractitioner();
  const medicalConfig = useMedicalConfig();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [weight, setWeight] = useState<any>();

  useEffect(() => {
    if (value) {
      setWeight(value);
      return;
    }

    const wt = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "29463-7",
      "Body Weight",
      medicalConfig?.vitalMeasurement?.weight ?? "kg",
    );
    setWeight(wt);
  }, [encounter, patient, value, medicalConfig]);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          id="vitals-height-weight"
          key={"vital-weight-input"}
          disabled={disabled}
          value={weight?.valueQuantity?.value ?? ""}
          onChange={(e) => {
            const targetValue = e.target.value;
            const valid = testRegExp(
              "non-negative decimal number or empty string",
              targetValue,
            );
            if (!valid) return;

            const newWeight = { ...weight };
            set(newWeight, "valueQuantity.value", targetValue);
            updateMetaData(newWeight, practitionerReference);
            onChange({
              ...value,
              ...newWeight,
              ...createDefaultVital(
                encounter,
                patient,
                CodingSystems.OBSERVATION_CODE,
                "29463-7",
                "Body Weight",
                weight?.valueQuantity?.unit ??
                  medicalConfig.vitalMeasurement?.weight ??
                  "kg",
                targetValue,
              ),
            });
          }}
          label="Weight"
          inputMode="numeric"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <SelectButton
                label={
                  value?.valueQuantity?.unit ??
                  medicalConfig?.vitalMeasurement?.weight ??
                  "kg"
                }
                options={["kg", "lbs"]}
                variant="text"
                disabled={disabled}
                onSelect={(unit) => {
                  const temp = createDefaultVital(
                    encounter,
                    patient,
                    CodingSystems.OBSERVATION_CODE,
                    "29463-7",
                    "Body Weight",
                    unit,
                    value?.valueQuantity?.value,
                  );

                  updateMetaData(temp, practitionerReference);
                  onChange(temp);
                }}
                size={size}
                getOptionLabel={(value) => value}
              />
            ),
          }}
          variant="outlined"
          size={size || "medium"}
        />
      </FormControl>
    </Grid>
  );
};

export default WeightInput;
