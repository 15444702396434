import { useContext } from "react";
import { NotificationsContext } from "../components/dashboard/NotificationsProvider";

const useNotifications = () => {
  const { notifications, setNotification, clearNotification } =
    useContext(NotificationsContext);
  return { notifications, setNotification, clearNotification };
};

export default useNotifications;
