import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Confusion",
    code: "confusion",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "BUN >19 mg/dL (>7 mmol/L urea)",
    code: "bun",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Respiratory Rate ≥30",
    code: "rr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Systolic BP <90 mmHg or Diastolic BP ≤60 mmHg",
    code: "bp",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Age ≥ 65",
    code: "age",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function Curb65Component() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (true) {
      case value >= 3:
        return "Higher mortality and admission to ICU should be considered, particulary for patients with a score of 4 or 5";
      case value >= 2:
        return "Higher mortality and hospital admission and therapy needed";
      default:
        return "Outpatient therapy may be appropriate";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
