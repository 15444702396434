import { Calculate } from "@mui/icons-material";
import ABCD2TIAComponent from "../calculators/ABCD2TIAComponent";
import AlbertaCroupSeverityComponent from "../calculators/AlbertaCroupSeverityComponent";
import AlvaradoAcuteAppedicitisComponent from "../calculators/AlvaradoAcuteAppendicitisComponent";
import AnionGapComponent from "../calculators/AnionGapComponent";
import BaseExcessComponent from "../calculators/BaseExcessComponent";
import BodySurfaceAreaBasedDosingComponent from "../calculators/BodySurfaceAreaBasedDosingComponent";
import CHA2DsVAScComponent from "../calculators/CHA2DSVAScComponent";
import CardiacRiskIndexComponent from "../calculators/CardiacRiskIndexComponent";
import CentorComponent from "../calculators/CentorComponent";
import ChildPughComponent from "../calculators/ChildPughComponent";
import Curb65Component from "../calculators/Curb65Component";
import DuBoisBMIBSAComponent from "../calculators/DuBoisBMIBSAComponent";
import FluidCalculationForChildrenComponent from "../calculators/FluidCalculationsForChildrenComponent";
import ForrestUpperGIBleedingComponent from "../calculators/ForrestUpperGIBleedingComponent";
import FraminghamCHDRiskComponent from "../calculators/FraminghamCHDRiskComponent";
import GlasgowBatchfordBleedingComponent from "../calculators/GlasgowBlatchfordBleedingComponent";
import GlasgowComaComponent from "../calculators/GlasgowComaComponent";
import HASBLEDComponent from "../calculators/HASBLEDComponent";
import HEARTComponent from "../calculators/HEARTComponent";
import LightsExudativeEffusionsComponent from "../calculators/LightsExudativeEffusionsComponent";
import MELDComponent from "../calculators/MELDComponent";
import MaddreyDiscriminantComponent from "../calculators/MaddreyDiscriminantComponent";
import MonstellerBMIBSAComponent from "../calculators/MonstellerBMIBSAComponent";
import NIHStrokeComponent from "../calculators/NIHStrokeComponent";
import PERCPulmonaryEmbolismComponent from "../calculators/PercPulmonaryEmbolismComponent";
import RIFLECriteriaComponent from "../calculators/RIFLECriteriaComponent";
import RockallUGIBComponent from "../calculators/RockallUGIBComponent";
import RockallUGIBPreEndoscopyComponent from "../calculators/RockallUGIBPreEndoscopyComponent";
import SepticShockCriteriaComponent from "../calculators/SepticShockCriteriaComponent";
import SerumOsmolarityComponent from "../calculators/SerumOsmolarityComponent";
import TIMIRiskComponent from "../calculators/TIMIRiskComponent";
import WellsDVTComponent from "../calculators/WellsDVTComponent";
import WellsPEComponent from "../calculators/WellsPEComponent";
import QSOFAComponent from "../calculators/qSOFAComponent";

type TShortcutOption = {
  title: string;
  enabled: boolean;
  whenToUse?: string;
  function?: string;
  component: JSX.Element;
  icon?: JSX.Element;
};

export const shortcutOptions: { [key: string]: TShortcutOption } = {
  "centor-score": {
    title: "Centor Score",
    enabled: false,
    icon: <Calculate />,
    component: <CentorComponent />,
  },
  "cha2ds-vasc": {
    title: "CHA2Ds-VASc",
    enabled: true,
    whenToUse:
      "To risk stratify patients with atrial fibrillation by risk of stroke and to guide the decision to start anticoagulation.",
    function:
      "Estimates stroke risk for patients with atrial fibrillation and suggests need for pharmacotherapy.",
    icon: <Calculate />,
    component: <CHA2DsVAScComponent />,
  },
  "child-pugh": {
    title: "Child-Pugh",
    enabled: false,
    icon: <Calculate />,
    component: <ChildPughComponent />,
  },
  "curb-65": {
    title: "CURB-65",
    enabled: true,
    function:
      "Estimate prognosis and determine disposition in community-acquired pneumonia.",
    whenToUse:
      "To help determine if patients can be treated outpatient versus inpatient, with consideration for ICU admission.",
    icon: <Calculate />,
    component: <Curb65Component />,
  },
  "framingham-chd-risk-score": {
    title: `Framingham "Heart" CHD Risk Score`,
    enabled: true,
    function:
      "Estimates 10-year risk of myocardial infarction (MI) or coronary death in individuals without prior heart disease, intermittent claudication, or diabetes.",
    whenToUse:
      "Used to estimate 10-year risk for MI/death for patients without previous cardiac events; can be helpful in the management of hyperlipidemia and in assessing if primary prevention with aspirin would have benefit.",
    icon: <Calculate />,
    component: <FraminghamCHDRiskComponent />,
  },
  "glasgow-coma-score": {
    title: "Glasgow Coma Score",
    enabled: false,
    function:
      "For serial assessments of patients with coma from either medical or surgical causes.",
    whenToUse:
      "The GCS is commonly used in the pre-hospital and acute care setting as well as over a patient’s hospital course to evaluate for mental status assessment in both traumatic and non-traumatic presentations.",
    icon: <Calculate />,
    component: <GlasgowComaComponent />,
  },
  "has-bled": {
    title: "HAS-BLED",
    enabled: true,
    function:
      "Estimates risk of major bleeding for patients on anticoagulation to assess risk-benefit in atrial fibrillation care.",
    whenToUse:
      "In concert with CHA2DS2-VASc, used to guide the decision to start anti-coagulation in patients with atrial fibrillation.",
    icon: <Calculate />,
    component: <HASBLEDComponent />,
  },
  heart: {
    title: "HEART",
    enabled: true,
    function:
      "Estimates 6-week risk of major adverse cardiac events in patients > 21 years-old presenting with symptoms suggesting acute coronary syndrome.",
    whenToUse:
      "To guide decision in emergency department if patients can be discharged or if need additional testing or admission by stratifying patients into low, moderate, and high-risk groups.",
    icon: <Calculate />,
    component: <HEARTComponent />,
  },
  "maddrey-discriminant": {
    title: "Maddrey's Discriminant Function",
    enabled: true,
    function:
      "Estimates short term prognosis in patients with alcoholic hepatitis and benefit of steroid therapy.",
    whenToUse:
      "To evaluate severity of alcoholic hepatitis and guide decision on steroid administration.",
    icon: <Calculate />,
    component: <MaddreyDiscriminantComponent />,
  },
  "meld-score": {
    title: "MELD Score",
    enabled: false,
    icon: <Calculate />,
    component: <MELDComponent />,
  },
  "nih-stroke-score": {
    title: "NIH Stroke Score (NIHSS)",
    enabled: true,
    function:
      "Quantifies severity of stroke in acute setting based on physical findings alone.",
    whenToUse:
      "Use in acute setting to determine severity and estimates long-term prognosis after an acute stroke.",
    icon: <Calculate />,
    component: <NIHStrokeComponent />,
  },
  "perc-pulmonary-embolism": {
    title: "PERC Rule for Pulmonary Embolism",
    enabled: true,
    function:
      "Estimates risk of PE to be < 2% when all criteria are negative and pre-test probability is low (< 15%).",
    whenToUse:
      "To rule out need for additional testing for PE based on clinical criteria alone.",
    icon: <Calculate />,
    component: <PERCPulmonaryEmbolismComponent />,
  },
  qsofa: {
    title: "qSOFA (Quick SOFA Score for Sepsis)",
    enabled: true,
    function:
      "Predicts risk of mortality in hospitalized patients with suspected infection outside of the intensive care unit based on bedside findings.",
    whenToUse:
      "Use at the bedside to prompt additional monitoring, testing, and/or treatment of patients with possible severe infections (sepsis).",
    icon: <Calculate />,
    component: <QSOFAComponent />,
  },
  "cardiac-risk-index": {
    title: "Revised Cardiac Risk Index for Pre-Operative Risk",
    enabled: true,
    function:
      "Estimates 30-day risk of death, MI, or cardiac arrest after non-cardiac surgery.",
    whenToUse:
      "To help risk stratify patients prior to surgery and to allow for shared decision making, especially in those with additional risk factors.",
    icon: <Calculate />,
    component: <CardiacRiskIndexComponent />,
  },
  "rifle-criteria": {
    title: "RIFLE Criteria",
    enabled: false,
    function:
      "Classifies severity of acute kidney injury by GFR and urine output. RIFLE is an acronym of Risk, Injury, and Failure; and Loss; and End-stage kidney disease.",
    whenToUse: "To determine severity and prognosis of acute kidney injury.",
    icon: <Calculate />,
    component: <RIFLECriteriaComponent />,
  },
  "septic-shock-criteria": {
    title: "SIRS/Sepsis/Septic Shock Criteria",
    enabled: true,
    function:
      "To classify infection status in patients with suspicion of SIRS, sepsis, or septic shock.",
    whenToUse:
      "Used as a screening or triage tool to identify patients who may need to be initiated on a sepsis protocol.",
    icon: <Calculate />,
    component: <SepticShockCriteriaComponent />,
  },
  "timi-risk-scores": {
    title: "TIMI Risk Scores",
    enabled: true,
    function:
      "Estimates 30-day mortality risk in patients with acute coronary syndrome based on age, blood pressure, and heart rate.",
    whenToUse: "Use to risk stratify patients and guide initial therapy.",
    icon: <Calculate />,
    component: <TIMIRiskComponent />,
  },
  "wells-criteria-dvt": {
    title: "Well's Criteria - Deep Vein Thrombosis",
    enabled: true,
    function:
      "Estimates risk of DVT in patients, particularly in emergency and outpatient settings.",
    whenToUse:
      "Use to exclude need for lower extremity duplex ultrasound in low risk patients.",
    icon: <Calculate />,
    component: <WellsDVTComponent />,
  },
  "wells-criteria-pe": {
    title: "Well's Criteria - PE",
    enabled: true,
    function: "Estimates risk of PE in emergency department settings.",
    whenToUse:
      "To risky stratify patients and exclude need for additional testing such as a CT angiogram in low risk patients.",
    icon: <Calculate />,
    component: <WellsPEComponent />,
  },
  "lights-criteria-exudative-effusions": {
    title: "Light's Criteria for Exudative Effusions",
    enabled: true,
    function:
      "Light's Criteria are used to determine whether a pleural effusion is exudative or transudative.",
    whenToUse:
      "Light's criteria can be used to determine the type of a patient’s pleural effusion and thus its etiology.",
    icon: <Calculate />,
    component: <LightsExudativeEffusionsComponent />,
  },
  "maintenance-fluid-calculation-for-children": {
    title: "Maintenance Fluid Calculation for Children",
    enabled: false,
    icon: <Calculate />,
    component: <FluidCalculationForChildrenComponent />,
  },
  "abcd2-score-for-tia": {
    title: "ABCD² Score for TIA",
    enabled: true,
    function:
      "Estimates the risk of stroke after a suspected transient ischemic attack (TIA).",
    whenToUse:
      "The ABCD2 score can help physicians risk stratify stroke in patients presenting with a TIA.",
    icon: <Calculate />,
    component: <ABCD2TIAComponent />,
  },
  "glasgow-blatchford-bleeding-score": {
    title: "Glasgow-Blatchford Bleeding Score (GBS)",
    enabled: true,
    function:
      'Stratifies upper GI bleeding in adult patients who are "low-risk" and candidates for outpatient management.',
    whenToUse:
      "Assess if intervention is required for acute upper GI bleeding.",
    icon: <Calculate />,
    component: <GlasgowBatchfordBleedingComponent />,
  },
  "forrest-upper-gi-bleeding": {
    title: "Forrest Classification of Upper GI Bleeding",
    enabled: false,
    function:
      "Stratifies severity of upper GI bleeding according to endoscopic findings.",
    whenToUse:
      "Patients with peptic ulcers seen on endoscopy that are or have been bleeding.",
    icon: <Calculate />,
    component: <ForrestUpperGIBleedingComponent />,
  },
  "rockall-ugib-score": {
    title: "Rockall Score for UGIB",
    enabled: true,
    function: "Determines severity of GI bleeding.",
    whenToUse:
      "Patients with clinical upper GI bleeding who have undergone endoscopy.",
    icon: <Calculate />,
    component: <RockallUGIBComponent />,
  },
  "rockall-ugib-pre-endoscopy-score": {
    title: "Rockall Score for UGIB (Pre-Endoscopy)",
    enabled: true,
    function: "Determines severity of GI bleeding, prior to endoscopy.",
    whenToUse: "Patients with clinical upper GI bleeding, prior to endoscopy.",
    icon: <Calculate />,
    component: <RockallUGIBPreEndoscopyComponent />,
  },
  "alvarado-acute-appendicitis-score": {
    title: "Alvarado Score for Acute Appendicitis",
    enabled: true,
    function: "Predicts likelihood of appendicitis diagnosis.",
    whenToUse:
      "Patients with suspected acute appendicitis (typically with right lower quadrant pain).",
    icon: <Calculate />,
    component: <AlvaradoAcuteAppedicitisComponent />,
  },
  "anion-gap": {
    title: "Anion Gap",
    enabled: true,
    function: "Evaluates states of metabolic acidosis.",
    whenToUse:
      "Determining if a patient's acidosis also has an elevated anion gap.",
    icon: <Calculate />,
    component: <AnionGapComponent />,
  },
  "serum-osmolarity": {
    title: "Serum Osmolarity",
    enabled: true,
    function:
      "Calculates expected serum osmolarity, for comparison to measured osmolarity to detect unmeasured compounds in the serum.",
    whenToUse:
      "To evaluate the etiology of hyponatremia and may be used to screen for alcohol intoxication by means of the osmolal gap.",
    icon: <Calculate />,
    component: <SerumOsmolarityComponent />,
  },
  "base-excess": {
    title: "Base Excess",
    enabled: true,
    function:
      "Estimates the amount of acid required to restore a litre of blood to its normal pH at a PaCO2 of 40 mmHg.",
    whenToUse: "In assessing acid-base status.",
    icon: <Calculate />,
    component: <BaseExcessComponent />,
  },
  "alberta-croup-severity": {
    title: "Alberta Croup Severity",
    enabled: false,
    icon: <Calculate />,
    component: <AlbertaCroupSeverityComponent />,
  },
  "bmi-bsa-dubois": {
    title: "BMI and BSA (Du Bois Method)",
    enabled: true,
    function:
      "Determine body mass index and body surface area using Du Bois method.",
    whenToUse:
      "To estimate the approximate surface area if height and weight are known.",
    icon: <Calculate />,
    component: <DuBoisBMIBSAComponent />,
  },
  "bmi-bsa-monsteller": {
    title: "BMI and BSA (Mosteller Method)",
    enabled: true,
    function:
      "Determine body mass index and body surface area using Mosteller method.",
    icon: <Calculate />,
    component: <MonstellerBMIBSAComponent />,
  },
  "body-surface-area-based-dosing": {
    title: "Body Surface Area Based Dosing",
    enabled: true,
    function: "Medication dose adjustment based on body surface area (BSA).",
    whenToUse:
      "Body surface area (BSA) based dosing is a useful way to mitigate patient size variation in medication regimens.",
    icon: <Calculate />,
    component: <BodySurfaceAreaBasedDosingComponent />,
  },
};
