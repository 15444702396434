import { Code } from "../../types/code";

export const pregnancyStatus: Code[] = [
  { display: "Pregnant", code: "LA15173-0" },
  { display: "Not Pregnant", code: "LA26683-5" },
  { display: "Unknown", code: " LA4489-6" },
];
export const estimatedDueDate: Code[] = [
  { display: "0 Weeks 0 Days", code: "11779-6" },
];
export const gestationalAge: Code[] = [
  { display: "0 Weeks 0 Days", code: "11885-1" },
];
export const pregnancyHistory: Code[] = [
  { display: "No", code: "LA32-8" },
  { display: "Possible, unconfirmed", code: "LA17332-0" },
  { display: "Yes, confirmed greater than 20 weeks", code: "LA17331-2" },
  { display: "Yes, confirmed 12 to 20 weeks", code: "LA17330-4" },
  { display: "Yes, confirmed less than 12 weeks", code: "LA17329-6" },
  { display: "Yes, Weeks Unknown", code: "LA24406-3" },
];
