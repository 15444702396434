import { GridSortModel } from "@mui/x-data-grid";
import axios from "axios";
import { isUndefined } from "lodash";

const querySearchEndpoint = async (url, queryParams, config) => {
  if (queryParams) {
    Object.keys(queryParams).forEach((k) => {
      if (Array.isArray(queryParams[k])) {
        queryParams[k].forEach((p) => url.searchParams.append(k, p));
      } else {
        url.searchParams.append(k, queryParams[k]);
      }
    });
  }
  const response = await axios.get(url.toString(), config);
  if (!isUndefined(response.data)) {
    return response.data;
  }
  return [];
};

export default querySearchEndpoint;

export const queryUpdateEndpoint = async (url, queryParams, config, data) => {
  if (queryParams) {
    Object.keys(queryParams).forEach((k) => {
      if (Array.isArray(queryParams[k])) {
        queryParams[k].forEach((p) => url.searchParams.append(k, p));
      } else {
        url.searchParams.append(k, queryParams[k]);
      }
    });
  }
  const response = await axios.put(url.toString(), data, config);
  if (!isUndefined(response.data)) {
    return response.data;
  }
  return [];
};

export const contructSortQuery = (model: GridSortModel) =>
  model.map((m) => `${m.field}:${m.sort}`);
