import { lazy } from "react";

type ComponentImportType = () => Promise<{ default: React.ComponentType<any> }>;

const sessionKey = "lazyWithRetry";

const lazyWithRetry = (componentImport: ComponentImportType) => {
  return lazy(async () => {
    const hasRefreshed =
      globalThis.sessionStorage.getItem(sessionKey) || "false";

    try {
      globalThis.sessionStorage.setItem(sessionKey, "false");
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === "false") {
        globalThis.sessionStorage.setItem(sessionKey, "true");
        globalThis.location.reload();
      }

      console.error(error);
      if (hasRefreshed === "true") throw new Error("chunkLoadError");
    }
    return await componentImport();
  });
};

export default lazyWithRetry;
