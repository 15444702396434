import { FormControl, TextField } from "@mui/material";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { updateMetaData } from "../VitalsFormHelper";
import { createDefaultVital } from ".";
import CodingSystems from "../../../../../../constants/CodingSystems";
import { testRegExp } from "../../../../../../utils/regex";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";

export default function BloodGlucoseInput({
  value,
  disabled,
  size,
  encounter,
  patient,
  onChange,
}: VitalsInputProps) {
  const practitioner = usePractitioner();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value ?? "";
    const valid = testRegExp(
      "non-negative decimal number or empty string",
      targetValue,
    );
    if (!valid) return;

    const temp = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "2345-7",
      "Blood Glucose Level",
      value?.valueQuantity?.unit ?? "mg/dL",
      targetValue,
    );
    updateMetaData(temp, practitioner);
    onChange(temp);
  };

  return (
    <FormControl fullWidth>
      <TextField
        id="vitals-blood-glucose"
        value={value?.valueQuantity?.value ?? ""}
        disabled={disabled}
        label="BGL"
        inputMode="numeric"
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <SelectButton
              label={value?.valueQuantity?.unit ?? "mg/dL"}
              options={["mg/dL", "mmol/L"]}
              variant="text"
              disabled={disabled}
              onSelect={(unit) => {
                const temp = createDefaultVital(
                  encounter,
                  patient,
                  CodingSystems.OBSERVATION_CODE,
                  "2345-7",
                  "Blood Glucose Level",
                  unit,
                  value?.valueQuantity?.value,
                );
                updateMetaData(temp, practitioner);
                onChange(temp);
              }}
              size={size}
              getOptionLabel={(value) => value}
            />
          ),
        }}
        size={size}
        variant="outlined"
        type="text"
        onChange={handleChange}
      />
    </FormControl>
  );
}
