import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { ScreenWidthContext } from "../contexts/ScreenWidthContext";
import useAuth from "../hooks/useAuth";
import useOrgSelectionModal from "../hooks/useOrgSelectionModal";
import useSettings from "../hooks/useSettings";
import {
  getCurrentOrganization,
  getUserRoles,
  setUserOrganization,
} from "../slices/user";
import { useDispatch, useSelector } from "../store";
import { Organization } from "../types/organization";

const ModalCloseButton = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  return (
    <Fab
      onClick={onClick}
      size="small"
      style={{
        position: "absolute",
        top: -20,
        right: -20,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        color: theme.palette.text.primary,
      }}
    >
      <CloseIcon fontSize="small" />
    </Fab>
  );
};

function OrgSelectionModal() {
  const { settings, saveSettings } = useSettings();
  const dispatch = useDispatch();
  const { organizations, isAuthenticated, logout } = useAuth();
  const { isOpen, closeOrgSelectionModal } = useOrgSelectionModal();
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const navigate = useNavigate();
  const screenWidth = useContext(ScreenWidthContext);

  const user = useSelector((state) => state.user.profile);

  const getRoleAndOrg = () => {
    if (selectedOrg && user) {
      dispatch(getUserRoles(selectedOrg.authId));
      dispatch(getCurrentOrganization(selectedOrg.id));
    }
  };

  const saveOrganization = () => {
    getRoleAndOrg();
    const newSettings = { ...settings, selectedOrganization: selectedOrg };
    dispatch(setUserOrganization(selectedOrg));
    saveSettings(newSettings);
    setSelectedOrg(undefined);
    closeOrgSelectionModal();

    navigate(`/home`);
    window.location.reload(); // This aborts fetch requests in process and throws an error.
  };

  const canCloseModal = (): boolean => {
    if (settings?.selectedOrganization?.id && organizations?.length) {
      const org = organizations.find(
        (org: Organization) => org.id === settings.selectedOrganization.id,
      );
      return org?.id === settings.selectedOrganization.id;
    }
    return false;
  };

  return (
    <div>
      <Modal
        open={isOpen && isAuthenticated}
        aria-labelledby="organization-selection-modal"
        aria-describedby="organization-selection-modal"
        onClose={canCloseModal() ? closeOrgSelectionModal : null}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: screenWidth < 700 ? 400 : 500,
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 10,
            borderRadius: 1,
            p: 2,
          }}
        >
          {canCloseModal() ? (
            <ModalCloseButton onClick={closeOrgSelectionModal} />
          ) : null}
          <Grid
            container
            sx={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <Grid container>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Select Your Medical Facility
              </Typography>
              <Typography id="modal-modal-title">
                The selected medical facility will be the institution at which
                the patient is currently receiving care.
              </Typography>
            </Grid>
            <Divider style={{ color: "grey", width: "100%", marginTop: 15 }} />
            <Grid container>
              <Grid
                container
                item
                xs={12}
                style={{
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                <Grid item md={12} xs={12}>
                  <List id="org-list">
                    {organizations?.map((org, i) => (
                      <ListItem
                        selected={selectedOrg?.id === org.id}
                        divider={i < organizations.length - 1}
                        key={org.id}
                        role="org-list-item"
                        onClick={() => {
                          setSelectedOrg(org);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={org.branding ? org.branding.logo_url : null}
                            sx={{ cursor: "pointer" }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={
                            <Link
                              color="textPrimary"
                              sx={{ cursor: "pointer" }}
                              underline="none"
                              variant="subtitle2"
                            >
                              {org.displayName}
                            </Link>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="body2">
                              {org.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{
                color: "grey",
                width: "100%",
                marginBottom: 10,
              }}
            />
            <Grid container justifyContent="flex-end">
              <Box justifyContent="flex-end">
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={logout}>
                    Logout
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={!selectedOrg?.id}
                    onClick={() => {
                      saveOrganization();
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default OrgSelectionModal;
