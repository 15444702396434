import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../store";
import { appointmentApi } from "../api/appointmentApi";
import { formatDateForUrl } from "../utils/DateUtils";
import moment from "moment";

interface CalendarState {
  holidays: any[];
  holidaysLoaded: any;
}

const initialState: CalendarState = {
  holidays: [],
  holidaysLoaded: false,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setHolidays(state: CalendarState, action: PayloadAction<any>): void {
      state.holidays = action.payload;
      state.holidaysLoaded = true;
    },
  },
});

export const { reducer } = slice;

export const getHolidays =
  (start, end, organizationId): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const query: any = {};
      const dateStart = new Date(start);
      const dateEnd = new Date(end);
      const startMoment = moment({
        year: dateStart.getFullYear(),
        month: dateStart.getMonth(),
        day: dateStart.getDate(),
      }).startOf("year");
      const endMoment = moment({
        year: dateEnd.getFullYear(),
        month: dateEnd.getMonth(),
        day: dateEnd.getDate(),
      }).endOf("year");
      query.start = formatDateForUrl(startMoment.startOf("year").toDate());
      query.end = formatDateForUrl(endMoment.endOf("year").toDate());
      query.organization = organizationId;
      const data = await appointmentApi.findHolidays(query);

      dispatch(slice.actions.setHolidays(data));
    } catch (e) {
      console.log("Error Loading holidays", e);
      dispatch(slice.actions.setHolidays([]));
    }
  };

export default slice;
