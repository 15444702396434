import { DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";

type Props = {
  tooltip: string;
} & IconButtonProps;

const DeleteIconButton = ({ tooltip, ...props }: Props) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton {...props}>
        <DeleteOutlineOutlined color="error" />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteIconButton;
