import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { apiConfig } from "../config";
import { useOrganizationReference } from "../pages/facility/hooks/useOrganization";
import { useQueryClient } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";

export let socket: Socket = null;

const useSocketIO = () => {
  const { autoRefreshAppointments } = useFlags();
  const [isConnected, setIsConnected] = useState(!!socket?.connected);
  const organizationReference = useOrganizationReference();
  const queryClient = useQueryClient();

  function joinRoom(organization: string) {
    console.log("joining room", organization);
    socket.emit("join-room", organization);
  }

  function onConnect() {
    console.log("connected to socket", socket.id);
    setIsConnected(true);
  }

  function onDisconnect() {
    console.log("socket disconnected", socket.id);
    setIsConnected(false);
  }

  function onRefresh(data: string[]) {
    console.log("refresh event", data);
    queryClient.invalidateQueries({ queryKey: data });
  }

  useEffect(() => {
    if (autoRefreshAppointments) {
      if (!socket) {
        socket = io(apiConfig.appointmentServiceBase, {
          transports: ["websocket"],
          reconnectionAttempts: 10,
        });
      }

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("refresh", onRefresh);

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("refresh", onRefresh);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefreshAppointments]);

  useEffect(() => {
    if (organizationReference?.reference && isConnected) {
      joinRoom(organizationReference.reference);
    }
  }, [organizationReference, isConnected]);

  return {
    isConnected,
  };
};

export default useSocketIO;
