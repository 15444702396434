import { useContext } from "react";
import { OrgSelectionModalModalContext } from "../contexts/OrgSelectionModalProvider";

const useOrgSelectionModal = () => {
  const { isOpen, openOrgSelectionModal, closeOrgSelectionModal } = useContext(
    OrgSelectionModalModalContext,
  );
  return { isOpen, openOrgSelectionModal, closeOrgSelectionModal };
};

export default useOrgSelectionModal;
