import {
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import type { FC } from "react";
import SearchIcon from "../../../icons/Search";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

interface ConfigurableTableProps extends DataGridProps {
  actionButton?: JSX.Element;
  sortOptions?: any[];
  tabs?: any[];
  tableTitle?: string;
  idProperty?: string;
  allowDuplicateIds?: boolean;
  disableSearch?: boolean;
  searchString?: string;
  searchPlaceholderText?: string;
  gridHeight?: number;
  filters?;
  useCard?: boolean;
  onSearchUpdate?: (...args: unknown[]) => unknown;
}

const TabbedTable: FC<ConfigurableTableProps> = ({
  actionButton,
  sortOptions,
  tabs,
  tableTitle,
  idProperty,
  allowDuplicateIds,
  disableSearch,
  searchString,
  searchPlaceholderText,
  gridHeight,
  filters,
  onSearchUpdate,
  useCard = true,
  ...rest
}) => {
  const _getRowId = (row) => {
    const id = idProperty ? get(row, idProperty) : row.id;
    return !allowDuplicateIds ? id : `${id}-${row.index}`;
  };

  const Component = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {tableTitle && (
          <Typography color="textPrimary" variant="h5">
            {tableTitle}
          </Typography>
        )}
      </Grid>

      {tabs && tabs.length > 0 && (
        <Grid item xs={12}>
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value="all"
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          spacing={2}
        >
          {!disableSearch && (
            <Grid item xs={12} width="100%">
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => onSearchUpdate(e.target.value)}
                    value={searchString}
                    placeholder={searchPlaceholderText}
                    variant="outlined"
                  />
                </Grid>
                {filters && (
                  <Grid item xs={12} md={6}>
                    {filters}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {sortOptions?.length > 0 && (
            <Grid item xs={12} width={240}>
              <TextField
                label="Sort By"
                name="sort"
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {sortOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          )}

          {actionButton && (
            <Grid item xs={12} width={240} textAlign="end">
              {actionButton}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            width="100%"
            height={gridHeight ? Number(gridHeight) : 600}
          >
            <DataGrid
              getRowId={rest.getRowId ?? _getRowId}
              hideFooterPagination={rest.hideFooterPagination ?? true}
              {...rest}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (useCard) {
    return (
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>{Component}</CardContent>
      </Card>
    );
  }

  return Component;
};

export default TabbedTable;
