import { Code } from "../../types/code";

export const generalPhysicalAssessment = [
  //Normal
  {
    code: "2c4340c8-0918-4990-8196-35858c91d7d2",
    display: "Well developed (WD)",
    variant: "positive",
  },
  {
    code: "ac90828a-ef94-4b8a-b84d-091cbc23bcca",
    display: "Well nourished (WN)",
    variant: "positive",
  },
  {
    code: "9c9e0739-aa07-462b-a636-7df90495ce78",
    display: "No acute distress",
    variant: "positive",
  },
  //Abnormal
  {
    code: "420afba0-5213-449a-af00-a152c482e050",
    display: "Malnourished",
    variant: "negative",
  },
  {
    code: "81ab75fc-3101-47dc-bfe6-bf5f6d2c53b9",
    display: "Cachectic",
    variant: "negative",
  },
  {
    code: "3d556860-2b27-4ffa-a669-494f2e19a260",
    display: "Frail",
    variant: "negative",
  },
  {
    code: "adb79606-fafb-4e4f-863f-991ad2478fee",
    display: "Obese",
    variant: "negative",
  },
  {
    code: "c24a183f-2274-4c5b-babe-54b07ba3caaf",
    display: "Unkempt",
    variant: "negative",
  },
  {
    code: "396d8f93-414f-441e-b7e8-328474c13f76",
    display: "Diaphoretic",
    variant: "negative",
  },
  {
    code: "e259a1c9-1752-43f0-a32f-a5bdbd893645",
    display: "Anxious",
    variant: "negative",
  },
  {
    code: "c48d1c8a-9aaf-44c7-8368-e8399b418ea1",
    display: "Breathless",
    variant: "negative",
  },
  //Not examined
  {
    code: "LA6630-3",
    display: "Not examined",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Skin
export const skinPhysicalAssessment: Code[] = [
  // Positive
  { code: "LA17207-4", display: "Warm", variant: "positive" },
  {
    code: "d5cb36b5-0b6e-4893-8a2e-506db4461185",
    display: "Good turgor",
    variant: "positive",
  },
  {
    code: "3a217fe6-cf29-4433-a663-b541d94dbca3",
    display: "No rashes, lesions",
    variant: "positive",
  },
  {
    code: "244337d6-ebf2-43fe-9e2c-d29ee56ea379",
    display: "No induration, inflammation",
    variant: "positive",
  },
  {
    code: "5aec76e9-15fd-441f-8f3b-f4ab7153256d",
    display: "No skin discolouration",
    variant: "positive",
  },
  {
    code: "LA23806-5",
    display: "Capillary Nail Bed Refill less than 2 seconds",
    variant: "positive",
  },
  // Negative
  { code: "LA18218-0", display: "Decreased turgor", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA17200-9", display: "Red (erythematous)", variant: "negative" },
  { code: "LA29194-0", display: "Exanthema", variant: "negative" },
  { code: "LA19035-7", display: "Edematous", variant: "negative" },
  { code: "LA22310-9", display: "Decubitus ulcer", variant: "negative" },
  { code: "LA17203-3", display: "Jaundiced", variant: "negative" },
  { code: "LA17206-6", display: "Pallor", variant: "negative" },
  { code: "LA17197-7", display: "Clammy", variant: "negative" },
  { code: "LA15475-9", display: "Cold", variant: "negative" },
  { code: "LA17198-5", display: "Cyanotic", variant: "negative" },
  { code: "LA18217-2", display: "Diaphoretic", variant: "negative" },
  { code: "LA17199-3", display: "Dry", variant: "negative" },
  { code: "LA17201-7", display: "Flushed", variant: "negative" },
  { code: "LA17202-3", display: "Hot", variant: "negative" },
  { code: "LA17204-1", display: "Lividity", variant: "negative" },
  { code: "LA17205-8", display: "Mottled", variant: "negative" },
  { code: "LA18219-8", display: "Tenting", variant: "negative" },
  {
    code: "LA23807-3",
    display: "Capillary Nail Bed Refill 2-4 seconds",
    variant: "negative",
  },
  {
    code: "LA23808-1",
    display: "Capillary Nail Bed Refill more than 4 seconds",
    variant: "negative",
  },
  //Not examined
  {
    code: "LA6630-3",
    display: "Not examined",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const facePhysicalAssessment: Code[] = [
  //Normal
  { code: "LA6626-1", display: "Normal", variant: "positive" },
  //Abnormal
  { code: "LA7410-9", display: "Abrasion", variant: "negative" },
  { code: "LA18220-6", display: "Avulsion", variant: "negative" },
  {
    code: "LA17216-5",
    display: "Asymmetric smile or droop",
    variant: "negative",
  },
  { code: "LA17208-2", display: "Bleeding controlled", variant: "negative" },
  { code: "LA17209-0", display: "Bleeding uncontrolled", variant: "negative" },
  { code: "LA18221-4", display: "Burn, blistering", variant: "negative" },
  { code: "LA18222-2", display: "Burn, charring", variant: "negative" },
  { code: "LA18223-0", display: "Burn, redness", variant: "negative" },
  { code: "LA18224-8", display: "Burn, white/waxy", variant: "negative" },
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA17211-6", display: "Drainage", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA7452-1", display: "Laceration", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  { code: "LA17214-0", display: "Puncture/stab wound", variant: "negative" },
  { code: "LA17212-4", display: "Gunshot wound", variant: "negative" },
  { code: "LA7423-2", display: "Contusion", variant: "negative" },
  { code: "LA17229-8", display: "Crush injury", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  { code: "LA17709-9", display: "Tenderness", variant: "negative" },
  //Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Lymph Nodes/Lymphatic
export const lymphaticPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "LA6626-1",
    display: "No neck, axillary, inguinal or other lymphadenopathy (LAD)",
    variant: "positive",
  },
  //Abnormal
  { code: "LA29195-7", display: "Lymphadenopathy ", variant: "negative" },
  //Not examined
  { code: "LA6630-3", display: "Not examined ", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Head
export const headPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "33de1320-8c50-4ee8-ae76-9ff15d0a9f11",
    display: "Normocephalic atraumatic (NCAT)",
    variant: "positive",
  },
  {
    code: "8448dd74-fc8b-41eb-9d92-80a043103cea",
    display: "No deformities",
    variant: "positive",
  },
  //Abnormal
  {
    code: "LA17709-9",
    display: "Tenderness",
    variant: "negative",
  },
  { code: "LA18220-6", display: "Avulsion", variant: "negative" },
  { code: "LA7410-9", display: "Abrasion", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  {
    code: "LA17208-2",
    display: "Bleeding controlled",
    variant: "negative",
  },
  {
    code: "LA17209-0",
    display: "Bleeding uncontrolled",
    variant: "negative",
  },
  { code: "LA18221-4", display: "Burn, blistering", variant: "negative" },
  { code: "LA18222-2", display: "Burn, charring", variant: "negative" },
  { code: "LA18223-0", display: "Burn, redness", variant: "negative" },
  { code: "LA18224-8", display: "Burn, white/waxy", variant: "negative" },
  { code: "LA17210-8", display: "Decapitation", variant: "negative" },
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA17211-6", display: "Drainage", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA7452-1", display: "Laceration", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  {
    code: "LA17214-0",
    display: "Puncture/stab wound",
    variant: "negative",
  },
  { code: "LA17212-4", display: "Gunshot wound", variant: "negative" },
  { code: "LA7423-2", display: "Contusion", variant: "negative" },
  { code: "LA17229-8", display: "Crush injury", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  //Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Eyes
export const eyesPhysicalAssessment: Code[] = [
  // Normal
  {
    code: "LA18256-0",
    display:
      "Pupils equal, round, reactive to light and accommodation (PERRLA)",
    variant: "positive",
  },
  {
    code: "6f1977fa-1332-40a4-b14a-5287ad67d316",
    display: "Eyes evenly placed, non-protruding",
    variant: "positive",
  },
  {
    code: "636029a8-b7ef-43f8-a6ec-94befb291da3",
    display: "No ptosis",
    variant: "positive",
  },
  {
    code: "92203838-58fc-414d-a782-e774ee110cff",
    display: "Conjunctivae pink",
    variant: "positive",
  },
  {
    code: "945b88cf-a86d-4257-9a16-a07998553e32",
    display: "Sclerae white, anicteric",
    variant: "positive",
  },
  {
    code: "f6e40a12-c8fa-4d5d-9b89-a07c522640da",
    display: "Vision grossly intact",
    variant: "positive",
  },
  {
    code: "6d4bfc92-b170-4053-8d1e-1554bc99cc4d",
    display: "Extra ocular muscles (EOM) intact",
    variant: "positive",
  },
  { code: "LA15255-5", display: "Pupils reactive", variant: "positive" },
  // Abnormal
  {
    code: "e79e404d-f27d-4cc7-b140-797fade5fd5e",
    display: "Eyes asymmetric",
    variant: "negative",
  },
  {
    code: "34f19852-296c-4d91-a946-a9e745cbff97",
    display: "Ptosis",
    variant: "negative",
  },
  {
    code: "596fe46c-c7e0-4790-82b8-76faeaa741ba",
    display: "Enophthalmos",
    variant: "negative",
  },
  {
    code: "07969d10-b529-46f3-bcd3-07ce9007c65f",
    display: "Exophthalmos",
    variant: "negative",
  },
  {
    code: "0f0682e2-0cbe-4341-b911-87705b140f7d",
    display: "Conjunctivae pale",
    variant: "negative",
  },
  {
    code: "9f09bad3-ba3e-4732-af0c-f3ab62458315",
    display: "Conjunctivae injected",
    variant: "negative",
  },
  {
    code: "398b8733-c9d5-4c4f-bb3b-098900c4188c",
    display: "Strabismus",
    variant: "negative",
  },
  {
    code: "6484a83d-2b2f-438d-8232-26b9d7811231",
    display: "Visual acuity defect",
    variant: "negative",
  },
  {
    code: "050487c1-57e6-4872-8f3c-f4ce139e88cd",
    display: "Visual field defect",
    variant: "negative",
  },
  { code: "LA17710-7", display: "Blind", variant: "negative" },
  { code: "LA18250-3", display: "Cataract present", variant: "negative" },
  { code: "LA17282-7", display: "Clouded", variant: "negative" },
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA18251-1", display: "Dysconjugate gaze", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA18252-9", display: "Glaucoma present", variant: "negative" },
  { code: "LA17283-5", display: "Hyphema", variant: "negative" },
  { code: "LA18253-7", display: "Jaundiced sclera", variant: "negative" },
  { code: "LA14698-7", display: "Missing eye", variant: "negative" },
  { code: "LA15256-3", display: "Non-reactive pupil", variant: "negative" },
  {
    code: "LA18254-5",
    display: "Non-reactive prosthetic",
    variant: "negative",
  },
  { code: "LA18255-2", display: "Nystagmus", variant: "negative" },
  { code: "LA17284-3", display: "Open globe", variant: "negative" },
  {
    code: "LA17285-0",
    display: "Pupil irregular/teardrop",
    variant: "negative",
  },
  { code: "LA32946-8", display: "Pin point pupil", variant: "negative" },
  { code: "LA19576-0", display: "Dilated pupil", variant: "negative" },
  { code: "LA7423-2", display: "Contusion", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  {
    code: "LA17214-0",
    display: "Puncture/stab wound",
    variant: "negative",
  },
  { code: "LA17211-6", display: "Drainage", variant: "negative" },

  // Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const earsPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "e3fec4ea-3589-46e2-b009-5ec642c335ec",
    display: "No deformities",
    variant: "positive",
  },
  {
    code: "2eb937ed-34c8-4b35-b4ce-646cd24b0075",
    display: "No discharge",
    variant: "positive",
  },
  {
    code: "08da6f6e-9584-4c74-add9-7b6743e6a3b7",
    display: "External auditory canals (EAC) clear",
    variant: "positive",
  },
  {
    code: "92f6530f-422d-42f9-92ff-2de1436ce5f5",
    display: "Hearing grossly intact",
    variant: "positive",
  },

  //Abnormal
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA17214-0", display: "Puncture/stab wound", variant: "negative" },
  { code: "LA7452-1", display: "Laceration", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  { code: "LA17709-9", display: "Tenderness", variant: "negative" },
  { code: "LA17208-2", display: "Bleeding controlled", variant: "negative" },
  { code: "LA17209-0", display: "Bleeding uncontrolled", variant: "negative" },
  { code: "LA17211-6", display: "Drainage", variant: "negative" },

  //Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const nosePhysicalAssessment: Code[] = [
  //Normal
  {
    code: "859a6e05-acb4-4789-b84c-a2e8b9f822d7",
    display: "Nares and nasal septum symmetric, no deformities",
    variant: "positive",
  },
  // Negative
  {
    code: "ec6b7aba-5b03-4284-aee2-dec9fc863bb1",
    display: "No discharge, bleeding",
    variant: "positive",
  },
  //Abnormal
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA17709-9", display: "Tenderness", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  { code: "LA17208-2", display: "Bleeding controlled", variant: "negative" },
  { code: "LA17209-0", display: "Bleeding uncontrolled", variant: "negative" },
  { code: "LA17211-6", display: "Drainage", variant: "negative" },
  //Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const oralCavityPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "7dc4c2ab-3b92-4b7c-a99b-af3aa422ccba",
    display: "Moist mucous membranes",
    variant: "positive",
  },
  {
    code: "56ba845e-102f-45a4-a10b-9ff757a88767",
    display: "Mucosa pink",
    variant: "positive",
  },
  {
    code: "2cfa827c-e936-4422-8acb-d853410e51aa",
    display: "No sores or leukoplakia",
    variant: "positive",
  },
  {
    code: "6e80f679-1817-4891-b1ad-bc8e67fe3575",
    display: "Teeth present, good dental hygiene",
    variant: "positive",
  },
  {
    code: "18879f97-f421-4d5c-bb5b-7177c4e2fcad",
    display: "Tongue normal size and papillation, midline protrusion",
    variant: "positive",
  },
  {
    code: "ed9261fd-9cd2-4c4b-af87-2b00bc43970d",
    display: "Tonsils present/absent, not enlarged",
    variant: "positive",
  },
  {
    code: "c4340232-0b85-4457-922b-b82220260956",
    display: "Palate elevates symmetrically, gag intact",
    variant: "positive",
  },
  //Abnormal
  {
    code: "067d7e23-d569-4a11-89e4-9e33e1d07261",
    display: "Dry mucous membranes",
    variant: "negative",
  },
  {
    code: "cbfea43d-c757-4899-9fda-1af35589082f",
    display: "Gums or gingivia with bruising, bleeding or inflammation",
    variant: "negative",
  },
  {
    code: "baffcdae-eaaf-4394-a9f0-63f1b4d0a59d",
    display: "Edentulism",
    variant: "negative",
  },
  {
    code: "13b9e3bf-5589-4f46-9b34-ce12a54ab365",
    display: "Dental implants",
    variant: "negative",
  },
  {
    code: "8103b708-8053-404e-af72-c2da7d17b4a1",
    display: "Poor dental hygiene",
    variant: "negative",
  },
  {
    code: "a923def5-8dd2-4a83-894f-b7cd8dae5f37",
    display: "Tongue enlarged, with lesions or ulcers",
    variant: "negative",
  },
  {
    code: "2605a21b-801b-43d3-80a8-250b1d1e2619",
    display: "Tonsils reddened",
    variant: "negative",
  },
  {
    code: "f89a8bf6-5ea4-4b5b-b4f4-0a38ea4351b1",
    display: "Tonsils enlarged",
    variant: "negative",
  },
  {
    code: "315dad39-838a-41c8-8f0e-71d02c931a73",
    display: "Tonsils with white exudate",
    variant: "negative",
  },
  {
    code: "5ae6bb9b-506b-4128-8314-d4f28aef98fd",
    display: "Tonsil tenderness",
    variant: "negative",
  },
  {
    code: "92ef989d-411e-4264-84dd-c8ed7eebaa5c",
    display: "Absent gag reflex",
    variant: "negative",
  },
  {
    code: "LA18225-5",
    display: "Deformity",
    variant: "negative",
  },
  {
    code: "LA17213-2",
    display: "Mass/lesion",
    variant: "negative",
  },
  {
    code: "LA22440-4",
    display: "Swelling",
    variant: "negative",
  },
  {
    code: "LA18226-3",
    display: "Foreign body",
    variant: "negative",
  },
  {
    code: "LA17709-9",
    display: "Tenderness",
    variant: "negative",
  },
  {
    code: "LA7460-4",
    display: "Pain",
    variant: "negative",
  },
  {
    code: "LA17208-2",
    display: "Bleeding controlled",
    variant: "negative",
  },
  {
    code: "LA17209-0",
    display: "Bleeding uncontrolled",
    variant: "negative",
  },
  {
    code: "LA17211-6",
    display: "Drainage",
    variant: "negative",
  },
  {
    code: "LA7452-1",
    display: "Laceration",
    variant: "negative",
  },
  {
    code: "LA17214-0",
    display: "Puncture/stab wound",
    variant: "negative",
  },
  {
    code: "LA17212-4",
    display: "Gunshot wound",
    variant: "negative",
  },
  {
    code: "LA7423-2",
    display: "Contusion",
    variant: "negative",
  },
  {
    code: "LA17229-8",
    display: "Crush injury",
    variant: "negative",
  },
  //Not examined
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Neck
export const neckPhysicalAssessment: Code[] = [
  // Positive
  {
    code: "0f79240b-cdc7-4440-bb1a-15db4f9d1791",
    display: "Supple with full range of motion (ROM)",
    variant: "positive",
  },
  {
    code: "99a925c7-79b0-427e-968a-15ed53e44a2d",
    display: "No palpable masses",
    variant: "positive",
  },
  {
    code: "57e6b6bb-972f-4e6f-a22a-4c161edc46d7",
    display: "No rigidity or tenderness",
    variant: "positive",
  },
  {
    code: "310a4aad-4bc5-42fa-a495-d4f53616e859",
    display: "No venous distension",
    variant: "positive",
  },
  {
    code: "201adf9d-98d6-4ddf-a4e6-449b67c6cfc1",
    display: "Trachea midline",
    variant: "positive",
  },
  {
    code: "b978f785-ec14-4b19-aa72-185d6294eb78",
    display: "Thyroid: no masses, enlargement or tenderness",
    variant: "positive",
  },
  // Negative
  {
    code: "LA18228-9",
    display: "Tracheal deviation, left",
    variant: "negative",
  },
  {
    code: "LA18227-1",
    display: "Tracheal deviation, right",
    variant: "negative",
  },
  { code: "LA7410-9", display: "Abrasion", variant: "negative" },
  { code: "LA18220-6", display: "Avulsion", variant: "negative" },
  { code: "LA17208-2", display: "Bleeding controlled", variant: "negative" },
  { code: "LA17209-0", display: "Bleeding uncontrolled", variant: "negative" },
  { code: "LA18221-4", display: "Burn, blistering", variant: "negative" },
  { code: "LA18222-2", display: "Burn, charring", variant: "negative" },
  { code: "LA18223-0", display: "Burn, redness", variant: "negative" },
  { code: "LA18224-8", display: "Burn, white/waxy", variant: "negative" },
  { code: "LA17210-8", display: "Decapitation", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  {
    code: "LA17217-3",
    display: "Jugular vein distension (JVD)",
    variant: "negative",
  },
  { code: "LA7452-1", display: "Laceration", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  {
    code: "LA17256-1",
    display: "Pain with range of motion",
    variant: "negative",
  },
  { code: "LA17214-0", display: "Puncture/stab wound", variant: "negative" },
  { code: "LA17612-5", display: "Stridor", variant: "negative" },
  { code: "LA17219-9", display: "Subcutaneous air", variant: "negative" },
  { code: "LA17212-4", display: "Gunshot wound", variant: "negative" },
  { code: "LA17229-8", display: "Crush injury", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  { code: "LA7423-2", display: "Contusion", variant: "negative" },
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA17709-9", display: "Tenderness", variant: "negative" },

  // Neutral
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

// Back and Spine
export const backPhysicalAssessment: Code[] = [
  // Positive
  {
    code: "fae4f22f-9898-4c53-99fa-8949ccef3922",
    display: "No deformities or tenderness of the spine",
    variant: "positive",
  },
  {
    code: "91e708ec-ce64-44fe-8c77-d36ec61d26d1",
    display: "No costovertebral angle (CVA) tenderness",
    variant: "positive",
  },
  {
    code: "08e6ea31-14c1-4676-a655-483d66b3a2dd",
    display: "Full range of motion (ROM)",
    variant: "positive",
  },

  // Negative
  {
    code: "LA17709-9",
    display: "Tenderness on palpation",
    variant: "negative",
  },
  {
    display: "Limited range of motion (ROM)",
    code: "e4b2b9f2-0406-4109-9dc8-6e098a302e54",
    variant: "negative",
  },
  {
    display: "Muscle spasms",
    code: "282d3aa1-c6bd-4084-b98f-a0fa4111e258",
    variant: "negative",
  },
  { display: "Abrasion", code: "LA7410-9", variant: "negative" },
  { display: "Avulsion", code: "LA18220-6", variant: "negative" },
  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  { display: "Burn, blistering", code: "LA18221-4", variant: "negative" },
  { display: "Burn, charring", code: "LA18222-2", variant: "negative" },
  { display: "Burn, redness", code: "LA18223-0", variant: "negative" },
  {
    display: "Burn, white/waxy",
    code: "ab8dbb77-807d-4a3c-aaac-65a3cd7b8a7e",
    variant: "negative",
  },
  { display: "Deformity", code: "LA18224-8", variant: "negative" },
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Foreign body", code: "LA18226-3", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  {
    display: "Pain with range of motion",
    code: "LA17256-1",
    variant: "negative",
  },
  { display: "Puncture/stab wound", code: "LA17214-0", variant: "negative" },
  {
    display: "Tenderness costovertebral angle (CVA)",
    code: "LA17257-9",
    variant: "negative",
  },
  {
    display: "Tenderness midline spinous process",
    code: "LA17258-7",
    variant: "negative",
  },
  { display: "Tenderness paraspinous", code: "LA17259-5", variant: "negative" },
  { display: "Gunshot wound", code: "LA17212-4", variant: "negative" },
  { display: "Crush injury", code: "LA17229-8", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Contusion", code: "LA7423-2", variant: "negative" },

  // Neutral
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const chestPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "200c5a7a-57b0-49b4-bd2e-4f0b46f6a456",
    display: "Chest wall symmetrical",
    variant: "positive",
  },
  {
    code: "1d02c321-1471-46c5-9b7c-925757411df1",
    display: "Non-tender on palpation",
    variant: "positive",
  },
  {
    code: "aa159d63-a69d-4cc7-8b53-4595c29170ee",
    display: "No palpable masses",
    variant: "positive",
  },
  {
    code: "44be5d43-3c7a-4cb8-b24e-ef2465c303a8",
    display: "No skin dimpling",
    variant: "positive",
  },
  {
    code: "2ea6c877-6649-442f-aa54-d47f84963074",
    display: "No nipple retraction ",
    variant: "positive",
  },
  {
    code: "bd368cb8-3086-4486-8e91-b3ab1eb15880",
    display: "No nipple discharge",
    variant: "positive",
  },
  {
    code: "d64470cd-ee4a-432b-aa59-de066ac94666",
    display: "No axillary adenopathy",
    variant: "positive",
  },
  //Abnormal
  {
    code: "LA18225-5",
    display: "Deformity",
    variant: "negative",
  },
  {
    code: "LA17213-2",
    display: "Mass/lesion",
    variant: "negative",
  },
  {
    code: "LA17709-9",
    display: "Tenderness",
    variant: "negative",
  },
  {
    code: "LA7460-4",
    display: "Pain",
    variant: "negative",
  },
  {
    code: "LA18226-3",
    display: "Foreign body",
    variant: "negative",
  },
  {
    code: "LA22440-4",
    display: "Swelling",
    variant: "negative",
  },
  {
    code: "LA17211-6",
    display: "Drainage",
    variant: "negative",
  },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const respiratoryPhysicalAssessment: Code[] = [
  // Positive

  {
    display: "No use of accessory muscles",
    code: "25e2921a-99df-4208-9746-9d9ebdef7ac4",
    variant: "positive",
  },
  {
    display: "Bilateral chest expansion",
    code: "4fa0546e-4a72-4cef-a933-1fd7994bf0b9",
    variant: "positive",
  },
  {
    display: "No tactile fremitus",
    code: "119da3a5-3d1c-43b4-8c99-987d00378285",
    variant: "positive",
  },
  {
    display: "Tympanic/resonant in all zones",
    code: "d9208c09-1576-4994-b8e8-99b1f85d0c92",
    variant: "positive",
  },
  {
    display: "Bilateral clear breath sounds, no crackles/wheezes/rhonchi",
    code: "baf072a9-b877-45a4-b889-a3ea03962747",
    variant: "positive",
  },
  { display: "Breath sounds-equal", code: "LA18402-0", variant: "positive" },
  {
    display: "Breath sounds-normal-left",
    code: "LA17227-2",
    variant: "positive",
  },
  {
    display: "Breath sounds-normal-right",
    code: "LA17228-0",
    variant: "positive",
  },

  // Negative
  {
    code: "LA17222-3",
    display: "Accessory muscles used with breathing",
    variant: "negative",
  },
  { display: "Abrasion", code: "LA7410-9", variant: "negative" },
  { display: "Avulsion", code: "LA18220-6", variant: "negative" },

  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  {
    display: "Breath sounds-absent-left",
    code: "LA17223-1",
    variant: "negative",
  },
  {
    display: "Breath sounds-absent-right",
    code: "LA17224-9",
    variant: "negative",
  },
  {
    display: "Breath sounds-decreased-left",
    code: "LA17225-6",
    variant: "negative",
  },
  {
    display: "Breath sounds-decreased-right",
    code: "LA17226-4",
    variant: "negative",
  },

  { display: "Burn, blistering", code: "LA18221-4", variant: "negative" },
  { display: "Burn, charring", code: "LA18222-2", variant: "negative" },
  { display: "Burn, redness", code: "LA18223-0", variant: "negative" },
  { display: "Burn, white/waxy", code: "LA18224-8", variant: "negative" },
  { display: "Crush injury", code: "LA17229-8", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Flail segment-left", code: "LA17230-6", variant: "negative" },
  { display: "Flail segment-right", code: "LA17231-4", variant: "negative" },
  { display: "Foreign body", code: "LA18226-3", variant: "negative" },
  {
    display: "Increased respiratory effort",
    code: "LA17232-2",
    variant: "negative",
  },
  { display: "Implanted device", code: "LA182336-2", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  {
    display: "Pain with inspiration/expiration-left",
    code: "LA17233-0",
    variant: "negative",
  },
  {
    display: "Pain with inspiration/expiration-right",
    code: "LA17234-8",
    variant: "negative",
  },
  { display: "Puncture/stab wound", code: "LA17214-0", variant: "negative" },
  { display: "Rales-left", code: "LA18229-7", variant: "negative" },
  { display: "Rales-right", code: "LA18230-5", variant: "negative" },
  { display: "Retraction", code: "LA18231-3", variant: "negative" },
  { display: "Rhonchi-left", code: "LA18232-1", variant: "negative" },
  { display: "Rhonchi-right", code: "LA18233-9", variant: "negative" },
  { display: "Rhonchi/wheezing", code: "LA17236-3", variant: "negative" },
  { display: "Stridor-left", code: "LA18234-7", variant: "negative" },
  { display: "Stridor-right", code: "LA18235-4", variant: "negative" },
  { display: "Tenderness-left", code: "LA17237-1", variant: "negative" },
  { display: "Tenderness-right", code: "LA17238-9", variant: "negative" },
  {
    display: "Wheezing-expiratory-left",
    code: "LA18238-8",
    variant: "negative",
  },
  {
    display: "Wheezing-expiratory-right",
    code: "LA18237-0",
    variant: "negative",
  },
  {
    display: "Wheezing-inspiratory-left",
    code: "LA18239-6",
    variant: "negative",
  },
  {
    display: "Wheezing-inspiratory-right",
    code: "LA18240-4",
    variant: "negative",
  },
  { display: "Gunshot wound", code: "LA17212-4", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Contusion", code: "LA7423-2", variant: "negative" },
  { display: "Tenderness-General", code: "LA23810-7", variant: "negative" },
  // Neutral
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const cardiovascularPhysicalAssessment: Code[] = [
  // Positive
  {
    display: "Symmetrical precordium",
    code: "f73d1d90-3ef9-4a71-abbd-462e19ab5d46",
    variant: "positive",
  },
  {
    display:
      "Point of maximal impulse (PMI) located at fifth intercostal space, mid-clavicular line",
    code: "b38d5782-1480-4747-b8f7-151f6741f9d9",
    variant: "positive",
  },
  {
    display: "Pink, warm peripheries, capillary refill <2s",
    code: "61199cfe-997b-43b2-8df4-c4110171741a",
    variant: "positive",
  },
  {
    display: "Regular rate and rhythm (RRR)",
    code: "de628d00-5d52-4f30-b959-91fd418bcff2",
    variant: "positive",
  },
  {
    code: "LA18242-0",
    display: "Clear S1 heart sounds",
    variant: "positive",
  },
  { display: "Clear S2 heart sounds", code: "LA18243-8", variant: "positive" },
  {
    display: "No murmurs, rubs or gallops",
    code: "61c9108e-4dd0-4504-9917-89cc071ae34c",
    variant: "positive",
  },
  {
    display: "No lifts, heaves or thrills",
    code: "d5fd8541-5335-475b-95de-437c0a31621c",
    variant: "positive",
  },
  {
    display: "Carotid, Aorta, Femoral pulses normal without bruits",
    code: "dddc5219-b5d1-4da4-b5f2-94d9235a8b36",
    variant: "positive",
  },
  {
    display: "Peripheral pulses present, equal and symmetrical",
    code: "0a65a7e2-2dcb-42b3-a109-3d8dbfc1c6a5",
    variant: "positive",
  },
  {
    display: "Jugular vein pressure (JVP) not elevated",
    code: "71aa0b67-d6b3-4670-acf7-29bb554eb1e6",
    variant: "positive",
  },
  {
    display: "No hepatojugular reflux (HJR)",
    code: "41dd67ca-4c75-40ee-8b04-f04b78628392",
    variant: "positive",
  },
  {
    display: "No edema",
    code: "b1ffd823-09c0-4316-8720-1ae134e3d38e",
    variant: "positive",
  },

  // Negative
  {
    display: "Displaced point of maximal impulse (PMI)",
    code: "e7ef490d-2945-48d2-8647-d2f95779f95c",
    variant: "negative",
  },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Pale peripheries", code: "LA17206-6", variant: "negative" },
  { display: "Cyanotic peripheries", code: "LA17198-5", variant: "negative" },
  { display: "Mottled peripheries", code: "LA17205-8", variant: "negative" },
  //not in list
  {
    display: "Prolonged capillary refill",
    code: "6185ea5b-8e38-429f-86d3-836cf268517d",
    variant: "negative",
  },
  {
    display: "Irregular rate and rhythm",
    code: "91f9019c-9065-4c36-9f46-58744d6d19d4",
    variant: "negative",
  },
  { display: "Heart sounds decreased", code: "LA17239-7", variant: "negative" },
  { display: "S3 heart sound", code: "LA18244-6", variant: "negative" },
  { display: "S4 heart sound", code: "LA18245-3", variant: "negative" },
  { display: "Heart murmur-systolic", code: "LA17241-3", variant: "negative" },
  { display: "Heart murmur-diastolic", code: "LA17240-5", variant: "negative" },
  { display: "Rubs", code: "LA18246-1", variant: "negative" },
  { display: "Clicks", code: "LA18241-2", variant: "negative" },
  // {
  //   code: "LA17241-3",
  //   display: "Heart murmur-systolic",
  //   variant: "negative",
  // },
  // {
  //   display: "Displaced point of maximal impulse (PMI)",
  //   code: "",
  //   variant: "negative",
  // },
  // { display: "Deformity", code: "LA18225-5", variant: "negative" },
  // { display: "Pale peripheries", code: "LA17206-6", variant: "negative" },
  // { display: "Cyanotic peripheries", code: "LA17198-5", variant: "negative" },
  // { display: "Mottled peripheries", code: "LA17205-8", variant: "negative" },
  // { display: "Prolonged capillary refill", code: "", variant: "negative" },
  // { display: "Irregular rate and rhythm", code: "", variant: "negative" },
  // { display: "Heart sounds decreased", code: "LA17239-7", variant: "negative" },
  // { display: "S3 heart sound", code: "LA18244-6", variant: "negative" },
  // { display: "S4 heart sound", code: "LA18245-3", variant: "negative" },
  // { display: "Heart murmur-systolic", code: "LA17241-3", variant: "negative" },
  // { display: "Heart murmur-diastolic", code: "LA17240-5", variant: "negative" },
  // { display: "Rubs", code: "LA18246-1", variant: "negative" },
  // { display: "Clicks", code: "LA18241-2", variant: "negative" },
  // { display: "Bruits", code: "", variant: "negative" },
  // { display: "Peripheral pulses weak", code: "", variant: "negative" },
  // { display: "Peripheral pulses absent", code: "", variant: "negative" },
  // {
  //   display: "Elevated jugular vein pressure (JVP)",
  //   code: "",
  //   variant: "negative",
  // },
  // { display: "Hepatojugular reflux (HJR)", code: "", variant: "negative" },
  // { display: "Oedema", code: "", variant: "negative" },
  // Neutral
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurPhysicalAssessment: Code[] = [
  //grade
  {
    display: "Heart Murmur Grade I",
    code: "LA25121-7",
    variant: "neutral",
  },
  {
    display: "Heart Murmur Grade II",
    code: "LA25122-5",
    variant: "neutral",
  },
  {
    display: "Heart Murmur Grade III",
    code: "LA25123-3",
    variant: "neutral",
  },
  {
    display: "Heart Murmur Grade IV",
    code: "LA25124-1",
    variant: "neutral",
  },
  {
    display: "Heart Murmur Grade V",
    code: "LA25125-8",
    variant: "neutral",
  },
  {
    display: "Heart Murmur Grade VI",
    code: "LA25126-6",
    variant: "neutral",
  },
  //quality
  {
    display: "Musical",
    code: "LA25127-4",
    variant: "neutral",
  },
  {
    display: "Squeaking",
    code: "LA25128-2",
    variant: "neutral",
  },
  {
    display: "Blowing",
    code: "LA25129-0",
    variant: "neutral",
  },
  {
    display: "Harsh",
    code: "LA25130-8",
    variant: "neutral",
  },
  {
    display: "Rumbling",
    code: "LA25131-6",
    variant: "neutral",
  },
  //pitch
  {
    display: "Crescendo murmur",
    code: "LA25132-4",
    variant: "neutral",
  },
  {
    display: "Crescendo-decrescendo murmur",
    code: "LA25133-2",
    variant: "neutral",
  },
  {
    display: "Decrescendo murmur",
    code: "LA25134-0",
    variant: "neutral",
  },
  {
    display: "High-pitched",
    code: "LA25034-2",
    variant: "neutral",
  },
  {
    display: "Low-pitched",
    code: "LA25035-9",
    variant: "neutral",
  },
  {
    display: "Medium-pitched",
    code: "LA25137-3",
    variant: "neutral",
  },
  //timing
  {
    display: "Early diastolic",
    code: "LA25138-1",
    variant: "neutral",
  },
  {
    display: "Early systolic",
    code: "LA25139-9",
    variant: "neutral",
  },
  {
    display: "Late diastolic",
    code: "LA25140-7",
    variant: "neutral",
  },
  {
    display: "Late systolic",
    code: "LA25141-5",
    variant: "neutral",
  },
  {
    display: "Mid diastolic",
    code: "LA25142-3",
    variant: "neutral",
  },
  {
    display: "Mid systolic",
    code: "LA25143-1",
    variant: "neutral",
  },
  {
    display: "Pansystolic/holosystolic",
    code: "LA25144-9",
    variant: "neutral",
  },
  //location
  {
    display: "Apical",
    code: "LA25151-4",
    variant: "neutral",
  },
  {
    display: "Base",
    code: "LA25152-2",
    variant: "neutral",
  },
  {
    display: "2nd intercostal space",
    code: "LA25150-6",
    variant: "neutral",
  },
  {
    display: "3rd intercostal space",
    code: "LA25153-0",
    variant: "neutral",
  },
  {
    display: "4th intercostal space",
    code: "LA25146-4",
    variant: "neutral",
  },
  {
    display: "5th intercostal space",
    code: "LA25145-6",
    variant: "neutral",
  },
  {
    display: "Left mid-clavicular line",
    code: "LA25147-2",
    variant: "neutral",
  },
  {
    display: "Left sternal border",
    code: "LA25148-0",
    variant: "neutral",
  },
  {
    display: "Right sternal border",
    code: "LA25149-8",
    variant: "neutral",
  },
  //radiating to
  {
    display: "Apex",
    code: "LA25154-8",
    variant: "neutral",
  },
  {
    display: "Left axilla",
    code: "LA25155-5",
    variant: "neutral",
  },
  {
    display: "Neck",
    code: "LA12703-7",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const abdomenPhysicalAssessment: Code[] = [
  // Positive
  { display: "Symmetrical contour", code: "LA25051-6", variant: "positive" },
  { display: "Flat", code: "LA9212-7", variant: "positive" },
  {
    display: "Pregnant-palpable uterus",
    code: "LA17247-0",
    variant: "positive",
  },
  { display: "Visibly pregnant", code: "LA15173-0", variant: "positive" },
  { display: "Pulsations (aorta)", code: "LA25062-3", variant: "positive" },
  {
    display: "Soft, non-distended",
    code: "181f1b91-4f69-4c6a-890a-b5d510dc95ce",
    variant: "positive",
  },
  {
    display: "Bowel sounds present and active, no bruits",
    code: "d40fa901-2cf9-4749-bc2c-c677c4f3c4fe",
    variant: "positive",
  },
  {
    display: "Tympanic abdominal percussion note",
    code: "55e51f56-1f64-42fb-8f09-064c4251ee2a",
    variant: "positive",
  },
  {
    display: "Superficial and deep palpation without organomegaly or masses",
    code: "8c41b45f-e891-4725-a951-f59cc9f0c5f5",
    variant: "positive",
  },
  {
    display: "No direct or rebound tenderness, rigidity or guarding",
    code: "LA25069-8",
    variant: "positive",
  },
  {
    display: "Liver edge soft, smooth and non-tender (or not palpable)",
    code: "d7a99e67-b264-46ae-9be3-96559e1529a9",
    variant: "positive",
  },
  {
    display: "Liver span 10cm (+/- 2cm)",
    code: "9edf5074-407a-49bc-8d64-955e87bb7bbe",
    variant: "positive",
  },
  {
    display: "Kidneys not palpable",
    code: "46def048-12b9-4715-9061-5d4eeb60ba87",
    variant: "positive",
  },
  // Negative
  {
    display: "Scaphoid (concave) contour",
    code: "LA25056-5",
    variant: "negative",
  },
  { display: "Irregular contour", code: "LA25040-9", variant: "negative" },
  { display: "Distention", code: "LA17242-1; LA19589-3", variant: "negative" },
  {
    display: "Obese",
    code: "7ae70867-ac7f-45e2-b91d-709ac84b2c27",
    variant: "negative",
  },
  { display: "Pendulous contour", code: "LA25053-2", variant: "negative" },
  { display: "Bowel sounds-present", code: "LA18248-7", variant: "negative" },
  { display: "Bowel sounds-absent", code: "LA18247-9", variant: "negative" },
  { display: "Mass", code: "LA25042-5", variant: "negative" },
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Mass-pulsating", code: "LA17246-2", variant: "negative" },
  { display: "Tenderness", code: "LA17709-9; LA25070-6", variant: "negative" },
  {
    display: "Direct or rebound tenderness",
    code: "LA23814-9",
    variant: "negative",
  },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  {
    display: "Abdominal rigidity",
    code: "LA23815-6; LA25043-3",
    variant: "negative",
  },
  { display: "Firm", code: "LA25048-2", variant: "negative" },
  { display: "Guarding", code: "LA17243-9", variant: "negative" },
  { display: "Taut", code: "LA25059-9", variant: "negative" },
  {
    display: "Distended abdominal veins",
    code: "LA25044-1",
    variant: "negative",
  },
  {
    display: "Prominent abdominal veins",
    code: "LA25055-7",
    variant: "negative",
  },
  { display: "Distinct protrusions", code: "LA25046-6", variant: "negative" },
  { display: "Cullen’s sign", code: "LA25045-8", variant: "negative" },
  { display: "Umbilical hernia", code: "LA25060-7", variant: "negative" },
  { display: "Inguinal hernia", code: "LA25050-8", variant: "negative" },
  { display: "Visible peristalsis", code: "LA25063-1", variant: "negative" },
  { display: "Vascularity", code: "LA25061-5", variant: "negative" },
  { display: "Scar", code: "LA24887-4", variant: "negative" },
  { display: "Shiny", code: "LA25058-1", variant: "negative" },
  { display: "Rash", code: "LA7469-5", variant: "negative" },
  { display: "Wrinkled", code: "LA25064-9", variant: "negative" },
  { display: "Abrasion", code: "LA7410-9", variant: "negative" },
  { display: "Avulsion", code: "LA18220-6", variant: "negative" },
  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  { display: "Burn, blistering", code: "LA18221-4", variant: "negative" },
  { display: "Burn, charring", code: "LA18222-2", variant: "negative" },
  { display: "Burn, redness", code: "LA18223-0", variant: "negative" },
  { display: "Burn, white/waxy", code: "LA18224-8", variant: "negative" },
  { display: "Foreign body", code: "LA18226-3", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Puncture/stab wound", code: "LA17214-0", variant: "negative" },
  { display: "Gunshot wound", code: "LA17212-4", variant: "negative" },
  { display: "Crush injury", code: "LA17229-8", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Contusion", code: "LA7423-2", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  // Neutral
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
  // Location
  // { display: "Generalised", code: "LA18721-3", variant: "neutral" },
  // {
  //   display: "Left lower quadrant (LLQ)",
  //   code: "LA18722-1",
  //   variant: "neutral",
  // },
  // {
  //   display: "Left upper quadrant (LUQ)",
  //   code: "LA12698-9",
  //   variant: "neutral",
  // },
  // { display: "Periumbilical", code: "LA18724-7", variant: "neutral" },
  // {
  //   display: "Right lower quadrant (RLQ)",
  //   code: "LA18725-4",
  //   variant: "neutral",
  // },
  // {
  //   display: "Right upper quadrant (RUQ)",
  //   code: "LA18723-9",
  //   variant: "neutral",
  // },
  // { display: "Epigastric", code: "LA23813-1", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const umbilicalCordPhysicalAssessment: Code[] = [
  //Positive
  { display: "Normal", code: "LA6626-1", variant: "positive" },
  //Negative
  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  //Not Examined
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const pelvisPhysicalAssessment: Code[] = [
  //Positive
  { display: "Normal", code: "LA6626-1", variant: "positive" },
  //Negative
  { display: "Abrasion", code: "LA7410-9", variant: "negative" },
  { display: "Avulsion", code: "LA18220-6", variant: "negative" },
  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  { display: "Bleeding-rectal", code: "LA17248-8", variant: "negative" },
  { display: "Bleeding-urethral", code: "LA17249-6", variant: "negative" },
  { display: "Bleeding-vaginal", code: "LA17250-4", variant: "negative" },
  { display: "Burn, blistering", code: "LA18221-4", variant: "negative" },
  { display: "Burn, charring", code: "LA18222-2", variant: "negative" },
  { display: "Burn, redness", code: "LA18223-0", variant: "negative" },
  { display: "Burn, white/waxy", code: "LA18224-8", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Foreign body", code: "LA18226-3", variant: "negative" },
  { display: "Genital injury", code: "LA17251-2", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  { display: "Pelvic Fracture", code: "LA17252-0", variant: "negative" },
  { display: "Pelvic instability", code: "LA17253-8", variant: "negative" },
  {
    display: "Penile priapism/erection",
    code: "LA17254-6",
    variant: "negative",
  },
  { display: "Pregnant-crowning", code: "LA17255-3", variant: "negative" },
  { display: "Puncture/stab wound", code: "LA17214-0", variant: "negative" },
  { display: "Tenderness", code: "LA17709-9", variant: "negative" },
  { display: "Gunshot wound", code: "LA17212-4", variant: "negative" },
  { display: "Crush injury", code: "LA17229-8", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Contusion", code: "LA7423-2", variant: "negative" },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const digitalRectalPhysicalAssessment: Code[] = [
  //Normal
  {
    display: "No external lesions, discharge and/or drainage",
    code: "99f0da17-8cb2-4316-b7cb-5832eec51efa",
    variant: "positive",
  },
  {
    display: "No masses or tenderness",
    code: "2abcdada-233d-4c62-aeb9-7edb97b69ef4",
    variant: "positive",
  },
  {
    display: "Good sphincter tone ",
    code: "82b3715c-2827-4ac4-8f24-2b97348648aa",
    variant: "positive",
  },
  {
    display: "Brown stool on gloved finger",
    code: "f43647e1-2370-4b6a-b604-3eaaa1f54754",
    variant: "positive",
  },
  {
    display: "No stool on gloved finger",
    code: "414739dd-5fb0-49b6-8a3b-c7ff1be05199",
    variant: "positive",
  },
  //Abnormal
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Bleeding-rectal", code: "LA17248-8", variant: "negative" },
  { display: "Foreign body", code: "LA18226-3", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Tenderness", code: "LA17709-9", variant: "negative" },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const pelvisGenitourinaryPhysicalAssessment: Code[] = [
  // Negative
  { code: "LA18220-6", display: "Avulsion", variant: "negative" },
  { code: "LA7410-9", display: "Abrasion", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA7410-9", display: "Abrasion", variant: "negative" },
  {
    code: "LA17208-2",
    display: "Bleeding controlled",
    variant: "negative",
  },
  {
    code: "LA17209-0",
    display: "Bleeding uncontrolled",
    variant: "negative",
  },
  { display: "Bleeding-rectal", code: "LA17248-8", variant: "negative" },
  { display: "Bleeding-urethral", code: "LA17249-6", variant: "negative" },
  { display: "Bleeding-vaginal", code: "LA17250-4", variant: "negative" },
  { code: "LA18221-4", display: "Burn, blistering", variant: "negative" },
  { code: "LA18222-2", display: "Burn, charring", variant: "negative" },
  { code: "LA18223-0", display: "Burn, redness", variant: "negative" },
  { code: "LA18224-8", display: "Burn, white/waxy", variant: "negative" },
  { code: "LA18225-5", display: "Deformity", variant: "negative" },
  { code: "LA18226-3", display: "Foreign body", variant: "negative" },
  { code: "LA17251-2", display: "Genital injury", variant: "negative" },
  { code: "LA7452-1", display: "Laceration", variant: "negative" },
  { code: "LA17213-2", display: "Mass/lesion", variant: "negative" },
  { code: "LA7460-4", display: "Pain", variant: "negative" },
  { code: "LA17252-0", display: "Pelvic Fracture", variant: "negative" },
  { code: "LA17253-8", display: "Pelvic instability", variant: "negative" },
  {
    code: "LA17254-6",
    display: "Penile priapism/erection",
    variant: "negative",
  },
  { code: "LA17255-3", display: "Pregnant-crowning", variant: "negative" },
  {
    code: "LA17214-0",
    display: "Puncture/stab wound",
    variant: "negative",
  },
  {
    code: "LA17709-9",
    display: "Tenderness",
    variant: "negative",
  },
  { code: "LA17212-4", display: "Gunshot wound", variant: "negative" },
  { code: "LA7423-2", display: "Contusion", variant: "negative" },
  { code: "LA17229-8", display: "Crush injury", variant: "negative" },
  { code: "LA22440-4", display: "Swelling", variant: "negative" },
  // Neutral
  { code: "LA6630-3", display: "Not examined", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const prostateExamPhysicalAssessment = [
  {
    code: "e92a623d-9813-4feb-bd30-6773f96f663c",
    display: "No stool on gloved finger",
  },
  {
    code: "6e6be217-0269-4d9b-b810-42b4f652643d",
    display: "Red coloured stool on gloved finger",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const maleGenitaliaPhysicalAssessment = [
  //Normal
  {
    code: "54b262f1-dab5-4366-9cc0-e7d41fd3fc4d",
    display: "No rashes",
    variant: "positive",
  },
  {
    code: "0200bb62-af7b-4781-a806-f1cfc80cf2bd",
    display: "Penile shaft without masses or lesions",
    variant: "positive",
  },
  {
    code: "ddedebc2-5b3e-4370-8d56-d7b55973de33",
    display: "Circumcised",
    variant: "positive",
  },
  {
    code: "dddfbd94-6471-4681-9bf1-45c17a578408",
    display: "Uncircumcised",
    variant: "positive",
  },
  {
    code: "ab022cf3-b07f-4b0b-a09c-7c6a07c7b7f6",
    display: "Foreskin easily retractable from the glans",
    variant: "positive",
  },
  {
    code: "8f9fcdd1-f35d-4124-aa39-e080da93ba0a",
    display: "No urethral discharge",
    variant: "positive",
  },
  {
    code: "b2354c99-b2d9-41eb-a48e-12305e8c4da6",
    display: "Bilateral testicles present and normal in consistency",
    variant: "positive",
  },
  {
    code: "af3592fb-ca23-444c-a04d-06763696e238",
    display: "No varicocele or hydrocele",
    variant: "positive",
  },
  {
    code: "3851e310-5d9a-4185-808c-cdf8346feca7",
    display: "No scrotal masses",
    variant: "positive",
  },
  {
    code: "fa39db06-4553-4d74-845d-bb6811f51ee8",
    display: "No inguinal hernia",
    variant: "positive",
  },
  {
    code: "fffbbafe-7699-42ff-be9e-f6ca906a67fa",
    display: "No inguinal lymphadenopathy",
    variant: "positive",
  },
  //Abnormal
  {
    code: "6c0fb4e0-660c-4229-9fb3-2ad40bbf8571",
    display: "Rashes",
    variant: "negative",
  },
  {
    code: "1a3642b8-3f07-4c19-a5e0-1ba17988cb17",
    display: "Penile shaft with lesions, masses, inflammation",
    variant: "negative",
  },
  {
    code: "1fe02bb3-5b05-49c5-84a5-1642b942c123",
    display: "Foreskin not easily retractable from the glans",
    variant: "negative",
  },
  {
    code: "468bd70a-2cfe-4d1b-b7b1-5b4ffab7364b",
    display: "Hypospadia",
    variant: "negative",
  },
  {
    code: "9226e3c0-56a2-43d0-8c85-904a76b3de4d",
    display: "Epispadia",
    variant: "negative",
  },
  {
    code: "afa22d13-c75a-4c51-98e2-c78a60a9ff05",
    display: "Hydrocele",
    variant: "negative",
  },
  {
    code: "3958c3b2-7699-4fdd-ab80-9a59f825a210",
    display: "Varicocele",
    variant: "negative",
  },
  {
    code: "07db399a-7283-4f2b-aaa7-47f6726126b4",
    display: "Scrotal mass",
    variant: "negative",
  },
  {
    code: "35136ec5-1192-4eeb-8762-ed8a95d6e15d",
    display: "Inguinal hernia",
    variant: "negative",
  },
  {
    code: "c27d867a-eb4e-426a-b58f-3d1fb2ac9e73",
    display: "Inguinal lymphadenopathy",
    variant: "negative",
  },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const femaleGenitaliaPhysicalAssessment = [
  //Positive
  {
    code: "28063d8d-2108-406a-a5e9-18c99891fdb9",
    display: "No rashes",
    variant: "positive",
  },
  {
    code: "a359bc58-58e8-4e9c-bae4-373e09ca677c",
    display: "Vulva without lesions, masses or inflammation",
    variant: "positive",
  },
  {
    code: "58b291bf-3249-432c-9dc9-bbf6c6583604",
    display: "Urethral meatus normal, no discharge",
    variant: "positive",
  },
  {
    code: "26552fb7-bdf3-49a4-9756-877b26463c30",
    display: "Normal Bartholin gland",
    variant: "positive",
  },
  {
    code: "b3945429-1a1b-4efe-9ead-1e7f758a0b30",
    display: "Normal vaginal discharge ",
    variant: "positive",
  },
  {
    code: "4972cf7d-8619-41e6-be17-b3af1b95c221",
    display: "Menstrual bleeding",
    variant: "positive",
  },
  {
    code: "c96d7eb3-87a9-4501-a17a-9390e2af3e3c",
    display: "Vaginal atrophy due to post-menopause",
    variant: "positive",
  },
  //Negative
  {
    code: "c9de5a91-6ab9-43f7-942a-6a1f40283ceb",
    display: "Rashes",
    variant: "negative",
  },
  {
    code: "fe1a88ef-8f51-4c9b-a094-8f8f0ae3f52d",
    display: "Vulva with lesions, masses or inflammation",
    variant: "negative",
  },
  {
    code: "79785a34-29d7-4cc6-badb-52e3f1424464",
    display: "Bartholin gland cyst",
    variant: "negative",
  },
  {
    code: "a736bc0f-89de-4504-add1-b798556dcac7",
    display: "Pathologic vaginal discharge ",
    variant: "negative",
  },
  {
    code: "fa119600-059a-4974-92a2-53ed7143935b",
    display: "Non-menstrual bleeding",
    variant: "negative",
  },
  {
    code: "aec86663-76b3-42df-ba43-f0d080ca7cac",
    display: "Uterine prolapse",
    variant: "negative",
  },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const obstetricPhysicalAssessment = [
  //Inspection
  {
    code: "90f7dc70-13bd-413c-905c-236caec7e577",
    display: "fetal movement (>24 weeks)",
    variant: "neutral",
  },
  {
    code: "54202200-a61f-4b83-b525-c887bc10b71b",
    display: "linea nigra",
    variant: "neutral",
  },
  {
    code: "4eec3b41-ebc0-4950-acfc-0f9b6a8b63d0",
    display: "striae gravidarum",
    variant: "neutral",
  },
  {
    code: "2204e416-c578-4f83-869a-b002b196313c",
    display: "striae albicans",
    variant: "neutral",
  },
  {
    code: "3b5f3210-126e-4ccc-a91f-6e17944ac76a",
    display: "Caesarean section scar",
    variant: "neutral",
  },
  {
    code: "215e805b-a16c-4f68-9507-831da0529fa9",
    display: "Lesions",
    variant: "neutral",
  },
  {
    code: "08a106e1-7f55-4420-af01-efb717c2c5c4",
    display: "edema",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const palpableFetalMovementsAssessment = [
  {
    code: "56834-5",
    display: "Palpable fetal movements",
    variant: "normal",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const fundalHeightGestationalAgeAssessment = [
  {
    code: "07ea673f-a1dc-4d5a-b2dd-6931cd2954e2",
    display: "Corresponds with gestational age",
    variant: "Positive",
  },
  {
    code: "e37ab1ae-730a-43e0-a66f-5c13429a5114",
    display: "Does not corresponds with gestational age",
    variant: "negative",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const palpationLieAssessment = [
  {
    code: "237839f6-3800-46dc-8ac8-49e159716b09",
    display: "Longitudinal",
    variant: "positive",
  },
  {
    code: "c6bbe04b-4ea8-4bab-aa24-b28563eae30c",
    display: "Transverse",
    variant: "negative",
  },
  {
    code: "de30568f-fd7d-43fe-bcce-b0ff1eb4b508",
    display: "Oblique",
    variant: "negative",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const palpationPresentationAssessment = [
  {
    code: "8ddda86c-83c3-41ed-be87-eae562cf518e",
    display: "Cephalic",
    variant: "positive",
  },
  {
    code: "d7186c12-354e-492e-905a-e89261174161",
    display: "Breech",
    variant: "negative",
  },
  {
    code: "9cbfb9d4-bd3e-4809-b4ff-e39939fe5509",
    display: "Difficult to determine on abdominal palpation",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const palpationEngagementAssessment = [
  {
    code: "e602eae7-1f2c-42fe-8dbe-8ce36ed071dd",
    display: "Engaged",
    variant: "neutral",
  },
  {
    code: "9dcff223-121a-45b3-9690-72df5be8a4d6",
    display: "Not engaged",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const musculoskeletalPhysicalAssessment: Code[] = [
  {
    code: "09977e20-b585-4a7a-9495-240b4a0a8684",
    display: "No clubbing, cyanosis or edema (CCE)",
    variant: "positive",
  },
  {
    code: "26436002-cdb2-422b-afd8-453819424aea",
    display: "No inflammation",
    variant: "positive",
  },
  {
    code: "c956fcee-3cb8-457f-bc67-fbdeeb2224c0",
    display: "No skeletal deformities or dislocations",
    variant: "positive",
  },
  {
    code: "1a8b7947-2154-44c2-8870-353eaaf20186",
    display: "Normal muscle tone and bulk",
    variant: "positive",
  },
  {
    code: "cba5d9e8-6a95-4f37-a901-250e308d61be",
    display: "No muscle spasm or involuntary movements",
    variant: "positive",
  },
  {
    code: "40aa278e-e5ed-4401-b8a4-71a62f01521b",
    display: "Full range of motion (FROM)",
    variant: "positive",
  },
  {
    code: "e7052934-a1d7-4e81-9051-075802ff4c79",
    display: "Normal gait",
    variant: "positive",
  },
  {
    code: "5bfe73f4-0da1-4282-a90a-da11c2189bff",
    display: "Non-tender on palpation",
    variant: "positive",
  },
  //Abnormal
  { display: "Mass/lesion", code: "LA17213-2", variant: "negative" },
  { display: "Deformity", code: "LA18225-5", variant: "negative" },
  { display: "Swelling", code: "LA22440-4", variant: "negative" },
  { display: "Tenderness", code: "LA17709-9", variant: "negative" },
  { display: "Pain", code: "LA7460-4", variant: "negative" },
  {
    display: "Pain with range of motion",
    code: "LA17256-1",
    variant: "negative",
  },
  { display: "Gunshot wound", code: "LA17212-4", variant: "negative" },
  { display: "Laceration", code: "LA7452-1", variant: "negative" },
  { display: "Puncture/stab wound", code: "LA17214-0", variant: "negative" },
  { display: "Abrasion", code: "LA7410-9", variant: "negative" },
  { display: "Avulsion", code: "LA18220-6", variant: "negative" },
  { display: "Bleeding controlled", code: "LA17208-2", variant: "negative" },
  { display: "Bleeding uncontrolled", code: "LA17209-0", variant: "negative" },
  { display: "Burn, blistering", code: "LA18221-4", variant: "negative" },
  { display: "Burn, charring", code: "LA18222-2", variant: "negative" },
  { display: "Burn, redness", code: "LA18223-0", variant: "negative" },
  { display: "Burn, white/waxy", code: "LA18224-8", variant: "negative" },
  { display: "Crush injury", code: "LA17229-8", variant: "negative" },
  { display: "Contusion", code: "LA7423-2", variant: "negative" },
  //Not examined
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const neurologicalPhysicalAssessment: Code[] = [
  //Normal
  {
    code: "LA17286-8",
    display: "Normal baseline for patient",
    variant: "positive",
  },
  {
    code: "699d6960-3661-414c-ae67-ac9df87b489f",
    display: "Alert and oriented to time, place, and person (A&Ox3)",
    variant: "positive",
  },
  {
    code: "e8466df4-beec-4ba2-a15d-60e8d353e529",
    display: "Normal gait",
    variant: "positive",
  },
  {
    code: "ef163b9a-9191-4069-93c1-a6580d294334",
    display: "Cranial nerves (CN) I-XII intact, symmetrical",
    variant: "positive",
  },
  {
    code: "2d245677-dd21-4633-989b-e09c627d7873",
    display: "No focal deficits",
    variant: "positive",
  },
  {
    code: "651baccc-e064-4d15-b447-899194c4d33d",
    display: "Normal deep tendon reflexes",
    variant: "positive",
  },
  {
    code: "1ad2ed85-c567-4dea-8efd-04a8b35f8608",
    display:
      "Sensation intact to light touch, position, pin prick, vibration, and two-point discrimination",
    variant: "positive",
  },
  {
    code: "2838a966-1b4f-4bd9-bd86-768d8fa6bcac",
    display: "Negative Bakinski's sign",
    variant: "positive",
  },
  //Abnormal
  {
    code: "99f8ea7c-1081-4d96-8a4d-534078a72a19",
    display: "Altered gait",
    variant: "negative",
  },
  {
    code: "30c9bacb-b2c5-4510-bc0a-8b7e526f5da0",
    display: "Cranial nerve impairment",
    variant: "negative",
  },
  {
    code: "0b288a2c-0985-4b00-9fc5-8d5303c6ceaa",
    display: "Cerebellar signs",
    variant: "negative",
  },
  {
    code: "afece5db-66c7-45b7-9dfc-c47ad37029d8",
    display: "Altered deep tendon reflexes",
    variant: "negative",
  },
  {
    code: "1ddff8db-7d3a-4a64-985d-014f4ae743fc",
    display: "Sensory deficit",
    variant: "negative",
  },
  {
    code: "c22eacd5-64c4-4534-ad76-41ccbf811c4f",
    display: "Motor deficit",
    variant: "negative",
  },
  {
    code: "LA32341-2",
    display: "Single-sided symptoms (pupil, extremities)",
    variant: "negative",
  },
  {
    code: "LA32340-4",
    display: "No smile",
    variant: "negative",
  },
  {
    code: "LA31047-6",
    display: "Vision impairment",
    variant: "negative",
  },
  {
    code: "LA17315-5",
    display: "Speech impaired",
    variant: "negative",
  },
  {
    code: "LA20372-1",
    display: "Dementia",
    variant: "negative",
  },
  {
    code: "LA32339-6",
    display: "Paraplegic symptoms",
    variant: "negative",
  },
  {
    code: "LA32338-8",
    display: "Babinski's sign",
    variant: "negative",
  },
  {
    code: "LA17660-4",
    display: "Meningismus",
    variant: "negative",
  },
  {
    code: "LA32337-0",
    display: "Pre-existing neurological condition",
    variant: "negative",
  },
  //Other
  {
    code: "LA46-8",
    display: "Other",
    variant: "neutral",
  },
  //Not examined
  {
    code: "LA6630-3",
    display: "Not examined",
    variant: "neutral",
  },
  //Unable to assess/Inconclusive results
  {
    code: "LA32336-2",
    display: "Unable to assess/Inconclusive results",
    variant: "neutral",
  },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const psychiatricPhysicalAssessment: Code[] = [
  // Positive
  {
    display: "Normal baseline for patient",
    code: "LA17286-8",
    variant: "positive",
  },
  { display: "Oriented-person", code: "LA17288-4", variant: "positive" },
  { display: "Oriented-place", code: "LA17289-2", variant: "positive" },
  { display: "Oriented-event", code: "LA18259-4", variant: "positive" },
  { display: "Oriented-time", code: "LA17290-0", variant: "positive" },

  // Negative
  { display: "Combative", code: "LA17287-6", variant: "negative" },
  { display: "Confused", code: "LA6560-2", variant: "negative" },
  { display: "Hallucinations", code: "LA7438-0", variant: "negative" },
  { display: "Unresponsive", code: "LA9343-0", variant: "negative" },
  { display: "Agitation", code: "LA6150-2", variant: "negative" },
  { display: "Somnolent", code: "LA23818-0", variant: "negative" },
  { display: "Stupor", code: "LA17666-1", variant: "negative" },
  { display: "Altered mental status", code: "LA32951-8", variant: "negative" },
  {
    display: "Developmentally impaired",
    code: "LA17307-2",
    variant: "negative",
  },
  {
    display: "Disorientation, unspecified",
    code: "LA17668-7",
    variant: "negative",
  },
  {
    display: "Pharmacologically paralyzed",
    code: "LA32953-4",
    variant: "negative",
  },
  {
    display: "Pharmacologically sedated",
    code: "LA32948-4",
    variant: "negative",
  },
  {
    display: "Psychologically impaired",
    code: "LA17313-0",
    variant: "negative",
  },
  {
    display: "Slowness and poor responsiveness",
    code: "LA32950-0",
    variant: "negative",
  },
  {
    display: "State of emotional shock and stress, unspecified",
    code: "LA17678-6",
    variant: "negative",
  },
  {
    display: "Strange and inexplicable behaviour",
    code: "LA32956-7",
    variant: "negative",
  },
  { display: "Uncooperative", code: "LA17317-1", variant: "negative" },
  { display: "Unspecified coma", code: "LA32955-9", variant: "negative" },

  // Netural
  { display: "Not examined", code: "LA6630-3", variant: "neutral" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurGradePhysicalAssessment: Code[] = [
  { display: "Heart Murmur Grade I", code: "LA25121-7" },
  { display: "Heart Murmur Grade II", code: "LA25122-5" },
  { display: "Heart Murmur Grade III", code: "LA25123-3" },
  { display: "Heart Murmur Grade IV", code: "LA25124-1" },
  { display: "Heart Murmur Grade V", code: "LA25125-8" },
  { display: "Heart Murmur Grade VI", code: "LA25126-6" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurQualityPhysicalAssessment: Code[] = [
  { display: "Musical", code: "LA25127-4" },
  { display: "Squeaking", code: "LA25128-2" },
  { display: "Blowing", code: "LA25129-0" },
  { display: "Harsh", code: "LA25130-8" },
  { display: "Rumbling", code: "LA25131-6" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurPitchPhysicalAssessment: Code[] = [
  { display: "Crescendo murmur", code: "LA25132-4" },
  { display: "Crescendo-decrescendo murmur", code: "LA25133-2" },
  { display: "Decrescendo murmur", code: "LA25134-0" },
  { display: "High-pitched", code: "LA25034-2" },
  { display: "Medium-pitched", code: "LA25137-3" },
  { display: "Low-pitched", code: "LA25035-9" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurTimingPhysicalAssessment: Code[] = [
  { display: "Early diastolic", code: "LA25138-1" },
  { display: "Early systolic", code: "LA25139-9" },
  { display: "Late diastolic", code: "LA25140-7" },
  { display: "Late systolic", code: "LA25141-5" },
  { display: "Mid diastolic", code: "LA25142-3" },
  { display: "Mid systolic", code: "LA25143-1" },
  { display: "Pansystolic/holosystolic", code: "LA25144-9" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurLocationPhysicalAssessment: Code[] = [
  { display: "Apical", code: "LA25151-4" },
  { display: "Base", code: "LA25152-2" },
  { display: "2nd intercostal space", code: "LA25150-6" },
  { display: "3rd intercostal space", code: "LA25153-0" },
  { display: "4th intercostal space", code: "LA25146-4" },
  { display: "5th intercostal space", code: "LA25145-6" },
  { display: "Left mid-clavicular line", code: "LA25147-2" },
  { display: "Left sternal border", code: "LA25148-0" },
  { display: "Right sternal border", code: "LA25149-8" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const heartMurmurRadiatingPhysicalAssessment: Code[] = [
  { display: "Apex", code: "LA25154-8" },
  { display: "Left axilla", code: "LA25155-5" },
  { display: "Neck", code: "LA12703-7" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const eyeOpeningScorePhysicalAssessment: Code[] = [
  { display: "No eye opening (score 1)", code: "LA6553-7" },
  { display: "Eye opening to pain (score 2)", code: "LA6554-5" },
  { display: "Eye opening to verbal command (score 3)", code: "LA6555-2" },
  { display: "Eyes open spontaneously (score 4)", code: "LA6556-0" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const motorScorePhysicalAssessment: Code[] = [
  { display: "No motor response (score 1)", code: "LA6562-8" },
  { display: "Extension to pain (score 2)", code: "LA6563-6" },
  { display: "Flexion to pain (score 3)", code: "LA6564-4" },
  { display: "Withdrawal from pain (score 4)", code: "LA6565-1" },
  { display: "Localising pain (score 5)", code: "LA6566-9" },
  { display: "Obeys commands (score 6)", code: "LA6567-7" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const verbalScorePhysicalAssessment: Code[] = [
  {
    display:
      "No verbal response (>2 yrs); no vocal response (<=2 yrs) (score 1)",
    code: "LA6557-8",
  },
  { display: "Incomprehensible sounds (score 2)", code: "LA6558-6" },
  { display: "Inappropriate words (score 3)", code: "LA6559-4" },
  { display: "Confused (score 4)", code: "LA6560-2" },
  { display: "Oriented (score 5)", code: "LA6561-0" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const prenatalKetonesPhysicalAssessment: Code[] = [
  { display: "Negative", code: "LA6577-6" },
  { display: "Trace", code: "LA11832-5" },
  { display: "Small", code: "LA8983-4" },
  { display: "Moderate", code: "LA6751-7" },
  { display: "Large", code: "LA8981-8" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const obstetricInspectionAssessment: Code[] = [
  { code: "fetalMovement", display: "Fetal movement (>24 weeks)" },
  { code: "lineaNigra", display: "Linea Nigra" },
  { code: "striaeGravidarum", display: "Striae Gravidarum" },
  { code: "striaeAlbicans", display: "Striae Albicans" },
  { code: "caesareanSectionScar", display: "Caesarean Section Scar" },
  { code: "lesions", display: "Lesions" },
  { code: "edema", display: "Edema" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

const mergedPhysicalExamCodes = [].concat(
  generalPhysicalAssessment,
  skinPhysicalAssessment,
  facePhysicalAssessment,
  lymphaticPhysicalAssessment,
  headPhysicalAssessment,
  eyesPhysicalAssessment,
  earsPhysicalAssessment,
  nosePhysicalAssessment,
  oralCavityPhysicalAssessment,
  neckPhysicalAssessment,
  backPhysicalAssessment,
  chestPhysicalAssessment,
  respiratoryPhysicalAssessment,
  cardiovascularPhysicalAssessment,
  heartMurmurPhysicalAssessment,
  abdomenPhysicalAssessment,
  umbilicalCordPhysicalAssessment,
  pelvisPhysicalAssessment,
  digitalRectalPhysicalAssessment,
  pelvisGenitourinaryPhysicalAssessment,
  prostateExamPhysicalAssessment,
  maleGenitaliaPhysicalAssessment,
  femaleGenitaliaPhysicalAssessment,
  obstetricPhysicalAssessment,
  palpableFetalMovementsAssessment,
  fundalHeightGestationalAgeAssessment,
  palpationLieAssessment,
  palpationPresentationAssessment,
  palpationEngagementAssessment,
  musculoskeletalPhysicalAssessment,
  neurologicalPhysicalAssessment,
  psychiatricPhysicalAssessment,
  heartMurmurQualityPhysicalAssessment,
  heartMurmurGradePhysicalAssessment,
  heartMurmurPitchPhysicalAssessment,
  heartMurmurTimingPhysicalAssessment,
  heartMurmurLocationPhysicalAssessment,
  heartMurmurRadiatingPhysicalAssessment,
  eyeOpeningScorePhysicalAssessment,
  motorScorePhysicalAssessment,
  verbalScorePhysicalAssessment,
  prenatalKetonesPhysicalAssessment,
  obstetricInspectionAssessment,
);

export const physicalExaminationDisplayMapping = Object.fromEntries(
  mergedPhysicalExamCodes.map((code) => [code.code, code.display]),
);
