import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Clinical signs and symptoms of DVT",
    code: "clinical-signs-and-symptoms",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 3 },
    ],
  },
  {
    title: "PE is #1 diagnosis OR equally likely",
    code: "pe-number-one",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 3 },
    ],
  },
  {
    title: "Heart rate > 100",
    code: "hr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1.5 },
    ],
  },
  {
    title: "Immobilization at least 3 days OR surgery in the previous 4 weeks",
    code: "immobilization",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1.5 },
    ],
  },
  {
    title: "Entire leg swollen",
    code: "leg-swollen",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Previous, objectively diagnosed PE or DVT",
    code: "previous-pe-dvt",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1.5 },
    ],
  },
  {
    title: "Hemoptysis",
    code: "hemoptysis",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Malignancy w/ treatment within 6 months or palliative",
    code: "malignancy",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function WellsPEComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (true) {
      case value > 6:
        return "High risk";
      case value >= 2:
        return "Moderate risk";
      default:
        return "Low risk";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
