import { sortBy } from "lodash";
import { defaultCountryName } from "../../config";
import { Code } from "../../types/code";

export const genders = [
  {
    code: "female",
    display: "Female",
  },
  {
    code: "male",
    display: "Male",
  },
  {
    code: "transgender-female",
    display: "Transgender Female",
  },
  {
    code: "transgender-male",
    display: "Transgender Male",
  },
  {
    code: "other",
    display: "Other",
  },
];

export const languages = [
  { code: "en", display: "English" },
  { code: "es", display: "Spanish" },
  { code: "cpf", display: "French Creole" },
];

export const countriesArr: Code[] = [
  { code: "AF", display: "Afghanistan" },
  { code: "AL", display: "Albania" },
  { code: "DZ", display: "Algeria" },
  { code: "AX", display: "Alland Islands" },
  { code: "AS", display: "American Samoa" },
  { code: "AD", display: "Andorra" },
  { code: "AO", display: "Angola" },
  { code: "AI", display: "Anguilla" },
  { code: "AQ", display: "Antarctica" },
  { code: "AG", display: "Antigua and Barbuda" },
  { code: "AR", display: "Argentina" },
  { code: "AM", display: "Armenia" },
  { code: "AW", display: "Aruba" },
  { code: "AU", display: "Australia" },
  { code: "AT", display: "Austria" },
  { code: "AZ", display: "Azerbaijan" },
  { code: "BS", display: "Bahamas" },
  { code: "BH", display: "Bahrain" },
  { code: "BD", display: "Bangladesh" },
  { code: "BB", display: "Barbados" },
  { code: "BY", display: "Belarus" },
  { code: "BE", display: "Belgium" },
  { code: "BZ", display: "Belize" },
  { code: "BJ", display: "Benin" },
  { code: "BM", display: "Bermuda" },
  { code: "BT", display: "Bhutan" },
  { code: "BO", display: "Bolivia" },
  { code: "BA", display: "Bosnia and Herzegovina" },
  { code: "BW", display: "Botswana" },
  { code: "BV", display: "Bouvet Island" },
  { code: "BR", display: "Brazil" },
  { code: "IO", display: "British Indian Ocean Territory" },
  { code: "VG", display: "British Virgin Islands" },
  { code: "BN", display: "Brunei Darussalam" },
  { code: "BG", display: "Bulgaria" },
  { code: "BF", display: "Burkina Faso" },
  { code: "BI", display: "Burundi" },
  { code: "KH", display: "Cambodia" },
  { code: "CM", display: "Cameroon" },
  { code: "CA", display: "Canada" },
  { code: "CV", display: "Cape Verde" },
  { code: "KY", display: "Cayman Islands" },
  { code: "CF", display: "Central African Republic" },
  { code: "TD", display: "Chad" },
  { code: "CL", display: "Chile" },
  { code: "CN", display: "China" },
  { code: "CX", display: "Christmas Island" },
  { code: "CC", display: "Cocos (Keeling) Islands" },
  { code: "CO", display: "Colombia" },
  { code: "KM", display: "Comoros" },
  { code: "CD", display: "Congo, Democratic Republic of the" },
  { code: "CG", display: "Congo, Republic of the" },
  { code: "CK", display: "Cook Islands" },
  { code: "CR", display: "Costa Rica" },
  { code: "CI", display: "Cote d'Ivoire" },
  { code: "HR", display: "Croatia" },
  { code: "CU", display: "Cuba" },
  { code: "CW", display: "Curacao" },
  { code: "CY", display: "Cyprus" },
  { code: "CZ", display: "Czech Republic" },
  { code: "DK", display: "Denmark" },
  { code: "DJ", display: "Djibouti" },
  { code: "DM", display: "Dominica" },
  { code: "DO", display: "Dominican Republic" },
  { code: "EC", display: "Ecuador" },
  { code: "EG", display: "Egypt" },
  { code: "SV", display: "El Salvador" },
  { code: "GQ", display: "Equatorial Guinea" },
  { code: "ER", display: "Eritrea" },
  { code: "EE", display: "Estonia" },
  { code: "ET", display: "Ethiopia" },
  { code: "FK", display: "Falkland Islands (Malvinas)" },
  { code: "FO", display: "Faroe Islands" },
  { code: "FJ", display: "Fiji" },
  { code: "FI", display: "Finland" },
  { code: "FR", display: "France" },
  { code: "GF", display: "French Guiana" },
  { code: "PF", display: "French Polynesia" },
  { code: "TF", display: "French Southern Territories" },
  { code: "GA", display: "Gabon" },
  { code: "GM", display: "Gambia" },
  { code: "GE", display: "Georgia" },
  { code: "DE", display: "Germany" },
  { code: "GH", display: "Ghana" },
  { code: "GI", display: "Gibraltar" },
  { code: "GR", display: "Greece" },
  { code: "GL", display: "Greenland" },
  { code: "GD", display: "Grenada" },
  { code: "GP", display: "Guadeloupe" },
  { code: "GU", display: "Guam" },
  { code: "GT", display: "Guatemala" },
  { code: "GG", display: "Guernsey" },
  { code: "GN", display: "Guinea" },
  { code: "GW", display: "Guinea-Bissau" },
  { code: "GY", display: "Guyana" },
  { code: "HT", display: "Haiti" },
  { code: "HM", display: "Heard Island and McDonald Islands" },
  { code: "VA", display: "Holy See (Vatican City State)" },
  { code: "HN", display: "Honduras" },
  { code: "HK", display: "Hong Kong" },
  { code: "HU", display: "Hungary" },
  { code: "IS", display: "Iceland" },
  { code: "IN", display: "India" },
  { code: "ID", display: "Indonesia" },
  { code: "IR", display: "Iran, Islamic Republic of" },
  { code: "IQ", display: "Iraq" },
  { code: "IE", display: "Ireland" },
  { code: "IM", display: "Isle of Man" },
  { code: "IL", display: "Israel" },
  { code: "IT", display: "Italy" },
  { code: "JM", display: "Jamaica" },
  { code: "JP", display: "Japan" },
  { code: "JE", display: "Jersey" },
  { code: "JO", display: "Jordan" },
  { code: "KZ", display: "Kazakhstan" },
  { code: "KE", display: "Kenya" },
  { code: "KI", display: "Kiribati" },
  { code: "KP", display: "Korea, Democratic People's Republic of" },
  { code: "KR", display: "Korea, Republic of" },
  { code: "XK", display: "Kosovo" },
  { code: "KW", display: "Kuwait" },
  { code: "KG", display: "Kyrgyzstan" },
  { code: "LA", display: "Lao People's Democratic Republic" },
  { code: "LV", display: "Latvia" },
  { code: "LB", display: "Lebanon" },
  { code: "LS", display: "Lesotho" },
  { code: "LR", display: "Liberia" },
  { code: "LY", display: "Libya" },
  { code: "LI", display: "Liechtenstein" },
  { code: "LT", display: "Lithuania" },
  { code: "LU", display: "Luxembourg" },
  { code: "MO", display: "Macao" },
  { code: "MK", display: "Macedonia, the Former Yugoslav Republic of" },
  { code: "MG", display: "Madagascar" },
  { code: "MW", display: "Malawi" },
  { code: "MY", display: "Malaysia" },
  { code: "MV", display: "Maldives" },
  { code: "ML", display: "Mali" },
  { code: "MT", display: "Malta" },
  { code: "MH", display: "Marshall Islands" },
  { code: "MQ", display: "Martinique" },
  { code: "MR", display: "Mauritania" },
  { code: "MU", display: "Mauritius" },
  { code: "YT", display: "Mayotte" },
  { code: "MX", display: "Mexico" },
  { code: "FM", display: "Micronesia, Federated States of" },
  { code: "MD", display: "Moldova, Republic of" },
  { code: "MC", display: "Monaco" },
  { code: "MN", display: "Mongolia" },
  { code: "ME", display: "Montenegro" },
  { code: "MS", display: "Montserrat" },
  { code: "MA", display: "Morocco" },
  { code: "MZ", display: "Mozambique" },
  { code: "MM", display: "Myanmar" },
  { code: "NA", display: "Namibia" },
  { code: "NR", display: "Nauru" },
  { code: "NP", display: "Nepal" },
  { code: "NL", display: "Netherlands" },
  { code: "NC", display: "New Caledonia" },
  { code: "NZ", display: "New Zealand" },
  { code: "NI", display: "Nicaragua" },
  { code: "NE", display: "Niger" },
  { code: "NG", display: "Nigeria" },
  { code: "NU", display: "Niue" },
  { code: "NF", display: "Norfolk Island" },
  { code: "MP", display: "Northern Mariana Islands" },
  { code: "NO", display: "Norway" },
  { code: "OM", display: "Oman" },
  { code: "PK", display: "Pakistan" },
  { code: "PW", display: "Palau" },
  { code: "PS", display: "Palestine, State of" },
  { code: "PA", display: "Panama" },
  { code: "PG", display: "Papua New Guinea" },
  { code: "PY", display: "Paraguay" },
  { code: "PE", display: "Peru" },
  { code: "PH", display: "Philippines" },
  { code: "PN", display: "Pitcairn" },
  { code: "PL", display: "Poland" },
  { code: "PT", display: "Portugal" },
  { code: "PR", display: "Puerto Rico" },
  { code: "QA", display: "Qatar" },
  { code: "RE", display: "Reunion" },
  { code: "RO", display: "Romania" },
  { code: "RU", display: "Russian Federation" },
  { code: "RW", display: "Rwanda" },
  { code: "BL", display: "Saint Barthelemy" },
  { code: "SH", display: "Saint Helena" },
  { code: "KN", display: "Saint Kitts and Nevis" },
  { code: "LC", display: "Saint Lucia" },
  { code: "MF", display: "Saint Martin (French part)" },
  { code: "PM", display: "Saint Pierre and Miquelon" },
  { code: "VC", display: "Saint Vincent and the Grenadines" },
  { code: "WS", display: "Samoa" },
  { code: "SM", display: "San Marino" },
  { code: "ST", display: "Sao Tome and Principe" },
  { code: "SA", display: "Saudi Arabia" },
  { code: "SN", display: "Senegal" },
  { code: "RS", display: "Serbia" },
  { code: "SC", display: "Seychelles" },
  { code: "SL", display: "Sierra Leone" },
  { code: "SG", display: "Singapore" },
  { code: "SX", display: "Sint Maarten (Dutch part)" },
  { code: "SK", display: "Slovakia" },
  { code: "SI", display: "Slovenia" },
  { code: "SB", display: "Solomon Islands" },
  { code: "SO", display: "Somalia" },
  { code: "ZA", display: "South Africa" },
  {
    code: "GS",
    display: "South Georgia and the South Sandwich Islands",
  },
  { code: "SS", display: "South Sudan" },
  { code: "ES", display: "Spain" },
  { code: "LK", display: "Sri Lanka" },
  { code: "SD", display: "Sudan" },
  { code: "SR", display: "Suriname" },
  { code: "SJ", display: "Svalbard and Jan Mayen" },
  { code: "SZ", display: "Swaziland" },
  { code: "SE", display: "Sweden" },
  { code: "CH", display: "Switzerland" },
  { code: "SY", display: "Syrian Arab Republic" },
  { code: "TW", display: "Taiwan, Province of China" },
  { code: "TJ", display: "Tajikistan" },
  { code: "TH", display: "Thailand" },
  { code: "TL", display: "Timor-Leste" },
  { code: "TG", display: "Togo" },
  { code: "TK", display: "Tokelau" },
  { code: "TO", display: "Tonga" },
  { code: "TT", display: "Trinidad and Tobago" },
  { code: "TN", display: "Tunisia" },
  { code: "TR", display: "Turkey" },
  { code: "TM", display: "Turkmenistan" },
  { code: "TC", display: "Turks and Caicos Islands" },
  { code: "TV", display: "Tuvalu" },
  { code: "VI", display: "US Virgin Islands" },
  { code: "UG", display: "Uganda" },
  { code: "UA", display: "Ukraine" },
  { code: "AE", display: "United Arab Emirates" },
  { code: "GB", display: "United Kingdom" },
  { code: "TZ", display: "United Republic of Tanzania" },
  { code: "US", display: "United States" },
  { code: "UY", display: "Uruguay" },
  { code: "UZ", display: "Uzbekistan" },
  { code: "VU", display: "Vanuatu" },
  { code: "VE", display: "Venezuela" },
  { code: "VN", display: "Vietnam" },
  { code: "WF", display: "Wallis and Futuna" },
  { code: "EH", display: "Western Sahara" },
  { code: "YE", display: "Yemen" },
  { code: "ZM", display: "Zambia" },
  { code: "ZW", display: "Zimbabwe" },
];

export const countries = sortBy(countriesArr, [
  (country) => country.display !== defaultCountryName.defaultCountryAddressName,
  (country) => country.display,
]);

export const daysOfWeek = [
  {
    code: "mon",
    display: "Monday",
  },
  {
    code: "tue",
    display: "Tuesday",
  },
  {
    code: "wed",
    display: "Wednesday",
  },
  {
    code: "thu",
    display: "Thursday",
  },
  {
    code: "fri",
    display: "Friday",
  },
  {
    code: "sat",
    display: "Saturday",
  },
  {
    code: "sun",
    display: "Sunday",
  },
];

export const name = {
  suffixes: [
    { code: "MD", display: "MD" },
    { code: "PHD", display: "PHD" },
    { code: "CNA", display: "CNA" },
    { code: "PHD", display: "PHD" },
    { code: "DVM", display: "DVM" },
    { code: "DC", display: "DC" },
    { code: "DDS", display: "DDS" },
    { code: "DNP", display: "DNP" },
    { code: "OD", display: "OD" },
    { code: "DO", display: "DO" },
    { code: "D.P.M", display: "D.P.M" },
    { code: "EMT", display: "EMT" },
    { code: "NRP", display: "NRP" },
    { code: "RN", display: "RN" },
    { code: "CM", display: "CM" },
    { code: "OT", display: "OT" },
    { code: "PT", display: "PT" },
    { code: "MLS", display: "MLS" },
    { code: "L.ac.", display: "L.ac." },
    { code: "CPT", display: "CPT" },
    { code: "CMA", display: "CMA" },
    { code: "RCP", display: "RCP" },
    { code: "RDH", display: "RDH" },
    { code: "RD", display: "RD" },
    { code: "RDN", display: "RDN" },
    { code: "Jr", display: "Jr" },
    { code: "Sr", display: "Sr" },
    { code: "II", display: "II" },
    { code: "III", display: "III" },
  ].sort((a, b) => {
    return a.display > b.display ? 1 : -1;
  }),

  prefixes: [
    { code: "Dr", display: "Doctor" },
    { code: "Mr", display: "Mister" },
    { code: "Ms", display: "Miss" },
    { code: "Mrs", display: "Mrs" },
    { code: "Adm", display: "Admiral" },
    { code: "Atty", display: "Attorney" },
    { code: "Brother", display: "Brother (religious)" },
    { code: "Capt", display: "Captain " },
    { code: "Chief", display: "Chief" },
    { code: "Cmdr", display: "Commander" },
    { code: "Col", display: "Colonel" },
    { code: "Elder", display: "Elder (religious)" },
    { code: "Father", display: "Father (religious)" },
    { code: "1st Lt", display: "First Lieutenant" },
    { code: "Gen", display: "General" },
    { code: "Gov", display: "Governor" },
    { code: "Hon", display: "Honorable " },
    { code: "Lt Col", display: "Lieutenant Colonel" },
    { code: "Maj", display: "Major" },
    { code: "MSgt", display: "Major/Master Sergeant" },
    { code: "Prince", display: "Prince" },
    { code: "Prof", display: "Professor (includes Assistant and Associate)" },
    { code: "Rabbi", display: "Rabbi (religious)" },
    { code: "Rev", display: "Reverend (religious)" },
    { code: "Sister", display: "Sister" },
    { code: "Dean", display: "University Dean" },
  ].sort((a, b) => {
    return a.display > b.display ? 1 : -1;
  }),
};

export const usStates = [
  { display: "Alabama", code: "AL" },
  { display: "Alaska", code: "AK" },
  { display: "Arizona", code: "AZ" },
  { display: "Arkansas", code: "AR" },
  { display: "California", code: "CA" },
  { display: "Colorado", code: "CO" },
  { display: "Connecticut", code: "CT" },
  { display: "Delaware", code: "DE" },
  { display: "District of Columbia", code: "DC" },
  { display: "Florida", code: "FL" },
  { display: "Georgia", code: "GA" },
  { display: "Hawaii", code: "HI" },
  { display: "Idaho", code: "ID" },
  { display: "Illinois", code: "IL" },
  { display: "Indiana", code: "IN" },
  { display: "Iowa", code: "IA" },
  { display: "Kansas", code: "KS" },
  { display: "Kentucky", code: "KY" },
  { display: "Louisiana", code: "LA" },
  { display: "Maine", code: "ME" },
  { display: "Montana", code: "MT" },
  { display: "Nebraska", code: "NE" },
  { display: "Nevada", code: "NV" },
  { display: "New Hampshire", code: "NH" },
  { display: "New Jersey", code: "NJ" },
  { display: "New Mexico", code: "NM" },
  { display: "New York", code: "NY" },
  { display: "North Carolina", code: "NC" },
  { display: "North Dakota", code: "ND" },
  { display: "Ohio", code: "OH" },
  { display: "Oklahoma", code: "OK" },
  { display: "Oregon", code: "OR" },
  { display: "Maryland", code: "MD" },
  { display: "Massachusetts", code: "MA" },
  { display: "Michigan", code: "MI" },
  { display: "Minnesota", code: "MN" },
  { display: "Mississippi", code: "MS" },
  { display: "Missouri", code: "MO" },
  { display: "Pennsylvania", code: "PA" },
  { display: "Rhode Island", code: "RI" },
  { display: "South Carolina", code: "SC" },
  { display: "South Dakota", code: "SD" },
  { display: "Tennessee", code: "TN" },
  { display: "Texas", code: "TX" },
  { display: "Utah", code: "UT" },
  { display: "Vermont", code: "VT" },
  { display: "Virginia", code: "VA" },
  { display: "Washington", code: "WA" },
  { display: "West Virginia", code: "WV" },
  { display: "Wisconsin", code: "WI" },
  { display: "Wyoming", code: "WY" },
];

export const smokingStatus = [
  { code: "active", display: "Active" },
  { code: "passive", display: "Passive" },
  { code: "quit", display: "Quit" },
  { code: "never", display: "Never" },
];

export const smokingSubstance = [
  { code: "cigarette", display: "Cigarette" },
  { code: "tobacco", display: "Tobacco" },
  { code: "marijuana", display: "Marijuana" },
  { code: "vape", display: "Vape" },
  { code: "other", display: "Other" },
];

export const accommodationType = [
  { code: "apartment", display: "Apartment" },
  { code: "house", display: "House" },
  { code: "bungalow", display: "Bungalow" },
  { code: "nursingHome", display: "Nursing Home" },
  { code: "n/a", display: "Non-applicable/homeless" },
  { code: "other", display: "Other" },
];

export const timeSpans = [
  {
    code: "min",
    display: "Minute(s)",
    definition: "minutes",
  },
  {
    code: "h",
    display: "Hour(s)",
    definition: "hours",
  },
  {
    code: "d",
    display: "Day(s)",
    definition: "days",
  },
  {
    code: "wk",
    display: "Week(s)",
    definition: "weeks",
  },
  {
    code: "mo",
    display: "Month(s)",
    definition: "months",
  },
  {
    code: "a",
    display: "Year(s)",
    definition: "years",
  },
];

export const simpleStatus = [
  { code: "finished", display: "Finished" },
  { code: "entered-in-error", display: "Entered in error", terminal: true },
];
