export const allergyCategories = [
  {
    code: "food",
    display: "Food",
    definition:
      "Any substance consumed to provide nutritional support for the body.",
  },
  {
    code: "medication",
    display: "Medication",
    definition: "Substances administered to achieve a physiological effect.",
  },
  {
    code: "environment",
    display: "Environment",
    definition:
      "Any substances that are encountered in the environment, including any substance not already classified as food, medication, or biologic.",
  },
  {
    code: "biologic",
    display: "Biologic",
    definition:
      "A preparation that is synthesized from living organisms or their products, especially a human or animal protein, such as a hormone or antitoxin, that is used as a diagnostic, preventive, or therapeutic agent. Examples of biologic medications include: vaccines; allergenic extracts, which are used for both diagnosis and treatment (for example, allergy shots); gene therapies; cellular therapies. There are other biologic products, such as tissues, which are not typically associated with allergies.",
  },
];

export const allergyIntoleranceTypes = [
  {
    code: "allergy",
    display: "Allergy",
    definition:
      'A propensity for hypersensitive reaction(s) to a substance. These reactions are most typically type I hypersensitivity, plus other "allergy-like" reactions, including pseudoallergy.',
  },
  {
    code: "intolerance",
    display: "Intolerance",
    definition:
      'A propensity for adverse reactions to a substance that is not judged to be allergic or "allergy-like". These reactions are typically (but not necessarily) non-immune. They are to some degree idiosyncratic and/or patient-specific (i.e. are not a reaction that is expected to occur with most or all patients given similar circumstances).',
  },
];

export const allergyVerificationStatuses = [
  {
    code: "unconfirmed",
    display: "Unconfirmed",
    definition:
      "A low level of certainty about the propensity for a reaction to the identified substance.",
  },
  {
    code: "confirmed",
    display: "Confirmed",
    definition:
      "A high level of certainty about the propensity for a reaction to the identified substance, which may include clinical evidence by testing or rechallenge.",
  },
  {
    code: "refuted",
    display: "Refuted",
    definition:
      "A propensity for a reaction to the identified substance has been disputed or disproven with a sufficient level of clinical certainty to justify invalidating the assertion. This might or might not include testing or rechallenge.",
  },
  {
    code: "entered-in-error",
    display: "Entered in Error",
    definition: "The statement was entered in error and is not valid.",
  },
];

export const allergyCriticalities = [
  {
    code: "low",
    display: "Low Risk",
    definition:
      "Worst case result of a future exposure is not assessed to be life-threatening or having high potential for organ system failure.",
  },
  {
    code: "high",
    display: "High Risk",
    definition:
      "Worst case result of a future exposure is assessed to be life-threatening or having high potential for organ system failure.",
  },
  {
    code: "unable-to-assess",
    display: "Unable to Assess",
    definition:
      "Risk	Unable to assess the worst case result of a future exposure.",
  },
];

export const allergyIntoleranceClinicalStatuses = [
  {
    code: "active",
    display: "Active",
    definition:
      "The subject is currently experiencing, or is at risk of, a reaction to the identified substance.",
  },
  {
    code: "inactive",
    display: "Inactive",
    definition:
      "The subject is no longer at risk of a reaction to the identified substance.",
  },
  {
    code: "resolved",
    display: "Resolved",
    definition:
      "A reaction to the identified substance has been clinically reassessed by testing or re-exposure and is considered no longer to be present. Re-exposure could be accidental, unplanned, or outside of any clinical setting.",
  },
];

export const severityCodes = [
  {
    code: "mild",
    display: "Mild",
    definition: "Causes mild physiological effects.",
  },
  {
    code: "moderate",
    display: "Moderate",
    definition: "Causes moderate physiological effects.",
  },
  {
    code: "severe",
    display: "Severe",
    definition: "Causes severe physiological effects.",
  },
];
