import { FC } from "react";
import { EncounterNotesInputProps } from ".";
import WeightInput from "../../../../pages/health/encounters/cards/vitals/components/WeightInput";
import RespiratoryRateInput from "../../../../pages/health/encounters/cards/vitals/components/RespiratoryRateInput";
import HeartRateInput from "../../../../pages/health/encounters/cards/vitals/components/HeartRateInput";
import TemperatureInput from "../../../../pages/health/encounters/cards/vitals/components/TemperatureInput";
import HeightInput from "../../../../pages/health/encounters/cards/vitals/components/HeightInput";
import OxygenSaturationInput from "../../../../pages/health/encounters/cards/vitals/components/OxygenSaturationInput";
import BloodPressureInput from "../../../../pages/health/encounters/cards/vitals/components/BloodPressureInput";
import BmiInput from "../../../../pages/health/encounters/cards/vitals/components/BmiInput";
import BloodGlucoseInput from "../../../../pages/health/encounters/cards/vitals/components/BloodGlucoseInput";
import { Grid, Typography } from "@mui/material";
import HeadCircumferenceInput from "../../../../pages/health/encounters/cards/vitals/components/HeadCircumferenceInput";

type Props = Omit<EncounterNotesInputProps, "onChange"> & {
  onChange: (value: any, modifier: boolean) => void;
  isModal?: boolean;
};

const VitalsInputForm: FC<Props> = ({
  value,
  patient,
  onChange,
  disabled,
  size,
  isModal,
}) => (
  <Grid item xs={12}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Vitals</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container columns={isModal ? 8 : 10} spacing={1}>
          <Grid item xs={2}>
            <HeightInput
              disabled={disabled}
              value={value?.height ?? ""}
              patient={patient}
              onChange={(newHeight) => {
                onChange({ ...value, height: newHeight }, true);
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <WeightInput
              disabled={disabled}
              patient={patient}
              value={value?.weight ?? ""}
              onChange={(newWeight) => {
                onChange({ ...value, weight: newWeight }, true);
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <BmiInput
              disabled={disabled}
              height={value?.height}
              weight={value?.weight}
              value={value?.bmi ?? ""}
              onChange={(newBmi) => {
                onChange({ ...value, bmi: newBmi }, false);
              }}
              size="small"
            />
          </Grid>

          <Grid item xs={2}>
            <Grid container>
              <TemperatureInput
                disabled={disabled}
                value={value?.temperature ?? ""}
                onChange={(newTemperature) => {
                  onChange({ ...value, temperature: newTemperature }, true);
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <HeadCircumferenceInput
              disabled={disabled}
              value={value?.headCircumference ?? ""}
              size="small"
              onChange={(hc) =>
                onChange({ ...value, headCircumference: hc }, true)
              }
            />
          </Grid>

          <Grid item xs={2}>
            <BloodPressureInput
              disabled={disabled}
              value={value?.bloodPressure ?? ""}
              onChange={(newBloodPressure) => {
                onChange({ ...value, bloodPressure: newBloodPressure }, true);
              }}
              size={size ?? "small"}
              type="modal"
            />
          </Grid>

          <Grid item xs={2}>
            <HeartRateInput
              disabled={disabled}
              value={value?.heartRate ?? ""}
              onChange={(newHeartRate) => {
                onChange({ ...value, heartRate: newHeartRate }, true);
              }}
              size="small"
            />
          </Grid>

          <Grid item xs={2}>
            <RespiratoryRateInput
              disabled={disabled}
              value={value?.respiratoryRate ?? ""}
              onChange={(newRespiratoryRate) => {
                onChange(
                  { ...value, respiratoryRate: newRespiratoryRate },
                  true,
                );
              }}
              size="small"
            />
          </Grid>

          <Grid item xs={2}>
            <Grid container>
              <OxygenSaturationInput
                disabled={disabled}
                value={value?.oxygenSaturation ?? ""}
                onChange={(newOxygenSat) => {
                  onChange({ ...value, oxygenSat: newOxygenSat }, true);
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <BloodGlucoseInput
              disabled={disabled}
              value={value?.bloodGlucose ?? ""}
              onChange={(bgl) => {
                onChange({ ...value, bloodGlucose: bgl }, true);
              }}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default VitalsInputForm;
