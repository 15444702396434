import { FormControl, Grid, TextField } from "@mui/material";
import { set } from "lodash";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { Vital } from "../../../../../../types/Vitals";
import { testRegExp } from "../../../../../../utils/regex";
import { useOrganization } from "../../../../../facility/hooks/useOrganization";
import { updateMetaData } from "../VitalsFormHelper";
import { useMedicalConfig } from "../../../../../../slices/configuration";

const HeightInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = usePractitioner();
  const { vitalMeasurement } = useMedicalConfig();
  const [height, setHeight] = useState<Vital>();
  const currentUnit =
    value?.valueQuantity?.unit ?? vitalMeasurement?.height ?? "cm";

  useEffect(() => {
    if (value) {
      setHeight(
        createDefaultVital(
          encounter,
          patient,
          CodingSystems.OBSERVATION_CODE,
          "8302-2",
          "Body Height",
          value?.valueQuantity?.unit ?? vitalMeasurement?.height ?? "cm",
          Math.round(value),
        ),
      );
      return;
    }
    const ht = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "8302-2",
      "Body Height",
      vitalMeasurement?.height ?? "cm",
    );
    setHeight(ht);
  }, [encounter, patient, value, vitalMeasurement]);

  const centimeterToWholeNumber = (value: number | string) => {
    if (currentUnit === "cm") {
      return value ? Math.round(Number(value)) : "";
    }

    return value ?? "";
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          disabled={disabled}
          value={centimeterToWholeNumber(value?.valueQuantity?.value)}
          onChange={(e) => {
            const newHeightValue = e.target.value;
            const valid = testRegExp(
              currentUnit === "ft"
                ? "non-negative decimal number or empty string"
                : "non-negative integer or empty string",
              newHeightValue,
            );
            if (!valid) return;

            const newHeight = { ...height };
            set(newHeight, "valueQuantity.value", newHeightValue);
            updateMetaData(newHeight, practitionerReference);
            onChange({
              updated: true,
              ...value,
              ...newHeight,
              ...createDefaultVital(
                encounter,
                patient,
                CodingSystems.OBSERVATION_CODE,
                "8302-2",
                "Body Height",
                value?.valueQuantity?.unit ?? vitalMeasurement?.height ?? "cm",
                newHeightValue,
              ),
            });
          }}
          id="vitals-height-feet"
          label={`Height`}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <SelectButton
                label={currentUnit}
                options={["cm", "ft"]}
                variant="text"
                disabled={disabled}
                onSelect={(unit) => {
                  const temp = createDefaultVital(
                    encounter,
                    patient,
                    CodingSystems.OBSERVATION_CODE,
                    "8302-2",
                    "Body Height",
                    unit,
                    value?.valueQuantity?.value,
                  );

                  updateMetaData(temp, practitionerReference);
                  onChange(temp);
                }}
                size={size}
                getOptionLabel={(value) => value}
              />
            ),
          }}
          variant="outlined"
          size={size || "medium"}
        />
      </FormControl>
    </Grid>
  );
};

export default HeightInput;
