export const DefaultEncounterClass = {
  system: "http://terminology.hl7.org/ValueSet/v3-ActEncounterCode",
  code: "AMB",
  display: "Ambulatory",
};

export const DefaultEncounterTriageLevel = {
  system: "http://terminology.hl7.org/CodeSystem/v2-0422",
  code: "5",
  display: "Level 5 – Non-urgent",
};
