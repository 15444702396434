import { set } from "lodash";
import { createContext, useCallback, useState } from "react";
import { OrganizationContact } from "../../../types/organization";

const defaultApi = {
  isOpen: undefined,
  contact: undefined,
  contactsList: undefined,
  setContactsList: (contactsList: Array<OrganizationContact>): void => null,
  openOrgContactModal: (): void => null,
  closeOrgContactModal: (revertData): void => null,
  setOrgContact: (contact: OrganizationContact): void => null,
  updateContact: (field: string, value: any): void => null,
};

export type OrgContactModalValue = typeof defaultApi;

export const OrgContactModalContext =
  createContext<OrgContactModalValue>(defaultApi);

export function OrgContactModalProvider({ children }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const blankContact = {
    purpose: [
      {
        coding: [
          {
            system: "",
            code: "",
            display: "",
          },
        ],
      },
    ],
    name: [
      {
        use: "",
        text: "",
        family: "",
        middleName: "",
        given: "",
        prefix: "",
        suffix: "",
        period: {},
        firstName: "",
      },
    ],
    telecom: [
      {
        system: "phone",
        value: "",
        use: "",
        rank: 0,
        period: {},
      },
    ],
    address: [],
  };
  const [contact, setContact] = useState<OrganizationContact>(blankContact);
  const [currentContact, setCurrentContact] =
    useState<OrganizationContact>(blankContact);
  const [contactsList, setContactsList] = useState<Array<OrganizationContact>>(
    [],
  );
  const openOrgContactModal = useCallback(() => {
    setIsOpen(true);
  }, [isOpen, setIsOpen]);

  const closeOrgContactModal = useCallback(
    (revertData) => {
      setIsOpen(false);
      if (revertData) {
        resetUpdatedContact();
      }
      setContact(blankContact);
    },
    [isOpen, setIsOpen],
  );

  const setOrgContact = (contact) => {
    setContact(contact);
    setCurrentContact(structuredClone(contact));
  };

  const updateContact = (field, value) => {
    const newContact = { ...contact };
    set(newContact, field, value);
    setContact(newContact);
  };

  const resetUpdatedContact = () => {
    set(contact, "name", currentContact.name);
    set(contact, "telecom", currentContact.telecom);
    set(contact, "address", currentContact.address);
  };

  // Return Provider with full API
  const api = {
    isOpen,
    contact,
    contactsList,
    setContactsList,
    openOrgContactModal,
    closeOrgContactModal,
    setOrgContact,
    updateContact,
  };
  return (
    <OrgContactModalContext.Provider value={api}>
      {children}
    </OrgContactModalContext.Provider>
  );
}
