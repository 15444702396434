import axios from "axios";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

export default function AxiosInterceptor() {
  const { logout } = useAuth();

  useEffect(() => {
    const responseInterceptorFunction = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          logout();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptorFunction);
    };
  }, [logout]);

  return null;
}
