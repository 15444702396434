import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import { FC, useEffect, useState } from "react";
import { EncounterNotesInputProps } from ".";
import TypeAheadInput from "../../../form/TypeAheadInput/TypeAheadInput";
import NoKnownInput from "./NoKnownInput";
import useAsyncEffect from "use-async-effect";
import { Allergy } from "../../../../types/allergy";
import { medicalsApi } from "../../../../api/medicalsApi";
import { createCodeableConcept } from "../../../../utils/DataUtils";
import CodingSystems from "../../../../constants/CodingSystems";

interface PatientAllergyInputProps extends EncounterNotesInputProps {
  setLoading?: (value: boolean) => void;
}

export function PatientAllergyInput(props: PatientAllergyInputProps) {
  useAsyncEffect(async () => {
    if (!props?.patient?.identifier) return;

    try {
      if (props.setLoading) props?.setLoading(true);
      const _allergies: Allergy[] = await medicalsApi.findAllergies({
        patient: props.patient.identifier,
        "clinical-status": ["active"],
        "verification-status": ["confirmed", "unconfirmed", "refuted"],
      });

      const codeableConcepts: any[] = [];
      for (const allergy of _allergies) {
        const code =
          allergy?.code?.coding[0]?.code[0]?.code ??
          allergy?.code?.coding[0]?.code;

        if (!code) continue;

        const codeableConcept = createCodeableConcept(
          CodingSystems.ALLERGY_INTOLERANCE_CODE,
          code,
          allergy.code.text ?? "",
        );

        codeableConcepts.push(codeableConcept);
      }

      props.onChange(codeableConcepts, true);
    } catch (error) {
      console.error(error);
    } finally {
      if (props.setLoading) props?.setLoading(false);
    }
  }, [props.patient?.identifier]);

  return <AllergyInput {...props} />;
}

const AllergyInput: FC<EncounterNotesInputProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const [noKnownAllergies, setNoKnownAllergies] = useState(false);
  useEffect(() => {
    setNoKnownAllergies(
      value &&
        value.length === 1 &&
        value[0]?.coding?.some(
          (e) => e?.code === "716186003" || e?.code === 716186003,
        ),
    );
  }, [value]);

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={9}>
        {noKnownAllergies ? (
          <Autocomplete
            disabled={true}
            multiple
            options={[
              createCodeableConcept(
                "http://snomed.info/sct",
                "716186003",
                "No Known Allergy(Situation)",
              ),
            ]}
            value={[
              createCodeableConcept(
                "http://snomed.info/sct",
                "716186003",
                "No Known Allergy(Situation)",
              ),
            ]}
            getOptionLabel={(option) => option.text}
            onChange={(event, newValue) => null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Typography>
                    Allergies{" "}
                    <span style={{ color: "red", fontSize: "1.5em" }}>*</span>
                  </Typography>
                }
                InputLabelProps={{ shrink: true, required: false }}
              />
            )}
          />
        ) : (
          <TypeAheadInput
            freeOptions
            optionType="allergy-common"
            isMultiple={true}
            value={value}
            size={"small"}
            disabled={disabled}
            onChange={(val) => {
              if (val) {
                const newValue = val.map((v) =>
                  createCodeableConcept(
                    CodingSystems.ALLERGY_INTOLERANCE_CODE,
                    v.code,
                    v.display,
                  ),
                );
                onChange(newValue);
              } else {
                onChange([]);
              }
            }}
            fieldLabel="Allergies"
            getValueLabel={(v) => get(v, "display") ?? get(v, "text") ?? v}
            getOptionLabel={(o) => o.display}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <NoKnownInput
          inputLabel="No Known Allergies"
          noKnownValue={noKnownAllergies}
          setNoKnownValue={() => {}}
          disabled={disabled}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
          ) => {
            if (checked) {
              onChange([
                createCodeableConcept(
                  "http://snomed.info/sct",
                  "716186003",
                  "No Known Allergy(Situation)",
                ),
              ]);
            } else {
              onChange([]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AllergyInput;
