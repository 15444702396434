import { Help } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import UserIcon from "../../icons/User";
import { generateHumanDisplayName } from "../../utils/DataTransformationUtils";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [username, setUsername] = useState("");
  const profile = useSelector((state) => get(state, "user.profile", null));

  useEffect(() => {
    if (isAuthenticated && profile) {
      setUsername(generateHumanDisplayName(profile.name[0]));
    }
  }, [isAuthenticated, profile]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={profile?.photo?.at(0)}
          sx={{
            height: 32,
            width: 32,
          }}
        >
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>
            {get(profile, "name[0].firstName") &&
              get(profile, "name[0].firstName")[0]}
            {get(profile, "name[0].family") &&
              get(profile, "name[0].family")[0]}
          </div>
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {username}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/staff/profile"
            onClick={() => handleClose()}
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open("https://medial-health.notion.site/", "_blank");
              handleClose();
              return null;
            }}
          >
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Help Center
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            onClick={() =>
              (window.location.href = "mailto:support@medialhealth.com")
            }
          >
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Email Support
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
