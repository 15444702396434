import { useState } from "react";
import { UnitOption } from "../types/unitOption";
import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { Quantity } from "../types/quantity";

const otherUnits: UnitOption[] = [
  { unit: "mmol/L", converter: (value) => value },
  { unit: "mEq/L", converter: (value) => value },
];

export default function AnionGapComponent() {
  const [values, setValues] = useState<{
    sodium: Quantity;
    chloride: Quantity;
    bicarbonate: Quantity;
  }>({
    sodium: {
      value: undefined,
      unit: otherUnits[0],
    },
    chloride: {
      value: undefined,
      unit: otherUnits[0],
    },
    bicarbonate: {
      value: undefined,
      unit: otherUnits[0],
    },
  });

  const interpretResult = (value: number) => {
    switch (true) {
      case value > 12:
        return "Elevated anion gap.";
      case value >= 6:
        return "Normal anion gap.";
      default:
        return "Descreased anion gap.";
    }
  };

  const calculateAnionGap = (
    sodium: number = 0,
    chloride: number = 0,
    bicarbonate: number = 0,
  ) => sodium - (chloride + bicarbonate);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Sodium"
            placeholder={`Norm: ${
              values.sodium.unit.unit === "mmol/L" ? "136-145" : "136-145"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                sodium: {
                  ...values.sodium,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={otherUnits}
                label={values.sodium.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    sodium: {
                      ...values.sodium,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Chloride"
            placeholder={`Norm: ${
              values.sodium.unit.unit === "mmol/L" ? "98-106" : "98-106"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                chloride: {
                  ...values.chloride,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={otherUnits}
                label={values.chloride.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    chloride: {
                      ...values.chloride,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Bicarbonate"
            placeholder={`Norm: ${
              values.bicarbonate.unit.unit === "mmol/L" ? "23-28" : "23-28"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                bicarbonate: {
                  ...values.bicarbonate,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={otherUnits}
                label={values.bicarbonate.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    bicarbonate: {
                      ...values.bicarbonate,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { bicarbonate, chloride, sodium } = values;
          const valid = [bicarbonate.value, chloride.value, sodium.value].every(
            (e) => Number.isFinite(e),
          );

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const result = calculateAnionGap(
            sodium.value,
            chloride.value,
            bicarbonate.value,
          );
          const resultInterpretation = interpretResult(result);

          return (
            <Typography>
              Anion gap: <b>{result.toFixed(1)}</b> mEq/L (
              {resultInterpretation})
            </Typography>
          );
        })()}
      </Box>
    </Box>
  );
}
