import { get, unionBy } from "lodash";

export const getSimpleVitalValue = (vital) => {
  return get(vital, "valueQuantity.value")
    ? Number(get(vital, "valueQuantity.value"))
    : null;
};

export const getHeightFeetValue = (height) => {
  return get(height, "valueQuantity.value")
    ? Math.floor(Number(get(height, "valueQuantity.value")) / 12)
    : null;
};

export const getHeightMeterValue = (height) => {
  return get(height, "valueQuantity.value")
    ? get(height, "valueQuantity.value") / 39.37
    : null;
};

export const getWeightKg = (weight) => {
  return get(weight, "valueQuantity.value")
    ? Number(get(weight, "valueQuantity.value")) / 2.205
    : null;
};

export const getHeightInchesValue = (height) => {
  return get(height, "valueQuantity.value")
    ? Number(get(height, "valueQuantity.value")) % 12
    : null;
};

export const adjustFeetValue = (height, newFeetValue = 0) => {
  const inches = getHeightInchesValue(height);
  const inchesFromFeet = newFeetValue * 12;
  return inches + inchesFromFeet;
};

export const adjustInchesValue = (height, newInchesValue = 0) => {
  const feet = getHeightFeetValue(height);
  const inchesFromFeet = feet * 12;
  return inchesFromFeet + newInchesValue;
};

export const getBloodPressureDisplay = (bloodPressure) =>
  bloodPressure?.valueQuantity?.value ?? "";

export const createBloodPressureComponent = (
  codeableConcept,
  value,
  unit,
  unitCode,
) => {
  return {
    code: codeableConcept,
    valueQuantity: {
      value: value,
      unit: unit,
      code: unitCode,
    },
  };
};

export const updateMetaData = (vital, participant) => {
  const { performer = [] } = vital;
  vital.updated = true;
  vital.performer = unionBy(performer, [participant], "identifier");
  vital.effectiveDateTime = new Date();
};

export const bmiCheckDisplay = (
  bmi: any,
): { bmiClassification: string; bmiDisplayColour: string } => {
  if (bmi === "Infinity") {
    return { bmiClassification: "Classification", bmiDisplayColour: "black" };
  } else if (bmi < 18.5) {
    return { bmiClassification: "Underweight", bmiDisplayColour: "orange" };
  } else if (bmi >= 18.5 && bmi < 25) {
    return {
      bmiClassification: "Normal",
      bmiDisplayColour: "gray",
    };
  } else if (bmi >= 25 && bmi < 29.9) {
    return { bmiClassification: "Overweight", bmiDisplayColour: "orange" };
  } else if (bmi >= 30 && bmi < 35) {
    return {
      bmiClassification: "Obesity Class I",
      bmiDisplayColour: "orange",
    };
  } else if (bmi >= 35 && bmi < 40) {
    return {
      bmiClassification: "Obesity Class II",
      bmiDisplayColour: "red",
    };
  } else if (bmi > 40) {
    return {
      bmiClassification: "Obesity Class III",
      bmiDisplayColour: "red",
    };
  } else {
    return {
      bmiClassification: "Classification",
      bmiDisplayColour: "success",
    };
  }
};
