import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MedicalConfiguration } from "../types/MedicalConfiguration";
import { AppThunk, useSelector } from "../store";
import { medicalsApi } from "../api/medicalsApi";

interface ConfigurationState {
  medical: MedicalConfiguration;
  medicalConfigurationSucess: boolean;
}

const initialState: ConfigurationState = {
  medical: {
    organization: undefined,
    defaultEncounterType: "full",
    vitalMeasurement: {
      height: "cm",
      weight: "lbs",
      temperature: "celcius",
    },
  },
  medicalConfigurationSucess: undefined,
};

export const useMedicalConfig = () => {
  return useSelector((state) => state.config.medical);
};

const slice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setMedicalConfig(
      state: ConfigurationState,
      action: PayloadAction<MedicalConfiguration>,
    ): void {
      state.medical = action.payload;
      state.medicalConfigurationSucess = true;
    },
    getMedicalConfig(
      state: ConfigurationState,
      action: PayloadAction<MedicalConfiguration>,
    ): void {
      state.medical = action.payload;
      state.medicalConfigurationSucess = true;
    },
    getMedicalConfigFailed(state: ConfigurationState): void {
      state.medicalConfigurationSucess = false;
    },
  },
});

export const setMedicalConfig =
  (data): AppThunk =>
  (dispatch): void => {
    try {
      dispatch(slice.actions.getMedicalConfig(data));
    } catch (e) {
      dispatch(slice.actions.getMedicalConfigFailed);
    }
  };

export const getMedicalConfig =
  (orgId): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      if (orgId) {
        const data = await medicalsApi.getMedicalConfigByOrganization(orgId);

        if (data) {
          dispatch(slice.actions.getMedicalConfig(data));
        } else {
          console.error("no data");
        }
      }
    } catch (e) {
      dispatch(slice.actions.getMedicalConfigFailed);
      dispatch(slice.actions.getMedicalConfig(initialState.medical));
      throw e;
    }
  };

export const { reducer } = slice;

export default slice;
