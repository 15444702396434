/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import type { FC, KeyboardEvent } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { get, startCase } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "../../icons/Search";
import XIcon from "../../icons/X";
import Scrollbar from "../Scrollbar";
import { patientApi } from "../../api/patientApi";
import { generateHumanDisplayName } from "../../utils/DataTransformationUtils";
import { useOrganizationReference } from "../../pages/facility/hooks/useOrganization";

const ContentSearch: FC = () => {
  const [value, setValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [results, setResults] = useState({ patients: [] });
  const [hasResults, setHasResults] = useState<boolean>(true);
  const [patientName, setPatientName] = useState({});
  const organizationReference = useOrganizationReference();

  const navigate = useNavigate();

  useEffect(() => {
    if (!open) {
      setValue("");
      setResults({ patients: [] });
    }
  }, [open]);

  useEffect(() => {
    let name = {};

    if (value) {
      const trimmedAndSplitName: string[] = value.trim().split(" ");

      if (trimmedAndSplitName.length > 2) {
        name = {
          firstName: trimmedAndSplitName[0],
          middleName: trimmedAndSplitName[1],
          family: trimmedAndSplitName[2],
        };
      } else {
        name = {
          firstName: trimmedAndSplitName[0],
          family: trimmedAndSplitName[1],
        };
      }
    }
    setPatientName(name);
  }, [value]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setHasResults(true);
    setResults({ patients: [] });
  };

  const search = async (): Promise<void> => {
    try {
      if (!organizationReference?.identifier) {
        setResults({ patients: [] });
        return;
      }

      setResults({ patients: [] });
      setIsLoading(true);
      // for now we are doing patient searh only
      const patients = await patientApi.findPatientsByOrg({
        "managing-organization": organizationReference?.identifier,
        q: value,
      });
      if (patients && patients.length > 0) {
        setResults({ patients });
        setHasResults(true);
      } else {
        setHasResults(false);
      }
    } catch (e) {
      console.error("Error while searching ", e);
    } finally {
      setIsLoading(false);
      setShowResults(true);
    }
  };

  const handleClick = (): void => {
    search();
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "ENTER") {
      search();
    }
  };

  const createNewPatient = async () => {
    navigate(`/patients/new`, { state: { name: [patientName] } });

    handleClose();
    setShowResults(false);
    setIsLoading(false);
  };

  const createSearchNameDisplay = () => {
    const keys = Object.keys(patientName);
    if (keys.length > 0) {
      return (
        <div style={{ paddingLeft: 50 }}>
          {keys.map((k, i) => {
            if (patientName[k]) {
              return (
                <Typography key={`${k}-${i}`}>
                  {k === "family" ? "Last Name: " : startCase(k)}:{" "}
                  {patientName[k] ? startCase(patientName[k]) : ""}
                </Typography>
              );
            }
            return <></>;
          })}
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen}>
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: "100%" },
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event): void => {
                  setHasResults(true);
                  setValue(event.target.value);
                }}
                onKeyUp={handleKeyUp}
                placeholder="Search..."
                value={value}
              />
              <Button
                color="primary"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                variant="contained"
              >
                Search
              </Button>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Scrollbar options={{ suppressScrollX: true }}>
                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {showResults &&
                      results.patients &&
                      results.patients.length > 0 && (
                        <>
                          {results.patients.map((patient, i) => (
                            <Box key={i} sx={{ mb: 2 }}>
                              <Link
                                color="textPrimary"
                                component={RouterLink}
                                to={`/patients/${patient.id}`}
                                variant="h5"
                                onClick={handleClose}
                              >
                                {`${generateHumanDisplayName(patient.name[0])}${
                                  patient.patientId
                                    ? ` - ${patient.patientId}`
                                    : ""
                                }`}
                              </Link>
                              <Grid container spacing={2}>
                                {patient.gender ? (
                                  <Grid item md={2} sm={12}>
                                    <Typography
                                      color="textPrimary"
                                      variant="body2"
                                    >
                                      {" "}
                                      {get(patient, "gender")
                                        ? `Gender: ${startCase(
                                            get(patient, "gender"),
                                          )}`
                                        : ""}
                                    </Typography>
                                  </Grid>
                                ) : null}
                                {patient.birthDate ? (
                                  <Grid item md={2} sm={12}>
                                    <Typography
                                      color="textPrimary"
                                      variant="body2"
                                    >
                                      {patient.birthDate
                                        ? `D.O.B: ${format(
                                            parseISO(patient.birthDate),
                                            "dd/MMM/yyyy",
                                          )}`
                                        : ""}
                                    </Typography>
                                  </Grid>
                                ) : null}
                                {get(patient, "address[0].line") ? (
                                  <Grid item md={3} sm={12}>
                                    <Typography
                                      color="textPrimary"
                                      variant="body2"
                                    >
                                      {`Address: ${get(
                                        patient,
                                        "address[0].line",
                                      )}`}
                                    </Typography>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Box>
                          ))}
                        </>
                      )}
                    <>
                      {showResults && !hasResults && (
                        <Box>
                          <Link
                            color="textPrimary"
                            variant="h6"
                            onClick={createNewPatient}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography variant="h6">
                              Create a new patient with the following
                              information:
                            </Typography>
                            {createSearchNameDisplay()}
                          </Link>
                        </Box>
                      )}
                    </>
                  </>
                )}
              </Scrollbar>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
