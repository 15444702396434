import React, { useContext, useEffect, useState } from "react";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
/* !!! DO NOT install types for the following package. For some reason, a couple of the !!!
 * !!! values returned by the useSpeechRecognition hook are not present.                !!!
 */
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { getRandomInt } from "../../pages/health/encounters/utils";
import { DictactionContext } from "../../contexts/DictationProvider";
import { Mic, MicOff } from "@mui/icons-material";

type DictationProps = TextFieldProps & {
  onTextChange: (text: string) => void;
};

type UseSpeechRecognitionReturnType = {
  transcript: string;
  interimTranscript: string;
  finalTranscript: string;
  listening: boolean;
  isMicrophoneAvailable: boolean;
  browserSupportsSpeechRecognition: boolean;
  browserSupportsContinuousListening: boolean;
  resetTranscript: () => void;
};

function DictationControl(userProps: DictationProps) {
  const {
    transcript,
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
    browserSupportsContinuousListening,
  }: UseSpeechRecognitionReturnType = useSpeechRecognition();

  const [key] = useState(userProps.key || `${getRandomInt()}`);
  const [fieldDict] = useState(userProps.name);
  const [isActive, setIsActive] = useState(false);

  const { activeKey, setActiveKey } = useContext(DictactionContext);

  const startListening = (e) => {
    resetTranscript();
    setActiveKey(key);
    setIsActive(true);
    browserSupportsContinuousListening
      ? SpeechRecognition.startListening({ continuous: true })
      : SpeechRecognition.startListening();
  };

  const stopListening = async () => {
    SpeechRecognition.stopListening();
    setIsActive(false);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldDict === userProps.name) {
      userProps?.onTextChange(event.target.value);
    }
  };

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.ctrlKey && event.target.id) {
        if (event.code === "Space") startListening(event);
      }
    };

    const onKeyUp = (event) => {
      stopListening();
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!listening && finalTranscript && activeKey === key) {
      if (fieldDict === userProps.name) {
        userProps?.onTextChange(
          userProps?.value
            ? `${userProps?.value} ${finalTranscript}`
            : finalTranscript,
        );
      }

      setActiveKey(undefined);
    }
  }, [listening, finalTranscript, userProps?.value]);

  if (!browserSupportsSpeechRecognition) {
    return (
      <TextField
        {...userProps}
        multiline
        onChange={handleTextChange}
        label={userProps.label}
        required={userProps.required}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <TextField
      {...userProps}
      value={isActive ? `${userProps.value} ${transcript}` : userProps?.value}
      multiline
      onChange={handleTextChange}
      label={userProps.label}
      required={userProps.required}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-hidden="true"
              aria-label="toggle password visibility"
              name={userProps?.name}
              onTouchStart={(e: React.SyntheticEvent) => startListening(e)}
              onMouseDown={(e) => startListening(e)}
              onTouchEnd={stopListening}
              onMouseUp={stopListening}
              edge="end"
              disabled={userProps.disabled}
            >
              {!isActive ? <MicOff /> : <Mic color="error" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DictationControl;
