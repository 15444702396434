import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { useState } from "react";

type Props<T> = {
  startIcon?: React.ReactNode;
  disabled?: boolean;
  label: string;
  variant?: "text" | "contained" | "outlined";
  size?: "small" | "medium";
  options: T[];
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  onSelect: (value: T) => void;
  getOptionLabel: (value: T) => string;
};

export default function SelectButton<T>({
  disabled = false,
  label,
  variant = "contained",
  size = "medium",
  options,
  onSelect,
  getOptionLabel,
  sx,
  fullWidth = false,
  startIcon,
}: Props<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        fullWidth={fullWidth}
        type="button"
        disabled={disabled}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={variant}
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        startIcon={startIcon}
        size={size}
        sx={
          size === "small"
            ? {
                ...{ margin: 0, padding: 0 },
                ...sx,
              }
            : sx
        }
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((e) => (
          <MenuItem
            key={getOptionLabel(e)}
            onClick={() => {
              onSelect(e);
              handleClose();
            }}
          >
            {getOptionLabel(e)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
