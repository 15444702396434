/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState } from "react";

const defaultApi = {
  registerCallback: (
    key: string,
    callback: (...args: unknown[]) => Promise<void> | void,
  ): void => {
    throw new Error("registerCallback not implemented");
  },
  executeCallback: (key: string, ...args: unknown[]): Promise<void> | void => {
    throw new Error("executeCallback not implemented");
  },
};

// Create a context to hold the callback functions
export const ExecutorContext = createContext<typeof defaultApi>(defaultApi);

// Executor Provider component
export const ExecutorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [callbacks, setCallbacks] = useState({});

  // Register a callback function with a specific key
  const registerCallback = (key, callback) => {
    setCallbacks((prevCallbacks) => ({
      ...prevCallbacks,
      [key]: callback,
    }));
  };

  // Execute a callback function using the specified key
  const executeCallback = (key, ...args: unknown[]) => {
    const callback = callbacks[key];
    if (callback) {
      return callback(...args);
    } else {
      console.error(`Callback with key '${key}' not found.`);
    }
  };

  return (
    <ExecutorContext.Provider value={{ registerCallback, executeCallback }}>
      {children}
    </ExecutorContext.Provider>
  );
};
