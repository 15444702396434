import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Restriction } from "../../../types/restriction";
import Restrictions from "../components/restrictions";
import { Box } from "@mui/material";
import FormHeader from "../../../components/FormHeader/FormHeader";

export interface BaseFormInterface {
  title: string;
  subHeader?: React.ReactNode;
  headerBgColor?: string;
  actionsBgColor?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  isLoading: boolean;
  contentPaddingLeft?: any;
  disableHeader?: boolean;
  contentPaddingRight?: any;
  contentPaddingTop?: any;
  contentPaddingBottom?: any;
  restriction?: Restriction;
  disabled?: boolean;
  onClose: () => void;
}

/**
 * This component is a wrapper for forms that are used in the encounter pages.
 * It provides a header, footer actions, and a loading spinner.
 * @param props  BaseFormInterface
 * @returns ReactElement
 * @constructor
 */
const BaseForm: FC<BaseFormInterface> = ({
  title,
  subHeader,
  headerBgColor,
  isLoading,
  actions,
  children,
  onClose,
  disableHeader,
  restriction,
  disabled,
}) => {
  const { allowRestrictions } = useFlags();
  const topBarRef = useRef(null);
  const bottomBarRef = useRef(null);

  const [topHeight, setTopHeight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);

  // Used to set the top and bottom of the BaseForm body area
  useLayoutEffect(() => {
    if (topBarRef.current) {
      setTopHeight(topBarRef.current.clientHeight);
    }
    if (bottomBarRef.current) {
      setBottomHeight(bottomBarRef.current.clientHeight);
    }
  }, []);

  return (
    <Box
      position="absolute"
      height="100%"
      top={0}
      bottom={0}
      right={0}
      left={0}
    >
      <Box
        ref={topBarRef}
        position="absolute"
        left={0}
        right={0}
        top={0}
        zIndex={10}
        bgcolor={headerBgColor ?? "background.default"}
        display={disableHeader ? "none" : "block"}
      >
        <Box
          px={4}
          py={1}
          sx={{
            borderBottom: 1,
            borderColor: "#D3D3D3",
          }}
        >
          <FormHeader
            title={title}
            onClose={onClose}
            disableActions={isLoading}
          />
        </Box>
        {subHeader && (
          <Box
            position="sticky"
            sx={{
              borderBottom: 1,
              borderColor: "#D3D3D3",
            }}
          >
            {subHeader}
          </Box>
        )}
      </Box>

      <Box
        width="100%"
        position="absolute"
        height={`calc(100% - (${topHeight}px + ${bottomHeight}px))`}
        mt={"0px"}
        top={topHeight}
        bottom={0}
        sx={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <>{children}</>
        <>
          {allowRestrictions && restriction && (
            <Restrictions restriction={restriction} disabled={disabled} />
          )}
        </>
      </Box>
      {actions && (
        <Box
          ref={bottomBarRef}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          px={4}
          py={1}
          zIndex={10}
          bgcolor={headerBgColor ?? "background.default"}
          sx={{
            borderTop: 1,
            borderColor: "#D3D3D3",
          }}
        >
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            py={2}
          >
            {actions}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BaseForm;
