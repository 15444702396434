import { FC, useEffect, useState } from "react";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import { createDefaultVital } from ".";
import CodingSystems from "../../../../../../constants/CodingSystems";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { getSimpleVitalValue, updateMetaData } from "../VitalsFormHelper";
import { get, set } from "lodash";
import usePractitioner from "../../../../../../hooks/usePractitioner";

const RespiratoryRateInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = usePractitioner();
  const [respiratoryRate, setRespiratoryRate] = useState<any>();

  useEffect(() => {
    if (value) {
      setRespiratoryRate(value);
    } else {
      const rr = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "9279-1",
        "Respiratory Rate",
        "/min",
      );
      setRespiratoryRate(rr);
    }
  }, [encounter, patient, value]);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          value={getSimpleVitalValue(respiratoryRate) || ""}
          disabled={disabled}
          onChange={(e) => {
            let val;
            if (e.target.value) {
              val = Number(e.target.value);
            } else {
              val = null;
            }
            const newVital = { ...respiratoryRate };
            set(newVital, "valueQuantity.value", val);
            updateMetaData(newVital, practitionerReference);
            onChange(newVital);
          }}
          id="vitals-respiratory-rate"
          label={size === "small" ? `RR` : `Respiratory Rate `}
          autoComplete="off"
          inputProps={{ inputProps: { type: "number" } }}
          InputProps={{
            endAdornment: (
              <Typography pl={1} variant="body2">
                {get(respiratoryRate, "valueQuantity.unit")}
              </Typography>
            ),
          }}
          variant="outlined"
          size={size || "medium"}
        />
      </FormControl>
    </Grid>
  );
};

export default RespiratoryRateInput;
