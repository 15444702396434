import { useState } from "react";

export default function useComponentLoader(inital?: boolean) {
  const [loading, setLoading] = useState<boolean>(inital ?? false);

  return [
    loading,
    (val: boolean) => {
      setLoading(val);
    },
  ] as const;
}
