import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Age ≥ 50 years",
    code: "age",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "HR ≥ 100",
    code: "hr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "SpO₂ on room air < 95%",
    code: "spo2",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Unilateral leg swelling",
    code: "unilateral-leg-swelling",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Hemoptysis",
    code: "hemoptysis",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Liable INR",
    subtitle: "Unstable/high INRs, time in therapeutic range <60%",
    code: "liable-inr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Recent surgery or trauma",
    subtitle:
      "Surgery or trauma ≤4 weeks ago requiring treatment with general anesthesia",
    code: "surgery",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Prior PE or DVT",
    code: "prior-pe-or-dvt",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Hormone use",
    subtitle:
      "Oral contraceptives, hormone replacement or estrogenic hormones use in males or female patients",
    code: "hormone-use",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function PERCPulmonaryEmbolismComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    return value === 0
      ? "Pulmonary embolism CAN be ruled out"
      : "Pulmonary embolism CAN NOT be ruled out";
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
