import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { useState } from "react";
import { UnitOption } from "../types/unitOption";
import { Optional } from "../types/optional";
import { Quantity } from "../types/quantity";

// 1 Micromole per Liter = 0.0113096584483149 Milligram per Deciliter
// Source : https://www.unitsconverters.com/en/Micromoleperliter-To-Milligramperdeciliter/Unittounit-6021-6020
const bilirubinUnits: UnitOption[] = [
  {
    unit: "μmol/L",
    converter: (value: number) => value / 17.1,
  },
  { unit: "mg/dL", converter: (value: number) => value },
];

export default function MaddreyDiscriminantComponent() {
  const [values, setValues] = useState<{
    pt: Optional<number>;
    ptControl: Optional<number>;
    bilirubin: Quantity;
  }>({
    pt: undefined,
    ptControl: undefined,
    bilirubin: {
      value: undefined,
      unit: bilirubinUnits[0],
    },
  });

  // pt => sec
  // ptControl => sec
  // bilirubin => mg/dL
  const calculateMaddreysDiscriminant = (
    pt: number = 0,
    ptControl: number = 0,
    bilirubin: number = 0,
  ) => 4.6 * (pt - ptControl) + bilirubin;

  const interpretDiscriminant = (value: number) => {
    if (value > 32) {
      return "Poor prognosis, patient may benefit from glucocorticoid therapy";
    }

    return "Good prognosis";
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="PT"
            placeholder="Norm: 11-13"
            endAdornment={<Typography>sec</Typography>}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                pt: float,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="PT control/reference level"
            placeholder="Norm: 11-13"
            endAdornment={<Typography>sec</Typography>}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                ptControl: float,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Total bilirubin"
            placeholder={`Norm: ${
              values.bilirubin.unit.unit === "μmol/L" ? "5.13-32.49" : "0.3-1.9"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                bilirubin: {
                  ...values.bilirubin,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={bilirubinUnits}
                label={values.bilirubin.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    bilirubin: {
                      ...values.bilirubin,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { pt, ptControl, bilirubin } = values;
          const valid = [pt, ptControl, bilirubin.value].every((e) =>
            Number.isFinite(e),
          );

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const discriminant = calculateMaddreysDiscriminant(
            pt,
            ptControl,
            bilirubin.unit.converter(values.bilirubin.value ?? 0),
          );
          const discriminantText = interpretDiscriminant(discriminant);

          return (
            <Typography>
              {discriminant.toFixed(2)} points <b>({discriminantText})</b>
            </Typography>
          );
        })()}
      </Box>
    </Box>
  );
}
