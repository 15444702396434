import { Box, Grid, TextField, Typography } from "@mui/material";
import { CalculatorTextFieldItemProps } from "../types/calculatorTextFieldItemProps";

export const CalculatorTextFieldItem = ({
  text,
  value,
  placeholder,
  endAdornment,
  onChange,
}: CalculatorTextFieldItemProps) => (
  <Grid item display="flex" alignItems="center" gap={2} width="100%">
    <Typography flex={3}>{text}</Typography>
    <Box flex={9} display="flex" justifyContent="flex-end">
      <TextField
        value={value}
        placeholder={placeholder}
        type="number"
        InputProps={{
          endAdornment,
        }}
        onChange={onChange}
      />
    </Box>
  </Grid>
);
