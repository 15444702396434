import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Level of consciousness",
    code: "loc",
    options: [
      {
        display: "Alert; keenly responsive",
        code: nanoid(),
        result: 0,
        default: true,
      },
      {
        display: "Not alert, but arousable by minor stimulation",
        code: nanoid(),
        result: 1,
      },
      {
        display: "Not alert, requires repeated stimulation",
        code: nanoid(),
        result: 2,
      },
      {
        display: "Unresponsive or responds only with reflex",
        code: nanoid(),
        result: 3,
      },
    ],
  },
  {
    title: "Level of consciousness questions:",
    subtitle: "What is the month?; What is your age?",
    code: "loc-questions",
    options: [
      {
        display: "Answers two questions correctly",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Answers one question correctly", code: nanoid(), result: 1 },
      {
        display: "Answers neither questions correctly",
        code: nanoid(),
        result: 2,
      },
    ],
  },
  {
    title: "Level of consciousness commands:",
    subtitle: "Open and close your eyes; Grip and release your hand",
    code: "loc-commands",
    options: [
      {
        display: "Performs both tasks correctly",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Performs one task correctly", code: nanoid(), result: 1 },
      { display: "Performs neither task correctly", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Best gaze",
    code: "bg",
    options: [
      { display: "Normal", code: nanoid(), result: 0, default: true },
      { display: "Partial gaze palsy", code: nanoid(), result: 1 },
      { display: "Forced deviation", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Visual",
    code: "visual",
    options: [
      { display: "No visual loss", code: nanoid(), result: 0, default: true },
      { display: "Partial hemianopia", code: nanoid(), result: 1 },
      { display: "Complete hemianopia", code: nanoid(), result: 2 },
      { display: "Bilateral hemianopia", code: nanoid(), result: 3 },
    ],
  },
  {
    title: "Facial palsy",
    code: "facial-palsy",
    options: [
      {
        display: "Normal symmetric movements",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Minor paralysis", code: nanoid(), result: 1 },
      { display: "Partial paralysis", code: nanoid(), result: 2 },
      {
        display: "Complete paralysis of one or both sides",
        code: nanoid(),
        result: 3,
      },
    ],
  },
  {
    title: "Motor arm (left)",
    code: "motor-arm-left",
    options: [
      { display: "No drift", code: nanoid(), result: 0, default: true },
      { display: "Drift", code: nanoid(), result: 1 },
      { display: "Some effort against gravity", code: nanoid(), result: 2 },
      {
        display: "No effort against gravity; limb falls",
        code: nanoid(),
        result: 3,
      },
      { display: "No movement", code: nanoid(), result: 4 },
    ],
  },
  {
    title: "Motor arm (right)",
    code: "motor-arm-right",
    options: [
      { display: "No drift", code: nanoid(), result: 0, default: true },
      { display: "Drift", code: nanoid(), result: 1 },
      { display: "Some effort against gravity", code: nanoid(), result: 2 },
      {
        display: "No effort against gravity; limb falls",
        code: nanoid(),
        result: 3,
      },
      { display: "No movement", code: nanoid(), result: 4 },
    ],
  },
  {
    title: "Motor leg (left)",
    code: "motor-leg-left",
    options: [
      { display: "No drift", code: nanoid(), result: 0, default: true },
      { display: "Drift", code: nanoid(), result: 1 },
      { display: "Some effort against gravity", code: nanoid(), result: 2 },
      {
        display: "No effort against gravity",
        code: nanoid(),
        result: 3,
      },
      { display: "No movement", code: nanoid(), result: 4 },
    ],
  },
  {
    title: "Motor leg (right)",
    code: "motor-leg-right",
    options: [
      { display: "No drift", code: nanoid(), result: 0, default: true },
      { display: "Drift", code: nanoid(), result: 1 },
      { display: "Some effort against gravity", code: nanoid(), result: 2 },
      {
        display: "No effort against gravity",
        code: nanoid(),
        result: 3,
      },
      { display: "No movement", code: nanoid(), result: 4 },
    ],
  },
  {
    title: "Limb ataxia",
    code: "limb-ataxia",
    options: [
      { display: "Absent", code: nanoid(), result: 0, default: true },
      { display: "Present in one limb", code: nanoid(), result: 1 },
      { display: "Present in two limbs", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Sensory",
    code: "sensory",
    options: [
      {
        display: "Normal; no sensory loss",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Mild-to-moderate sensory loss", code: nanoid(), result: 1 },
      { display: "Severe to total sensory loss", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Best language",
    code: "best-language",
    options: [
      {
        display: "No aphasia; normal",
        code: nanoid(),
        result: 0,
        default: true,
      },
      { display: "Mild to moderate aphasia", code: nanoid(), result: 1 },
      { display: "Severe aphasia", code: nanoid(), result: 2 },
      { display: "Mute, global aphasia", code: nanoid(), result: 3 },
    ],
  },
  {
    title: "Dysarthria",
    code: "dysarthria",
    options: [
      { display: "Normal", code: nanoid(), result: 0, default: true },
      { display: "Mild to moderate dysarthria", code: nanoid(), result: 1 },
      { display: "Severe dysarthria", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Extinction and inattention",
    code: "extinction-and-inattention",
    options: [
      { display: "No abnormality", code: nanoid(), result: 0, default: true },
      {
        display: "Visual, tactile, auditory, spatial, or personal inattention",
        code: nanoid(),
        result: 1,
      },
      {
        display: "Profound hemi-inattention or extinction",
        code: nanoid(),
        result: 2,
      },
    ],
  },
];

export default function NIHStrokeComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (true) {
      case value >= 21:
        return "Severe stroke";
      case value >= 15:
        return "Moderate to severe stroke";
      case value >= 5:
        return "Moderate stroke";
      case value >= 1:
        return "Minor stroke";
      default:
        return "No stroke";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
