import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  InputAdornment,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  isTypeCodeableConcept,
  isTypeCoding,
} from "../../../../types/codeableConcept";
import { createSlug } from "../../../../utils/createSlug";

type Props = {
  disabled?: boolean;
  freeOptions?: boolean;
  onItemSelection: (val: any, reason: string) => void;
  value?: any;
  options: any[];
  optionType: string;
  loading?: boolean;
  fieldLabel: string;
  required?: boolean;
  size?: "small" | "medium";
  variant?: "standard" | "filled" | "outlined";
  getOptionValue?: (a: any) => void;
  setInputValue: (value: string) => void;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  disableCloseOnSelect?: boolean;
  getOptionLabel;
};

function SingleInput({
  disabled,
  onItemSelection,
  value,
  options,
  optionType,
  loading,
  fieldLabel,
  required,
  size,
  variant,
  getOptionValue,
  setInputValue,
  isOptionEqualToValue,
  disableCloseOnSelect,
  getOptionLabel,
  freeOptions = false,
}: Props) {
  const [isInitialValuePresent, setIsInitialValuePresent] = useState(false);

  useEffect(() => {
    if (value && options && options.length > 0 && !isInitialValuePresent) {
      const convertedValue = createValue(value, options, optionType);
      setComponentValue(convertedValue);
      setIsInitialValuePresent(true);
    }
  }, [value, options]);

  const isTypeMedication = (value) => value && value.type === "medications";

  const createValue = (value, options, optionType) => {
    if (!isEmpty(value) && options && options.length > 0) {
      if (optionType === "snomed") {
        const option = options.find((o) =>
          isTypeCodeableConcept(value)
            ? o.snomedCID === value.coding[0].code
            : o.snomedCID === value.code,
        );
        return option || value;
      }
      const option = options.find((o) =>
        isTypeCodeableConcept(value)
          ? o.code === value.coding[0].code
          : isTypeCoding(value)
            ? o.code === value.code
            : isTypeMedication(value)
              ? o.medicationCode === value.identifier
              : o.code === value.identifier,
      );
      return option ?? value;
    }
    return undefined;
  };

  const [componentValue, setComponentValue] = useState<any>(
    value ? createValue(value, options, optionType) : "",
  );

  const checkIfInputHasValue = (value) => {
    const VALUE_IS_REQUIRED = true;
    if (value) return value.length === 0;
    return VALUE_IS_REQUIRED;
  };

  const selectInputValue = (val, reason) => {
    if (val) {
      const v = getOptionValue ? getOptionValue(val) : val;
      onItemSelection(val, reason);
      setComponentValue(v);
    } else {
      onItemSelection(undefined, reason);
      setComponentValue(null); // not sure if this is needed
    }
  };

  const getLabel = (option) => {
    if (getOptionLabel) {
      return getOptionLabel(option) || option.display || option.text || "";
    }
    if (optionType === "snomed") {
      return option.snomedFSN;
    }
    return option.display || "";
  };

  const cleanFreeOptions = (
    val,
    reason: AutocompleteChangeReason,
    detail: AutocompleteChangeDetails<any>,
  ) => {
    if (!val) return undefined;

    const display =
      optionType === "snomed" ? detail.option.snomedFSN : detail.option.display;
    const code =
      optionType === "snomed" ? detail.option.snomedCID : detail.option.code;

    if (!val) return val;
    if (!freeOptions) return val;
    if (reason !== "selectOption") return val;
    if (!code || !`${code}`.startsWith("other")) return val;

    const newDisplay = display.match(/"([^"]+)"/)[1]; // get text between quotes
    const newDetail =
      optionType === "snomed"
        ? {
            snomedCID: code,
            snomedFSN: newDisplay,
          }
        : {
            code,
            display: newDisplay,
          };

    return newDetail;
  };

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={getLabel}
      autoHighlight={freeOptions}
      onChange={(e, val, reason, detail) => {
        const valueArray = cleanFreeOptions(val, reason, detail);
        selectInputValue(valueArray, reason);
      }}
      value={componentValue || null}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={(event, newInput) =>
        newInput && newInput !== "undefined" && newInput !== "null"
          ? setInputValue(isEmpty(newInput.trim()) ? "" : newInput.trim())
          : setInputValue(" ")
      }
      renderInput={(params) => (
        <TextField
          {...{
            ...params,
            ...(loading
              ? {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={20} thickness={6} />
                      </InputAdornment>
                    ),
                  },
                }
              : null),
          }}
          label={fieldLabel}
          required={required}
          variant={variant || "outlined"}
          size={size || "medium"}
          InputLabelProps={{ shrink: true }}
        />
      )}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);
        const { inputValue } = params;

        const exists = options.some(
          (o) => getLabel(o).toLowerCase() === inputValue.toLowerCase(),
        );

        if (inputValue === "") return filtered;
        if (exists) return filtered;
        if (!freeOptions) return filtered;

        const newOption =
          optionType === "snomed"
            ? {
                snomedCID: createSlug(inputValue, "other"),
                snomedFSN: `Add "${inputValue}"`,
              }
            : {
                code: createSlug(inputValue, "other"),
                display: `Add "${inputValue}"`,
              };

        filtered.push(newOption);

        return filtered;
      }}
      id="tags-standard"
      clearOnBlur={false}
      disableCloseOnSelect={disableCloseOnSelect}
    />
  );
}

export default SingleInput;
