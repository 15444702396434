import { useContext } from "react";
import { FullPageLoaderContext } from "../contexts/FullPageLoaderProvider";

const useFullPageLoader = () => {
  const { fullPageLoader, setFullPageLoader } = useContext(
    FullPageLoaderContext,
  );

  return [fullPageLoader, setFullPageLoader] as const;
};

export default useFullPageLoader;
