import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { UnitOption } from "../types/unitOption";
import { useState } from "react";
import Choice from "../components/Choice";
import { CalculatorOption } from "../types/calculatorOption";
import { Quantity } from "../types/quantity";
import { Optional } from "../types/optional";

const hemoglobinUnits: UnitOption[] = [
  {
    // default
    unit: "g/L",
    converter(value) {
      return value;
    },
  },
  {
    unit: "g/dL",
    converter(value) {
      return value * 10;
    },
  },
];
const bunUnits: UnitOption[] = [
  {
    // default
    unit: "mmol/L",
    converter(value) {
      return value;
    },
  },
  {
    unit: "mg/dL",
    converter(value) {
      return value * 0.3571;
    },
  },
];
const sexOptions: CalculatorOption[] = [
  {
    display: "Female",
    code: "female",
    result: 0,
  },
  {
    display: "Male",
    code: "male",
    result: 1,
  },
];
const heartRateOver100Options: CalculatorOption[] = [
  {
    display: "No",
    code: "no",
    result: 0,
    default: true,
  },
  {
    display: "Yes",
    code: "yes",
    result: 1,
  },
];
const melenaPresentOptions: CalculatorOption[] = [
  {
    display: "No",
    code: "no",
    result: 0,
    default: true,
  },
  {
    display: "Yes",
    code: "yes",
    result: 1,
  },
];
const recentSyncopeOptions: CalculatorOption[] = [
  {
    display: "No",
    code: "no",
    result: 0,
    default: true,
  },
  {
    display: "Yes",
    code: "yes",
    result: 2,
  },
];
const hepaticDiseaseHistoryOptions: CalculatorOption[] = [
  {
    display: "No",
    code: "no",
    result: 0,
    default: true,
  },
  {
    display: "Yes",
    code: "yes",
    result: 2,
  },
];
const cardiacFailurePresentOptions: CalculatorOption[] = [
  {
    display: "No",
    code: "no",
    result: 0,
    default: true,
  },
  {
    display: "Yes",
    code: "yes",
    result: 2,
  },
];

export default function GlasgowBatchfordBleedingComponent() {
  const [values, setValues] = useState<{
    hemoglobin: Quantity;
    bun: Quantity;
    sysBp: Optional<number>;
    sex: Optional<CalculatorOption>;
    hr: Optional<CalculatorOption>;
    melena: Optional<CalculatorOption>;
    syncope: Optional<CalculatorOption>;
    hepatic: Optional<CalculatorOption>;
    cardiac: Optional<CalculatorOption>;
  }>({
    hemoglobin: {
      value: undefined,
      unit: hemoglobinUnits[0],
    },
    bun: {
      value: undefined,
      unit: bunUnits[0],
    },
    sysBp: undefined,
    sex: undefined,
    hr: heartRateOver100Options.find((e) => e.default),
    melena: melenaPresentOptions.find((e) => e.default),
    syncope: recentSyncopeOptions.find((e) => e.default),
    hepatic: hepaticDiseaseHistoryOptions.find((e) => e.default),
    cardiac: cardiacFailurePresentOptions.find((e) => e.default),
  });

  // calculated using g/L
  const calculateHemoglobinScore = (
    hemoglobin: number,
    sex: "male" | "female",
  ) => {
    switch (sex) {
      case "male":
        switch (true) {
          case hemoglobin >= 130:
            return 0;
          case hemoglobin >= 120:
            return 1;
          case hemoglobin >= 100:
            return 3;
          default:
            return 6;
        }
      case "female":
        switch (true) {
          case hemoglobin >= 120:
            return 0;
          case hemoglobin >= 100:
            return 1;
          default:
            return 6;
        }
    }
  };

  // calculated using mmol/L
  const calculateBUNScore = (bun: number) => {
    switch (true) {
      case bun > 25:
        return 6;
      case bun >= 10:
        return 4;
      case bun >= 8:
        return 3;
      case bun >= 6.5:
        return 2;
      default:
        return 0;
    }
  };

  const calculateSysBPScore = (sysBp: number) => {
    switch (true) {
      case sysBp >= 110:
        return 0;
      case sysBp >= 100:
        return 1;
      case sysBp >= 90:
        return 2;
      default:
        return 3;
    }
  };

  const calculateResult = ({
    sex,
    hemoglobin,
    bun,
    sysBp,
    hr = 0,
    syncope = 0,
    hepatic = 0,
    cardiac = 0,
    melena = 0,
  }: {
    sex: "male" | "female" | undefined;
    hemoglobin?: number;
    bun?: number;
    sysBp?: number;
    hr?: number;
    syncope?: number;
    hepatic?: number;
    cardiac?: number;
    melena?: number;
  }) => {
    let result = 0;

    result += sex && hemoglobin ? calculateHemoglobinScore(hemoglobin, sex) : 0;
    result += bun ? calculateBUNScore(bun) : 0;
    result += sysBp ? calculateSysBPScore(sysBp) : 0;
    result += melena + hr + syncope + hepatic + cardiac;

    return result;
  };

  const interpretResult = (result: number) => {
    switch (true) {
      case result >= 6:
        return "Greater than 50% risk of needing an intervention";
      case result > 0:
        return "Higher risk of needing intervention";
      default:
        return "Has minimal risk of needing an intervention like transfusion, endoscopy or surgery";
    }
  };

  const result = calculateResult({
    sex: values.sex?.code as "male" | "female" | undefined,
    hemoglobin: values.hemoglobin.unit.converter(values.hemoglobin.value ?? 0),
    bun: values.bun.unit.converter(values.bun.value ?? 0),
    sysBp: values.sysBp,
    hr: values.hr?.result,
    syncope: values.syncope?.result,
    hepatic: values.hepatic?.result,
    cardiac: values.cardiac?.result,
    melena: values.melena?.result,
  });
  const resultInterpretation = interpretResult(result);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Hemoglobin"
            placeholder={`Norm: ${
              values.hemoglobin.unit.unit === "g/L" ? "120-170" : "12-17"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                hemoglobin: {
                  ...values.hemoglobin,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={hemoglobinUnits}
                label={values.hemoglobin.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    hemoglobin: {
                      ...values.hemoglobin,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="BUN"
            placeholder={`Norm: ${
              values.bun.unit.unit === "mmol/L" ? "2.9-7.1" : "8-20"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                bun: {
                  ...values.bun,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={bunUnits}
                label={values.bun.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    bun: {
                      ...values.bun,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Initial systolic BP"
            placeholder="Norm 100-120"
            onChange={(e) => {
              const int = parseInt(e.target.value);
              setValues({
                ...values,
                sysBp: int,
              });
            }}
            endAdornment={<Typography>mmHg</Typography>}
          />

          <Grid container pl={2} pt={2} spacing={2}>
            <Grid item xs={12}>
              <Choice
                group={"Sex"}
                value={values.sex}
                options={sexOptions}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    sex: option,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Choice
                group={"Heart rate ≥100"}
                value={values.hr}
                options={heartRateOver100Options}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    hr: option,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Choice
                group={"Melena present"}
                value={values.melena}
                options={melenaPresentOptions}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    melena: option,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Choice
                group={"Recent syncope"}
                value={values.syncope}
                options={recentSyncopeOptions}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    syncope: option,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Choice
                group={"Hepatic disease history"}
                value={values.hepatic}
                options={hepaticDiseaseHistoryOptions}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    hepatic: option,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Choice
                group={"Cardiac failure present"}
                value={values.cardiac}
                options={cardiacFailurePresentOptions}
                onChange={(option: CalculatorOption) => {
                  setValues({
                    ...values,
                    cardiac: option,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        <Typography>
          Score <b>{result}</b> ({resultInterpretation})
        </Typography>
      </Box>
    </Box>
  );
}
