import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Hypertension",
    subtitle: "Uncontrolled, >160 mmHg systolic",
    code: "hypertension",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Renal disease",
    subtitle: "Dialysis, transplant, Cr >2.26 mg/dL or >200 µmol/L",
    code: "renal-disease",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Liver disease",
    subtitle: "(Cirrhosis or bilirubin >2x normal with AST/ALT/AP >3x normal)",
    code: "liver-disease",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Stroke history",
    code: "stroke-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Prior major bleeding or predisposition to bleeding",
    code: "prior-major-bleeding",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Liable INR",
    subtitle: "Unstable/high INRs, time in therapeutic range <60%",
    code: "liable-inr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Age > 65",
    code: "age",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Medication usage predisposing to bleeding",
    subtitle: "Aspirin, clopidogrel, NSAIDs",
    code: "bleeding-medication",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Alcohol use (≥ 8 drinks/week)",
    code: "alcohol-use",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function HASBLEDComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (value) {
      case 0:
      case 1:
        return "Anticoagulation should be considered: Patient has a relatively low risk for major bleeding (~1/100 patient-years).";
      case 2:
        return "Anticoagulation can be considered, however patient does have moderate risk for major bleeding (~2/100 patient-years).";
      case 3:
      case 4:
      case 5:
        return "Alternatives to anticoagulation should be considered: Patient is at high risk for major bleeding.";
      default:
        return "Alternatives to anticoagulation should be considered: Patient is at very high risk for major bleeding.";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
