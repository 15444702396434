import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "../../store";
import calculateTrialDaysRemaining from "../../utils/calculateTrialDaysRemaining";

interface OrganizationTierProps {
  trialColor?: string;
  premiumColor?: string;
}

const ONE_HOUR_TO_MS = 3600000;

const OrganizationTier = ({
  premiumColor,
  trialColor,
}: OrganizationTierProps) => {
  const currentOrganization = useSelector(
    (state) => state.user.currentOrganization,
  );

  const [trialDaysRemaining, setTrialDaysRemaining] = useState(-1);
  const [trialEnded, setTrialEnded] = useState(false);

  useEffect(() => {
    // first run on load or when org changes
    if (
      currentOrganization?.adminSettings?.tier === "TRIAL" &&
      currentOrganization?.adminSettings?.trialEndDate
    ) {
      const days = calculateTrialDaysRemaining(currentOrganization);
      setTrialDaysRemaining(days);
    }

    // every hour after
    const interval = setInterval(() => {
      const days = calculateTrialDaysRemaining(currentOrganization);
      setTrialDaysRemaining(days);
    }, ONE_HOUR_TO_MS);
    return () => {
      clearInterval(interval);
    };
  }, [currentOrganization]);

  useEffect(() => {
    setTrialEnded(!trialDaysRemaining || trialDaysRemaining < 0);
  }, [trialDaysRemaining]);

  return (
    <>
      {currentOrganization?.adminSettings?.tier === "PREMIUM" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: -20,
            marginLeft: 10,
            color: premiumColor || "#FFD700",
          }}
        >
          Premium
        </Typography>
      )}
      {currentOrganization?.adminSettings?.tier === "TRIAL" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: trialDaysRemaining > -1 ? -20 : 0,
            marginLeft: 10,
            color: trialColor || "#37fd12",
          }}
        >
          {!trialEnded && (
            <>
              Trial -{" "}
              {trialDaysRemaining !== 1
                ? `${trialDaysRemaining} days `
                : `${trialDaysRemaining} day `}
              left.
            </>
          )}
          {trialEnded && <> - Your trial has ended.</>}
        </Typography>
      )}
    </>
  );
};

export default OrganizationTier;
