import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useProfileModal from "../hooks/useProfileModal";
import { useDispatch, useSelector } from "../store";
import { providerApi } from "../api/providerApi";
import useFullPageLoader from "../hooks/useFullPageLoader";
import { getUserProfile } from "../slices/user";
import { name as namePresets } from "../slices/options/general";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

function UserProfileModal() {
  const { isAuthenticated, logout } = useAuth();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { isOpen, closeUserProfileModal } = useProfileModal();
  const [, toggleLoader] = useFullPageLoader();
  const [name, setName] = useState<any>({});

  useEffect(() => {
    if (profile) {
      setName(profile.name[0]);
    }
  }, [profile]);

  const saveProfile = async () => {
    toggleLoader(true);
    const userProfile = { ...profile };
    userProfile.name = [name];
    const updatedProfile = await providerApi.updatePractitioner(
      profile.id,
      userProfile,
    );
    dispatch(getUserProfile(updatedProfile?.authId));
    closeUserProfileModal();
    toggleLoader(false);
  };

  return (
    <div>
      <Modal
        open={isOpen && isAuthenticated}
        aria-labelledby="organization-selection-modal"
        aria-describedby="organization-selection-modal"
      >
        <Box
          width={{ xs: "90%", sm: 700 }}
          p={2}
          position="fixed"
          top="50%"
          left="50%"
          maxHeight="90%"
          bgcolor="background.paper"
          border="2px solid #000"
          borderRadius={1}
          boxShadow={10}
          overflow="auto"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid container sx={{ height: "100%" }}>
            <Grid container>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Update your profile
              </Typography>
              <Typography id="modal-modal-title">
                Welcome to Medial Health. Please update your basic profile
                details so others can find you within the system. You can set
                more profile information by visiting the profile section later.
              </Typography>
            </Grid>
            <Divider style={{ color: "grey", width: "100%", marginTop: 15 }} />
            <Grid container>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                py={3}
                style={{
                  maxHeight: 800,
                  overflow: "auto",
                }}
              >
                <Grid item xs={9}>
                  <Grid item md={5}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        select
                        value={name.prefix}
                        onChange={(e) => {
                          setName({ ...name, prefix: e.target.value });
                        }}
                        id="demo-simple-select-outlined"
                        label="Prefix"
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem key={-1} value={""}>
                          Select Prefix
                        </MenuItem>
                        {namePresets.prefixes.map(
                          ({ code, display }, index) => {
                            return (
                              <MenuItem key={index} value={code}>
                                {display}
                              </MenuItem>
                            );
                          },
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={name.firstName}
                      onChange={(e) => {
                        setName({ ...name, firstName: e.target.value });
                      }}
                      id="standard-basic"
                      label="First Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={name.middleName}
                      onChange={(e) => {
                        setName({ ...name, middleName: e.target.value });
                      }}
                      id="standard-basic"
                      label="Middle Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      value={name?.family}
                      onChange={(e) => {
                        setName({ ...name, family: e.target.value });
                      }}
                      id="standard-basic"
                      label="Last Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <Grid item md={5}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        select
                        value={name.suffix}
                        onChange={(e) => {
                          setName({ ...name, suffix: e.target.value });
                        }}
                        id="demo-simple-select-outlined"
                        label="Suffix"
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem key={-1} value={""}>
                          Select Suffix
                        </MenuItem>
                        {namePresets.suffixes.map(
                          ({ code, display }, index) => {
                            return (
                              <MenuItem key={index} value={code}>
                                {display}
                              </MenuItem>
                            );
                          },
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{
                color: "grey",
                width: "100%",
                marginBottom: 10,
              }}
            />
            <Grid container justifyContent="flex-end">
              <Box justifyContent="flex-end">
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={logout}>
                    Logout
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={false}
                    onClick={() => {
                      saveProfile();
                    }}
                  >
                    Update Profile
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default UserProfileModal;
