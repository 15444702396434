import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useQueryClient } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  cloneDeep,
  find,
  get,
  isNil,
  pickBy,
  sortBy,
  unionBy,
  uniqBy,
} from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAsyncEffect } from "use-async-effect";
import { medicalsApi } from "../api/medicalsApi";
import { providerApi } from "../api/providerApi";
import CodingSystems from "../constants/CodingSystems";
import { FhirConstants } from "../constants/FhirConstants";
import { QueryKeys } from "../constants/queryKeys";
import {
  DefaultEncounterClass,
  DefaultEncounterTriageLevel,
} from "../contexts/constants";
import useComponentLoader from "../hooks/useComponentLoader";
import useFullPageLoader from "../hooks/useFullPageLoader";
import useMedicalEncounterModal from "../hooks/useMedicalEncounterModal";
import usePractitioner from "../hooks/usePractitioner";
import useRoles from "../hooks/useRoles";
import { useSaved } from "../hooks/useSaved";
import useSettings from "../hooks/useSettings";
import { useOrganizationReference } from "../pages/facility/hooks/useOrganization";
import { defaultChiefComplaint } from "../pages/health/encounters/cards/ChiefComplaint/ChiefComplaintForm";
import { createCustomTheme } from "../theme";
import { CodeableConcept } from "../types/codeableConcept";
import {
  createReference,
  generateHumanDisplayName,
} from "../utils/DataTransformationUtils";
import { createCodeableConcept } from "../utils/DataUtils";
import asArray from "../utils/asArray";
import ComponentLoader from "./ComponentLoader";
import {
  NotificationVariant,
  NotificationsContext,
} from "./dashboard/NotificationsProvider";
import DictationControl from "./dictation/DictationControl";
import NotesInput from "./form/NotesInput";
import PaperEncounterInput from "./widgets/PaperEncounterInput";
import { PatientAllergyInput } from "./widgets/modals/encounter/AllergyInput";
import FindOrCreatePatientInput from "./widgets/modals/encounter/FindOrCreatePatientInput";
import { PatientMedicalHistoryInput } from "./widgets/modals/encounter/MedicalHistoryInput";
import { PatientMedicationsInput } from "./widgets/modals/encounter/MedicationsInput";
import VitalsInputForm from "./widgets/modals/encounter/VitalsInputForm";
import { EncounterType } from "../types/medicalEncounter";
import { useMedicalConfig } from "../slices/configuration";

const defaultObservation = {
  ...defaultChiefComplaint,
};

export default function MedicalEncounterModal() {
  const {
    allowSimplifiedEncounter,
    allowFullEncounter,
    allowHistoricalEncounterUpload,
  } = useFlags();

  const navigate = useNavigate();
  const practitionerReference = usePractitioner();
  const { isOpen, closeMedicalEncounterModal, appointment, patient } =
    useMedicalEncounterModal();

  const [practitioners, setPractitioners] = useState<any[]>();
  const { defaultEncounterType } = useMedicalConfig();

  const [encounterType, setEncounterType] = useState<EncounterType>(
    defaultEncounterType ?? "simple",
  );
  const [allergies, setAllergies] = useState<CodeableConcept[]>();
  const [currentMedications, setCurrentMedications] = useState<any[]>();
  const [currentVitals, setCurrentVitals] = useState();
  const [medicalHistory, setMedicalHistory] = useState<CodeableConcept[]>();
  const [chiefComplaint, setChiefComplaint] = useState<any>(
    cloneDeep(defaultObservation),
  );

  const userRoles = useRoles();
  const [participants, setParticipants] = useState<any[]>([]);
  const [subject, setSubject] = useState<any>();

  const [actualPeriod, setActualPeriod] = useState<any>({ start: new Date() });
  const [encounterClass, setEncounterClass] = useState<any>(
    DefaultEncounterClass,
  );
  const [encounterStatus, setEncounterStatus] = useState<string>("arrived");
  const [encounterTriageType, setEncounterTriageType] = useState<any>(
    DefaultEncounterTriageLevel,
  );

  const [, toggleLoader] = useFullPageLoader();
  const { setNotification } = useContext(NotificationsContext);

  const organizationReference = useOrganizationReference();
  const [encounterParticipants, setEncounterParticipants] = useState<any[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [currentEncounterId, setCurrentEncounterId] = useState<string>();
  const [encounterSearchComplete, setEncounterSearchComplete] =
    useState<boolean>(false);
  const [componentLoading] = useComponentLoader();
  const { settings } = useSettings();
  const queryClient = useQueryClient();

  const invalidateCache = () => {
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ENCOUNTERS] }),
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ALLERGIES] }),
    ]);
  };

  useEffect(() => {
    if (!practitionerReference?.identifier) return;
    if (chiefComplaint?.note && chiefComplaint?.note?.length !== 0) return;

    setChiefComplaint({
      ...chiefComplaint,
      note: [{ author: practitionerReference }],
    });

    if (
      practitionerReference?.identifier &&
      userRoles &&
      userRoles.includes("Practitioner")
    ) {
      setEncounterParticipants([
        {
          actor: practitionerReference,
          type: [
            createCodeableConcept(
              CodingSystems.ENCOUNTER_PARTICIPANTS_ROLE,
              "ADM",
              "Admitter",
            ),
          ],
        },
      ]);
    }
  }, [practitionerReference, chiefComplaint, userRoles]);

  useEffect(() => {
    if (
      encounterParticipants &&
      encounterParticipants.length > 0 &&
      participants &&
      participants.length === 0
    ) {
      setParticipants(
        encounterParticipants.map((p) => {
          return { code: p.actor.identifier, display: p.actor.display };
        }),
      );
    }
  }, [encounterParticipants]);

  useEffect(() => {
    if (participants.length !== encounterParticipants?.length) {
      setEncounterParticipants(
        participants.map((p) => {
          return {
            actor: createReference("Practitioner", p.code, p.display),
          };
        }),
      );
    }
  }, [participants]);

  const loadPractitioners = async () => {
    try {
      if (!practitioners) {
        const staff = await providerApi.findPractitioners({
          "managing-organization": organizationReference?.identifier,
        });

        const practitionerOpts = sortBy(
          staff.map((p) => {
            return {
              display: generateHumanDisplayName(p.name[0]),
              code: p.id,
            };
          }),
        );

        setPractitioners(practitionerOpts);
      }
    } catch (error) {
      console.error(error);
      setNotification({
        message:
          "Unable to retrieve staff members. Please refresh the page and try again, or contact technical support.",
        variant: NotificationVariant.error,
      });
    } finally {
      toggleLoader(false);
    }
  };

  useAsyncEffect(async () => {
    // Call for practitioners and/or services
    if (organizationReference) {
      try {
        toggleLoader(true);
        await loadPractitioners();
      } catch (error) {
        console.error(error);
        setNotification({
          message:
            "Unable to retrieve staff members. Please refresh the page and try again, or contact technical support.",
          variant: NotificationVariant.error,
        });
      } finally {
        toggleLoader(false);
      }
    }
  }, [organizationReference]);

  const resetEncounter = () => {
    setSubject(null);
    setEncounterClass(DefaultEncounterClass);
    setEncounterStatus("arrived");
    setEncounterTriageType(DefaultEncounterTriageLevel);
    setChiefComplaint(cloneDeep(defaultObservation));
    setCurrentVitals(undefined);
    setUploadedFiles(null);
    setAllergies(null);
    setCurrentMedications(null);
    setMedicalHistory(null);
    setEncounterSearchComplete(false);
  };

  const closeModal = () => {
    resetEncounter();
    closeMedicalEncounterModal();
    setCurrentEncounterId(null);
  };
  const { setSaved } = useSaved(true, closeModal);

  useEffect(() => {
    if (!isOpen) return;
    setActualPeriod((actualPeriod) => ({
      ...actualPeriod,
      start: new Date(),
    }));
  }, [isOpen]);

  useEffect(() => {
    if (patient) {
      const ref = createReference(
        "Patient",
        patient.id,
        generateHumanDisplayName(patient.name[0]),
      );
      setSubject(ref);
    } else setSubject(undefined);
  }, [patient]);

  useEffect(() => {
    if (appointment?.id) {
      const impression = { ...chiefComplaint[0] };
      if (appointment.participant && appointment.participant.length > 0) {
        const appointmentPractitioners = [];
        appointment.participant.forEach((p) => {
          if (p.actor?.type === "Patient") {
            setSubject(p.actor);
          }
          if (p.actor?.type === "Practitioner") {
            appointmentPractitioners.push(p.actor);
          }

          const participants = unionBy(
            appointmentPractitioners,
            [practitionerReference],
            "identifier",
          ).map((p) => {
            return { actor: p };
          });
          setEncounterParticipants(participants);
        });

        const patient = find(appointment.participant, function (p) {
          return p.actor?.type === "Patient";
        });
        setSubject(patient?.actor);
      }
      if (appointment.reasonCode) {
        impression.supportingInfo = appointment.reasonCode;
      }

      setChiefComplaint([impression]);
    }
  }, [appointment]);

  const isVitalValid = (vitals) => {
    if (!vitals) return false;
    if (Object.keys(vitals).length === 1) return false;
    // check if any vital has value
    const filteredVitals = Object.keys(vitals).filter(
      (v) =>
        vitals[v].valueQuantity?.value && vitals[v].valueQuantity?.value !== 0,
    );
    if (filteredVitals.length === 0) return false;
    return true;
  };

  const saveMedicalEncounter = async () => {
    try {
      toggleLoader(true);
      const payload: any = {
        subject,
        serviceProvider: organizationReference,
        participant: unionBy(
          encounterParticipants,
          [
            {
              actor: practitionerReference,
              type: [
                createCodeableConcept(
                  CodingSystems.ENCOUNTER_PARTICIPANTS_ROLE,
                  "ADM",
                  "Admitter",
                ),
              ],
            },
          ],
          "actor.identifier",
        ),
        actualPeriod: actualPeriod,
        status: encounterType === "paper" ? "finished" : encounterStatus,
        class: encounterClass,
        triageType: encounterTriageType,
        appointment:
          appointment?.id
            ? createReference("Appointment", appointment.id)
            : undefined,
        chiefComplaint: chiefComplaint,
        vitals: isVitalValid(currentVitals)
          ? pickBy(currentVitals, (v: any) => !isNil(v?.valueQuantity?.value))
          : undefined,
        allergies: allergies.filter((e) => e?.coding[0]?.code),
        medicalHistory: medicalHistory.filter((e) => e?.coding[0]?.code),
        medications: currentMedications.filter((e) => e.identifier),
        extensions: [
          {
            url: `${FhirConstants.EXTENSION_URL}/encounter-type`,
            value: encounterType,
          },
        ],
      };
      if (
        encounterType === "paper" &&
        (!uploadedFiles || uploadedFiles?.length === 0)
      ) {
        setNotification({
          message:
            "Cannot upload a paper based encounter without any attached files",
          variant: "error",
        });
        return;
      }

      const savedEncounter = await medicalsApi.saveEncounter(
        payload,
        { encounter: `Encounter/${currentEncounterId}` },
        uploadedFiles,
      );

      setNotification({
        message: "Successfully saved the medical encounter.",
        variant: NotificationVariant.success,
      });
      invalidateCache();
      setSaved(true);
      closeModal();
      navigate(`/health/encounters/${savedEncounter.id}/edit`);
    } catch (e) {
      setNotification({
        message: "Error creating patient encounter. Please try again.",
        variant: NotificationVariant.error,
      });
    } finally {
      toggleLoader(false);
    }
  };

  const goToEncounter = () => {
    navigate(`/health/encounters/${currentEncounterId}/edit`);
    closeModal();
  };

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Modal
          open={isOpen}
          aria-labelledby="encounter-modal-modal-title"
          aria-describedby="encounter-modal-modal-description"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveMedicalEncounter();
            }}
          >
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 700,
                maxHeight: "90%",
                overflowY: "auto",
                overflowX: "hidden",
                bgcolor: "background.default",
                border: "2px solid #000",
                boxShadow: 24,
                borderRadius: 1,
                p: 2,
                paddingRight: 4,
              }}
            >
              <Grid
                container
                sx={{
                  height: "100%",
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Typography
                      id="encounter-modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      New Medical Encounter
                    </Typography>
                  </Grid>
                  {encounterType !== "paper" && (
                    <Grid item xs={12} md={6}>
                      <DateTimePicker
                        renderInput={(props) => (
                          <TextField
                            size="small"
                            required
                            fullWidth
                            {...props}
                            InputLabelProps={{ shrink: true }}
                            helperText={null}
                          />
                        )}
                        maxDateTime={new Date()}
                        label="Encounter Date"
                        value={actualPeriod.start}
                        onChange={(date) => {
                          setActualPeriod({ ...actualPeriod, start: date });
                        }}
                        inputFormat="dd/MM/yyyy hh:mm a"
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider
                  variant="middle"
                  style={{ color: "grey", width: "100%", marginTop: 15 }}
                />
                <Grid container py={1}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    style={{
                      paddingTop: 15,
                      paddingLeft: 5,
                      paddingBottom: 15,
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        wrap="nowrap"
                        alignItems="center"
                      >
                        <FindOrCreatePatientInput
                          subject={subject}
                          setSubject={setSubject}
                          setCurrentEncounterId={setCurrentEncounterId}
                          setEncounterSearchComplete={
                            setEncounterSearchComplete
                          }
                        />
                      </Grid>
                    </Grid>
                    {subject?.identifier &&
                      !currentEncounterId &&
                      encounterSearchComplete && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingTop: 0 }}
                          key={"medical-encounter-observation"}
                        >
                          <Grid container py={2} spacing={3}>
                            {practitioners && practitioners.length > 1 && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  autoHighlight
                                  disableCloseOnSelect
                                  value={participants ?? []} //practitioners
                                  options={practitioners ?? []} //practitioners
                                  onChange={(e, practs) => {
                                    const data = uniqBy(practs, "code");
                                    setParticipants(data);
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.display ?? "..."
                                  }
                                  renderOption={(
                                    props,
                                    option: any,
                                    { selected },
                                  ) => {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          style={{ marginRight: 8 }}
                                          checked={asArray(
                                            encounterParticipants?.map(
                                              (p) => p.actor.identifier,
                                            ) ?? [],
                                          ).includes(option.code)}
                                        />
                                        {option.display}
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Practitioners involved?"
                                      maxRows={3}
                                      required={participants?.length === 0}
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <FormControl>
                                <FormLabel>Encounter Mode</FormLabel>
                                <RadioGroup
                                  row
                                  value={encounterType}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (
                                      !["simple", "full", "paper"].includes(
                                        value,
                                      )
                                    ) {
                                      return;
                                    }

                                    setEncounterType(
                                      value as EncounterType,
                                    );
                                  }}
                                >
                                  {allowHistoricalEncounterUpload && (
                                    <FormControlLabel
                                      value="paper"
                                      label="Paper Upload"
                                      control={<Radio />}
                                    />
                                  )}
                                  {allowSimplifiedEncounter && (
                                    <FormControlLabel
                                      value="simple"
                                      label="Simple Note"
                                      control={<Radio />}
                                    />
                                  )}
                                  {allowFullEncounter && (
                                    <FormControlLabel
                                      value="full"
                                      label="Detailed Note"
                                      control={<Radio />}
                                    />
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {encounterType === "paper" && (
                              <Grid item xs={12}>
                                <Alert severity="warning" variant="outlined">
                                  <Typography variant="subtitle2">
                                    Use this option only if you would like to
                                    register a past encounter recorded on paper.
                                    Otherwise, select `&quot;`<strong>Detailed Note</strong>`&quot;` and add
                                    files as attachments.
                                  </Typography>
                                </Alert>
                              </Grid>
                            )}

                            {encounterType !== "paper" && (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <DictationControl
                                        value={
                                          get(chiefComplaint, "summary") ?? ""
                                        }
                                        onTextChange={(value) => {
                                          setChiefComplaint({
                                            ...chiefComplaint,
                                            summary: value,
                                          });
                                          setSaved(false);
                                        }}
                                        id="encounter-chief-complaint-summary"
                                        label="Chief Complaint"
                                        placeholder="Summary of the complaint"
                                        required
                                        disabled={!subject?.identifier}
                                        minRows={1}
                                        maxRows={2}
                                        style={{ width: "100%" }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <PatientAllergyInput
                                      value={allergies}
                                      patient={subject}
                                      disabled={!subject?.identifier}
                                      onChange={(allergies) => {
                                        setAllergies(allergies);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <PatientMedicalHistoryInput
                                      value={medicalHistory}
                                      disabled={!subject?.identifier}
                                      patient={subject}
                                      onChange={(value) =>
                                        setMedicalHistory(value)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <PatientMedicationsInput
                                      value={currentMedications}
                                      disabled={!subject?.identifier}
                                      patient={subject}
                                      onChange={setCurrentMedications}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <VitalsInputForm
                                      isModal
                                      value={currentVitals}
                                      onChange={setCurrentVitals}
                                      disabled={!subject?.identifier}
                                      patient={subject}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                      <NotesInput
                                        value={chiefComplaint?.note}
                                        label={"HPI - HPC"}
                                        onChange={(notes) => {
                                          setChiefComplaint({
                                            ...chiefComplaint,
                                            note: notes,
                                          });
                                          setSaved(false);
                                        }}
                                        placeholderText="Please add as much information as possible, including exact words of the patient."
                                        disabled={!subject?.identifier}
                                        minRows={3}
                                        maxNotes={1}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {encounterType === "paper" && (
                              <Grid item xs={12}>
                                <PaperEncounterInput
                                  actualPeriod={actualPeriod}
                                  setActualPeriod={setActualPeriod}
                                  chiefComplaint={chiefComplaint}
                                  setChiefComplaint={setChiefComplaint}
                                  setSaved={setSaved}
                                  subject={subject}
                                  setUploadedFiles={setUploadedFiles}
                                  uploadedFiles={uploadedFiles}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                  </Grid>

                  <Grid container>
                    <Grid item md={5} xs={12} py={1.9} pl={7}>
                      {currentEncounterId && (
                        <Typography variant="subtitle2" color="#FF0000">
                          Patient has an open encounter!
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={7} xs={12} justifyContent="flex-end">
                      <Box display="flex" justifyContent="flex-end" py={1}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          Cancel
                        </Button>
                        {!currentEncounterId ? (
                          <Button
                            type="submit"
                            disabled={!encounterSearchComplete || !subject}
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "10px" }}
                          >
                            Save Encounter
                          </Button>
                        ) : (
                          <Button
                            disabled={!currentEncounterId}
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "10px" }}
                            onClick={() => goToEncounter()}
                          >
                            Go to Current Patient Encounter
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ComponentLoader loading={componentLoading} />
            </Box>
          </form>
        </Modal>
      </div>
    </ThemeProvider>
  );
}
