import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Elevated-risk surgery",
    subtitle:
      "Defined as: Intraperitoneal, intrathoracic, or suprainguinal vascular",
    code: "elevated-risk-surgery",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "History of ischemic heart disease",
    code: "ischemic-heart-disease",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "History of congestive heart failure",
    code: "congestive-heart-failure",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "History of cerebrovascular disease",
    code: "cerebrovasculardisease",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Pre-operative treatment with insulin",
    code: "pre-operative-treatment-with-insulin",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Pre-operative creatinine >2 mg/dL / 176.8 µmol/L",
    code: "pre-operative-creatinine",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function CardiacRiskIndexComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (true) {
      case value >= 3:
        return "Class IV Risk: 15.0% 30-day risk of death, MI, or cardiac arrest";
      case value === 2:
        return "Class III Risk: 10.1% 30-day risk of death, MI, or cardiac arrest";
      case value === 1:
        return "Class II Risk: 6.0% 30-day risk of death, MI, or cardiac arrest";
      default:
        return "Class I Risk: 3.9% 30-day risk of death, MI, or cardiac arrest";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
