import type { FC } from "react";
import { matchPath } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "../store";
import { List, ListProps, ListSubheader } from "@mui/material";
import { shouldRenderMenuItem } from "../utils/shouldRenderMenuItem";
import { TNavItem } from "../types/navItem";
import NavItem from "./NavItem";

interface NavSectionProps extends ListProps {
  items: TNavItem[];
  pathname: string;
  title: string;
  ldOrgFlag?: string;
  allowedRoles?: string[];
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
  launchDarklyFlags,
  adminSettings,
  orgId,
  userRoles,
}: {
  items: TNavItem[];
  pathname: string;
  depth?: number;
  launchDarklyFlags: any;
  adminSettings: any;
  orgId: string;
  userRoles: string[];
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          launchDarklyFlags,
          adminSettings,
          orgId,
          userRoles,
        }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
  launchDarklyFlags,
  adminSettings,
  orgId,
  userRoles,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: TNavItem;
  depth: number;
  launchDarklyFlags: any;
  adminSettings: any;
  orgId: string;
  userRoles: string[];
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname,
        )
      : false;
    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          launchDarklyFlags,
          adminSettings,
          orgId,
          userRoles,
        })}
      </NavItem>,
    );
  } else {
    if (
      shouldRenderMenuItem(
        item,
        launchDarklyFlags,
        adminSettings,
        orgId,
        userRoles,
      )
    ) {
      acc.push(
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
        />,
      );
    }
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { items, pathname, title, ldOrgFlag, allowedRoles, ...other } = props;
  const flags = useFlags();
  const currentOrg = useSelector((state) => state.user.currentOrganization);
  const userRoles = useSelector((state) => state.user.roles);

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "0.75rem",
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        launchDarklyFlags: flags,
        adminSettings: currentOrg?.adminSettings,
        orgId: currentOrg?.id,
        userRoles,
      })}
    </List>
  );
};

export default NavSection;
