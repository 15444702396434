const CodingSystems = {
  ENCOUNTER_REASON: "http://hl7.org/fhir/ValueSet/encounter-reason",
  ENCOUNTER_CLASS: "http://terminology.hl7.org/ValueSet/v3-ActEncounterCode",
  ENCOUNTER_SERVICE_TYPE: "http://hl7.org/fhir/ValueSet/service-type",
  APPOINTMENT_SERVICE_CATEGORY:
    "http://terminology.hl7.org/CodeSystem/service-category",
  APPOINTMENT_SERVICE_TYPE:
    "http://terminology.hl7.org/CodeSystem/service-type",
  APPOINTMENT_SPECIALTY: "http://hl7.org/fhir/ValueSet/c80-practice-codes",
  ALLERGY_INTOLERANCE_CODE:
    "http://hl7.org/fhir/ValueSet/allergyintolerance-code",
  ALLERGY_INTOLERANCE_CLINICAL_STATUS:
    "http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical",
  ALLERGY_INTOLERANCE_VERIFICATION_STATUS:
    "http://terminology.hl7.org/CodeSystem/allergyintolerance-verification",
  APPOINTMENT_TYPE: "http://terminology.hl7.org/CodeSystem/v2-0276",
  CONDITION_CATEGORY:
    "http://terminology.hl7.org/CodeSystem/condition-category",
  CONDITION_VERIFICATION_STATUS:
    "http://terminology.hl7.org/CodeSystem/condition-ver-status",
  CONDITION_CLINICAL_STATUS:
    "http://terminology.hl7.org/CodeSystem/condition-clinical",
  CONDITION_CODE: "http://hl7.org/fhir/ValueSet/condition-code",
  CONDITION_STAGE: "http://hl7.org/fhir/ValueSet/condition-stage",
  CONTACT_ENTITY_TYPE: "http://hl7.org/fhir/ValueSet/contactentity-type",
  FAMILY_MEMBER: "http://terminology.hl7.org/ValueSet/v3-FamilyMember",
  UNITS_OF_MEASURE: "http://unitsofmeasure.org",
  CONDITION_SEVERITY: "http://hl7.org/fhir/ValueSet/condition-severity",
  INVESTIGATION_TYPE: "http://hl7.org/fhir/ValueSet/investigation-sets",
  COMMON_LANGUAGES: "http://hl7.org/fhir/ValueSet/languages",
  PRACTICE_CODES: "http://hl7.org/fhir/ValueSet/c80-practice-codes",
  ROUTE_CODES: "http://hl7.org/fhir/ValueSet/route-codes",
  APPROACH_SITE_CODES: "http://hl7.org/fhir/ValueSet/approach-site-codes",
  OBSERVATION_CATEGORY: "http://hl7.org/fhir/ValueSet/observation-category",
  OBSERVATION_CODE: "http://hl7.org/fhir/ValueSet/observation-codes",
  BODY_POSITION: "http://hl7.org/fhir/us/vitals/ValueSet/bodyPositionVS",
  PROCEDURE_CODES: "http://hl7.org/fhir/ValueSet/procedure-code",
  PROCEDURE_CATEGORY: "http://hl7.org/fhir/ValueSet/procedure-category",
  PROCEDURE_OUTCOMES: "http://hl7.org/fhir/ValueSet/procedure-outcome",
  BODY_SITE_CODES: "http://hl7.org/fhir/ValueSet/body-site",
  FLAG_CODE: "http://hl7.org/fhir/R4B/valueset-flag-code.html",
  FLAG_CATEGORY: "http://hl7.org/fhir/R4B/valueset-flag-category.html",
  FLAG_PRIORITY: "http://hl7.org/fhir/R4B/valueset-flag-priority.html",
  FLAG_STATUS: "http://hl7.org/fhir/R4B/valueset-flag-status.html",
  IMMUNIZATION_SITE: "http://terminology.hl7.org/CodeSystem/v3-ActSite",
  IMMUNIZATION_ROUTE:
    "http://terminology.hl7.org/CodeSystem/v3-RouteOfAdministration",
  ENCOUNTER_PARTICIPANTS_ROLE:
    "http://hl7.org/fhir/ValueSet/encounter-participant-type",
  VACCINC_CODE: "http://hl7.org/fhir/ValueSet/vaccine-code",
  IMMUNIZATION_STATUS_REASON:
    "http://hl7.org/fhir/ValueSet/immunization-status-reason",
  MEDICATION_STATUS_CODES:
    "http://hl7.org/fhir/ValueSet/reason-medication-status-codes",
  LOINC: "http://loinc.org",
  ORGANIZATION_TYPE: "http://hl7.org/fhir/ValueSet/organization-type",
};

export default CodingSystems;
