export const conditionVerificationStatuses = [
  {
    code: "unconfirmed",
    display: "Unconfirmed",
    definition:
      "A low level of certainty about the propensity for a reaction to the identified substance.",
  },
  {
    code: "confirmed",
    display: "Confirmed",
    definition:
      "A high level of certainty about the propensity for a reaction to the identified substance, which may include clinical evidence by testing or rechallenge.",
  },
  {
    code: "refuted",
    display: "Refuted",
    definition:
      "A propensity for a reaction to the identified substance has been disputed or disproven with a sufficient level of clinical certainty to justify invalidating the assertion. This might or might not include testing or rechallenge.",
  },
  {
    code: "provisional",
    display: "Provisional",
    definition:
      "This is a tentative diagnosis - still a candidate that is under consideration.",
  },
  {
    code: "differential",
    display: "Differential",
    definition:
      "One of a set of potential (and typically mutually exclusive) diagnoses asserted to further guide the diagnostic process and preliminary treatment.",
  },
  {
    code: "entered-in-error",
    display: "Entered in Error",
    definition: "The subject's condition was entered in error.",
  },
];

export const conditionCategory = [
  {
    code: "problem-list-item",
    display: "Problem List Item",
    definition:
      "An item on a problem list that can be managed over time and can be expressed by a practitioner (e.g. physician, nurse), patient, or related person.",
  },
  {
    code: "encounter-diagnosis",
    display: "Encounter Diagnosis",
    definition:
      "A point in time diagnosis (e.g. from a physician or nurse) in context of an encounter.",
  },
];

export const conditionClinicalStatuses = [
  {
    code: "active",
    display: "Active",
    definition:
      "The subject is currently experiencing the symptoms of the condition or there is evidence of the condition.",
  },
  {
    code: "recurrence",
    display: "Recurrence",
    definition:
      "The subject is experiencing a re-occurence or repeating of a previously resolved condition, e.g. urinary tract infection, pancreatitis, cholangitis, conjunctivitis.",
  },
  {
    code: "relapse",
    display: "Relapse",
    definition:
      "The subject is experiencing a return of a condition, or signs and symptoms after a period of improvement or remission, e.g. relapse of cancer, multiple sclerosis, rheumatoid arthritis, systemic lupus erythematosus, bipolar disorder, [psychotic relapse of] schizophrenia, etc.",
  },
  {
    code: "inactive",
    display: "Inactive",
    definition:
      "The subject is no longer experiencing the symptoms of the condition or there is no longer evidence of the condition.",
  },
  {
    code: "remission",
    display: "Remission",
    definition:
      "The subject is no longer experiencing the symptoms of the condition, but there is a risk of the symptoms returning.",
  },
  {
    code: "resolved",
    display: "Resolved",
    definition:
      "The subject is no longer experiencing the symptoms of the condition and there is a negligible perceived risk of the symptoms returning.",
  },
];
