import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Age",
    code: "age",
    options: [
      {
        display: "< 60 years",
        code: "662f7481-336e-4e7e-901b-bd22accd49ef",
        result: 0,
        default: true,
      },
      {
        display: "60-79 years",
        result: 1,
        code: "0b1630d8-968c-4e51-a71d-82b1c15cb8ac",
      },
      {
        display: "≥ 80 years",
        result: 2,
        code: "2b1770d1-a477-487b-ab51-dda810164304",
      },
    ],
  },
  {
    title: "Shock",
    code: "shock",
    options: [
      {
        display: "No sock (SBP ≥ 100 and HR < 100)",
        result: 0,
        code: "f51ef46b-3ded-492d-859a-a4d589f8053c",
        default: true,
      },
      {
        display: "Tachycardia (SBP ≥ 100 and HR ≥ 100)",
        result: 1,
        code: "80f03239-2de3-48fe-88f7-9467be8f9262",
      },
      {
        display: "Hypotension (SBP < 100)",
        result: 2,
        code: "54f9262b-980d-483f-928a-1ac4ecbccd76",
      },
    ],
  },
  {
    title: "Comorbidities",
    code: "comorbidities",
    options: [
      {
        display: "No major comorbidity",
        result: 0,
        code: "bd32b44b-7fd9-4e9b-8964-ea5b2ee7f161",
        default: true,
      },
      {
        display:
          "Any comorbidity EXCEPT renal failure, liver failure, and/or disseminated malignancy",
        result: 2,
        code: "0719c85f-d701-4336-881a-3e47379ae3b2",
      },
      {
        display: "Renal failure, liver failure, and/or disseminated malignancy",
        result: 3,
        code: "c540b4a4-0931-4f5f-9619-cbede62b0d9a",
      },
    ],
  },
  {
    title: "Diagnosis",
    code: "diagnosis",
    options: [
      {
        display: "Mallory-Weiss tear",
        result: 0,
        code: "5978b6af-e8f2-4449-b661-ae8264547b21",
        default: true,
      },
      {
        display: "No lesion identified and no stigmata of recent hemorrhage",
        result: 0,
        code: "5848007b-09a0-4278-b504-e98af9202a26",
      },
      {
        display: "All other diagnoses",
        result: 1,
        code: "5f6f5227-d985-471c-98b0-798f2cae0b12",
      },
      {
        display: "Malignancy of upper GI tract",
        result: 2,
        code: "0ad44e8b-25d8-43f6-80c7-dd79f295bcd8",
      },
    ],
  },
  {
    title: "Major stigmata of recent hemorrhage",
    code: "majorStigmata",
    options: [
      {
        display: "None",
        result: 0,
        code: "f1356bd4-db67-4427-86ff-7f9e779b107d",
        default: true,
      },
      {
        display: "Dark spot only",
        result: 0,
        code: "ce130299-fe0e-42d8-8d73-3b2e9a63d431",
      },
      {
        display: "Blood in upper GI tract",
        result: 2,
        code: "9977818c-de48-47ca-85d6-73b66b03cb55",
      },
      {
        display: "Adherent clot",
        result: 2,
        code: "c32c5de1-b804-4095-9fec-5fb80b8fed12",
      },
      {
        display: "Visible or spurting vessel",
        result: 2,
        code: "12000703-e9ab-4e96-a46c-a44193050622",
      },
    ],
  },
];

export default function RockallUGIBComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value: number) => {
    switch (true) {
      case value >= 5:
        return "High Risk";
      case value >= 2:
        return "Intermediate Risk";
      default:
        return "Low Risk";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
