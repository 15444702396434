import { FC, useEffect, useState } from "react";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import { useSelector } from "../../../../../../store";
import { createDefaultVital } from ".";
import CodingSystems from "../../../../../../constants/CodingSystems";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { get, set } from "lodash";
import { updateMetaData } from "../VitalsFormHelper";
import { testRegExp } from "../../../../../../utils/regex";

const OxygenSaturationInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = useSelector(
    (state) => state.user.practitionerReference,
  );
  const [oxygenSaturation, setOxygenSaturation] = useState<any>();

  useEffect(() => {
    if (value) {
      setOxygenSaturation(value);
    } else {
      const os = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "2708-6",
        "Oxygen Saturation",
        "%",
      );
      setOxygenSaturation(os);
    }
  }, [encounter, patient, value]);

  const handleChange = (event) => {
    const eventValue = event.target.value;
    const valid = testRegExp(
      "non-negative integer or empty string",
      eventValue,
    );
    if (!valid) return;
    if (eventValue > 100 || eventValue < 0) return;

    const newOxygenSat = { ...oxygenSaturation };
    set(newOxygenSat, "valueQuantity.value", eventValue);
    updateMetaData(newOxygenSat, practitionerReference);
    onChange(newOxygenSat);
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          value={get(oxygenSaturation, "valueQuantity.value") || ""}
          disabled={disabled}
          onChange={handleChange}
          id="vitals-oxygen-sat"
          label={size === "small" ? `SpO2` : `O2 Saturation`}
          inputMode="numeric"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <Typography pl={1} variant="body2">
                {get(oxygenSaturation, "valueQuantity.unit")}
              </Typography>
            ),
          }}
          variant="outlined"
          size={size || "medium"}
          type="text"
        />
      </FormControl>
    </Grid>
  );
};

export default OxygenSaturationInput;
