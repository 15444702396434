import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { nanoid } from "@reduxjs/toolkit";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Altered mental status and/or GCS < 15",
    code: "altered-mental-status",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Respiratory rate ≥ 22",
    code: "rr",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Systolic BP ≤ 100",
    code: "bp",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function QSOFAComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (value) {
      case 0:
      case 1:
        return "Not high risk";
      default:
        return "High risk";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
