import { ReactNode, createContext, useState } from "react";

const defaultApi = {
  fullPageLoader: undefined,
  setFullPageLoader: (e: boolean) => null,
};

export type FullPageLoaderValue = typeof defaultApi;

export const FullPageLoaderContext =
  createContext<FullPageLoaderValue>(defaultApi);

type Props = {
  children: ReactNode;
};

export function FullPageLoaderProvider({ children }: Props) {
  const [fullPageLoader, setFullPageLoading] = useState<boolean>(undefined);

  // Return Provider with full API
  const api = {
    fullPageLoader,
    setFullPageLoader: setFullPageLoading,
  };

  return (
    <FullPageLoaderContext.Provider value={api}>
      {children}
    </FullPageLoaderContext.Provider>
  );
}
