import { createContext, useState, useCallback } from "react";

const defaultApi = {
  isOpen: undefined,
  duplicatePatients: [],
  patientForCreation: {},
  setDuplicatePatients: (arg: any): void => null,
  openDuplicatePatientModal: (): void => null,
  closeDuplicatePatientModal: (): void => null,
  duplicatePatientActions: {
    actionButtons: [],
    useDuplicatePatient: (arg: any): void => null,
  },
  setPatientForCreation: (arEG: any): void => null,
  setDuplicatePatientActions: (actions: any): void => null,
};

export type DuplicatePatientModalValue = typeof defaultApi;

export const DuplicatePatientModalContext =
  createContext<DuplicatePatientModalValue>(defaultApi);

export function DuplicatePatientModalProvider({ children }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [duplicatePatients, setDuplicates] = useState<any[]>([]);
  const [actions, setActions] = useState<any>({});
  const [patientForCreation, setPatientForCreation] = useState({});

  const openDuplicatePatientModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeDuplicatePatientModal = useCallback(() => {
    setPatientForCreation({});
    setIsOpen(false);
  }, [setIsOpen]);

  const setDuplicatePatients = (patients) => {
    setDuplicates(patients);
  };

  const setDuplicatePatientActions = useCallback(
    (buttons) => {
      setActions(buttons);
    },
    [setActions],
  );

  // Return Provider with full API
  const api = {
    isOpen,
    duplicatePatients,
    patientForCreation,
    setDuplicatePatients,
    openDuplicatePatientModal,
    closeDuplicatePatientModal,
    duplicatePatientActions: actions,
    setDuplicatePatientActions: setDuplicatePatientActions,
    setPatientForCreation: setPatientForCreation,
  };
  return (
    <DuplicatePatientModalContext.Provider value={api}>
      {children}
    </DuplicatePatientModalContext.Provider>
  );
}
