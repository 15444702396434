import { createContext, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { auth0Config } from "../config";
import type { User } from "../types/user";
import { userApi } from "../api/userApi";
import { Organization } from "../types/organization";
import useAsyncEffect from "use-async-effect";

let auth0Client: Auth0Client | null = null;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  organizations: Organization[] | [];
}

export interface AuthContextValue extends State {
  platform: "Auth0";
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
  clearCredentials: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    organizations?: Organization[];
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
    organizations?: Organization[];
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  organizations: [],
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, organizations } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      organizations,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, organizations } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      organizations,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "Auth0",
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  clearCredentials: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useAsyncEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();

          //if organizations exist
          const userOrganizations = await userApi.getOrganazitionsForUser(
            user.sub.split("|")[1],
          );

          //TODO: SF - Use this to retrieve the token for all API Calls
          // const authToken = await auth0Client.getTokenSilently();
          // console.log("authToken -  ", JSON.stringify(authToken));

          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: `${user.given_name} ${user.family_name}`,
                plan: "Premium",
              },
              organizations: userOrganizations || [],
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated,
              user: null,
              organizations: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            organizations: [],
          },
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (options): Promise<void> => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const userOrganizations = await userApi.getOrganazitionsForUser(
        user.sub.split("|")[1],
      );

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: `${user.given_name} ${user.family_name}`,
            plan: "Premium",
          },
          organizations: userOrganizations || [],
        },
      });
    }
  };

  const logout = (): void => {
    auth0Client.logout();
    dispatch({
      type: "LOGOUT",
    });
  };

  const clearCredentials = (): void => {
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Auth0",
        loginWithPopup,
        logout,
        clearCredentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
