import * as Sentry from "@sentry/react";
import React from "react";
import PageLoadError from "./pages/PageLoadError";

interface Props {
  children: React.ReactNode;
}

export default function ErrorBoundary({ children }: Props) {
  return (
    <Sentry.ErrorBoundary
      fallback={<PageLoadError />}
      onError={(error, componentStack) => {
        console.error(error, componentStack);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
