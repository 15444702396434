export type THintDetails = {
  message: string;
  color: string;
  bgcolor: string;
};

export const inputHintHelper = {
  bp: (value: string): THintDetails => {
    try {
      const [sys, dia] = value ? value.split("/") : [undefined, undefined];

      // if sys and dia are not not finite numbers return undefined
      if (!sys || !dia || !isFinite(parseInt(sys)) || !isFinite(parseInt(dia)))
        return undefined;

      const sysNumber = parseInt(sys);
      const diaNumber = parseInt(dia);

      const normal = sysNumber < 120 && diaNumber < 80;
      if (normal) return;

      const elevated = sysNumber >= 120 && sysNumber <= 129 && diaNumber < 80;
      const hbp1 =
        sysNumber >= 130 &&
        sysNumber <= 139 &&
        diaNumber >= 80 &&
        diaNumber <= 89;
      const hbp2 = sysNumber >= 140 && diaNumber >= 90;
      const crisis = sysNumber > 180 && diaNumber > 120;

      let message: string;
      let color: string;
      let bgcolor: string;
      if (elevated) {
        message = "Elevated";
        color = "black";
        bgcolor = "#FFEB00";
      }

      if (hbp1) {
        message = "High Blood Pressure Stage 1";
        color = "black";
        bgcolor = "#FFB200";
      }

      if (hbp2) {
        message = "High Blood Pressure Stage 2";
        color = "white";
        bgcolor = "#CB2A00";
      }

      if (crisis) {
        message = "Hypertensive crisis";
        color = "white";
        bgcolor = "#A70000";
      }

      if ([message, color, bgcolor].some((e) => !e)) return undefined;

      return { message, color, bgcolor };
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },
};
