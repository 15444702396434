export const bpMeasurementLocation = [
  { display: "Biceps left", code: "LA11158-5" },
  { display: "Biceps right", code: "LA11159-3" },
  { display: "Wrist left", code: "LA11160-1" },
  { display: "Wrist right", code: "LA11161-9" },
  { display: "Thigh left", code: "LA21901-6" },
  { display: "Thigh right", code: "LA21900-8" },
  { display: "Ankle left", code: "LA21903-2" },
  { display: "Ankle right", code: "LA21902-4" },
  { display: "Finger", code: "LA11862-2" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const vitalsPulseMeasurement = [
  { code: "895bd118-3d4a-47c1-9f72-eedd2993621c", display: "Temporal" },
  { code: "LA24031-9", display: "Carotid artery" },
  { code: "LA24033-5", display: "Brachial artery" },
  { code: "LA24032-7", display: "Femoral artery" },
  { code: "LA24030-1", display: "Radial artery" },
  { code: "914e32f9-474e-4484-b5e3-19be6ef461ac", display: "Popliteal artery" },
  { code: "LA24034-3", display: "Dorsalis pedis artery" },
  { code: "LA24035-0", display: "Posterior tibial artery" },
  { code: "LA24036-8", display: "Cardiac apex" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const temperatureUnit = [
  {
    code: "celcius",
    display: "ºC",
    definition: "Celcius",
  },
  {
    code: "fahrenheit",
    display: "ºF",
    definition: "Fahrenheit",
  },
];
