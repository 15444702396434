import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Age",
    code: "age",
    options: [
      { display: "< 60 years", code: nanoid(), result: 0, default: true },
      { display: "60-79 years", code: nanoid(), result: 1 },
      { display: "≥ 80 years", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Shock",
    code: "shock",
    options: [
      {
        display: "No shock (SBP ≥100 and HR <100)",
        code: nanoid(),
        result: 0,
        default: true,
      },
      {
        display: "Tachycardia (SBP ≥100 and HR ≥100)",
        code: nanoid(),
        result: 1,
      },
      { display: "Hypotension (SBP <100)", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Comorbidities",
    code: "comorbidities",
    options: [
      {
        display: "No major comorbidity",
        code: nanoid(),
        result: 0,
        default: true,
      },
      {
        display:
          "Any comorbidity EXCEPT renal failure, liver failure, and/or disseminated malignancy",
        code: nanoid(),
        result: 2,
      },
      {
        display: "Renal failure, liver failure, and/or disseminated malignancy",
        code: nanoid(),
        result: 3,
      },
    ],
  },
];

export default function RockallUGIBPreEndoscopyComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (value) {
      case 0:
        return "0.2% chance of mortality";
      case 1:
        return "2.4% chance of mortality";
      case 2:
        return "5.6% chance of mortality";
      case 3:
        return "11% chance of mortality";
      case 4:
        return "24.6% chance of mortality";
      case 5:
        return "39.6% chance of mortality";
      case 6:
        return "48.9% chance of mortality";
      case 7:
        return "50% chance of mortality";
      default:
        return "";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
