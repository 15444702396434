export const organizationTypes = [
  { code: "prov", display: "Healthcare Provider" },
  { code: "dept", display: "Hospital Department" },
  { code: "team", display: "Organizational Team" },
  { code: "govt", display: "Government" },
  { code: "ins", display: "Insurance company" },
  { code: "pay", display: "Payer" },
  { code: "edu", display: "Educational Institute" },
  { code: "reli", display: "Religious Institution" },
  { code: "crs", display: "Clinical Research Sponsor" },
  { code: "cg", display: "Community Group" },
  { code: "bus", display: "Non-Healthcare Business or Corporation" },
  { code: "other", display: "Other" },
];
