import { FormControl, TextField, Typography } from "@mui/material";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { updateMetaData } from "../VitalsFormHelper";
import CodingSystems from "../../../../../../constants/CodingSystems";
import { createDefaultVital } from ".";
import { testRegExp } from "../../../../../../utils/regex";

export default function HeadCircumferenceInput({
  value,
  disabled,
  size,
  encounter,
  patient,
  onChange,
}: VitalsInputProps) {
  const practitioner = usePractitioner();

  const handleChange = (event) => {
    const targetValue = event.target.value;
    const valid = testRegExp(
      "non-negative integer or empty string",
      targetValue,
    );
    if (!valid) return;

    const vital = createDefaultVital(
      encounter,
      patient,
      CodingSystems.OBSERVATION_CODE,
      "8287-5",
      "Head Circumference",
      "cm",
      targetValue,
    );
    updateMetaData(vital, practitioner);
    onChange(vital);
  };

  return (
    <FormControl fullWidth>
      <TextField
        id="vitals-head-circumference"
        value={value?.valueQuantity?.value ?? ""}
        disabled={disabled}
        label="HC"
        inputMode="numeric"
        InputProps={{
          endAdornment: (
            <Typography pl={1} variant="body2">
              cm
            </Typography>
          ),
        }}
        size={size}
        variant="outlined"
        type="text"
        onChange={handleChange}
      />
    </FormControl>
  );
}
