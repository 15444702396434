import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CalculatorOption } from "../types/calculatorOption";

type Props = {
  group: string;
  subtitle?: string;
  value: CalculatorOption | undefined;
  options: CalculatorOption[];
  onChange: (option: CalculatorOption | undefined) => void;
};

export default function Choice({
  group,
  value,
  subtitle,
  options,
  onChange,
}: Props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      <Box flex={3}>
        <Typography>{group}</Typography>
        {subtitle && (
          <Typography color="GrayText" variant="body2">
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box flex={9} width="100%">
        <FormControl fullWidth sx={{ justifyContent: "flex-end" }}>
          <RadioGroup
            row
            value={value?.code ?? null}
            sx={{ justifyContent: "flex-end" }}
            onChange={(e) => {
              onChange(options.find((o) => o.code === e.target.value));
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.code}
                value={option.code}
                control={<Radio />}
                label={option.display}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
