import { useEffect, useRef } from "react";

export function useKeyCombination(
  callback: () => void,
  ...combinations: string[][]
) {
  const pressed = useRef(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (pressed.current) return;

      for (const combination of combinations) {
        const allKeysPressed = combination.every((key) => {
          if (key === "Meta") return e.metaKey;
          if (key === "Control") return e.ctrlKey;
          return e.code === key;
        });

        if (allKeysPressed) {
          pressed.current = true;
          e.preventDefault();
          callback();
          break;
        }
      }
    };

    const handleKeyUp = () => {
      pressed.current = false;
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [callback, combinations]);
}
