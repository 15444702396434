export const compositionStatus = [
  { code: "registered", display: "Registered" },
  { code: "partial", display: "Partial" },
  { code: "preliminary", display: "Preliminary" },
  { code: "final", display: "Final" },
  { code: "amended", display: "Amended" },
  { code: "corrected", display: "Corrected" },
  { code: "appended", display: "Appended" },
  { code: "cancelled", display: "Cancelled" },
  { code: "entered-in-error", display: "Entered in Error" },
  { code: "deprecated", display: "Deprecated" },
  { code: "unknown", display: "Unknown" },
];

export const medicalClinicCompositionTypes = [
  { code: "28636-9", display: "Initial evaluation note" },
  { code: "18736-9", display: "Physician Initial evaluation note " },
  { code: "11505-5", display: "Physician procedure note" },
  { code: "18735-1", display: "Physical therapy Initial evaluation note" },
  { code: "11508-9", display: "Physical therapy Progress note" },
  { code: "11506-3", display: "Progress note" },
  { code: "28570-0", display: "Procedure note" },
  { code: "29749-9", display: "Dialysis records" },
  { code: "11486-8", display: "Chemotherapy records" },
  { code: "11504-8", display: "Surgical operation note" },
  { code: "11502-2", display: "Laboratory report" },
  { code: "28568-4", display: "Physician Emergency department Note" },
  { code: "15507-7", display: "Emergency department Progress note" },
  {
    code: "18763-3",
    display: "Physician consulting Initial evaluation note",
  },
  { code: "28569-2", display: "Physician consulting Progress note" },
  { code: "28575-9", display: "Nurse practitioner Progress note" },
  { code: "11536-0", display: "Nurse notes" },
  { code: "28656-7", display: "Social work Progress note" },
  { code: "18841-7", display: "Hospital consultations Document" },
  { code: "11488-4", display: "Consult note" },
  { code: "28616-1", display: "Physician Transfer note" },
  { code: "18761-7", display: "Transfer summary note" },
  { code: "18842-5", display: "Discharge summary" },
  { code: "18776-5", display: "Plan of care note" },
  {
    code: "52522a61-1a3b-40a5-bf17-47c07431d4ce",
    display: "Ultrasound findings",
  },
  { code: "18743-5", display: "Autopsy report" },
];

export const compositionTypes = [
  { code: "100018-1", display: "Hospice care Note" },
  {
    code: "100029-8",
    display:
      "Cancer related multigene analysis in Plasma cell-free DNA by Molecular genetics method",
  },
  {
    code: "100213-8",
    display:
      "Prostate cancer multigene analysis in Blood or Tissue by Molecular genetics method",
  },
  { code: "100214-6", display: "Dental model prior authorization Document" },
  {
    code: "100215-3",
    display: "Episode of care medical records Document Transplant surgery",
  },
  { code: "100216-1", display: "Surgical synoptic report" },
  { code: "100217-9", display: "Surgical oncology synoptic report" },
  { code: "100218-7", display: "Surgical oncology of colon synoptic report" },
  {
    code: "100219-5",
    display: "Surgical oncology of melanoma synoptic report",
  },
  {
    code: "100220-3",
    display: "Surgical oncology of breast synoptic report",
  },
  {
    code: "100221-1",
    display: "Surgical oncology of thyroid synoptic report",
  },
  {
    code: "100222-9",
    display: "Surgical oncology of pancreas synoptic report",
  },
  { code: "100225-2", display: "Bone density quantitative ultrasound study" },
  {
    code: "100226-0",
    display: "Small bowel capsule endoscopy study Document",
  },
  { code: "100227-8", display: "Colon capsule endoscopy study Document" },
  {
    code: "100228-6",
    display: "Upper gastrointestinal capsule endoscopy study Document",
  },
  { code: "100229-4", display: "Endoscopic ultrasound study Document" },
  { code: "100231-0", display: "Endobronchial ultrasound study" },
  { code: "100282-3", display: "Vaccine exemption certificate" },
  { code: "100349-0", display: "US.doppler Penis vessels W vasodilator IV" },
  { code: "100369-8", display: "NM Thyroid gland Views W Tl-201 IV" },
  {
    code: "100438-1",
    display: "Adolescent medicine Outpatient Progress note",
  },
  {
    code: "100439-9",
    display: "Allergy and Immunology Outpatient Progress note",
  },
  { code: "100440-7", display: "Allergy Outpatient Progress note" },
  { code: "100441-5", display: "Attending Outpatient Progress note" },
  { code: "100442-3", display: "Audiology Outpatient Progress note" },
  { code: "100443-1", display: "Bariatric surgery Outpatient Progress note" },
  {
    code: "100444-9",
    display: "Blood banking and transfusion medicine Outpatient Progress note",
  },
  {
    code: "100445-6",
    display: "Bone Marrow Transplant Outpatient Progress note",
  },
  { code: "100446-4", display: "Breastfeeding Outpatient Progress note" },
  { code: "100447-2", display: "Burn management Outpatient Progress note" },
  { code: "100448-0", display: "Cardiac surgery Outpatient Progress note" },
  { code: "100449-8", display: "Cardiopulmonary Outpatient Progress note" },
  {
    code: "100450-6",
    display: "Child and adolescent psychiatry Outpatient Progress note",
  },
  {
    code: "100451-4",
    display: "Chiropractic medicine Outpatient Progress note",
  },
  {
    code: "100452-2",
    display: "Clinical cardiac electrophysiology Outpatient Progress note",
  },
  { code: "100453-0", display: "Clinical genetics Outpatient Progress note" },
  {
    code: "100454-8",
    display: "Clinical neurophysiology Outpatient Progress note",
  },
  {
    code: "100455-5",
    display: "Clinical pathology Outpatient Progress note",
  },
  {
    code: "100456-3",
    display: "Colon and rectal surgery Outpatient Progress note",
  },
  { code: "100457-1", display: "Consultant Outpatient Progress note" },
  {
    code: "100458-9",
    display: "Outpatient COVID-19 Intubation Progress note",
  },
  { code: "100459-7", display: "Outpatient COVID-19 Progress note" },
  { code: "100460-5", display: "Dermatology Outpatient Progress note" },
  {
    code: "100461-3",
    display: "Developmental-behavioral pediatrics Outpatient Progress note",
  },
  {
    code: "100462-1",
    display: "Dialysis and Therapeutic apheresis Outpatient Progress note",
  },
  { code: "100463-9", display: "Eating disorders Outpatient Progress note" },
  { code: "100464-7", display: "Endocrinology Outpatient Progress note" },
  { code: "100465-4", display: "Family medicine Outpatient Progress note" },
  { code: "100466-2", display: "Gastroenterology Outpatient Progress note" },
  {
    code: "100467-0",
    display: "Geriatric medicine Outpatient Progress note",
  },
  {
    code: "100468-8",
    display: "Gynecologic oncology Outpatient Progress note",
  },
  { code: "100469-6", display: "Gynecology Outpatient Progress note" },
  {
    code: "100470-4",
    display: "Healthcare navigator Outpatient Progress note",
  },
  { code: "100471-2", display: "Heart failure Outpatient Progress note" },
  {
    code: "100472-0",
    display: "Heart failure+Transplant cardiology Outpatient Progress note",
  },
  { code: "100473-8", display: "Hematology Outpatient Progress note" },
  {
    code: "100474-6",
    display: "Hematology+Medical oncology Outpatient Progress note",
  },
  { code: "100475-3", display: "Hepatology Outpatient Progress note" },
  { code: "100476-1", display: "Immunology Outpatient Progress note" },
  {
    code: "100477-9",
    display: "Infectious disease Outpatient Progress note",
  },
  { code: "100478-7", display: "Internal medicine Outpatient Progress note" },
  {
    code: "100479-5",
    display: "Interventional cardiology Outpatient Progress note",
  },
  {
    code: "100480-3",
    display: "Interventional radiology Outpatient Progress note",
  },
  {
    code: "100481-1",
    display: "Outpatient Mechanical circulatory support Progress note",
  },
  {
    code: "100482-9",
    display: "Medical Aid in Dying Outpatient Progress note",
  },
  { code: "100483-7", display: "Mental health Outpatient Progress note" },
  {
    code: "100484-5",
    display: "Multi-specialty program Outpatient Progress note",
  },
  {
    code: "100485-2",
    display: "Neonatal perinatal medicine Outpatient Progress note",
  },
  { code: "100486-0", display: "Nephrology Outpatient Progress note" },
  {
    code: "100487-8",
    display: "Neurological surgery Outpatient Progress note",
  },
  { code: "100488-6", display: "Neurology Outpatient Progress note" },
  {
    code: "100489-4",
    display:
      "Neurology with special qualifications in child neurology Outpatient Progress note",
  },
  {
    code: "100490-2",
    display: "Nurse practitioner Outpatient Progress note",
  },
  { code: "100491-0", display: "Nurse Outpatient Progress note" },
  {
    code: "100492-8",
    display: "Nutrition and dietetics Outpatient Progress note",
  },
  {
    code: "100493-6",
    display: "Obstetrics and Gynecology Outpatient Progress note",
  },
  { code: "100494-4", display: "Obstetrics Outpatient Progress note" },
  {
    code: "100495-1",
    display: "Occupational therapy Outpatient Progress note",
  },
  { code: "100496-9", display: "Oncology Outpatient Progress note" },
  { code: "100497-7", display: "Ophthalmology Outpatient Progress note" },
  { code: "100498-5", display: "Optometry Outpatient Progress note" },
  {
    code: "100499-3",
    display: "Oral and Maxillofacial Surgery Outpatient Progress note",
  },
  {
    code: "100500-8",
    display: "Orthopaedic surgery Outpatient Progress note",
  },
  {
    code: "100501-6",
    display: "Orthotics prosthetics Outpatient Progress note",
  },
  { code: "100502-4", display: "Otolaryngology Outpatient Progress note" },
  { code: "100503-2", display: "Pain medicine Outpatient Progress note" },
  { code: "100504-0", display: "Palliative care Outpatient Progress note" },
  {
    code: "100505-7",
    display: "Pediatric cardiology Outpatient Progress note",
  },
  {
    code: "100506-5",
    display: "Pediatric dermatology Outpatient Progress note",
  },
  {
    code: "100507-3",
    display: "Pediatric endocrinology Outpatient Progress note",
  },
  {
    code: "100508-1",
    display: "Pediatric gastroenterology Outpatient Progress note",
  },
  {
    code: "100509-9",
    display: "Pediatric hematology-oncology Outpatient Progress note",
  },
  {
    code: "100510-7",
    display: "Pediatric infectious diseases Outpatient Progress note",
  },
  {
    code: "100511-5",
    display: "Pediatric otolaryngology Outpatient Progress note",
  },
  {
    code: "100512-3",
    display: "Pediatric pulmonology Outpatient Progress note",
  },
  {
    code: "100513-1",
    display: "Pediatric rheumatology Outpatient Progress note",
  },
  { code: "100514-9", display: "Pediatric surgery Outpatient Progress note" },
  {
    code: "100515-6",
    display: "Pediatric transplant hepatology Outpatient Progress note",
  },
  { code: "100516-4", display: "Pediatric urology Outpatient Progress note" },
  { code: "100517-2", display: "Pharmacogenomics Outpatient Progress note" },
  {
    code: "100518-0",
    display: "Physical medicine and rehab Outpatient Progress note",
  },
  { code: "100519-8", display: "Physical therapy Outpatient Progress note" },
  { code: "100520-6", display: "Plastic surgery Outpatient Progress note" },
  { code: "100521-4", display: "Podiatry Outpatient Progress note" },
  { code: "100522-2", display: "Psychiatry Hospital Progress note" },
  { code: "100523-0", display: "Psychology Outpatient Progress note" },
  { code: "100524-8", display: "Pulmonary Outpatient Progress note" },
  {
    code: "100525-5",
    display: "Radiation oncology Outpatient Progress note",
  },
  { code: "100526-3", display: "Radiology Outpatient Progress note" },
  {
    code: "100527-1",
    display: "Recreational therapy Outpatient Progress note",
  },
  {
    code: "100528-9",
    display:
      "Reproductive endocrinology and infertility Outpatient Progress note",
  },
  { code: "100529-7", display: "Research Outpatient Progress note" },
  { code: "100530-5", display: "Rheumatology Outpatient Progress note" },
  { code: "100531-3", display: "Sleep medicine Outpatient Progress note" },
  { code: "100532-1", display: "Social worker Outpatient Progress note" },
  {
    code: "100533-9",
    display: "Solid Organ Transplant Outpatient Progress note",
  },
  {
    code: "100534-7",
    display: "Speech-language pathology Outpatient Progress note",
  },
  { code: "100535-4", display: "Spinal surgery Outpatient Progress note" },
  { code: "100536-2", display: "Sports medicine Outpatient Progress note" },
  {
    code: "100537-0",
    display: "Surgery of the hand Outpatient Progress note",
  },
  { code: "100538-8", display: "Telehealth+Outpatient Progress note" },
  { code: "100539-6", display: "Thromboembolism Outpatient Progress note" },
  {
    code: "100540-4",
    display: "Transplant surgery Outpatient Progress note",
  },
  { code: "100541-2", display: "Trauma Outpatient Progress note" },
  { code: "100542-0", display: "Urology Outpatient Progress note" },
  {
    code: "100543-8",
    display: "Vascular neurology Outpatient Progress note",
  },
  { code: "100544-6", display: "Vascular surgery Outpatient Progress note" },
  {
    code: "100545-3",
    display: "Wound care management Outpatient Progress note",
  },
  {
    code: "100546-1",
    display: "Wound, Ostomy, and Continence Care Outpatient Progress note",
  },
  { code: "100547-9", display: "Adolescent medicine Hospital Progress note" },
  {
    code: "100548-7",
    display: "Allergy and Immunology Hospital Progress note",
  },
  { code: "100549-5", display: "Allergy Hospital Progress note" },
  { code: "100550-3", display: "Attending Hospital Progress note" },
  { code: "100551-1", display: "Audiology Hospital Progress note" },
  { code: "100552-9", display: "Bariatric surgery Hospital Progress note" },
  {
    code: "100553-7",
    display: "Blood banking and transfusion medicine Hospital Progress note",
  },
  {
    code: "100554-5",
    display: "Bone Marrow Transplant Hospital Progress note",
  },
  { code: "100555-2", display: "Breastfeeding Hospital Progress note" },
  { code: "100556-0", display: "Burn management Hospital Progress note" },
  { code: "100557-8", display: "Cardiac surgery Hospital Progress note" },
  { code: "100558-6", display: "Cardiopulmonary Hospital Progress note" },
  {
    code: "100559-4",
    display: "Child and adolescent psychiatry Hospital Progress note",
  },
  {
    code: "100560-2",
    display: "Clinical cardiac electrophysiology Hospital Progress note",
  },
  { code: "100561-0", display: "Clinical genetics Hospital Progress note" },
  {
    code: "100562-8",
    display: "Clinical neurophysiology Hospital Progress note",
  },
  { code: "100563-6", display: "Clinical pathology Hospital Progress note" },
  {
    code: "100564-4",
    display: "Colon and rectal surgery Hospital Progress note",
  },
  { code: "100565-1", display: "Consultant Hospital Progress note" },
  { code: "100566-9", display: "Dentistry Hospital Progress note" },
  { code: "100567-7", display: "Dermatology Hospital Progress note" },
  {
    code: "100568-5",
    display: "Developmental-behavioral pediatrics Hospital Progress note",
  },
  {
    code: "100569-3",
    display: "Dialysis and Therapeutic apheresis Hospital Progress note",
  },
  { code: "100570-1", display: "Eating disorders Hospital Progress note" },
  { code: "100571-9", display: "Endocrinology Hospital Progress note" },
  { code: "100572-7", display: "Family medicine Hospital Progress note" },
  { code: "100573-5", display: "Gastroenterology Hospital Progress note" },
  { code: "100574-3", display: "General medicine Hospital Progress note" },
  { code: "100575-0", display: "Geriatric medicine Hospital Progress note" },
  {
    code: "100576-8",
    display: "Gynecologic oncology Hospital Progress note",
  },
  { code: "100577-6", display: "Gynecology Hospital Progress note" },
  {
    code: "100578-4",
    display: "Healthcare navigator Hospital Progress note",
  },
  { code: "100579-2", display: "Heart failure Hospital Progress note" },
  {
    code: "100580-0",
    display: "Heart failure+Transplant cardiology Hospital Progress note",
  },
  { code: "100581-8", display: "Hematology Hospital Progress note" },
  {
    code: "100582-6",
    display: "Hematology+Medical oncology Hospital Progress note",
  },
  { code: "100583-4", display: "Hepatology Hospital Progress note" },
  { code: "100584-2", display: "Immunology Hospital Progress note" },
  { code: "100585-9", display: "Infectious disease Hospital Progress note" },
  { code: "100586-7", display: "Internal medicine Hospital Progress note" },
  {
    code: "100587-5",
    display: "Interventional cardiology Hospital Progress note",
  },
  {
    code: "100588-3",
    display: "Interventional radiology Hospital Progress note",
  },
  {
    code: "100589-1",
    display: "Hospital Mechanical circulatory support Progress note",
  },
  {
    code: "100590-9",
    display: "Medical Aid in Dying Hospital Progress note",
  },
  { code: "100591-7", display: "Mental health Hospital Progress note" },
  {
    code: "100592-5",
    display: "Multi-specialty program Hospital Progress note",
  },
  {
    code: "100593-3",
    display: "Neonatal perinatal medicine Hospital Progress note",
  },
  { code: "100594-1", display: "Nephrology Hospital Progress note" },
  {
    code: "100595-8",
    display: "Neurological surgery Hospital Progress note",
  },
  { code: "100596-6", display: "Neurology Hospital Progress note" },
  {
    code: "100597-4",
    display:
      "Neurology with special qualifications in child neurology Hospital Progress note",
  },
  { code: "100598-2", display: "Nurse practitioner Hospital Progress note" },
  { code: "100599-0", display: "Nurse Hospital Progress note" },
  {
    code: "100600-6",
    display: "Nutrition and dietetics Hospital Progress note",
  },
  {
    code: "100601-4",
    display: "Obstetrics and Gynecology Hospital Progress note",
  },
  { code: "100602-2", display: "Obstetrics Hospital Progress note" },
  {
    code: "100603-0",
    display: "Occupational therapy Hospital Progress note",
  },
  { code: "100604-8", display: "Oncology Hospital Progress note" },
  { code: "100605-5", display: "Ophthalmology Hospital Progress note" },
  {
    code: "100606-3",
    display: "Oral and Maxillofacial Surgery Hospital Progress note",
  },
  { code: "100607-1", display: "Orthopaedic surgery Hospital Progress note" },
  {
    code: "100608-9",
    display: "Orthotics prosthetics Hospital Progress note",
  },
  { code: "100609-7", display: "Otolaryngology Hospital Progress note" },
  { code: "100610-5", display: "Pain medicine Hospital Progress note" },
  { code: "100611-3", display: "Palliative care Hospital Progress note" },
  { code: "100612-1", display: "Pastoral care Hospital Progress note" },
  {
    code: "100613-9",
    display: "Pediatric cardiology Hospital Progress note",
  },
  {
    code: "100614-7",
    display: "Pediatric dermatology Hospital Progress note",
  },
  {
    code: "100615-4",
    display: "Pediatric endocrinology Hospital Progress note",
  },
  {
    code: "100616-2",
    display: "Pediatric gastroenterology Hospital Progress note",
  },
  {
    code: "100617-0",
    display: "Pediatric hematology-oncology Hospital Progress note",
  },
  {
    code: "100618-8",
    display: "Pediatric infectious diseases Hospital Progress note",
  },
  {
    code: "100619-6",
    display: "Pediatric otolaryngology Hospital Progress note",
  },
  {
    code: "100620-4",
    display: "Pediatric pulmonology Hospital Progress note",
  },
  {
    code: "100621-2",
    display: "Pediatric rheumatology Hospital Progress note",
  },
  { code: "100622-0", display: "Pediatric surgery Hospital Progress note" },
  {
    code: "100623-8",
    display: "Pediatric transplant hepatology Hospital Progress note",
  },
  { code: "100624-6", display: "Pharmacogenomics Hospital Progress note" },
  { code: "100625-3", display: "Pharmacology Hospital Progress note" },
  {
    code: "100626-1",
    display: "Physical medicine and rehab Hospital Progress note",
  },
  { code: "100627-9", display: "Physical therapy Hospital Progress note" },
  { code: "100628-7", display: "Plastic surgery Hospital Progress note" },
  { code: "100629-5", display: "Podiatry Hospital Progress note" },
  { code: "100630-3", display: "Psychology Hospital Progress note" },
  { code: "100631-1", display: "Radiation oncology Hospital Progress note" },
  { code: "100632-9", display: "Radiology Hospital Progress note" },
  { code: "100633-7", display: "Rapid response team Hospital Progress note" },
  {
    code: "100634-5",
    display: "Recreational therapy Hospital Progress note",
  },
  {
    code: "100635-2",
    display:
      "Reproductive endocrinology and infertility Hospital Progress note",
  },
  { code: "100636-0", display: "Research Hospital Progress note" },
  { code: "100637-8", display: "Rheumatology Hospital Progress note" },
  { code: "100638-6", display: "Sleep medicine Hospital Progress note" },
  { code: "100639-4", display: "Social worker Hospital Progress note" },
  {
    code: "100640-2",
    display: "Solid Organ Transplant Hospital Progress note",
  },
  {
    code: "100641-0",
    display: "Speech-language pathology Hospital Progress note",
  },
  { code: "100642-8", display: "Spinal surgery Hospital Progress note" },
  { code: "100643-6", display: "Surgery of the hand Hospital Progress note" },
  { code: "100644-4", display: "Thromboembolism Hospital Progress note" },
  { code: "100645-1", display: "Trauma Hospital Progress note" },
  { code: "100646-9", display: "Urology Hospital Progress note" },
  { code: "100647-7", display: "Vascular neurology Hospital Progress note" },
  { code: "100648-5", display: "Vascular surgery Hospital Progress note" },
  {
    code: "100649-3",
    display: "Wound care management Hospital Progress note",
  },
  {
    code: "100650-1",
    display: "Wound, Ostomy, and Continence Care Hospital Progress note",
  },
  { code: "100651-9", display: "Hospital COVID-19 Intubation Progress note" },
  { code: "100655-0", display: "XR Sella turcica Lateral" },
  {
    code: "100656-8",
    display: "Maternal and fetal medicine Outpatient Progress note",
  },
  {
    code: "100657-6",
    display:
      "Maternal and fetal medicine Telephone encounter+Hospital Progress note",
  },
  { code: "100658-4", display: "Hospital COVID-19 Progress note" },
  {
    code: "100659-2",
    display: "Maternal and fetal medicine Hospital Progress note",
  },
  {
    code: "100660-0",
    display:
      "Maternal and fetal medicine Telephone encounter+Outpatient Progress note",
  },
  { code: "100661-8", display: "Psychiatry Outpatient Progress note" },
  { code: "100697-2", display: "Interventional radiology Outpatient Note" },
  {
    code: "100698-0",
    display: "Social Determinants of Health screening report Document",
  },
  {
    code: "100718-6",
    display: "Undersea and hyperbaric medicine Discharge summary",
  },
  { code: "100719-4", display: "Surgical oncology Discharge summary" },
  {
    code: "100720-2",
    display: "Reproductive endocrinology and infertility Discharge summary",
  },
  { code: "100721-0", display: "Community health care Discharge summary" },
  { code: "100722-8", display: "Integrative medicine Discharge summary" },
  { code: "100723-6", display: "Sports medicine Discharge summary" },
  { code: "100724-4", display: "Diabetology Discharge summary" },
  { code: "100725-1", display: "Optometry Discharge summary" },
  { code: "100726-9", display: "Podiatry Discharge summary" },
  { code: "100727-7", display: "Chiropractic medicine Discharge summary" },
  { code: "100728-5", display: "Cardiac surgery Discharge summary" },
  { code: "100729-3", display: "Breastfeeding Discharge summary" },
  { code: "100730-1", display: "Transplant surgery Discharge summary" },
  { code: "100731-9", display: "Urgent care center Discharge summary" },
  {
    code: "100736-8",
    display: "Acylcarnitine Analysis Post Mortem in Specimen Document",
  },
  {
    code: "100754-1",
    display:
      "Platelet disorders multigene analysis in Blood or Tissue by Sequencing",
  },
  { code: "100760-8", display: "XR Radius and Ulna - left Single view" },
  {
    code: "100761-6",
    display:
      "PALB2 gene targeted mutation analysis in Blood or Tissue by Molecular genetics method",
  },
  { code: "100852-3", display: "NM Bones Views for metastasis W Sr-89 IV" },
  { code: "100853-1", display: "NM Pericardial space Pericardial effusion" },
  { code: "100875-4", display: "Fetal Heart Document limited US" },
  { code: "100876-2", display: "Fetal Heart Document US" },
  { code: "100877-0", display: "US Heart Transesophageal during surgery" },
  {
    code: "100878-8",
    display: "Endoscopic ultrasound study Upper GI tract Document",
  },
  {
    code: "100879-6",
    display: "US Guidance for cryoablation of Breast - right",
  },
  {
    code: "100880-4",
    display: "US Guidance for cryoablation of Breast - left",
  },
  { code: "100881-2", display: "MR Guidance for cryoablation of Bone" },
  {
    code: "100882-0",
    display: "MR Peritoneum W contrast percutaneous intraperitoneal",
  },
  { code: "100883-8", display: "MR Guidance for biopsy of Kidney - left" },
  { code: "100884-6", display: "MR Guidance for biopsy of Kidney - right" },
  { code: "100966-1", display: "Oncofertility consultation note" },
  { code: "100967-9", display: "Fetal Document Fetal Heart Tracing" },
  { code: "11485-0", display: "Anesthesia records" },
  { code: "11486-8", display: "Chemotherapy records" },
  { code: "11488-4", display: "Consult note" },
  { code: "11490-0", display: "Physician Discharge summary" },
  {
    code: "11492-6",
    display: "Provider-unspecifed, History and physical note",
  },
  {
    code: "11494-2",
    display: "Deprecated Physician Initial assessment note at First encounter",
  },
  {
    code: "11495-9",
    display:
      "Deprecated Physical therapy Initial assessment note at First encounter",
  },
  {
    code: "11496-7",
    display: "Deprecated Podiatry Initial assessment note at First encounter",
  },
  {
    code: "11497-5",
    display: "Deprecated Psychology Initial assessment note at First encounter",
  },
  {
    code: "11498-3",
    display:
      "Deprecated Social work Initial assessment note at First encounter",
  },
  {
    code: "11499-1",
    display:
      "Deprecated Speech therapy Initial assessment note at First encounter",
  },
  {
    code: "11500-6",
    display:
      "Deprecated Occupational therapy Initial assessment note at First encounter",
  },
  { code: "11502-2", display: "Laboratory report" },
  { code: "11503-0", display: "Medical records" },
  { code: "11504-8", display: "Surgical operation note" },
  { code: "11505-5", display: "Physician procedure note" },
  { code: "11506-3", display: "Progress note" },
  { code: "11507-1", display: "Occupational therapy Progress note" },
  { code: "11508-9", display: "Physical therapy Progress note" },
  { code: "11509-7", display: "Podiatry Progress note" },
  { code: "11510-5", display: "Psychology Progress note" },
  { code: "11512-1", display: "Speech-language pathology Progress note" },
  {
    code: "11514-7",
    display: "Chiropractic Episode of care medical records",
  },
  {
    code: "11515-4",
    display: "Physical therapy Episode of care medical records",
  },
  { code: "11516-2", display: "Physician Episode of care medical records" },
  { code: "11517-0", display: "Podiatry Episode of care medical records" },
  { code: "11518-8", display: "Psychology Episode of care medical records" },
  {
    code: "11519-6",
    display: "Social service Episode of care medical records",
  },
  {
    code: "11520-4",
    display: "Speech therapy Episode of care medical records",
  },
  {
    code: "11521-2",
    display: "Occupational therapy Episode of care medical records",
  },
  { code: "11522-0", display: "Deprecated Cardiac echo study" },
  { code: "11523-8", display: "EEG study" },
  { code: "11524-6", display: "EKG study" },
  { code: "11525-3", display: "US for pregnancy" },
  { code: "11526-1", display: "Pathology study" },
  { code: "11527-9", display: "Psychiatry study" },
  {
    code: "11528-7",
    display: "Deprecated Radiology Unspecified site and modality Study",
  },
  { code: "11529-5", display: "Surgical pathology study" },
  { code: "11534-5", display: "Temperature charts" },
  { code: "11536-0", display: "Deprecated Nurse Notes" },
  { code: "11538-6", display: "Deprecated CT Chest study" },
  { code: "11539-4", display: "Deprecated CT Head study" },
  { code: "11540-2", display: "Deprecated CT Abdomen study" },
  { code: "11541-0", display: "Deprecated MRI Brain study" },
  { code: "11542-8", display: "Deprecated Provider-unspecified visit note" },
  { code: "11543-6", display: "Nursery records" },
  { code: "15507-7", display: "Emergency department Progress note" },
  { code: "15508-5", display: "Labor and delivery records" },
  { code: "17787-3", display: "Deprecated NM Thyroid gland Study report" },
  { code: "18594-2", display: "Psychiatric service attachment" },
  { code: "18682-5", display: "Ambulance records" },
  { code: "18733-6", display: "Attending Progress note" },
  {
    code: "18734-4",
    display: "Occupational therapy Initial evaluation note",
  },
  { code: "18735-1", display: "Physical therapy Initial evaluation note" },
  { code: "18736-9", display: "Physician Initial evaluation note" },
  { code: "18737-7", display: "Podiatry Initial evaluation note" },
  { code: "18738-5", display: "Psychology Initial evaluation note" },
  { code: "18739-3", display: "Social worker Initial evaluation note" },
  {
    code: "18740-1",
    display: "Speech-language pathology Initial evaluation note",
  },
  {
    code: "18741-9",
    display: "Deprecated Attending physician progress note",
  },
  { code: "18742-7", display: "Arthroscopy study" },
  { code: "18743-5", display: "Autopsy report" },
  { code: "18744-3", display: "Bronchoscopy study" },
  { code: "18745-0", display: "Cardiac catheterization study" },
  { code: "18746-8", display: "Colonoscopy study" },
  { code: "18747-6", display: "Deprecated CT Unspecified system Study" },
  { code: "18748-4", display: "Diagnostic imaging study" },
  { code: "18749-2", display: "Electromyogram study" },
  { code: "18750-0", display: "Cardiac electrophysiology study" },
  { code: "18751-8", display: "Endoscopy study" },
  { code: "18752-6", display: "Exercise stress test study" },
  { code: "18753-4", display: "Flexible sigmoidoscopy study" },
  {
    code: "18754-2",
    display: "Ambulatory cardiac rhythm monitor (Holter) study",
  },
  { code: "18755-9", display: "Deprecated MRI Unspecified site study" },
  { code: "18756-7", display: "Deprecated MR Spine study" },
  { code: "18757-5", display: "Deprecated Nuclear medicine study" },
  {
    code: "18758-3",
    display: "Deprecated PET scan Unspecified system Study",
  },
  { code: "18759-1", display: "Spirometry study" },
  { code: "18760-9", display: "Deprecated US Unspecified system Study" },
  { code: "18761-7", display: "Transfer summary note" },
  { code: "18762-5", display: "Deprecated Chiropractor Progress note" },
  { code: "18763-3", display: "Consultant Initial evaluation note" },
  { code: "18764-1", display: "Deprecated Nurse practitioner Progress note" },
  { code: "18765-8", display: "Deprecated Podiatry visit note" },
  { code: "18766-6", display: "Deprecated Psychology visit note" },
  { code: "18776-5", display: "Plan of care note" },
  {
    code: "18823-5",
    display: "Alcohol and/or substance abuse service attachment",
  },
  { code: "18824-3", display: "Cardiac service attachment" },
  { code: "18825-0", display: "Medical social services attachment" },
  { code: "18826-8", display: "Occupational therapy service attachment" },
  { code: "18836-7", display: "Cardiac stress study Procedure" },
  { code: "18841-7", display: "Hospital consultations Document" },
  { code: "18842-5", display: "Discharge summary" },
  { code: "19002-5", display: "Physical therapy service attachment" },
  { code: "19003-3", display: "Respiratory therapy service attachment" },
  {
    code: "19004-1",
    display: "Deprecated Skilled nursing service attachment",
  },
  { code: "24531-6", display: "US Retroperitoneum" },
  { code: "24532-4", display: "US Abdomen RUQ" },
  { code: "24533-2", display: "MRA Abdominal vessels W contrast IV" },
  { code: "24534-0", display: "US.doppler Abdominal vessels" },
  { code: "24535-7", display: "XR Acetabulum Views" },
  { code: "24536-5", display: "XR Acromioclavicular Joint Views" },
  {
    code: "24537-3",
    display: "US Guidance for aspiration of amniotic fluid of Uterus",
  },
  { code: "24538-1", display: "MR Ankle" },
  { code: "24539-9", display: "MR Ankle WO and W contrast IV" },
  { code: "24540-7", display: "XR Ankle 2 Views" },
  { code: "24541-5", display: "XR Ankle Views" },
  { code: "24542-3", display: "US Anus" },
  {
    code: "24543-1",
    display:
      "RFA Guidance for angioplasty of Thoracic and abdominal aorta-- W contrast IA",
  },
  { code: "24544-9", display: "CT Thoracic Aorta" },
  { code: "24545-6", display: "CT Thoracic Aorta W contrast IV" },
  { code: "24546-4", display: "Deprecated RFA Aortic arch W contrast IA" },
  { code: "24547-2", display: "US Thoracic and abdominal aorta" },
  { code: "24548-0", display: "US Appendix" },
  { code: "24549-8", display: "MRA Upper extremity vessels W contrast IV" },
  {
    code: "24550-6",
    display: "RFA Upper extremity veins Views W contrast IV",
  },
  { code: "24551-4", display: "RFA AV fistula Views W contrast IA" },
  { code: "24552-2", display: "RF Stent Views W contrast intra stent" },
  {
    code: "24553-0",
    display:
      "RFA Guidance for embolectomy of Intracranial vessel-- W contrast IV",
  },
  {
    code: "24554-8",
    display: "RFA Guidance for embolization of Artery-- W contrast IA",
  },
  {
    code: "24555-5",
    display: "RFA Guidance for placement of stent in Artery",
  },
  { code: "24556-3", display: "MR Abdomen" },
  { code: "24557-1", display: "MR Abdomen WO and W contrast IV" },
  { code: "24558-9", display: "US Abdomen" },
  {
    code: "24559-7",
    display:
      "US Guidance for drainage and placement of drainage catheter of Abdomen",
  },
  {
    code: "24560-5",
    display: "Portable XR Abdomen AP left lateral-decubitus",
  },
  { code: "24561-3", display: "XR Abdomen AP left lateral-decubitus" },
  {
    code: "24562-1",
    display: "XR Abdomen Left lateral-decubitus and Right lateral-decubitus",
  },
  { code: "24563-9", display: "XR Abdomen AP right lateral-decubitus" },
  { code: "24564-7", display: "Portable XR Abdomen AP upright" },
  { code: "24566-2", display: "CT Retroperitoneum" },
  {
    code: "24567-0",
    display: "Deprecated Abdomen>Retroperitoneum CT W contrast",
  },
  {
    code: "24568-8",
    display: "RFA Guidance for atherectomy of AV fistula-- W contrast IV",
  },
  { code: "24569-6", display: "RFA AV shunt Views W contrast IV" },
  {
    code: "24570-4",
    display:
      "RF Guidance for removal of calculus from Biliary duct common-- W contrast retrograde intra biliary",
  },
  {
    code: "24571-2",
    display:
      "NM Biliary ducts and Gallbladder Views for patency of biliary structures and ejection fraction W sincalide and W radionuclide IV",
  },
  {
    code: "24572-0",
    display:
      "NM Biliary ducts and Gallbladder Views for patency of biliary structures W Tc-99m IV",
  },
  {
    code: "24573-8",
    display: "XR Biliary ducts and Gallbladder Views W contrast IV",
  },
  {
    code: "24574-6",
    display:
      "RF Biliary ducts and Gallbladder Views during surgery W contrast biliary duct",
  },
  {
    code: "24575-3",
    display:
      "RF Biliary ducts and Gallbladder Views W contrast percutaneous transhepatic",
  },
  {
    code: "24576-1",
    display: "RFA Urinary bladder arteries Views W contrast IA",
  },
  { code: "24577-9", display: "XR Bone Views during surgery" },
  { code: "24578-7", display: "SPECT Bones" },
  { code: "24579-5", display: "XR Long bones Survey Views" },
  {
    code: "24580-3",
    display:
      "RFA Guidance for angioplasty of Brachiocephalic artery-- W contrast IA",
  },
  {
    code: "24581-1",
    display: "RFA Subclavian artery and Brachial artery Views W contrast IA",
  },
  { code: "24582-9", display: "MR Brachial plexus" },
  { code: "24583-7", display: "MR Brachial plexus WO and W contrast IV" },
  { code: "24584-5", display: "MRA Thoracic inlet vessels W contrast IV" },
  {
    code: "24585-2",
    display: "CT Guidance for stereotactic biopsy of Head-- W contrast IV",
  },
  { code: "24586-0", display: "MR Brain W anesthesia" },
  { code: "24587-8", display: "MR Brain WO and W contrast IV" },
  {
    code: "24588-6",
    display: "MR Brain WO and W contrast IV and W anesthesia",
  },
  { code: "24589-4", display: "MR Brain W contrast IV" },
  { code: "24590-2", display: "MR Brain" },
  {
    code: "24591-0",
    display: "NM Brain Brain death protocol Views W Tc-99m HMPAO IV",
  },
  { code: "24593-6", display: "MRA Head vessels W contrast IV" },
  {
    code: "24594-4",
    display: "MG Guidance for aspiration of cyst of Breast",
  },
  {
    code: "24595-1",
    display: "MG Guidance for needle localization of mass of Breast",
  },
  { code: "24596-9", display: "US Breast specimen" },
  { code: "24597-7", display: "MG Breast specimen Views" },
  { code: "24598-5", display: "MG Guidance for fluid aspiration of Breast" },
  { code: "24599-3", display: "US Breast limited" },
  {
    code: "24600-9",
    display: "US Guidance for needle localization of Breast",
  },
  { code: "24601-7", display: "US Breast" },
  { code: "24602-5", display: "MG Guidance for biopsy of Breast" },
  { code: "24603-3", display: "MG stereo Guidance for biopsy of Breast" },
  { code: "24604-1", display: "MG Breast Diagnostic Limited Views" },
  { code: "24605-8", display: "MG Breast Diagnostic" },
  { code: "24606-6", display: "MG Breast Screening" },
  {
    code: "24609-0",
    display: "MG Guidance for percutaneous biopsy.core needle of Breast",
  },
  { code: "24610-8", display: "MG Breast Limited Views" },
  { code: "24611-6", display: "Outpatient Consultation 2nd opinion" },
  { code: "24612-4", display: "XR Calcaneus Views" },
  {
    code: "24613-2",
    display:
      "Deprecated RFA Guidance for placement of catheter in artery in Central cardiovascular artery",
  },
  {
    code: "24614-0",
    display:
      "RFA Guidance for angioplasty of Carotid artery extracranial-- W contrast IA",
  },
  {
    code: "24615-7",
    display:
      "RFA Guidance for angioplasty of Carotid artery.intracranial-- W contrast IA",
  },
  { code: "24616-5", display: "US Carotid arteries" },
  { code: "24617-3", display: "RFA Carotid arteries Views W contrast IA" },
  { code: "24619-9", display: "XR Wrist Views" },
  {
    code: "24620-7",
    display: "RF Catheter Views for patency check W contrast via catheter",
  },
  {
    code: "24621-5",
    display:
      "RF Guidance for percutaneous drainage and placement of drainage catheter of Unspecified body region",
  },
  { code: "24622-3", display: "RFA Celiac artery Views W contrast IA" },
  {
    code: "24623-1",
    display: "CT Guidance for nerve block of Celiac plexus",
  },
  {
    code: "24624-9",
    display: "RFA Guidance for change of CV catheter in Vein-- W contrast IV",
  },
  {
    code: "24625-6",
    display:
      "RFA Guidance for placement of CV catheter in Vein-- W contrast IV",
  },
  {
    code: "24626-4",
    display:
      "RFA Guidance for reposition of CV catheter in Vein-- W contrast IV",
  },
  { code: "24627-2", display: "CT Chest" },
  { code: "24628-0", display: "CT Chest W contrast IV" },
  { code: "24629-8", display: "MR Chest" },
  { code: "24630-6", display: "US Chest" },
  {
    code: "24631-4",
    display:
      "RF Unspecified body region Views for central venous catheter placement check",
  },
  { code: "24632-2", display: "Portable XR Chest Views AP" },
  {
    code: "24634-8",
    display: "Portable XR Chest Views W inspiration and expiration",
  },
  {
    code: "24635-5",
    display: "XR Chest PA upright Views W inspiration and expiration",
  },
  { code: "24636-3", display: "Portable XR Chest AP left lateral-decubitus" },
  { code: "24637-1", display: "XR Chest AP left lateral-decubitus" },
  { code: "24638-9", display: "Portable XR Chest Left lateral upright" },
  { code: "24639-7", display: "XR Chest Left lateral upright" },
  { code: "24640-5", display: "XR Chest Apical lordotic" },
  { code: "24641-3", display: "Portable XR Chest Left oblique" },
  { code: "24642-1", display: "XR Chest AP and PA upright" },
  { code: "24643-9", display: "XR Chest PA and Lateral and Oblique upright" },
  { code: "24644-7", display: "Portable XR Chest PA and Lateral upright" },
  {
    code: "24645-4",
    display:
      "Portable XR Chest PA and Right lateral and Right oblique and Left oblique upright",
  },
  {
    code: "24646-2",
    display:
      "XR Chest PA and Right lateral and Right oblique and Left oblique upright",
  },
  { code: "24647-0", display: "XR Chest PA and Lateral upright" },
  { code: "24648-8", display: "XR Chest PA upright" },
  {
    code: "24649-6",
    display:
      "Portable XR Chest Right lateral-decubitus and Left lateral-decubitus",
  },
  {
    code: "24650-4",
    display: "XR Chest Right lateral-decubitus and Left lateral-decubitus",
  },
  {
    code: "24651-2",
    display: "XR Chest Right oblique and Left oblique upright",
  },
  {
    code: "24652-0",
    display: "Portable XR Chest AP right lateral-decubitus",
  },
  { code: "24653-8", display: "XR Chest AP and AP right lateral-decubitus" },
  {
    code: "24654-6",
    display: "Portable XR Chest AP and AP right lateral-decubitus",
  },
  { code: "24655-3", display: "RF Chest Image intensifier during surgery" },
  { code: "24656-1", display: "RF Chest Single view during surgery" },
  { code: "24657-9", display: "XR tomography Chest" },
  {
    code: "24658-7",
    display: "RFA Thoracic and abdominal aorta Views W contrast IA",
  },
  { code: "24659-5", display: "MRA Chest vessels W contrast IV" },
  { code: "24660-3", display: "MRA Thoracic Aorta" },
  {
    code: "24661-1",
    display: "RF Pleural space Views W contrast intra pleural space",
  },
  {
    code: "24662-9",
    display: "US Guidance for fluid aspiration of Pleural space",
  },
  {
    code: "24663-7",
    display: "NM Cerebral cisterns Views W radionuclide IT",
  },
  { code: "24664-5", display: "XR Clavicle Views" },
  { code: "24665-2", display: "XR Sacrum and Coccyx Views" },
  { code: "24666-0", display: "RF Colon Views W air and barium contrast PR" },
  { code: "24667-8", display: "RF Colon Views W contrast PR" },
  {
    code: "24668-6",
    display: "RF Colon Single view for transit Post solid contrast",
  },
  { code: "24669-4", display: "RF Colon Views W water soluble contrast PR" },
  {
    code: "24670-2",
    display: "US Guidance for biopsy of cyst of Unspecified body region",
  },
  {
    code: "24671-0",
    display: "RF Guidance for aspiration of cyst of Unspecified body region",
  },
  { code: "24672-8", display: "US Diaphragm for motion" },
  {
    code: "24673-6",
    display:
      "Deprecated Duodenum Radio fluoroscopy W contrast PO and hypotonic agent per ng",
  },
  { code: "24674-4", display: "MR Elbow" },
  { code: "24675-1", display: "MR Elbow WO and W contrast IV" },
  { code: "24676-9", display: "XR Elbow Views" },
  { code: "24677-7", display: "US Pelvis transvaginal" },
  { code: "24678-5", display: "RF Esophagus Views W contrast PO" },
  { code: "24679-3", display: "RF Esophagus Views W Gastrografin PO" },
  { code: "24680-1", display: "RF Guidance for dilation of Esophagus" },
  {
    code: "24681-9",
    display: "RF videography Hypopharynx and Esophagus Views",
  },
  {
    code: "24682-7",
    display:
      "RF videography Hypopharynx and Esophagus Views W liquid and paste contrast PO during swallowing",
  },
  { code: "24683-5", display: "NM Esophagus+Stomach Views W Tc-99m SC PO" },
  {
    code: "24684-3",
    display:
      "RFA Guidance for embolectomy of Extracranial vessels-- W contrast IA",
  },
  { code: "24685-0", display: "RFA Peripheral veins Views W contrast IV" },
  { code: "24686-8", display: "XR Lower extremity Views" },
  { code: "24687-6", display: "MR Lower Extremity Joint" },
  { code: "24688-4", display: "MR Upper extremity" },
  { code: "24689-2", display: "XR Upper extremity Views" },
  { code: "24690-0", display: "CT Extremity" },
  { code: "24691-8", display: "CT Extremity W contrast IV" },
  {
    code: "24692-6",
    display:
      "US Guidance for drainage and placement of drainage catheter of Extremity",
  },
  { code: "24693-4", display: "US Extremity" },
  { code: "24694-2", display: "MR Face WO and W contrast IV" },
  { code: "24695-9", display: "XR Facial bones Views" },
  { code: "24696-7", display: "CT Facial bones" },
  { code: "24697-5", display: "CT Facial bones W contrast IV" },
  {
    code: "24698-3",
    display: "RFA Guidance for angioplasty of Femoral artery-- W contrast IA",
  },
  { code: "24699-1", display: "RFA Femoral artery Runoff W contrast IA" },
  { code: "24700-7", display: "XR Femur and Tibia Views for leg length" },
  { code: "24702-3", display: "MR Thigh" },
  { code: "24703-1", display: "MR Thigh WO and W contrast IV" },
  { code: "24704-9", display: "XR Femur Views" },
  { code: "24705-6", display: "MR Finger" },
  { code: "24706-4", display: "XR Finger Views" },
  { code: "24707-2", display: "MR Foot" },
  { code: "24708-0", display: "XR Foot Views W standing" },
  { code: "24709-8", display: "XR Foot Views" },
  { code: "24710-6", display: "MR Forearm" },
  { code: "24711-4", display: "US Gallbladder" },
  { code: "24712-2", display: "XR Gallbladder Views W contrast PO" },
  { code: "24713-0", display: "XR Gallbladder Views 48H post contrast PO" },
  {
    code: "24714-8",
    display:
      "NM Gastrointestinal tract Views for gastrointestinal bleeding W Tc-99m tagged RBC IV",
  },
  {
    code: "24715-5",
    display: "RF Gastrointestinal tract upper Single view W contrast PO",
  },
  {
    code: "24716-3",
    display:
      "RF Guidance for placement of decompression tube in Gastrointestinal tract",
  },
  { code: "24717-1", display: "Deprecated XR Ileal conduit Loopogram" },
  {
    code: "24718-9",
    display: "RF Guidance for transjugular biopsy of Liver-- W contrast IV",
  },
  { code: "24719-7", display: "US Groin" },
  { code: "24720-5", display: "MR Hand" },
  { code: "24721-3", display: "XR Hand 2 Views" },
  { code: "24722-1", display: "XR Hand 3 Views" },
  { code: "24723-9", display: "XR Hand Arthritis" },
  { code: "24724-7", display: "XR Wrist and Hand Bone age Views" },
  { code: "24725-4", display: "CT Head" },
  { code: "24726-2", display: "CT Head WO and W contrast IV" },
  { code: "24727-0", display: "CT Head W contrast IV" },
  { code: "24728-8", display: "CT perfusion Head" },
  { code: "24729-6", display: "CT perfusion Head WO and W contrast IV" },
  { code: "24730-4", display: "NM Brain Views" },
  { code: "24731-2", display: "US Head" },
  { code: "24732-0", display: "US Head during surgery" },
  { code: "24733-8", display: "US.doppler Head vessels" },
  { code: "24734-6", display: "CT Cerebral cisterns W contrast IT" },
  {
    code: "24735-3",
    display: "MR Internal auditory canal and Posterior fossa",
  },
  {
    code: "24740-3",
    display:
      "MR Internal auditory canal and Posterior fossa WO and W contrast IV",
  },
  { code: "24745-2", display: "XR Petrous part of temporal bone Views" },
  { code: "24746-0", display: "Deprecated Head Sagittal Sinus MR" },
  {
    code: "24747-8",
    display: "Deprecated Head Sagittal Sinus MR angiogram W contrast IV",
  },
  { code: "24748-6", display: "MR Heart" },
  { code: "24750-2", display: "NM Heart Views at rest and W Tl-201 IV" },
  {
    code: "24751-0",
    display: "NM Parathyroid gland Views W TI-201 subtraction Tc-99m IV",
  },
  { code: "24752-8", display: "RF videography Heart Views" },
  { code: "24753-6", display: "CT Unspecified body region W contrast IV" },
  {
    code: "24754-4",
    display: "Administration of vasodilator into catheter Vein",
  },
  {
    code: "24755-1",
    display: "RFA Guidance for atherectomy of Vein-- W contrast IV",
  },
  { code: "24756-9", display: "RFA Guidance for placement of stent in Vein" },
  { code: "24757-7", display: "Deprecated Coronary arteries CT fast" },
  { code: "24760-1", display: "US Hip" },
  { code: "24761-9", display: "XR Hip Single view" },
  { code: "24762-7", display: "XR Hip Views" },
  { code: "24764-3", display: "RF Hip Arthrogram" },
  { code: "24765-0", display: "XR Humerus 2 Views" },
  {
    code: "24766-8",
    display: "RFA Guidance for angioplasty of Iliac artery-- W contrast IA",
  },
  { code: "24767-6", display: "XR tomography Internal auditory canal" },
  { code: "24769-2", display: "CT Guidance for injection of Joint space" },
  { code: "24770-0", display: "NM Joint Views W In-111 Intrajoint" },
  {
    code: "24771-8",
    display: "RF Guidance for arthrocentesis of Joint space",
  },
  { code: "24772-6", display: "US Guidance for biopsy of Kidney" },
  {
    code: "24773-4",
    display: "NM Kidney Views W radionuclide transplant scan",
  },
  { code: "24776-7", display: "NM Kidney Views" },
  {
    code: "24778-3",
    display: "XR Kidney - bilateral 3 Serial Views WO and W contrast IV",
  },
  {
    code: "24779-1",
    display:
      "RF Guidance for percutaneous placement of nephrostomy tube of Kidney - bilateral-- W contrast via tube",
  },
  {
    code: "24780-9",
    display: "RF Kidney - bilateral Views W contrast via nephrostomy tube",
  },
  {
    code: "24781-7",
    display:
      "RF Guidance for exchange of nephrostomy tube of Kidney - bilateral-- W contrast",
  },
  {
    code: "24782-5",
    display:
      "RF Guidance for percutaneous placement of nephroureteral stent of Kidney - bilateral-- W contrast via stent",
  },
  { code: "24783-3", display: "RF Kidney - bilateral Views for urodynamics" },
  {
    code: "24784-1",
    display: "XR tomography Kidney - bilateral WO and W contrast IV",
  },
  {
    code: "24787-4",
    display:
      "XR tomography Kidney - bilateral WO contrast and 10M post contrast IV",
  },
  { code: "24788-2", display: "XR Kidney - bilateral Views W contrast IV" },
  { code: "24789-0", display: "XR tomography Kidney - bilateral" },
  {
    code: "24790-8",
    display: "XR tomography Kidney - bilateral W contrast IV",
  },
  {
    code: "24792-4",
    display: "Portable XR Abdomen AP and AP left lateral-decubitus",
  },
  { code: "24793-2", display: "Portable XR Abdomen AP and Lateral" },
  { code: "24794-0", display: "XR Abdomen AP and Lateral" },
  { code: "24795-7", display: "Portable XR Abdomen Supine and Upright" },
  { code: "24796-5", display: "XR Abdomen AP and AP left lateral-decubitus" },
  { code: "24797-3", display: "XR Abdomen AP and Oblique prone" },
  { code: "24798-1", display: "XR Abdomen Supine and Upright" },
  { code: "24799-9", display: "XR Abdomen AP" },
  { code: "24800-5", display: "RF Knee Arthrogram" },
  { code: "24801-3", display: "XR Knee Merchants" },
  { code: "24802-1", display: "MR Knee" },
  { code: "24803-9", display: "MR Knee WO and W contrast IV" },
  { code: "24804-7", display: "NM Knee Views" },
  { code: "24805-4", display: "XR Knee AP and Lateral W standing" },
  { code: "24806-2", display: "XR Knee 2 Views" },
  { code: "24807-0", display: "XR Knee AP W standing" },
  { code: "24808-8", display: "XR Knee AP and PA W standing" },
  { code: "24809-6", display: "XR Knee Views W standing" },
  { code: "24811-2", display: "CT Guidance for fluid aspiration of Liver" },
  { code: "24812-0", display: "CT Guidance for biopsy of Liver" },
  { code: "24813-8", display: "CT Guidance for core needle biopsy of Liver" },
  { code: "24814-6", display: "CT Liver" },
  { code: "24815-3", display: "CT Liver W contrast IV" },
  { code: "24816-1", display: "US Guidance for biopsy of Liver" },
  { code: "24817-9", display: "SPECT Liver W Tc-99m IV" },
  { code: "24818-7", display: "US Diaphragm and Liver" },
  {
    code: "24819-5",
    display: "Deprecated Liver and Spleen NM W Tc-99m calcium colloid IV",
  },
  { code: "24820-3", display: "MRA Lower leg vessels W contrast IV" },
  { code: "24821-1", display: "MR Lower leg" },
  { code: "24822-9", display: "CT Guidance for fluid aspiration of Lung" },
  { code: "24823-7", display: "CT Guidance for biopsy of Lung" },
  { code: "24824-5", display: "NM Lung Portable Views" },
  { code: "24825-2", display: "XR Lung Views W contrast intrabronchial" },
  {
    code: "24826-0",
    display: "NM Lymphatic vessels Views W radionuclide intra lymphatic",
  },
  {
    code: "24827-8",
    display: "RFA Lymphatic vessels Views W contrast intra lymphatic",
  },
  { code: "24828-6", display: "XR tomography Mandible Panoramic" },
  { code: "24829-4", display: "XR Mandible Views" },
  { code: "24830-2", display: "XR Mastoid Views" },
  {
    code: "24831-0",
    display: "NM Small bowel Views for Meckel's diverticulum W Tc-99m M04 IV",
  },
  {
    code: "24832-8",
    display:
      "RFA Guidance for angioplasty of Mesenteric artery-- W contrast IA",
  },
  { code: "24833-6", display: "RFA Mesenteric artery Views W contrast IA" },
  { code: "24834-4", display: "XR Nasal bones Views" },
  { code: "24835-1", display: "CT Nasopharynx and Neck" },
  { code: "24836-9", display: "CT Nasopharynx and Neck W contrast IV" },
  { code: "24837-7", display: "CT Guidance for fluid aspiration of Neck" },
  { code: "24838-5", display: "CT Guidance for biopsy of Neck" },
  { code: "24839-3", display: "MR Neck" },
  { code: "24840-1", display: "MR Neck WO and W contrast IV" },
  { code: "24841-9", display: "MR Neck W contrast IV" },
  { code: "24842-7", display: "US Neck" },
  { code: "24843-5", display: "XR Neck Lateral" },
  { code: "24844-3", display: "MRA Neck vessels W contrast IV" },
  { code: "24845-0", display: "RF Neck Views W contrast intra larynx" },
  { code: "24846-8", display: "XR Optic foramen Views" },
  { code: "24848-4", display: "CT Orbit - bilateral" },
  { code: "24849-2", display: "CT Orbit - bilateral WO and W contrast IV" },
  { code: "24850-0", display: "CT Orbit - bilateral W contrast IV" },
  { code: "24851-8", display: "MR Orbit - bilateral WO and W contrast IV" },
  { code: "24852-6", display: "MR Orbit - bilateral W contrast IV" },
  { code: "24853-4", display: "US Eye+Orbit - bilateral" },
  { code: "24854-2", display: "XR Orbit - bilateral Views" },
  { code: "24855-9", display: "RF videography Oropharynx Views" },
  {
    code: "24856-7",
    display: "CT Guidance for fluid aspiration of Pancreas",
  },
  { code: "24857-5", display: "CT Pancreas" },
  { code: "24858-3", display: "CT Pancreas W contrast IV" },
  { code: "24859-1", display: "US Pancreas" },
  { code: "24860-9", display: "RFA Pancreatic artery Views W contrast IA" },
  { code: "24861-7", display: "XR Patella 2 Views" },
  {
    code: "24862-5",
    display: "RFA Iliac artery Internal Views W contrast IA",
  },
  { code: "24863-3", display: "CT Guidance for fluid aspiration of Pelvis" },
  { code: "24864-1", display: "CT Guidance for biopsy of Pelvis" },
  { code: "24865-8", display: "CT Pelvis" },
  { code: "24866-6", display: "CT Pelvis W contrast IV" },
  { code: "24867-4", display: "MR Pelvis" },
  {
    code: "24868-2",
    display:
      "US Guidance for drainage and placement of drainage catheter of Pelvis",
  },
  { code: "24869-0", display: "US Pelvis" },
  { code: "24870-8", display: "US.doppler Pelvis vessels" },
  { code: "24871-6", display: "XR Pelvis Pelvimetry" },
  { code: "24872-4", display: "MR Pelvis and Hip" },
  { code: "24873-2", display: "MRA Pelvis vessels W contrast IV" },
  { code: "24874-0", display: "RFA Peripheral arteries Views W contrast IA" },
  {
    code: "24875-7",
    display: "Deprecated Peripheral vessel US.doppler Peripheral plane",
  },
  {
    code: "24876-5",
    display: "NM Head to Pelvis Views for shunt patency W Tc-99m DTPA IT",
  },
  { code: "24877-3", display: "CT Petrous part of temporal bone" },
  {
    code: "24878-1",
    display: "CT Petrous part of temporal bone W contrast IV",
  },
  {
    code: "24879-9",
    display: "MR Pituitary and Sella turcica WO and W contrast IV",
  },
  { code: "24880-7", display: "MR Pituitary and Sella turcica" },
  { code: "24881-5", display: "US Popliteal space" },
  {
    code: "24882-3",
    display:
      "RFA Guidance for percutaneous transluminal angioplasty of Popliteal artery-- W contrast IA",
  },
  {
    code: "24883-1",
    display: "US Guidance for percutaneous biopsy of Prostate",
  },
  { code: "24884-9", display: "US Prostate transrectal" },
  {
    code: "24885-6",
    display: "Deprecated US Guidance for repair of Pseudoaneurysm/AV fistula",
  },
  {
    code: "24887-2",
    display:
      "RFA Guidance for embolectomy of Pulmonary arteries-- W contrast IA",
  },
  {
    code: "24888-0",
    display:
      "NM Pulmonary system Ventilation and Perfusion W Xe-133 IH and W Tc-99m MAA IV",
  },
  { code: "24889-8", display: "US Pylorus for Pyloric Stenosis" },
  { code: "24891-4", display: "XR Radius and Ulna Views" },
  { code: "24892-2", display: "US Rectum" },
  {
    code: "24893-0",
    display: "RF Rectum Single view post contrast PR during defecation",
  },
  {
    code: "24894-8",
    display:
      "RF Rectum and Urinary bladder Views W contrast PR and intra bladder during defecation and voiding",
  },
  {
    code: "24896-3",
    display:
      "US Guidance for drainage and placement of drainage catheter of Kidney",
  },
  { code: "24899-7", display: "XR Ribs Views" },
  { code: "24900-3", display: "XR Sacroiliac Joint Views" },
  {
    code: "24901-1",
    display: "CT Guidance for injection of Sacroiliac Joint",
  },
  {
    code: "24902-9",
    display: "RF Salivary gland Views W contrast intra salivary duct",
  },
  { code: "24903-7", display: "XR Scapula Views" },
  {
    code: "24904-5",
    display: "CT Pituitary and Sella turcica WO and W contrast IV",
  },
  { code: "24905-2", display: "MR Shoulder" },
  { code: "24906-0", display: "MR Shoulder WO and W contrast IV" },
  { code: "24907-8", display: "US Shoulder" },
  { code: "24908-6", display: "XR Shoulder 3 Views" },
  { code: "24909-4", display: "XR Shoulder Views" },
  { code: "24910-2", display: "RF Shoulder Arthrogram" },
  { code: "24911-0", display: "RF Shunt Views" },
  {
    code: "24912-8",
    display: "RF Sinus tract Views W contrast intra sinus tract",
  },
  { code: "24913-6", display: "CT Sinuses limited" },
  { code: "24914-4", display: "MR Sinuses" },
  { code: "24915-1", display: "MR Sinuses W contrast IV" },
  { code: "24916-9", display: "XR Sinuses Views" },
  { code: "24917-7", display: "XR Skull Single view" },
  { code: "24918-5", display: "XR Skull 3 Views" },
  { code: "24919-3", display: "XR Skull AP and Lateral" },
  { code: "24920-1", display: "XR Skull Lateral" },
  { code: "24921-9", display: "XR Skull Waters" },
  { code: "24922-7", display: "XR Skull 5 Views" },
  {
    code: "24923-5",
    display: "RF Small bowel Views W positive contrast via enteroclysis tube",
  },
  { code: "24924-3", display: "RF Small bowel Views W contrast PO" },
  { code: "24925-0", display: "RFA Spinal artery Views W contrast IA" },
  { code: "24926-8", display: "US Spine" },
  { code: "24927-6", display: "RF Spine Views W contrast intradisc" },
  { code: "24928-4", display: "XR Spine AP and Lateral" },
  {
    code: "24929-2",
    display:
      "XR Thoracic and lumbar spine Views for scoliosis W flexion and W extension",
  },
  {
    code: "24930-0",
    display: "XR Thoracic and lumbar spine Views for scoliosis",
  },
  {
    code: "24931-8",
    display: "RF Guidance for injection of Spine facet joint",
  },
  { code: "24932-6", display: "CT Cervical spine" },
  { code: "24933-4", display: "CT Cervical spine W contrast IV" },
  { code: "24934-2", display: "CT Cervical spine W contrast IT" },
  { code: "24935-9", display: "MR Cervical spine" },
  { code: "24936-7", display: "MR Cervical spine W anesthesia" },
  { code: "24937-5", display: "MR Cervical spine WO and W contrast IV" },
  { code: "24938-3", display: "MR Cervical spine W contrast IV" },
  { code: "24939-1", display: "XR Cervical spine 5 Views" },
  { code: "24940-9", display: "XR Cervical spine Single view" },
  { code: "24941-7", display: "XR Cervical spine 3 Views" },
  { code: "24942-5", display: "XR Cervical spine AP and Lateral" },
  { code: "24943-3", display: "XR Cervical spine Lateral" },
  { code: "24944-1", display: "XR Cervical spine Swimmers" },
  {
    code: "24945-8",
    display: "XR Cervical spine Views W flexion and W extension",
  },
  { code: "24946-6", display: "XR Cervical spine Views" },
  { code: "24947-4", display: "RF Cervical spine Views W contrast IT" },
  {
    code: "24948-2",
    display: "XR Spine Cervical Odontoid and Cervical axis AP",
  },
  { code: "24963-1", display: "CT Lumbar spine" },
  { code: "24964-9", display: "CT Lumbar spine W contrast IV" },
  { code: "24965-6", display: "CT Lumbar spine W contrast IT" },
  { code: "24967-2", display: "MR Lumbar spine WO and W contrast IV" },
  { code: "24968-0", display: "MR Lumbar spine" },
  { code: "24969-8", display: "XR Lumbar spine Lateral" },
  { code: "24970-6", display: "XR Lumbar spine AP and Lateral" },
  {
    code: "24971-4",
    display: "XR Lumbar spine Views W flexion and W extension",
  },
  { code: "24972-2", display: "XR Lumbar spine Views" },
  {
    code: "24973-0",
    display: "RF Guidance for fluid aspiration of Lumbar spine space",
  },
  { code: "24974-8", display: "RF Lumbar spine Views W contrast IT" },
  {
    code: "24975-5",
    display: "XR Spine.lumbar and Sacroiliac joint - bilateral Views",
  },
  { code: "24977-1", display: "MR Lumbar spine W anesthesia" },
  { code: "24978-9", display: "CT Thoracic spine" },
  { code: "24979-7", display: "CT Thoracic spine W contrast IV" },
  { code: "24980-5", display: "MR Thoracic spine" },
  { code: "24981-3", display: "MR Thoracic spine WO and W contrast IV" },
  { code: "24982-1", display: "MR Thoracic spine W contrast IV" },
  { code: "24983-9", display: "XR Thoracic spine Views" },
  { code: "24984-7", display: "XR Thoracic and lumbar spine 2 Views" },
  { code: "24985-4", display: "RF Thoracic spine Views W contrast IT" },
  { code: "24986-2", display: "CT Guidance for biopsy of Spine" },
  { code: "24987-0", display: "CT Spine W contrast IV" },
  { code: "24988-8", display: "CT Spleen" },
  { code: "24989-6", display: "CT Spleen WO and W contrast IV" },
  { code: "24990-4", display: "US Spleen" },
  {
    code: "24991-2",
    display: "RFA Splenic vein and Portal vein Views W contrast IA",
  },
  { code: "24992-0", display: "RFA Splenic artery Views W contrast IA" },
  { code: "24993-8", display: "Deprecated X-ray" },
  { code: "24994-6", display: "XR Sternum Views" },
  {
    code: "24995-3",
    display: "RF Guidance for placement of tube in Stomach",
  },
  {
    code: "24996-1",
    display:
      "RF Guidance for percutaneous replacement of gastrostomy of Stomach",
  },
  {
    code: "24997-9",
    display: "NM Stomach Views for gastric emptying solid phase W Tc-99m SC PO",
  },
  {
    code: "24998-7",
    display: "RF Placement check of gastrostomy tube W contrast via GI tube",
  },
  { code: "24999-5", display: "MR Temporomandibular joint" },
  { code: "25000-1", display: "XR Temporomandibular joint Views" },
  {
    code: "25001-9",
    display: "NM Scrotum and testicle Views W Tc-99m pertechnetate IV",
  },
  { code: "25002-7", display: "US Scrotum and testicle" },
  { code: "25003-5", display: "MRA Thigh vessels W contrast IV" },
  { code: "25005-0", display: "RFA Three vessels Views W contrast" },
  { code: "25006-8", display: "XR Thumb Views" },
  { code: "25007-6", display: "NM Thyroid gland Views W I-131 IV" },
  {
    code: "25008-4",
    display: "NM Thyroid gland Views and Views uptake W I-131 IV",
  },
  { code: "25009-2", display: "US Guidance for biopsy of Thyroid gland" },
  { code: "25010-0", display: "US Thyroid gland" },
  { code: "25011-8", display: "XR Tibia and Fibula Views" },
  {
    code: "25012-6",
    display:
      "Deprecated RFA Guidance for angioplasty of Tibial artery-- W contrast IA",
  },
  { code: "25013-4", display: "XR Toes Views" },
  { code: "25014-2", display: "RFA Two vessels Views W contrast" },
  {
    code: "25015-9",
    display:
      "Replacement of percutaneous gastrojejunostomy Upper GI tract Document",
  },
  { code: "25016-7", display: "RF Urethra Views W contrast intra urethra" },
  {
    code: "25017-5",
    display: "RF Urinary bladder and Urethra Views W contrast intra bladder",
  },
  { code: "25018-3", display: "NM Urinary bladder Views" },
  { code: "25019-1", display: "US Urinary bladder" },
  {
    code: "25020-9",
    display:
      "RF Urinary bladder and Urethra Views W contrast retrograde via urethra",
  },
  {
    code: "25022-5",
    display: "RF Uterus and Fallopian tubes Views W contrast IU",
  },
  { code: "25023-3", display: "RFA Vein Views W contrast IV" },
  {
    code: "25024-1",
    display:
      "RFA Guidance for placement of peripherally-inserted central venous catheter in Vein",
  },
  { code: "25025-8", display: "RFA Vena cava Views W contrast IV" },
  {
    code: "25026-6",
    display:
      "RFA Guidance for placement of venous filter in Inferior vena cava-- W contrast IV",
  },
  {
    code: "25027-4",
    display: "Guidance for placement of large bore CV catheter in Vein",
  },
  {
    code: "25028-2",
    display:
      "RFA Guidance for placement of catheter for infusion of thrombolytic in Vessel-- W contrast intravascular",
  },
  {
    code: "25029-0",
    display:
      "RFA Guidance for placement of catheter for vasoconstrictor infusion in Vessels",
  },
  { code: "25030-8", display: "RFA Abdominal arteries Views W contrast IA" },
  { code: "25031-6", display: "NM Bone Views" },
  { code: "25032-4", display: "NM Bone Views W In-111 tagged WBC IV" },
  { code: "25033-2", display: "MR Wrist" },
  { code: "25034-0", display: "RF Wrist Arthrogram" },
  { code: "25035-7", display: "MR Wrist WO and W contrast IV" },
  { code: "25036-5", display: "US Wrist" },
  { code: "25038-1", display: "Courtesy consultation Document" },
  { code: "25039-9", display: "CT Unspecified body region limited" },
  { code: "25040-7", display: "Deprecated Unspecified body region CT 3D" },
  {
    code: "25041-5",
    display:
      "CT Guidance for aspiration or biopsy of Unspecified body region-- W contrast IV",
  },
  {
    code: "25042-3",
    display: "CT Guidance for aspiration or biopsy of Unspecified body region",
  },
  {
    code: "25043-1",
    display: "CT Guidance for fluid aspiration of Unspecified body region",
  },
  {
    code: "25044-9",
    display: "CT Guidance for biopsy of Unspecified body region",
  },
  { code: "25045-6", display: "CT Unspecified body region" },
  { code: "25046-4", display: "CT Unspecified body region W anesthesia" },
  {
    code: "25047-2",
    display: "CT Unspecified body region W conscious sedation",
  },
  {
    code: "25050-6",
    display:
      "Deprecated Unspecified body region CT 3D sagittal and coronal disarticulation",
  },
  {
    code: "25051-4",
    display: "Deprecated Unspecified body region CT Multisectional sagittal",
  },
  {
    code: "25052-2",
    display: "Deprecated Unspecified body region CT sagittal and coronal",
  },
  {
    code: "25053-0",
    display: "CT Guidance for radiosurgery of Unspecified body region",
  },
  {
    code: "25054-8",
    display:
      "CT Guidance for radiosurgery of Unspecified body region-- W contrast IV",
  },
  {
    code: "25055-5",
    display: "Deprecated Unspecified body region MR additional sequence",
  },
  { code: "25056-3", display: "MR Unspecified body region" },
  {
    code: "25057-1",
    display: "MR Unspecified body region W conscious sedation",
  },
  { code: "25058-9", display: "MRA Unspecified body region W contrast IV" },
  {
    code: "25059-7",
    display: "US Guidance for biopsy of Unspecified body region",
  },
  { code: "25060-5", display: "US Unspecified body region No charge" },
  { code: "25061-3", display: "US Unspecified body region" },
  { code: "25062-1", display: "XR Unspecified body region Comparison view" },
  { code: "25063-9", display: "RFA Vessel Single view W contrast IA" },
  {
    code: "25064-7",
    display: "RFA Vessel Views for angioplasty W contrast IA",
  },
  { code: "25065-4", display: "RF 15 minutes" },
  { code: "25066-2", display: "RF 30 minutes" },
  { code: "25067-0", display: "RF 45 minutes" },
  { code: "25068-8", display: "RF 1 hour" },
  {
    code: "25069-6",
    display: "RF Guidance for biopsy of Unspecified body region",
  },
  {
    code: "25070-4",
    display: "RF Unspecified body region Views during surgery",
  },
  { code: "25071-2", display: "XR tomography Unspecified body region" },
  { code: "25072-0", display: "Guidance for placement of infusion port" },
  {
    code: "25073-8",
    display: "RFA Guidance for removal of foreign body from Vessel",
  },
  { code: "25074-6", display: "XR Zygomatic arch Views" },
  { code: "25076-1", display: "RFA Hepatic artery Views W contrast IA" },
  {
    code: "25077-9",
    display:
      "RFA Guidance for placement of catheter in Hepatic artery-- W contrast IA",
  },
  {
    code: "25078-7",
    display: "RF Guidance for placement of stent in Intrahepatic portal system",
  },
  { code: "25079-5", display: "RFA Renal arteries Views W contrast IA" },
  {
    code: "25080-3",
    display:
      "RFA Renal vein - bilateral Views for renin sampling W contrast IV",
  },
  {
    code: "25081-1",
    display: "RFA Guidance for angioplasty of Renal vessel-- W contrast IA",
  },
  { code: "26064-6", display: "RFA Vein - bilateral Views W contrast IV" },
  { code: "26065-3", display: "RFA Vein - left Views W contrast IV" },
  { code: "26066-1", display: "RFA Vein - right Views W contrast IV" },
  {
    code: "26067-9",
    display:
      "RF Salivary gland - bilateral Views W contrast intra salivary duct",
  },
  {
    code: "26068-7",
    display: "RF Salivary gland - left Views W contrast intra salivary duct",
  },
  {
    code: "26069-5",
    display: "RF Salivary gland - right Views W contrast intra salivary duct",
  },
  { code: "26070-3", display: "RF Hip - bilateral Arthrogram" },
  { code: "26071-1", display: "RF Hip - left Arthrogram" },
  { code: "26072-9", display: "RF Hip - right Arthrogram" },
  { code: "26073-7", display: "RF Knee - bilateral Arthrogram" },
  { code: "26074-5", display: "RF Knee - left Arthrogram" },
  { code: "26075-2", display: "RF Knee - right Arthrogram" },
  { code: "26076-0", display: "RF Shoulder - bilateral Arthrogram" },
  { code: "26077-8", display: "RF Shoulder - left Arthrogram" },
  { code: "26078-6", display: "RF Shoulder - right Arthrogram" },
  {
    code: "26079-4",
    display: "RFA Carotid arteries - bilateral Views W contrast IA",
  },
  {
    code: "26080-2",
    display: "RFA Carotid arteries - left Views W contrast IA",
  },
  {
    code: "26081-0",
    display: "RFA Carotid arteries -right Views W contrast IA",
  },
  {
    code: "26082-8",
    display: "RFA Spinal artery - bilateral Views W contrast IA",
  },
  {
    code: "26083-6",
    display: "RFA Spinal artery - left Views W contrast IA",
  },
  {
    code: "26084-4",
    display: "RFA Spinal artery - right Views W contrast IA",
  },
  { code: "26085-1", display: "XR Knee - bilateral Views W standing" },
  { code: "26086-9", display: "XR Knee - left Views W standing" },
  { code: "26087-7", display: "XR Knee - right Views W standing" },
  { code: "26088-5", display: "NM Knee - bilateral Views" },
  { code: "26089-3", display: "NM Knee - left Views" },
  { code: "26090-1", display: "NM Knee - right Views" },
  {
    code: "26091-9",
    display:
      "NM Scrotum and Testicle - bilateral Views W Tc-99m pertechnetate IV",
  },
  {
    code: "26092-7",
    display: "NM Scrotum and Testicle - left Views W Tc-99m pertechnetate IV",
  },
  {
    code: "26093-5",
    display: "NM Scrotum and Testicle - right Views W Tc-99m pertechnetate IV",
  },
  { code: "26094-3", display: "XR Foot - bilateral Views W standing" },
  { code: "26095-0", display: "XR Foot - left Views W standing" },
  { code: "26096-8", display: "XR Foot - right Views W standing" },
  { code: "26097-6", display: "XR Ankle - bilateral Views" },
  { code: "26098-4", display: "XR Ankle - left Views" },
  { code: "26099-2", display: "XR Ankle - right Views" },
  { code: "26100-8", display: "XR Calcaneus - bilateral Views" },
  { code: "26101-6", display: "XR Calcaneus - left Views" },
  { code: "26102-4", display: "XR Calcaneus - right Views" },
  { code: "26106-5", display: "XR Clavicle - bilateral Views" },
  { code: "26107-3", display: "XR Clavicle - left Views" },
  { code: "26108-1", display: "XR Clavicle - right Views" },
  { code: "26109-9", display: "XR Elbow - bilateral Views" },
  { code: "26110-7", display: "XR Elbow - left Views" },
  { code: "26111-5", display: "XR Elbow - right Views" },
  { code: "26112-3", display: "XR Lower extremity - bilateral Views" },
  { code: "26113-1", display: "XR Lower extremity - left Views" },
  { code: "26114-9", display: "XR Lower extremity - right Views" },
  { code: "26115-6", display: "XR Upper extremity - bilateral Views" },
  { code: "26116-4", display: "XR Upper extremity - left Views" },
  { code: "26117-2", display: "XR Upper extremity - right Views" },
  { code: "26118-0", display: "XR Femur - bilateral Views" },
  { code: "26120-6", display: "XR Femur - left Views" },
  { code: "26122-2", display: "XR Femur - right Views" },
  { code: "26124-8", display: "XR Finger - bilateral Views" },
  { code: "26125-5", display: "XR Finger - left Views" },
  { code: "26126-3", display: "XR Finger - right Views" },
  { code: "26127-1", display: "XR Foot - bilateral Views" },
  { code: "26128-9", display: "XR Foot - left Views" },
  { code: "26129-7", display: "XR Foot - right Views" },
  { code: "26130-5", display: "XR Hip - bilateral Views" },
  { code: "26131-3", display: "XR Hip - left Views" },
  { code: "26132-1", display: "XR Hip - right Views" },
  { code: "26133-9", display: "XR Acetabulum - bilateral Views" },
  { code: "26134-7", display: "XR Acetabulum - left Views" },
  { code: "26135-4", display: "XR Acetabulum - right Views" },
  {
    code: "26136-2",
    display: "XR Acromioclavicular joint - bilateral Views",
  },
  { code: "26137-0", display: "XR Acromioclavicular joint - left Views" },
  { code: "26138-8", display: "XR Acromioclavicular joint - right Views" },
  { code: "26139-6", display: "XR Mastoid - bilateral Views" },
  { code: "26140-4", display: "XR Mastoid - left Views" },
  { code: "26141-2", display: "XR Mastoid - right Views" },
  { code: "26142-0", display: "XR Optic foramen - bilateral Views" },
  { code: "26143-8", display: "XR Optic foramen - left Views" },
  { code: "26144-6", display: "XR Optic foramen - right Views" },
  { code: "26146-1", display: "XR Radius and Ulna - bilateral Views" },
  { code: "26148-7", display: "XR Radius and Ulna - left Views" },
  { code: "26150-3", display: "XR Radius and Ulna - right Views" },
  { code: "26151-1", display: "XR Ribs - bilateral Views" },
  { code: "26152-9", display: "XR Ribs - left Views" },
  { code: "26153-7", display: "XR Ribs - right Views" },
  { code: "26154-5", display: "XR Scapula - bilateral Views" },
  { code: "26155-2", display: "XR Scapula - left Views" },
  { code: "26156-0", display: "XR Scapula - right Views" },
  { code: "26157-8", display: "XR Shoulder - bilateral Views" },
  { code: "26158-6", display: "XR Shoulder - left Views" },
  { code: "26159-4", display: "XR Shoulder - right Views" },
  { code: "26160-2", display: "XR Thumb - bilateral Views" },
  { code: "26161-0", display: "XR Thumb - left Views" },
  { code: "26162-8", display: "XR Thumb - right Views" },
  { code: "26163-6", display: "XR Tibia and Fibula - bilateral Views" },
  { code: "26164-4", display: "XR Tibia and Fibula - left Views" },
  { code: "26165-1", display: "XR Tibia and Fibula - right Views" },
  { code: "26166-9", display: "XR Toes - bilateral Views" },
  { code: "26167-7", display: "XR Toes - left Views" },
  { code: "26168-5", display: "XR Toes - right Views" },
  { code: "26169-3", display: "XR Wrist - bilateral Views" },
  { code: "26170-1", display: "XR Wrist - left Views" },
  { code: "26171-9", display: "XR Wrist - right Views" },
  { code: "26172-7", display: "XR Zygomatic arch - bilateral Views" },
  { code: "26173-5", display: "XR Zygomatic arch - left Views" },
  { code: "26174-3", display: "XR Zygomatic arch - right Views" },
  { code: "26175-0", display: "MG Breast - bilateral Screening" },
  { code: "26176-8", display: "MG Breast - left Screening" },
  { code: "26177-6", display: "MG Breast - right Screening" },
  {
    code: "26178-4",
    display: "RFA Femoral artery - bilateral Runoff W contrast IA",
  },
  {
    code: "26179-2",
    display: "RFA Femoral artery - left Runoff W contrast IA",
  },
  {
    code: "26180-0",
    display: "RFA Femoral artery - right Runoff W contrast IA",
  },
  {
    code: "26181-8",
    display: "MRA Thoracic inlet vessels - bilateral W contrast IV",
  },
  {
    code: "26182-6",
    display: "MRA Thoracic inlet vessels - left W contrast IV",
  },
  {
    code: "26183-4",
    display: "MRA Thoracic inlet vessels - right W contrast IV",
  },
  { code: "26184-2", display: "CT Extremity - bilateral W contrast IV" },
  { code: "26185-9", display: "CT Extremity - left W contrast IV" },
  { code: "26186-7", display: "CT Extremity - right W contrast IV" },
  { code: "26187-5", display: "MR Ankle - bilateral WO and W contrast IV" },
  { code: "26188-3", display: "MR Ankle - left WO and W contrast IV" },
  { code: "26189-1", display: "MR Ankle - right WO and W contrast IV" },
  {
    code: "26190-9",
    display: "MR Brachial plexus - bilateral WO and W contrast IV",
  },
  {
    code: "26191-7",
    display: "MR Brachial plexus - left WO and W contrast IV",
  },
  {
    code: "26192-5",
    display: "MR Brachial plexus - right WO and W contrast IV",
  },
  { code: "26193-3", display: "MR Elbow - bilateral WO and W contrast IV" },
  { code: "26194-1", display: "MR Elbow - left WO and W contrast IV" },
  { code: "26195-8", display: "MR Elbow - right WO and W contrast IV" },
  { code: "26196-6", display: "MR Thigh - bilateral WO and W contrast IV" },
  { code: "26197-4", display: "MR Thigh - left WO and W contrast IV" },
  { code: "26198-2", display: "MR Thigh - right WO and W contrast IV" },
  { code: "26199-0", display: "MR Knee - bilateral WO and W contrast IV" },
  { code: "26200-6", display: "MR Knee - left WO and W contrast IV" },
  { code: "26201-4", display: "MR Knee - right WO and W contrast IV" },
  {
    code: "26202-2",
    display: "MR Shoulder - bilateral WO and W contrast IV",
  },
  { code: "26203-0", display: "MR Shoulder - left WO and W contrast IV" },
  { code: "26204-8", display: "MR Shoulder - right WO and W contrast IV" },
];
