import { Box, Container } from "@mui/material";
import type { FC } from "react";

const Footer: FC = (props) => (
  <Box {...props}>
    <Container maxWidth="lg"></Container>
  </Box>
);

export default Footer;
