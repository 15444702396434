import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
import enUs from "date-fns/locale/en-US";

const date = new Date();

export const defaultStartDateTime = moment({
  year: date.getFullYear(),
  month: date.getMonth(),
  date: date.getDate(),
})
  .startOf("week")
  .toDate();
export const defaultEndDateTime = moment({
  year: date.getFullYear(),
  month: date.getMonth(),
  date: date.getDate(),
})
  .endOf("week")
  .toDate();

const locales = {
  "en-US": enUs,
};

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const Roles = {
  PRACTITIONER: "Practitioner",
  ADMINISTRATOR: "Administrator",
};
