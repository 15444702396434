export const alertReminderStatus = [
  { code: "active", display: "Active" },
  { code: "inactive", display: "Inactive" },
  { code: "entered-in-error", display: "Entered in Error" },
];

export const alertReminderCategory = [
  { code: "diet", display: "Diet" },
  { code: "drug", display: "Drug" },
  { code: "lab", display: "Lab" },
  { code: "admin", display: "Administrative" },
  { code: "contact", display: "Subject Contact" },
  { code: "clinical", display: "Clinical" },
  { code: "behavioral", display: "Behavioral" },
  { code: "research", display: "Research" },
  { code: "advance-direct", display: "Advance Directive" },
  { code: "safety", display: "Safety" },
];
