import { useState } from "react";
import { bmiCheckDisplay } from "../../../pages/health/encounters/cards/vitals/VitalsFormHelper";
import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import { Optional } from "../types/optional";

export default function MonstellerBMIBSAComponent() {
  const [values, setValues] = useState<{
    height: Optional<number>;
    weight: Optional<number>;
  }>({
    height: undefined,
    weight: undefined,
  });

  // height in cm
  const calculateBSA = (height: number = 0, weight: number = 0) => {
    return Math.sqrt((height * weight) / 3600);
  };

  // height in cm
  const calculateBMI = (height: number, weight: number) => {
    return weight / Math.pow(height / 100, 2);
  };

  const calculate = (
    method: "bmi" | "bsa",
    height: Optional<number>,
    weight: Optional<number>,
  ) => {
    try {
      const fn = method === "bmi" ? calculateBMI : calculateBSA;
      const result = fn(height, weight);
      if (Number.isNaN(result) || result === Infinity) {
        throw new Error("Invalid number");
      }

      return result;
    } catch (error) {
      return 0;
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Height"
            placeholder="Enter height"
            endAdornment={<Typography>cm</Typography>}
            onChange={(e) => {
              const value = e.target.value;
              const float = parseFloat(value);
              setValues({
                ...values,
                height: float,
              });
            }}
          />
          <CalculatorTextFieldItem
            text="Weight"
            placeholder="Enter weight"
            endAdornment={<Typography>kg</Typography>}
            onChange={(e) => {
              const value = e.target.value;
              const float = parseInt(value);
              setValues({
                ...values,
                weight: float,
              });
            }}
          />
        </Grid>
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const { height, weight } = values;
          const valid = [height, weight].every((e) => Number.isFinite(e));

          if (!valid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          const bmi = calculate("bmi", height, weight);
          const bsa = calculate("bsa", height, weight);
          const bmiDisplay = bmiCheckDisplay(bmi).bmiClassification;

          return (
            <>
              <Typography>
                BMI: <b>{bmi.toFixed(2)} kg/m²</b> ({bmiDisplay})
              </Typography>
              <Typography>
                BSA: <b>{bsa.toFixed(2)} m²</b>
              </Typography>
            </>
          );
        })()}
      </Box>
    </Box>
  );
}
