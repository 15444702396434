export const telRenderer = (telecom, type) => {
  return telecom?.filter((tel) => tel.system === type)?.length ? (
    <div>
      {telecom
        .filter((tel) => tel.system === type)
        .map((tel, index) => (
          <div key={index}>{`${tel?.use}: ${tel?.value}`}</div>
        ))}
    </div>
  ) : null;
};
