import { CircularProgress } from "@mui/material";
import type { FC } from "react";
import useFullPageLoader from "../../hooks/useFullPageLoader";
interface FullPageLoaderProps {
  loading: boolean;
}

const FullPageLoader: FC<FullPageLoaderProps> = ({ loading }) => {
  const [fullPageLoading] = useFullPageLoader();

  return (
    <>
      {(loading || fullPageLoading) && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
            background: "white",
            opacity: 0.4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
};

export default FullPageLoader;
