import { Auth0Client } from "@auth0/auth0-spa-js";
import { AxiosRequestConfig } from "axios";

export async function getAuthHeader(
  auth0Client: Auth0Client,
  rest?: Record<string, any>,
): Promise<AxiosRequestConfig> {
  const authToken = await auth0Client.getTokenSilently();
  return { headers: { Authorization: `Bearer ${authToken}`, ...rest } };
}
