import { Code } from "../../types/code";
export const Specialty = [
  { code: "408467006", display: "Adult mental illness" },
  { code: "394577000", display: "Anesthetics" },
  { code: "394578005", display: "Audiological medicine" },
  { code: "421661004", display: "Blood banking and transfusion medicine" },
  { code: "408462000", display: "Burns care" },
  { code: "394579002", display: "Cardiology" },
  {
    code: "394804000",
    display: "Clinical cytogenetics and molecular genetics",
  },
  { code: "394580004", display: "Clinical genetics" },
  { code: "394803006", display: "Clinical hematology" },
  { code: "408480009", display: "Clinical immunology" },
  { code: "408454008", display: "Clinical microbiology" },
  { code: "394809005", display: "Clinical neuro-physiology" },
  { code: "394592004", display: "Clinical oncology" },
  { code: "394600006", display: "Clinical pharmacology" },
  { code: "394601005", display: "Clinical physiology" },
  { code: "394581000", display: "Community medicine" },
  { code: "408478003", display: "Critical care medicine" },
  { code: "394812008", display: "Dental medicine specialties" },
  { code: "408444009", display: "Dental-General dental practice" },
  { code: "394582007", display: "Dermatology" },
  { code: "408475000", display: "Diabetic medicine" },
  { code: "410005002", display: "Dive medicine" },
  { code: "394583002", display: "Endocrinology" },
  { code: "419772000", display: "Family practice" },
  { code: "394584008", display: "Gastroenterology" },
  { code: "408443003", display: "General medical practice" },
  { code: "394802001", display: "General medicine" },
  { code: "394915009", display: "General pathology" },
  { code: "394814009", display: "General practice" },
  { code: "394808002", display: "Genito-urinary medicine" },
  { code: "394811001", display: "Geriatric medicine" },
  { code: "408446006", display: "Gynecological oncology" },
  { code: "394586005", display: "Gynecology" },
  { code: "394916005", display: "Hematopathology" },
  { code: "408472002", display: "Hepatology" },
  { code: "394597005", display: "Histopathology" },
  { code: "394598000", display: "Immunopathology" },
  { code: "394807007", display: "Infectious diseases" },
  { code: "419192003", display: "Internal medicine" },
  { code: "408468001", display: "Learning disability" },
  { code: "394593009", display: "Medical oncology" },
  { code: "394813003", display: "Medical ophthalmology" },
  { code: "410001006", display: "Military medicine" },
  { code: "394589003", display: "Nephrology" },
  { code: "394591006", display: "Neurology	" },
  { code: "394599008", display: "Neuropathology" },
  { code: "394649004", display: "Nuclear medicine" },
  { code: "408470005", display: "Obstetrics" },
  { code: "394585009", display: "Obstetrics and gynecology" },
  { code: "394821009", display: "Occupational medicine" },
  { code: "422191005", display: "Ophthalmic surgery" },
  { code: "394594003", display: "Ophthalmology" },
  { code: "416304004", display: "Osteopathic manipulative medicine" },
  { code: "418960008", display: "Otolaryngology" },
  { code: "394882004", display: "Pain management" },
  { code: "394806003", display: "Palliative medicine" },
  {
    code: "394588006",
    display: "Pediatric (Child and adolescent) psychiatry",
  },
  { code: "408459003", display: "Pediatric cardiology" },
  { code: "394607009", display: "Pediatric dentistry" },
  { code: "419610006", display: "Pediatric endocrinology" },
  { code: "418058008", display: "Pediatric gastroenterology" },
  { code: "420208008", display: "Pediatric genetics" },
  { code: "418652005", display: "Pediatric hematology" },
  { code: "418535003", display: "Pediatric immunology" },
  { code: "418862001", display: "Pediatric infectious diseases" },
  { code: "419365004", display: "Pediatric nephrology" },
  { code: "418002000", display: "Pediatric oncology" },
  { code: "419983000", display: "Pediatric ophthalmology" },
  { code: "419170002", display: "Pediatric pulmonology" },
  { code: "419472004", display: "Pediatric rheumatology" },
  { code: "394539006", display: "Pediatric surgery" },
  {
    code: "420112009",
    display: "Pediatric surgery-bone marrow transplantation",
  },
  { code: "409968004", display: "Preventive medicine" },
  { code: "394587001", display: "Psychiatry" },
  { code: "394913002", display: "Psychotherapy" },
  { code: "408440000", display: "Public health medicine" },
  { code: "418112009", display: "Pulmonary medicine" },
  { code: "419815003", display: "Radiation oncology" },
  { code: "394914008", display: "Radiology" },
  { code: "408455009", display: "Radiology-Interventional radiology" },
  { code: "394602003", display: "Rehabilitation" },
  { code: "408447002", display: "Respite care" },
  { code: "394810000", display: "Rheumatology" },
  { code: "408450004", display: "Sleep studies" },
  { code: "408476004", display: "Surgery-Bone and marrow transplantation" },
  { code: "408469009", display: "Surgery-Breast surgery" },
  { code: "408466002", display: "Surgery-Cardiac surgery" },
  { code: "408471009", display: "Surgery-Cardiothoracic transplantation" },
  { code: "408464004", display: "Surgery-Colorectal surgery" },
  { code: "408441001", display: "Surgery-Dental-Endodontics" },
  {
    code: "408465003",
    display: "Surgery-Dental-Oral and maxillofacial surgery",
  },
  { code: "394605001", display: "Surgery-Dental-Oral surgery" },
  { code: "394608004", display: "Surgery-Dental-Orthodontics" },
  { code: "408461007", display: "Surgery-Dental-Periodontal surgery" },
  {
    code: "408460008",
    display: "Surgery-Dental-Prosthetic dentistry (Prosthodontics)",
  },
  { code: "408460008", display: "Surgery-Dental-surgical-Prosthodontics" },
  { code: "394606000", display: "Surgery-Dentistry-Restorative dentistry" },
  { code: "408449004", display: "Surgery-Dentistry--surgical" },
  { code: "394608004", display: "Surgery-Dentistry-surgical-Orthodontics	" },
  { code: "418018006", display: "Surgery-Dermatologic surgery" },
  { code: "394604002", display: "Surgery-Ear, nose and throat surgery" },
  { code: "394609007", display: "Surgery-general" },
  {
    code: "408474001",
    display: "Surgery-Hepatobiliary and pancreatic surgery",
  },
  { code: "394610002", display: "Surgery-Neurosurgery" },
  { code: "394611003", display: "Surgery-Plastic surgery" },
  { code: "408477008", display: "Surgery-Transplantation surgery" },
  { code: "394801008", display: "Surgery-Trauma and orthopedics" },
  { code: "408463005", display: "Surgery-Vascular" },
  { code: "419321007", display: "Surgical oncology" },
  { code: "394576009", display: "Surgical-Accident & emergency" },
  { code: "394590007", display: "Thoracic medicine" },
  { code: "409967009", display: "Toxicology" },
  { code: "408448007", display: "Tropical medicine" },
  { code: "419043006", display: "Urological oncology" },
  { code: "394612005", display: "Urology" },
  { code: "394733009", display: "Medical specialty--OTHER--NOT LISTED" },
  { code: "394732004", display: "Surgical specialty--OTHER-NOT LISTED" },
];

export const AppointmentType = [
  {
    code: "CHECKUP",
    display: "Routine check-up",
  },
  { code: "EMERGENCY", display: "Emergency appointment" },
  {
    code: "FOLLOWUP",
    display: "Follow up visit",
  },
  { code: "WALKIN", display: "Walk-in visit" },
  { code: "REFERRAL", display: "Referral/Consult" },
  { code: "FIRSTTIME", display: "First Time Visit" },
  { code: "PROCEDURE", display: "Procedure" },
  { code: "UNAVAILABLE", display: "Doctor Unavailable" },
];

export const Priority = [
  { code: 4, display: "Critical" },
  { code: 3, display: "Urgent" },
  { code: 2, display: "Routine" }, // Medium in Fhir
  { code: 1, display: "Low" },
  { code: 0, display: "None" },
];

export const Status = [
  { code: "proposed", display: "Proposed" },
  { code: "pending", display: "Pending" },
  { code: "booked", display: "Booked" },
  { code: "arrived", display: "Arrived" },
  { code: "fulfilled", display: "Fulfilled", terminal: true },
  { code: "cancelled", display: "Cancelled", terminal: true },
  { code: "noshow", display: "No Show", terminal: true },
  { code: "checked-in", display: "Checked In" },
  { code: "waitlist", display: "Waitlisted" },
  { code: "entered-in-error", display: "Entered in error", terminal: true },
];

export const weekOfMonth: Code[] = [
  {
    code: "first",
    display: "First",
    system: "http://hl7.org/fhir/week-of-month",
  },
  {
    code: "second",
    display: "Second",
    system: "http://hl7.org/fhir/week-of-month",
  },
  {
    code: "third",
    display: "Third",
    system: "http://hl7.org/fhir/week-of-month",
  },
  {
    code: "fourth",
    display: "Fourth",
    system: "http://hl7.org/fhir/week-of-month",
  },
  {
    code: "last",
    display: "Last",
    system: "http://hl7.org/fhir/week-of-month",
  },
];

export const dayOfWeek: Code[] = [
  {
    code: "mon",
    display: "Monday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "tue",
    display: "Tuesday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "wed",
    display: "Wednesday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "thu",
    display: "Thursday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "fri",
    display: "Friday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "sat",
    display: "Saturday",
    system: "http://hl7.org/fhir/days-of-week",
  },
  {
    code: "sun",
    display: "Sunday",
    system: "http://hl7.org/fhir/days-of-week",
  },
];

export const recurrenceType: Code[] = [
  { code: "d", display: "Day", system: "http://unitsofmeasure.org" },
  { code: "wk", display: "Week", system: "http://unitsofmeasure.org" },
  { code: "mo", display: "Month", system: "http://unitsofmeasure.org" },
  { code: "a", display: "Year", system: "http://unitsofmeasure.org" },
];
