import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Age ≥ 60 years",
    code: "age",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "BP ≥ 140/90 mmHg ",
    subtitle: "Initial BP. Either SBP ≥ 140 or DBP ≥ 90.",
    code: "bp",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Clinical features of the TIA",
    code: "tia",
    options: [
      { display: "Other symptoms", code: nanoid(), result: 0, default: true },
      {
        display: "Speech disturbance without weakness",
        code: nanoid(),
        result: 1,
      },
      { display: "Unilateral weakness", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Duration of symptoms",
    code: "duration",
    options: [
      { display: "< 10 minutes", code: nanoid(), result: 0, default: true },
      { display: "10-59 minutes", code: nanoid(), result: 1 },
      { display: "≥ 60 minutes", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "History of diabetes",
    code: "diabetes",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function ABCD2TIAComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    if (value === undefined) return "Please fill out required fields";
    switch (true) {
      case value >= 6:
        return "High risk";
      case value >= 4:
        return "Moderate risk";
      default:
        return "Low risk";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
