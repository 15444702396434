import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { auth0Config, apiConfig } from "../config";

const paths = {
  countries: "countries",
  medications: "medications",
  snomed: "snomedct",
};

class RefDataApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  async searchOptions(option: string, searchString: string, subset: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const path = paths[option] ? paths[option] : "value-sets";
      const payload: any = {
        q: searchString,
      };
      if (subset) {
        payload.type = subset;
      } else {
        payload.type = option;
      }
      const response = await axios.post(
        `${apiConfig.refDataServiceBase}/${path}/search`,
        payload,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when retrieving patients " + JSON.stringify(e));
      throw new Error(
        "An error occured while finding  patients. Please try again.",
      );
    }
  }

  // healthCheck
  async healthCheck() {
    try {
      const response = await axios.get(
        `${apiConfig.refDataServiceBase}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Appointment Service health check was unsuccessful" + JSON.stringify(e),
      );
    }
  }
}

export const refDataApi = new RefDataApi();
