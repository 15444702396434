import { combineReducers } from "@reduxjs/toolkit";
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as optionsReducer } from "../slices/options/options";
import { reducer as userReducer } from "../slices/user";
import { reducer as configReducer } from "../slices/configuration";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  options: optionsReducer,
  user: userReducer,
  config: configReducer,
});

export default rootReducer;
