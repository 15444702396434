import { createContext, useState } from "react";

const defaultApi = {
  open: false,
  setOpen: (value: boolean) => null,
};

export type ShortcutsContextValue = typeof defaultApi;
export const ShortcutsContext =
  createContext<ShortcutsContextValue>(defaultApi);

export default function ShortcutsProvider({ children }: any) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ShortcutsContext.Provider value={{ open, setOpen }}>
      {children}
    </ShortcutsContext.Provider>
  );
}
