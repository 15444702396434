import { Checkbox, FormControlLabel, Typography } from "@mui/material";

type Props = {
  inputLabel: string;
  noKnownValue: boolean;
  disabled?: boolean;
  setNoKnownValue: (noKnownValue: boolean) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

const NoKnownInput = ({
  inputLabel,
  noKnownValue,
  disabled,
  setNoKnownValue,
  onChange,
}: Props) => {
  return (
    <FormControlLabel
      label={
        <Typography variant="body2" noWrap>
          {inputLabel}
        </Typography>
      }
      control={
        <Checkbox
          value=""
          checked={noKnownValue}
          disabled={Boolean(disabled)}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
          ) => {
            onChange(event, checked);
            setNoKnownValue(!noKnownValue);
          }}
          color="primary"
        />
      }
    />
  );
};

export default NoKnownInput;
