const createFormData = (formData, files) => {
  const bodyFormData = new FormData();
  Object.keys(formData ?? {}).forEach((k) => {
    if (formData[k]) {
      bodyFormData.append(k, JSON.stringify(formData[k]));
    }
  });

  if (files && files.length) {
    files.forEach((file) => {
      bodyFormData.append("files[]", file, `${file.name}`);
    });
  }
  return bodyFormData;
};

export default createFormData;
