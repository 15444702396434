import { createContext, useState } from "react";

const defaultApi = {
  activeKey: undefined,
  setActiveKey: (string): void => null,
};

export type DictationValue = typeof defaultApi;

export const DictactionContext = createContext<DictationValue>(defaultApi);

export function DictactionProvider({ children }: any) {
  const [activeKey, setActiveKey] = useState<string>();

  const _setActiveKey = (value?: string) => {
    setActiveKey(value);
  };

  // Return Provider with full API
  const api = {
    activeKey,
    setActiveKey: _setActiveKey,
  };

  return (
    <DictactionContext.Provider value={api}>
      {children}
    </DictactionContext.Provider>
  );
}
