export enum QueryKeys {
  ALLERGIES = "allergies",
  APPOINTMENTS = "appointments",
  APPOINTMENTS_BY_TYPE_PENDING = "appointments-type-pending",
  APPOINTMENTS_BY_TYPE_WAITLISTED = "appointments-type-waitlisted",
  BROADCASTS = "broadcasts",
  CARE_PLANS = "care_plans",
  COUNT_ENTITIES = "count-entities",
  ENCOUNTERS = "encounters",
  MEDICATION = "medication-requests",
  OBGYN_QUESTIONNAIRE_HISTORY = "obgyn-questionnaire-history",
  PATIENTS = "patients",
  PRACTITIONERS = "practitioners",
  PREVIOUS_ENCOUNTERS = "previous_encounters",
  SERVICES = "services",
  APPOINTMENT_DUPLICATE_PATIENTS = "appointment-duplicate-patients",
}
