type Props = {
  children: React.ReactNode;
};

const ShortcutDisplay = ({ children }: Props) => {
  return (
    <span
      style={{
        fontSize: "0.75rem",
        fontWeight: "bold",
        lineHeight: "20px",
        marginLeft: "4px",
        border: "1px solid rgb(218, 226, 237)",
        backgroundColor: "rgb(255, 255, 255)",
        padding: "2px 4px",
        borderRadius: "7px",
      }}
    >
      {children}
    </span>
  );
};

export default ShortcutDisplay;
