import { TextField, TextFieldProps } from "@mui/material";

// To use while MUI NumberInput is unstable
export default function NumberInput(
  props: Omit<TextFieldProps, "inputMode" | "type">,
) {
  return (
    <TextField
      type="text"
      inputMode="numeric"
      {...props}
      onChange={(e) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) return;
        props.onChange?.(e);
      }}
    />
  );
}
