import React, { useEffect } from "react";
import useNotifications from "../../hooks/useNotifications";
import { Alert, AlertColor, Fade, SlideProps, Snackbar } from "@mui/material";

type TransitionProps = Omit<SlideProps, "direction">;

function NotificationComponent() {
  const { notifications, clearNotification } = useNotifications();

  const [state] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & { children?: React.ReactElement<any, any> }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClose = (notificationId) => {
    clearNotification(notificationId);
  };

  useEffect(() => {
    setTimeout(() => {
      notifications.forEach((item) => {
        handleClose(item.id);
      });
    }, 5000);
  }, [notifications]);

  return (
    <>
      {notifications.map((notification: any, i: number) => {
        const severity: AlertColor = notification.variant as AlertColor;
        return (
          <Snackbar
            key={`notification-${i}`}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "50%" }}
            open={notification && notification.message}
            TransitionComponent={state.Transition}
          >
            <Alert
              severity={severity}
              sx={{ width: "100%" }}
              onClose={() => handleClose(notification.id)}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
}

export default NotificationComponent;
