import { useQuery } from "@tanstack/react-query";
import { set } from "lodash";
import { useEffect, useState } from "react";
import { providerApi } from "../../../api/providerApi";
import { NotificationVariant } from "../../../components/dashboard/NotificationsProvider";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import useNotifications from "../../../hooks/useNotifications";
import { useSelector } from "../../../store";
import { Organization } from "../../../types/organization";
import { useOrgContactModal } from "./useOrgContactModal";

export const useOrganizationReference = () => {
  return useSelector((state) => state.user.organizationReference);
};

export const useOrganization = () => {
  const organizationReference = useOrganizationReference();
  const { contactsList, setContactsList } = useOrgContactModal();
  const [, setLoading] = useFullPageLoader();
  const { setNotification } = useNotifications();
  const [organization, setOrganization] = useState<Organization>(null);
  const THIRTY_SECONDS_IN_MS = 30 * 1000;
  const updateOrganization = (field: string, value) => {
    const update = { ...organization };
    set(update, field, value);
    setOrganization(update);
  };

  const {
    data: organizationData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["organization", organizationReference?.identifier],
    queryFn: () =>
      providerApi.findOrganization(organizationReference?.identifier),
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onError: (e) => {
      setNotification({
        message: "An error occurred while fetching organization",
        variant: NotificationVariant.error,
      });
    },
    enabled: !!organizationReference?.identifier,
    staleTime: THIRTY_SECONDS_IN_MS,
  });

  useEffect(() => {
    if (!organizationReference?.identifier) return;
    setLoading(isLoading);
    if (organizationData) setOrganization(organizationData);
    if (error) console.error(`Error finding Org ${error}`);
  }, [
    organizationData,
    isLoading,
    error,
    setLoading,
    organizationReference?.identifier,
  ]);

  // CONTACTS
  const generateContactId = (contactList) => {
    if (contactList) {
      contactList.map(
        (contact, index) =>
          (contact["id"] = `${contact.name[0].firstName}${index}`),
      );
    }
  };

  useEffect(() => {
    if (organization) {
      generateContactId(organization?.contact || []);
      setContactsList(organization?.contact || []);
    }
  }, [organization]);

  const updateContactList = (contact) => {
    const update = [...contactsList];
    const index = update.findIndex((c) => c["id"] === contact.id);
    if (index > -1) {
      update[index] = contact;
    } else {
      update.push({ ...contact, id: contact.name[0].firstName + index });
    }
    setContactsList(update);
  };
  return {
    setOrganization,
    organization,
    contactsList,
    updateOrganization,
    updateContactList,
    organizationReference,
  };
};
