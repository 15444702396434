import { FormControl, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { createDefaultVital } from ".";
import NumberInput from "../../../../../../components/NumberInput";
import SelectButton from "../../../../../../components/shortcuts/components/SelectButton";
import { VitalsInputProps } from "../../../../../../components/widgets/modals/encounter";
import CodingSystems from "../../../../../../constants/CodingSystems";
import usePractitioner from "../../../../../../hooks/usePractitioner";
import { temperatureUnit } from "../../../../../../slices/options/vitals";
import { updateMetaData } from "../VitalsFormHelper";
import { useMedicalConfig } from "../../../../../../slices/configuration";

const TemperatureInput: FC<VitalsInputProps> = ({
  encounter,
  patient,
  disabled,
  value,
  onChange,
  size,
}) => {
  const practitionerReference = usePractitioner();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [temperature, setTemperature] = useState<any>();
  const medicalConfig = useMedicalConfig();
  const currentUnit =
    temperatureUnit?.find((e) => e.code === value?.valueQuantity?.unit)
      ?.display ??
    temperatureUnit?.find(
      (e) => e.code === medicalConfig?.vitalMeasurement?.temperature,
    )?.display;
  medicalConfig?.vitalMeasurement?.temperature ?? temperatureUnit.at(0).display;

  useEffect(() => {
    if (value) {
      setTemperature(value);
    } else {
      const t = createDefaultVital(
        encounter,
        patient,
        CodingSystems.OBSERVATION_CODE,
        "8310-5",
        "Body Temperature",
        medicalConfig?.vitalMeasurement?.temperature ?? "celcius",
      );
      setTemperature(t);
    }
  }, [encounter, medicalConfig, patient, value]);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <NumberInput
          value={value?.valueQuantity?.value ?? ""}
          label="Temp"
          size="small"
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectButton
                label={currentUnit}
                variant="text"
                options={temperatureUnit}
                getOptionLabel={(option) => option?.display ?? ""}
                size={size}
                disabled={disabled}
                onSelect={(value) => {
                  const newVital = temperature.valueQuantity
                    ? { ...temperature.valueQuantity, unit: value.code }
                    : {};

                  const copy = { ...temperature, valueQuantity: newVital };
                  updateMetaData(copy, practitionerReference);
                  onChange(copy);
                }}
              />
            ),
          }}
          onChange={(e) => {
            const newVital = temperature.valueQuantity
              ? { ...temperature.valueQuantity, value: e.target.value }
              : {};

            const tempCopy = { ...temperature };

            updateMetaData(tempCopy, practitionerReference);
            onChange({ ...tempCopy, valueQuantity: newVital });
          }}
        />
      </FormControl>
    </Grid>
  );
};

export default TemperatureInput;
