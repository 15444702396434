import { useState } from "react";
import type { FC } from "react";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
import { Box, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useComponentLoader from "../../../hooks/useComponentLoader";

const LoginAuth0: FC = (props) => {
  const mounted = useMounted();
  const [loading, toggleLoader] = useComponentLoader();
  const { loginWithPopup, clearCredentials } = useAuth() as any;
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (): Promise<void> => {
    try {
      toggleLoader(true);
      await loginWithPopup();
    } catch (err) {
      console.error(err);
      //TODO: Prevent Users from Logging in with invalid usernames and passwords
      //this does not necessarily work smoothly - but It'll do for now
      clearCredentials();
      if (mounted.current) {
        setError(err.message);
      }
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <div {...props}>
      {error && (
        <Box sx={{ my: 3 }}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <LoadingButton
          color="primary"
          onClick={handleLogin}
          variant="contained"
          size="large"
          loading={loading}
        >
          Log In
        </LoadingButton>
      </Box>
    </div>
  );
};

export default LoginAuth0;
