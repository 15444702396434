import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Active cancer",
    subtitle: "Treatment or palliation within 6 months",
    code: "active-cancer",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Bedridden recently >3 days or major surgery within 12 weeks",
    code: "bedridden",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Calf swelling >3 cm compared to the other leg",
    code: "calf-swelling",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Collateral (non-varicose) superficial veins present",
    code: "collateral-superficial-veins-present",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Entire leg swollen",
    code: "leg-swollen",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Localized tenderness along the deep venous system",
    code: "localized-tenderness",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Pitting edema, confined to symptomatic leg",
    code: "pitting-edema",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title:
      "Paralysis, paresis, or recent plaster immobilization of the lower extremity",
    code: "paralysis",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Previously documented DVT",
    code: "previously-documented-dvt",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Alternative diagnosis to DVT as likely or more likely",
    code: "alternative-diagnosis",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: -2 },
    ],
  },
];

export default function WellsDVTComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (true) {
      case value >= 3:
        return "DVT likely";
      case value >= 1:
        return "Moderate risk of DVT";
      default:
        return "DVT unlikely";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
