import { createContext, useState, useCallback } from "react";

const defaultApi = {
  isOpen: false,
  // encounterId: string,
  openOrgSelectionModal: (): void => null,
  closeOrgSelectionModal: (): void => null,
};

export type OrgSelectionModalModalValue = typeof defaultApi;

export const OrgSelectionModalModalContext =
  createContext<OrgSelectionModalModalValue>(defaultApi);

export function OrgSelectionModalModalProvider({ children }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [encounterId, setEncounterId] = useState();

  const openOrgSelectionModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeOrgSelectionModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // Return Provider with full API
  const api = { isOpen, openOrgSelectionModal, closeOrgSelectionModal };
  return (
    <OrgSelectionModalModalContext.Provider value={api}>
      {children}
    </OrgSelectionModalModalContext.Provider>
  );
}
