import type { FC } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { LoginAuth0 } from "../../components/authentication/login";
import useAuth from "../../hooks/useAuth";
import gtm from "../../lib/gtm";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import HealthProfessionalTeam from "../../icons/HealthProfessionalTeam";
import MedialHealthLogo from "../../icons/MedialHealthLogo-500.png";

const Login: FC = () => {
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Medial Health</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          minHeight: "100vh",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#3E2465",
            borderBottom: 1,
            borderColor: "divider",
            py: 2,
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  mt: -50,
                  ml: 5,
                  mr: -7,
                }}
              >
                <img
                  src={MedialHealthLogo}
                  className="logo"
                  height="50px"
                  alt="Medial Health Logo"
                  style={{ borderRadius: "50%" }}
                />
              </Box>

              <Box>
                <Typography
                  color="white"
                  fontFamily="Roboto"
                  variant="subtitle1"
                  display="flex"
                  padding="70px"
                  marginRight="280px"
                  fontSize={25}
                  fontWeight="bold"
                >
                  Medial Health
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                    marginRight: "60px",
                  }}
                >
                  <HealthProfessionalTeam />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 15,
            }}
          ></Box>

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Login
                  </Typography>
                </div>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                  mb: 10,
                }}
              >
                {platform === "Auth0" && <LoginAuth0 />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
