import { useState } from "react";
import { UnitOption } from "../types/unitOption";
import { Box, Grid, Typography } from "@mui/material";
import { CalculatorTextFieldItem } from "../components/CalculatorTextFieldItem";
import SelectButton from "../components/SelectButton";
import { Quantity } from "../types/quantity";

const sodiumUnits: UnitOption[] = [
  {
    unit: "mmol/L",
    converter: (value) => value,
  },
  {
    unit: "mEq/L",
    converter: (value) => value,
  },
];
const bunUnits: UnitOption[] = [
  {
    unit: "mmol/L",
    converter: (value) => value * 2.801,
  },
  {
    unit: "mg/dL",
    converter: (value) => value,
  },
];
const glucoseUnits: UnitOption[] = [
  {
    unit: "mmol/L",
    converter: (value) => value * 18.0182,
  },
  {
    unit: "mg/dL",
    converter: (value) => value,
  },
];
const serumAlcoholConcentrationUnits: UnitOption[] = [
  {
    unit: "mmol/L",
    converter: (value) => value * 0.7894,
  },
  {
    unit: "mg/dL",
    converter: (value) => value,
  },
];
const measuredSerumOSMUnits: UnitOption[] = [
  {
    unit: "mmol/kg",
    converter: (value) => value,
  },
  {
    unit: "mOsm/kg",
    converter: (value) => value,
  },
];

export default function SerumOsmolarityComponent() {
  const [values, setValues] = useState<{
    sodium: Quantity;
    bun: Quantity;
    glucose: Quantity;
    serumAlcoholConcentration: Quantity;
    measuredSerumOSM: Quantity;
  }>({
    sodium: {
      value: undefined,
      unit: sodiumUnits[0],
    },
    bun: {
      value: undefined,
      unit: bunUnits[0],
    },
    glucose: {
      value: undefined,
      unit: glucoseUnits[0],
    },
    serumAlcoholConcentration: {
      value: undefined,
      unit: serumAlcoholConcentrationUnits[0],
    },
    measuredSerumOSM: {
      value: undefined,
      unit: measuredSerumOSMUnits[0],
    },
  });

  const calculateSerumOsmolarity = (
    sodium: number = 0,
    bun: number = 0,
    glucose: number = 0,
    serumAlcoholConcentration: number = 0,
    measuredSerumOSM: number = 0,
  ) => {
    const calculatedSerumOsm =
      2 * sodium + bun / 2.8 + glucose / 18 + serumAlcoholConcentration / 4.6;
    const osmGap = measuredSerumOSM - calculatedSerumOsm;
    return [calculatedSerumOsm, osmGap] as const;
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} overflow="auto" p={2}>
        <Grid container spacing={2}>
          <CalculatorTextFieldItem
            text="Sodium"
            placeholder={`Norm: ${
              values.sodium.unit.unit === "mmol/L" ? "136-145" : "136-145"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                sodium: {
                  ...values.sodium,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={sodiumUnits}
                label={values.sodium.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    sodium: {
                      ...values.sodium,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="BUN"
            placeholder={`Norm: ${
              values.bun.unit.unit === "mmol/L" ? "2.9-7.1" : "8-20"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                bun: {
                  ...values.bun,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={bunUnits}
                label={values.bun.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    bun: {
                      ...values.bun,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Glucose"
            placeholder={`Norm: ${
              values.glucose.unit.unit === "mmol/L" ? "3.9-5.8" : "70-105"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                glucose: {
                  ...values.glucose,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={glucoseUnits}
                label={values.glucose.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    glucose: {
                      ...values.glucose,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Serum alcohol concentration"
            placeholder={`Norm: ${
              values.serumAlcoholConcentration.unit.unit === "mmol/L"
                ? "0-17.36"
                : "0-80"
            }`}
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                serumAlcoholConcentration: {
                  ...values.serumAlcoholConcentration,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={glucoseUnits}
                label={values.serumAlcoholConcentration.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    serumAlcoholConcentration: {
                      ...values.serumAlcoholConcentration,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />

          <CalculatorTextFieldItem
            text="Measured serum osm"
            placeholder="Norm: 275-295"
            onChange={(e) => {
              const float = parseFloat(e.target.value);
              setValues({
                ...values,
                measuredSerumOSM: {
                  ...values.measuredSerumOSM,
                  value: float,
                },
              });
            }}
            endAdornment={
              <SelectButton
                options={glucoseUnits}
                label={values.measuredSerumOSM.unit.unit}
                variant="text"
                onSelect={(value: UnitOption) => {
                  setValues({
                    ...values,
                    measuredSerumOSM: {
                      ...values.measuredSerumOSM,
                      unit: value,
                    },
                  });
                }}
                getOptionLabel={(value) => value.unit}
              />
            }
          />
        </Grid>
      </Box>

      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderColor: "#D3D3D3",
        }}
      >
        <Typography variant="h5">Result</Typography>
        {(() => {
          const {
            sodium,
            bun,
            glucose,
            serumAlcoholConcentration,
            measuredSerumOSM,
          } = values;

          const [calculatedSerumOsm, osmGap] = calculateSerumOsmolarity(
            sodium.unit.converter(sodium.value),
            bun.unit.converter(bun.value),
            glucose.unit.converter(glucose.value),
            serumAlcoholConcentration.unit.converter(
              serumAlcoholConcentration.value,
            ),
            measuredSerumOSM.unit.converter(measuredSerumOSM.value),
          );

          const interpretOsmGapResults = (osmGap: number) =>
            osmGap > 10 ? "Significant OSM Gap" : "Normal Osm Gap";
          const interpretSerumOsm = (serumOsm: number) =>
            serumOsm > 320 || serumOsm < 265 ? "(Possible critical value)" : "";

          const serumValid = Number.isFinite(calculatedSerumOsm);
          const gapValid =
            Number.isFinite(osmGap) && Number.isFinite(measuredSerumOSM.value);

          if (!serumValid && !gapValid) {
            return (
              <Typography>
                <i>Please fill out the required fields</i>
              </Typography>
            );
          }

          return (
            <>
              {serumValid && (
                <>
                  <Typography>
                    Calculated Serum Osm:{" "}
                    <b>{calculatedSerumOsm?.toFixed(0)} mOsm/kg</b>{" "}
                    <span style={{ color: "red" }}>
                      <b>{interpretSerumOsm(calculatedSerumOsm)}</b>
                    </span>
                  </Typography>
                  <Typography variant="body2" color="GrayText">
                    Normal adult/elderly: 285-295 mOsm/kg
                    <br />
                    Normal child: 275-290 mOsm/kg H2O
                  </Typography>
                </>
              )}
              {gapValid && (
                <Typography mt={2}>
                  Osm Gap: <b>{osmGap?.toFixed(0)} mOsm/kg</b> (
                  {interpretOsmGapResults(osmGap)})
                </Typography>
              )}
            </>
          );
        })()}
      </Box>
    </Box>
  );
}
