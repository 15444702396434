import React, { createContext, useState, useEffect } from "react";

// Create the context
const ScreenWidthContext = createContext(window.innerWidth);

// Create the provider
const ScreenWidthProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Update the screen width on resize
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Render the provider with the current screen width as the value
  return (
    <ScreenWidthContext.Provider value={screenWidth}>
      {children}
    </ScreenWidthContext.Provider>
  );
};

export { ScreenWidthContext, ScreenWidthProvider };
