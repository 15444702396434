import { Code } from "../../types/code";

export const observationStatus: Code[] = [
  {
    code: "registered",
    display: "Registered",
  },
  {
    code: "preliminary",
    display: "Preliminary",
  },
  {
    code: "final",
    display: "Final",
  },
  {
    code: "amended",
    display: "Amended",
  },
];

export const identifierTypes = [
  { code: "NI", display: "National ID number" }, // true display: National unique individual identifier (NI)
  { code: "DL", display: "Driver's license number" },
  { code: "PPN", display: "Passport number" },
  // { code: "BRN", display: "Breed Registry Number" },
  { code: "MR", display: "Medical record number" },
  // { code: "MCN", display: "Microchip Number" },
  { code: "EN", display: "Employer number" },
  { code: "TAX", display: "Tax ID number" },
  { code: "NIIP", display: "National Insurance ID" },
  { code: "IIP", display: "Insurance Policy" },
  // { code: "PRN", display: "Provider number" },
  { code: "MD", display: "Medical License number" },
  { code: "DR", display: "Donor Registration Number" },
  { code: "ACSN", display: "Accession/Imaging ID" },
  { code: "LACSN", display: "Accession/Lab ID" },
  // { code: "UDI", display: "Universal Device Identifier" },
  { code: "SNO", display: "Serial Number" },
  { code: "SB", display: "Social Beneficiary Identifier" },
  { code: "OTH", display: "Other" },

  // { code: "PLAC", display: "Placer Identifier" },
  // { code: "FILL", display: "Filler Identifier" },
  // { code: "JHN", display: "Jurisdictional health number (Canada)" },
];

export const dosageTiming = [
  { code: "BID", display: "Two times a day" },
  { code: "TID", display: "Three times a day" },
  { code: "QID", display: "Four times a day" },
  { code: "AM", display: "Every morning" },
  { code: "PM", display: "Every afternoon" },
  { code: "QD", display: "Every day" },
  { code: "QOD", display: "Every other day" },
  { code: "Q1H", display: "Every hour" },
  { code: "Q2H", display: "Every 2 hours" },
  { code: "Q3H", display: "Every 3 hours" },
  { code: "Q4H", display: "Every 4 hours" },
  { code: "Q6H", display: "Every 6 Hours" },
  { code: "Q8H", display: "Every 8 hours" },
  { code: "BED", display: "At bedtime" },
  { code: "WK", display: "Weekly" },
  { code: "MO", display: "Monthly" },
];

export const practiceCodes = [
  { code: "408467006", display: "Adult mental illness" },
  { code: "394577000", display: "Anesthetics" },
  { code: "394578005", display: "Audiological medicine" },
  { code: "421661004", display: "Blood banking and transfusion medicine" },
  { code: "408462000", display: "Burns care" },
  { code: "394579002", display: "Cardiology" },
  {
    code: "394804000",
    display: "Clinical cytogenetics and molecular genetics",
  },
  { code: "394580004", display: "Clinical genetics" },
  { code: "394803006", display: "Clinical hematology" },
  { code: "408480009", display: "Clinical immunology" },
  { code: "408454008", display: "Clinical microbiology" },
  { code: "394809005", display: "Clinical neuro-physiology" },
  { code: "394592004", display: "Clinical oncology" },
  { code: "394600006", display: "Clinical pharmacology" },
  { code: "394601005", display: "Clinical physiology" },
  { code: "394581000", display: "Community medicine" },
  { code: "408478003", display: "Critical care medicine" },
  { code: "394812008", display: "Dental medicine specialties" },
  { code: "408444009", display: "Dental-General dental practice" },
  { code: "394582007", display: "Dermatology" },
  { code: "408475000", display: "Diabetic medicine" },
  { code: "410005002", display: "Dive medicine" },
  { code: "394583002", display: "Endocrinology" },
  { code: "419772000", display: "Family practice" },
  { code: "394584008", display: "Gastroenterology" },
  { code: "408443003", display: "General medical practice" },
  { code: "394802001", display: "General medicine" },
  { code: "394915009", display: "General pathology" },
  { code: "394814009", display: "General practice" },
  { code: "394808002", display: "Genito-urinary medicine" },
  { code: "394811001", display: "Geriatric medicine" },
  { code: "408446006", display: "Gynecological oncology" },
  { code: "394586005", display: "Gynecology" },
  { code: "394916005", display: "Hematopathology" },
  { code: "408472002", display: "Hepatology" },
  { code: "394597005", display: "Histopathology" },
  { code: "394598000", display: "Immunopathology" },
  { code: "394807007", display: "Infectious diseases" },
  { code: "419192003", display: "Internal medicine" },
  { code: "408468001", display: "Learning disability" },
  { code: "394593009", display: "Medical oncology" },
  { code: "394813003", display: "Medical ophthalmology" },
  { code: "410001006", display: "Military medicine" },
  { code: "394589003", display: "Nephrology" },
  { code: "394591006", display: "Neurology" },
  { code: "394599008", display: "Neuropathology" },
  { code: "394649004", display: "Nuclear medicine" },
  { code: "408470005", display: "Obstetrics" },
  { code: "394585009", display: "Obstetrics and gynecology" },
  { code: "394821009", display: "Occupational medicine" },
  { code: "422191005", display: "Ophthalmic surgery" },
  { code: "394594003", display: "Ophthalmology" },
  { code: "416304004", display: "Osteopathic manipulative medicine" },
  { code: "418960008", display: "Otolaryngology" },
  { code: "394882004", display: "Pain management" },
  { code: "394806003", display: "Palliative medicine" },
  { code: "394588006", display: "Pediatric (Child and adolescent) psychiatry" },
  { code: "408459003", display: "Pediatric cardiology" },
  { code: "394607009", display: "Pediatric dentistry" },
  { code: "419610006", display: "Pediatric endocrinology" },
  { code: "418058008", display: "Pediatric gastroenterology" },
  { code: "420208008", display: "Pediatric genetics" },
  { code: "418652005", display: "Pediatric hematology" },
  { code: "418535003", display: "Pediatric immunology" },
  { code: "418862001", display: "Pediatric infectious diseases" },
  { code: "419365004", display: "Pediatric nephrology" },
  { code: "418002000", display: "Pediatric oncology" },
  { code: "419983000", display: "Pediatric ophthalmology" },
  { code: "419170002", display: "Pediatric pulmonology" },
  { code: "419472004", display: "Pediatric rheumatology" },
  { code: "394539006", display: "Pediatric surgery" },
  {
    code: "420112009",
    display: "Pediatric surgery-bone marrow transplantation",
  },
  { code: "409968004", display: "Preventive medicine" },
  { code: "394587001", display: "Psychiatry" },
  { code: "394913002", display: "Psychotherapy" },
  { code: "408440000", display: "Public health medicine" },
  { code: "418112009", display: "Pulmonary medicine" },
  { code: "419815003", display: "Radiation oncology" },
  { code: "394914008", display: "Radiology" },
  { code: "408455009", display: "Radiology-Interventional radiology" },
  { code: "394602003", display: "Rehabilitation" },
  { code: "408447002", display: "Respite care" },
  { code: "394810000", display: "Rheumatology" },
  { code: "408450004", display: "Sleep studies" },
  { code: "408476004", display: "Surgery-Bone and marrow transplantation" },
  { code: "408469009", display: "Surgery-Breast surgery" },
  { code: "408466002", display: "Surgery-Cardiac surgery" },
  { code: "408471009", display: "Surgery-Cardiothoracic transplantation" },
  { code: "408464004", display: "Surgery-Colorectal surgery" },
  { code: "408441001", display: "Surgery-Dental-Endodontics" },
  {
    code: "408465003",
    display: "Surgery-Dental-Oral and maxillofacial surgery",
  },
  { code: "394605001", display: "Surgery-Dental-Oral surgery" },
  { code: "394608004", display: "Surgery-Dental-Orthodontics" },
  { code: "408461007", display: "Surgery-Dental-Periodontal surgery" },
  {
    code: "408460008",
    display: "Surgery-Dental-Prosthetic dentistry (Prosthodontics)",
  },
  { code: "408460008", display: "Surgery-Dental-surgical-Prosthodontics" },
  { code: "394606000", display: "Surgery-Dentistry-Restorative dentistry" },
  { code: "408449004", display: "Surgery-Dentistry--surgical" },
  { code: "394608004", display: "Surgery-Dentistry-surgical-Orthodontics" },
  { code: "418018006", display: "Surgery-Dermatologic surgery" },
  { code: "394604002", display: "Surgery-Ear, nose and throat surgery" },
  { code: "394609007", display: "Surgery-general" },
  {
    code: "408474001",
    display: "Surgery-Hepatobiliary and pancreatic surgery",
  },
  { code: "394610002", display: "Surgery-Neurosurgery" },
  { code: "394611003", display: "Surgery-Plastic surgery" },
  { code: "408477008", display: "Surgery-Transplantation surgery" },
  { code: "394801008", display: "Surgery-Trauma and orthopedics" },
  { code: "408463005", display: "Surgery-Vascular" },
  { code: "419321007", display: "Surgical oncology" },
  { code: "394576009", display: "Surgical-Accident & emergency" },
  { code: "394590007", display: "Thoracic medicine" },
  { code: "409967009", display: "Toxicology" },
  { code: "408448007", display: "Tropical medicine" },
  { code: "419043006", display: "Urological oncology" },
  { code: "394612005", display: "Urology" },
  { code: "394733009", display: "Medical specialty--OTHER--NOT LISTED" },
  { code: "394732004", display: "Surgical specialty--OTHER-NOT LISTED" },
];

export const alcoholFrequency = [
  { code: "daily", display: "Daily" },
  { code: "weekly", display: "Weekly" },
  { code: "monthly", display: "Monthly" },
];

export const physicalActivityFrequency = [
  { code: "none", display: "Sedentary/None" },
  { code: "2-3times", display: "2-3 Times/Week" },
  { code: "5-6times", display: "5-6 Times/Week" },
];

export const alcoholicBeverageType = [
  { code: "beer", display: "Beer" },
  { code: "wine", display: "Wine" },
  { code: "spirits", display: "Spirits" },
];

export const chiefComplaintStatus = [
  {
    code: "in-progress",
    display: "In Progress",
    definition: "The event is currently occurring.",
  },
  {
    code: "completed",
    display: "Completed",
    definition: "The event has now concluded.",
  },

  {
    code: "entered-in-error",
    display: "Entered in Error",
    definition:
      'This electronic record should never have existed, though it is possible that real-world decisions were based on it. (If real-world activity has occurred, the status should be "stopped" rather than "entered-in-error".).',
  },
];

export const identifierUses = [
  {
    code: "usual",
    display: "Usual",
    definition:
      "The identifier recommended for display and use in real-world interactions.",
  },
  {
    code: "official",
    display: "Official",
    definition:
      'The identifier considered to be most trusted for the identification of this item. Sometimes also known as "primary" and "main". The determination of "official" is subjective and implementation guides often provide additional guidelines for use.',
  },
  { code: "temp", display: "Temp", definition: "A temporary identifier." },
  {
    code: "secondary",
    display: "Secondary",
    definition:
      "An identifier that was assigned in secondary use - it serves to identify the object in a relative context, but cannot be consistently assigned to the same object again in a different context.",
  },
  {
    code: "old",
    display: "Old",
    definition:
      "The identifier id no longer considered valid, but may be relevant for search purposes. E.g. Changes to identifier schemes, account merges, etc.",
  },
];

export const ReasonCode = [
  { code: "109006", display: "Anxiety disorder of childhood OR adolescence" },
  { code: "134006", display: "Decreased hair growth" },
  { code: "1201005", display: "Benign essential hypertension" },
  { code: "140004", display: "Chronic pharyngitis" },
  {
    code: "162004",
    display: "Severe manic bipolar I disorder without psychotic features",
  },
  { code: "193003", display: "Benign hypertensive renal disease" },
  { code: "219006", display: "Alcohol user" },
  { code: "279001", display: "Senile myocarditis" },
  { code: "281004", display: "Alcoholic dementia" },
  {
    code: "282006",
    display: "Acute myocardial infarction of basal-lateral wall",
  },
  { code: "504009", display: "Androgen-dependent hirsutism" },
  { code: "664002", display: "Delayed ovulation" },
];

export const contactPurpose = [
  { code: "BILL", display: "Billing" },
  { code: "ADMIN", display: "Administrative" },
  { code: "HR", display: "Human Resource" },
  { code: "PAYOR", display: "Payor" },
  { code: "PATINF", display: "Patient" },
  { code: "PRESS", display: "Press" },
];
