export const familyMemberRelationships = [
  { code: "FAMMEMB", display: "family member" },
  { code: "CHILD", display: "child" },
  { code: "CHLDADOPT", display: "adopted child" },
  { code: "DAUADOPT", display: "adopted daughter" },
  { code: "SONADOPT", display: "adopted son" },
  { code: "CHLDFOST", display: "foster child" },
  { code: "DAUFOST", display: "foster daughter	" },
  { code: "SONFOST", display: "foster son" },
  { code: "DAUC", display: "daughter" },
  { code: "DAU", display: "natural daughter	" },
  { code: "STPDAU", display: "stepdaughter" },
  { code: "NCHILD", display: "natural child	" },
  { code: "SON", display: "natural son" },
  { code: "SONC", display: "son" },
  { code: "STPSON", display: "stepson" },
  { code: "STPCHLD", display: "step child" },
  { code: "EXT", display: "extended family member" },
  { code: "AUNT", display: "aunt" },
  { code: "MAUNT", display: "maternal aunt" },
  { code: "PAUNT", display: "paternal aunt" },
  { code: "COUSN", display: "cousin" },
  { code: "MCOUSN", display: "maternal cousin" },
  { code: "PCOUSN", display: "paternal cousin" },
  { code: "GGRPRN", display: "great grandparent" },
  { code: "GGRFTH", display: "great grandfather" },
  { code: "MGGRFTH", display: "maternal great-grandfather" },
  { code: "PGGRFTH", display: "paternal great-grandfather" },
  { code: "GGRMTH", display: "great grandmother" },
  { code: "MGGRMTH", display: "maternal great-grandmother" },
  { code: "PGGRMTH", display: "paternal great-grandmother" },
  { code: "MGGRPRN", display: "maternal great-grandparent" },
  { code: "PGGRPRN", display: "paternal great-grandparent" },
  { code: "GRNDCHILD", display: "grandchild" },
  { code: "GRNDDAU", display: "granddaughter" },
  { code: "GRNDSON", display: "grandson" },
  { code: "GRPRN", display: "grandparent" },
  { code: "GRFTH", display: "grandfather" },
  { code: "MGRFTH", display: "maternal grandfather" },
  { code: "PGRFTH", display: "paternal grandfather" },
  { code: "GRMTH", display: "grandmother" },
  { code: "MGRMTH", display: "maternal grandmother" },
  { code: "PGRMTH", display: "paternal grandmother" },
  { code: "MGRPRN", display: "maternal grandparent" },
  { code: "PGRPRN", display: "paternal grandparent" },
  { code: "INLAW", display: "inlaw" },
  { code: "CHLDINLAW", display: "child-in-law" },
  { code: "DAUINLAW", display: "daughter in-law" },
  { code: "SONINLAW", display: "son in-law" },
  { code: "PRNINLAW", display: "parent in-law" },
  { code: "FTHINLAW", display: "father-in-law" },
  { code: "MTHINLAW", display: "mother-in-law" },
  { code: "MTHINLOAW", display: "mother-in-law" },
  { code: "SIBINLAW", display: "sibling in-law" },
  { code: "BROINLAW", display: "brother-in-law" },
  { code: "SISINLAW", display: "sister-in-law" },
  { code: "SISLINLAW", display: "sister-in-law" },
  { code: "NIENEPH", display: "niece/nephew" },
  { code: "NEPHEW", display: "nephew" },
  { code: "NIECE", display: "niece" },
  { code: "UNCLE", display: "uncle" },
  { code: "MUNCLE", display: "maternal uncle" },
  { code: "PUNCLE", display: "paternal uncle" },
  { code: "PRN", display: "parent" },
  { code: "ADOPTP", display: "adoptive parent" },
  { code: "ADOPTF", display: "adoptive father" },
  { code: "ADOPTM", display: "adoptive mother" },
  { code: "FTH", display: "father" },
  { code: "FTHFOST", display: "foster father" },
  { code: "NFTH", display: "natural father" },
  { code: "NFTHF", display: "natural father of fetus" },
  { code: "STPFTH", display: "stepfather" },
  { code: "MTH", display: "mother" },
  { code: "GESTM", display: "gestational mother" },
  { code: "MTHFOST", display: "foster mother" },
  { code: "NMTH", display: "natural mother" },
  { code: "NMTHF", display: "natural mother of fetus" },
  { code: "STPMTH", display: "stepmother" },
  { code: "NPRN", display: "natural parent" },
  { code: "PRNFOST", display: "foster parent" },
  { code: "STPPRN", display: "step parent" },
  { code: "SIB", display: "sibling" },
  { code: "BRO", display: "brother" },
  { code: "HBRO", display: "half-brother" },
  { code: "NBRO", display: "natural brother" },
  { code: "TWINBRO", display: "twin brother" },
  { code: "FTWINBRO", display: "fraternal twin brother" },
  { code: "ITWINBRO", display: "identical twin brother" },
  { code: "STPBRO", display: "stepbrother" },
  { code: "HSIB", display: "half-sibling" },
  { code: "HSIS", display: "half-sister" },
  { code: "NSIB", display: "natural sibling" },
  { code: "NSIS", display: "natural sister" },
  { code: "TWINSIS", display: "twin sister" },
  { code: "FTWINSIS", display: "fraternal twin sister" },
  { code: "ITWINSIS", display: "identical twin sister" },
  { code: "TWIN", display: "twin" },
  { code: "FTWIN", display: "fraternal twin" },
  { code: "ITWIN", display: "identical twin" },
  { code: "SIS", display: "sister" },
  { code: "STPSIS", display: "stepsister" },
  { code: "STPSIB", display: "step sibling" },
  { code: "SIGOTHR", display: "significant other" },
  { code: "DOMPART", display: "domestic partner" },
  { code: "FMRSPS", display: "former spouse" },
  { code: "SPS", display: "spouse" },
  { code: "HUSB", display: "husband" },
  { code: "WIFE", display: "wife" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const familyHistoryRelationships = [
  { code: "MOTHER", display: "Mother" },
  { code: "FATHER", display: "Father" },
  { code: "CHILD", display: "Child" },
  { code: "SIBLING", display: "Sibling" },
  { code: "UNCLE", display: "Uncle" },
  { code: "AUNT", display: "Aunt" },
  { code: "GRANDPARENT", display: "Grand Parent" },
  { code: "OTHER", display: "Other" },
].sort((a, b) => {
  return a.display > b.display ? 1 : -1;
});

export const patientRelationships = [
  { code: "C", display: "Emergency Contact" },
  { code: "E", display: "Employer" },
  { code: "F", display: "Federal Agency" },
  { code: "I", display: "Insurance Company" },
  { code: "N", display: "Next-of-Kin" },
  { code: "S", display: "State Agency" },
  { code: "U", display: "Unknown" },
];
