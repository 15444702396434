import useDuplicatePatientModal from "../hooks/useDuplicatePatientModal";
import TabbedTable from "./widgets/tables/TabbedTable";
import { GridColDef } from "@mui/x-data-grid";
import {
  generateHumanDisplayName,
  generatePatientAddress,
} from "../utils/DataTransformationUtils";
import { capitalize, get } from "lodash";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import ComponentLoader from "./ComponentLoader";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import useComponentLoader from "../hooks/useComponentLoader";
import { telRenderer } from "../utils/telRenderer";

const defaultRow = {
  sortable: false,
  hideable: false,
  filterable: false,
  disableColumnMenu: true,
};

const columnConfig: GridColDef[] = [
  {
    ...defaultRow,
    field: "row.patientId",
    headerName: "Record # (last 8)",
    type: "string",
    width: 140,
    renderCell: (params) => {
      return <>{`...${params.row.patientId.slice(-8).toUpperCase()}`}</>;
    },
  },
  {
    ...defaultRow,
    field: "fullname",
    headerName: "Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="rowitem">
          <span style={{ paddingLeft: "16px" }}>
            {params.row?.name?.[0]
              ? generateHumanDisplayName(params.row.name[0])
              : ""}
          </span>
        </div>
      );
    },
  },
  {
    ...defaultRow,
    field: "gender",
    headerName: "Gender",
    width: 80,
    renderCell: (params) => {
      return capitalize(get(params, "row.gender")) || "";
    },
  },
  {
    ...defaultRow,
    field: "birthDate",
    headerName: "Date of Birth",
    width: 120,
    renderCell: (params) => {
      return get(params, "row.birthDate")
        ? format(parseISO(get(params, "row.birthDate")), "dd/MMM/yyyy")
        : "";
    },
  },
  {
    ...defaultRow,
    field: "telecom[0].value",
    headerName: "Phone",
    width: 200,
    renderCell: (params) => telRenderer(params.row.telecom, "phone"),
  },
  {
    ...defaultRow,
    field: "address",
    headerName: "Address",
    minWidth: 500,
    renderCell: (params) => {
      return params?.row?.address?.length ? (
        <div>
          {params.row.address.map((address, i) => (
            <div key={`address-${i}`}>{generatePatientAddress(address)}</div>
          ))}
        </div>
      ) : null;
    },
  },
].map((i) => ({
  ...i,
  resizible: false,
  disableColumnReorder: true,
  disableColumnResize: true,
}));

function DuplicatePatientModal() {
  const [loading] = useComponentLoader();
  const {
    isOpen,
    closeDuplicatePatientModal,
    duplicatePatients,
    setDuplicatePatients,
    duplicatePatientActions,
    setPatientForCreation,
    patientForCreation,
  } = useDuplicatePatientModal();
  const [selectedPatient, setSelectedPatient] =
    useState<Record<string, unknown>>();

  const closeModal = () => {
    setPatientForCreation({});
    setDuplicatePatients([]);
    closeDuplicatePatientModal();
  };

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="encounter-modal-modal-title"
        aria-describedby="encounter-modal-modal-description"
      >
        <Box
          position="fixed"
          top="50%"
          left="50%"
          width={900}
          height="90vh"
          bgcolor="background.paper"
          border="2px solid #000"
          boxShadow={24}
          borderRadius={1}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <Box display="flex" height="100%" flexDirection="column">
            <Box flex={1} overflow="auto">
              <Grid container height={"100%"} p={2}>
                <Grid item xs={12}>
                  <Typography
                    id="encounter-modal-modal-title"
                    variant="h6"
                    component="h6"
                  >
                    Duplicate Patient(s) Warning
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    id="encounter-modal-modal-subtitle"
                    variant="subtitle2"
                    color={"red"}
                  >
                    The system detected patient(s) with similar attributes to
                    the patient you are trying to create or update. Please
                    examine the existing patients carefully to ensure you are
                    not creating a duplicate. After you have done so, please
                    proceed to the desired action below.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    style={{ color: "grey", width: "100%", marginTop: 15 }}
                  />
                </Grid>

                <Grid item xs={12} py={1}>
                  <Box
                    bgcolor={"background.default"}
                    width={"100%"}
                    height={500}
                  >
                    <TabbedTable
                      rows={duplicatePatients || []}
                      columns={columnConfig}
                      disableSearch={true}
                      tabs={[]}
                      sortOptions={[]}
                      searchString={""}
                      idProperty={"id"}
                      searchPlaceholderText="Filter patients"
                      paginationModel={{
                        page: 0,
                        pageSize: 20,
                      }}
                      gridHeight={500}
                      onRowClick={(item) => setSelectedPatient(item.row)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box
              display="flex"
              justifyContent="flex-end"
              gap={2}
              py={2}
              px={2}
              borderTop={"1px solid #ccc"}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={closeDuplicatePatientModal}
              >
                Discard
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!selectedPatient}
                onClick={() => {
                  duplicatePatientActions.useDuplicatePatient(selectedPatient);
                  setSelectedPatient(undefined);
                }}
              >
                Use Selected Patient
              </Button>
              {duplicatePatientActions?.actionButtons?.map((action) => {
                const ActionButton = action.button;
                const clickFunction = action.onClick;
                return (
                  <ActionButton
                    key={action.label}
                    onClick={() => clickFunction(patientForCreation, true)}
                  />
                );
              })}
            </Box>
          </Box>

          <ComponentLoader loading={loading} />
        </Box>
      </Modal>
    </div>
  );
}

export default DuplicatePatientModal;
