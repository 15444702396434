import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { apiConfig, auth0Config } from "../config";
import querySearchEndpoint from "../utils/querySearchEndpoint";
import { HealthCareServiceInterface } from "../pages/appointment/HealthcareServiceInterface";
import { Organization } from "../types/organization";
import {
  Auth0Role,
  Auth0User,
  Auth0UserWithDocument,
} from "../pages/facility/types/Auth0User";

class ProviderApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  // try {
  //     RestUtils.prepareRefresh(Action.HDA.DESHBOARD.API_UNDER_PROGRESS);
  //     const header = await CommonData.getHeaderWithToken();
  //     let response = await axios({
  //       method: 'GET',
  //       url: Api.healthDiary(id),
  //       headers: header,
  //     });
  //     if (response.data.success === 'OK') {
  //       RestUtils.updateData(Action.HDA.DESHBOARD.API_DATE, response.data);
  //     } else {
  //       RestUtils.restoreData(Action.HDA.DESHBOARD.API_FAIL);
  //     }
  //   } catch (e) {
  //     RestUtils.Console('Error Under getHealthDashboardVital', e);
  //     RestUtils.restoreData(Action.HDA.DESHBOARD.API_FAIL);
  //     Utils.toastError();
  //   }

  async getCurrentPractitioner(providerId: string, cb) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/practitioners/${providerId}`,
        config,
      );
      if (response.data) {
        cb(response.data);
      }
    } catch (e) {
      console.error("Error when retrieving provider " + JSON.stringify(e));
    }
  }

  async getPractitionerProfile(id) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/practitioners/${id}/profile`,
        config,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Error when retrieving provider profile " + JSON.stringify(e),
      );
      throw "An error occurred while loading your profile. Please try again.";
    }
  }

  async getPractitionerProfileById(id) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const response = await axios.get(
      `${apiConfig.providerServiceBase}/practitioners/${id}`,
      config,
    );

    return response.data;
  }

  async findPractitioners(query, signal?: AbortSignal) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };

      if (signal) {
        config.signal = signal;
      }

      const url = new URL(`${apiConfig.providerServiceBase}/practitioners`);
      const response = await querySearchEndpoint(url, query, config);

      if (response) {
        return response;
      } else {
        console.error({ response });
        throw "An error occurred while searching for practitioners. Please try again.";
      }
    } catch (e) {
      console.error("Error when retrieving provider " + JSON.stringify(e));
      throw e;
    }
  }

  async updatePractitioner(id: string, formData) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.put(
        `${apiConfig.providerServiceBase}/practitioners/${id}`,
        formData,
        config,
      );

      return response.data;
    } catch (e) {
      console.error(
        `Error updating patient information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(
        "An error occured while updating the patient's information. Please try again.",
      );
    }
  }

  async getIdTokenClaims() {
    return await this.auth0Client.getIdTokenClaims();
  }

  async getUserRoles(orgId: string, userId) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/practitioners/${userId}/member/${orgId}/roles`,
        config,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Error when retrieving provider roles " + JSON.stringify(e),
      );
      throw new Error(
        "An error occurred while loading your roles. Please try again.",
      );
    }
  }

  // gets healthcare services provided by a particular organization
  async findHealthcareServices(query, signal?: AbortSignal) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };

      if (signal) {
        config.signal = signal;
      }

      const url = new URL(
        `${apiConfig.providerServiceBase}/healthcare-services`,
      );

      const response = await querySearchEndpoint(url, query, config);

      if (response) {
        return response;
      } else {
        console.error({ response });
        throw "An error occurred while searching for healthcare services. Please try again.";
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getHealthcareService(id: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/healthcare-services/${id}`,
        config,
      );
      return response.data;
    } catch (e) {
      console.error(
        "An error occurred while retrieving the healthcare service. Please try again.",
      );
      throw e;
    }
  }

  async saveHealthcareService(healthcareService) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.post(
        `${apiConfig.providerServiceBase}/healthcare-services`,
        healthcareService,
        config,
      );
      return response.data;
    } catch (e) {
      console.error(
        "An error occurred while saving the healthcare service. Please try again.",
      );
      throw e;
    }
  }

  async updateHealthcareService(
    id: string,
    newData: HealthCareServiceInterface,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.put(
        `${apiConfig.providerServiceBase}/healthcare-services/${id}`,
        newData,
        config,
      );
      return response.data;
    } catch (e) {
      console.error(
        "An error occurred while updating the healthcare service. Please try again.",
      );
      throw e;
    }
  }

  async findOrganization(id: string): Promise<Organization> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(
        `${apiConfig.providerServiceBase}/organizations/${id}`,
      );
      const response = await axios.get(url.toString(), config);

      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async findOrganizations(query?) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(`${apiConfig.providerServiceBase}/organizations`);
      const response = await querySearchEndpoint(url, query, config);

      if (response) {
        return response;
      } else {
        console.log({ response });
        throw "An error occurred while searching for organizations. Please try again.";
      }
    } catch (e) {
      console.error("Error when retrieving organizations " + JSON.stringify(e));
      throw e;
    }
  }

  async updateOrganization(id: string, newData: Organization) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const response = await axios.put(
        `${apiConfig.providerServiceBase}/organizations/${id}`,
        newData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      //TODO: This error shows to much data
      console.log("Error when updating organization " + JSON.stringify(error));
      throw error;
    }
  }

  async getAuth0Users(): Promise<Auth0UserWithDocument[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const response = await axios.get(
        `${apiConfig.providerServiceBase}/auth/members`,
        config,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAuth0Roles(): Promise<Auth0Role[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const response = await axios.get(
        `${apiConfig.providerServiceBase}/auth/roles`,
        config,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async addAuth0RolesToUser(
    memberId: string,
    organizationId: string,
    roles: string[],
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const response = await axios.post(
      `${apiConfig.providerServiceBase}/auth/members/${memberId}/organizations/${organizationId}/roles`,
      { roles },
      config,
    );

    return response.data;
  }

  async removeAuth0RolesFromUser(
    memberId: string,
    organizationId: string,
    roles: string[],
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const response = await axios.delete(
        `${apiConfig.providerServiceBase}/auth/members/${memberId}/organizations/${organizationId}/roles`,
        {
          headers: config.headers,
          data: { roles },
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async createAuth0User(
    user: { email: string; password: string },
    roles: string[],
  ): Promise<
    [
      (
        | (Auth0UserWithDocument & { authorizationSuccessful: boolean })
        | undefined
      ),
      number,
    ]
  > {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const response = await axios.post(
        `${apiConfig.providerServiceBase}/auth/members/add`,
        { ...user, roles },
        config,
      );

      return [response.data, response.status];
    } catch (error) {
      console.error(error);
    }
  }

  async removeUserFromOrganization(userId: string, organizationId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        data: { userId, organizationId },
      };

      const response = await axios.delete(
        `${apiConfig.providerServiceBase}/auth/members/${userId}/organizations/${organizationId}`,
        config,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  // healthCheck
  async healthCheck() {
    try {
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Provider Service health check was unsuccessful" + JSON.stringify(e),
      );
    }
  }
}

export const providerApi = new ProviderApi();
