import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { auth0Config, apiConfig } from "../config";

class UserApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  async getOrganazitionsForUser(userId) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.providerServiceBase}/practitioners/${userId}/organizations`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error when retrieving organizations for the provider with userID${userId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
    }
  }
}

export const userApi = new UserApi();
