import { useState } from "react";
import type { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import NotificationComponent from "./NotificationComponent";
import FullPageLoader from "../FullPageLoader";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import ShortcutsProvider from "../shortcuts/ShortcutsContext";
import ShortcutsModal from "../shortcuts/ShortcutsModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Container, styled } from "@mui/material";
import useSettings from "../../hooks/useSettings";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout2: FC<DashboardLayoutProps> = () => {
  const { settings } = useSettings();
  const { allowShortcutsMenu } = useFlags();

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  const [loading] = useFullPageLoader();

  return (
    <ShortcutsProvider>
      <DashboardLayoutRoot>
        <DashboardNavbar
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        />
        <DashboardSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent id="dashboard-container">
              <NotificationComponent />
              <Container
                sx={{ py: 2 }}
                maxWidth={settings.compact ? "xl" : false}
              >
                <Outlet />
              </Container>
            </DashboardLayoutContent>
            <FullPageLoader loading={loading} />
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
        {allowShortcutsMenu && <ShortcutsModal />}
      </DashboardLayoutRoot>
    </ShortcutsProvider>
  );
};

export default DashboardLayout2;
