export const bloodGroups = [
  { code: "LA21321-7", display: "O+" },
  { code: "LA21322-5", display: "O-" },
  { code: "LA21327-4", display: "B+" },
  { code: "LA21328-2", display: "B-" },
  { code: "LA21323-3", display: "AB+" },
  { code: "LA21324-1", display: "AB-" },
  { code: "LA21325-8", display: "A+" },
  { code: "LA21326-6", display: "A-" },
];
