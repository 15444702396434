export const localOptions: any = {
  countries: true,
  usStates: true,
  insuranceOptions: true,
  ServiceCategory: true,
  ServiceType: true,
  Specialty: true,
  AppointmentType: true,
  Priority: true,
  ReasonCode: true,
  Status: true,
  EncounterClassification: true,
  EncounterStatus: true,
  accommodationType: true,
  smokingStatus: true,
  alcoholFrequency: true,
  physicalActivityFrequency: true,
  alcoholicBeverageType: true,
  bpMeasurementLocation: true,
  generalPhysicalAssessment: true,
  skinPhysicalAssessment: true,
  lymphaticPhysicalAssessment: true,
  headPhysicalAssessment: true,
  eyesPhysicalAssessment: true,
  earsPhysicalAssessment: true,
  nosePhysicalAssessment: true,
  oralCavityPhysicalAssessment: true,
  neckPhysicalAssessment: true,
  backPhysicalAssessment: true,
  chestPhysicalAssessment: true,
  respiratoryPhysicalAssessment: true,
  cardiovascularPhysicalAssessment: true,
  abdomenPhysicalAssessment: true,
  prostateExamPhysicalAssessment: true,
  maleGenitaliaPhysicalAssessment: true,
  femaleGenitaliaPhysicalAssessment: true,
  obstetricPhysicalAssessment: true,
  musculoskeletalPhysicalAssessment: true,
  psychiatricPhysicalAssessment: true,
  immunizationStatusReason: true,
  immunizationStatuses: true,
  immunizationSubpotentReason: true,
  immunizationRoute: true,
  bodySite: true,
  heartMurmurGradePhysicalAssessment: true,
  heartMurmurQualityPhysicalAssessment: true,
  heartMurmurPitchPhysicalAssessment: true,
  heartMurmurTimingPhysicalAssessment: true,
  heartMurmurLocationPhysicalAssessment: true,
  heartMurmurRadiatingPhysicalAssessment: true,
  eyeOpeningScorePhysicalAssessment: true,
  motorScorePhysicalAssessment: true,
  verbalScorePhysicalAssessment: true,
  prenatalKetonesPhysicalAssessment: true,
  compositionTypes: true,
  sexualAttractionType: true,
  sexualEncounterTime: true,
  medicalClinicCompositionTypes: true,
  pregnancyStatus: true,
  pregnancyHistory: true,
  gestationalAge: true,
  estimatedDueDate: true,
};
