import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import { FC, useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { EncounterNotesInputProps } from ".";
import TypeAheadInput from "../../../form/TypeAheadInput/TypeAheadInput";
import NoKnownInput from "./NoKnownInput";
import { medicalsApi } from "../../../../api/medicalsApi";
import {
  createReference,
  generateMedicationDisplay,
} from "../../../../utils/DataTransformationUtils";
import { Reference } from "../../../../types/reference";

interface PatientMedicationsInputProps extends EncounterNotesInputProps {
  setLoading?: (value: boolean) => void;
}

export function PatientMedicationsInput(props: PatientMedicationsInputProps) {
  useAsyncEffect(async () => {
    if (!props?.patient?.identifier) return;

    try {
      if (props.setLoading) props.setLoading(true);
      const currentMedications: any[] =
        await medicalsApi.findMedicationStatements({
          patient: props.patient.identifier,
          status: ["active", "on-hold", "not-taken", "intended"],
        });

      const formatted = currentMedications.map((e) => e.medication);

      props.onChange(formatted, true);
    } catch (error) {
      console.error(error);
      props.onChange([], true);
    } finally {
      if (props.setLoading) props.setLoading(false);
    }
  }, [props.patient]);

  return <MedicationsInput {...props} />;
}

const MedicationsInput: FC<EncounterNotesInputProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const [noKnownMedications, setNoKnownMedications] = useState(false);

  useEffect(() => {
    setNoKnownMedications(
      value &&
        value.length === 1 &&
        (value[0]?.identifier === "787481004" ||
          value[0]?.identifier === 787481004),
    );
  }, [value]);

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={9}>
        {noKnownMedications ? (
          <Autocomplete
            disabled
            multiple
            options={[
              createReference(
                "medications",
                "787481004",
                "No Known Medication(Situation)",
              ),
            ]}
            value={[
              createReference(
                "medications",
                "787481004",
                "No Known Medication(Situation)",
              ),
            ]}
            getOptionLabel={(option) => option.display}
            size="small"
            onChange={(event, newValue) => null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Typography>
                    Current Medications{" "}
                    <span style={{ color: "red", fontSize: "1.5em" }}>*</span>
                  </Typography>
                }
                // required
                InputLabelProps={{ shrink: true, required: false }}
              />
            )}
          />
        ) : (
          <TypeAheadInput
            optionType="medications"
            isMultiple
            value={value}
            size="small"
            disabled={disabled}
            onChange={(val) => {
              if (!val) {
                onChange([]);
                return;
              }

              const newValue = val.map((v) => {
                // return saved medication if not new or free text
                if (!v?.medicationCode && !v?.code) return v;

                let med: Reference & {
                  info?: Record<string, unknown>;
                };

                if (v._id) {
                  med = {
                    ...createReference(
                      "medications",
                      v.medicationCode,
                      generateMedicationDisplay(v),
                    ),
                    info: {
                      ingredient: v.ingredient,
                      form: v.form,
                      route: v.route,
                      productType: v.productType,
                      genericName: v.genericName,
                      company: v.company,
                    },
                  };
                } else {
                  med = createReference("medications", v.code, v.display);
                }

                return med;
              });

              onChange(newValue);
            }}
            fieldLabel="Current Medications"
            getValueLabel={(v) => get(v, "display")}
            getOptionLabel={(option) =>
              option?.display || generateMedicationDisplay(option)
            }
            freeOptions
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <NoKnownInput
          inputLabel="No Known Rx"
          noKnownValue={noKnownMedications}
          setNoKnownValue={() => {}}
          disabled={disabled}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
          ) => {
            if (checked) {
              onChange([
                createReference(
                  "medications",
                  "787481004",
                  "No Known Medication(Situation)",
                ),
              ]);
            } else {
              onChange([]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MedicationsInput;
