import { useQuery } from "@tanstack/react-query";
import { aiApi } from "../api/aiApi";
import { appointmentApi } from "../api/appointmentApi";
import { medicalsApi } from "../api/medicalsApi";
import { patientApi } from "../api/patientApi";
import { providerApi } from "../api/providerApi";
import { refDataApi } from "../api/refdata";

const useHealthChecks = () => {
  const FOURTEEN_MINUTES_IN_MS = 14 * 60 * 1000;
  const runHealthChecks = () => {
    return Promise.all([
      aiApi.healthCheck(),
      medicalsApi.healthCheck(),
      patientApi.healthCheck(),
      providerApi.healthCheck(),
      refDataApi.healthCheck(),
    ]);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["health-checks"],
    queryFn: () => {
      runHealthChecks();
      return { status: "ok", message: "health checks running" };
    },
    refetchInterval: FOURTEEN_MINUTES_IN_MS,
    staleTime: Infinity,
  });

  return {
    healthChecks: data,
    isLoading,
    error,
  };
};

export default useHealthChecks;
