import CodingSystems from "../../../../../../constants/CodingSystems";
import { Vital } from "../../../../../../types/Vitals";
import { Reference } from "../../../../../../types/reference";
import { createCodeableConcept } from "../../../../../../utils/DataUtils";

type CreateDefaultVital = (
  encounterReference: Reference,
  patientReference: Reference,
  codingSystem: string,
  vitalCode: string,
  vitalDisplay: string,
  unit: string,
  value?,
) => Vital;

export const createDefaultVital: CreateDefaultVital = (
  encounterReference,
  patientReference,
  codingSystem,
  vitalCode,
  vitalDisplay,
  unit,
  value?,
): Vital => {
  return {
    status: "preliminary",
    encounter: encounterReference,
    subject: patientReference,
    valueQuantity: { unit, value },
    code: createCodeableConcept(codingSystem, vitalCode, vitalDisplay),
    category: [
      createCodeableConcept(
        CodingSystems.OBSERVATION_CATEGORY,
        "vital-signs",
        "Vital Signs",
      ),
    ],
  };
};
