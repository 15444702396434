import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { get, startCase } from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import { useState } from "react";
import { defaultCountryConfig } from "../../config";
import { genders } from "../../slices/options/general";
import { datePickerErrorMsg } from "../../utils/DateUtils";
import DeleteIconButton from "../widgets/buttons/DeleteIconButton";

type Props = {
  newPatient: any;
  handleUpdate: (key: string, value: any) => void;
  handleCreate: (patient: any, reset: boolean) => void;
  handleReset: (optionForNew: any) => void;
  btnText?: string;
  dobRequired?: boolean;
  genderRequired?: boolean;
};

const PatientInput = ({
  newPatient,
  handleUpdate,
  handleCreate,
  handleReset,
  btnText = "Add New Patient",
  dobRequired = false,
  genderRequired = false,
}: Props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isDateValid, setIsDateValid] = useState(false);
  const TODAY_DATE = new Date();

  const checkIfPatientIsInvalid = () => {
    const isBirthDateInvalid = dobRequired
      ? (!isDateValid && errorMsg !== "") ||
        get(newPatient, "birthDate") === null
      : false;

    const isGenderInvalid = genderRequired
      ? startCase(get(newPatient, "gender")) === ""
      : false;
    return (
      !get(newPatient, "name[0].firstName") ||
      !get(newPatient, "name[0].family") ||
      !get(newPatient, "telecom[0].value") ||
      get(newPatient, "telecom[0].value").length <= 6 ||
      isBirthDateInvalid ||
      isGenderInvalid
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              value={newPatient?.name[0].firstName}
              onChange={(e) => {
                handleUpdate("name[0].firstName", e.target.value.trim());
              }}
              id="standard-basic"
              label="First Name"
              required={true}
              InputLabelProps={{ shrink: true }}
              tabIndex={1}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              value={newPatient?.name[0].family}
              onChange={(e) => {
                handleUpdate("name[0].family", e.target.value.trim());
              }}
              id="standard-basic"
              label="Last Name"
              required={true}
              InputLabelProps={{ shrink: true }}
              tabIndex={2}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <FormControl variant="outlined" fullWidth>
                <MuiPhoneNumber
                  autoFormat
                  defaultCountry={defaultCountryConfig?.defaultCountryPhoneCode}
                  value={newPatient?.telecom[0].value}
                  onChange={(e) => {
                    handleUpdate("telecom[0].value", e);
                  }}
                  fullWidth
                  variant="outlined"
                  tabIndex={3}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DeleteIconButton
                tooltip="Remove Patient"
                onClick={handleReset}
                tabIndex={6}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              required={genderRequired}
              select
              value={newPatient?.gender ? newPatient?.gender : ""}
              onChange={(e) => {
                handleUpdate("gender", e.target.value);
              }}
              id="patient-gender"
              label="Gender"
              InputLabelProps={{ shrink: true }}
              tabIndex={5}
            >
              {genders.map(({ code, display }, index) => {
                return (
                  <MenuItem key={index} value={code}>
                    {display}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <DatePicker
              onError={(reason, value) => {
                if (reason) {
                  setErrorMsg(datePickerErrorMsg(reason));
                  setIsDateValid(false);
                } else {
                  setErrorMsg("");
                  setIsDateValid(true);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  InputLabelProps={{ shrink: true }}
                  error={!isDateValid && errorMsg !== ""}
                  helperText={errorMsg}
                  required={dobRequired}
                  tabIndex={4}
                />
              )}
              label="Date of Birth"
              value={newPatient?.birthDate ? newPatient?.birthDate : null}
              onChange={(date) => {
                if (date) {
                  const d = new Date(date);
                  d.setHours(0, 0, 0, 0);
                  handleUpdate("birthDate", d);
                } else {
                  handleUpdate("birthDate", null);
                }
              }}
              maxDate={TODAY_DATE}
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" py={0}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={checkIfPatientIsInvalid()}
              style={{ marginLeft: "5px" }}
              onClick={() => {
                handleCreate(newPatient, false);
              }}
            >
              {btnText}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInput;
