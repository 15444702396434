import { get } from "lodash";
import { Code } from "../types/code";
import { CodeableConcept } from "../types/codeableConcept";

export const createCodeableConcept = (
  system,
  code,
  display,
): CodeableConcept => {
  return {
    coding: [
      {
        system,
        code,
        display,
      },
    ],
    text: display,
  };
};

export const createCodeFromCcodeableConcept = (
  codeableConcept: CodeableConcept,
): Code => {
  if (codeableConcept) {
    return {
      code: codeableConcept.coding[0]?.code,
      display: codeableConcept?.text || codeableConcept.coding[0]?.display,
    };
  }
};

export const isCodeableConceptEqualToValue = (
  option: any,
  value: any,
  isMultiple?: boolean,
) => {
  let isEqual = false;

  if (option && value) {
    isEqual = option.code === value[0]?.code;
  }
  return isEqual;
};

export const isReferenceEqualToOption = (option: any, value: any) => {
  let isEqual = false;

  if (option && value) {
    isEqual = option.code === value?.identifier;
  }
  return isEqual;
};

export const isCodeableConcept = (concept) => {
  const code = get(concept, "coding[0].code");
  return code !== undefined && code !== null;
};

export const isPremiumOrganization = (adminSettings) => {
  if (!adminSettings) return false;
  if (get(adminSettings, "tier") === "PREMIUM") {
    return true;
  }
  return false;
};

export const getOrganizationTier = (adminSettings) => {
  return get(adminSettings, "tier");
};
