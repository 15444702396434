import { nanoid } from "@reduxjs/toolkit";
import { CalculatorOptionsGroup } from "../types/calculatorOptionsGroup";
import { useChoiceCalculator } from "../hooks/useChoiceCalculator";
import BasicCalculatorChoice from "../components/BasicCalculatorChoice";

const options: CalculatorOptionsGroup[] = [
  {
    title: "Age",
    code: "age",
    required: true,
    options: [
      { display: "< 65 years", code: nanoid(), result: 0 },
      { display: "65-74 years", code: nanoid(), result: 1 },
      { display: "≥ 74 years", code: nanoid(), result: 2 },
    ],
  },
  {
    title: "Sex",
    code: "sex",
    required: true,
    options: [
      { display: "Male", code: nanoid(), result: 0 },
      { display: "Female", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "CHF History",
    code: "chf-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Hypertension History",
    code: "hypertension-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Stroke/TIA/Thromboembolism history",
    code: "stroke-tia-thromboembolism-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Vascular disease history",
    subtitle: "(prior MI, peripheral artery disease, or aortic plaque)",
    code: "vasular-disease-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
  {
    title: "Diabetes history",
    code: "diabetes-history",
    options: [
      { display: "No", code: nanoid(), result: 0, default: true },
      { display: "Yes", code: nanoid(), result: 1 },
    ],
  },
];

export default function CHA2DsVAScComponent() {
  const choiceCalculator = useChoiceCalculator(options, (value) => {
    switch (true) {
      case value >= 2:
        return "Oral anticoagulants (OAC)";
      case value === 1:
        return "None or aspirin or oral anticoagulants (OAC)";
      default:
        return "None";
    }
  });

  return <BasicCalculatorChoice {...choiceCalculator} options={options} />;
}
