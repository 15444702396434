import { Auth0Client } from "@auth0/auth0-spa-js";
import { auth0Config } from "../config";
import axios from "axios";
import { Reference } from "../types/reference";

export class AIApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  async getTranscription(
    data: string,
    encounterId: string,
    providerReferece: Reference,
  ) {
    const token = await this.auth0Client?.getTokenSilently();

    return await axios.post(
      `${import.meta.env.VITE_AI_SERVICE_BASE}/speech/transcribe/b64`,
      { audio: data, attester: providerReferece },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-current-encounter": encounterId,
        },
      },
    );
  }

  async generateNote(copilotId: string) {
    const token = await this.auth0Client?.getTokenSilently();

    return await axios.get(
      `${import.meta.env.VITE_AI_SERVICE_BASE}/notes/copilots/${copilotId}`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  }

  async healthCheck() {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_AI_SERVICE_BASE}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "AI Service health check was unsuccessful" + JSON.stringify(e),
      );
    }
  }
}

export const aiApi = new AIApi();
