import { Code } from "../../types/code";

export const encounterTriageTypes = [
  {
    code: "0",
    display: "Dead on Arrival (DOA)",
    definition: "Dead on Arrival (DOA)",
  },
  {
    code: "1",
    display: "Level 1 – Resuscitation",
    definition:
      "Immediate life-saving intervention. Time to assessment: immediately or within 2 minutes. ",
  },
  {
    code: "2",
    display: "Level 2 – Emergency",
    definition:
      "Imminently life-threatening condition eg. serious chest pains, difficulty breathing, severe fractures. Time to assessment: within 10 minutes. ",
  },
  {
    code: "3",
    display: "Level 3 – Urgent",
    definition:
      "Potentially life threatening condition eg. severe dehydration, major fracture, bleeding heavily from cuts. Time to assessment: within 30 minutes. ",
  },
  {
    code: "4",
    display: "Level 4 – Semi-urgent",
    definition:
      "Potentially serious condition eg. sprained ankle, migraine, earache. Time to assessment: within one hour.",
  },
  {
    code: "5",
    display: "Level 5 – Non-urgent",
    definition:
      "(less urgent condition eg. minor aches and pains). Time to assessment: within two hours.",
  },

  // { code: "99", display: "Other", definition: "Other" },
];

export const defaultEncounterStatus = [
  {
    code: "planned",
    display: "Planned",
    definition: "The Encounter has not yet started.",
  },
  {
    code: "arrived",
    display: "Arrived",
    definition:
      "The Patient is present for the encounter, however is not currently meeting with a practitioner.",
  },
  {
    code: "triaged",
    display: "Triaged",
    definition:
      "The patient has been assessed for the priority of their treatment based on the severity of their condition.",
  },
  {
    code: "in-progress",
    display: "In Progress",
    definition:
      "The Encounter has begun and the patient is present / the practitioner and the patient are meeting.",
  },
  {
    code: "on-leave",
    display: "On Leave",
    definition:
      "The Encounter has begun, but the patient is temporarily on leave.",
  },
  {
    code: "unknown",
    display: "Unknown",
    definition:
      'The encounter status is unknown. Note that "unknown" is a value of last resort and every attempt should be made to provide a meaningful value other than "unknown".',
  },
];

export const activeEncounterStatus = [
  {
    code: "arrived",
    display: "Arrived",
    definition:
      "The Patient is present for the encounter, however is not currently meeting with a practitioner.",
  },
  {
    code: "triaged",
    display: "Triaged",
    definition:
      "The patient has been assessed for the priority of their treatment based on the severity of their condition.",
  },
  {
    code: "in-progress",
    display: "In Progress",
    definition:
      "The Encounter has begun and the patient is present / the practitioner and the patient are meeting.",
  },
];

export const encounterParticipantRole: Code[] = [
  {
    code: "ADM",
    display: "Admitter",
    definition:
      "The practitioner who is responsible for admitting a patient to a patient encounter.",
  },
  {
    code: "ATND",
    display: "Attender",
    definition:
      "The practitioner that has responsibility for overseeing a patient's care during a patient encounter.",
  },
  {
    code: "CON",
    display: "Consultant",
    definition:
      "An advisor participating in the service by performing evaluations and making recommendations.",
  },
];

export const EncounterStatus = [
  {
    code: "planned",
    display: "Planned",
    definition: "The Encounter has not yet started.",
  },
  {
    code: "arrived",
    display: "Arrived",
    definition:
      "The Patient is present for the encounter, however is not currently meeting with a practitioner.",
  },
  {
    code: "triaged",
    display: "Triaged",
    definition:
      "The patient has been assessed for the priority of their treatment based on the severity of their condition.",
  },
  {
    code: "in-progress",
    display: "In Progress",
    definition:
      "The Encounter has begun and the patient is present / the practitioner and the patient are meeting.",
  },
  {
    code: "on-leave",
    display: "On Leave",
    definition:
      "The Encounter has begun, but the patient is temporarily on leave.",
  },
  {
    code: "finished",
    display: "Finished",
    definition: "The Encounter has ended.",
  },
  {
    code: "cancelled",
    display: "Cancelled",
    definition: "The Encounter has ended before it has begun.",
  },
  {
    code: "entered-in-error",
    display: "Entered in Error",
    definition:
      "This instance should not have been part of this patient's medical record.",
  },
];

export const EncounterClassification = [
  {
    code: "AMB",
    display: "Ambulatory | Outpatient",
    definition:
      "A comprehensive term for health care provided in a healthcare facility (e.g. a practitioneraTMs office, clinic setting, or hospital) on a nonresident basis. The term ambulatory usually implies that the patient has come to the location and is not assigned to a bed. Sometimes referred to as an outpatient encounter.",
  },
  {
    code: "EMER",
    display: "Emergency",
    definition:
      "A patient encounter that takes place at a dedicated healthcare service delivery location where the patient receives immediate evaluation and treatment, provided until the patient can be discharged or responsibility for the patient's care is transferred elsewhere (for example, the patient could be admitted as an inpatient or transferred to another facility.)",
  },
  {
    code: "ELEC",
    display: "Elective",
    definition:
      "A patient encounter that takes place at a dedicated healthcare service delivery location where the patient receives elective treatment.",
  },
  {
    code: "FLD",
    display: "Field",
    definition:
      "A patient encounter that takes place both outside a dedicated service delivery location and outside a patient's residence. Example locations might include an accident site and at a supermarket.",
  },
  {
    code: "HH",
    display: "Home Health",
    definition:
      "Healthcare encounter that takes place in the residence of the patient or a designee",
  },
  {
    code: "IMP",
    display: "Inpatient",
    definition:
      "A patient encounter where a patient is admitted by a hospital or equivalent facility, assigned to a location where patients generally stay at least overnight and provided with room, board, and continuous nursing service.",
  },
  {
    code: "ACUTE",
    display: "Inpatient Acute",
    definition: "An acute inpatient encounter.",
  },
  {
    code: "NONAC",
    display: "Inpatient Non-acute",
    definition: "Any category of inpatient encounter except 'acute'",
  },
  {
    code: "NEWB",
    display: "New Born",
    definition:
      "A patient encounter where a patient is admitted to a hospital for purposes of delivering a baby.",
  },
  {
    code: "OBSENC",
    display: "Observation",
    definition:
      "An encounter where the patient usually will start in different encounter, such as one in the emergency department (EMER) but then transition to this type of encounter because they require a significant period of treatment and monitoring to determine whether or not their condition warrants an inpatient admission or discharge. In the majority of cases the decision about admission or discharge will occur within a time period determined by local, regional or national regulation, often between 24 and 48 hours.",
  },
  {
    code: "PRENC",
    display: "Pre-admission",
    definition:
      "A patient encounter where patient is scheduled or planned to receive service delivery in the future, and the patient is given a pre-admission account number. When the patient comes back for subsequent service, the pre-admission encounter is selected and is encapsulated into the service registration, and a new account number is generated. Usage Note: This is intended to be used in advance of encounter types such as ambulatory, inpatient encounter, virtual, etc.",
  },
  {
    code: "SS",
    display: "Short Stay",
    definition:
      "An encounter where the patient is admitted to a health care facility for a predetermined length of time, usually less than 24 hours.",
  },
  {
    code: "VR",
    display: "Virtual",
    definition:
      "A patient encounter where the patient and the practitioner(s) are not in the same physical location. Examples include telephone conference, email exchange, robotic surgery, and televideo conference.",
  },
];
