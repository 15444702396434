import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { get, pullAt } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { FC } from "react";
import usePractitioner from "../../hooks/usePractitioner";
import { formatDateTime } from "../../utils/DateUtils";
import DictationControl from "../dictation/DictationControl";
import DeleteIconButton from "../widgets/buttons/DeleteIconButton";

interface NotesInputProps {
  value: any;
  onChange: any;
  label?: string;
  placeholderText: string;
  buttonLabel?: string;
  inputSize?: "small" | "medium";
  labelVariant?: Variant;
  disabled: boolean;
  minRows?: number;
  required?: boolean;
  maxNotes?: number;
}

const NotesInput: FC<NotesInputProps> = ({
  value,
  onChange,
  label,
  placeholderText,
  buttonLabel,
  labelVariant,
  inputSize,
  disabled,
  maxNotes,
  minRows = 1,
  required,
}) => {
  const confirm = useConfirm();

  const practitionerReference = usePractitioner();

  const addNote = () => {
    if (value && value.length > 0) {
      onChange([...value, { author: practitionerReference }]);
    } else {
      onChange([{ author: practitionerReference }]);
    }
  };

  const deleteNote = (index) => {
    confirm({ description: "This action is permanent once saved!" })
      .then(() => {
        if (index > -1) {
          const copy = [...value];
          pullAt(copy, [index]);
          onChange(copy);
        }
      })
      .catch(() => {});
  };

  return (
    <Grid container spacing={1}>
      {maxNotes !== 1 && label && (
        <Grid item xs={12}>
          <Typography variant={labelVariant ? labelVariant : "subtitle2"}>
            {label}
          </Typography>
        </Grid>
      )}

      {value?.map((note, i) => (
        <Grid item xs={12} key={`note-${i}`}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            flexDirection="row"
            gap={1}
          >
            <Box flex={1}>
              <FormControl variant="outlined" fullWidth>
                <DictationControl
                  fullWidth
                  size={inputSize}
                  required={required}
                  value={get(note, "text") || ""}
                  onTextChange={(val) => {
                    const updatedNotes = [...value];
                    updatedNotes[i].text = val;
                    updatedNotes[i].time = new Date();
                    onChange(updatedNotes);
                  }}
                  id={`notes-label-${i}`}
                  label={maxNotes === 1 ? label : ""}
                  disabled={
                    (maxNotes !== 1 &&
                      practitionerReference?.reference !==
                        value[i]?.author?.reference) ||
                    disabled ||
                    i < value.length - 1
                  }
                  placeholder={placeholderText}
                  minRows={minRows}
                  maxRows={12}
                />
              </FormControl>
            </Box>
            {(!maxNotes || maxNotes > 1) && (
              <Box>
                {!disabled &&
                  practitionerReference?.reference ===
                    value[i]?.author?.reference &&
                  i === value.length - 1 && (
                    <DeleteIconButton
                      tooltip="Remove Comment"
                      onClick={() => deleteNote(i)}
                      disabled={disabled}
                    />
                  )}
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            gap={1}
          >
            <Typography
              flex={1}
              style={{ float: "right" }}
              align="right"
              variant="caption"
            >
              {get(value[i], "author.display")
                ? `Author: ${get(value[i], "author.display")}`
                : ""}{" "}
              {get(value[i], "time")
                ? `Time: ${formatDateTime(new Date(get(value[i], "time")))}`
                : ""}
            </Typography>
            <Box width="40px" />
          </Box>
        </Grid>
      ))}

      {(!maxNotes || maxNotes > 1) && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              onClick={addNote}
              color="info"
              disabled={disabled}
              variant="outlined"
              size="small"
            >
              {buttonLabel || "Add Comment"}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default NotesInput;
