import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../store";
import { providerApi } from "../api/providerApi";
import { Organization } from "../types/organization";
import { Reference } from "../types/reference";
import {
  createReference,
  generateHumanDisplayName,
} from "../utils/DataTransformationUtils";

interface UserState {
  profile: any;
  profileSuccess: boolean;
  roles: any[];
  rolesSuccess: boolean;
  organization: Organization;
  practitionerReference: Reference;
  organizationReference: Reference;
  currentOrganization: Organization;
}

const initialState: UserState = {
  profile: undefined,
  profileSuccess: undefined,
  roles: undefined,
  rolesSuccess: undefined,
  organization: undefined,
  practitionerReference: undefined,
  organizationReference: undefined,
  currentOrganization: undefined,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile(state: UserState, action: PayloadAction<any>): void {
      state.profile = action.payload;
      state.profileSuccess = true;
    },
    getUserProfile(state: UserState, action: PayloadAction<any>): void {
      state.profile = action.payload;
      state.profileSuccess = true;
    },
    getUserProfileFailed(state: UserState): void {
      state.profileSuccess = false;
      state.profile = undefined;
    },
    setUserRoles(state: UserState, action: PayloadAction<any>): void {
      state.roles = action.payload;
      state.rolesSuccess = true;
    },
    getUserRoles(state: UserState, action: PayloadAction<any>): void {
      state.roles = action.payload;
      state.rolesSuccess = true;
    },
    getUserRolesFailed(state: UserState): void {
      state.rolesSuccess = false;
      state.roles = undefined;
    },
    setUserOrganization(state: UserState, action: PayloadAction<any>): void {
      state.organization = action.payload;
    },
    setCurrentOrganizationFailed(state: UserState): void {
      state.organization = undefined;
    },
    setCurrentOrganization(state: UserState, action: PayloadAction<any>): void {
      state.currentOrganization = action.payload;
    },
    setOrganizationReference(
      state: UserState,
      action: PayloadAction<any>,
    ): void {
      const reference = createReference(
        "Organization",
        action.payload.id,
        action.payload.displayName,
      );
      state.organizationReference = reference;
    },
    setPractitionerReference(
      state: UserState,
      action: PayloadAction<any>,
    ): void {
      const reference = createReference(
        "Practitioner",
        action.payload.id,
        generateHumanDisplayName(action.payload.name[0]),
      );
      state.practitionerReference = reference;
    },
  },
});

export const setUserProfile =
  (data): AppThunk =>
  (dispatch): void => {
    try {
      dispatch(slice.actions.getUserProfile(data));
      dispatch(slice.actions.setPractitionerReference(data));
    } catch (e) {
      dispatch(slice.actions.getUserProfileFailed());
    }
  };

export const getUserProfile =
  (id): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      if (id) {
        const data = await providerApi.getPractitionerProfile(id);
        if (data) {
          dispatch(slice.actions.getUserProfile(data));
          dispatch(slice.actions.setPractitionerReference(data));
        }
      }
    } catch (e) {
      dispatch(slice.actions.getUserProfileFailed());
    }
  };

export const getUserRoles =
  (orgId): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const claims = await providerApi.getIdTokenClaims();
      const data = claims["https://www.medialhealth.com/roles"][orgId];

      dispatch(slice.actions.setUserRoles(data));
    } catch (e) {
      dispatch(slice.actions.getUserRolesFailed());
    }
  };

export const getCurrentOrganization =
  (orgId): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const data = await providerApi.findOrganization(orgId);

      dispatch(slice.actions.setCurrentOrganization(data));
    } catch (e) {
      dispatch(slice.actions.setCurrentOrganizationFailed());
    }
  };

export const setUserOrganization =
  (org): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.setUserOrganization(org));
    dispatch(slice.actions.setOrganizationReference(org));
  };

export const { reducer } = slice;

export default slice;
