import { Code } from "../../types/code";

export const routeCodes = [
  { code: "6064005", display: "Topical route" },
  { code: "10547007", display: "Auricular use" },
  { code: "12130007", display: "Intra-articular route" },
  { code: "16857009", display: "Vaginal use" },
  { code: "26643006", display: "Oral use" },
  { code: "34206005", display: "SC use" },
  { code: "37161004", display: "Rectal use" },
  { code: "37737002", display: "Intraluminal use" },
  { code: "37839007", display: "Sublingual use" },
  { code: "38239002", display: "Intraperitoneal use" },
  { code: "45890007", display: "Transdermal use" },
  { code: "46713006", display: "Nasal use" },
  { code: "47625008", display: "Intravenous use" },
  { code: "54471007", display: "Buccal use" },
  { code: "54485002", display: "Ophthalmic use" },
  { code: "58100008", display: "Intra-arterial use" },
  { code: "60213007", display: "Intramedullary route" },
  { code: "62226000", display: "Intrauterine route" },
  { code: "72607000", display: "Intrathecal route" },
  { code: "78421000", display: "Intramuscular use" },
  { code: "90028008", display: "Urethral use" },
  { code: "127490009", display: "Gastrostomy use" },
  { code: "127491008", display: "Jejunostomy use" },
  { code: "127492001", display: "Nasogastric use" },
  { code: "372449004", display: "Dental use" },
  { code: "372450004", display: "Endocervical use" },
  { code: "372451000", display: "Endosinusial use" },
  { code: "372452007", display: "Endotracheopulmonary use" },
  { code: "372453002", display: "Extra-amniotic use" },
  { code: "372454008", display: "Gastroenteral use" },
  { code: "372457001", display: "Gingival use" },
  { code: "372458006", display: "Intraamniotic use" },
  { code: "372459003", display: "Intrabursal use" },
  { code: "372460008", display: "Intracardiac use" },
  { code: "372461007", display: "Intracavernous use" },
  { code: "372463005", display: "Intracoronary use" },
  { code: "372464004", display: "Intradermal use" },
  { code: "372465003", display: "Intradiscal use" },
  { code: "372466002", display: "Intralesional use" },
  { code: "372467006", display: "Intralymphatic use" },
  { code: "372468001", display: "Intraocular use" },
  { code: "372469009", display: "Intrapleural use" },
  { code: "372470005", display: "Intrasternal use" },
  { code: "372471009", display: "Intravesical use" },
  { code: "372473007", display: "Oromucosal use" },
  { code: "372474001", display: "Periarticular use" },
  { code: "372475000", display: "Perineural use" },
  { code: "372476004", display: "Subconjunctival use" },
  { code: "422037005", display: "Subcutaneous route" },
  { code: "404815008", display: "Transmucosal route" },
  { code: "404818005", display: "Intratracheal route" },
  { code: "404819002", display: "Intrabiliary route" },
  { code: "404820008", display: "Epidural route" },
  { code: "416174007", display: "Suborbital route" },
  { code: "417070009", display: "Caudal route" },
  { code: "417255000", display: "Intraosseous route" },
  { code: "417950001", display: "Intrathoracic route" },
  { code: "417985001", display: "Enteral route" },
  { code: "417989007", display: "Intraductal route" },
  { code: "418091004", display: "Intratympanic route" },
  { code: "418114005", display: "Intravenous central route" },
  { code: "418133000", display: "Intramyometrial route" },
  { code: "418136008", display: "Gastro-intestinal stoma route" },
  { code: "418162004", display: "Colostomy route" },
  { code: "418204005", display: "Periurethral route" },
  { code: "418287000", display: "Intracoronal route" },
  { code: "418321004", display: "Retrobulbar route" },
  { code: "418331006", display: "Intracartilaginous route" },
  { code: "418401004", display: "Intravitreal route" },
  { code: "418418000", display: "Intraspinal route" },
  { code: "418441008", display: "Orogastric route" },
  { code: "418511008", display: "Transurethral route" },
  { code: "418586008", display: "Intratendinous route" },
  { code: "418608002", display: "Intracorneal route" },
  { code: "418664002", display: "Oropharyngeal route" },
  { code: "418722009", display: "Peribulbar route" },
  { code: "418730005", display: "Nasojejunal route" },
  { code: "418743005", display: "Fistula route" },
  { code: "418813001", display: "Surgical drain route" },
  { code: "418821007", display: "Intracameral route" },
  { code: "418851001", display: "Paracervical route" },
  { code: "418877009", display: "Intrasynovial route" },
  { code: "418887008", display: "Intraduodenal route" },
  { code: "418892005", display: "Intracisternal route" },
  { code: "418947002", display: "Intratesticular route" },
  { code: "418987007", display: "Intracranial route" },
  { code: "419021003", display: "Tumor cavity route" },
  { code: "419165009", display: "Paravertebral route" },
  { code: "419231003", display: "Intrasinal route" },
  { code: "419243002", display: "Transcervical route" },
  { code: "419320008", display: "Subtendinous route" },
  { code: "419396008", display: "Intraabdominal route" },
  { code: "419601003", display: "Subgingival route" },
  { code: "419631009", display: "Intraovarian route" },
  { code: "419684008", display: "Ureteral route" },
  { code: "419762003", display: "Peritendinous route" },
  { code: "419778001", display: "Intrabronchial route" },
  { code: "419810008", display: "Intraprostatic route" },
  { code: "419874009", display: "Submucosal route" },
  { code: "419894000", display: "Surgical cavity route" },
  { code: "419954003", display: "Ileostomy route" },
  { code: "419993007", display: "Intravenous peripheral route" },
  { code: "420047004", display: "Periosteal route" },
  { code: "420163009", display: "Esophagostomy route" },
  { code: "420168000", display: "Urostomy route" },
  { code: "420185003", display: "Laryngeal route" },
  { code: "420201002", display: "Intrapulmonary route" },
  { code: "420204005", display: "Mucous fistula route" },
  { code: "420218003", display: "Nasoduodenal route" },
  { code: "420254004", display: "Body cavity route" },
  { code: "420287000", display: "Intraventricular route - cardiac" },
  { code: "420719007", display: "Intracerebroventricular route" },
  { code: "428191002", display: "Percutaneous route" },
  { code: "429817007", display: "Interstitial route" },
  { code: "445752009", display: "Intraesophageal route" },
  { code: "445754005", display: "Intragingival route" },
  { code: "445755006", display: "Intravascular route" },
  { code: "445756007", display: "Intradural route" },
  { code: "445767008", display: "Intrameningeal route" },
  { code: "445768003", display: "Intragastric route" },
  { code: "445769006", display: "Intracorpus cavernosum route" },
  { code: "445771006", display: "Intrapericardial route" },
  { code: "445913005", display: "Intralingual route" },
  { code: "445941009", display: "Intrahepatic route" },
  { code: "446105004", display: "Conjunctival route" },
  { code: "446407004", display: "Intraepicardial route" },
  { code: "446435000", display: "Transendocardial route" },
  { code: "446442000", display: "Transplacental route" },
  { code: "446540005", display: "Intracerebral route" },
  { code: "447026006", display: "Intraileal route" },
  { code: "447052000", display: "Periodontal route" },
  { code: "447080003", display: "Peridural route" },
  { code: "447081004", display: "Lower respiratory tract route" },
  { code: "447121004", display: "Intramammary route" },
  { code: "447122006", display: "Intratumor route" },
  { code: "447227007", display: "Transtympanic route" },
  { code: "447229005", display: "Transtracheal route" },
  { code: "447694001", display: "Respiratory tract route" },
  { code: "447964005", display: "Digestive tract route" },
  { code: "448077001", display: "Intraepidermal route" },
  { code: "448491004", display: "Intrajejunal route" },
  { code: "448492006", display: "Intracolonic route" },
  { code: "448598008", display: "Cutaneous route" },
  { code: "697971008", display: "Arteriovenous fistula route" },
  { code: "711360002", display: "Intraneural route" },
  { code: "711378007", display: "Intramural route" },
  { code: "714743009", display: "Extracorporeal route" },
  { code: "718329006", display: "Infiltration route" },
  { code: "1611000175109", display: "Sublesional route" },
  { code: "180677251000087104", display: "Intraventricular" },
  { code: "461657851000087101", display: "Translingual" },
  { code: "17596003", display: "Otic route" },
];

export const bodySite: Code[] = [
  {
    code: "LA",
    display: "left arm",
    definition: "",
  },
  {
    code: "RA",
    display: "right arm",
    definition: "",
  },
  {
    code: "BE",
    display: "bilateral ears",
    definition: "",
  },
  {
    code: "BN",
    display: "bilateral nares",
    definition: "",
  },
  {
    code: "BU",
    display: "buttock",
    definition: "",
  },

  {
    code: "LAC",
    display: "left anterior chest",
    definition: "",
  },
  {
    code: "LACF",
    display: "left antecubital fossa",
    definition: "",
  },
  {
    code: "LD",
    display: "left deltoid",
    definition: "",
  },
  {
    code: "LE",
    display: "left ear",
    definition: "",
  },
  {
    code: "LEJ",
    display: "left external jugular",
    definition: "",
  },
  {
    code: "LF",
    display: "left foot",
    definition: "",
  },
  {
    code: "LG",
    display: "left gluteus medius",
    definition: "",
  },
  {
    code: "LH",
    display: "left hand",
    definition: "",
  },
  {
    code: "LIJ",
    display: "left internal jugular",
    definition: "",
  },
  {
    code: "LLAQ",
    display: "left lower abd quadrant",
    definition: "",
  },
  {
    code: "LLFA",
    display: "left lower forearm",
    definition: "",
  },
  {
    code: "LMFA",
    display: "left mid forearm",
    definition: "",
  },
  {
    code: "LN",
    display: "left naris",
    definition: "",
  },
  {
    code: "LPC",
    display: "left posterior chest",
    definition: "",
  },
  {
    code: "LSC",
    display: "left subclavian",
    definition: "",
  },
  {
    code: "LT",
    display: "left thigh",
    definition: "",
  },
  {
    code: "LUA",
    display: "left upper arm",
    definition: "",
  },
  {
    code: "LUAQ",
    display: "left upper abd quadrant",
    definition: "",
  },
  {
    code: "LUFA",
    display: "left upper forearm",
    definition: "",
  },
  {
    code: "LVG",
    display: "left ventragluteal",
    definition: "",
  },
  {
    code: "LVL",
    display: "left vastus lateralis",
    definition: "",
  },
  {
    code: "OD",
    display: "right eye",
    definition: "",
  },
  {
    code: "OS",
    display: "left eye",
    definition: "",
  },
  {
    code: "OU",
    display: "bilateral eyes",
    definition: "",
  },
  {
    code: "PA",
    display: "perianal",
    definition: "",
  },
  {
    code: "PERIN",
    display: "perineal",
    definition: "",
  },

  {
    code: "RAC",
    display: "right anterior chest",
    definition: "",
  },
  {
    code: "RACF",
    display: "right antecubital fossa",
    definition: "",
  },
  {
    code: "RD",
    display: "right deltoid",
    definition: "",
  },
  {
    code: "RE",
    display: "right ear",
    definition: "",
  },
  {
    code: "REJ",
    display: "right external jugular",
    definition: "",
  },
  {
    code: "RF",
    display: "right foot",
    definition: "",
  },
  {
    code: "RG",
    display: "right gluteus medius",
    definition: "",
  },
  {
    code: "RH",
    display: "right hand",
    definition: "",
  },
  {
    code: "RIJ",
    display: "right internal jugular",
    definition: "",
  },
  {
    code: "RLAQ",
    display: "right lower abd quadrant",
    definition: "",
  },
  {
    code: "RLFA",
    display: "right lower forearm",
    definition: "",
  },
  {
    code: "RMFA",
    display: "right mid forearm",
    definition: "",
  },
  {
    code: "RN",
    display: "right naris",
    definition: "",
  },
  {
    code: "RPC",
    display: "right posterior chest",
    definition: "",
  },
  {
    code: "RSC",
    display: "right subclavian",
    definition: "",
  },
  {
    code: "RT",
    display: "right thigh",
    definition: "",
  },
  {
    code: "RUA",
    display: "right upper arm",
    definition: "",
  },
  {
    code: "RUAQ",
    display: "right upper abd quadrant",
    definition: "",
  },
  {
    code: "RUFA",
    display: "right upper forearm",
    definition: "",
  },
  {
    code: "RVG",
    display: "right ventragluteal",
    definition: "",
  },
  {
    code: "RVL",
    display: "right vastus lateralis",
    definition: "",
  },
];

export const bodyPosition = [
  {
    code: "30212006",
    display: "Fowler's position",
  },
  {
    code: "26527006",
    display: "Inverse Trendelenburg position",
  },
  {
    code: "102536004",
    display: "Left lateral decubitus position",
  },
  {
    code: "414585002",
    display: "Left lateral tilt",
  },
  {
    code: "10904000",
    display: "Orthostatic body position",
  },
  {
    code: "1240000",
    display: "Prone body position",
  },
  {
    code: "102538003",
    display: "Recumbent body position",
  },
  {
    code: "423413008",
    display: "Reverse trendelenburg positioning",
  },
  {
    code: "102535000",
    display: "Right lateral decubitus position",
  },
  {
    code: "415346000",
    display: "Right lateral tilt",
  },
  {
    code: "33586001",
    display: "Sitting position",
  },
  {
    code: "40199007",
    display: "Supine body position",
  },
  {
    code: "34106002",
    display: "Trendelenburg position",
  },
];
