import { useState, MouseEvent, useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import ContentSearch from "./ContentSearch";
import Logo from "../Logo";
import useAuth from "../../hooks/useAuth";
import { Organization } from "../../types/organization";
import useSettings from "../../hooks/useSettings";
import useOrgSelectionModal from "../../hooks/useOrgSelectionModal";
import ActionsMenu from "../ActionsMenu";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Alert,
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Toolbar,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import OrganizationTier from "./OrganizationTier";
import { KeyboardArrowDown } from "@mui/icons-material";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.appBar,
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const auth = useAuth();
  const { homeDashboard } = useFlags();
  const { settings } = useSettings();
  const { openOrgSelectionModal } = useOrgSelectionModal();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [currentOrg, setCurrentOrg] = useState<Organization>();
  const { onSidebarMobileOpen, ...other } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    const { organizations } = auth;
    const { selectedOrganization } = settings;
    if (selectedOrganization) {
      const selectedOrg: Organization = organizations?.find(
        (o: Organization) => o.id === selectedOrganization.id,
      );
      setOrganizations(organizations);
      setCurrentOrg(selectedOrg);
    }
  }, [auth, settings]);

  const { appAlert } = useFlags();
  const orgMenuOpen = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerFacilityChange = () => {
    openOrgSelectionModal();
    handleClose();
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, display: "flex" }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <RouterLink to={homeDashboard ? "/" : "/patients"}>
          {currentOrg && currentOrg.branding && currentOrg.branding.logo_url ? (
            <Avatar
              src={currentOrg.branding.logo_url}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <Logo
              sx={{
                display: {
                  lg: "inline",
                  xs: "none",
                },
                height: 40,
                width: 40,
              }}
            />
          )}
        </RouterLink>

        <Box
          sx={{
            ml: 2,
          }}
        >
          <Button
            fullWidth
            id="org-select-menu-button"
            aria-controls="org-select-menu"
            aria-haspopup="true"
            aria-expanded={orgMenuOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            style={{
              backgroundColor: "transparent",
              cursor: "default",
              whiteSpace: "nowrap",
            }}
            endIcon={
              organizations && organizations.length > 1 ? (
                <KeyboardArrowDown />
              ) : null
            }
          >
            {currentOrg ? (
              <>
                <Typography color="white">{currentOrg.displayName}</Typography>
                <OrganizationTier />
              </>
            ) : null}
          </Button>
          {organizations?.length > 1 ? (
            <StyledMenu
              id="org-select-menu"
              MenuListProps={{ "aria-labelledby": "org-select-menu-button" }}
              anchorEl={anchorEl}
              open={orgMenuOpen}
              onClose={handleClose}
            >
              <MenuItem onClick={triggerFacilityChange}>
                Change Medical Facility
              </MenuItem>
            </StyledMenu>
          ) : null}
        </Box>

        <Grid flex={1} item xs={12} sx={{ zIndex: "modal" }}>
          {appAlert && appAlert?.message && (
            <Grid sx={{ zIndex: "modal" }}>
              <Alert severity={appAlert?.variant}>{appAlert?.message}</Alert>
            </Grid>
          )}
        </Grid>

        <Box sx={{ ml: 1 }}>
          <ActionsMenu />
        </Box>

        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>

        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
